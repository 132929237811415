import type { ReactNode } from 'react';
import { InfoTooltip } from '../InfoTooltip';

export function TooltipReleveInformation(): ReactNode {
  return (
    <InfoTooltip
      position="bottom"
      tooltip={
        <div>
          Ce document est l’historique sur lequel vous retrouvez toutes les informations concernant votre contrat
          d’assurance, tels que votre bonus malus, vos sinistres, etc.
          <br />
          <br />
          Il vous est transmis automatiquement quand vous résiliez un contrat d’assurance.
          <br />
          <br />
          Si vous ne l’avez pas, demandez-le à votre assureur qui est tenu de vous l’envoyer sous 15 jours.
          <br />
          <br />
          En attendant, vous pouvez vous référer à l’avis d’échéance envoyé par votre assureur tous les ans.
        </div>
      }
    />
  );
}
