import { CheckRegularIcon, InfoRegularIcon, WarningRegularIcon, XRegularIcon } from '@ornikar/kitt-icons/phosphor';
import { IconButton, Typography, TypographyIcon } from '@ornikar/kitt-universal';
import cx from 'classnames';
import type { ReactNode } from 'react';
import styles from './styles.module.css';

export type ToastType = 'info' | 'success' | 'error';

export interface ToastProps {
  isVisible?: boolean;
  message?: string | ReactNode;
  type?: ToastType;
  offset?: number;
  children?: ReactNode;
  className?: string;
  onClose?: () => void;
}

const getIconSpecsOnType = (type: ToastType) => {
  switch (type) {
    case 'info':
      return { icon: <InfoRegularIcon />, color: 'primary' };
    case 'success':
      return { icon: <CheckRegularIcon />, color: 'success' };
    case 'error':
      return { icon: <WarningRegularIcon />, color: 'danger' };
    default:
      return { icon: <InfoRegularIcon />, color: 'primary' };
  }
};

export function Toast({
  isVisible = true,
  message = '',
  type = 'info',
  offset = 16,
  className,
  children,
  onClose,
}: ToastProps): ReactNode {
  const { icon, color } = getIconSpecsOnType(type);
  return (
    <div
      className={cx(
        styles.Wrapper,
        {
          [styles.Hidden]: !isVisible,
          // [styles.Info]: type === 'info',
          // [styles.Success]: type === 'success',
          // [styles.Error]: type === 'error',
        },
        className,
      )}
      style={{ marginBottom: offset }}
    >
      <div className={styles.Card}>
        <div className={styles.InnerWrapper}>
          <TypographyIcon icon={icon} color={color as any} />
          <Typography.Text>{message || children}</Typography.Text>
          <div className={styles.CloseIcon}>
            <IconButton icon={<XRegularIcon />} onPress={onClose} />
          </div>
        </div>
      </div>
    </div>
  );
}
