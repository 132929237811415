import { LargeLoader } from '@ornikar/kitt';
import type { ReactNode } from 'react';
import styles from './styles.module.css';

export function PageLoader(): ReactNode {
  return (
    <div className={styles.PageLoader}>
      <LargeLoader />
    </div>
  );
}
