import type {
  AssureDepuisType,
  Civility,
  DejaAssureAvecNomType,
  FinancementType,
  HamonWantType,
  LicenseType,
  LogementType,
  MaritalStatus,
  ParkingType,
  Profession,
  TitulaireCarteGriseType,
  UsageType,
} from '../apis/types/LongQuoteAnswers';
import type { PaymentPeriodicity } from '../apis/types/PaymentOptions';
import type { Version } from '../apis/vehicules';
import type { ChoiceWithLabel, ChoiceWithRender } from '../components/RadioBlockGroup';
import type { CountryCode } from '../utils/country';

export type DateType = string;

export enum YesNoQuestion {
  YES = 'YES',
  NO = 'NO',
}

// Type declarations for fields useful to subscription flow (not handled on back-end side)
export enum InsuranceSituationType {
  alreadyCovered = '1',
  firstVehicle = '2',
  brandNewVehicle = '3',
  otherVehicle = '4',
}

export enum RegistrationDocumentOwnerIsSubscriberType {
  Oui = 'yes',
  Non = 'no',
}

export enum ServerAncienneAssuranceType {
  No = 4,
  Less1year = 6,
  Continu12 = 5,
  Continu24 = 7,
  Continu36 = 1,
  Interrompu = 2,
}

export enum DriverHasBeenCoveredNegative {
  NonJamais = 'NonJamais',
  NonSurPeriode = 'NonSurPeriode',
}

export enum DriverHasBeenCoveredPositive {
  OuiPartiellement = 'OuiPartiellement',
  OuiEnContinu = 'OuiEnContinu',
}

export type DriverHasBeenCovered = DriverHasBeenCoveredNegative | DriverHasBeenCoveredPositive;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DriverHasBeenCovered = { ...DriverHasBeenCoveredNegative, ...DriverHasBeenCoveredPositive };

export enum SecondaryRelationType {
  Conjoint = '2',
  Enfants = '3',
  Entourage = '6',
}

export enum VehiculePreSelectionType {
  LICENSE_PLATE = 0,
  BRAND = 1,
}

export interface Period {
  start: DateType;
  end: DateType;
}

export enum VehicleCurrentContractAnniversaryMonthType {
  January = 1,
  February = 2,
  March = 3,
  April = 4,
  May = 5,
  June = 6,
  July = 7,
  August = 8,
  September = 9,
  October = 10,
  November = 11,
  December = 12,
}

export interface VehicleFsmAnswers {
  vehiculePreSelectionType: VehiculePreSelectionType;
  vehiculeImmatriculation: string; // duplicated in PaymentInformationsFsmAnswers
  vehiculeMarque: string;
  vehiculeModele: string;
  vehiculePuissanceFiscale: number;
  vehiculeCarburant: string;
  vehiculeIsUtility: boolean;
  vehiculeMiseEnCirculation: string;
  vehiculeVersion: string; // SRA code
  vehiculeVersionOptions: Version[];
  vehiculeVersionName: string;
  vehiculeAcquisition: DateType;
  dateEffetSouhaite: DateType;
  vehiculeDejaAssureAvecNom: DejaAssureAvecNomType;
  vehiculeAssureDepuis: AssureDepuisType;
  vehicleLastInsuranceEndDate: DateType;
  vehiculeFinancement: FinancementType;
  vehicleCurrentContractAnniversaryMonth?: VehicleCurrentContractAnniversaryMonthType;
}

export interface DrivingFsmAnswers {
  usage: UsageType;
  kilometersPerYear: number;
  parkingLocation: ParkingType;
  parkingCodePostal: string;
  parkingCommune: string;
  housingType: LogementType;
  isPropertyOwner: YesNoQuestion;
  registrationDocumentOwnerIsSubscriber: RegistrationDocumentOwnerIsSubscriberType; // not back-end
  titulaireCartegrise: TitulaireCarteGriseType;
}

export enum InsuranceRecordType {
  Termination = 'termination',
  CancellationOrSuspension = 'cancellationOrSuspension',
  Claim = 'claim',
}

export enum CancellationOrSuspensionOrClaim {
  Suspension = 'suspension',
  Cancellation = 'cancellation',
  Claim = 'claim',
}

export enum ClaimQuantity {
  None = 'None',
  One = 'One',
  Many = 'Many',
}

export enum ClaimNature {
  BodilyAccident = 'BodilyAccident',
  MaterialAccident = 'MaterialAccident',
  Theft = 'Theft',
  Fire = 'Fire',
  BrokenGlass = 'BrokenGlass',
  Other = 'Other',
}

export enum DriverType {
  Myself = 'Myself',
  AnotherDriver = 'AnotherDriver',
}

export enum ResponsibilityLevel {
  Responsible = 'Responsible',
  PartiallyResponsible = 'PartiallyResponsible',
  NonResponsible = 'NonResponsible',
}

export enum UnderTheInfluenceOf {
  None = 'None',
  Alcohol = 'Alcohol',
  Drugs = 'Drugs',
  AlcoholAndDrugs = 'AlcoholAndDrugs',
}

export enum BloodAlcoholLevel {
  From200To799mgPerLiter = 'From200To799mgPerLiter',
  From800To1999mgPerLiter = 'From800To1999mgPerLiter',
  From2000mgPerLiter = 'From2000mgPerLiter',
}

export interface ClaimRecord {
  type: InsuranceRecordType.Claim;
  nature: ClaimNature;
  date: DateType;
  driver: DriverType;
  responsibilityLevel: ResponsibilityLevel;
  underTheInfluenceOf: UnderTheInfluenceOf;
  bloodAlcoholLevel?: BloodAlcoholLevel;
}

export enum WithdrawalType {
  Cancellation = 'Cancellation',
  Suspension = 'Suspension',
}

export enum SuspensionDuration {
  LessThanOneMonth = 'LessThanOneMonth',
  OneMonth = 'OneMonth',
  TwoMonths = 'TwoMonths',
  ThreeMonths = 'ThreeMonths',
  FourMonths = 'FourMonths',
  FiveMonths = 'FiveMonths',
  SixMonths = 'SixMonths',
  SevenMonths = 'SevenMonths',
  EightMonths = 'EightMonths',
  NineMonths = 'NineMonths',
  TenMonths = 'TenMonths',
  ElevenMonths = 'ElevenMonths',
  TwelveMonths = 'TwelveMonths',
  ThirteenMonths = 'ThirteenMonths',
  FourteenMonths = 'FourteenMonths',
  FifteenMonths = 'FifteenMonths',
  SixteenMonths = 'SixteenMonths',
  SeventeenMonths = 'SeventeenMonths',
  EighteenMonthsOrMore = 'EighteenMonthsOrMore',
}

export enum AccidentType {
  None = 'None',
  BodilyAccident = 'BodilyAccident',
  MaterialAccident = 'MaterialAccident',
}

export enum CancellationOrSuspensionReason {
  LackOfPoints = 'LackOfPoints',
  Speeding = 'Speeding',
  DrivingUnderInfluenceOfAlcohol = 'DrivingUnderInfluenceOfAlcohol',
  DrivingUnderInfluenceOfDrugs = 'DrivingUnderInfluenceOfDrugs',
  DrivingUnderInfluenceOfAlcoholAndDrugs = 'DrivingUnderInfluenceOfAlcoholAndDrugs',
  Other = 'Other',
}

export interface CancellationOrSuspensionRecord {
  type: InsuranceRecordType.CancellationOrSuspension;
  id?: string;
  withdrawalType?: WithdrawalType;
  date: DateType;
  suspensionDuration?: SuspensionDuration;
  accident?: AccidentType;
  reason: CancellationOrSuspensionReason;
  bloodAlcoholLevel?: BloodAlcoholLevel;
  isHitAndRun?: YesNoQuestion;
  claimRecord?: ClaimRecord;
}

export enum TerminationReason {
  NonPayment = 'NonPayment',
  DriversLicenseSuspension = 'DriversLicenseSuspension',
  DriversLicenseCancellation = 'DriversLicenseCancellation',
  Claims = 'Claims',
  IncompleteSupportingDocumentation = 'IncompleteSupportingDocumentation',
  FalseDeclaration = 'FalseDeclaration',
  Other = 'Other',
}

export enum ServerUninsuredVehicleOwnershipDuration {
  LessThanTwoMonths = 'LessThanTwoMonths',
  BetweenTwoAndFiveMonths = 'BetweenTwoAndFiveMonths',
  MoreThanFiveMonths = 'MoreThanFiveMonths',
}

export enum UninsuredVehicleOwnershipDuration {
  LessThanOneMonth = 'LessThanOneMonth',
  OneMonth = 'OneMonth',
  TwoMonths = 'TwoMonths',
  ThreeMonths = 'ThreeMonths',
  FourMonths = 'FourMonths',
  FiveMonths = 'FiveMonths',
  SixMonthsOrMore = 'SixMonthsOrMore',
}

export enum NonPaymentTerminationInsurerCount {
  One = 'One',
  Two = 'Two',
  ThreeAndMore = 'ThreeAndMore',
}

export interface TerminationRecord {
  type: InsuranceRecordType.Termination;
  date: DateType;
  reason: TerminationReason;
  duration: UninsuredVehicleOwnershipDuration;
  terminationCount?: NonPaymentTerminationInsurerCount;
  cancellationOrSuspensionRecord?: CancellationOrSuspensionRecord;
}

export interface DriverInsuranceHistory {
  cancellationOrSuspensionRecords: CancellationOrSuspensionRecord[];
  hasBeenCancelledOrSuspended: WithdrawalType | YesNoQuestion.NO;
  claimQuantity: ClaimQuantity;
  claimRecords: ClaimRecord[];
  terminationRecords: TerminationRecord[];
  hasTermination: YesNoQuestion;
}

export interface DriverAnswers {
  firstName?: string;
  lastName?: string;
  civility?: Civility;
  birthDate?: DateType;
  birthCity?: string;
  birthCountryCode?: CountryCode;
  profession?: Profession;
  maritalStatus?: MaritalStatus;
  accompaniedDriving?: YesNoQuestion;
  licenseType?: LicenseType;
  licenseDate?: DateType;
  hasBeenCovered?: DriverHasBeenCovered; // not back-end
  insurancePeriods?: Period[]; // not backend
  crm?: number;
  yearsSinceHighestBonus?: number;
  // RAG
  hasTermination?: YesNoQuestion;
  claimQuantity?: ClaimQuantity;
  terminationRecords?: TerminationRecord[];
  hasBeenCancelledOrSuspended?: WithdrawalType | YesNoQuestion.NO;
  cancellationOrSuspensionRecords?: CancellationOrSuspensionRecord[];
  claimRecords?: ClaimRecord[];
}

export interface InformationFsmAnswers {
  // PRIMARY INFORMATIONS (end of subscription flow)
  primaryDriver: DriverAnswers;
  secondaryDriver: DriverAnswers;

  declareSecondaryDriver: DeclareSecondaryDriverType;
  secondaryRelation: SecondaryRelationType;

  dateEffetSouhaite: DateType;
  subscriberFirstName: string;
  subscriberLastName: string;
  subscriberCivilite: Civility;
  subscriberEmail: string;
  subscriberPhone: string;
  subscriberCompleteAdress: string;
  subscriberAdress1: string;
  subscriberAdress2: string;
  subscriberAdress3: string;
  subscriberCodePostal: string;
  subscriberCommune: string;
  privacyPolicyAccepted: true;
  hasAcceptedCommercialCommunication?: boolean;
  hasAcceptedPartnersCommunication?: boolean;
  hasDoneRevalidation?: boolean;
}

export enum DeclareSecondaryDriverType {
  Oui = 'yes',
  Non = 'no',
}

export enum AddonName {
  OML_ASSISTANCE_0_KM_ADDON = 'OMLAssistance0km',
  OML_VEHICLE_REPLACEMENT = 'OMLVehicleReplacement',
  OML_DRIVER_PROTECTION_500 = 'OMLDriverProtection500',
  OML_VEHICLE_LOAN = 'OMLExpandedDriving',
}

export enum DeductibleName {
  OML_WIND_SCREEN = 'OMLWindScreen',
  OML_FIRE_THEFT_DTA = 'OMLFireTheftDTA',
}

export interface Addon {
  name: AddonName;
  term: string | null;
  isSelected?: boolean;
}

export interface DeductibleTerm {
  name: string;
  value: string;
  packageSubtype: string;
}

export interface Deductible {
  name: DeductibleName;
  term: DeductibleTerm;
}

export interface QuoteFsmAnswers {
  formulePicked: string;
  optionsSelected: string[];
  addons: Partial<Record<AddonName, Addon>>;
  deductibles: Partial<Record<DeductibleName, Deductible>>;
}

export enum HamonFormTypeEnum {
  AutoComplete = 'AutoComplete',
  Legacy = 'Legacy',
  Manual = 'Manual',
}

export interface InsuranceFsmAnswers {
  // HAMON
  hamonWant: HamonWantType;
  hamonContractRef: string;
  hamonName: string; // champ 'nom' d'une adresse postale
  hamonCompleteAdress: string;
  hamonAutoCompletedAddress: hamonAutoCompletedAddressType;
  hamonAdress: string;
  hamonCodePostal: string;
  hamonCommune: string;
  hamonCedex?: string;
  hamonFormType: HamonFormTypeEnum;
  // PAYMENT
  vehiculeImmatriculation: string; // duplicated in VehiculeSelectionFsmAnswers
  kilometrageCompteur: number;
  periodicity: PaymentPeriodicity;
  ibanNumber: string;
  ibanTitulaire: string;
  checkboxesVersion: number;
  chosenPaymentOption: string;
  legalCheckbox1: boolean;
  legalCheckbox2: boolean;
  legalCheckbox3: boolean;
  legalCheckbox4: boolean;
}

export interface hamonAutoCompletedAddressType {
  formattedAddress: string;
  street: string;
  zipCode: string;
  city: string;
}

export type LongQuoteAnswers = VehicleFsmAnswers &
  DrivingFsmAnswers &
  InformationFsmAnswers &
  QuoteFsmAnswers &
  InsuranceFsmAnswers;

export function makeChoices<EnumType extends string>(
  enumObject: Record<string, EnumType>,
  choices: [EnumType, ChoiceWithLabel['label'] | ChoiceWithRender['render']][],
): (ChoiceWithLabel<EnumType> | ChoiceWithRender<EnumType>)[] {
  if (import.meta.env.DEV) {
    Object.keys(enumObject).forEach((enumValue) => {
      if (!choices.some(([value]) => value === enumObject[enumValue])) {
        throw new Error(`Missing key ${enumValue}`);
      }
    });
  }

  return choices.map(([value, labelOrRender]) =>
    typeof labelOrRender === 'function'
      ? { value, render: labelOrRender as ChoiceWithRender['render'] }
      : { value, label: labelOrRender },
  );
}
