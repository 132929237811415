import { Button, Modal, Typography } from '@ornikar/kitt';
import type { ReactNode } from 'react';
import { useForm } from 'react-final-form';
import { delayPromise } from '../../utils/promise';
import styles from './styles.module.css';

interface LoiHamonModalProps {
  visible: boolean;
  onClose: () => void;
}

export function LoiHamonModal({ visible, onClose }: LoiHamonModalProps): ReactNode {
  const { submit } = useForm();

  return (
    <Modal visible={visible} onClose={onClose}>
      <Modal.Header>
        <Typography.h5 variant="bold">Votre contrat actuel a moins d&apos;un an</Typography.h5>
      </Modal.Header>
      <Modal.Body>
        Nous pouvons vous afficher votre tarif sur-mesure, mais il se peut que nous ne puissions pas vous assurer avant
        que votre contrat actuel ne fête ses 1 an.
        <br />
        <br />
        En revanche, dans certains cas nous pouvons d&apos;ores et déjà vous assurer.
        <br />
        <br />
        N&apos;hésitez pas à nous contacter après l&apos;obtention de votre tarif si vous ne pouvez pas souscrire en
        ligne.
      </Modal.Body>
      <Modal.Footer>
        <div className={styles.ModalFooter}>
          <Button
            type="primary"
            onClick={async () => {
              /**
               * @devs delay is here to fix a desynchronization issue with `submitting` final-form state
               * Solution found here (different issue, same solution) -> https://github.com/final-form/react-final-form/issues/761#issuecomment-603163705
               */
              delayPromise(submit);
            }}
          >
            J&apos;ai compris
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
