import type { ReactNode } from 'react';
import { ClaimRecordDriverTemplate } from '../../../../components/ScreenTemplates/InformationTemplates/InsuranceRecordTemplates/ClaimRecordTemplates/ClaimRecordDriverTemplate';
import { DriverType } from '../../../../fsm/answers';
import { useSubscriptionFsmState } from '../../../../fsm/context';

export function SecondaryClaimRecordDriverScreen(): ReactNode {
  const {
    context: { editingInsuranceRecord },
  } = useSubscriptionFsmState();

  return (
    <ClaimRecordDriverTemplate
      fieldName={`secondaryDriver.claimRecords[${editingInsuranceRecord.index}].driver`}
      title="Qui était au volant&nbsp;?"
      choices={[
        { label: 'Lui-même', value: DriverType.Myself },
        { label: 'Un autre conducteur', value: DriverType.AnotherDriver },
      ]}
    />
  );
}
