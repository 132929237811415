import isEmpty from 'lodash/isEmpty';
import {
  AccidentType,
  CancellationOrSuspensionReason,
  ClaimNature,
  ClaimQuantity,
  DriverType,
  InsuranceRecordType,
  TerminationReason,
  UnderTheInfluenceOf,
  WithdrawalType,
  YesNoQuestion,
} from '../answers';
import type { AnswerEvent, Context, Driver, EditInsuranceRecordEvent, Events } from '../types';
import { isAnswerEquals, not } from './utils';

/** CLAIMS */

/**
 * If the driver declared at least one claim
 */
export const doesDriverDeclaredAtLeastOneClaim = (driver: Driver): ((context: Context, event: Events) => boolean) =>
  isAnswerEquals(`${driver}Driver.claimQuantity`, (answer) => answer !== ClaimQuantity.None);

export const isClaimCausedByAnAccident = (driver: Driver): ((context: Context, event: Events) => boolean) =>
  isAnswerEquals(
    ({ editingInsuranceRecord: { index } }) => `${driver}Driver.claimRecords[${index}].nature`,
    (answer) => answer === ClaimNature.BodilyAccident || answer === ClaimNature.MaterialAccident,
  );

export const isClaimNotCausedByAnAccident = (driver: Driver): ((context: Context, event: Events) => boolean) =>
  not(isClaimCausedByAnAccident(driver));

/**
 * If the driver declared multiple claims
 */
export const doesDriverDeclaredMultipleClaims = (driver: Driver): ((context: Context, event: Events) => boolean) =>
  isAnswerEquals(`${driver}Driver.claimQuantity`, (answer) => answer === ClaimQuantity.Many);

export const isClaimDriverBehindTheWheel = (driver: Driver): ((context: Context, event: Events) => boolean) =>
  isAnswerEquals(
    ({ editingInsuranceRecord: { index } }) => `${driver}Driver.claimRecords[${index}].driver`,
    (answer) => answer === DriverType.Myself,
  );

export const isClaimAnotherDriverBehindTheWheel = (driver: Driver): ((context: Context, event: Events) => boolean) =>
  isAnswerEquals(
    ({ editingInsuranceRecord: { index } }) => `${driver}Driver.claimRecords[${index}].driver`,
    (answer) => answer === DriverType.AnotherDriver,
  );

export const isClaimUnderTheInfluenceOfAlcohol = (driver: Driver): ((context: Context, event: Events) => boolean) =>
  isAnswerEquals(
    ({ editingInsuranceRecord: { index } }) => `${driver}Driver.claimRecords[${index}].underTheInfluenceOf`,
    (answer) => answer === UnderTheInfluenceOf.Alcohol || answer === UnderTheInfluenceOf.AlcoholAndDrugs,
  );

export const hasDeclaredCancellationRecords = (driver: Driver): ((context: Context, event: Events) => boolean) =>
  isAnswerEquals(`${driver}Driver.cancellationOrSuspensionRecords`, (answer) => Boolean(answer?.length));

export const isAddingAnotherCancellationOrSuspension = (
  driver: Driver,
): ((context: Context, event: Events) => boolean) =>
  isAnswerEquals(`${driver}Driver.cancellationOrSuspensionRecords`, (answer) => answer?.length > 1);

// CANCELLATION
export const isRecordASuspension = (driver: Driver): ((context: Context, event: Events) => boolean) =>
  isAnswerEquals(
    ({ editingInsuranceRecord: { index } }) =>
      `${driver}Driver.cancellationOrSuspensionRecords[${index}].withdrawalType`,
    (answer) => answer === WithdrawalType.Suspension,
  );

export const isNextRecordASuspension = (driver: Driver): ((context: Context, event: Events) => boolean) =>
  isAnswerEquals(
    ({ editingInsuranceRecord: { index } }) =>
      `${driver}Driver.cancellationOrSuspensionRecords[${index + 1}].withdrawalType`,
    (answer) => answer === WithdrawalType.Suspension,
  );

export const hasBeenCancelledOrSuspended =
  (driver: Driver) =>
  (_: Context, event: AnswerEvent): boolean => {
    const value = event.answers?.[`${driver}Driver`]?.hasBeenCancelledOrSuspended;
    return value === WithdrawalType.Cancellation || value === WithdrawalType.Suspension;
  };

export const isCancellationRecordRelatedToAClaim = (driver: Driver): ((context: Context, event: Events) => boolean) =>
  isAnswerEquals(
    ({ editingInsuranceRecord: { index } }) => `${driver}Driver.cancellationOrSuspensionRecords[${index}].accident`,
    (answer) => answer !== AccidentType.None,
  );

export const isCancellationClaimUnderTheInfluenceOfAlcohol = (
  driver: Driver,
): ((context: Context, event: Events) => boolean) =>
  isAnswerEquals(
    ({ editingInsuranceRecord: { index } }) =>
      `${driver}Driver.cancellationOrSuspensionRecords[${index}].claimRecord.underTheInfluenceOf`,
    (answer) => answer === UnderTheInfluenceOf.Alcohol || answer === UnderTheInfluenceOf.AlcoholAndDrugs,
  );

export const doesCancellationRecordInvolveAlcohol = (driver: Driver): ((context: Context, event: Events) => boolean) =>
  isAnswerEquals(
    ({ editingInsuranceRecord: { index } }) => `${driver}Driver.cancellationOrSuspensionRecords[${index}].reason`,
    (answer) =>
      answer === CancellationOrSuspensionReason.DrivingUnderInfluenceOfAlcohol ||
      answer === CancellationOrSuspensionReason.DrivingUnderInfluenceOfAlcoholAndDrugs,
  );

export const hasPreviousInsuranceRecord =
  (driver: Driver, record: InsuranceRecordType) =>
  ({ editingInsuranceRecord }: Context): boolean =>
    editingInsuranceRecord.driver === driver &&
    editingInsuranceRecord.recordType === record &&
    editingInsuranceRecord.index > 0;

// TERMINATION
export const hasTerminationRecord = (driver: Driver): ((context: Context, event: Events) => boolean) =>
  isAnswerEquals(`${driver}Driver.hasTermination`, (answer) => answer === YesNoQuestion.YES);

export const isTerminatedForNonPayment = (driver: Driver): ((context: Context, event: Events) => boolean) =>
  isAnswerEquals(`${driver}Driver.terminationRecords[0].reason`, (answer) => answer === TerminationReason.NonPayment);

export const isTerminatedForSuspension = (driver: Driver): ((context: Context, event: Events) => boolean) =>
  isAnswerEquals(
    `${driver}Driver.terminationRecords[0].reason`,
    (answer) => answer === TerminationReason.DriversLicenseSuspension,
  );

export const isTerminatedForCancellation = (driver: Driver): ((context: Context, event: Events) => boolean) =>
  isAnswerEquals(
    `${driver}Driver.terminationRecords[0].reason`,
    (answer) => answer === TerminationReason.DriversLicenseCancellation,
  );

export const isTerminationCancellationRelatedToAClaim = (
  driver: Driver,
): ((context: Context, event: Events) => boolean) =>
  isAnswerEquals(
    `${driver}Driver.terminationRecords[0].cancellationOrSuspensionRecord.accident`,
    (answer) => answer !== AccidentType.None,
  );

export const doesTerminationCancellationRecordInvolveAlcohol = (
  driver: Driver,
): ((context: Context, event: Events) => boolean) =>
  isAnswerEquals(
    `${driver}Driver.terminationRecords[0].cancellationOrSuspensionRecord.reason`,
    (answer) =>
      answer === CancellationOrSuspensionReason.DrivingUnderInfluenceOfAlcohol ||
      answer === CancellationOrSuspensionReason.DrivingUnderInfluenceOfAlcoholAndDrugs,
  );

export const isTerminationCancellationClaimUnderTheInfluenceOfAlcohol = (
  driver: Driver,
): ((context: Context, event: Events) => boolean) =>
  isAnswerEquals(
    `${driver}Driver.terminationRecords[0].cancellationOrSuspensionRecord.claimRecord.underTheInfluenceOf`,
    (answer) => answer === UnderTheInfluenceOf.Alcohol || answer === UnderTheInfluenceOf.AlcoholAndDrugs,
  );

export const hasDeclaredTermination = (driver: Driver): ((context: Context, event: Events) => boolean) =>
  isAnswerEquals(`${driver}Driver.terminationRecords`, (answer) => Boolean(answer?.length));

// INSURANCE RECORD
export const isEditingRecordAClaim = (context: Context, event: EditInsuranceRecordEvent): boolean => {
  return event.recordType === (InsuranceRecordType.Claim as string);
};

export const isEditingRecordASuspension =
  (driver: Driver) =>
  (context: Context, event: EditInsuranceRecordEvent): boolean => {
    const record = context.answers[`${driver}Driver`]?.cancellationOrSuspensionRecords?.[event.recordIndex];

    return (
      event.recordType === (InsuranceRecordType.CancellationOrSuspension as string) &&
      !isEmpty(record) &&
      record?.withdrawalType === WithdrawalType.Suspension
    );
  };
