import type { ReactNode } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { RadioScreenTemplate } from '../../../components/ScreenTemplates/RadioScreenTemplate';
import { vehicleUsageOptionsList } from '../../../constants/mappers/driving';
import { Driver } from '../../../fsm/types';
import { useIsLicenseDateInFuture } from '../../../hooks/useIsLicenseDateInFuture';
import { FormattedMessageWithRichTextFormatting } from '../../../utils/intl';

const messages = defineMessages({
  highlightTitle: {
    id: 'drivingVehicleUsageScreen.highlight.title',
    defaultMessage: 'Quel usage renseigner\u00A0?',
  },
  highlightLeisure: {
    id: 'drivingVehicleUsageScreen.highlight.leisure',
    defaultMessage:
      '<b>Loisirs</b>\u00A0: le véhicule est utilisé uniquement pour des déplacements personnels (exemple : aller à la salle de sport).',
  },
  highlightLeisureAndWorkHomeTrips: {
    id: 'drivingVehicleUsageScreen.highlight.leisureAndWorkHomeTrips',
    defaultMessage:
      '<b>Loisirs et trajets maison / travail</b>\u00A0: le véhicule est utilisé pour les loisirs et les allers-retours entre le domicile et le travail.',
  },
  highlightLeisureAndOccasionalBusinessTrips: {
    id: 'drivingVehicleUsageScreen.highlight.leisureAndOccasionalBusinessTrips',
    defaultMessage:
      '<b>Loisirs et usage professionnel occasionnel</b>\u00A0: le véhicule est utilisé pour les loisirs, les trajets maison / travail et quelques déplacements professionnels sur les heures de travail, de manière occasionnelle.',
  },
  highlightFrequentBusinessTripsAndTechnicalInterventions: {
    id: 'drivingVehicleUsageScreen.highlight.frequentBusinessTripsAndTechnicalInterventions',
    defaultMessage:
      '<b>Tournées de clientèles et déplacements techniques réguliers</b>\u00A0: le véhicule est utilisé pour des déplacements professionnels réguliers, des prestations à domicile, des tournées de clientèle, etc.',
  },
});

export function DrivingVehicleUsageScreen(): ReactNode {
  const isLicenseDateInFuture = useIsLicenseDateInFuture(Driver.Primary);
  const title = isLicenseDateInFuture
    ? {
        id: 'drivingVehicleUsageScreen.title.future',
        defaultMessage: 'Dans quel cadre utiliserez-vous votre véhicule\u00A0?',
      }
    : {
        id: 'drivingVehicleUsageScreen.title',
        defaultMessage: 'Dans quel cadre utilisez-vous votre véhicule\u00A0?',
      };

  return (
    <RadioScreenTemplate
      title={<FormattedMessage {...title} />}
      highlightTitle={<FormattedMessage {...messages.highlightTitle} />}
      highlightContent={
        <ul>
          <li>
            <FormattedMessageWithRichTextFormatting {...messages.highlightLeisure} />
          </li>
          <li>
            <FormattedMessageWithRichTextFormatting {...messages.highlightLeisureAndWorkHomeTrips} />
          </li>
          <li>
            <FormattedMessageWithRichTextFormatting {...messages.highlightLeisureAndOccasionalBusinessTrips} />
          </li>
          <li>
            <FormattedMessageWithRichTextFormatting
              {...messages.highlightFrequentBusinessTripsAndTechnicalInterventions}
            />
          </li>
        </ul>
      }
      fieldName="usage"
      choices={vehicleUsageOptionsList}
    />
  );
}
