import type { ReactNode } from 'react';
import { CallbackErrorView } from './CallbackErrorView';
import { CallbackSuccessView } from './CallbackSuccessView';

export enum CallbackStatusEnum {
  Success = 'Success',
  Error = 'Error',
}

interface CallbackModalProps {
  status: CallbackStatusEnum;
  timeSlotRangeInMinutes: number;
  booking?: Date;
}

export function CallbackStatusView({ status, timeSlotRangeInMinutes, booking }: CallbackModalProps): ReactNode {
  return status === CallbackStatusEnum.Success ? (
    <CallbackSuccessView booking={booking} timeSlotRangeInMinutes={timeSlotRangeInMinutes} />
  ) : (
    <CallbackErrorView />
  );
}
