import { REGISTRATION_DOCUMENT_PARTNER_LINK } from '../../constants/flagshipKeys';
import { getFsFlag } from '../../setup/flagship';

export const isRegistrationDocumentPartnerLinkDisplayed = (): boolean => {
  const registrationDocumentPartnerLinkDisplayFlag = getFsFlag<boolean>(REGISTRATION_DOCUMENT_PARTNER_LINK, false, {
    shouldSendVariationToMixpanel: true,
  });

  return registrationDocumentPartnerLinkDisplayFlag.getValue();
};
