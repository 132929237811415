import type { LongQuoteAnswers } from '../fsm/answers';
import { api } from './api';
import { mapServerRequestToLongQuoteAnswers } from './mappers/longQuoteAnswersMapper';
import type { ServerLongQuoteRetrieve } from './types/LongQuoteAnswers';
import { getVersionDetails } from './vehicules';

export const getRemoteQuoteFromCoreApi = async (
  quoteId: string | number,
  leadId: string,
): Promise<Partial<LongQuoteAnswers>> => {
  const longQuote = await api<ServerLongQuoteRetrieve>(`api/v1/quotes/${quoteId}?lead_id=${leadId}`);
  const versionDetails = await getVersionDetails(longQuote.codeAuto);

  return mapServerRequestToLongQuoteAnswers({
    ...longQuote,
    ...versionDetails,
  });
};
