import type { ReactNode } from 'react';
import { InformationBonusMalusTemplate } from '../../../../components/ScreenTemplates/InformationTemplates/InformationBonusMalusTemplate';
import { useSubscriptionFsmState } from '../../../../fsm/context';
import { Driver } from '../../../../fsm/types';

export function PrimaryBonusMalusScreen(): ReactNode {
  const { context } = useSubscriptionFsmState();
  return (
    <InformationBonusMalusTemplate
      driverType={Driver.Primary}
      licenseDate={context.answers.primaryDriver?.licenseDate}
    />
  );
}
