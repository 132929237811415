import type { ReactNode } from 'react';
import { AddonScreenTemplate } from '../../../components/ScreenTemplates/AddonScreenTemplate';
import { AddonName } from '../../../fsm/answers';
import { useSendPageNameToSegment } from '../../../providers/AnalyticsProvider';
import { DriverProtection500Card } from './DriverProtection500Card';

export function DriverProtection500Screen(): ReactNode {
  useSendPageNameToSegment();
  return (
    <AddonScreenTemplate addonName={AddonName.OML_DRIVER_PROTECTION_500}>
      <DriverProtection500Card />
    </AddonScreenTemplate>
  );
}
