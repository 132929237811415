import type { ServerAncienneAssuranceType, VehicleCurrentContractAnniversaryMonthType } from '../../fsm/answers';
import type { CountryCode } from '../../utils/country';
import type { ServerInsuranceRecord } from './InsuranceRecord';

export enum Profession {
  SansProfession = '4',
  Etudiant = '10',
  Salarié = '1',
  Fonctionnaire = '2',
  ProfessionLibérale = '5',
  Entrepreneur = '6',
  Artisan = '7',
  Commercant = '8',
  Agriculteur = '9',
  Ecclésiastique = '11',
  Retraité = '3',
  VRP = '12',
}

export enum ServerProfessionType {
  SansProfession = 4,
  Etudiant = 10,
  Salarié = 1,
  Fonctionnaire = 2,
  ProfessionLibérale = 5,
  Entrepreneur = 6,
  Artisan = 7,
  Commercant = 8,
  Agriculteur = 9,
  Ecclésiastique = 11,
  Retraité = 3,
  VRP = 12,
}

export enum AncienneAssuranceType {
  No = '4',
  Less1year = '6',
  Continu12 = '5',
  Continu24 = '7',
  Continu36 = '1',
  Interrompu = '2',
}

export type APIDate = string; // yyyy-MM-dd

export enum LicenseType {
  french = '1',
  european = '2',
  other = '3',
}

export enum ServerLicenseType {
  french = 1,
  european = 2,
  other = 3,
}

export enum UsageType {
  leisure = '1',
  leisureAndWorkHomeTrips = '2',
  leisureAndOccasionalBusinessTrips = '3', // anciennement mergé avec frequentBusinessTripsAndTechnicalInterventions
  frequentBusinessTripsAndTechnicalInterventions = '4',
}

export enum ServerUsageType {
  leisure = 1,
  leisureAndWorkHomeTrips = 2,
  // leisureAndOccasionalBusinessTrips = 3, mergé avec frequentBusinessTripsAndTechnicalInterventions
  frequentBusinessTripsAndTechnicalInterventions = 4,
}

export enum ParkingType {
  public = '1',
  parking = '2',
  garage = '3',
}

export enum ServerParkingType {
  public = 1,
  parking = 2,
  garage = 3,
}

export enum FonctionVehicule {
  Actuelle = 1,
  Remplacement = 2,
  Supplementaire = 3,
  Premiere = 4,
}

export enum FinancementType {
  comptant = '1',
  credit = '2',
  LLD = '3',
  LOA = '4',
  Don = '5',
}

export enum ServerFinancementType {
  comptant = 1,
  credit = 2,
  LLD = 3,
  LOA = 4,
  Don = 5,
}

export enum ConducteurPrincipalType {
  Vous = '1',
  VousEtConjoint = '2',
  VousEtEnfant = '3',
  Famille = '6',
}

export enum ServerConducteurPrincipalType {
  Vous = 1,
  VousEtConjoint = 2,
  VousEtEnfant = 3,
  Famille = 6,
}

export enum TitulaireCarteGriseType {
  Souscripteur = '1',
  Société = '2',
  SouscripteurOuConjoint = '3',
  SocieteLeasing = '4',
  SouscripteurEtConjoint = '5',
  Parents = '6',
  ParentsConjoint = '7',
  Autre = '8',
}

export enum ServerTitulaireCarteGriseType {
  Souscripteur = 1,
  Société = 2,
  SouscripteurOuConjoint = 3,
  SocieteLeasing = 4,
  SouscripteurEtConjoint = 5,
  Parents = 6,
  ParentsConjoint = 7,
  Autre = 8,
}

export enum DejaAssureType {
  Oui = '1',
  Non = '0',
}

export enum DejaAssureAvecNomType {
  Non = '0',
  OuiAMonNom = '1',
  OuiPasAMonNom = '2',
}

export enum AssureDepuisType {
  Moins12Mois = '1',
  Plus12Mois = '2',
}

export enum ServerDejaAssureType {
  OuiPasAMonNom = 2,
  OuiAMonNom = 1,
  Non = 0,
}

export enum MaritalStatus {
  Célibataire = '3',
  Marié = '1',
}

export enum ServerSituationMaritaleType {
  Célibataire = 3,
  Marié = 1,
}

export enum Civility {
  Homme = '1',
  Femme = '2',
}

export enum ServerSexeType {
  Homme = 1,
  Femme = 2,
}

export enum LogementType {
  Appartement = '1',
  Maison = '2',
  Autre = '3',
}

export enum ServerLogementType {
  Appartement = 1,
  Maison = 2,
  Autre = 3,
}

export enum ServerProprieteType {
  Proprietaire = 1,
  Locataire = 2,
  Autre = 3,
}

export enum ServerAccompaniedDrivingType {
  Yes = 1,
  No = 0,
}

export enum HamonWantType {
  Yes = '1',
  No = '0',
}

export enum PeriodicityType {
  annuel = 'annuel',
  mensuel = 'mensuel',
  semestriel = 'semestriel',
}

export enum ServerVehicleInsuredForType {
  LessThan12Months = 'LESS_THAN_12_MONTHS',
  MoreThan12Months = 'MORE_THAN_12_MONTHS',
}

export interface ServerInsurancePeriod {
  startDate: APIDate;
  endDate: APIDate;
}

export interface ServerLongQuote {
  // vehicle
  codeAuto: string;
  licensePlate?: string;

  circulationDate: APIDate;
  vehiclePurchase: FonctionVehicule;
  acquisitionDate: APIDate;
  financing: ServerFinancementType;
  alreadyInsured?: ServerDejaAssureType;
  vehicleLastInsuranceEndDate?: APIDate;
  vehicleInsuredFor?: ServerVehicleInsuredForType;
  vehicleCurrentContractAnniversaryMonth?: VehicleCurrentContractAnniversaryMonthType;

  // usage
  usage: ServerUsageType;
  parking: ServerParkingType;
  parkingZipCode: string;
  parkingCity: string;
  mileage: number;
  principalDriver: ServerConducteurPrincipalType;
  ownerVehicleRegistration: ServerTitulaireCarteGriseType;

  // principal
  gender: ServerSexeType;
  birthDatePrincipalDriver: APIDate;
  maritalStatusPrincipalDriver: ServerSituationMaritaleType;
  professionPrincipalDriver: ServerProfessionType;
  licensingDatePrincipalDriver: APIDate;
  licenseTypePrincipalDriver: ServerLicenseType;
  accompaniedDrivingPrincipalDriver: ServerAccompaniedDrivingType;
  penaltyPrincipalDriver: number;
  penaltyYearPrincipalDriver?: number;
  lostPointsPrincipalDriver: number;
  oldInsurancePrincipalDriver: ServerAncienneAssuranceType;
  insurancePeriodsPrincipalDriver: ServerInsurancePeriod[];

  childrenPrincipalDriver: number;
  birthDateChild1PrincipalDriver: APIDate;
  birthDateChild2PrincipalDriver: APIDate;
  birthDateChild3PrincipalDriver: APIDate;
  birthDateChild4PrincipalDriver: APIDate;
  birthDateChild5PrincipalDriver: APIDate;
  housingTypePrincipalDriver: ServerLogementType;
  propertyOwnershipPrincipalDriver: ServerProprieteType;

  // secondary
  genderSecondaryDriver: ServerSexeType;
  birthDateSecondaryDriver: APIDate;
  maritalStatusSecondaryDriver: ServerSituationMaritaleType;
  professionSecondaryDriver: ServerProfessionType;
  licensingDateSecondaryDriver: APIDate;
  licenseTypeSecondaryDriver: ServerLicenseType;
  accompaniedDrivingSecondaryDriver: ServerAccompaniedDrivingType;
  penaltySecondaryDriver: number;
  penaltyYearSecondaryDriver?: number;
  oldInsuranceSecondaryDriver: ServerAncienneAssuranceType;
  insurancePeriodsSecondaryDriver: ServerInsurancePeriod[];

  // contract
  firstName: string;
  lastName: string;
  address: string;
  phone: string;
  email: string;

  // Conjoint
  firstNamePartner: string;
  lastNamePartner: string;
  genderPartner: Civility;
  birthDatePartner: APIDate;

  desiredEffectiveDate: APIDate;

  // New fields
  firstNamePrincipalDriver: string;
  lastNamePrincipalDriver: string;
  genderPrincipalDriver: ServerSexeType;
  birthCityPrincipalDriver: string;
  birthCountryCodePrincipalDriver: CountryCode;
  firstNameSecondaryDriver: string;
  lastNameSecondaryDriver: string;
  addressLine2?: string;
  addressLine3?: string;
  addressZipCode: string;
  addressCity: string;

  privacyPolicyAccepted: true;
  hasAcceptedCommercialCommunication?: boolean;
  hasAcceptedPartnersCommunication?: boolean;
  principalDriverInsuranceRecords: ServerInsuranceRecord[];
  secondaryDriverInsuranceRecords?: ServerInsuranceRecord[];

  learnerId?: number;
}

export interface ServerLongQuoteRetrieve extends ServerLongQuote {
  brand: string;
  model: string;
}
