import * as Sentry from '@sentry/react';
import type { Context } from '../fsm/types';
import { api } from './api';
import { mapServerRequestToLongQuoteAnswers } from './mappers/longQuoteAnswersMapper';
import type { ServerLongQuoteRetrieve } from './types/LongQuoteAnswers';
import type { TarificationResult, TarificationResultOK } from './types/Quotation';
import { getVersionDetails } from './vehicules';

export const getRemoteQuoteFromPolicyCenter = async (
  submissionId: string,
  submissionNumber: string,
): Promise<Partial<Context>> => {
  try {
    const { packages, quote } = await api<{ packages: Partial<TarificationResult>; quote: ServerLongQuoteRetrieve }>(
      `api/v2/quotes/${submissionId}?number=${submissionNumber}`,
    );

    const versionDetails = await getVersionDetails(quote.codeAuto);

    const answers = mapServerRequestToLongQuoteAnswers({
      ...quote,
      ...versionDetails,
    });

    return {
      answers,
      tarificationResult: { isOk: true, ...packages } as TarificationResultOK,
    };
  } catch (error) {
    Sentry.captureException(error);
    return {};
  }
};
