function timeout(ms?: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export async function delayPromise<ReturnValue>(
  callback: () => Promise<ReturnValue | undefined> | undefined,
  ms?: number,
): Promise<ReturnValue | undefined> {
  await timeout(ms || 0);
  if (!callback) {
    return undefined;
  }
  const res = await callback();
  return res;
}
