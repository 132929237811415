export const getFromNativeApp = (): boolean => localStorage.getItem('from_native_app') === '1';

declare global {
  interface Window {
    ReactNativeWebView: {
      postMessage: (message: string) => void;
    };
  }
}

export function postReactNativeMessage(key: string, data: string): void {
  if (getFromNativeApp() && window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(`${key}.${data}`);
  }
}
