import { defineMessages } from 'react-intl';
import type { AddonInfoModalContentItemProps } from '../../../../components/AddonInfoModal/components/AddonInfoModalContentItem';
import { FormattedMessageWithRichTextFormatting } from '../../../../utils/intl';

export const messages = defineMessages({
  addonsPdcDetailExmpleText: {
    id: 'addons.pdc.detail.exmple.text',
    defaultMessage:
      "Sur l'autoroute des vacances, vous perdez le contrôle de votre voiture et vous vous blessez. A votre retour, des frais d’aide ménagère pour vous aider peuvent être pris en charge. ",
  },
  addonsPdcDetailExmpleTitle: {
    id: 'addons.pdc.detail.exmple.title',
    defaultMessage: 'Exemple',
  },
  addonsPdcDetailPart1Text: {
    id: 'addons.pdc.detail.part_1.text',
    defaultMessage: "<li>d'un accident responsable,</li><li>d'un incendie.</li>",
  },
  addonsPdcDetailPart1Title: {
    id: 'addons.pdc.detail.part_1.title',
    defaultMessage: 'Quand vous êtes victime avec votre véhicule assuré : ',
  },
  addonsPdcDetailPart2Text: {
    id: 'addons.pdc.detail.part_2.text',
    defaultMessage:
      '<li>les dommages corporels (blessures, souffrances physiques) subis par le conducteur du véhicule assuré,</li><li>les frais médicaux liés à l’accident,</li><li>les pertes de revenus professionnels,</li><li>les frais liés aux aides-soignants à domicile et aux aides ménagères,</li><li>le versement d’un capital aux proches du conducteur en cas de décès.</li> ',
  },
  addonsPdcDetailPart2Title: {
    id: 'addons.pdc.detail.part_2.title',
    defaultMessage: 'Nous assurons :',
  },
  addonsPdcDetailPart3Text: {
    id: 'addons.pdc.detail.part_3.text',
    defaultMessage: "Jusqu'à 500 000€ de remboursement.",
  },
  addonsPdcDetailPart3Title: {
    id: 'addons.pdc.detail.part_3.title',
    defaultMessage: 'Quelle indemnisation ?',
  },
});

export const infoModalContent: AddonInfoModalContentItemProps[] = [
  {
    title: <FormattedMessageWithRichTextFormatting {...messages.addonsPdcDetailPart1Title} />,
    description: <FormattedMessageWithRichTextFormatting {...messages.addonsPdcDetailPart1Text} />,
  },
  {
    title: <FormattedMessageWithRichTextFormatting {...messages.addonsPdcDetailPart2Title} />,
    description: <FormattedMessageWithRichTextFormatting {...messages.addonsPdcDetailPart2Text} />,
  },
  {
    title: <FormattedMessageWithRichTextFormatting {...messages.addonsPdcDetailPart3Title} />,
    description: <FormattedMessageWithRichTextFormatting {...messages.addonsPdcDetailPart3Text} />,
  },
  {
    title: <FormattedMessageWithRichTextFormatting {...messages.addonsPdcDetailExmpleTitle} />,
    description: <FormattedMessageWithRichTextFormatting {...messages.addonsPdcDetailExmpleText} />,
    isHighlighted: true,
    variant: 'dark',
  },
];
