import { Typography } from '@ornikar/kitt';
import { NavigationModal } from '@ornikar/kitt-universal';
import { type ReactNode, useState } from 'react';
import type { VehicleFsmAnswers } from '../../../../../fsm/answers';
import { ImmatriculationStep } from '../ImmatriculationStep';
import { VehicleStep } from '../VehicleStep';

interface Props {
  visible: boolean;
  onClose: () => void;
  step: number;
  onChangeStep: (newStep: number) => void;
  onSubmit?: (vehicleImmatriculation: string) => void;
}

export function CorrectVehicleModal({ visible, onClose, step, onChangeStep, onSubmit }: Props): ReactNode {
  // The <Typography.div> has to be delete when we will remove kitt components in the modal
  const [currentVehicleData, setCurrentVehicleData] = useState<Partial<VehicleFsmAnswers>>({});

  return (
    <NavigationModal.ModalBehaviour visible={visible} onClose={onClose}>
      <Typography.div base="body">
        {step === 0 && (
          <ImmatriculationStep
            setCurrentVehicleData={setCurrentVehicleData}
            onChangeStep={onChangeStep}
            onClose={onClose}
          />
        )}
        {step === 1 && (
          <VehicleStep
            currentVehicleData={currentVehicleData}
            onChangeStep={onChangeStep}
            onClose={onClose}
            onSubmit={onSubmit}
          />
        )}
      </Typography.div>
    </NavigationModal.ModalBehaviour>
  );
}
