import type { ReactNode } from 'react';
import { Civility } from '../../../apis/types/LongQuoteAnswers';
import { accompaniedDrivingOptionsList } from '../../../constants/mappers/driving';
import { useSubscriptionFsmState } from '../../../fsm/context';
import { Driver } from '../../../fsm/types';
import { useIsLicenseDateInFuture } from '../../../hooks/useIsLicenseDateInFuture';
import { RadioScreenTemplate } from '../../ScreenTemplates/RadioScreenTemplate';
import type { HighlightContentProps, PrimaryOrSecondaryScreenTemplateType } from '../types';

function HighlightContent({ isPrimaryDriver = false, civility }: HighlightContentProps) {
  return isPrimaryDriver ? (
    <div>
      Si vous avez obtenu votre permis en conduite accompagnée, vous{' '}
      <b>bénéficiez d&apos;un tarif légèrement plus bas</b> compte tenu de votre apprentissage avancé.
    </div>
  ) : (
    <div>
      {civility === Civility.Homme ? 'S’il' : 'Si elle'} a obtenu son permis en conduite accompagnée, vous{' '}
      <b>bénéficiez d&apos;un tarif légèrement plus bas</b> compte tenu de son apprentissage avancé.
    </div>
  );
}

export function AccompaniedDrivingTemplate({ driverType }: PrimaryOrSecondaryScreenTemplateType): ReactNode {
  const isPrimaryDriver = driverType === Driver.Primary;
  const { context } = useSubscriptionFsmState();
  const civility = context.answers[`${driverType}Driver`]?.civility;
  const attribut = civility === Civility.Homme ? 'il' : 'elle';
  const isLicenseDateInFuture = useIsLicenseDateInFuture(driverType);

  return (
    <RadioScreenTemplate
      title={
        isPrimaryDriver ? (
          <div>{isLicenseDateInFuture ? 'Aurez' : 'Avez'}-vous fait de la conduite accompagnée&nbsp;?</div>
        ) : (
          <div>A-t-{attribut} fait de la conduite accompagnée&nbsp;?</div>
        )
      }
      highlightTitle="Besoin de précision ?"
      highlightContent={
        isPrimaryDriver ? <HighlightContent isPrimaryDriver /> : <HighlightContent civility={civility} />
      }
      fieldName={`${driverType}Driver.accompaniedDriving`}
      choices={accompaniedDrivingOptionsList}
    />
  );
}
