import type { ReactNode } from 'react';
import { ClaimRecordNatureTemplate } from '../../../../components/ScreenTemplates/InformationTemplates/InsuranceRecordTemplates/ClaimRecordTemplates/ClaimRecordNatureTemplate';
import { claimRecordNatureOptionsList } from '../../../../constants/mappers/information';
import { ClaimQuantity } from '../../../../fsm/answers';
import { useSubscriptionFsmState } from '../../../../fsm/context';

const numberToHowManthy = [
  'premier',
  'deuxième',
  'troisième',
  'quatrième',
  'cinquième',
  'sixième',
  'septième',
  'huitième',
  'neuvième',
  'dixième',
  'onzième',
  'douzième',
  'treizième',
  'quatorizième',
  'quinzième',
  'seizième',
];

const computeTitle = (claimQuantity: ClaimQuantity = ClaimQuantity.One, recordNumber = 0): string => {
  if (claimQuantity === ClaimQuantity.One) {
    return "De quel type de sinistre s'agit\u2011il\u00A0?";
  }

  if (recordNumber === 0) {
    return "Commençons par l'un des sinistres : de quel type était\u2011il\u00A0?";
  }

  if (recordNumber === 1) {
    return 'Merci, continuons avec le sinistre suivant : de quel type était\u2011il\u00A0?';
  }

  if (recordNumber < 16) {
    return `De quel type était votre ${numberToHowManthy[recordNumber]} sinistre\u00A0?`;
  }

  return `De quel type était votre ${recordNumber} ème sinistre\u00A0?`;
};

export function PrimaryClaimRecordNatureScreen(): ReactNode {
  const {
    context: {
      editingInsuranceRecord,
      answers: { primaryDriver },
    },
  } = useSubscriptionFsmState();

  return (
    <ClaimRecordNatureTemplate
      fieldName={`primaryDriver.claimRecords[${editingInsuranceRecord.index}].nature`}
      title={computeTitle(primaryDriver?.claimQuantity, primaryDriver?.claimRecords?.length)}
      choices={claimRecordNatureOptionsList}
      highlightTitle="Où trouver le type de sinistre&nbsp;?"
      highlightContent="Sur votre Relevé d'Information sur lequel sont inscrits les types de sinistres que vous avez déclarés."
    />
  );
}
