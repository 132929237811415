import type { DropdownSelectProps } from '@ornikar/kitt';
import { DropdownSelect } from '@ornikar/kitt';
import type { ReactNode } from 'react';
import { useField } from 'react-final-form';
import styles from './styles.module.css';

export type DropdownProps<FieldValue extends string | number> = DropdownSelectProps<FieldValue>;

export function Dropdown<FieldValue extends string | number>(props: DropdownProps<FieldValue>): ReactNode {
  const {
    input: { onChange, onBlur: blur },
  } = useField(props.name || '');

  return (
    <DropdownSelect<FieldValue>
      className={styles.Dropdown}
      {...props}
      onChange={(value: string | number) => {
        onChange(value);
        // trigger validation
        blur();
      }}
    />
  );
}
