import { DialogModal, Image, Typography, VStack, View } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { useInsuranceDesktopMediaQuery } from '../../../../../hooks/useInsuranceDesktopMediaQuery';
import Illustration from './assets/car-at-warning-sign.svg';

export interface EmailErrorModalProps {
  email?: string;
  visible?: boolean;
  onClose: () => void;
}

export function EmailErrorModal({ email, visible, onClose }: EmailErrorModalProps): ReactNode {
  const domainName = email?.split('@')[1];
  const isDesktop = useInsuranceDesktopMediaQuery();

  return (
    <DialogModal.ModalBehaviour visible={visible} onClose={onClose}>
      <DialogModal
        title={
          <VStack alignContent="center" alignItems="center" space="kitt.7">
            <View marginTop="kitt.10" padding="0">
              <Image src={Illustration} alt="car at traffic light" width={170} height={115} />
            </View>
            <Typography.Paragraph variant="bold" textAlign="center">
              Nous n'acceptons pas encore les adresses e-mail @{domainName}
            </Typography.Paragraph>
          </VStack>
        }
        actions={
          <VStack alignItems={isDesktop ? 'center' : undefined} space="kitt.3">
            <DialogModal.Button type="primary" onPress={onClose}>
              J'utilise un autre e-mail
            </DialogModal.Button>
          </VStack>
        }
      >
        <Typography.Paragraph base="body" textAlign="center">
          Désolé... On espère que vous continuerez quand même l’aventure avec nous !
        </Typography.Paragraph>
      </DialogModal>
    </DialogModal.ModalBehaviour>
  );
}
