import { InputText } from '@ornikar/kitt-universal';
import { type ReactNode } from 'react';
import { Field } from '../../../../components/Field';
import { useHamonContractRefValidator } from '../../../../forms/validation/sections/insurance/hamon';

export function ContractRef(): ReactNode {
  const hamonContractRefValidator = useHamonContractRefValidator();
  return (
    <Field
      component={InputText}
      label="Numéro du contrat à résilier"
      name="hamonContractRef"
      validate={hamonContractRefValidator}
    />
  );
}
