import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../fsm/context';
import { Event } from '../fsm/types';
import { sendEvent } from '../utils/mixpanel';

const useGetBackState = (): string => {
  const { configuration, value } = useSubscriptionFsmState();
  const nodeConfig = configuration.find((conf) => conf.key === value);
  const backState = (nodeConfig?.config?.on as any)?.BACK?.target;
  return backState;
};

export function useGoBack(): [boolean, () => void] {
  const { nextEvents } = useSubscriptionFsmState();
  const send = useSubscriptionFsmDispatch();
  const canBack = nextEvents.includes('BACK');
  const backState = useGetBackState();
  const goBack = (): void => {
    if (canBack) {
      sendEvent('Click Return Button', { to_state: backState });
      send({ type: Event.BACK });
    }
  };

  return [canBack, goBack];
}
