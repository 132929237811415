import type { ReactNode } from 'react';

export enum SubscribeStatus {
  SUBSCRIBED = 'subscribed',
  NEED_PAYMENT = 'need_payment',
  KO = 'ko',
}

export type ServerSubscribeResultKO =
  | {
      status: SubscribeStatus.KO;
      error: string;
      message: undefined;
    }
  | {
      status: undefined;
      error: string;
      message: string | string[];
      statusCode: number;
    };

export type ServerSubscribeResultOK =
  | {
      status: SubscribeStatus.SUBSCRIBED;
      contractId: string;
    }
  | {
      status: SubscribeStatus.NEED_PAYMENT;
      contractId: string;
      paymentForm: string;
    };

export type ServerSubscribeResult = ServerSubscribeResultOK | ServerSubscribeResultKO;

export type SubscribeResult =
  | {
      status: SubscribeStatus.SUBSCRIBED;
      contractId: string;
    }
  | {
      status: SubscribeStatus.NEED_PAYMENT;
      contractId: string;
      paymentForm: string;
    }
  | {
      status: SubscribeStatus.KO;
      error: NonNullable<ReactNode>;
    };
