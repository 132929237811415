import { DejaAssureAvecNomType } from '../../apis/types/LongQuoteAnswers';
import { VehiculePreSelectionType } from '../answers';
import { type Context, DefaultAnswersEnum } from '../types';
import { isBeforeToday } from '../validators';
import { isAnswerEquals } from './utils';

export const isLicensePlateSelection = isAnswerEquals(
  'vehiculePreSelectionType',
  (answer): boolean => answer === VehiculePreSelectionType.LICENSE_PLATE,
);

export const isBrandSelection = isAnswerEquals(
  'vehiculePreSelectionType',
  (answer): boolean => answer === VehiculePreSelectionType.BRAND,
);

export const hasUniqueAvailableVersion = (context: Context): boolean => {
  return context.availableVersions && context.availableVersions.length === 1;
};

export const isVehicleAlreadyInPossession = isAnswerEquals(
  'vehiculeAcquisition',
  (answer): boolean => !!answer && isBeforeToday(answer),
);

export const isVehicleNotInPossession = isAnswerEquals(
  'vehiculeAcquisition',
  (answer): boolean => !!answer && !isBeforeToday(answer),
);

export const isVehicleCoveredWithSubscriberName = isAnswerEquals(
  'vehiculeDejaAssureAvecNom',
  (answer): boolean => answer === DejaAssureAvecNomType.OuiAMonNom,
);

export const isVehicleCoveredWithoutSubscriberName = isAnswerEquals(
  'vehiculeDejaAssureAvecNom',
  (answer): boolean => answer === DejaAssureAvecNomType.OuiPasAMonNom,
);

export const isVehicleCovered = isAnswerEquals(
  'vehiculeDejaAssureAvecNom',
  (answer): boolean => answer === DejaAssureAvecNomType.OuiAMonNom || answer === DejaAssureAvecNomType.OuiPasAMonNom,
);

export const hasDefaultMiseEnCirculation = (context: Context): boolean => {
  return context.defaultAnswers.includes(DefaultAnswersEnum.VEHICULE_MISE_EN_CIRCULATION);
};
