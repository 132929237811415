import type { ReactNode } from 'react';
import { DateField } from '../../../components/DateField';
import { ScreenTemplateWithValidation } from '../../../components/ScreenTemplates/ScreenTemplateWithValidation';
import { useVehicleAcquisitionDateValidator } from '../../../forms/validation/sections/vehicle/vehicleDateAcquisition';
import { useSubscriptionFsmState } from '../../../fsm/context';

const fieldName = 'vehiculeAcquisition';

export function VehicleAcquisitionDateScreen(): ReactNode {
  const { context } = useSubscriptionFsmState();
  const vehicleAcquisitionDateValidator = useVehicleAcquisitionDateValidator(
    context.answers.vehiculeMiseEnCirculation!,
  );

  return (
    <ScreenTemplateWithValidation
      fieldNames={[fieldName]}
      title="À quelle date allez-vous acheter votre véhicule&nbsp;? Si le véhicule est déjà en votre possession, indiquez la date d'achat."
      highlightTitle="Où trouver la date d'achat ?"
      highlightContent={
        <ul>
          <li>
            Pour un véhicule neuf, rendez-vous au <u>repère B</u> de votre carte grise (certificat d’immatriculation).
          </li>
          <li>
            Pour une voiture d&apos;occasion, rendez-vous au <u>repère I</u> de votre carte grise (certificat
            d’immatriculation).
          </li>
        </ul>
      }
    >
      <DateField name={fieldName} validate={vehicleAcquisitionDateValidator} />
    </ScreenTemplateWithValidation>
  );
}
