import type { ComponentProps, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

export function FormattedMessageWithRichTextFormatting(props: ComponentProps<typeof FormattedMessage>): ReactNode {
  return (
    <FormattedMessage
      {...props}
      values={{
        ...props.values,
        b: (chunk) => <b>{chunk}</b>,
        li: (chunk) => <li>{chunk}</li>,
        br: <br />,
      }}
    />
  );
}
