export enum StepEnum {
  BeingCalledASAP = 'BeingCalledASAP',
  ChoseADaySlot = 'ChoseADaySlot',
  ChoseATimeSlot = 'ChoseATimeSlot',
  ConfirmDialog = 'ConfirmDialog',
}

export enum SwitchStateEnum {
  AsSoonAsPossible = 'AsSoonAsPossible',
  ChoseASlot = 'ChoseASlot',
}
