import type { Options } from 'ky';
import ky from 'ky';

export const api = <Result>(url: string, options?: Options): Promise<Result> => {
  const leadId = localStorage.getItem('leadId');

  return ky<Result>(url, {
    prefixUrl: import.meta.env.VITE_INSURANCE_API_URL,
    ...options,
    headers: {
      ...options?.headers,
      'X-Session-Id': leadId ?? undefined,
    },
  }).json();
};
