import {
  LicenseType,
  LogementType,
  ParkingType,
  TitulaireCarteGriseType,
  UsageType,
} from '../../apis/types/LongQuoteAnswers';
import { YesNoQuestion } from '../../fsm/answers';
import { mapOptionsListToOptionsDict } from './utils';

export const accompaniedDrivingOptionsList = [
  {
    label: 'Oui',
    value: YesNoQuestion.YES,
  },
  {
    label: 'Non',
    value: YesNoQuestion.NO,
  },
];

export const accompaniedDrivingOptionsDict = mapOptionsListToOptionsDict<YesNoQuestion>(accompaniedDrivingOptionsList);

export const licenseTypeOptionsList = [
  {
    label: 'En France',
    value: LicenseType.french,
  },
  {
    label: "Dans un autre pays d'Europe",
    value: LicenseType.european,
  },
];

export const licenseTypeOptionsDict = mapOptionsListToOptionsDict<LicenseType>(licenseTypeOptionsList);

export const vehicleUsageOptionsList = [
  {
    label: 'Loisirs',
    value: UsageType.leisure,
  },

  {
    label: 'Loisirs et trajets maison / travail',
    value: UsageType.leisureAndWorkHomeTrips,
  },

  {
    label: 'Loisirs et usage professionnel occasionnel',
    value: UsageType.leisureAndOccasionalBusinessTrips,
  },

  {
    label: 'Tournées de clientèles et déplacements techniques réguliers',
    value: UsageType.frequentBusinessTripsAndTechnicalInterventions,
  },
];

export const vehicleUsageOptionsDict = mapOptionsListToOptionsDict<UsageType>(vehicleUsageOptionsList);

export const parkingLocationOptionsList = [
  {
    label: 'Dans la rue ou dans un parking public',
    value: ParkingType.public,
  },
  {
    label: 'Dans un parking privé clos',
    value: ParkingType.parking,
  },
  {
    label: 'Dans un box privé clos',
    value: ParkingType.garage,
  },
];

export const parkingLocationOptionsDict = mapOptionsListToOptionsDict<ParkingType>(parkingLocationOptionsList);

export const housingTypeOptionsList = [
  {
    label: 'Une maison',
    value: LogementType.Maison,
  },
  {
    label: 'Un appartement',
    value: LogementType.Appartement,
  },
  {
    label: 'Autre',
    value: LogementType.Autre,
  },
];

export const housingTypeOptionsDict = mapOptionsListToOptionsDict<LogementType>(housingTypeOptionsList);

export const isPropertyOwnerOptionsList = [
  {
    label: 'Oui',
    value: YesNoQuestion.YES,
  },
  {
    label: 'Non',
    value: YesNoQuestion.NO,
  },
];

export const isPropertyOwnerOptionsDict = mapOptionsListToOptionsDict<YesNoQuestion>(isPropertyOwnerOptionsList);

export const ownerVehicleRegistrationOptionsList = [
  {
    label: "Je suis l'unique propriétaire",
    value: TitulaireCarteGriseType.Souscripteur,
  },
  {
    label: 'Mon / ma conjoint.e et moi',
    value: TitulaireCarteGriseType.SouscripteurEtConjoint,
  },
  {
    label: 'Mon / ma conjoint.e',
    value: TitulaireCarteGriseType.SouscripteurOuConjoint,
  },
  {
    label: 'Mes parents',
    value: TitulaireCarteGriseType.Parents,
  },
  {
    label: 'Les parents de mon / ma conjoint.e',
    value: TitulaireCarteGriseType.ParentsConjoint,
  },
  {
    label: 'Une entreprise',
    value: TitulaireCarteGriseType.Société,
  },
  {
    label: 'Une société de leasing ou de crédit',
    value: TitulaireCarteGriseType.SocieteLeasing,
  },
];

export const ownerVehicleRegistrationOptionsDict = mapOptionsListToOptionsDict<TitulaireCarteGriseType>(
  ownerVehicleRegistrationOptionsList,
);
