import type { IntlValidator } from '@ornikar/react-validators';
import { useComposeValidators, useRequiredValidator, useValidator } from '@ornikar/react-validators';
import type { Validator } from '@ornikar/validators';
import { invalidTypeSymbol } from '@ornikar/validators';
import type { AnyObject } from 'react-final-form';
import { defineMessages } from 'react-intl';
import { FinancementType, TitulaireCarteGriseType } from '../../../../apis/types/LongQuoteAnswers';
import { RegistrationDocumentOwnerIsSubscriberType } from '../../../../fsm/answers';
import type { FormValues } from '../../../answers';

const messages = defineMessages({
  isNotFundingCompatible: {
    id: 'validators.registrationDocumentOwner.isNotFundingCompatible',
    defaultMessage:
      'Parce que vous avez acheté votre véhicule en Location avec Option d’Achat ou Location Longue Durée, le propriétaire du véhicule ne peut être qu’une société de crédit',
  },
});

export const COMPANY_FUNDING_TYPES = [FinancementType.LLD, FinancementType.LOA];

export const REQUIRED_REGISTRATION_DOCUMENT_OWNERS_FOR_COMPANY_FUNDING: TitulaireCarteGriseType[] = [
  TitulaireCarteGriseType.SocieteLeasing,
  // "Autre" is not a required document owner type for company funding
  // but this answer leads to an end-flow screen and thus it needs to bypass validation
  TitulaireCarteGriseType.Autre,
];

const registrationDocumentOwnerNotCompatibleWithFundingValidator = ((
  value: TitulaireCarteGriseType,
  values: AnyObject & FormValues,
) => {
  if (!value) return undefined;

  if (
    values.vehiculeFinancement &&
    COMPANY_FUNDING_TYPES.includes(values.vehiculeFinancement) &&
    REQUIRED_REGISTRATION_DOCUMENT_OWNERS_FOR_COMPANY_FUNDING.length > 0 &&
    !REQUIRED_REGISTRATION_DOCUMENT_OWNERS_FOR_COMPANY_FUNDING.includes(value)
  ) {
    return invalidTypeSymbol;
  }

  return undefined;
}) as Validator<TitulaireCarteGriseType>;

const subscriberAsRegistrationDocumentOwnerNotCompatibleWithFundingValidator = ((
  value: string,
  values: AnyObject & FormValues,
) => {
  if (!value) return undefined;

  if (
    values.vehiculeFinancement &&
    COMPANY_FUNDING_TYPES.includes(values.vehiculeFinancement) &&
    value === (RegistrationDocumentOwnerIsSubscriberType.Oui as string) &&
    REQUIRED_REGISTRATION_DOCUMENT_OWNERS_FOR_COMPANY_FUNDING.length > 0 &&
    !REQUIRED_REGISTRATION_DOCUMENT_OWNERS_FOR_COMPANY_FUNDING.includes(TitulaireCarteGriseType.Souscripteur)
  ) {
    return invalidTypeSymbol;
  }

  return undefined;
}) as Validator<string>;

export const useRegistrationDocumentOwnerValidator = (): IntlValidator<TitulaireCarteGriseType> =>
  useComposeValidators(
    useRequiredValidator(),
    useValidator(registrationDocumentOwnerNotCompatibleWithFundingValidator, messages.isNotFundingCompatible),
  );

export const useRegistrationDocumentOwnerIsSubscriberValidator = (): IntlValidator<string> =>
  useComposeValidators(
    useRequiredValidator(),
    useValidator(
      subscriberAsRegistrationDocumentOwnerNotCompatibleWithFundingValidator,
      messages.isNotFundingCompatible,
    ),
  );
