import type { StateMachineConfig } from '../types';

export interface StateMachineInitContext {
  config: StateMachineConfig;
  isFetched: boolean;
  leadId: string | null;
  quoteId: string | null;
  submissionId: string | null;
  submissionNumber: string | null;
  agentEmail?: string;
}

export enum StateMachineInitState {
  GETTING_URL_SEARCH_PARAMS = 'GETTING_URL_SEARCH_PARAMS',
  SENDING_VISIT = 'SENDING_VISIT',
  CONFIG_OVERRIDE = 'CONFIG_OVERRIDE',
  FETCHING_REMOTE_QUOTE_FROM_CORE_API = 'FETCHING_REMOTE_QUOTE_FROM_CORE_API',
  FETCHING_REMOTE_QUOTE_FROM_POLICY_CENTER = 'FETCHING_REMOTE_QUOTE_FROM_POLICY_CENTER',
  GETTING_LOCAL_MACHINE_CONFIG = 'GETTING_LOCAL_MACHINE_CONFIG',
  END = 'END',
}

export interface StateMachineInitSchema {
  states: Record<StateMachineInitState, Record<string, unknown>>;
}

export type StateMachineInitDispatch = { type: 'FETCH_REMOTE_QUOTE' } | { type: 'GET_LOCAL_MACHINE_CONFIG' };
