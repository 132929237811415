import { api } from './api';

export interface InsurerDto {
  name: string;
  email: string;
  address: string;
  cedex?: string;
  zipCode: string;
  city: string;
  country: string;
}

export const getInsurerNameSuggestions = (inputText: string): Promise<InsurerDto[]> => {
  const searchParams = new URLSearchParams({ name: inputText });

  return api<InsurerDto[]>(`api/v1/insurers/search?${searchParams.toString()}`);
};
