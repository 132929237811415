export interface Option<T extends string | number> {
  label: string;
  value: T;
}

export function mapOptionsListToOptionsDict<T extends string | number>(
  optionsList: Option<T>[],
): Record<Option<T>['value'], Option<T>['label']> {
  const optionsDict = {} as Record<Option<T>['value'], Option<T>['label']>;

  optionsList.forEach(({ value, label }) => {
    optionsDict[value] = label;
  });

  return optionsDict;
}
