import type { Dict } from 'mixpanel-browser';
import mixpanel from 'mixpanel-browser';
import { getFromNativeApp } from '../utils/nativeApp';

export function initializeMixpanel(): void {
  mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
    api_host: import.meta.env.VITE_MIXPANEL_API_HOST,
    debug: import.meta.env.DEV,
  });

  const props: Dict = {
    app_configuration: {
      from: getFromNativeApp() ? 'insurance_app_webview' : 'web',
    },
    session_user_agent: navigator.userAgent,
  };

  const isBot = /(Mozilla\/4.0)/i.test(navigator.userAgent);

  if (isBot) {
    props.$ignore = true;
  }

  mixpanel.register(props);
}
