import type { ReactNode } from 'react';
import { CancellationRecordHitAndRunTemplate } from '../../../../components/ScreenTemplates/InformationTemplates/InsuranceRecordTemplates/CancellationRecordTemplates/CancellationRecordHitAndRunTemplate';

export function PrimaryTerminationRecordLicenseCanceledOrSuspendedHitAndRunScreen(): ReactNode {
  return (
    <CancellationRecordHitAndRunTemplate
      title="Avez-vous commis un délit de fuite ou refusé d'obtempérer&nbsp;?"
      fieldName="primaryDriver.terminationRecords[0].cancellationOrSuspensionRecord.isHitAndRun"
    />
  );
}
