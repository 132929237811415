import { addMonths, format } from 'date-fns';
import { fr } from 'date-fns/locale';
import type { ReactNode } from 'react';
import { useFormState } from 'react-final-form';
import { Dropdown } from '../../../components/Dropdown';
import { Field } from '../../../components/Field';
import { ScreenTemplate } from '../../../components/ScreenTemplates/ScreenTemplate';
import { sendEvent } from '../../../utils/mixpanel';
import { capitalize } from '../../../utils/string';

const choices: { label: string; value: number }[] = [];

for (let i = 0; i < 12; i++) {
  const date = addMonths(new Date(), i);
  const label = capitalize(format(date, 'MMMM', { locale: fr }));
  const value = date.getMonth() + 1;

  choices.push({ label, value });
}

export function VehicleCurrentContractAnniversaryMonthScreen(): ReactNode {
  const { values } = useFormState();

  const handleBeforeSubmit = (): void => {
    if (!values.vehicleCurrentContractAnniversaryMonth) {
      sendEvent('Skip Anniversary Contract Question');
    }
  };

  return (
    <ScreenTemplate
      title="Quand votre contrat arrive-t-il à échéance&nbsp;?"
      highlightTitle="Comment trouver cette information&nbsp;?"
      highlightContent="Vous trouverez la date d’échéance sur votre contrat d’assurance."
      submitButton={{
        label: values.vehicleCurrentContractAnniversaryMonth ? 'Continuer' : 'Je ne connais pas cette date',
      }}
      onBeforeSubmit={handleBeforeSubmit}
    >
      <Field
        component={Dropdown}
        choices={choices}
        name="vehicleCurrentContractAnniversaryMonth"
        placeholder="Sélectionner le mois d’échéance"
      />
    </ScreenTemplate>
  );
}
