import type { ReactNode } from 'react';
import { TitulaireCarteGriseType } from '../../../apis/types/LongQuoteAnswers';
import { RadioScreenTemplate } from '../../../components/ScreenTemplates/RadioScreenTemplate';
import { ownerVehicleRegistrationOptionsList } from '../../../constants/mappers/driving';
import { useRegistrationDocumentOwnerValidator } from '../../../forms/validation/sections/driving/registrationDocumentOwner';

const choices = ownerVehicleRegistrationOptionsList.filter(
  ({ value }) => value !== TitulaireCarteGriseType.Souscripteur,
);

export function DrivingRegistrationDocumentOwnerScreen(): ReactNode {
  const validator = useRegistrationDocumentOwnerValidator();
  return (
    <RadioScreenTemplate<TitulaireCarteGriseType>
      title="Qui est inscrit sur la carte grise (certificat d’immatriculation) du véhicule ?"
      fieldName="titulaireCartegrise"
      choices={choices}
      highlightTitle="Précision importante"
      highlightContent={
        <div>
          Vérifiez bien cette information, présente sur le <b>champ C1</b> de la carte grise (certificat
          d’immatriculation) du véhicule.
          <br />
          <br />
          Lors de votre souscription, vous devrez envoyer les permis de conduire des personnes inscrites sur la carte
          grise du véhicule pour activer votre assurance.
        </div>
      }
      validate={validator}
    />
  );
}
