import { InfoRegularIcon } from '@ornikar/kitt-icons/phosphor';
import { Button, HStack, Icon, Tooltip, Typography, VStack, View, theme } from '@ornikar/kitt-universal';
import { SetNotification } from '@ornikar/react-notification';
import * as Sentry from '@sentry/react';
import { differenceInYears, parse } from 'date-fns';
import { type ReactNode, useMemo, useState } from 'react';
import { getQuoteOptions } from '../../../apis/getQuoteOptions';
import { saveQuote } from '../../../apis/saveQuote';
import type { Package } from '../../../apis/types/Formula';
import { Civility } from '../../../apis/types/LongQuoteAnswers';
import { PaymentPeriodicity } from '../../../apis/types/PaymentOptions';
import type { TarificationResultOK } from '../../../apis/types/Quotation';
import { CallbackModule } from '../../../components/CallbackModule';
import { Footer } from '../../../components/Footer';
import { MrhRedirection } from '../../../components/MrhRedirection';
import { Toggle } from '../../../components/Toggle';
import { ExitDoorHighlight } from '../../../components/exitDoor/ExitDoorHighlight';
import { FORMULA_SCREEN_MRH_REDIRECTION_FEATURE, MRH_REDIRECTION_FEATURE } from '../../../constants/flagshipKeys';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { Event, isTarificationOK } from '../../../fsm/types';
import { useGoBack } from '../../../hooks/useGoBack';
import { useInsuranceDesktopMediaQuery } from '../../../hooks/useInsuranceDesktopMediaQuery';
import { useIdentifyUser, useSendPageNameToSegment } from '../../../providers/AnalyticsProvider';
import { useFsFlag } from '../../../setup/flagship';
import { serviceUnavailable } from '../../../utils/errorMessages';
import { sendErrorMessageEvent, sendEvent } from '../../../utils/mixpanel';
import { getPaymentPeriodicityAmount } from '../../../utils/price';
import { FormulesTabsDesktop } from './components/FormulesTabsDesktop';
import { FormulesTabsMobile } from './components/FormulesTabsMobile';
import { FrequentlyAskedQuestions } from './components/FrequentlyAskedQuestions';
import { MobileBottomBar } from './components/MobileBottomBar';
import { NextSteps } from './components/NextSteps';
import { PostSubscription } from './components/PostSubscription';
import type { RecommendationProps } from './components/Recommendation';
import { Recommendation } from './components/Recommendation';
import { getUserName } from './userNameMapper';

export function FormulesScreen(): ReactNode {
  // Déclarations de hooks
  const isDesktop = useInsuranceDesktopMediaQuery();
  const { context } = useSubscriptionFsmState();
  const [, goBack] = useGoBack();
  const send = useSubscriptionFsmDispatch();
  const tarificationResult = context.tarificationResult as TarificationResultOK;
  const selectedFormula = context.formulePickedObject;
  const formulas = isTarificationOK(tarificationResult) ? tarificationResult.packages : [];

  const mrhRedirectionFeatureFormulesScreen = useFsFlag<boolean>(FORMULA_SCREEN_MRH_REDIRECTION_FEATURE, false, {
    shouldSendVariationToMixpanel: true,
  });

  const mrhRedirectionFeature = useFsFlag<boolean>(MRH_REDIRECTION_FEATURE, false, {
    shouldSendVariationToMixpanel: true,
  });

  useIdentifyUser();
  useSendPageNameToSegment();

  // State et fonctions
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [formuleSelected, setFormuleSelected] = useState<Package>(() => {
    const formula =
      formulas.find((formule) => (selectedFormula ? formule.code === selectedFormula.code : formule.isRecommended)) ||
      formulas[0];

    return formula;
  });

  const isMonthlyPaymentAvailable = Boolean(formuleSelected.monthlyPrice && formuleSelected.isMonthlyPaymentAllowed);

  const defaultPaymentPeriodicity = (): PaymentPeriodicity => {
    if (context.paymentPeriodicity) {
      return context.paymentPeriodicity === 'monthly' ? PaymentPeriodicity.MONTHLY : PaymentPeriodicity.ANNUALLY;
    }
    return isMonthlyPaymentAvailable ? PaymentPeriodicity.MONTHLY : PaymentPeriodicity.ANNUALLY;
  };

  const [paymentPeriodicity, setPaymentPeriodicity] = useState<PaymentPeriodicity>(() => defaultPaymentPeriodicity());

  const price = getPaymentPeriodicityAmount(paymentPeriodicity, formuleSelected);

  const handleSubscription = async (formule: Package): Promise<void> => {
    if (isTarificationOK(tarificationResult) && formule?.code) {
      try {
        setError(null);
        setIsLoading(true);
        const saveQuoteResult = await saveQuote(tarificationResult, formule.reference, paymentPeriodicity);
        const options = await getQuoteOptions(tarificationResult.quoteId);

        const periodicity = paymentPeriodicity === PaymentPeriodicity.MONTHLY ? 'monthly' : 'yearly';
        const currency =
          paymentPeriodicity === PaymentPeriodicity.MONTHLY
            ? formule.monthlyPrice?.currency
            : formule.yearlyPrice.currency;

        sendEvent('Product Click', {
          price,
          currency,
          name: formule.name,
          id: formule.reference,
          recommanded: formule.isRecommended ? 'yes' : 'no',
          date_range: periodicity,
        });

        send(Event.FORMULE_SELECTED, {
          answers: { formulePicked: formule.reference, addons: {} },
          paymentPeriodicity: periodicity,
          results: saveQuoteResult,
          options,
        });
      } catch (error_) {
        Sentry.captureException(error_);

        sendErrorMessageEvent('banner', 'Error when selecting formula', (error_ as Error).message, {
          job_number: tarificationResult.submissionNumber,
        });

        setError(serviceUnavailable);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleClick = (formule: Package): void => {
    setFormuleSelected(formule);

    if (isDesktop) {
      handleSubscription(formule);
    }
  };

  const sendEventViewedCoverageModal = (label: string): void => {
    sendEvent('Viewed Coverage Modal', { name: label });
  };

  // Éléments JSX
  const IntroductionSentenceWrapper = isDesktop ? Typography.h2 : Typography.h3;
  const FormulesTabs = isDesktop ? FormulesTabsDesktop : FormulesTabsMobile;

  const hasDiscountTitle = (
    <Typography.Text
      variant="regular"
      color="kitt.white"
      type={{
        base: 'heading-s',
        medium: 'heading-xl',
      }}
    >
      {getUserName(context.answers)}, en tant qu'
      {context.answers.primaryDriver?.civility === Civility.Homme ? 'ancien' : 'ancienne'} élève,{' '}
      <Typography.Text
        color={theme.palettes.lateOcean['coral.4']}
        type={{
          base: 'heading-impact-s',
          medium: 'heading-impact-xl',
        }}
      >
        économisez en moyenne 750€{' '}
        <VStack
          top={{
            base: '4px',
            medium: '-10px',
          }}
          justifyContent="center"
        >
          <Tooltip
            content="Économie moyenne constatée sur la base d'une étude Ornikar Assurances menée du 1er octobre au 30 novembre 2023, pour nos utilisateurs auto-école ayant un profil jeune conducteur et présentant les mêmes caractéristiques."
            position="top"
          >
            <Icon icon={<InfoRegularIcon />} size={24} color={theme.palettes.lateOcean['coral.4']} />
          </Tooltip>
        </VStack>
      </Typography.Text>
    </Typography.Text>
  );

  const recommandationProps = useMemo(() => {
    const recommandedFormuleIndex: number = tarificationResult.packages.findIndex(({ isRecommended }) => isRecommended);
    const positions = ['left', 'middle', 'right'];

    const vehicleAgeInYears: number = differenceInYears(
      new Date(),
      parse(context.answers.vehiculeMiseEnCirculation!, 'yyyy-MM-dd', new Date()),
    );

    const recommandedFormules = ['plus de 10', vehicleAgeInYears, 'moins de 5'];

    if (tarificationResult.packages.length < 3) {
      positions.splice(1, 1);
      recommandedFormules.splice(1, 1);
    }

    const adaptedVehicleAgeInYears = recommandedFormules[recommandedFormuleIndex];

    return {
      position: positions[recommandedFormuleIndex] as RecommendationProps['position'],
      content: `Recommandé pour protéger votre véhicule car il a ${adaptedVehicleAgeInYears} ans.`,
    };
  }, [context.answers.vehiculeMiseEnCirculation, tarificationResult.packages]);

  return (
    <VStack pointerEvents={isLoading ? 'none' : 'auto'}>
      <VStack
        backgroundColor="kitt.palettes.lateOcean.lateOceanLight1"
        paddingX={isDesktop ? 'kitt.8' : 'kitt.4'}
        paddingTop={isDesktop ? 'kitt.10' : 'kitt.4'}
        paddingBottom={isDesktop ? 'kitt.10' : 'kitt.6'}
        borderBottomRadius={isDesktop ? undefined : 'kitt.5'}
        alignItems="center"
      >
        <VStack space="kitt.2" width="min(1204px, 100%)">
          {error && (
            <SetNotification
              dismissible
              centeredText={false}
              type="danger"
              message={<Typography.Text color="kitt.white">{error}</Typography.Text>}
            />
          )}

          {context.defaultAnswers.length > 0 && (
            <View marginBottom={{ base: 'kitt.4', large: 'kitt.6' }}>
              <ExitDoorHighlight backgroundColor="kitt.white">
                Puisque vous avez indiqué ne pas connaître certaines informations, votre tarif est estimé à partir de
                réponses standards. Vous devrez les corriger pour obtenir un tarif personnalisé et continuer l’aventure
                avec nous.
              </ExitDoorHighlight>
            </View>
          )}

          <HStack justifyContent="space-between" space="kitt.4">
            <IntroductionSentenceWrapper color="kitt.white">
              {tarificationResult.hasDiscount ? (
                hasDiscountTitle
              ) : (
                <Typography.Text>
                  <Typography.Paragraph color="kitt.palettes.lateOcean.warmEmbrace">
                    {getUserName(context.answers)}
                  </Typography.Paragraph>
                  {', choisissez votre formule\u00A0!'}
                </Typography.Text>
              )}
            </IntroductionSentenceWrapper>
            {isDesktop && (
              <View paddingTop="kitt.4">
                <Button type="default" variant="ghost" onPress={goBack}>
                  Modifier mes informations
                </Button>
              </View>
            )}
          </HStack>

          {!isDesktop && isMonthlyPaymentAvailable && (
            <View marginY="kitt.2">
              <Toggle
                label="Paiement annuel"
                value={paymentPeriodicity === PaymentPeriodicity.ANNUALLY}
                variant="accent"
                onChange={(value) =>
                  setPaymentPeriodicity(value ? PaymentPeriodicity.ANNUALLY : PaymentPeriodicity.MONTHLY)
                }
              />
            </View>
          )}

          <View
            position="relative"
            marginTop={isDesktop ? 'kitt.6' : 'kitt.2'}
            marginBottom={isDesktop ? 'kitt.4' : undefined}
          >
            <Recommendation {...recommandationProps} />
            {isDesktop && isMonthlyPaymentAvailable && (
              <View
                position="absolute"
                height={50}
                justifyContent="center"
                right={recommandationProps.position === 'left' ? 0 : undefined}
              >
                <Toggle
                  label="Paiement annuel"
                  value={paymentPeriodicity === PaymentPeriodicity.ANNUALLY}
                  variant="accent"
                  onChange={(value) =>
                    setPaymentPeriodicity(value ? PaymentPeriodicity.ANNUALLY : PaymentPeriodicity.MONTHLY)
                  }
                />
              </View>
            )}
          </View>

          <FormulesTabs
            packages={tarificationResult.packages}
            formuleSelected={formuleSelected}
            paymentPeriodicity={paymentPeriodicity}
            isLoading={isLoading}
            onClick={handleClick}
            onCoverageViewed={sendEventViewedCoverageModal}
          />
        </VStack>
      </VStack>

      <VStack paddingX="kitt.4" paddingY="kitt.10" alignItems="center">
        {isDesktop ? (
          <HStack justifyContent="space-between" space="kitt.8" maxWidth={980}>
            <VStack space="kitt.8" flex={1}>
              <NextSteps />
              {mrhRedirectionFeature.getValue() && mrhRedirectionFeatureFormulesScreen.getValue() ? (
                <MrhRedirection withBanner slug="FORMULES" />
              ) : null}
              <PostSubscription />
              <FrequentlyAskedQuestions />
            </VStack>
            <View width={380}>
              <View position="sticky" top={96}>
                <CallbackModule />
              </View>
            </View>
          </HStack>
        ) : (
          <VStack space="kitt.10">
            <NextSteps />
            {mrhRedirectionFeature.getValue() && mrhRedirectionFeatureFormulesScreen.getValue() ? (
              <MrhRedirection withBanner slug="FORMULES" />
            ) : null}
            <PostSubscription />
            <CallbackModule />
            <FrequentlyAskedQuestions />
          </VStack>
        )}
      </VStack>

      <Footer />

      {!isDesktop && formuleSelected && price && (
        <MobileBottomBar
          title={formuleSelected.name}
          price={price}
          pricePeriodicityLabel={paymentPeriodicity === PaymentPeriodicity.MONTHLY ? 'mois' : 'an'}
          isLoading={isLoading}
          onSubmit={() => handleSubscription(formuleSelected)}
        />
      )}
    </VStack>
  );
}
