import type { IntlValidator } from '@ornikar/react-validators';
import { useComposeValidators, useDateValidator, useRequiredValidator, useValidator } from '@ornikar/react-validators';
import type { ValidatorCreator } from '@ornikar/validators';
import {
  createMaxLengthValidator,
  createValidDateAfterValidator,
  createValidDateBeforeValidator,
  invalidTypeSymbol,
} from '@ornikar/validators';
import { format, startOfTomorrow, subDays, subYears } from 'date-fns';
import { defineMessage } from 'react-intl';
import { PaymentPeriodicity } from '../../apis/types/PaymentOptions';
import type { LongQuoteAnswers } from '../../fsm/answers';
import { DateFromYearsAgo } from '../../utils/date';
import type { ValidatorType } from './types';

export const TODAY = new Date();
export const TOMORROW = format(startOfTomorrow(), 'yyyy-MM-dd');
export const THREE_YEARS_AGO = format(subDays(subYears(TODAY, 3), 1), 'yyyy-MM-dd');

export const yesterdayMinusNYears = (years: number): string => format(subDays(subYears(TODAY, years), 1), 'yyyy-MM-dd');

export const useDateBeforeTomorrowValidator = (): IntlValidator => {
  return useComposeValidators(
    useDateValidator(),
    useValidator(
      createValidDateBeforeValidator(() => TOMORROW),
      {
        id: 'maxTodayValidator',
        defaultMessage: "La date doit être antérieure ou égale à aujourd'hui",
      },
    ),
  );
};

const annuallyPaymentValidator: ValidatorType = () => (value: string) => {
  if (value !== (PaymentPeriodicity.ANNUALLY as string)) {
    return invalidTypeSymbol;
  }
  return undefined;
};

const userNameValidator: ValidatorCreator<PaymentPeriodicity> = (periodicity) => (value) => {
  if (periodicity === PaymentPeriodicity.ANNUALLY && value === 'ANNUEL') {
    return invalidTypeSymbol;
  }
  return undefined;
};

export const usePeriodicityFieldValidator = (): IntlValidator => {
  return useComposeValidators<any>(
    useRequiredValidator(),
    useValidator(annuallyPaymentValidator(), {
      id: 'annuallyPaymentValidator',
      defaultMessage: 'Veuillez indiquer une périodicité annuelle',
    }),
  );
};

export const usePrimaryFirstNameFieldValidator = (values: LongQuoteAnswers): IntlValidator => {
  return useComposeValidators(
    useRequiredValidator(),
    useValidator(userNameValidator(values.periodicity), {
      id: 'userNameValidator',
      defaultMessage: 'Vous ne pouvez pas avoir la même valeur de periodicité et de nom de famille',
    }),
  );
};

export const useDrivingFieldsValidator = (values: LongQuoteAnswers): IntlValidator => {
  const periodicityValidator = usePeriodicityFieldValidator();
  const primaryFirstNameValidator = usePrimaryFirstNameFieldValidator(values);

  return useComposeValidators(periodicityValidator, primaryFirstNameValidator);
};

export const useDateAfterNYearsAgoValidator = (years: number): IntlValidator => {
  return useValidator(
    createValidDateAfterValidator(() => yesterdayMinusNYears(years)),
    {
      id: 'minYearsAgoValidator',
      defaultMessage: `La date doit être postérieure ou égale au ${DateFromYearsAgo(years)}`,
    },
  );
};

export const useDateAfterThreeYearsAgoValidator = (): IntlValidator => {
  return useDateAfterNYearsAgoValidator(3);
};

export const useFirstNameMaxLengthValidator = (): IntlValidator => {
  return useValidator(
    createMaxLengthValidator(30),
    defineMessage({
      id: 'validators.firstName.tooLong',
      defaultMessage: 'Votre prénom ne peut pas être composé de plus de 30 charactères...',
    }),
  );
};
