import { DialogModal, Typography, VStack, View } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';

interface ButtonProps {
  label: ReactNode;
  onClick: () => void;
}

export interface InconsistencyModalProps {
  content: ReactNode;
  title: ReactNode;
  illustration?: ReactNode;
  mainButton: ButtonProps;
  secondButton: ButtonProps;
}

export function InconsistencyModal({
  content,
  title,
  illustration,
  mainButton: redirect,
  secondButton: cancel,
}: InconsistencyModalProps): ReactNode {
  return (
    <DialogModal
      title={
        <VStack alignContent="center" alignItems="center">
          {illustration && (
            <View marginTop="kitt.10" marginBottom="kitt.8">
              {illustration}
            </View>
          )}
          <Typography.Text variant="bold" textAlign="center">
            {title}
          </Typography.Text>
        </VStack>
      }
      actions={
        <VStack alignItems="center" space="kitt.3">
          <DialogModal.Button type="primary" onPress={redirect.onClick}>
            {redirect.label}
          </DialogModal.Button>
          <DialogModal.Button type="subtle" onPress={cancel.onClick}>
            {cancel.label}
          </DialogModal.Button>
        </VStack>
      }
    >
      <Typography.Text base="body" textAlign="center">
        {content}
      </Typography.Text>
    </DialogModal>
  );
}
