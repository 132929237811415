import type { ReactNode } from 'react';
import { InsuranceRecordAlcoholLevelTemplate } from '../../../../components/ScreenTemplates/InformationTemplates/InsuranceRecordTemplates/InsuranceRecordAlcoholLevelTemplate';
import { useSubscriptionFsmState } from '../../../../fsm/context';

export function SecondaryClaimRecordAlcoholLevelScreen(): ReactNode {
  const {
    context: { editingInsuranceRecord },
  } = useSubscriptionFsmState();

  return (
    <InsuranceRecordAlcoholLevelTemplate
      title="Quel était son taux d'alcoolémie lors de cet accident&nbsp;?"
      fieldName={`secondaryDriver.claimRecords[${editingInsuranceRecord.index}].bloodAlcoholLevel`}
    />
  );
}
