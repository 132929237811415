import type { MutableRefObject } from 'react';
import { useEffect, useRef } from 'react';

export function useTimeout(callback: () => any, delay?: number | null): MutableRefObject<any> {
  const timeoutRef = useRef<number>();
  const callbackRef = useRef<() => void>(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (typeof delay === 'number') {
      timeoutRef.current = window.setTimeout(() => callbackRef.current(), delay);

      return () => window.clearTimeout(timeoutRef.current);
    }
  }, [delay]);

  return timeoutRef;
}
