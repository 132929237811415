import { format, parse } from 'date-fns';
import type { LongQuoteAnswers, SecondaryRelationType } from '../../fsm/answers';
import {
  DeclareSecondaryDriverType,
  DriverHasBeenCovered,
  RegistrationDocumentOwnerIsSubscriberType,
  YesNoQuestion,
} from '../../fsm/answers';
import { computeAncienneAssurance, computeHasBeenCovered, computeInsurancePeriods } from '../computeInsuranceHistory';
import type {
  APIDate,
  Civility,
  FinancementType,
  LicenseType,
  LogementType,
  MaritalStatus,
  ParkingType,
  Profession,
  ServerLongQuote,
  ServerLongQuoteRetrieve,
  UsageType,
} from '../types/LongQuoteAnswers';
import {
  AssureDepuisType,
  DejaAssureAvecNomType,
  ServerAccompaniedDrivingType,
  ServerConducteurPrincipalType,
  ServerDejaAssureType,
  ServerProprieteType,
  ServerVehicleInsuredForType,
  TitulaireCarteGriseType,
} from '../types/LongQuoteAnswers';
import type { Version } from '../vehicules';
import { mapInsuranceRecordsToServerRequest, mapServerRequestToInsuranceRecords } from './insuranceRecordMapper';

export const toDate = (apiDate: APIDate): Date => {
  return parse(apiDate, 'yyyy-MM-dd', new Date());
};
export const toAPIDate = (date: Date): APIDate => {
  return format(date, 'yyyy-MM-dd');
};

const dejaAssureAvecNomTypeToServerDejaAssureTypeMapper = {
  [DejaAssureAvecNomType.Non]: ServerDejaAssureType.Non,
  [DejaAssureAvecNomType.OuiAMonNom]: ServerDejaAssureType.OuiAMonNom,
  [DejaAssureAvecNomType.OuiPasAMonNom]: ServerDejaAssureType.OuiPasAMonNom,
} satisfies Record<DejaAssureAvecNomType, ServerDejaAssureType>;

export function mapLongQuoteAnswersToServerRequest(answers: LongQuoteAnswers): Partial<ServerLongQuote> {
  const { primaryDriver } = answers;
  const { secondaryDriver = {} } = answers;
  const hasPrimaryBeenCovered = primaryDriver.hasBeenCovered!;
  const primaryInsurancePeriods = computeInsurancePeriods(
    hasPrimaryBeenCovered,
    primaryDriver.licenseDate!,
    primaryDriver.insurancePeriods,
  );
  const hasSecondaryBeenCovered = secondaryDriver.hasBeenCovered!;
  const secondaryInsurancePeriods = computeInsurancePeriods(
    hasSecondaryBeenCovered,
    secondaryDriver.licenseDate!,
    secondaryDriver.insurancePeriods,
  );

  const primaryHasBeenCovered =
    hasPrimaryBeenCovered && primaryInsurancePeriods
      ? computeAncienneAssurance(hasPrimaryBeenCovered, primaryInsurancePeriods)
      : undefined;

  const primaryDriverGender = parseInt(primaryDriver.civility!, 10);

  const secondaryHasBeenCovered =
    hasSecondaryBeenCovered && secondaryInsurancePeriods
      ? computeAncienneAssurance(hasSecondaryBeenCovered, secondaryInsurancePeriods)
      : undefined;

  const serverRequest: Partial<ServerLongQuote> = {
    codeAuto: answers.vehiculeVersion,
    licensePlate: answers.vehiculeImmatriculation,

    circulationDate: answers.vehiculeMiseEnCirculation,
    acquisitionDate: answers.vehiculeAcquisition,
    financing: parseInt(answers.vehiculeFinancement, 10),
    alreadyInsured: dejaAssureAvecNomTypeToServerDejaAssureTypeMapper[answers.vehiculeDejaAssureAvecNom],
    vehicleLastInsuranceEndDate: answers.vehicleLastInsuranceEndDate,

    // vehicle
    usage: parseInt(answers.usage, 10),
    parking: parseInt(answers.parkingLocation, 10),
    parkingZipCode: answers.parkingCodePostal,
    parkingCity: answers.parkingCommune,
    mileage: answers.kilometersPerYear,
    ownerVehicleRegistration: parseInt(answers.titulaireCartegrise, 10),

    // drivers
    principalDriver: Number(answers.secondaryRelation) || ServerConducteurPrincipalType.Vous,

    // principal driver
    firstNamePrincipalDriver: primaryDriver.firstName, // @TODO: not yet understood by the server
    lastNamePrincipalDriver: primaryDriver.lastName, // @TODO: not yet understood by the server
    genderPrincipalDriver: primaryDriverGender,
    birthDatePrincipalDriver: primaryDriver.birthDate,
    maritalStatusPrincipalDriver: parseInt(primaryDriver.maritalStatus!, 10),
    professionPrincipalDriver: parseInt(primaryDriver.profession!, 10),
    licensingDatePrincipalDriver: primaryDriver.licenseDate,
    licenseTypePrincipalDriver: Number(primaryDriver.licenseType),
    accompaniedDrivingPrincipalDriver: primaryDriver.accompaniedDriving === YesNoQuestion.YES ? 1 : 0,
    penaltyPrincipalDriver: primaryDriver.hasBeenCovered === DriverHasBeenCovered.NonJamais ? 100 : primaryDriver.crm,
    penaltyYearPrincipalDriver: primaryDriver.crm === 50 ? primaryDriver.yearsSinceHighestBonus : undefined,
    housingTypePrincipalDriver: parseInt(answers.housingType, 10),
    propertyOwnershipPrincipalDriver:
      // We don't support 'OTHER' option yet
      answers.isPropertyOwner === YesNoQuestion.YES ? ServerProprieteType.Proprietaire : ServerProprieteType.Locataire,
    oldInsurancePrincipalDriver: primaryHasBeenCovered,
    insurancePeriodsPrincipalDriver: primaryInsurancePeriods?.map((period) => ({
      startDate: period.start,
      endDate: period.end,
    })),
    principalDriverInsuranceRecords: mapInsuranceRecordsToServerRequest(
      primaryDriver.terminationRecords,
      primaryDriver.cancellationOrSuspensionRecords,
      primaryDriver.claimRecords,
    ),

    // secondary
    firstNameSecondaryDriver: secondaryDriver.firstName,
    lastNameSecondaryDriver: secondaryDriver.lastName,
    genderSecondaryDriver: secondaryDriver.civility ? parseInt(secondaryDriver.civility, 10) : undefined,
    birthDateSecondaryDriver: secondaryDriver.birthDate,
    maritalStatusSecondaryDriver: parseInt(primaryDriver.maritalStatus!, 10), // @TODO: we need a screen to set secondary this field
    professionSecondaryDriver: parseInt(primaryDriver.profession!, 10), // @TODO: we need a screen to set secondary this field
    licensingDateSecondaryDriver: secondaryDriver.licenseDate,
    licenseTypeSecondaryDriver: secondaryDriver.licenseType ? Number(secondaryDriver.licenseType) : undefined,
    accompaniedDrivingSecondaryDriver: secondaryDriver.accompaniedDriving === YesNoQuestion.YES ? 1 : 0,
    penaltySecondaryDriver:
      secondaryDriver.hasBeenCovered === DriverHasBeenCovered.NonJamais ? 100 : secondaryDriver.crm,
    penaltyYearSecondaryDriver: secondaryDriver.crm === 50 ? secondaryDriver.yearsSinceHighestBonus : undefined,
    oldInsuranceSecondaryDriver: secondaryHasBeenCovered,
    secondaryDriverInsuranceRecords: mapInsuranceRecordsToServerRequest(
      secondaryDriver.terminationRecords,
      secondaryDriver.cancellationOrSuspensionRecords,
      secondaryDriver.claimRecords,
    ),
    insurancePeriodsSecondaryDriver: secondaryInsurancePeriods?.map((period) => ({
      startDate: period.start,
      endDate: period.end,
    })),

    // contract
    gender: primaryDriverGender,
    firstName: primaryDriver.firstName,
    lastName: primaryDriver.lastName,
    address: answers.subscriberAdress1,
    addressLine2: answers.subscriberAdress2,
    addressLine3: answers.subscriberAdress3,
    addressZipCode: answers.subscriberCodePostal,
    addressCity: answers.subscriberCommune,
    birthCityPrincipalDriver: primaryDriver.birthCity,
    birthCountryCodePrincipalDriver: primaryDriver.birthCountryCode,
    phone: answers.subscriberPhone,
    email: answers.subscriberEmail,
    hasAcceptedCommercialCommunication: answers.hasAcceptedCommercialCommunication || false,
    hasAcceptedPartnersCommunication: answers.hasAcceptedPartnersCommunication || false,
    desiredEffectiveDate: answers.dateEffetSouhaite,
    // @TODO Will one day be removed. For now, keep changes as minimal as possible and force it to true.
    // See OSE-8564
    privacyPolicyAccepted: true,
  };

  if (serverRequest.alreadyInsured === ServerDejaAssureType.OuiAMonNom) {
    serverRequest.vehicleCurrentContractAnniversaryMonth = answers.vehicleCurrentContractAnniversaryMonth;
    serverRequest.vehicleInsuredFor =
      answers.vehiculeAssureDepuis === AssureDepuisType.Plus12Mois
        ? ServerVehicleInsuredForType.MoreThan12Months
        : ServerVehicleInsuredForType.LessThan12Months;
  }

  return serverRequest;
}

export function mapServerRequestToLongQuoteAnswers(
  request: ServerLongQuoteRetrieve & Partial<Version>,
): Partial<LongQuoteAnswers> {
  const titulaireCartegrise = request.ownerVehicleRegistration?.toString() as TitulaireCarteGriseType;

  const answers: Partial<LongQuoteAnswers> = {
    vehiculeImmatriculation: request.licensePlate,
    vehiculeMarque: request.brand,
    vehiculeModele: request.model,
    vehiculePuissanceFiscale: request.tax_horsepower,
    vehiculeCarburant: request.energy,
    vehiculeIsUtility: request.is_utility,
    vehiculeMiseEnCirculation: request.circulationDate,
    vehiculeVersion: request.codeAuto,
    vehiculeAcquisition: request.acquisitionDate,
    vehiculeFinancement: request.financing?.toString() as FinancementType,
    vehiculeDejaAssureAvecNom: request.alreadyInsured?.toString() as DejaAssureAvecNomType,
    vehicleCurrentContractAnniversaryMonth: request.vehicleCurrentContractAnniversaryMonth,
    vehicleLastInsuranceEndDate: request.vehicleLastInsuranceEndDate,

    // usage
    usage: request.usage?.toString() as UsageType,
    parkingLocation: request.parking?.toString() as ParkingType,
    parkingCodePostal: request.parkingZipCode,
    parkingCommune: request.parkingCity,
    kilometersPerYear: request.mileage,
    housingType: request.housingTypePrincipalDriver?.toString() as LogementType,
    isPropertyOwner:
      // We don't support 'OTHER' option yet
      request.propertyOwnershipPrincipalDriver === ServerProprieteType.Proprietaire
        ? YesNoQuestion.YES
        : YesNoQuestion.NO,

    titulaireCartegrise,
    registrationDocumentOwnerIsSubscriber:
      titulaireCartegrise === TitulaireCarteGriseType.Souscripteur
        ? RegistrationDocumentOwnerIsSubscriberType.Oui
        : RegistrationDocumentOwnerIsSubscriberType.Non,

    secondaryRelation: request.principalDriver?.toString() as SecondaryRelationType,

    primaryDriver: {
      firstName: request.firstNamePrincipalDriver,
      lastName: request.lastNamePrincipalDriver,
      civility: request.gender?.toString() as Civility,
      birthDate: request.birthDatePrincipalDriver,
      birthCity: request.birthCityPrincipalDriver,
      birthCountryCode: request.birthCountryCodePrincipalDriver,
      maritalStatus: request.maritalStatusPrincipalDriver?.toString() as MaritalStatus,
      profession: request.professionPrincipalDriver?.toString() as Profession,
      licenseDate: request.licensingDatePrincipalDriver,
      licenseType: request.licenseTypePrincipalDriver?.toString() as LicenseType,
      accompaniedDriving:
        request.accompaniedDrivingPrincipalDriver === ServerAccompaniedDrivingType.Yes
          ? YesNoQuestion.YES
          : YesNoQuestion.NO,
      crm: request.penaltyPrincipalDriver,
      hasBeenCovered: computeHasBeenCovered(
        request.oldInsurancePrincipalDriver,
        request.insurancePeriodsPrincipalDriver,
      ),
      insurancePeriods: request.insurancePeriodsPrincipalDriver?.map((period) => ({
        start: period.startDate,
        end: period.endDate,
      })),
      yearsSinceHighestBonus: request.penaltyYearPrincipalDriver,
      ...mapServerRequestToInsuranceRecords(request.principalDriverInsuranceRecords),
    },

    // @todo: missing question malus année
    declareSecondaryDriver:
      request.principalDriver !== ServerConducteurPrincipalType.Vous
        ? DeclareSecondaryDriverType.Oui
        : DeclareSecondaryDriverType.Non,
    subscriberFirstName: request.firstName,
    subscriberLastName: request.lastName,
    // subscriberCivilite: request.gender?.toString() as SexeType,
    subscriberAdress1: request.address || request.address,
    subscriberAdress2: request.addressLine2,
    subscriberAdress3: request.addressLine3,
    subscriberCompleteAdress: `${request.address || request.address}, ${request.addressZipCode} ${request.addressCity}`,
    subscriberCodePostal: request.addressZipCode,
    subscriberCommune: request.addressCity,
    subscriberPhone: request.phone,
    subscriberEmail: request.email,

    dateEffetSouhaite: request.desiredEffectiveDate,
  };

  if (request.vehicleInsuredFor !== null) {
    answers.vehiculeAssureDepuis =
      request.vehicleInsuredFor === ServerVehicleInsuredForType.MoreThan12Months
        ? AssureDepuisType.Plus12Mois
        : AssureDepuisType.Moins12Mois;
  }

  if (answers.declareSecondaryDriver === DeclareSecondaryDriverType.Oui) {
    return {
      ...answers,
      secondaryDriver: {
        firstName: request.firstNameSecondaryDriver,
        lastName: request.lastNameSecondaryDriver,
        civility: request.genderSecondaryDriver?.toString() as Civility,
        birthDate: request.birthDateSecondaryDriver,
        maritalStatus: request.maritalStatusSecondaryDriver?.toString() as MaritalStatus,
        profession: request.professionSecondaryDriver?.toString() as Profession,
        licenseDate: request.licensingDateSecondaryDriver,
        licenseType: request.licenseTypeSecondaryDriver?.toString() as LicenseType,
        accompaniedDriving:
          request.accompaniedDrivingSecondaryDriver === ServerAccompaniedDrivingType.Yes
            ? YesNoQuestion.YES
            : YesNoQuestion.NO,
        crm: request.penaltySecondaryDriver,
        hasBeenCovered: computeHasBeenCovered(
          request.oldInsuranceSecondaryDriver,
          request.insurancePeriodsSecondaryDriver,
        ),
        insurancePeriods: request.insurancePeriodsSecondaryDriver?.map((period) => ({
          start: period.startDate,
          end: period.endDate,
        })),
        yearsSinceHighestBonus: request.penaltyYearSecondaryDriver,
        ...mapServerRequestToInsuranceRecords(request.secondaryDriverInsuranceRecords ?? []),
      },
    };
  }

  return answers;
}
