import type { Attributes, HTMLAttributes, ReactNode } from 'react';
import type { ItemProps } from './components/Item';
import { Item } from './components/Item';

export interface TimelineProps {
  items: (ItemProps & { key?: Attributes['key'] })[];
  className?: HTMLAttributes<HTMLDivElement>['className'];
}

export function Timeline({ items, className }: TimelineProps): ReactNode {
  return (
    <div className={className}>
      {items.map(({ key, ...rest }, index) => (
        <Item key={key ?? index} {...rest} />
      ))}
    </div>
  );
}
