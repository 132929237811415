import type { ReactNode } from 'react';
import { InsuranceRecordAlcoholLevelTemplate } from '../../../../components/ScreenTemplates/InformationTemplates/InsuranceRecordTemplates/InsuranceRecordAlcoholLevelTemplate';

export function SecondaryTerminationRecordLicenseCanceledOrSuspendedClaimAlcoholLevelScreen(): ReactNode {
  return (
    <InsuranceRecordAlcoholLevelTemplate
      title="Quel était son taux d'alcoolémie lors de cet accident&nbsp;?"
      fieldName="secondaryDriver.terminationRecords[0].cancellationOrSuspensionRecord.claimRecord.bloodAlcoholLevel"
    />
  );
}
