import { theme } from '@ornikar/kitt-universal';

export interface ToggleThemeType {
  default: {
    backgroundColor: string;
    hover: {
      backgroundColor: string;
    };
  };
  checked: {
    backgroundColor: string;
    hover: {
      backgroundColor: string;
    };
  };
  pressed: {
    borderColor: string;
    shadowColor: string;
  };
  disabled: {
    backgroundColor: string;
    item: {
      backgroundColor: string;
    };
  };
  label: {
    default: {
      color: string;
    };
    disabled: {
      color: string;
    };
  };
}

export interface ToggleTheme {
  borderRadius: number;
  padding: number;
  regular: ToggleThemeType;
  accent: ToggleThemeType;
}

export const toggleColors: ToggleTheme = {
  borderRadius: 4 * 5,
  /** @deprecated use primary instead */
  regular: {
    default: {
      backgroundColor: theme.palettes.lateOcean.black200,
      hover: {
        backgroundColor: theme.palettes.lateOcean.black400,
      },
    },
    checked: {
      backgroundColor: theme.palettes.lateOcean.lateOcean,
      hover: {
        backgroundColor: theme.palettes.lateOcean.lateOceanLight1,
      },
    },
    pressed: {
      borderColor: theme.palettes.lateOcean.white,
      shadowColor: theme.palettes.lateOcean.black1000,
    },
    disabled: {
      backgroundColor: theme.palettes.lateOcean.black100,
      item: {
        backgroundColor: theme.palettes.lateOcean.white,
      },
    },
    label: {
      default: {
        color: theme.palettes.lateOcean.black1000,
      },
      disabled: {
        color: theme.palettes.lateOcean.black1000,
      },
    },
  },
  accent: {
    default: {
      backgroundColor: theme.palettes.lateOcean.moonPurple,
      hover: {
        backgroundColor: theme.palettes.lateOcean.lateOceanLight2,
      },
    },
    checked: {
      backgroundColor: theme.palettes.lateOcean.lateOceanDark1,
      hover: {
        backgroundColor: theme.palettes.lateOcean.black1000,
      },
    },
    pressed: {
      borderColor: theme.palettes.lateOcean.lateOceanLight1,
      shadowColor: theme.palettes.lateOcean.white,
    },
    disabled: {
      backgroundColor: theme.palettes.lateOcean.lateOceanLight2,
      item: {
        backgroundColor: theme.palettes.lateOcean.lateOceanLight1,
      },
    },
    label: {
      default: {
        color: theme.palettes.lateOcean.white,
      },
      disabled: {
        color: theme.palettes.lateOcean.lateOceanDark1,
      },
    },
  },
  padding: 4 * 5,
};
