import { CopyRegularIcon } from '@ornikar/kitt-icons/phosphor';
import { ActionCard, Button, HStack, Icon, Typography } from '@ornikar/kitt-universal';
import { type ReactNode } from 'react';
import { useSubscriptionFsmState } from '../../fsm/context';
import type { State } from '../../fsm/types';
import { MAPPED_STATES_TO_SECTIONS, Section } from '../../fsm/types';
import { useInsuranceDesktopMediaQuery } from '../../hooks/useInsuranceDesktopMediaQuery';
import { getUtmParamsAsSearchParams } from '../../utils/getUtmParams';

export function NumberCopyBanner(): ReactNode | null {
  const { context, value } = useSubscriptionFsmState();
  const isDesktop = useInsuranceDesktopMediaQuery();
  const source = getUtmParamsAsSearchParams().get('utm_source');

  const submissionNumber = context.tarificationResult?.isOk && context.tarificationResult.submissionNumber;
  const currentSection = MAPPED_STATES_TO_SECTIONS[value as State];

  const displayNumberCopyBanner =
    isDesktop &&
    context.agentEmail &&
    source === 'salesforce' &&
    submissionNumber &&
    currentSection === Section.TARIFICATION;

  return displayNumberCopyBanner ? (
    <ActionCard variant="primary" minWidth={572} position="fixed" bottom="kitt.8" right="kitt.8" zIndex={10}>
      <HStack justifyContent="space-between" alignItems="center" space={32} padding="kitt.4">
        <HStack space="kitt.4" alignItems="flex-end">
          <Typography.Text base="body" variant="bold">
            Numéro de devis:
          </Typography.Text>
          <Typography.Text base="header4" variant="bold">
            {submissionNumber}
          </Typography.Text>
        </HStack>

        <Button
          onPress={() => {
            navigator.clipboard.writeText(submissionNumber);
          }}
        >
          <HStack space="kitt.2" alignItems="center">
            <Icon size="kitt.4" color="kitt.secondary" icon={<CopyRegularIcon />} />
            Copier le numéro
          </HStack>
        </Button>
      </HStack>
    </ActionCard>
  ) : null;
}
