export const carData = (hexColor = '#000000'): any => {
  const k = [
    parseInt(hexColor.replace('#', '').slice(0, 2), 16) / 255,
    parseInt(hexColor.replace('#', '').slice(2, 4), 16) / 255,
    parseInt(hexColor.replace('#', '').slice(4, 6), 16) / 255,
  ];

  return {
    v: '5.5.2',
    fr: 50,
    ip: 0,
    op: 139,
    w: 500,
    h: 500,
    nm: 'CarTypes',
    ddd: 0,
    assets: [],
    layers: [
      {
        ddd: 0,
        ind: 1,
        ty: 4,
        nm: 'Shape Layer 8',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [105.25, 212.007, 0], ix: 2 },
          a: { a: 0, k: [-76.75, -44.993, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [14.5, 35.5],
                      [-48, 35.5],
                    ],
                    c: false,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [...k, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 6, ix: 5 },
                lc: 2,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [...k, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-77, -45], ix: 2 },
                a: { a: 0, k: [-17, 35.493], ix: 1 },
                s: { a: 0, k: [100, 98.611], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Shape 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'tm',
            s: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 127, s: [0] },
                { t: 133, s: [100] },
              ],
              ix: 1,
            },
            e: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 114, s: [0] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 122, s: [100] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 127, s: [100] },
                { t: 133, s: [100] },
              ],
              ix: 2,
            },
            o: { a: 0, k: 0, ix: 3 },
            m: 1,
            ix: 2,
            nm: 'Trim Paths 1',
            mn: 'ADBE Vector Filter - Trim',
            hd: false,
          },
        ],
        ip: 114,
        op: 137,
        st: 114,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 2,
        ty: 4,
        nm: 'Shape Layer 7',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [152.25, 190.007, 0], ix: 2 },
          a: { a: 0, k: [-76.75, -44.993, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [14.5, 35.5],
                      [-48, 35.5],
                    ],
                    c: false,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [...k, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 6, ix: 5 },
                lc: 2,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [0.796078012504, 0.882353001015, 1, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-77, -45], ix: 2 },
                a: { a: 0, k: [-17, 35.493], ix: 1 },
                s: { a: 0, k: [100, 98.611], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Shape 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'tm',
            s: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 120, s: [0] },
                { t: 126, s: [100] },
              ],
              ix: 1,
            },
            e: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 107, s: [0] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 115, s: [100] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 120, s: [100] },
                { t: 126, s: [100] },
              ],
              ix: 2,
            },
            o: { a: 0, k: 0, ix: 3 },
            m: 1,
            ix: 2,
            nm: 'Trim Paths 1',
            mn: 'ADBE Vector Filter - Trim',
            hd: false,
          },
        ],
        ip: 107,
        op: 130,
        st: 107,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 3,
        ty: 4,
        nm: 'Shape Layer 6',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [179.25, 210.007, 0], ix: 2 },
          a: { a: 0, k: [-76.75, -44.993, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [14.5, 35.5],
                      [-48, 35.5],
                    ],
                    c: false,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [...k, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 6, ix: 5 },
                lc: 2,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [0.796078012504, 0.882353001015, 1, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-77, -45], ix: 2 },
                a: { a: 0, k: [-17, 35.493], ix: 1 },
                s: { a: 0, k: [100, 98.611], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Shape 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'tm',
            s: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 82, s: [0] },
                { t: 88, s: [100] },
              ],
              ix: 1,
            },
            e: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 69, s: [0] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 77, s: [100] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 82, s: [100] },
                { t: 88, s: [100] },
              ],
              ix: 2,
            },
            o: { a: 0, k: 0, ix: 3 },
            m: 1,
            ix: 2,
            nm: 'Trim Paths 1',
            mn: 'ADBE Vector Filter - Trim',
            hd: false,
          },
        ],
        ip: 69,
        op: 92,
        st: 69,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 4,
        ty: 4,
        nm: 'Shape Layer 3',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [123.25, 187.007, 0], ix: 2 },
          a: { a: 0, k: [-76.75, -44.993, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [14.5, 35.5],
                      [-48, 35.5],
                    ],
                    c: false,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [...k, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 6, ix: 5 },
                lc: 2,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [0.796078012504, 0.882353001015, 1, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-77, -45], ix: 2 },
                a: { a: 0, k: [-17, 35.493], ix: 1 },
                s: { a: 0, k: [100, 98.611], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Shape 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'tm',
            s: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 25, s: [0] },
                { t: 31, s: [100] },
              ],
              ix: 1,
            },
            e: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 12, s: [0] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 20, s: [100] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 25, s: [100] },
                { t: 31, s: [100] },
              ],
              ix: 2,
            },
            o: { a: 0, k: 0, ix: 3 },
            m: 1,
            ix: 2,
            nm: 'Trim Paths 1',
            mn: 'ADBE Vector Filter - Trim',
            hd: false,
          },
        ],
        ip: 12,
        op: 35,
        st: 12,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 5,
        ty: 4,
        nm: 'Shape Layer 5',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [210.25, 192.007, 0], ix: 2 },
          a: { a: 0, k: [-76.75, -44.993, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [14.5, 35.5],
                      [-48, 35.5],
                    ],
                    c: false,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [...k, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 6, ix: 5 },
                lc: 2,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [0.796078012504, 0.882353001015, 1, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-77, -45], ix: 2 },
                a: { a: 0, k: [-17, 35.493], ix: 1 },
                s: { a: 0, k: [100, 98.611], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Shape 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'tm',
            s: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 93, s: [0] },
                { t: 99, s: [100] },
              ],
              ix: 1,
            },
            e: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 80, s: [0] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 88, s: [100] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 93, s: [100] },
                { t: 99, s: [100] },
              ],
              ix: 2,
            },
            o: { a: 0, k: 0, ix: 3 },
            m: 1,
            ix: 2,
            nm: 'Trim Paths 1',
            mn: 'ADBE Vector Filter - Trim',
            hd: false,
          },
        ],
        ip: 80,
        op: 103,
        st: 80,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 6,
        ty: 4,
        nm: 'Shape Layer 2',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [174.25, 152.007, 0], ix: 2 },
          a: { a: 0, k: [-76.75, -44.993, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [14.5, 35.5],
                      [-48, 35.5],
                    ],
                    c: false,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [...k, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 6, ix: 5 },
                lc: 2,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [0.796078012504, 0.882353001015, 1, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-77, -45], ix: 2 },
                a: { a: 0, k: [-17, 35.493], ix: 1 },
                s: { a: 0, k: [100, 98.611], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Shape 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'tm',
            s: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 36, s: [0] },
                { t: 42, s: [100] },
              ],
              ix: 1,
            },
            e: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 23, s: [0] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 31, s: [100] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 36, s: [100] },
                { t: 42, s: [100] },
              ],
              ix: 2,
            },
            o: { a: 0, k: 0, ix: 3 },
            m: 1,
            ix: 2,
            nm: 'Trim Paths 1',
            mn: 'ADBE Vector Filter - Trim',
            hd: false,
          },
        ],
        ip: 23,
        op: 46,
        st: 23,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 7,
        ty: 4,
        nm: 'Shape Layer 4',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [133.25, 205.007, 0], ix: 2 },
          a: { a: 0, k: [-76.75, -44.993, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [14.5, 35.5],
                      [-48, 35.5],
                    ],
                    c: false,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [...k, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 6, ix: 5 },
                lc: 2,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [0.796078012504, 0.882353001015, 1, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-77, -45], ix: 2 },
                a: { a: 0, k: [-17, 35.493], ix: 1 },
                s: { a: 0, k: [100, 98.611], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Shape 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'tm',
            s: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 70, s: [0] },
                { t: 76, s: [100] },
              ],
              ix: 1,
            },
            e: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 57, s: [0] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 65, s: [100] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 70, s: [100] },
                { t: 76, s: [100] },
              ],
              ix: 2,
            },
            o: { a: 0, k: 0, ix: 3 },
            m: 1,
            ix: 2,
            nm: 'Trim Paths 1',
            mn: 'ADBE Vector Filter - Trim',
            hd: false,
          },
        ],
        ip: 57,
        op: 80,
        st: 57,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 8,
        ty: 4,
        nm: 'Shape Layer 1',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [133.25, 205.007, 0], ix: 2 },
          a: { a: 0, k: [-76.75, -44.993, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [14.5, 35.5],
                      [-48, 35.5],
                    ],
                    c: false,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [...k, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 6, ix: 5 },
                lc: 2,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [0.796078012504, 0.882353001015, 1, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-77, -45], ix: 2 },
                a: { a: 0, k: [-17, 35.493], ix: 1 },
                s: { a: 0, k: [100, 98.611], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Shape 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'tm',
            s: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 13, s: [0] },
                { t: 19, s: [100] },
              ],
              ix: 1,
            },
            e: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [0] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 8, s: [100] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 13, s: [100] },
                { t: 19, s: [100] },
              ],
              ix: 2,
            },
            o: { a: 0, k: 0, ix: 3 },
            m: 1,
            ix: 2,
            nm: 'Trim Paths 1',
            mn: 'ADBE Vector Filter - Trim',
            hd: false,
          },
        ],
        ip: 0,
        op: 23,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 9,
        ty: 4,
        nm: 'WhR',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 95,
                s: [333.206, 281.264, 0],
                to: [-7.958, 0, 0],
                ti: [7.958, 0, 0],
              },
              {
                i: { x: 0.667, y: 0.667 },
                o: { x: 0.333, y: 0.333 },
                t: 104,
                s: [285.456, 281.264, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 128,
                s: [285.456, 281.264, 0],
                to: [7.958, 0, 0],
                ti: [-7.958, 0, 0],
              },
              { t: 137, s: [333.206, 281.264, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [37.531, 29.708, 0], ix: 1 },
          s: { a: 0, k: [664, 664, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -1.848],
                      [1.848, 0],
                      [0, 1.848],
                      [-1.847, 0],
                    ],
                    o: [
                      [0, 1.848],
                      [-1.847, 0],
                      [0, -1.848],
                      [1.848, 0],
                    ],
                    v: [
                      [3.345, -0.001],
                      [-0.001, 3.346],
                      [-3.345, -0.001],
                      [-0.001, -3.346],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [...k, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 1, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 10,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [37.531, 29.708], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 6',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 139,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 10,
        ty: 4,
        nm: 'WhL',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [169.197, 281.264, 0], ix: 2 },
          a: { a: 0, k: [12.831, 29.708, 0], ix: 1 },
          s: { a: 0, k: [664, 664, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -1.848],
                      [1.847, 0],
                      [0, 1.848],
                      [-1.848, 0],
                    ],
                    o: [
                      [0, 1.848],
                      [-1.848, 0],
                      [0, -1.848],
                      [1.847, 0],
                    ],
                    v: [
                      [3.345, -0.001],
                      [0, 3.346],
                      [-3.345, -0.001],
                      [0, -3.346],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [...k, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 1, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 10,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [12.831, 29.708], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 7',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 139,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 11,
        ty: 4,
        nm: 'Layer 2/CarTypes_t Outlines - Group 1',
        parent: 18,
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [24.443, 26.3, 0], ix: 2 },
          a: { a: 0, k: [24.443, 26.331, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 17,
                      s: [
                        {
                          i: [
                            [4.38, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 4.38],
                          ],
                          v: [
                            [-1.617, 3.966],
                            [-6.314, 3.966],
                            [-6.314, -3.716],
                            [6.314, -3.841],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 26,
                      s: [
                        {
                          i: [
                            [4.38, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.191, 6.097],
                          ],
                          v: [
                            [-1.617, 3.966],
                            [-6.314, 3.966],
                            [-6.314, -5.222],
                            [6.314, -5.347],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 55,
                      s: [
                        {
                          i: [
                            [4.38, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.191, 6.097],
                          ],
                          v: [
                            [-1.617, 3.966],
                            [-6.314, 3.966],
                            [-6.314, -5.222],
                            [6.314, -5.347],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 64,
                      s: [
                        {
                          i: [
                            [4.38, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.191, 6.097],
                          ],
                          v: [
                            [-1.617, 3.966],
                            [-11.661, -3.79],
                            [-6.325, -3.641],
                            [6.32, -3.54],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 95,
                      s: [
                        {
                          i: [
                            [4.38, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.191, 6.097],
                          ],
                          v: [
                            [-1.617, 3.966],
                            [-11.661, -3.79],
                            [-6.325, -3.641],
                            [6.32, -3.54],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 104,
                      s: [
                        {
                          i: [
                            [4.38, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 4.38],
                          ],
                          v: [
                            [-3.5, 3.966],
                            [-6.314, 3.966],
                            [-6.314, -3.716],
                            [3.679, -3.841],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 128,
                      s: [
                        {
                          i: [
                            [4.38, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 4.38],
                          ],
                          v: [
                            [-3.5, 3.966],
                            [-6.314, 3.966],
                            [-6.314, -3.716],
                            [3.679, -3.841],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      t: 137,
                      s: [
                        {
                          i: [
                            [4.38, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 4.38],
                          ],
                          v: [
                            [-1.617, 3.966],
                            [-6.314, 3.966],
                            [-6.314, -3.716],
                            [6.314, -3.841],
                          ],
                          c: true,
                        },
                      ],
                    },
                  ],
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [...k, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 1, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 10,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [24.443, 26.331], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 1',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 139,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 12,
        ty: 4,
        nm: 'Layer 2/CarTypes_t Outlines - Group 9',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [235.563, 218.411, 0], ix: 2 },
          a: { a: 0, k: [22.826, 20.243, 0], ix: 1 },
          s: { a: 0, k: [664, 664, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 17,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [22.826, 18.121],
                            [22.826, 22.365],
                          ],
                          c: false,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 26,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [22.835, 14.77],
                            [22.826, 22.365],
                          ],
                          c: false,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 55,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [22.835, 14.77],
                            [22.826, 22.365],
                          ],
                          c: false,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 60,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [22.835, 21.735],
                            [22.826, 22.365],
                          ],
                          c: false,
                        },
                      ],
                    },
                    {
                      t: 95,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [22.835, 21.735],
                            [22.826, 22.365],
                          ],
                          c: false,
                        },
                      ],
                    },
                  ],
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [...k, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 1, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 10,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'tr',
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 17,
                      s: [0, 0],
                      to: [0.667, -0.251],
                      ti: [-0.667, 0.251],
                    },
                    {
                      i: { x: 0.667, y: 0.667 },
                      o: { x: 0.333, y: 0.333 },
                      t: 26,
                      s: [4, -1.506],
                      to: [0, 0],
                      ti: [0, 0],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 55,
                      s: [4, -1.506],
                      to: [0, 0.25],
                      ti: [0, -0.333],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 60,
                      s: [4, -0.006],
                      to: [0, 0.333],
                      ti: [0, -0.083],
                    },
                    {
                      i: { x: 0.667, y: 0.667 },
                      o: { x: 0.333, y: 0.333 },
                      t: 64,
                      s: [4, 0.494],
                      to: [0, 0],
                      ti: [0, 0],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 95,
                      s: [4, 0.494],
                      to: [0, -0.083],
                      ti: [0, 0.083],
                    },
                    { t: 104, s: [4, -0.006] },
                  ],
                  ix: 2,
                },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 2',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 62,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 13,
        ty: 4,
        nm: 'Layer 2/CarTypes_t Outlines - Group 2',
        parent: 18,
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [22.826, 20.243, 0], ix: 2 },
          a: { a: 0, k: [22.826, 20.243, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 17,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [22.826, 18.121],
                            [22.826, 22.365],
                          ],
                          c: false,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 26,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [22.826, 15.259],
                            [22.826, 22.365],
                          ],
                          c: false,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 55,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [22.826, 15.259],
                            [22.826, 22.365],
                          ],
                          c: false,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 60,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [22.835, 21.886],
                            [22.826, 22.365],
                          ],
                          c: false,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 95,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [22.835, 21.886],
                            [22.826, 22.365],
                          ],
                          c: false,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 101,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [22.826, 18.121],
                            [22.826, 22.365],
                          ],
                          c: false,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 128,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [22.826, 18.121],
                            [22.826, 22.365],
                          ],
                          c: false,
                        },
                      ],
                    },
                    {
                      t: 137,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [22.826, 18.121],
                            [22.826, 22.365],
                          ],
                          c: false,
                        },
                      ],
                    },
                  ],
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [...k, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 1, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 10,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'tr',
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 17,
                      s: [0, 0],
                      to: [-1.23, -0.251],
                      ti: [1.23, 0.251],
                    },
                    {
                      i: { x: 0.667, y: 0.667 },
                      o: { x: 0.333, y: 0.333 },
                      t: 26,
                      s: [-7.38, -1.506],
                      to: [0, 0],
                      ti: [0, 0],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 55,
                      s: [-7.38, -1.506],
                      to: [0, 0.22],
                      ti: [0, -0.283],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 60,
                      s: [-7.38, -0.184],
                      to: [0, 0.283],
                      ti: [0, -0.063],
                    },
                    {
                      i: { x: 0.667, y: 0.667 },
                      o: { x: 0.333, y: 0.333 },
                      t: 64,
                      s: [-7.38, 0.193],
                      to: [0, 0],
                      ti: [0, 0],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 95,
                      s: [-7.38, 0.193],
                      to: [1.23, -0.032],
                      ti: [-1.23, 0.032],
                    },
                    {
                      i: { x: 0.667, y: 0.667 },
                      o: { x: 0.333, y: 0.333 },
                      t: 101,
                      s: [0, 0],
                      to: [0, 0],
                      ti: [0, 0],
                    },
                    {
                      i: { x: 0.667, y: 0.667 },
                      o: { x: 0.333, y: 0.333 },
                      t: 128,
                      s: [0, 0],
                      to: [0, 0],
                      ti: [0, 0],
                    },
                    { t: 137, s: [0, 0] },
                  ],
                  ix: 2,
                },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 2',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 139,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 14,
        ty: 4,
        nm: 'Layer 2/CarTypes_t Outlines - Group 3',
        parent: 18,
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [22.493, 20.243, 0], ix: 2 },
          a: { a: 0, k: [22.493, 20.243, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 17,
                      s: [
                        {
                          i: [
                            [1.123, 0],
                            [0, 0],
                            [1.051, -1.145],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [-1.555, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.797, -0.792],
                          ],
                          v: [
                            [3.773, -2.122],
                            [-3.46, -2.122],
                            [-7.548, -0.326],
                            [-9.794, 2.122],
                            [9.794, 2.122],
                            [6.771, -0.885],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 26,
                      s: [
                        {
                          i: [
                            [1.123, 0],
                            [0, 0],
                            [0.792, -1.777],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [-2.486, 0.057],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.914, -1.218],
                          ],
                          v: [
                            [5.995, -5.15],
                            [-7.507, -5.134],
                            [-11.595, -3.338],
                            [-13.842, 2.122],
                            [12.016, 2.106],
                            [8.993, -3.913],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 55,
                      s: [
                        {
                          i: [
                            [1.123, 0],
                            [0, 0],
                            [0.792, -1.777],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [-2.486, 0.057],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.914, -1.218],
                          ],
                          v: [
                            [5.995, -5.15],
                            [-7.507, -5.134],
                            [-11.595, -3.338],
                            [-13.842, 2.122],
                            [12.016, 2.106],
                            [8.993, -3.913],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 64,
                      s: [
                        {
                          i: [
                            [1.123, 0],
                            [0, 0],
                            [-2.069, -1.826],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [-1.055, 0.139],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-1.064, -0.778],
                          ],
                          v: [
                            [3.472, -2.329],
                            [2.188, -2.273],
                            [4.35, 0.352],
                            [6.471, 2.127],
                            [9.794, 2.122],
                            [6.602, -0.734],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 95,
                      s: [
                        {
                          i: [
                            [1.123, 0],
                            [0, 0],
                            [-2.069, -1.826],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [-1.055, 0.139],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-1.064, -0.778],
                          ],
                          v: [
                            [3.472, -2.329],
                            [2.188, -2.273],
                            [4.35, 0.352],
                            [6.471, 2.127],
                            [9.794, 2.122],
                            [6.602, -0.734],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 104,
                      s: [
                        {
                          i: [
                            [1.123, 0],
                            [0, 0],
                            [1.051, -1.145],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [-1.555, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.797, -0.792],
                          ],
                          v: [
                            [3.773, -2.122],
                            [-0.824, -2.122],
                            [-4.912, -0.326],
                            [-7.159, 2.122],
                            [9.794, 2.122],
                            [6.771, -0.885],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 128,
                      s: [
                        {
                          i: [
                            [1.123, 0],
                            [0, 0],
                            [1.051, -1.145],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [-1.555, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.797, -0.792],
                          ],
                          v: [
                            [3.773, -2.122],
                            [-0.824, -2.122],
                            [-4.912, -0.326],
                            [-7.159, 2.122],
                            [9.794, 2.122],
                            [6.771, -0.885],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      t: 137,
                      s: [
                        {
                          i: [
                            [1.123, 0],
                            [0, 0],
                            [1.051, -1.145],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [-1.555, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.797, -0.792],
                          ],
                          v: [
                            [3.773, -2.122],
                            [-3.46, -2.122],
                            [-7.548, -0.326],
                            [-9.794, 2.122],
                            [9.794, 2.122],
                            [6.771, -0.885],
                          ],
                          c: true,
                        },
                      ],
                    },
                  ],
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [...k, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 1, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 10,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'tr',
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 17,
                      s: [22.493, 20.243],
                      to: [0, -0.251],
                      ti: [0, 0.251],
                    },
                    {
                      i: { x: 0.667, y: 0.667 },
                      o: { x: 0.333, y: 0.333 },
                      t: 26,
                      s: [22.493, 18.737],
                      to: [0, 0],
                      ti: [0, 0],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 55,
                      s: [22.493, 18.737],
                      to: [0, 0.327],
                      ti: [0, -0.327],
                    },
                    {
                      i: { x: 0.667, y: 0.667 },
                      o: { x: 0.333, y: 0.333 },
                      t: 64,
                      s: [22.493, 20.7],
                      to: [0, 0],
                      ti: [0, 0],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 95,
                      s: [22.493, 20.7],
                      to: [-0.628, -0.076],
                      ti: [0.628, 0.076],
                    },
                    {
                      i: { x: 0.667, y: 0.667 },
                      o: { x: 0.333, y: 0.333 },
                      t: 104,
                      s: [18.728, 20.243],
                      to: [0, 0],
                      ti: [0, 0],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 128,
                      s: [18.728, 20.243],
                      to: [0.628, 0],
                      ti: [-0.628, 0],
                    },
                    { t: 137, s: [22.493, 20.243] },
                  ],
                  ix: 2,
                },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 3',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 139,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 15,
        ty: 4,
        nm: 'Layer 2/CarTypes_t Outlines - Group 4',
        parent: 18,
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: {
            a: 1,
            k: [
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 55, s: [0] },
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 64, s: [21] },
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 95, s: [21] },
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 104, s: [0] },
              { t: 128, s: [0] },
            ],
            ix: 10,
          },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 17,
                s: [41.887, 24.924, 0],
                to: [0.132, -0.138, 0],
                ti: [-0.132, 0.138, 0],
              },
              {
                i: { x: 0.667, y: 0.667 },
                o: { x: 0.333, y: 0.333 },
                t: 26,
                s: [42.677, 24.095, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 55,
                s: [42.677, 24.095, 0],
                to: [0, 0.213, 0],
                ti: [0, -0.213, 0],
              },
              {
                i: { x: 0.667, y: 0.667 },
                o: { x: 0.333, y: 0.333 },
                t: 64,
                s: [42.677, 25.375, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 95,
                s: [42.677, 25.375, 0],
                to: [-1.255, 0, 0],
                ti: [1.255, 0, 0],
              },
              {
                i: { x: 0.667, y: 0.667 },
                o: { x: 0.333, y: 0.333 },
                t: 104,
                s: [35.147, 25.375, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 128,
                s: [35.147, 25.375, 0],
                to: [1.123, -0.075, 0],
                ti: [-1.123, 0.075, 0],
              },
              { t: 137, s: [41.887, 24.924, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [41.887, 24.924, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-0.294, -1.215],
                      [0, 0],
                      [0, 1.145],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [-1.144, 0],
                      [0, 0],
                      [1.251, 0],
                    ],
                    v: [
                      [2.505, 1.036],
                      [-0.433, 1.036],
                      [-2.505, -1.036],
                      [-0.128, -1.036],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [...k, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 1, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 10,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [41.887, 24.924], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 4',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 139,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 16,
        ty: 4,
        nm: 'Layer 2/CarTypes_t Outlines - Group 5',
        parent: 18,
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 17,
                s: [6.139, 27.36, 0],
                to: [0, -0.063, 0],
                ti: [0, 0.063, 0],
              },
              {
                i: { x: 0.667, y: 0.667 },
                o: { x: 0.333, y: 0.333 },
                t: 26,
                s: [6.139, 26.984, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 55,
                s: [6.139, 26.984, 0],
                to: [0, 0.025, 0],
                ti: [0, -0.025, 0],
              },
              {
                i: { x: 0.667, y: 0.667 },
                o: { x: 0.333, y: 0.333 },
                t: 64,
                s: [6.139, 27.134, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 0.667 },
                o: { x: 0.333, y: 0.333 },
                t: 95,
                s: [6.139, 27.134, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 128,
                s: [6.139, 27.134, 0],
                to: [0, 0.038, 0],
                ti: [0, -0.038, 0],
              },
              { t: 137, s: [6.139, 27.36, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [6.139, 27.36, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 17,
                      s: [
                        {
                          i: [
                            [0.551, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0.551],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.551, 0],
                            [0, -0.551],
                          ],
                          v: [
                            [0.335, -0.997],
                            [-1.333, -0.997],
                            [-1.333, 0.997],
                            [0.335, 0.997],
                            [1.333, 0],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 26,
                      s: [
                        {
                          i: [
                            [0.551, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.051, 1.232],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.551, 0],
                            [-0.055, -1.327],
                          ],
                          v: [
                            [0.335, -2.409],
                            [-1.333, -2.409],
                            [-1.333, 0.997],
                            [0.335, 0.997],
                            [1.333, -0.753],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 55,
                      s: [
                        {
                          i: [
                            [0.551, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.051, 1.232],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.551, 0],
                            [-0.055, -1.327],
                          ],
                          v: [
                            [0.335, -2.409],
                            [-1.333, -2.409],
                            [-1.333, 0.997],
                            [0.335, 0.997],
                            [1.333, -0.753],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 64,
                      s: [
                        {
                          i: [
                            [0.551, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.004, 1.028],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.551, 0],
                            [0.004, -0.836],
                          ],
                          v: [
                            [0.335, -0.903],
                            [-1.258, -0.903],
                            [-1.258, 0.997],
                            [0.335, 0.997],
                            [1.801, -0.037],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 95,
                      s: [
                        {
                          i: [
                            [0.551, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.004, 1.028],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.551, 0],
                            [0.004, -0.836],
                          ],
                          v: [
                            [0.335, -0.903],
                            [-1.258, -0.903],
                            [-1.258, 0.997],
                            [0.335, 0.997],
                            [1.801, -0.037],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 104,
                      s: [
                        {
                          i: [
                            [0.551, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.004, 1.195],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.551, 0],
                            [-0.003, -0.836],
                          ],
                          v: [
                            [0.335, -1.138],
                            [-1.258, -1.138],
                            [-1.258, 1.35],
                            [0.335, 1.35],
                            [1.707, -0.037],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 128,
                      s: [
                        {
                          i: [
                            [0.551, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.004, 1.195],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.551, 0],
                            [-0.003, -0.836],
                          ],
                          v: [
                            [0.335, -1.138],
                            [-1.258, -1.138],
                            [-1.258, 1.35],
                            [0.335, 1.35],
                            [1.707, -0.037],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      t: 137,
                      s: [
                        {
                          i: [
                            [0.551, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0.551],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.551, 0],
                            [0, -0.551],
                          ],
                          v: [
                            [0.335, -0.997],
                            [-1.333, -0.997],
                            [-1.333, 0.997],
                            [0.335, 0.997],
                            [1.333, 0],
                          ],
                          c: true,
                        },
                      ],
                    },
                  ],
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [...k, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 1, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 10,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [6.139, 27.36], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 5',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 139,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 17,
        ty: 4,
        nm: 'Layer 2/CarTypes_t Outlines - Group 8',
        parent: 18,
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [25.261, 26.331, 0], ix: 2 },
          a: { a: 0, k: [25.261, 26.331, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 17,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0.769],
                            [0, 0],
                            [-2.177, 0],
                            [0, 0],
                            [0, -3.612],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [-0.768, 0],
                            [0, 0],
                            [0, -2.178],
                            [0, 0],
                            [3.612, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-15.775, 3.966],
                            [-19.064, 3.966],
                            [-20.456, 2.574],
                            [-20.456, -0.023],
                            [-16.514, -3.966],
                            [13.915, -3.966],
                            [20.456, 2.574],
                            [20.456, 3.966],
                            [15.562, 3.966],
                          ],
                          c: false,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 26,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0.769],
                            [0, 0],
                            [-2.177, 0],
                            [0, 0],
                            [0, -3.612],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [-0.768, 0],
                            [0, 0],
                            [0, -2.178],
                            [0, 0],
                            [3.612, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-15.775, 3.966],
                            [-19.064, 3.966],
                            [-20.456, 2.574],
                            [-20.456, -1.529],
                            [-16.514, -5.472],
                            [13.915, -5.472],
                            [20.456, 1.068],
                            [20.456, 3.966],
                            [15.562, 3.966],
                          ],
                          c: false,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 55,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0.769],
                            [0, 0],
                            [-2.177, 0],
                            [0, 0],
                            [0, -3.612],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [-0.768, 0],
                            [0, 0],
                            [0, -2.178],
                            [0, 0],
                            [3.612, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-15.775, 3.966],
                            [-19.064, 3.966],
                            [-20.456, 2.574],
                            [-20.456, -1.529],
                            [-16.514, -5.472],
                            [13.915, -5.472],
                            [20.456, 1.068],
                            [20.456, 3.966],
                            [15.562, 3.966],
                          ],
                          c: false,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 64,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0.769],
                            [0, 0],
                            [-2.177, 0],
                            [0, 0],
                            [0, -3.612],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [-0.768, 0],
                            [0, 0],
                            [0, -2.178],
                            [0, 0],
                            [3.612, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-15.775, 3.966],
                            [-19.064, 3.966],
                            [-20.456, 2.574],
                            [-20.456, -0.023],
                            [-16.514, -3.966],
                            [13.915, -3.213],
                            [20.456, 2.574],
                            [20.456, 3.966],
                            [15.562, 3.966],
                          ],
                          c: false,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 95,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0.769],
                            [0, 0],
                            [-2.177, 0],
                            [0, 0],
                            [0, -3.612],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [-0.768, 0],
                            [0, 0],
                            [0, -2.178],
                            [0, 0],
                            [3.612, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-15.775, 3.966],
                            [-19.064, 3.966],
                            [-20.456, 2.574],
                            [-20.456, -0.023],
                            [-16.514, -3.966],
                            [13.915, -3.213],
                            [20.456, 2.574],
                            [20.456, 3.966],
                            [15.562, 3.966],
                          ],
                          c: false,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 104,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0.769],
                            [0, 0],
                            [-2.177, 0],
                            [0, 0],
                            [0, -3.612],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [-0.768, 0],
                            [0, 0],
                            [0, -2.178],
                            [0, 0],
                            [3.612, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-15.775, 3.966],
                            [-19.064, 3.966],
                            [-20.456, 2.574],
                            [-20.456, -0.023],
                            [-16.514, -3.966],
                            [6.762, -3.966],
                            [13.302, 2.574],
                            [13.302, 3.966],
                            [8.558, 4],
                          ],
                          c: false,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 128,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0.769],
                            [0, 0],
                            [-2.177, 0],
                            [0, 0],
                            [0, -3.612],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [-0.768, 0],
                            [0, 0],
                            [0, -2.178],
                            [0, 0],
                            [3.612, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-15.775, 3.966],
                            [-19.064, 3.966],
                            [-20.456, 2.574],
                            [-20.456, -0.023],
                            [-16.514, -3.966],
                            [6.762, -3.966],
                            [13.302, 2.574],
                            [13.302, 3.966],
                            [8.558, 4],
                          ],
                          c: false,
                        },
                      ],
                    },
                    {
                      t: 137,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0.769],
                            [0, 0],
                            [-2.177, 0],
                            [0, 0],
                            [0, -3.612],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [-0.768, 0],
                            [0, 0],
                            [0, -2.178],
                            [0, 0],
                            [3.612, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-15.775, 3.966],
                            [-19.064, 3.966],
                            [-20.456, 2.574],
                            [-20.456, -0.023],
                            [-16.514, -3.966],
                            [13.915, -3.966],
                            [20.456, 2.574],
                            [20.456, 3.966],
                            [15.562, 3.966],
                          ],
                          c: false,
                        },
                      ],
                    },
                  ],
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [...k, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 1, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 10,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [25.261, 26.331], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 8',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 139,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 18,
        ty: 4,
        nm: 'Base',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 0,
                s: [251.026, 285.171, 0],
                to: [0, -0.45, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 3,
                s: [251.026, 282.474, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.167, y: 0 },
                t: 6,
                s: [251.026, 285.171, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 9,
                s: [251.026, 282.474, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.167, y: 0 },
                t: 12,
                s: [251.026, 285.171, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 15,
                s: [251.026, 282.474, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.167, y: 0 },
                t: 18,
                s: [251.026, 285.171, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 21,
                s: [251.026, 282.474, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.167, y: 0 },
                t: 24,
                s: [251.026, 285.171, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 27,
                s: [251.026, 282.474, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.167, y: 0 },
                t: 30,
                s: [251.026, 285.171, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 33,
                s: [251.026, 282.474, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.167, y: 0 },
                t: 36,
                s: [251.026, 285.171, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 39,
                s: [251.026, 282.474, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.167, y: 0 },
                t: 42,
                s: [251.026, 285.171, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 45,
                s: [251.026, 282.474, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.167, y: 0 },
                t: 48,
                s: [251.026, 285.171, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 51,
                s: [251.026, 282.474, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.167, y: 0 },
                t: 54,
                s: [251.026, 285.171, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 57,
                s: [251.026, 282.474, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.167, y: 0 },
                t: 60,
                s: [251.026, 285.171, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 63,
                s: [251.026, 282.474, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.167, y: 0 },
                t: 66,
                s: [251.026, 285.171, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 69,
                s: [251.026, 282.474, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.167, y: 0 },
                t: 72,
                s: [251.026, 285.171, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 75,
                s: [251.026, 282.474, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.167, y: 0 },
                t: 78,
                s: [251.026, 285.171, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 81,
                s: [251.026, 282.474, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.167, y: 0 },
                t: 84,
                s: [251.026, 285.171, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 87,
                s: [251.026, 282.474, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.167, y: 0 },
                t: 90,
                s: [251.026, 285.171, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 93,
                s: [251.026, 282.474, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.167, y: 0 },
                t: 96,
                s: [251.026, 285.171, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 99,
                s: [251.026, 282.474, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.167, y: 0 },
                t: 102,
                s: [251.026, 285.171, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 105,
                s: [251.026, 282.474, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.167, y: 0 },
                t: 108,
                s: [251.026, 285.171, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 111,
                s: [251.026, 282.474, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.167, y: 0 },
                t: 114,
                s: [251.026, 285.171, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 117,
                s: [251.026, 282.474, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.167, y: 0 },
                t: 120,
                s: [251.026, 285.171, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 123,
                s: [251.026, 282.474, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.167, y: 0 },
                t: 126,
                s: [251.026, 285.171, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 129,
                s: [251.026, 282.474, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.167, y: 0 },
                t: 132,
                s: [251.026, 285.171, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 135,
                s: [251.026, 282.474, 0],
                to: [0, 0, 0],
                ti: [0, -0.45, 0],
              },
              { t: 138, s: [251.026, 285.171, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [25.155, 30.297, 0], ix: 1 },
          s: { a: 0, k: [664, 664, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 84,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [34.186, 30.297],
                            [16.124, 30.297],
                          ],
                          c: false,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 95,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [34.186, 30.297],
                            [16.124, 30.297],
                          ],
                          c: false,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 104,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [26.655, 30.325],
                            [16.124, 30.297],
                          ],
                          c: false,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 128,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [26.655, 30.325],
                            [16.124, 30.297],
                          ],
                          c: false,
                        },
                      ],
                    },
                    {
                      t: 137,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [34.186, 30.297],
                            [16.124, 30.297],
                          ],
                          c: false,
                        },
                      ],
                    },
                  ],
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [...k, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 1, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 10,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [0, 0], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 9',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 139,
        st: 0,
        bm: 0,
      },
    ],
    markers: [],
  };
};
