import type { ReactNode } from 'react';
import { CancellationRecordReasonTemplate } from '../../../../components/ScreenTemplates/InformationTemplates/InsuranceRecordTemplates/CancellationRecordTemplates/CancellationRecordReasonTemplate';
import { WithdrawalType } from '../../../../fsm/answers';
import { useSubscriptionFsmState } from '../../../../fsm/context';

export function SecondaryCancellationRecordReasonScreen(): ReactNode {
  const {
    context: {
      editingInsuranceRecord,
      answers: { secondaryDriver },
    },
  } = useSubscriptionFsmState();

  const title = `Pour quel motif son permis a-t-il été ${
    secondaryDriver?.cancellationOrSuspensionRecords?.[editingInsuranceRecord.index].withdrawalType ===
    WithdrawalType.Cancellation
      ? 'annulé'
      : 'suspendu'
  }\u00A0?`;

  return (
    <CancellationRecordReasonTemplate
      title={title}
      fieldName={`secondaryDriver.cancellationOrSuspensionRecords[${editingInsuranceRecord.index}].reason`}
    />
  );
}
