import { Button, LoaderIcon, Typography, View } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { usePageBottom } from '../../../../../hooks/usePageBottom';
import { formatPrice } from '../../../../../utils/formatPrice';

interface MobileBottomBarProps {
  title: ReactNode;
  price: number;
  pricePeriodicityLabel: ReactNode;
  isLoading?: boolean;
  onSubmit: () => void;
}

export function MobileBottomBar({
  title,
  price,
  pricePeriodicityLabel,
  isLoading,
  onSubmit,
}: MobileBottomBarProps): ReactNode {
  const hasReachedBottom = usePageBottom();

  return (
    <div
      style={{
        width: '100%',
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px',
        backgroundColor: 'white',
        boxShadow: '0 5px 30px rgba(0, 0, 0, 0.32)',
        transition: 'bottom 0.3s ease-in-out',
        zIndex: 1,
        bottom: hasReachedBottom ? '-120px' : '0',
      }}
    >
      <View>
        <Typography.Text variant="bold" base="header5" textAlign="center">
          {title} •{' '}
          <Typography.Text color="primary">
            {formatPrice(price)} €/{pricePeriodicityLabel}
          </Typography.Text>
        </Typography.Text>
      </View>
      <View maxWidth={430} width="100%">
        <Button
          stretch
          type="primary"
          disabled={isLoading}
          icon={isLoading ? <LoaderIcon /> : undefined}
          onPress={onSubmit}
        >
          {isLoading ? 'Patientez' : 'Choisir cette formule'}
        </Button>
      </View>
    </div>
  );
}
