import type { ReactNode } from 'react';
import { ContactLayout } from '../../../components/ContactLayout';

export function ContactUsEndQuoteScreen(): ReactNode {
  return (
    <ContactLayout
      title="Contactez-nous pour finaliser votre souscription avec l’un de nos conseiller expert&nbsp;!"
      text={
        <span>
          Suite à vos réponses, nous ne pouvons pas vous afficher votre tarif en ligne.{' '}
          <b>Reprenez votre devis par téléphone</b> et pas de panique, on va bien s’occuper de vous !
        </span>
      }
    />
  );
}
