import { useCallback } from 'react';
import type { DriverAnswers, LongQuoteAnswers } from '../fsm/answers';
import type { Driver } from '../fsm/types';
import { sendEvent } from '../utils/mixpanel';

const ANONYMOUS_FIELDS: (keyof LongQuoteAnswers | `${Driver}Driver.${keyof DriverAnswers}`)[] = [
  'ibanNumber',
  'ibanTitulaire',
  'primaryDriver.firstName',
  'primaryDriver.lastName',
  'primaryDriver.birthDate',
  'primaryDriver.licenseDate',
  'secondaryDriver.firstName',
  'secondaryDriver.lastName',
  'secondaryDriver.birthDate',
  'secondaryDriver.licenseDate',
  'subscriberFirstName',
  'subscriberLastName',
  'subscriberEmail',
  'subscriberPhone',
  'subscriberAdress1',
  'subscriberCodePostal',
  'subscriberCommune',
  'subscriberCompleteAdress',
  'vehiculeImmatriculation',
  'hamonContractRef',
  'hamonName',
  'hamonCompleteAdress',
  'hamonAdress',
  'hamonCodePostal',
  'hamonCommune',
];

const anonymizeValue = (fieldName: keyof LongQuoteAnswers, value: string | number): string => {
  if (!value && value !== 0) {
    return '';
  }

  if (ANONYMOUS_FIELDS.includes(fieldName)) {
    if (typeof value === 'number') {
      return value.toString().replace(/./g, 'X');
    }

    return value.replace(/./g, 'X');
  }

  return typeof value === 'number' ? value.toString() : value;
};

export interface ErrorFieldReport {
  field_name: string;
  field_value: string | number;
  field_error: string;
}

export const useReportErrorFields = (): ((errorType: 'validation' | 'submit', errors: ErrorFieldReport[]) => void) => {
  const reportErrorFields = useCallback((errorType: 'validation' | 'submit', errors: ErrorFieldReport[]): void => {
    sendEvent('Validate Insurance State', {
      is_valid: false,
      error_type: errorType,
      field_errors: errors.map((error) => ({
        ...error,
        field_value: anonymizeValue(error.field_name as keyof LongQuoteAnswers, error.field_value),
      })),
    });
  }, []);

  return reportErrorFields;
};
