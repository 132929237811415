import { PencilLineRegularIcon, TrashRegularIcon } from '@ornikar/kitt-icons/phosphor';
import { Button, HStack, Typography, VStack, View } from '@ornikar/kitt-universal';
import type { PropsWithChildren, ReactNode } from 'react';

export interface InformationContainerProps {
  title: string;
  onEdit: () => void;
  onDelete: () => void;
}

export function InformationContainer({
  title,
  children,
  onEdit,
  onDelete,
}: PropsWithChildren<InformationContainerProps>): ReactNode {
  const styleWithNoChildren = {
    borderBottomRadius: 'kitt.5',
  };
  const styleWithChildren = {
    borderBottomColor: 'kitt.separator',
    borderBottomWidth: '1',
  };

  const style = children ? styleWithChildren : styleWithNoChildren;

  return (
    <View borderRadius="kitt.5" borderColor="kitt.separator" backgroundColor="white" borderWidth="1">
      <VStack>
        <HStack
          backgroundColor="kitt.palettes.lateOcean.black50"
          padding="kitt.4"
          borderTopRadius="kitt.5"
          justifyContent="space-between"
          space="kitt.4"
          {...style}
        >
          <Typography.Paragraph medium="body" variant="bold" alignSelf="center">
            {title}
          </Typography.Paragraph>

          <HStack space="kitt.2">
            <Button icon={<PencilLineRegularIcon />} onPress={onEdit} />
            <Button icon={<TrashRegularIcon />} onPress={onDelete} />
          </HStack>
        </HStack>
        {children && <View padding="kitt.4">{children}</View>}
      </VStack>
    </View>
  );
}
