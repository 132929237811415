import { CheckRegularIcon } from '@ornikar/kitt-icons/phosphor';
import { HStack, Icon, Tag, Typography, VStack, View } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { addonNameMapping } from '../../../../constants/options';
import type { Addon } from '../../../../fsm/answers';
import { formatPrice } from '../../../../utils/formatPrice';
import { Separator } from './Separator';

export interface AddonsListProps {
  addons: [string, Addon][];
  hasDeductible: boolean;
  addonsPrice: number;
  paymentPeriodicity: string;
}
export function AddonsList({ addons, hasDeductible, addonsPrice, paymentPeriodicity }: AddonsListProps): ReactNode {
  return (
    <View marginTop={hasDeductible ? 'kitt.12' : 'kitt.0'} paddingTop={hasDeductible ? 'kitt.12' : 'kitt.0'}>
      <Separator />
      <HStack marginBottom="kitt.6" justifyContent="space-between" alignItems="top">
        <Typography.Paragraph base="body" variant="bold" color="white">
          {' '}
          <FormattedMessage id="shoppingCart.yourOptions" defaultMessage="Vos options" />
        </Typography.Paragraph>
        <VStack>
          <View>
            <Typography.Paragraph base="body-medium" variant="bold" color="kitt.palettes.lateOcean.goldCrayola">
              {formatPrice(addonsPrice)}&nbsp;€
            </Typography.Paragraph>
          </View>
          <View marginTop="-kitt.2">
            <Typography.Paragraph textAlign="right" base="body-small" color="kitt.palettes.lateOcean.goldCrayola">
              {paymentPeriodicity === 'monthly' ? (
                <FormattedMessage id="shoppingCart.addonPeriodicity.monthly" defaultMessage="/mois" />
              ) : (
                <FormattedMessage id="shoppingCart.addonPeriodicity.yearly" defaultMessage="/an" />
              )}
            </Typography.Paragraph>
          </View>
        </VStack>
      </HStack>
      <VStack space="kitt.2">
        {addons.map((addon) => {
          return (
            <Tag
              key={addon[1].name}
              type="primary"
              label={
                <View display="flex" flexDirection="row" paddingY="kitt.1" alignItems="center">
                  <Icon size="kitt.4" color="kitt.primary" icon={<CheckRegularIcon />} />
                  <Typography.Paragraph base="body-small" color="kitt.primary">
                    {' '}
                    {addonNameMapping[addon[1].name]}
                  </Typography.Paragraph>
                </View>
              }
            />
          );
        })}
      </VStack>
    </View>
  );
}
