import { useEffect, useState } from 'react';

const minKeyboardHeight = 300;

export function useDetectAndroidKeyboard(): boolean {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

  useEffect(() => {
    const isAndroidDevice = window.navigator.userAgent.toLowerCase().includes('android');

    const { visualViewport } = window;

    if (isAndroidDevice && visualViewport) {
      const handleResize = (): void => {
        setIsKeyboardOpen(visualViewport.height <= window.screen.height - minKeyboardHeight);
      };

      visualViewport.addEventListener('resize', handleResize);

      return () => {
        visualViewport.removeEventListener('resize', handleResize);
      };
    }

    return undefined;
  }, []);

  return isKeyboardOpen;
}
