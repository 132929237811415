import { useRequiredValidator } from '@ornikar/react-validators';
import type { ReactNode } from 'react';
import { terminationRecordReasonOptionsList } from '../../../../../../constants/mappers/information';
import type { TerminationReason } from '../../../../../../fsm/answers';
import type { RadioScreenTemplateProps } from '../../../../RadioScreenTemplate';
import { RadioScreenTemplate } from '../../../../RadioScreenTemplate';

interface TerminationRecordReasonTemplateProps
  extends Pick<RadioScreenTemplateProps<TerminationReason>, 'fieldName' | 'highlightContent'> {}

export function TerminationRecordReasonTemplate(props: TerminationRecordReasonTemplateProps): ReactNode {
  const requiredValidator = useRequiredValidator();

  return (
    <RadioScreenTemplate
      {...props}
      choices={terminationRecordReasonOptionsList}
      validate={requiredValidator}
      title="Pour quel motif a-t-il été résilié&nbsp;?"
      highlightTitle="Oú trouver le motif de résiliation&nbsp;?"
    />
  );
}
