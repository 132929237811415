import type { IFlag } from '@flagship.io/js-sdk';
import { Flagship, LogLevel } from '@flagship.io/js-sdk';
import type { Dict } from 'mixpanel-browser';
import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';

Flagship.start(import.meta.env.VITE_FLAGSHIP_ENV_ID, import.meta.env.VITE_FLAGSHIP_API_KEY, {
  fetchNow: false,
  logLevel: import.meta.env.DEV ? LogLevel.ALL : LogLevel.NONE,
});

const visitor = Flagship.newVisitor({
  isAuthenticated: false,
});

function sendVariationToMixpanel<T>(flag: IFlag<T>, insuranceSubscriptionProps?: Dict): void {
  const { campaignId, variationId } = flag.metadata;

  mixpanel.track('User exposed to flagship experiment', {
    flagship_experiment: {
      campaign_id: campaignId,
      variation_id: variationId,
      visitor_id: visitor.visitorId,
    },
    insurance_subscription: {
      ...mixpanel.get_property('insurance_subscription'),
      ...insuranceSubscriptionProps,
    },
  });
}

export function useFsFlag<T>(
  key: string,
  defaultValue: T,
  options?: {
    shouldSendVariationToMixpanel?: boolean;
    mixpanelInsuranceSubscriptionProps?: Dict;
  },
): IFlag<T> {
  const flag = visitor.getFlag<T>(key, defaultValue);

  useEffect(() => {
    if (options?.shouldSendVariationToMixpanel) {
      sendVariationToMixpanel(flag, options?.mixpanelInsuranceSubscriptionProps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return flag;
}

export function getFsFlag<T>(
  key: string,
  defaultValue: T,
  options?: {
    shouldSendVariationToMixpanel?: boolean;
    mixpanelInsuranceSubscriptionProps?: Dict;
  },
): IFlag<T> {
  const flag = visitor.getFlag<T>(key, defaultValue);

  if (options?.shouldSendVariationToMixpanel) {
    sendVariationToMixpanel(flag, options?.mixpanelInsuranceSubscriptionProps);
  }

  return flag;
}

export function initializeFlagship(): void {
  visitor.fetchFlags();
}
