import { defineMessages } from 'react-intl';

export const validatorMessages = defineMessages({
  dateFormatValidator: {
    id: 'dateFormatValidator',
    defaultMessage: 'Le format de la date est incorrect',
  },
  pastDateValidator: {
    id: 'pastDateValidator',
    defaultMessage: 'La date est dans le futur',
  },
});
