import { cloneDeep, isNil } from 'lodash';

export const lowercaseValues = <T extends Record<string, string>>(object: T): T => {
  return Object.fromEntries(Object.entries(object).map(([key, value]) => [key, value.toLowerCase()])) as T;
};

export const removeNilValuesInObject = <T extends Record<string, unknown>>(object: T): T => {
  const clonedObject = cloneDeep<T>(object);
  const stack = [clonedObject];

  while (stack.length > 0) {
    const currentObject = stack.pop();

    if (currentObject) {
      Object.entries(currentObject).forEach(([key, value]) => {
        if (typeof value === 'object' && value !== null) {
          stack.push(value as T);
        } else if (isNil(value)) {
          delete currentObject[key];
        }
      });
    }
  }

  return clonedObject;
};
