import { Civility } from '../../../apis/types/LongQuoteAnswers';

export function getPronoun(civility?: Civility, isCapitalize = false): string {
  switch (civility) {
    case Civility.Femme:
      return isCapitalize ? 'Elle' : 'elle';
    default:
      return isCapitalize ? 'Il' : 'il';
  }
}
