import { TypographyLink, View } from '@ornikar/kitt-universal';
import { useRequiredValidator } from '@ornikar/react-validators';
import { subYears } from 'date-fns';
import { type ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Civility, DejaAssureAvecNomType } from '../../../apis/types/LongQuoteAnswers';
import type { ChoiceWithLabel, ChoiceWithRender } from '../../../components/RadioBlockGroup';
import { RadioScreenTemplate } from '../../../components/ScreenTemplates/RadioScreenTemplate';
import { MODAL_VIEWED } from '../../../constants/eventName';
import {
  DriverHasBeenCovered,
  DriverHasBeenCoveredNegative,
  DriverHasBeenCoveredPositive,
  makeChoices,
} from '../../../fsm/answers';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { Driver, Event } from '../../../fsm/types';
import { DateFromYearsAgo } from '../../../utils/date';
import { sendEvent } from '../../../utils/mixpanel';
import type { DriverScreenProps } from '../../types/DriverScreenProps';
import { HighlightContent } from './HighlightContent';
import { DateInconsistencyModal } from './components/DateInconsistencyModal';

type TranslationGenderType = 'femalePrimary' | 'malePrimary' | 'femaleSecondary' | 'maleSecondary';

const buildHasEverBeenCoveredChoices = (
  shouldCheckConsistency: boolean | undefined,
  gender?: TranslationGenderType,
): (
  | ChoiceWithLabel<DriverHasBeenCoveredNegative | DriverHasBeenCoveredPositive>
  | ChoiceWithRender<DriverHasBeenCoveredNegative | DriverHasBeenCoveredPositive>
)[] => {
  const choices: [DriverHasBeenCoveredNegative | DriverHasBeenCoveredPositive, ReactNode][] = shouldCheckConsistency
    ? []
    : [
        [
          DriverHasBeenCoveredNegative.NonJamais,
          <FormattedMessage
            id="driverHasBeenCovered.notInsured"
            defaultMessage="Non, {gender, select,
                  femaleSecondary {elle n'a}
                  maleSecondary {il n'a}
                  other {je n'ai}
              } jamais été {gender, select,
                  femalePrimary {assurée}
                  femaleSecondary {assurée}
                  other {assuré}
              } de {gender, select,
                  femaleSecondary {sa}
                  maleSecondary {sa}
                  other {ma}
              } vie"
            values={{
              gender,
            }}
          />,
        ],
        [
          DriverHasBeenCoveredNegative.NonSurPeriode,
          <FormattedMessage
            id="driverHasBeenCovered.notInsuredOnThisPeriod"
            defaultMessage="Non, pas sur cette période"
          />,
        ],
      ];

  choices.push(
    [
      DriverHasBeenCoveredPositive.OuiPartiellement,
      <FormattedMessage
        id="driverHasBeenCovered.insuredPartially"
        defaultMessage="Oui, mais pas pendant toute la période"
      />,
    ],
    [
      DriverHasBeenCoveredPositive.OuiEnContinu,
      <FormattedMessage id="driverHasBeenCovered.insuredFully" defaultMessage="Oui, en continu sur toute la période" />,
    ],
  );

  return makeChoices(shouldCheckConsistency ? DriverHasBeenCoveredPositive : DriverHasBeenCovered, choices);
};

export function DriverHasBeenCoveredScreen({ driver }: DriverScreenProps): ReactNode {
  const send = useSubscriptionFsmDispatch();

  const requiredValidator = useRequiredValidator();
  const isPrimaryDriver = driver === Driver.Primary;
  const {
    context: { answers },
  } = useSubscriptionFsmState();

  const civility = answers[`${driver}Driver`]?.civility;
  const isWoman = civility === Civility.Femme;
  const [modalVisible, setModalVisible] = useState(false);
  const shouldCheckConsistency =
    isPrimaryDriver && answers.primaryDriver && answers.vehiculeDejaAssureAvecNom === DejaAssureAvecNomType.OuiAMonNom;

  const date = DateFromYearsAgo(3);
  const gender: TranslationGenderType = `${isWoman ? 'female' : 'male'}${isPrimaryDriver ? 'Primary' : 'Secondary'}`;

  return (
    <RadioScreenTemplate
      title={
        <FormattedMessage
          id="driverHasBeenCovered.title1"
          defaultMessage="{gender, select,
              femaleSecondary {A-t-elle}
              maleSecondary {A-t-il}
              other {Avez-vous}
          } été {gender, select,
              femaleSecondary {assurée}
              other {assuré}
          } depuis le {date}&nbsp;"
          values={{
            date,
            gender,
          }}
        />
      }
      highlightTitle="Besoin de précision ?"
      highlightContent={<HighlightContent isPrimaryDriver={isPrimaryDriver} civility={civility} />}
      fieldName={`${driver}Driver.hasBeenCovered`}
      choices={buildHasEverBeenCoveredChoices(shouldCheckConsistency, gender)}
      validate={requiredValidator}
    >
      {shouldCheckConsistency ? (
        <View marginTop="kitt.3" marginBottom={{ medium: 'kitt.4', base: '0' }}>
          <TypographyLink
            variant="bold"
            color="kitt.primary"
            onPress={() => {
              setModalVisible(true);
              sendEvent(MODAL_VIEWED, {
                modal_name: 'INS - Inconsistency in Declaration',
              });
            }}
          >
            <FormattedMessage
              id="driverHasBeenCovered.link"
              defaultMessage="Non, je n’ai pas été assuré depuis le {date}"
              values={{ date }}
            />
          </TypographyLink>
        </View>
      ) : null}
      <DateInconsistencyModal
        date={subYears(new Date(), 3)}
        gender={isWoman ? 'female' : 'male'}
        mainButtonClick={() => {
          send(Event.INSURANCE_DATE_INCONSISTENCY);
        }}
        secondButtonClick={() => {
          setModalVisible(false);
        }}
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
      />
    </RadioScreenTemplate>
  );
}
