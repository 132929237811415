import type { ActiveHours } from '../utils/businessDates';
import { api } from './api';

export interface WebCallbackScheduleData {
  daySlotsList: Date[];
  timeSlotsPerDate: { day: Date; timeSlots: ActiveHours }[];
  isBusinessHours: boolean;
  timeSlotRangeInMinutes: number;
}

export const getWebCallbackScheduleData = (): Promise<WebCallbackScheduleData> => {
  return api<WebCallbackScheduleData>('api/v1/web-callback');
};
