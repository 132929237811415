import { Calendar, Timer } from '@ornikar/illustrated-icons';
import { ArrowLeftRegularIcon, XRegularIcon } from '@ornikar/kitt-icons/phosphor';
import { Button, Icon, NavigationModal, Pressable, Typography, VStack } from '@ornikar/kitt-universal';
import { addMinutes } from 'date-fns';
import { useEffect, useState } from 'react';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Query, useMediaQuery } from '../../hooks/useMediaQuery';
import { type Time } from '../../utils/businessDates';
import { formatDateWithDayLabelAndMonth, formatDateWithHoursAndMinutes } from '../../utils/date';
import type { UseCallbackModule } from '../CallbackModuleModal';
import type { CallbackScheduleItem } from '../CallbackScheduleModule';
import { CallbackStatusEnum, CallbackStatusView } from '../CallbackStatusView';
import { CallbackFormView } from './components/CallbackFormView';
import { CallbackModeSwitch } from './components/CallbackModeSwitch';
import { CancelButton } from './components/CancelButton';
import { ConfirmButton } from './components/ConfirmButton';
import { DaySlotsSchedule } from './components/DaySlotsSchedule';
import { HelperCard } from './components/HelperCard';
import { TimeSlotsSchedule } from './components/TimeSlotsSchedule';
import { StepEnum, SwitchStateEnum } from './constants';

interface CallbackModuleModalViewProps {
  shouldDisplaySwitch: boolean;
  timeSlotRangeInMinutes: number;
  status?: CallbackStatusEnum;
  callbackModule: UseCallbackModule;
  dayItems: CallbackScheduleItem<Date>[];
  timeItems: CallbackScheduleItem<[Time, Time]>[];
  setStatus: (status?: CallbackStatusEnum) => void;
  onCancel: () => void;
  onClose: () => void;
}

export function CallbackModuleModalView({
  shouldDisplaySwitch,
  timeSlotRangeInMinutes,
  status,
  callbackModule,
  dayItems,
  timeItems,
  setStatus,
  onCancel,
  onClose,
}: CallbackModuleModalViewProps): ReactNode {
  const isSmall = useMediaQuery(Query.SMALL);
  const [step, setStep] = useState<StepEnum>(shouldDisplaySwitch ? StepEnum.BeingCalledASAP : StepEnum.ChoseADaySlot);
  const [switchState, setSwitchState] = useState<SwitchStateEnum>(
    shouldDisplaySwitch ? SwitchStateEnum.AsSoonAsPossible : SwitchStateEnum.ChoseASlot,
  );
  const { daySlot, timeSlot, callDueDate, setDaySlot, setTimeSlot } = callbackModule;

  useEffect(() => {
    switch (switchState) {
      case SwitchStateEnum.AsSoonAsPossible:
        setStep(StepEnum.BeingCalledASAP);
        break;
      case SwitchStateEnum.ChoseASlot:
        setStep(StepEnum.ChoseADaySlot);
        break;
      default:
        break;
    }
  }, [switchState]);

  useEffect(() => {
    if (switchState === SwitchStateEnum.ChoseASlot) {
      if (daySlot && timeSlot) {
        setStep(StepEnum.ConfirmDialog);
      } else if (daySlot) {
        setStep(StepEnum.ChoseATimeSlot);
      } else {
        setStep(StepEnum.ChoseADaySlot);
      }
    }
  }, [daySlot, timeSlot, switchState]);

  const header = (): ReactNode => {
    return (
      <Typography.Header5 base="header5" textAlign="center">
        {status === CallbackStatusEnum.Success ? (
          callDueDate ? (
            <FormattedMessage id="callbackModuleModal.bookingConfirmed" defaultMessage="Rendez-vous confirmé" />
          ) : (
            <FormattedMessage id="callbackModuleModal.callbackConfirmed" defaultMessage="Rappel confirmé" />
          )
        ) : (
          <FormattedMessage id="callbackModuleModal.beingCalledLater" defaultMessage="Être rappelé plus tard" />
        )}
      </Typography.Header5>
    );
  };

  const body = (): ReactNode => {
    const content = (): ReactNode => {
      if (status) {
        return (
          <CallbackStatusView status={status} booking={callDueDate} timeSlotRangeInMinutes={timeSlotRangeInMinutes} />
        );
      }
      switch (step) {
        case StepEnum.BeingCalledASAP:
          return (
            <VStack space="kitt.5" width="100%">
              <HelperCard icon={<Timer />}>
                <FormattedMessage
                  id="callbackModuleModal.customerSupportWillCallYou"
                  defaultMessage="Un conseiller vous rappelle dans les 10 minutes !"
                />
              </HelperCard>
              <CallbackFormView />
            </VStack>
          );
        case StepEnum.ChoseADaySlot:
          return <DaySlotsSchedule dayItems={dayItems} selectedItemId={daySlot?.id} onPress={setDaySlot} />;
        case StepEnum.ChoseATimeSlot:
          return <TimeSlotsSchedule timeItems={timeItems} selectedItemId={timeSlot?.id} onPress={setTimeSlot} />;
        case StepEnum.ConfirmDialog:
          return (
            <VStack space="kitt.5" width="100%">
              {callDueDate ? (
                <HelperCard icon={<Calendar />}>
                  <FormattedMessage
                    id="confirmCallbackDialog.text"
                    defaultMessage="Votre rendez-vous aura lieu le {date} de {startHour} à {endHour}"
                    values={{
                      date: formatDateWithDayLabelAndMonth(callDueDate),
                      startHour: formatDateWithHoursAndMinutes(callDueDate),
                      endHour: formatDateWithHoursAndMinutes(addMinutes(callDueDate, timeSlotRangeInMinutes)),
                    }}
                  />
                </HelperCard>
              ) : null}
              <CallbackFormView withAdditionalMessage />
            </VStack>
          );
        default:
          return null;
      }
    };
    return (
      <VStack alignItems="center" space="kitt.6" paddingBottom="kitt.8">
        {shouldDisplaySwitch && !status ? <CallbackModeSwitch value={switchState} onChange={setSwitchState} /> : null}
        {content()}
      </VStack>
    );
  };

  const buttons = (): ReactNode => {
    const content = (): ReactNode => {
      if (status) {
        return status === CallbackStatusEnum.Success ? (
          <Button stretch type="primary" onPress={onCancel}>
            <FormattedMessage id="callbackModuleModal.close" defaultMessage="C'est noté" />
          </Button>
        ) : (
          <Button stretch type="primary" onPress={() => setStatus(undefined)}>
            <FormattedMessage id="callbackModuleModal.back" defaultMessage="Revenir en arrière" />
          </Button>
        );
      }
      const buttonType = isSmall ? 'default' : 'subtle';
      switch (step) {
        case StepEnum.BeingCalledASAP:
          return (
            <>
              <ConfirmButton />
              <CancelButton type={buttonType} onPress={onCancel} />
            </>
          );
        case StepEnum.ChoseADaySlot:
          return (
            <>
              {!isSmall ? <ConfirmButton /> : null}
              <CancelButton type={buttonType} onPress={onCancel} />
            </>
          );
        case StepEnum.ChoseATimeSlot:
          return (
            <>
              {!isSmall ? <ConfirmButton /> : null}
              <Button stretch icon={<ArrowLeftRegularIcon />} type={buttonType} onPress={() => setDaySlot(undefined)}>
                <FormattedMessage id="callbackModuleModal.backToDayChoice" defaultMessage="Choix du jour" />
              </Button>
            </>
          );
        case StepEnum.ConfirmDialog:
          return (
            <>
              <ConfirmButton />
              <Button stretch icon={<ArrowLeftRegularIcon />} type={buttonType} onPress={() => setTimeSlot(undefined)}>
                <FormattedMessage id="callbackModuleModal.backToTimeChoice" defaultMessage="Choix du créneau" />
              </Button>
            </>
          );
        default:
          return null;
      }
    };

    return (
      <VStack space="kitt.2" zIndex={-1}>
        {content()}
      </VStack>
    );
  };

  return (
    <NavigationModal
      header={
        <NavigationModal.Header
          right={
            !isSmall ? (
              <Pressable onPress={onClose}>
                <Icon icon={<XRegularIcon />} size={20} color="black" />
              </Pressable>
            ) : null
          }
          hasSeparator={!isSmall}
          paddingTop={isSmall ? 'kitt.8' : 0}
          paddingBottom={0}
        >
          {header()}
        </NavigationModal.Header>
      }
      body={
        <NavigationModal.Body paddingX="kitt.8" paddingBottom="kitt.8" paddingTop="kitt.6" maxHeight="70vh">
          {body()}
          {isSmall ? buttons() : null}
        </NavigationModal.Body>
      }
      footer={!isSmall ? <NavigationModal.Footer hasSeparator>{buttons()}</NavigationModal.Footer> : null}
    />
  );
}
