const supportsIntlNumberFormat = typeof Intl === 'object' && typeof Intl.NumberFormat === 'function';

const getDecimals = (price: number): 0 | 2 => (price % 100 === 0 ? 0 : 2);

const fallbackFormatPrice = (price: number): string => {
  const decimalDigits = getDecimals(price);
  const fixedPrice = parseFloat(String(price / 100)).toFixed(decimalDigits);
  return fixedPrice.toString().replace('.', ',');
};

const intlFormatPrice = (price: number): string => {
  const decimalDigits = getDecimals(price);

  return new Intl.NumberFormat('fr-FR', {
    minimumFractionDigits: decimalDigits,
    maximumFractionDigits: decimalDigits,
  }).format(price / 100);
};

export const formatPrice = supportsIntlNumberFormat ? intlFormatPrice : fallbackFormatPrice;
