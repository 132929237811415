import { TextArea, VStack } from '@ornikar/kitt-universal';
import { type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { usePhoneNumberValidator } from '../../../forms/validation/sections/informations/phoneNumber';
import { CallbackForm } from '../../CallbackModuleModal/CallbackForm';
import { PhoneInput } from '../../PhoneInput';

export interface CallbackFormViewProps {
  withAdditionalMessage?: boolean;
}

export function CallbackFormView({ withAdditionalMessage = false }: CallbackFormViewProps): ReactNode {
  const phoneNumberValidator = usePhoneNumberValidator();

  return (
    <VStack space="kitt.6">
      <CallbackForm.Field
        name="phoneNumber"
        component={PhoneInput}
        label={<FormattedMessage id="callbackForm.phoneNumber.label" defaultMessage="Votre numéro de mobile" />}
        validate={phoneNumberValidator}
      />
      {withAdditionalMessage ? (
        <CallbackForm.Field
          limit={255}
          name="additionalMessage"
          component={TextArea}
          label={<FormattedMessage id="callbackForm.comment.label" defaultMessage="Quelque chose à ajouter ?" />}
          placeholder="Par exemple, j'ai une question à propos des bris de glace."
        />
      ) : null}
    </VStack>
  );
}
