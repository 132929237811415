import { DropdownSelect } from '@ornikar/kitt';
import type { ReactNode } from 'react';
import { MaritalStatus } from '../../../apis/types/LongQuoteAnswers';
import type { FieldProps } from '../../../components/Field';
import { Field } from '../../../components/Field';
import { makeChoices } from '../../../fsm/answers';
import styles from './styles.module.css';

const maritalStatusOptions = makeChoices(MaritalStatus, [
  [MaritalStatus.Célibataire, 'Je vis seul·e'],
  [MaritalStatus.Marié, 'Je vis en couple'],
]) as { label: string; value: MaritalStatus }[];

export function MaritalStatusField({ validate }: Pick<FieldProps<string>, 'validate'>): ReactNode {
  return (
    <Field
      component={DropdownSelect}
      name="primaryDriver.maritalStatus"
      label="Votre statut marital"
      choices={maritalStatusOptions}
      validate={validate}
      placeholder="Choisissez un statut marital"
      className={styles.Field}
    />
  );
}
