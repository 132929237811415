import type { IntlValidator } from '@ornikar/react-validators';
import { useComposeValidators, useRequiredValidator, useValidator } from '@ornikar/react-validators';
import { invalidTypeSymbol, requiredTypeSymbol } from '@ornikar/validators';
import type { ValidatorType } from '../../types';

export const validateMileage: ValidatorType = () => (value: string) => {
  if (!value) {
    return undefined;
  }

  if (!value.trim()) {
    return requiredTypeSymbol;
  }

  return /,|\./g.test(value) ? invalidTypeSymbol : undefined;
};

export const useMileageValidator = (): IntlValidator<string> =>
  useComposeValidators(
    useRequiredValidator(),
    useValidator(validateMileage(), {
      id: 'PaymentInformationScreen.mileageIntegerValidator',
      defaultMessage: 'Veuillez arrondir au kilomètre (valeur entière)',
    }),
  );
