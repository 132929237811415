import { HStack, Typography, VStack, View, theme, useBreakpointValue } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import type { BillingInformation } from '../../../../../apis/getBillingInformation';
import type { OrnikarPackageLevel, PaymentPeriodicity } from '../../../../../apis/types/Formula';
import { getAmountDetails } from '../../../../../utils/price';

export interface PaymentDetailsProps {
  billingData: BillingInformation;
  formulaName?: OrnikarPackageLevel;
  paymentPeriodicity: PaymentPeriodicity;
}

export function PaymentDetails({ billingData, formulaName, paymentPeriodicity }: PaymentDetailsProps): ReactNode {
  const isMedium = useBreakpointValue({ base: false, medium: true });

  const amountDetails = getAmountDetails(billingData, formulaName, paymentPeriodicity);

  return (
    <View>
      <View marginBottom={{ base: 'kitt.2', medium: 'kitt.4' }}>
        <Typography.Paragraph base="body-medium" variant="bold">
          Vos paiements
        </Typography.Paragraph>
      </View>

      <VStack
        borderWidth={1}
        borderRadius="kitt.4"
        borderColor={theme.palettes.lateOcean['eggshell.3']}
        backgroundColor="white"
        padding={{ base: 'kitt.4', medium: 'kitt.6' }}
      >
        <HStack space="kitt.6">
          <VStack flex={1} space={{ base: 0, medium: 'kitt.1' }}>
            <Typography.Paragraph variant="bold" base="body">
              Aujourd'hui
            </Typography.Paragraph>

            <Typography.Paragraph base={isMedium ? 'body-small' : 'body-xsmall'} color="black-light">
              {amountDetails}
            </Typography.Paragraph>
          </VStack>

          <Typography.Paragraph color="primary" base="body-medium" variant="bold">
            {billingData.upFrontPaymentTotalAmount.formatted}
          </Typography.Paragraph>
        </HStack>
      </VStack>
    </View>
  );
}
