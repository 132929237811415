import { Button } from '@ornikar/kitt-universal';
import type { ButtonType } from '@ornikar/kitt-universal/dist/definitions/Button/Button';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

export interface CancelButtonProps {
  type?: ButtonType;
  onPress: () => void;
}

export function CancelButton({ type, onPress }: CancelButtonProps): ReactNode {
  return (
    <Button stretch type={type} onPress={onPress}>
      <FormattedMessage id="callbackModuleModal.cancel" defaultMessage="Annuler" />
    </Button>
  );
}
