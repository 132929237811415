import { format, intlFormat, subYears } from 'date-fns';

/**
 *
 * @returns `date` as "yyyy-MM-dd" format
 */
export const formatDate = (date: string | Date): string => format(new Date(date), 'yyyy-MM-dd');

/**
 *
 * @returns `date` as "dd/MM/yyyy" format
 */
export const formatDateForUI = (date: string | Date): string => format(new Date(date), 'dd/MM/yyyy');

export const DateFromYearsAgo = (years: number): string => {
  const today = new Date();

  const yearsAgo = subYears(today, years);

  return formatDateForUI(yearsAgo);
};

/**
 *
 * @param date - Date object
 * @returns `date` as "Lundi 23 mai" format
 */
export const formatDateWithDayLabelAndMonth = (date: Date): string => {
  const formattedDate = intlFormat(
    date,
    {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
    },
    {
      locale: 'fr-FR',
    },
  );
  return formattedDate;
};

export const formatDateWithHoursAndMinutes = (date: Date): string => {
  return format(date, 'HH:mm');
};
