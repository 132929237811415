import type { ButtonProps } from '@ornikar/kitt-universal';
import { Button } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { useSubscriptionFsmState } from '../../fsm/context';
import { sendSaveInsuranceLongQuoteGTMEvent } from '../../utils/dataLayer';
import { sendEvent } from '../../utils/mixpanel';
import { useToast } from '../Toast/useToast';

export function SaveQuoteButton({ ...props }: ButtonProps): ReactNode {
  const { openToast } = useToast();
  const { context: fsmContext } = useSubscriptionFsmState();

  return (
    <Button
      onPress={() => {
        openToast({
          type: 'success',
          message:
            'Votre devis a été sauvegardé avec succès! Reprenez le à tout moment ici-même en retournant sur notre site.',
          timer: 5000,
        });

        sendEvent('Save Insurance Long Quote');

        sendSaveInsuranceLongQuoteGTMEvent(fsmContext.answers.subscriberEmail);
      }}
      {...props}
    >
      Enregistrer et finir plus tard
    </Button>
  );
}
