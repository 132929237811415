import type { FormValues } from '../../forms/answers';
import type { LongQuoteAnswers } from '../answers';

export const mapAnswersToFormValues = ({
  subscriberAdress1,
  subscriberCodePostal,
  subscriberCommune,
  subscriberCompleteAdress,
  addons,
  ...answers
}: Partial<LongQuoteAnswers>): Partial<FormValues> => {
  if (subscriberAdress1 && subscriberCodePostal && subscriberCommune && subscriberCompleteAdress) {
    return {
      subscriberAutoCompletedAddress: {
        street: subscriberAdress1,
        zipCode: subscriberCodePostal,
        city: subscriberCommune,
        formattedAddress: subscriberCompleteAdress,
      },
      ...answers,
    };
  }

  return answers;
};
