import { Typography, TypographyLink } from '@ornikar/kitt-universal';
import cx from 'classnames';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import styles from './styles.module.css';

interface BreadcrumbProps {
  children: NonNullable<ReactNode>;
  index: number;
  currentIndex: number;
  hasSlash?: boolean;
  isEnabled?: boolean;
  onClick: () => void;
}

export function Breadcrumb({
  children,
  index,
  currentIndex,
  hasSlash,
  isEnabled,
  onClick,
}: BreadcrumbProps): ReactNode {
  const preceding = useMemo(() => index <= currentIndex, [index, currentIndex]);

  return (
    <>
      {hasSlash && index > 0 ? (
        <span className={cx(styles.Item, { [styles.Disabled]: !isEnabled, [styles.Completed]: preceding })}>/</span>
      ) : null}
      {isEnabled ? (
        <TypographyLink
          noUnderline
          color={preceding ? 'primary' : 'black'}
          variant="bold"
          onPress={() => {
            onClick();
          }}
        >
          {children}
        </TypographyLink>
      ) : (
        <Typography.Paragraph variant="bold" color="black-light" cursor="not-allowed">
          {children}
        </Typography.Paragraph>
      )}
    </>
  );
}
