import { InputText, Typography } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { filterValidEnergies } from '../../../apis/vehicules';
import type { FieldProps } from '../../../components/Field';
import { Field } from '../../../components/Field';
import { LocalDropdownSelect } from '../../../components/LocalDropdownSelect';
import { useSubscriptionFsmState } from '../../../fsm/context';
import { useAsync } from '../../../hooks/useAsync';
import { serviceUnavailable } from '../../../utils/errorMessages';

export function CarburantField(props: Pick<FieldProps<string>, 'name' | 'label' | 'validate'>): ReactNode {
  const { context } = useSubscriptionFsmState();

  const [validEnergies, error, loading] = useAsync(() => {
    return filterValidEnergies(
      context.answers.vehiculeMarque,
      context.answers.vehiculeModele,
      context.answers.vehiculePuissanceFiscale,
      context.availableCarburants,
    );
  }, []);

  const choices = useMemo(() => {
    return (validEnergies || []).map((v) => ({ label: v, value: v }));
  }, [validEnergies]);

  const placeholderText = loading ? 'Chargement...' : 'Sélectionner';

  if (error) {
    return (
      <>
        <Field {...props} disabled component={InputText} />
        <Typography.Text color="kitt.danger">{serviceUnavailable}</Typography.Text>
      </>
    );
  }

  return (
    <Field {...props} submitOnChange component={LocalDropdownSelect} placeholder={placeholderText} choices={choices} />
  );
}
