import { HStack, Typography, VStack } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import type { QuoteNamedDeductible } from '../../../../apis/types/QuoteOptions';
import { deductibleNameMapping } from '../../../../constants/options';
import type { Deductible, DeductibleName } from '../../../../fsm/answers';

export interface DeductiblesListProps {
  deductibles: QuoteNamedDeductible[];
  selectedDeductibles: Partial<Record<DeductibleName, Deductible>> | undefined;
}
export function DeductiblesList({ deductibles, selectedDeductibles }: DeductiblesListProps): ReactNode {
  const calculateDeductibleValue = (deductibleName: DeductibleName): number => {
    const value = selectedDeductibles?.[deductibleName]?.term?.value ?? '0';
    return Number.parseFloat(value);
  };

  return (
    <VStack space="kitt.2">
      <Typography.Paragraph color="white" variant="bold" base="body-small">
        <FormattedMessage id="shoppingCart.deductibles" defaultMessage="Franchises" />
      </Typography.Paragraph>
      {deductibles.map((deductible) => {
        return (
          <HStack key={deductible.name} justifyContent="space-between" alignItems="bottom" space="kitt.2">
            <Typography.Paragraph color="white">{deductibleNameMapping[deductible.name]} </Typography.Paragraph>
            <Typography.Paragraph alignItems="bottom" textAlign="right" color="white">
              {calculateDeductibleValue(deductible.name)} €
            </Typography.Paragraph>
          </HStack>
        );
      })}
    </VStack>
  );
}
