import { SparkleFillIcon } from '@ornikar/kitt-icons/phosphor';
import { HStack, Icon, Pressable, Typography, VStack, View, theme } from '@ornikar/kitt-universal';
import type { ReactElement, ReactNode } from 'react';
import { Children, cloneElement, isValidElement } from 'react';
import type { StyleProp, TextStyle } from 'react-native';
import ArrowElbowDownRight from '../assets/ArrowElbowDownRight.svg';

export interface InformationEntryProps {
  children?: ReactNode;
  footer?: ReactNode;
  hasSeparator?: boolean;
  isChild?: boolean;
  label: string;
  onPress?: () => void;
  value: string;
  isDefaultValue?: boolean;
}

export function InformationEntry({
  children,
  footer,
  hasSeparator = false,
  isChild = false,
  label,
  onPress,
  value,
  isDefaultValue = false,
}: InformationEntryProps): ReactNode {
  const overflow = { overflowWrap: 'normal' } as StyleProp<TextStyle>;
  const globalSeparator = hasSeparator ? { borderBottomWidth: '1', borderBottomColor: 'kitt.separator' } : {};

  const renderChildren = () => {
    const elements = Children.toArray(children)
      .filter((child) => isValidElement(child))
      .map((child, index, { length }) =>
        cloneElement(child as ReactElement<InformationEntryProps>, {
          hasSeparator: index < length - 1,
          isChild: true,
        }),
      );

    if (elements.length > 0) {
      return <View marginLeft="kitt.8">{elements}</View>;
    }

    return null;
  };

  return (
    <VStack {...globalSeparator}>
      <HStack marginTop="kitt.2" marginBottom="kitt.2">
        {isChild && (
          <View flexGrow={0} marginRight="kitt.2">
            <img src={ArrowElbowDownRight} alt="Arrow Elbow Down Right" width="20px" />
          </View>
        )}
        <HStack justifyContent="space-between" flex={1}>
          <View flexShrink={1} marginRight="kitt.4" minWidth="30%">
            <Typography.Paragraph medium="body" style={overflow}>
              {label}
            </Typography.Paragraph>
          </View>
          <View flexShrink={1} minWidth="30%">
            {onPress ? (
              <HStack space="kitt.2" justifyContent="flex-end" alignItems="center">
                <Pressable width="full" _hover={{ textDecoration: 'underline', color: 'kitt.primary' }}>
                  <Typography.Paragraph
                    textAlign="right"
                    medium="body"
                    variant="bold"
                    style={overflow}
                    color="primary"
                    onPress={onPress}
                  >
                    {value}
                  </Typography.Paragraph>
                </Pressable>
                {isDefaultValue && (
                  <View marginTop="-kitt.1">
                    <Icon icon={<SparkleFillIcon />} size="kitt.6" color={theme.palettes.lateOcean['eggshell.9']} />
                  </View>
                )}
              </HStack>
            ) : (
              <Typography.Paragraph textAlign="right" medium="body" variant="bold" style={overflow}>
                {value}
              </Typography.Paragraph>
            )}
          </View>
        </HStack>
      </HStack>
      {footer}
      {renderChildren()}
    </VStack>
  );
}
