import type { ReactNode } from 'react';
import { TerminationRecordReasonTemplate } from '../../../../components/ScreenTemplates/InformationTemplates/InsuranceRecordTemplates/TerminationRecordTemplates/TerminationRecordReasonTemplate';

export function PrimaryTerminationRecordReasonScreen(): ReactNode {
  return (
    <TerminationRecordReasonTemplate
      fieldName="primaryDriver.terminationRecords[0].reason"
      highlightContent="Cette information se trouve sur votre Relevé d'Informations."
    />
  );
}
