import { api } from './api';

export enum GeocodingProvider {
  GEO_API_GOUV = 'geo.api.gouv',
  GOOGLE_MAPS = 'google-maps',
}

export interface PartialGeocodingDto {
  placeId: string;
  formattedAddress: string;
}

export interface GeocodingDto {
  formattedAddress: string;
  street: string;
  zipCode: string;
  city: string;
}

export interface PartialGeocodingListDto {
  source: GeocodingProvider.GOOGLE_MAPS;
  suggestions: PartialGeocodingDto[];
}

export interface GeocodingListDto {
  source: GeocodingProvider.GOOGLE_MAPS;
  suggestions: GeocodingDto[];
}

export const getAddressSuggestions = (
  inputText: string,
  signal: AbortSignal,
): Promise<GeocodingListDto | PartialGeocodingListDto> => {
  const searchParams = new URLSearchParams({ inputText });

  return api<GeocodingListDto | PartialGeocodingListDto>(`geocoding/addresses?${searchParams.toString()}`, { signal });
};

export const getAddressDetails = (placeId: string): Promise<GeocodingDto> => {
  return api<GeocodingDto>(`geocoding/addresses/${placeId}`);
};
