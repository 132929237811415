import { useRequiredValidator } from '@ornikar/react-validators';
import type { ReactNode } from 'react';
import type { DriverType } from '../../../../../../fsm/answers';
import { RadioScreenTemplate } from '../../../../RadioScreenTemplate';
import type { RadioScreenTemplateProps } from '../../../../RadioScreenTemplate';

interface ClaimRecordDriverTemplateProps extends RadioScreenTemplateProps<DriverType> {}

export function ClaimRecordDriverTemplate(props: ClaimRecordDriverTemplateProps): ReactNode {
  const requiredValidator = useRequiredValidator();

  return <RadioScreenTemplate {...props} validate={requiredValidator} />;
}
