import type { ReactNode } from 'react';
import { ClaimRecordResponsibilityLevelTemplate } from '../../../../components/ScreenTemplates/InformationTemplates/InsuranceRecordTemplates/ClaimRecordTemplates/ClaimRecordResponsibilityLevelTemplate';
import { useSubscriptionFsmState } from '../../../../fsm/context';

export function SecondaryClaimRecordResponsibilityLevelScreen(): ReactNode {
  const {
    context: { editingInsuranceRecord },
  } = useSubscriptionFsmState();

  return (
    <ClaimRecordResponsibilityLevelTemplate
      title="Quel était son niveau de responsabilité lors de l’accident&nbsp;?"
      fieldName={`secondaryDriver.claimRecords[${editingInsuranceRecord.index}].responsibilityLevel`}
    />
  );
}
