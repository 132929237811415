import type { IntlValidator } from '@ornikar/react-validators';
import { useComposeValidators, useRequiredZipcodeValidator, useValidator } from '@ornikar/react-validators';
import type { Validator } from '@ornikar/validators';
import { invalidTypeSymbol } from '@ornikar/validators';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  isUnauthorizedZipCode: {
    id: 'vitrineFr.insuranceSubscriptionV2.validators.zipcode.isUnauthorizedZipCode',
    defaultMessage: 'Notre offre ne couvre pas ce département',
  },
});

const DROM_COM_BASE_ZIPCODE_1 = '97';
const DROM_COM_BASE_ZIPCODE_2 = '98';
const CORSICA_BASE_ZIPCODE = '20';

const notUnauthorizedZipCodeValidator: Validator<string> = (value) => {
  if (!value) return undefined;

  if ([DROM_COM_BASE_ZIPCODE_1, DROM_COM_BASE_ZIPCODE_2, CORSICA_BASE_ZIPCODE].some((item) => value.startsWith(item))) {
    return invalidTypeSymbol;
  }

  return undefined;
};

export const useZipcodeValidator = (): IntlValidator<string> =>
  useComposeValidators(
    useValidator(notUnauthorizedZipCodeValidator, messages.isUnauthorizedZipCode),
    useRequiredZipcodeValidator(),
  );
