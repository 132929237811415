import { PhoneRegularIcon } from '@ornikar/kitt-icons/phosphor';
import { Button, HStack, Pressable, Typography, TypographyIcon, View } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { WEB_CALLBACK_CLICK } from '../../constants/eventName';
import { useInsuranceDesktopMediaQuery } from '../../hooks/useInsuranceDesktopMediaQuery';
import { useWebCallbackScheduleData } from '../../hooks/useWebCallbackScheduleData';
import { sendEvent } from '../../utils/mixpanel';
import { CallbackModuleModal } from '../CallbackModuleModal';

interface CallbackHeaderButtonProps {
  variant?: 'legacy' | 'new';
  forceBusinessHours?: boolean;
}

export function CallbackHeaderButton({ variant = 'legacy', forceBusinessHours }: CallbackHeaderButtonProps): ReactNode {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isInBusinessHours, setIsInBusinessHours] = useState(false);
  const isDesktop = useInsuranceDesktopMediaQuery();
  const webCallbackScheduleData = useWebCallbackScheduleData();

  useEffect(() => {
    const handleScroll: EventListener = (event: Event) => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    setIsInBusinessHours(!!webCallbackScheduleData?.isBusinessHours);
  }, [webCallbackScheduleData]);

  const label =
    isInBusinessHours || forceBusinessHours ? (
      <FormattedMessage id="callbackHeaderButton.businessHours.label" defaultMessage="Appeler un conseiller" />
    ) : (
      <FormattedMessage id="callbackHeaderButton.notBusinessHours.label" defaultMessage="Prendre rendez-vous" />
    );

  const mobileBorderColor = isScrolled ? (variant === 'new' ? 'white' : 'black') : 'transparent';

  const onPress = useCallback((): void => {
    sendEvent(WEB_CALLBACK_CLICK, {
      web_callback_button_location: 'header',
      web_callback_button_type: isInBusinessHours ? 'callback' : 'call',
      opening_hours: isInBusinessHours,
    });

    setIsModalVisible(true);
  }, [isInBusinessHours]);

  return (
    <>
      {isDesktop ? (
        <View>
          <Button icon={<PhoneRegularIcon />} type="primary" iconPosition="right" onPress={onPress}>
            {label}
          </Button>
        </View>
      ) : (
        <Pressable onPress={onPress}>
          <HStack
            space={isScrolled ? 'kitt.2' : 0}
            borderWidth={isScrolled ? 1 : 0}
            borderColor={mobileBorderColor}
            paddingX={isScrolled ? 'kitt.4' : 0}
            borderRadius="kitt.10"
            paddingY="kitt.1"
            alignItems="center"
          >
            <View
              maxWidth={isScrolled ? 'kitt.64' : 0}
              style={{
                transitionDuration: '200ms',
                transitionProperty: 'max-width',
              }}
            >
              <Typography.Text
                variant="bold"
                color={variant === 'new' ? 'white' : 'black'}
                base="body-xsmall"
                numberOfLines={1}
              >
                {label}
              </Typography.Text>
            </View>
            <View paddingX={variant === 'new' && !isScrolled ? 10 : 0}>
              <TypographyIcon
                icon={<PhoneRegularIcon />}
                color={variant === 'new' ? 'white' : 'black'}
                size={isScrolled ? 'kitt.4' : 'kitt.5'}
              />
            </View>
          </HStack>
        </Pressable>
      )}

      <CallbackModuleModal isVisible={isModalVisible} onClose={() => setIsModalVisible(false)} />
    </>
  );
}
