import type { Actions, TransitionsConfig, TransitionsConfigMap } from 'xstate';
import { sendBreadcrumbDestinationStateEvent } from './actions/analytics';
import { resetFicMailDisplay } from './actions/quote';
import type { Context, Events, NavEventType } from './types';
import { Event, State } from './types';

export const MAPPED_SUBSCRIPTION_NAVIGATION_EVENTS: [
  NavEventType,
  {
    state: State;
    actions?: Actions<Context, any>;
  },
][] = [
  [Event.NAVIGATE_TO_VEHICULE, { state: State.VEHICLE_SUMMARY }],
  [Event.NAVIGATE_TO_DRIVING, { state: State.DRIVING_SUMMARY }],
  [Event.NAVIGATE_TO_PRIMARY_DRIVER_SUMMARY, { state: State.INFORMATION_PRIMARY_INSURANCE_RECORD_SUMMARY }],
  [Event.NAVIGATE_TO_SECONDARY_DRIVER_SUMMARY, { state: State.INFORMATION_SECONDARY_INSURANCE_RECORD_SUMMARY }],
  [Event.NAVIGATE_TO_INFORMATION, { state: State.INFORMATION_PRIMARY_DETAILS }],
  [Event.NAVIGATE_TO_TARIFICATION, { state: State.FORMULES, actions: resetFicMailDisplay }],
];

export const buildNavigationEvents = (): TransitionsConfigMap<Context, Events> => {
  const navigationEvents: TransitionsConfig<Context, Events> = {};

  MAPPED_SUBSCRIPTION_NAVIGATION_EVENTS.forEach((event) => {
    const [eventName, { state, actions }] = event;

    navigationEvents[eventName] = {
      target: state,
      actions: [{ actions } as any, () => sendBreadcrumbDestinationStateEvent(state)],
    };
  });
  return navigationEvents;
};
