import { useSubscriptionFsmDispatch } from '../fsm/context';
import { Event } from '../fsm/types';

export const useSendContinue = (): (() => void) => {
  const send = useSubscriptionFsmDispatch();
  const sendContinue = (): void => {
    send({ type: Event.CONTINUE });
  };
  return sendContinue;
};
