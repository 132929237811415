import cx from 'classnames';
import type { ReactNode } from 'react';
import { SecurePayment } from '../../../../components/SecurePayment';
import { GoCardlessLegalInformation } from './GoCardlessLegalInformation';
import { IbanField } from './IbanField';
import { IbanHolderField } from './IbanHolderField';
import styles from './styles.module.css';

export interface IbanCardProps {
  onChange?: () => void;
  className?: string;
}

export function IbanCard({ onChange, className }: IbanCardProps): ReactNode {
  return (
    <div className={cx(styles.IbanCard, className)}>
      <IbanField name="ibanNumber" onChange={onChange} />
      <IbanHolderField name="ibanTitulaire" onChange={onChange} />
      <SecurePayment />
      <GoCardlessLegalInformation />
    </div>
  );
}
