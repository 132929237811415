import { useRequiredValidator } from '@ornikar/react-validators';
import type { ReactNode } from 'react';
import { ScreenTemplate } from '../../../components/ScreenTemplates/ScreenTemplate';
import { CarburantField } from './CarburantField';

export function VehicleCarburantScreen(): ReactNode {
  const requiredValidator = useRequiredValidator();
  return (
    <ScreenTemplate
      title={
        <span>
          Quel est son
          <br />
          carburant / énergie&nbsp;?
        </span>
      }
      highlightTitle="Où trouver le type de carburant ?"
      highlightContent={
        <div>
          Rendez-vous au <b>repère P.3</b> de votre <b>carte grise (certificat d’immatriculation)</b>
        </div>
      }
      hideSubmitButtonOnFirstEditionOfField="vehiculeCarburant"
    >
      <CarburantField name="vehiculeCarburant" validate={requiredValidator} />
    </ScreenTemplate>
  );
}
