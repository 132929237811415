import { format, isBefore, setMonth, startOfToday } from 'date-fns';
import { fr } from 'date-fns/locale';
import type { ReactNode } from 'react';
import { DejaAssureAvecNomType } from '../../../apis/types/LongQuoteAnswers';
import { InformationList } from '../../../components/InformationList/InformationList';
import { InformationEntry } from '../../../components/InformationList/components/InformationEntry';
import { RecapScreenTemplate } from '../../../components/ScreenTemplates/RecapScreenTemplate';
import {
  vehicleFundingOptionsDict,
  vehicleInsuredByOptionsDict,
  vehicleInsuredSinceOptionsDict,
} from '../../../constants/mappers/vehicle';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { DefaultAnswersEnum, Event, State } from '../../../fsm/types';
import { formatDateForUI } from '../../../utils/date';
import { capitalize } from '../../../utils/string';
import CarIllustration from './assets/car.svg';
import { VehicleDetails } from './components/VehicleDetails';

export function VehicleSummaryScreen(): ReactNode {
  const {
    context: { answers, availableVersions, defaultAnswers },
  } = useSubscriptionFsmState();

  const send = useSubscriptionFsmDispatch();

  const date = setMonth(new Date(), (answers.vehicleCurrentContractAnniversaryMonth ?? 1) - 1); // This date is only used to get month name
  const vehicleInsuranceEndDateMonth = answers.vehicleCurrentContractAnniversaryMonth
    ? capitalize(format(date, 'MMMM', { locale: fr }))
    : 'Non renseignée';

  const financement = vehicleFundingOptionsDict[answers.vehiculeFinancement!];

  const purchaseDate = formatDateForUI(answers.vehiculeAcquisition!);
  const circulationDate = formatDateForUI(answers.vehiculeMiseEnCirculation!);

  const insuranceExpectedDate = formatDateForUI(answers.dateEffetSouhaite!);

  const insuredBy = vehicleInsuredByOptionsDict[answers.vehiculeDejaAssureAvecNom!];
  const notInsuredSince =
    answers.vehiculeDejaAssureAvecNom === DejaAssureAvecNomType.Non && answers.vehicleLastInsuranceEndDate
      ? `Non assuré depuis le ${formatDateForUI(answers.vehicleLastInsuranceEndDate)}`
      : '';
  const insuredSince = vehicleInsuredSinceOptionsDict[answers.vehiculeAssureDepuis!];
  const foundVersion = availableVersions.find((value) => value.id === answers.vehiculeVersion);
  const version = foundVersion ? foundVersion.version : '';
  const isVersionDefaultValue = defaultAnswers.includes(DefaultAnswersEnum.VEHICULE_VERSION);

  const shouldDisplayVehicleInsuranceInfo =
    answers.vehiculeAcquisition && isBefore(new Date(answers.vehiculeAcquisition), startOfToday());

  const moveToScreen = (destination: State) => {
    send(Event.RETURN_TO_SCREEN, { destination });
  };

  return (
    <RecapScreenTemplate
      title="Mon véhicule"
      illustration={<img src={CarIllustration} alt="Illustration d'une voiture" />}
      onConfirm={() => send(Event.CONTINUE)}
    >
      <InformationList>
        {answers.vehiculeImmatriculation ? (
          <InformationEntry
            label="Plaque d'immatriculation"
            value={answers.vehiculeImmatriculation!}
            footer={
              <VehicleDetails
                circulationDate={circulationDate}
                vehicleBrand={answers.vehiculeMarque!}
                vehicleModel={answers.vehiculeModele!}
                vehiclePower={answers.vehiculePuissanceFiscale!.toString()}
                vehicleFuel={answers.vehiculeCarburant!}
              />
            }
            onPress={() => moveToScreen(State.VEHICULE_LICENSE_PLATE)}
          >
            <InformationEntry
              label="Version"
              value={version}
              isDefaultValue={isVersionDefaultValue}
              onPress={() => moveToScreen(State.VEHICULE_FETCH_VERSIONS)}
            />
          </InformationEntry>
        ) : (
          <InformationEntry
            label="Marque"
            value={answers.vehiculeMarque!}
            onPress={() => moveToScreen(State.VEHICULE_BRAND)}
          >
            <InformationEntry
              label="Modèle"
              value={answers.vehiculeModele!}
              onPress={() => moveToScreen(State.VEHICULE_MODEL)}
            />
            <InformationEntry
              label="Puissance"
              value={`${answers.vehiculePuissanceFiscale!.toString()} CV`}
              isDefaultValue={defaultAnswers.includes(DefaultAnswersEnum.VEHICULE_PUISSANCE_FISCALE)}
              onPress={() => moveToScreen(State.VEHICULE_PUISSANCE_FISCALE)}
            />
            <InformationEntry
              label="Carburant"
              value={answers.vehiculeCarburant!}
              onPress={() => moveToScreen(State.VEHICULE_CARBURANT)}
            />
            <InformationEntry
              label="Date de mise en circulation"
              value={circulationDate}
              isDefaultValue={defaultAnswers.includes(DefaultAnswersEnum.VEHICULE_MISE_EN_CIRCULATION)}
              onPress={() => moveToScreen(State.VEHICULE_MISE_EN_CIRCULATION)}
            />
            <InformationEntry
              label="Version"
              value={version}
              isDefaultValue={isVersionDefaultValue}
              onPress={() => moveToScreen(State.VEHICULE_FETCH_VERSIONS)}
            />
          </InformationEntry>
        )}
        {shouldDisplayVehicleInsuranceInfo ? (
          <InformationEntry
            label="Date d'achat"
            value={purchaseDate}
            onPress={() => moveToScreen(State.VEHICULE_ACQUISITON_DATE)}
          >
            <InformationEntry
              label="Véhicule assuré"
              value={insuredBy}
              onPress={() => moveToScreen(State.VEHICULE_IS_COVERED)}
            />
            {answers.vehiculeDejaAssureAvecNom === DejaAssureAvecNomType.OuiAMonNom && (
              <InformationEntry
                label="Vous êtes chez votre assureur"
                value={insuredSince}
                onPress={() => moveToScreen(State.VEHICULE_COVERED_SINCE)}
              />
            )}
            {answers.vehiculeDejaAssureAvecNom === DejaAssureAvecNomType.OuiAMonNom && (
              <InformationEntry
                label="Date d'échéance du contrat"
                value={vehicleInsuranceEndDateMonth}
                onPress={() => moveToScreen(State.VEHICLE_CURRENT_CONTRACT_ANNIVERSARY_MONTH)}
              />
            )}
            {answers.vehiculeDejaAssureAvecNom === DejaAssureAvecNomType.Non && (
              <InformationEntry
                label="Véhicule assuré"
                value={notInsuredSince}
                onPress={() => moveToScreen(State.VEHICLE_LAST_INSURANCE_END_DATE)}
              />
            )}
          </InformationEntry>
        ) : (
          <InformationEntry
            label="Date d'achat"
            value={purchaseDate}
            onPress={() => moveToScreen(State.VEHICULE_ACQUISITON_DATE)}
          />
        )}
        <InformationEntry
          label="Date de début d'assurance souhaitée"
          value={insuranceExpectedDate}
          onPress={() => moveToScreen(State.VEHICULE_COVERING_DATE)}
        />
        <InformationEntry
          label="Financement"
          value={financement}
          onPress={() => moveToScreen(State.VEHICULE_FUNDING)}
        />
      </InformationList>
    </RecapScreenTemplate>
  );
}
