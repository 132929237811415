export const WEB_CALLBACK_CLICK = 'Web Callback Click';
export const WEB_CALLBACK = 'Web Callback';
export const WEB_CALLBACK_CONFIRMATION = 'Web Callback Confirmation';

export const MODAL_VIEWED = 'Modal Viewed';

export const EXIT_DOOR_EVENT = 'Exit Door';

export const MRH_CTA_CLICK = 'MRH CTA Clicked';

export const REGISTRATION_DOCUMENT_PARTNER_LINK_CLICK = 'Registration document partner click';
