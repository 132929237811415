import type { Except } from 'type-fest';
import { getPaymentSession } from '../../../apis/getPaymentSession';

type Environment = 'test' | 'live';

interface Session {
  id: string;
  sessionData: string;
}

interface PaymentCompletedResult {
  resultCode: string;
}

export interface CheckoutConfiguration {
  showPayButton: boolean;
  locale: string;
  environment: Environment;
  clientKey: string;
  analytics: {
    enabled: boolean;
  };
  session: Session;
  onPaymentCompleted: ({ resultCode }: PaymentCompletedResult) => void;
  onError: () => void;
  paymentMethodsConfiguration: any;
}

const baseConfiguration: Except<
  CheckoutConfiguration,
  'clientKey' | 'environment' | 'session' | 'onPaymentCompleted' | 'onError'
> = {
  showPayButton: true,
  locale: 'fr-FR',
  analytics: {
    enabled: true,
  },
  paymentMethodsConfiguration: {
    card: {
      hasHolderName: true,
      holderNameRequired: true,
      enableStoreDetails: false,
      hideCVC: false,
      name: 'Credit or debit card',
    },
  },
};

const APPROXIMATED_DELAY_TO_SUCCCESS_ANIMATION_MS = 2000;

export const generateCheckoutConfig = async (
  quoteId: number | string,
  successCallback: () => void,
  errorCallback: () => void,
): Promise<CheckoutConfiguration> => {
  const adyenEnv = import.meta.env.MODE === 'production' ? 'live' : 'test';

  const session = await getPaymentSession(quoteId);

  return {
    ...baseConfiguration,
    onPaymentCompleted: ({ resultCode }): void => {
      if (['Cancelled', 'Error', 'Refused'].includes(resultCode)) {
        errorCallback();
        return;
      }

      setTimeout(() => {
        successCallback();
      }, APPROXIMATED_DELAY_TO_SUCCCESS_ANIMATION_MS);
    },
    onError: (): void => {
      errorCallback();
    },
    clientKey: import.meta.env.VITE_ADYEN_CLIENT_KEY,
    environment: adyenEnv,
    session: {
      id: session.id,
      sessionData: session.data,
    },
  };
};
