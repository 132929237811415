import { Typography } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { FormulaPriceBottomBar } from '../../../components/FormulaPrice/FormulaPriceBottomBar';
import { FormulaPriceCard } from '../../../components/FormulaPrice/FormulaPriceCard';
import { ScreenTemplateWithSideAndBottomBars } from '../../../components/ScreenTemplates/ScreenTemplateWithSideAndBottomBars';
import { useSubscriptionFsmDispatch } from '../../../fsm/context';
import { Event } from '../../../fsm/types';
import { useSendPageNameToSegment } from '../../../providers/AnalyticsProvider';
import womanOnPhone from './assets/woman-phone.svg';
import styles from './styles.module.css';

export function AddonsIntroductionScreen(): ReactNode {
  const send = useSubscriptionFsmDispatch();
  useSendPageNameToSegment();

  const handleSubmit = (): void => {
    send(Event.CONTINUE);
  };

  return (
    <ScreenTemplateWithSideAndBottomBars
      hideSubmitButton
      hideSaveQuoteButton
      title=" À présent, personnalisez votre assurance avec les options suivantes puis ajustez vos niveaux de franchises. "
      submitButton={{
        label: 'J’ai compris, c’est parti',
      }}
      className={styles.Column}
      sideBar={<FormulaPriceCard buttonLabel="J’ai compris, c’est parti" onSubmit={handleSubmit} />}
      bottomBar={<FormulaPriceBottomBar buttonLabel="J’ai compris, c’est parti" onSubmit={handleSubmit} />}
      onSubmit={handleSubmit}
    >
      <Typography.Text variant="regular" textAlign="center">
        Vous pouvez finaliser votre souscription en ligne ou avec un conseiller.
      </Typography.Text>
      <img src={womanOnPhone} alt="Femme qui regarde son téléphone dans sa main" className="Illustration" />
    </ScreenTemplateWithSideAndBottomBars>
  );
}
