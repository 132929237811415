import type { ReactNode } from 'react';
import { ClaimRecordDriverTemplate } from '../../../../components/ScreenTemplates/InformationTemplates/InsuranceRecordTemplates/ClaimRecordTemplates/ClaimRecordDriverTemplate';
import { claimRecordDriverOptionsList } from '../../../../constants/mappers/information';
import { useSubscriptionFsmState } from '../../../../fsm/context';

export function PrimaryClaimRecordDriverScreen(): ReactNode {
  const {
    context: { editingInsuranceRecord },
  } = useSubscriptionFsmState();

  return (
    <ClaimRecordDriverTemplate
      fieldName={`primaryDriver.claimRecords[${editingInsuranceRecord.index}].driver`}
      title="Qui était au volant&nbsp;?"
      choices={claimRecordDriverOptionsList}
    />
  );
}
