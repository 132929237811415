import {
  ArrowLeftRegularIcon,
  CalendarRegularIcon,
  PaperPlaneTiltRegularIcon,
  PhoneRegularIcon,
} from '@ornikar/kitt-icons/phosphor';
import { Button, Typography, View } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { useState } from 'react';
import { useGoBack } from '../../hooks/useGoBack';
import { useInsuranceDesktopMediaQuery } from '../../hooks/useInsuranceDesktopMediaQuery';
import { sendEvent } from '../../utils/mixpanel';
import { CallbackModuleModal } from '../CallbackModuleModal';
import { ConstrainedWidth } from '../ConstrainedWidth';
import styles from './styles.module.css';

export interface ContactLayoutProps {
  title: ReactNode;
  text?: ReactNode;
}
export function ContactLayout({ title, text }: ContactLayoutProps): ReactNode {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isDesktop = useInsuranceDesktopMediaQuery();
  const [canBack, goBack] = useGoBack();

  const handleContactButtonPress = (method: 'phone' | 'email' | 'appointment'): void => {
    sendEvent('Select Contact Method', {
      contact_method: method,
    });
  };

  return (
    <div className={styles.Screen}>
      <div className={styles.TitleContainer}>
        <ConstrainedWidth>
          {canBack && isDesktop ? (
            <Button
              icon={<ArrowLeftRegularIcon />}
              iconPosition="left"
              type="subtle-dark"
              className={styles.BackButton}
              onPress={goBack}
            >
              Étape précédente
            </Button>
          ) : null}
          <View marginTop="kitt.4">
            <Typography.h3 textAlign="center">{title}</Typography.h3>
          </View>
        </ConstrainedWidth>
      </div>

      <ConstrainedWidth>
        <div className={styles.CenteredColumn}>
          {text ? (
            <View margin="kitt.2" marginTop="kitt.12" marginBottom="kitt.8">
              <Typography.Paragraph textAlign="center">{text}</Typography.Paragraph>
            </View>
          ) : null}

          <Typography.Paragraph textAlign="center">
            Nous sommes à votre écoute du <b>lundi au vendredi de 9h à 20h.</b>
          </Typography.Paragraph>
          <div className={styles.ButtonSection}>
            <Button
              stretch
              icon={<PhoneRegularIcon />}
              type="primary"
              href="tel:0176497522"
              onPress={() => {
                handleContactButtonPress('phone');
              }}
            >
              01 76 49 75 22
            </Button>
            <Button
              stretch
              icon={<CalendarRegularIcon />}
              type="primary"
              onPress={() => {
                setIsModalVisible(true);
                handleContactButtonPress('appointment');
              }}
            >
              Prendre un rendez-vous
            </Button>
            <Button
              stretch
              icon={<PaperPlaneTiltRegularIcon />}
              type="primary"
              href="mailto:assurance@ornikar.com"
              onPress={() => {
                handleContactButtonPress('email');
              }}
            >
              assurance@ornikar.com
            </Button>
          </div>
        </div>
      </ConstrainedWidth>
      <CallbackModuleModal isVisible={isModalVisible} onClose={() => setIsModalVisible(false)} />
    </div>
  );
}
