import { useEffect, useState } from 'react';

export const Query = {
  SMALL: '(min-width: 480px)',
  MEDIUM: '(min-width: 768px)',
  LARGE: '(min-width: 1024px)',
  WIDE: '(min-width: 1280px)',
};

export const useMediaQuery = (query: string): boolean => {
  const [isValid, setIsValid] = useState(() => window.matchMedia(query).matches);

  useEffect(() => {
    const handler = (e: MediaQueryListEvent): void => setIsValid(e.matches);

    window.matchMedia(query).addListener(handler);

    // Using deprecated method addListener because addEventListener has not been implemented in IE and some Safari versions

    return () => {
      window.matchMedia(query).removeListener(handler);
    };
  }, [query]);

  return isValid;
};
