import { InputText } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import type { FieldProps } from '../../../components/Field';
import { Field } from '../../../components/Field';
import styles from './styles.module.css';

export function EmailField({ validate }: Pick<FieldProps<string>, 'validate'>): ReactNode {
  return (
    <Field
      component={InputText}
      className={styles.Field}
      name="subscriberEmail"
      label="Votre adresse e-mail"
      validate={validate}
      type="email"
    />
  );
}
