import { IllustratedIcon, Information } from '@ornikar/illustrated-icons';
import {
  FileArrowDownRegularIcon,
  PiggyBankRegularIcon,
  SparkleRegularIcon,
  XRegularIcon,
} from '@ornikar/kitt-icons/phosphor';
import {
  ActionCard,
  ExternalLink,
  HStack,
  Highlight,
  Icon,
  IconButton,
  NavigationModal,
  Typography,
  TypographyLink,
  VStack,
  View,
  theme,
} from '@ornikar/kitt-universal';
import { format } from 'date-fns';
import type { ReactNode } from 'react';
import { Separator } from './Separator';

interface PromoCodeModalProps {
  isVisible: boolean;
  onClose: () => void;
  amount: ReactNode;
  promoCode: string;
  minimumPrimeAmount?: number;
  campaignStartDate?: string;
  campaignEndDate?: string;
  generalConditionsURL?: string;
}

export function PromoCodeModal({
  isVisible,
  onClose,
  amount = 0,
  promoCode,
  minimumPrimeAmount,
  campaignEndDate,
  campaignStartDate,
  generalConditionsURL,
}: PromoCodeModalProps): ReactNode {
  const hightlightIcon = <IllustratedIcon icon={<Information />} size={48} />;

  function renderDateSticker(): ReactNode {
    if (!campaignStartDate || !campaignEndDate) {
      return null;
    }
    return (
      <View
        top={0}
        position="absolute"
        padding={2}
        width={185}
        backgroundColor="kitt.white"
        height={51}
        borderRadius="50%"
        style={{
          transform: 'rotate(2deg)',
          shadowColor: '#000000',
          shadowRadius: 1,
          shadowOffset: {
            width: 0,
            height: 1,
          },
          shadowOpacity: 0.1,
        }}
      >
        <HStack
          width="100%"
          height="100%"
          borderRadius="50%"
          borderWidth={1}
          justifyContent="center"
          alignItems="center"
        >
          <Typography.Text variant="regular" style={{ fontFamily: 'Transducer', fontStyle: 'italic', fontSize: 15 }}>
            Du {format(new Date(campaignStartDate), 'dd/MM')} au 01/12 !
          </Typography.Text>
        </HStack>
      </View>
    );
  }

  return (
    <NavigationModal.ModalBehaviour visible={isVisible} onClose={onClose}>
      <NavigationModal
        header={
          <NavigationModal.Header right={<IconButton icon={<XRegularIcon />} onPress={onClose} />} left={<View />}>
            <Typography.Text base="body" variant="bold" textAlign="center">
              Code promo
            </Typography.Text>
          </NavigationModal.Header>
        }
        body={
          <NavigationModal.Body>
            <VStack space="kitt.6">
              {/* BANNER */}
              <View paddingY="kitt.2" paddingTop={37}>
                <HStack justifyContent="center">
                  <HStack
                    backgroundColor={theme.palettes.lateOcean['lavender.7']}
                    padding="kitt.2"
                    width="fit-content"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      transform: 'rotate(-4deg)',
                    }}
                  >
                    <Typography.Text
                      variant="bold"
                      style={{
                        fontFamily: 'Transducer',
                        fontSize: 48,
                        lineHeight: 48,
                        marginTop: -4,
                        // @ts-expect-error next-line
                        WebkitTextStroke: '3px black',
                      }}
                      color="kitt.white"
                    >
                      {promoCode}
                    </Typography.Text>
                    <Typography.Text
                      variant="bold"
                      style={{
                        position: 'absolute',
                        fontFamily: 'Transducer',
                        fontSize: 48,
                        lineHeight: 48,
                        marginTop: -4,
                      }}
                      color="kitt.white"
                    >
                      {promoCode}
                    </Typography.Text>
                  </HStack>
                </HStack>

                {renderDateSticker()}
              </View>

              {/* HEAD */}
              <VStack space="kitt.2">
                <Typography.Text variant="bold" type={{ base: 'heading-s' }}>
                  Profitez de {amount}€ remboursés sur votre assurance&nbsp;!
                </Typography.Text>

                {minimumPrimeAmount ? (
                  <Typography.Text variant="regular">
                    Offre valable pour une assurance annuelle minimum de {minimumPrimeAmount}€ TTC.
                  </Typography.Text>
                ) : null}
              </VStack>

              {/* DESCRIPTION */}
              <ActionCard>
                <VStack space="kitt.4" padding="kitt.4">
                  <Typography.Text variant="bold" type={{ base: 'heading-xs' }}>
                    Comment ça marche&nbsp;?
                  </Typography.Text>

                  <VStack space="kitt.2">
                    <HStack space="kitt.2">
                      <Icon icon={<SparkleRegularIcon />} color="black" />
                      <Typography.Text>Finalisez votre souscription</Typography.Text>
                    </HStack>

                    <Separator />

                    <HStack space="kitt.2">
                      <Icon icon={<FileArrowDownRegularIcon />} color="black" />
                      <Typography.Text>Envoyez vos documents sous 30 jours</Typography.Text>
                    </HStack>

                    <Separator />

                    <HStack space="kitt.2">
                      <Icon icon={<PiggyBankRegularIcon />} color="black" />
                      <Typography.Text>
                        Le remboursement sera effectué sur votre compte bancaire après le délai indiqué dans les
                        conditions de l'offre
                      </Typography.Text>
                    </HStack>
                  </VStack>
                </VStack>
              </ActionCard>

              <Highlight icon={hightlightIcon}>
                <Typography.Text variant="regular">
                  Le code promo n'est pas cumulable avec d'autres offres promotionnelles.
                </Typography.Text>
                <ExternalLink variant="regular" href={generalConditionsURL} as={TypographyLink}>
                  Voir les conditions de l’offre.
                </ExternalLink>
              </Highlight>
            </VStack>
          </NavigationModal.Body>
        }
      />
    </NavigationModal.ModalBehaviour>
  );
}
