import { assign } from 'xstate';
import type { Addon, QuoteFsmAnswers } from '../answers';
import { AddonName } from '../answers';
import type { Context } from '../types';

/**
 * The purpose of this mapping is to link "parent add-ons" to its "children add-ons"
 * It's used in the following function to automatically disable "children add-ons" when their "parents" are not selected
 */
export const parentAddonToChildrenAddonsMapping = {
  [AddonName.OML_ASSISTANCE_0_KM_ADDON]: [AddonName.OML_VEHICLE_REPLACEMENT],
};

export const getFilteredSelectableAddons = (currentAddons: QuoteFsmAnswers['addons']): QuoteFsmAnswers['addons'] => {
  const addons = { ...currentAddons };

  Object.entries(parentAddonToChildrenAddonsMapping)
    .filter(([parent]) => !addons[parent as AddonName]?.isSelected)
    .forEach(([, children]) => {
      children.forEach((child) => {
        if (addons[child]?.isSelected) {
          addons[child] = {
            ...addons[child],
            isSelected: false,
          } as Addon;
        }
      });
    });

  return addons;
};

export const checkAndTransformAddons = assign((context: Context) => {
  if (context.answers.addons) {
    const addons = getFilteredSelectableAddons(context.answers.addons);

    return {
      answers: {
        ...context.answers,
        addons,
      },
    };
  }

  return {};
});
