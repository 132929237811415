import type { DoneInvokeEvent } from 'xstate';
import { assign } from 'xstate';
import type { Version } from '../../apis/vehicules';
import type { VehiculePreSelectionType } from '../answers';
import type { AnswerEvent, Context, Event, Events } from '../types';
import type { AnswerAssign, SimpleEventAssign, TypedAssign } from './types';

export const changeVehiclePreSelectionType = (type: VehiculePreSelectionType): SimpleEventAssign<Event.SKIP> =>
  assign((context) => ({
    answers: { ...context.answers, vehiculePreSelectionType: type },
  }));

export const setVehicleInfo: AnswerAssign = assign((context, event) => {
  const { vehiculeVersionOptions, ...vehicleData } = event.answers;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { vehiculeVersion, vehiculeVersionName, ...answers } = context.answers;
  const hasUniqueOption = vehiculeVersionOptions?.length === 1;
  return {
    availableVersions: vehiculeVersionOptions,
    answers: {
      ...answers,
      ...vehicleData,
      vehicleVersion: hasUniqueOption ? vehiculeVersionOptions[0].id : undefined,
      vehicleVersionName: hasUniqueOption ? vehiculeVersionOptions[0].version : undefined,
    },
  };
});

export const resetVehicleInfoOrNot: AnswerAssign = assign((context, event) => {
  if (
    typeof context.answers.vehiculePreSelectionType === 'number' &&
    context.answers.vehiculePreSelectionType !== event.answers.vehiculePreSelectionType
  ) {
    return {
      answers: {
        ...context.answers,
        vehiculeImmatriculation: undefined,
        vehiculeMarque: undefined,
        vehiculeModele: undefined,
        vehiculeCarburant: undefined,
        vehiculePuissanceFiscale: undefined,
        vehiculeMiseEnCirculation: undefined,
        vehiculeVersionOptions: undefined,
        vehiculeVersion: undefined,
        vehiculeVersionName: undefined,
        vehiculePreSelectionType: undefined,
      },
    };
  }
  return {};
});

export const resetVehicleInfoOnSkip: SimpleEventAssign<Event.SKIP> = assign((context) => {
  return {
    answers: {
      ...context.answers,
      vehiculeImmatriculation: undefined,
      vehiculeMarque: undefined,
      vehiculeModele: undefined,
      vehiculeCarburant: undefined,
      vehiculePuissanceFiscale: undefined,
      vehiculeMiseEnCirculation: undefined,
      vehiculeVersionOptions: undefined,
      vehiculeVersion: undefined,
      vehiculeVersionName: undefined,
      vehiculePreSelectionType: undefined,
    },
  };
});

export const resetVehicleInfoFromBrand: AnswerAssign = assign((context, event) => {
  if (event.answers.vehiculeMarque !== context.answers.vehiculeMarque) {
    return {
      answers: {
        ...context.answers,
        vehiculeMarque: event.answers.vehiculeMarque,
        vehiculeModele: undefined,
        vehiculePuissanceFiscale: undefined,
        vehiculeCarburant: undefined,
        vehiculeIsUtility: undefined,
        vehiculeMiseEnCirculation: undefined,
        vehiculeVersion: undefined,
        vehiculeVersionOptions: undefined,
        vehiculeVersionName: undefined,
        vehiculeInsuranceSituation: undefined,
        vehiculeDejaAssureAvecNom: undefined,
        vehiculeAcquisition: undefined,
        vehiculeFinancement: undefined,
        vehiculeImmatriculation: undefined,
      },
      availableBrands: event.answers.availableBrands,
      availableModels: undefined,
      availableCarburants: undefined,
      availablePuissanceFiscales: undefined,
      availableVersions: undefined,
    };
  }

  return {};
});

export const resetVehicleInfoFromModel: AnswerAssign = assign((context, event) => {
  if (event.answers.vehiculeModele !== context.answers.vehiculeModele) {
    return {
      answers: {
        ...context.answers,
        vehiculeModele: event.answers.vehiculeModele,
        vehiculePuissanceFiscale: undefined,
        vehiculeCarburant: undefined,
        vehiculeIsUtility: undefined,
        vehiculeMiseEnCirculation: undefined,
        vehiculeVersion: undefined,
        vehiculeVersionOptions: undefined,
        vehiculeVersionName: undefined,
        vehiculeInsuranceSituation: undefined,
        vehiculeDejaAssureAvecNom: undefined,
        vehiculeAcquisition: undefined,
        vehiculeFinancement: undefined,
        vehiculeImmatriculation: undefined,
      },
      availableModels: event.answers.availableModels,
      availableVersions: undefined,
      availableCarburants: event.answers.availableCarburants,
      availablePuissanceFiscales: event.answers.availablePuissanceFiscales,
    };
  }

  return {
    availableModels: event.answers.availableModels,
    availableCarburants: event.answers.availableCarburants,
    availablePuissanceFiscales: event.answers.availablePuissanceFiscales,
  };
});

export const resetVehicleInfoFromHorsePower: AnswerAssign = assign((context, event) => {
  if (event.answers.vehiculePuissanceFiscale !== context.answers.vehiculePuissanceFiscale) {
    return {
      answers: {
        ...context.answers,
        vehiculePuissanceFiscale: event.answers.vehiculePuissanceFiscale,
        vehiculeCarburant: undefined,
        vehiculeIsUtility: undefined,
        vehiculeMiseEnCirculation: undefined,
        vehiculeVersion: undefined,
        vehiculeVersionOptions: undefined,
        vehiculeVersionName: undefined,
        vehiculeInsuranceSituation: undefined,
        vehiculeDejaAssureAvecNom: undefined,
        vehiculeAcquisition: undefined,
        vehiculeFinancement: undefined,
        vehiculeImmatriculation: undefined,
      },
      availableVersions: undefined,
    };
  }

  return {};
});

export const resetVehicleInfoFromEnergy: AnswerAssign = assign((context, event) => {
  if (event.answers.vehiculeCarburant !== context.answers.vehiculeCarburant) {
    return {
      answers: {
        ...context.answers,
        vehiculeCarburant: event.answers.vehiculeCarburant,
        vehiculeIsUtility: undefined,
        vehiculeMiseEnCirculation: undefined,
        vehiculeVersion: undefined,
        vehiculeVersionOptions: undefined,
        vehiculeVersionName: undefined,
        vehiculeInsuranceSituation: undefined,
        vehiculeDejaAssureAvecNom: undefined,
        vehiculeAcquisition: undefined,
        vehiculeFinancement: undefined,
        vehiculeImmatriculation: undefined,
      },
      isBrandSelected: true,
      availableVersions: undefined,
    };
  }

  return {};
});

export const resetVehicleInfoFromVehicleAcquisition: AnswerAssign = assign((context, event) => {
  const answersToReset =
    event.answers.vehiculeAcquisition !== context.answers.vehiculeAcquisition
      ? {
          vehiculeDejaAssureAvecNom: undefined,
          vehiculeAssureDepuis: undefined,
          vehicleCurrentContractAnniversaryMonth: undefined,
        }
      : {};

  return {
    answers: {
      ...context.answers,
      vehiculeAcquisition: event.answers.vehiculeAcquisition,
      ...answersToReset,
    },
  };
});

export const setVehicleVersion: AnswerAssign = assign((context, event) => {
  return {
    answers: {
      ...context.answers,
      vehiculeIsUtility: event.answers.vehiculeIsUtility,
      vehiculeVersion: event.answers.vehiculeVersion,
      vehiculeVersionName: context.availableVersions?.find(
        ({ id: versionId }) => versionId === event.answers.vehiculeVersion,
      )?.version,
    },
  };
});

export const setVehicleAvailableVersions = assign<Context, DoneInvokeEvent<Version[]>>((_, event) => ({
  availableVersions: event.data,
  isLoading: false,
}));

export const setVehicleUniqueAvailableVersion = assign<Context, DoneInvokeEvent<Version[]>>((context, event) => ({
  answers: {
    ...context.answers,
    vehiculeIsUtility: event.data[0].is_utility,
    vehiculeVersion: event.data[0].id,
    vehiculeVersionName: event.data[0].version,
  },
  isLoading: false,
  availableVersions: event.data,
}));

export const resetVehicleAvailableVersion = assign<Context, DoneInvokeEvent<any>>(() => ({
  availableVersions: undefined,
  isLoading: false,
}));

export const setFetchingLicensePlateLoading = assign<Context, Events>({
  isFetchingLicensePlate: true,
});

export const resetFetchingLicensePlateLoading = assign<Context, Events>({
  isFetchingLicensePlate: false,
});

export const resetVehicleCurrentContractAnniversaryMonth: AnswerAssign = assign((context) => {
  return {
    answers: {
      ...context.answers,
      vehicleCurrentContractAnniversaryMonth: undefined,
    },
  };
});

export const resetVehiculeDejaAssureAvecNom: SimpleEventAssign<Event.INSURANCE_DATE_INCONSISTENCY> = assign(
  (context) => {
    return {
      answers: {
        ...context.answers,
        vehiculeDejaAssureAvecNom: undefined,
      },
    };
  },
);

export const setIsLoading = <T extends AnswerEvent | DoneInvokeEvent<any>>(isLoading: boolean): TypedAssign<T> =>
  assign<Context, T>(() => ({ isLoading }));
