/* eslint-disable jsx-a11y/alt-text */
import { TypographyLink } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import Facebook from './assets/facebook.svg';
import Instagram from './assets/instagram.svg';
import Twitter from './assets/twitter.svg';
import Youtube from './assets/youtube.svg';
import styles from './styles.module.css';

export function FooterSocials(): ReactNode {
  return (
    <div className={styles.Social}>
      <TypographyLink
        variant="regular"
        href="https://www.instagram.com/ornikarassur"
        hrefAttrs={{ target: '_blank', rel: 'noopener noreferrer' }}
      >
        <img src={Instagram} />
      </TypographyLink>
      <TypographyLink
        variant="regular"
        href="https://www.facebook.com/OrnikarAssurance"
        hrefAttrs={{ target: '_blank', rel: 'noopener noreferrer' }}
      >
        <img src={Facebook} />
      </TypographyLink>
      <TypographyLink
        variant="regular"
        href="https://www.youtube.com/channel/UCMBNOyJPLv5GmFr-TRL4DVw"
        hrefAttrs={{ target: '_blank', rel: 'noopener noreferrer' }}
      >
        <img src={Youtube} />
      </TypographyLink>
      <TypographyLink
        variant="regular"
        href="https://twitter.com/ornikarassur"
        hrefAttrs={{ target: '_blank', rel: 'noopener noreferrer' }}
      >
        <img src={Twitter} />
      </TypographyLink>
    </div>
  );
}
