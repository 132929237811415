import { Checkbox } from '@ornikar/kitt';
import { Typography, VStack } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { useEffect } from 'react';
import { useField } from 'react-final-form';
import { Field } from '../../../../components/Field';
import { useIbanCheckboxValidator } from '../../../../forms/validation/sections/insurance/iban';
import { useDocumentsNeeded } from '../../../../hooks/useDocumentsNeeded';
import styles from './styles.module.css';

const CHECKBOXES_LEGAL_VERSION = 6;

export function PaymentLegalCheckboxes(): ReactNode {
  const { checkboxesVersionInput } = useField('checkboxesVersion');
  const documentsList = useDocumentsNeeded();

  const validCheckBox = useIbanCheckboxValidator();

  useEffect(() => {
    checkboxesVersionInput?.onChange(CHECKBOXES_LEGAL_VERSION);
  }, [checkboxesVersionInput]);

  /* LEGAL REQUIREMENT : any change in the following return block shoud increment the CHECKBOXES_LEGAL_VERSION */
  return (
    <div className={styles.Checkboxes}>
      <Field baseline name="legalCheckbox1" validate={validCheckBox} type="checkbox" component={Checkbox}>
        Je reconnais avoir pris connaissance des documents d'information fournis par l'Assureur (FIC et DIPA), des
        Conditions Générales Automobile, de la recommandation de Ornikar Assurances et du délai de 14 jours calendaires
        à compter de la souscription du contrat pour me rétracter.
      </Field>
      <Field baseline name="legalCheckbox2" validate={validCheckBox} type="checkbox" component={Checkbox}>
        Je reconnais que l'ensemble des déclarations apportées aux questions précédemment posées sont exactes, conformes
        à la réalité et au risque objet de mon souhait d'assurance et je m'engage à transmettre tous les documents
        nécessaires (
        {documentsList
          .map((document) => (document === 'Permis de conduire' ? `${document} ou certificat temporaire` : document))
          .join(', ')}
        ) à la finalisation de mon contrat sous 30 jours.
      </Field>
      <VStack marginTop="kitt.3">
        <Typography.Paragraph color="black-light" base="body-xsmall">
          En souscrivant un contrat, vous êtes susceptible de recevoir des offres et avantages exclusifs du groupe
          Ornikar. Vous pourrez vous désinscrire à tout moment.
        </Typography.Paragraph>
      </VStack>
    </div>
  );
  /* END LEGAL REQUIREMENT */
}
