import { useRequiredValidator } from '@ornikar/react-validators';
import { addMonths, isAfter, startOfToday, subDays } from 'date-fns';
import { type ReactNode, useMemo } from 'react';
import { useField } from 'react-final-form';
import { RadioScreenTemplate } from '../../../components/ScreenTemplates/RadioScreenTemplate';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { Event } from '../../../fsm/types';
import { formatDate } from '../../../utils/date';

export function DrivingPrimaryDefaultLicenseDateScreen(): ReactNode {
  const {
    input: { value: primaryDriver },
  } = useField('primaryDriver');

  const requiredValidator = useRequiredValidator();

  const send = useSubscriptionFsmDispatch();

  const { context } = useSubscriptionFsmState();

  const choices = useMemo(() => {
    const options = [
      {
        label: 'Dans moins d’1 mois',
        value: startOfToday(),
      },
      {
        label: 'Dans moins de 3 mois',
        value: addMonths(startOfToday(), 1),
      },
      {
        label: 'Je ne sais pas',
        value: subDays(addMonths(startOfToday(), 1), 1),
      },
    ];

    const effectiveDate = new Date(context.answers.dateEffetSouhaite!);

    return options
      .filter(({ value }) => !isAfter(value, effectiveDate))
      .map(({ label, value }) => ({
        label,
        value: formatDate(value),
      }));
  }, [context.answers.dateEffetSouhaite]);

  const handleSubmit = (): void => {
    send(Event.DEFAULT_ANSWER, {
      answers: {
        primaryDriver,
      },
    });
  };

  return (
    <RadioScreenTemplate
      fieldName="primaryDriver.licenseDate"
      title="Quand pensez-vous avoir votre permis&nbsp;?"
      choices={choices}
      validate={requiredValidator}
      onSubmit={handleSubmit}
    />
  );
}
