import { useRequiredValidator } from '@ornikar/react-validators';
import { useEffect } from 'react';
import type { ReactNode } from 'react';
import { useField, useForm } from 'react-final-form';
import type { Except } from 'type-fest';
import { useDebounce } from '../../../hooks/useDebounce';
import type { FieldProps } from '../../Field';
import { Field } from '../../Field';
import { RadioBlockGroup } from '../../RadioBlockGroup';
import type { ChoiceWithLabel, ChoiceWithRender, RadioBlockGroupProps } from '../../RadioBlockGroup';
import { ScreenTemplate } from '../ScreenTemplate';
import type { ScreenTemplateProps } from '../ScreenTemplate';

export interface RadioScreenTemplateProps<FieldValue extends string> extends Except<ScreenTemplateProps, 'children'> {
  fieldName: string;
  onChange?: () => void;
  children?: ReactNode;
  choices: (ChoiceWithLabel<FieldValue> | ChoiceWithRender<FieldValue>)[];
  validate?: FieldProps<FieldValue>['validate'];
  defaultValue?: FieldProps<FieldValue>['defaultValue'];
}

export function RadioScreenTemplate<FieldValue extends string>({
  fieldName,
  children,
  choices,
  defaultValue,
  ...props
}: RadioScreenTemplateProps<FieldValue>): ReactNode {
  const requiredValidator = useRequiredValidator();
  const { submit } = useForm();
  const {
    meta,
    input: { value },
  } = useField(fieldName);

  const debounceSubmit = useDebounce(props.onSubmit || submit);

  useEffect(() => {
    // make sure we updated the value, so if a value is set by default, we don't submit
    if (meta.modified) {
      debounceSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <ScreenTemplate {...props} hideSubmitButtonOnFirstEditionOfField={defaultValue ? '' : fieldName}>
      <Field<FieldValue, RadioBlockGroupProps>
        {...props}
        component={RadioBlockGroup}
        name={fieldName}
        choices={choices}
        validate={props.validate || requiredValidator}
        defaultValue={defaultValue}
      />
      {children}
    </ScreenTemplate>
  );
}
