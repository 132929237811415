import { Typography, VStack } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';

interface SectionTitleProps {
  title: string;
  fromDate: string;
}

export function SectionTitle({ title, fromDate }: SectionTitleProps): ReactNode {
  return (
    <VStack space="kitt.1">
      <Typography.Text base="header4" variant="bold">
        {title}
      </Typography.Text>
      <Typography.Text base="body">Depuis le {fromDate}</Typography.Text>
    </VStack>
  );
}
