import { AddonName, DeductibleName } from '../fsm/answers';

export const addonNameMapping: Record<AddonName, string> = {
  [AddonName.OML_ASSISTANCE_0_KM_ADDON]: 'Assistance 0km',
  [AddonName.OML_VEHICLE_REPLACEMENT]: 'Véhicule de remplacement',
  [AddonName.OML_DRIVER_PROTECTION_500]: 'Protection du conducteur étendue',
  [AddonName.OML_VEHICLE_LOAN]: 'Prêt de volant',
};

export const deductibleNameMapping: Record<DeductibleName, string> = {
  [DeductibleName.OML_WIND_SCREEN]: 'Bris de glace',
  [DeductibleName.OML_FIRE_THEFT_DTA]: 'Vol et incendie',
};
