import type { HamonLawInformation, ServerHamonLawInformation } from '../types/HamonLawInformation';

export function hamonLawInformationToServerRequest({
  hamonAdress,
  hamonName,
  hamonCodePostal,
  hamonCommune,
  hamonContractRef,
  hamonCedex,
}: HamonLawInformation): ServerHamonLawInformation {
  return {
    currentInsurerAddress: hamonAdress,
    currentInsurerCity: hamonCommune,
    currentInsurerCompanyName: hamonName,
    currentInsurerZipCode: hamonCodePostal,
    currentInsurerCedex: hamonCedex,
    currentPolicyReference: hamonContractRef,
  };
}
