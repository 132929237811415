import type { ID } from '@segment/analytics-next';
import { AnalyticsBrowser } from '@segment/analytics-next';
import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Civility } from '../apis/types/LongQuoteAnswers';
import { DriverHasBeenCovered } from '../fsm/answers';
import { useSubscriptionFsmState, useSubscriptionFsmStateValue } from '../fsm/context';
import { State } from '../fsm/types';

const AnalyticsContext = createContext<AnalyticsBrowser>(new AnalyticsBrowser());

const segmentationPageNameToIndex: Partial<Record<State, number>> = {
  [State.FORMULES]: 1,
  [State.OPTIONS_INTRODUCTION]: 2,
  [State.ADDON_DRIVER_PROTECTION_500]: 3,
  [State.REGISTER_IBAN]: 4,
  [State.WEBAPP_INTRODUCTION]: 10,
};

interface AnalyticsProviderProps {
  children: ReactNode;
}

export function AnalyticsProvider({ children }: AnalyticsProviderProps): ReactNode {
  const analytics: AnalyticsBrowser = useMemo(
    () => AnalyticsBrowser.load({ writeKey: import.meta.env.VITE_SEGMENT_API_KEY }),
    [],
  );

  return <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>;
}

export function useAnalytics(): AnalyticsBrowser {
  return useContext(AnalyticsContext);
}

export function useSegmentAnonymousId(): ID {
  const [anonymousId, setAnonymousId] = useState<ID>();
  const analytics = useAnalytics();

  useEffect(() => {
    const getUser = async (): Promise<void> => {
      const user = await analytics.user();
      setAnonymousId(user.anonymousId());
    };
    getUser();
  }, [analytics]);
  return anonymousId;
}

export function useIdentifyUser(): void {
  const { context } = useSubscriptionFsmState();
  const analytics = useAnalytics();

  useEffect(() => {
    const { segmentUserId, answers, isFromAggregator } = context;

    analytics.identify(segmentUserId, {
      insurance_lead_gender: answers.primaryDriver?.civility === Civility.Femme ? 'F' : 'M',
      insurance_lead_penaly:
        answers.primaryDriver?.hasBeenCovered === DriverHasBeenCovered.NonJamais ? 100 : answers.primaryDriver?.crm,
      insurance_lead_is_from_aggregator: isFromAggregator,
    });
  }, [analytics, context]);
}

export function useSendPageNameToSegment(): void {
  const analytics = useAnalytics();
  const pageName = useSubscriptionFsmStateValue() as State;

  useEffect(() => {
    analytics.page('INS Subscription', pageName, { insLeadStep: segmentationPageNameToIndex[pageName] });
  }, [analytics, pageName]);
}
