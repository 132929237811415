import { useEffect, useState } from 'react';
import { useForm } from 'react-final-form';

export const useFieldHasBeenAlreadyEdited = (fieldName: string): boolean => {
  const { getFieldState } = useForm();
  const [hasBeenEdited, setHasBeenEdited] = useState(false);

  useEffect(() => {
    const fieldState = getFieldState(fieldName);
    setHasBeenEdited(!!fieldState?.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return hasBeenEdited;
};
