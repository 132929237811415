import { IllustratedIcon, Light } from '@ornikar/illustrated-icons';
import { HStack, Typography, View } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';

interface ExitDoorHighlightProps {
  backgroundColor: string;
  children: ReactNode;
}

export function ExitDoorHighlight({ backgroundColor, children }: ExitDoorHighlightProps): ReactNode {
  return (
    <View borderRadius="kitt.2" padding="kitt.4" backgroundColor={backgroundColor}>
      <HStack space="kitt.2">
        <IllustratedIcon icon={<Light />} size={48} />
        <Typography.Paragraph base="body-small">{children}</Typography.Paragraph>
      </HStack>
    </View>
  );
}
