import set from 'lodash/fp/set';
import type { NestedPaths, TypeFromPath } from '../types/utility';

export const deepCloneAndAssign = <O extends Record<string, any>, K extends NestedPaths<O>>(
  path: K,
  value: TypeFromPath<Required<O>, K> | undefined,
  initialObject: O,
): O => {
  return set(path, value, initialObject);
};
