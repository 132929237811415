import type { ReactNode } from 'react';
import { ClaimRecordWithAlcoholTemplate } from '../../../../components/ScreenTemplates/InformationTemplates/InsuranceRecordTemplates/ClaimRecordTemplates/ClaimRecordWithAlcoholTemplate';

export function PrimaryTerminationRecordLicenseCanceledOrSuspendedClaimWithAlcoholScreen(): ReactNode {
  return (
    <ClaimRecordWithAlcoholTemplate
      title="Étiez-vous sous l'emprise d'alcool ou de stupéfiants lors de cet accident&nbsp;?"
      fieldName="primaryDriver.terminationRecords[0].cancellationOrSuspensionRecord.claimRecord.underTheInfluenceOf"
    />
  );
}
