import { Typography } from '@ornikar/kitt';
import type { ReactChild, ReactNode } from 'react';

// TODO [>1]: make it an env var
const GOCARDLESS_PRIVACY_POLICY_URL = 'https://gocardless.com/fr-fr/legal/politique-de-confidentialite/';

interface GoCardlessPrivacyPolicyLinkProps {
  children: ReactChild;
}

export function GoCardlessPrivacyPolicyLink({ children }: GoCardlessPrivacyPolicyLinkProps): ReactNode {
  return (
    <Typography.Link noBold color="black-light" href={GOCARDLESS_PRIVACY_POLICY_URL} target="_blank">
      {children}
    </Typography.Link>
  );
}
