import { useRequiredValidator } from '@ornikar/react-validators';
import type { ReactNode } from 'react';
import { useField, useForm } from 'react-final-form';
import { RadioScreenTemplate } from '../../../components/ScreenTemplates/RadioScreenTemplate';
import type { ClaimRecord } from '../../../fsm/answers';
import {
  AccidentType,
  ClaimNature,
  DriverType,
  InsuranceRecordType,
  WithdrawalType,
  makeChoices,
} from '../../../fsm/answers';
import { useSubscriptionFsmState } from '../../../fsm/context';
import type { Driver } from '../../../fsm/types';
import type { DriverScreenProps } from '../../types/DriverScreenProps';

type CancellationRecordIsClaimScreenProps = DriverScreenProps;

const choices = makeChoices(AccidentType, [
  [AccidentType.None, 'Non'],
  [AccidentType.MaterialAccident, 'Oui, matériel'],
  [AccidentType.BodilyAccident, 'Oui, corporel'],
]);

export function CancellationRecordIsClaimScreen({ driver }: CancellationRecordIsClaimScreenProps): ReactNode {
  const requiredValidator = useRequiredValidator();
  const driverKey: `${Driver}Driver` = `${driver}Driver`;

  const {
    context: {
      editingInsuranceRecord,
      answers: { [driverKey]: driverAnswers },
    },
  } = useSubscriptionFsmState();

  const fieldName = `${driverKey}.cancellationOrSuspensionRecords[${editingInsuranceRecord.index}].accident`;

  const {
    input: { value },
  } = useField<AccidentType>(fieldName);
  const { submit, change } = useForm();

  const withdrawalType = driverAnswers?.cancellationOrSuspensionRecords?.[editingInsuranceRecord.index].withdrawalType;
  const title = `Cette ${
    withdrawalType === WithdrawalType.Cancellation ? 'annulation' : 'suspension'
  } est-elle liée à un accident\u00A0?`;

  return (
    <RadioScreenTemplate
      title={title}
      fieldName={fieldName}
      choices={choices}
      validate={requiredValidator}
      onSubmit={() => {
        if (value !== AccidentType.None) {
          const initializedClaimRecord: Partial<ClaimRecord> = {
            type: InsuranceRecordType.Claim,
            nature: ClaimNature[value],
            driver: DriverType.Myself,
          };

          change(`${driverKey}.cancellationOrSuspensionRecords[${editingInsuranceRecord.index}].claimRecord`, {
            ...initializedClaimRecord,
          });
        }

        submit();
      }}
    />
  );
}
