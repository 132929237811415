import { useRequiredValidator } from '@ornikar/react-validators';
import type { ReactNode } from 'react';
import { RadioScreenTemplate } from '../../../components/ScreenTemplates/RadioScreenTemplate';
import { isPropertyOwnerOptionsList } from '../../../constants/mappers/driving';

export function DrivingIsPropertyOwnerScreen(): ReactNode {
  const requiredValidator = useRequiredValidator();

  return (
    <RadioScreenTemplate
      fieldName="isPropertyOwner"
      title="Êtes-vous propriétaire de votre logement&nbsp;?"
      choices={isPropertyOwnerOptionsList}
      validate={requiredValidator}
    />
  );
}
