import { InputText } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { Field } from '../../../../components/Field';
import { useIbanTitulaireValidator } from '../../../../forms/validation/sections/insurance/iban';

export interface IbanHolderFieldProps {
  name: string;
  onChange?: () => void;
}

export function IbanHolderField({ name, onChange }: IbanHolderFieldProps): ReactNode {
  const ibanHolderValidator = useIbanTitulaireValidator();

  return (
    <Field
      component={InputText}
      name={name}
      label="Nom du titulaire"
      placeholder="John Smith"
      validate={ibanHolderValidator}
      onChange={onChange}
    />
  );
}
