import { Typography } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { GoCardlessPrivacyPolicyLink } from './GoCardlessPrivacyPolicyLink';

export function GoCardlessLegalInformation(): ReactNode {
  return (
    <Typography.Paragraph base="body-xsmall" color="black-light">
      Paiements gérés par GoCardless. Lire la{' '}
      <GoCardlessPrivacyPolicyLink>politique de confidentialité</GoCardlessPrivacyPolicyLink> de GoCardless.
    </Typography.Paragraph>
  );
}
