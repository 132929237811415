import type { DrivingFsmAnswers, InformationFsmAnswers, VehicleFsmAnswers } from '../../../fsm/answers';
import { useSubscriptionFsmDispatch } from '../../../fsm/context';
import { Event } from '../../../fsm/types';
import { useSendContinue } from '../../../hooks/useSendContinue';
import { formatPhone } from '../../../utils/formatPhone';
import type { DrivingFormValues, InformationFormValues, VehicleFormValues } from '../../answers';
import { validateHolderInformations } from '../../validation/common/holderInformations';
import type { Submissions } from '../types';

type InformationSubmissionFormValues = VehicleFormValues & DrivingFormValues & InformationFormValues;
type InformationSubmissionFsmAnswers = VehicleFsmAnswers & DrivingFsmAnswers & InformationFsmAnswers;

export const useInformationSubmissions: () => Submissions<
  InformationSubmissionFormValues,
  InformationSubmissionFsmAnswers
> = () => {
  const send = useSubscriptionFsmDispatch();
  const sendContinue = useSendContinue();

  return {
    INFORMATION_PRIMARY_HAS_BEEN_COVERED: ({ primaryDriver }) => {
      send({ type: Event.ANSWER, answers: { primaryDriver } });
    },
    INFORMATION_PRIMARY_BONUS_MALUS: ({ primaryDriver }) => {
      send({ type: Event.ANSWER, answers: { primaryDriver } });
    },
    INFORMATION_PRIMARY_BONUS50: ({ primaryDriver }) => {
      send({ type: Event.ANSWER, answers: { primaryDriver } });
    },
    INFORMATION_PRIMARY_INSURANCE_PERIODS: ({ primaryDriver }) => {
      send({ type: Event.ANSWER, answers: { primaryDriver } });
    },
    INFORMATION_PRIMARY_HAS_TERMINATION_RECORD: ({ primaryDriver }) => {
      send({ type: Event.ANSWER, answers: { primaryDriver } });
    },
    INFORMATION_PRIMARY_TERMINATION_RECORD_DATE: ({ primaryDriver }) => {
      send({ type: Event.ANSWER, answers: { primaryDriver } });
    },
    INFORMATION_PRIMARY_TERMINATION_RECORD_REASON: ({ primaryDriver }) => {
      send({ type: Event.ANSWER, answers: { primaryDriver } });
    },
    INFORMATION_PRIMARY_TERMINATION_RECORD_DURATION_WITHOUT_INSURANCE: ({ primaryDriver }) => {
      send({ type: Event.ANSWER, answers: { primaryDriver } });
    },
    INFORMATION_PRIMARY_TERMINATION_RECORD_TERMINATION_COUNT_FOR_NON_PAYMENT: ({ primaryDriver }) => {
      send({ type: Event.ANSWER, answers: { primaryDriver } });
    },
    INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_DATE: ({ primaryDriver }) => {
      send({ type: Event.ANSWER, answers: { primaryDriver } });
    },
    INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_SUSPENDED_DURATION: ({ primaryDriver }) => {
      send({ type: Event.ANSWER, answers: { primaryDriver } });
    },
    INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_IS_CLAIM: ({ primaryDriver }) => {
      send({ type: Event.ANSWER, answers: { primaryDriver } });
    },
    INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_REASON: ({ primaryDriver }) => {
      send({ type: Event.ANSWER, answers: { primaryDriver } });
    },
    INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_ALCOHOL_LEVEL: ({ primaryDriver }) => {
      send({ type: Event.ANSWER, answers: { primaryDriver } });
    },
    INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_HIT_AND_RUN: ({ primaryDriver }) => {
      send({ type: Event.ANSWER, answers: { primaryDriver } });
    },
    INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_DATE: ({ primaryDriver }) => {
      send({ type: Event.ANSWER, answers: { primaryDriver } });
    },
    INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_RESPONSIBILITY_LEVEL: ({
      primaryDriver,
    }) => {
      send({ type: Event.ANSWER, answers: { primaryDriver } });
    },
    INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_WITH_ALCOHOL: ({ primaryDriver }) => {
      send({ type: Event.ANSWER, answers: { primaryDriver } });
    },
    INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_ALCOHOL_LEVEL: ({ primaryDriver }) => {
      send({ type: Event.ANSWER, answers: { primaryDriver } });
    },
    INFORMATION_PRIMARY_CANCELLATION_RECORD: ({ primaryDriver }) => {
      send({ type: Event.ANSWER, answers: { primaryDriver } });
    },
    INFORMATION_PRIMARY_CANCELLATION_RECORD_DATE: ({ primaryDriver }) => {
      send({ type: Event.ANSWER, answers: { primaryDriver } });
    },
    INFORMATION_PRIMARY_CANCELLATION_RECORD_SUSPENDED_DURATION: ({ primaryDriver }) => {
      send({ type: Event.ANSWER, answers: { primaryDriver } });
    },
    INFORMATION_PRIMARY_CANCELLATION_RECORD_IS_CLAIM: ({ primaryDriver }) => {
      send({ type: Event.ANSWER, answers: { primaryDriver } });
    },
    INFORMATION_PRIMARY_CANCELLATION_RECORD_REASON: ({ primaryDriver }) => {
      send({ type: Event.ANSWER, answers: { primaryDriver } });
    },
    INFORMATION_PRIMARY_CANCELLATION_RECORD_ALCOHOL_LEVEL: ({ primaryDriver }) => {
      send({ type: Event.ANSWER, answers: { primaryDriver } });
    },
    INFORMATION_PRIMARY_CANCELLATION_RECORD_CLAIM_DATE: ({ primaryDriver }) => {
      send({ type: Event.ANSWER, answers: { primaryDriver } });
    },
    INFORMATION_PRIMARY_CANCELLATION_RECORD_CLAIM_RESPONSIBILITY_LEVEL: ({ primaryDriver }) => {
      send({ type: Event.ANSWER, answers: { primaryDriver } });
    },
    INFORMATION_PRIMARY_CANCELLATION_RECORD_CLAIM_WITH_ALCOHOL: ({ primaryDriver }) => {
      send({ type: Event.ANSWER, answers: { primaryDriver } });
    },
    INFORMATION_PRIMARY_CANCELLATION_RECORD_CLAIM_ALCOHOL_LEVEL: ({ primaryDriver }) => {
      send({ type: Event.ANSWER, answers: { primaryDriver } });
    },
    INFORMATION_PRIMARY_CANCELLATION_RECORD_HIT_AND_RUN: ({ primaryDriver }) => {
      send({ type: Event.ANSWER, answers: { primaryDriver } });
    },
    INFORMATION_PRIMARY_ADD_CANCELLATION_RECORD: ({ primaryDriver }) => {
      send({ type: Event.ANSWER, answers: { primaryDriver } });
    },
    INFORMATION_PRIMARY_CLAIM_RECORD_DATE: ({ primaryDriver }) => {
      send({ type: Event.ANSWER, answers: { primaryDriver } });
    },
    INFORMATION_PRIMARY_CLAIM_RECORD_DRIVER: ({ primaryDriver }) => {
      send({ type: Event.ANSWER, answers: { primaryDriver } });
    },
    INFORMATION_PRIMARY_CLAIM_RECORD_NATURE: ({ primaryDriver }) => {
      send({ type: Event.ANSWER, answers: { primaryDriver } });
    },
    INFORMATION_PRIMARY_CLAIM_RECORD_QUANTITY: ({ primaryDriver }) => {
      send({ type: Event.ANSWER, answers: { primaryDriver } });
    },
    INFORMATION_PRIMARY_CLAIM_RECORD_RESPONSIBILITY_LEVEL: ({ primaryDriver }) => {
      send({ type: Event.ANSWER, answers: { primaryDriver } });
    },
    INFORMATION_PRIMARY_CLAIM_RECORD_WITH_ALCOHOL: ({ primaryDriver }) => {
      send({ type: Event.ANSWER, answers: { primaryDriver } });
    },
    INFORMATION_PRIMARY_CLAIM_RECORD_ALCOHOL_LEVEL: ({ primaryDriver }) => {
      send({ type: Event.ANSWER, answers: { primaryDriver } });
    },
    INFORMATION_PRIMARY_ADD_CLAIM_RECORD: () => {
      // @devs: Handled in screen
    },
    INFORMATION_PRIMARY_INSURANCE_RECORD_SUMMARY: () => {
      sendContinue();
    },
    INFORMATION_DECLARE_SECONDARY_DRIVER: ({ declareSecondaryDriver }) => {
      send({ type: Event.ANSWER, answers: { declareSecondaryDriver } });
    },
    INFORMATION_SECONDARY_DETAILS: ({ secondaryDriver }) => {
      send({
        type: Event.ANSWER,
        answers: { secondaryDriver },
      });
    },
    INFORMATION_SECONDARY_LICENSE_DATE: ({ secondaryDriver }) => {
      send({ type: Event.ANSWER, answers: { secondaryDriver } });
    },
    INFORMATION_SECONDARY_LICENSE_TYPE: ({ secondaryDriver }) => {
      send({ type: Event.ANSWER, answers: { secondaryDriver } });
    },
    INFORMATION_SECONDARY_WAS_ACCOMPANIED: ({ secondaryDriver }) => {
      send({ type: Event.ANSWER, answers: { secondaryDriver } });
    },
    INFORMATION_SECONDARY_RELATION: ({ secondaryRelation }) => {
      send({ type: Event.ANSWER, answers: { secondaryRelation } });
    },
    INFORMATION_SECONDARY_HAS_BEEN_COVERED: ({ secondaryDriver }) => {
      send({ type: Event.ANSWER, answers: { secondaryDriver } });
    },
    INFORMATION_SECONDARY_BONUS_MALUS: ({ secondaryDriver }) => {
      send({ type: Event.ANSWER, answers: { secondaryDriver } });
    },
    INFORMATION_SECONDARY_BONUS50: ({ secondaryDriver }) => {
      send({ type: Event.ANSWER, answers: { secondaryDriver } });
    },
    INFORMATION_SECONDARY_INSURANCE_PERIODS: ({ secondaryDriver }) => {
      send({ type: Event.ANSWER, answers: { secondaryDriver } });
    },
    INFORMATION_SECONDARY_HAS_TERMINATION_RECORD: ({ secondaryDriver }) => {
      send({ type: Event.ANSWER, answers: { secondaryDriver } });
    },
    INFORMATION_SECONDARY_TERMINATION_RECORD_DATE: ({ secondaryDriver }) => {
      send({ type: Event.ANSWER, answers: { secondaryDriver } });
    },
    INFORMATION_SECONDARY_TERMINATION_RECORD_REASON: ({ secondaryDriver }) => {
      send({ type: Event.ANSWER, answers: { secondaryDriver } });
    },
    INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_DATE: ({ secondaryDriver }) => {
      send({ type: Event.ANSWER, answers: { secondaryDriver } });
    },
    INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_SUSPENDED_DURATION: ({ secondaryDriver }) => {
      send({ type: Event.ANSWER, answers: { secondaryDriver } });
    },
    INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_IS_CLAIM: ({ secondaryDriver }) => {
      send({ type: Event.ANSWER, answers: { secondaryDriver } });
    },
    INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_REASON: ({ secondaryDriver }) => {
      send({ type: Event.ANSWER, answers: { secondaryDriver } });
    },
    INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_ALCOHOL_LEVEL: ({ secondaryDriver }) => {
      send({ type: Event.ANSWER, answers: { secondaryDriver } });
    },
    INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_HIT_AND_RUN: ({ secondaryDriver }) => {
      send({ type: Event.ANSWER, answers: { secondaryDriver } });
    },
    INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_DATE: ({ secondaryDriver }) => {
      send({ type: Event.ANSWER, answers: { secondaryDriver } });
    },
    INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_RESPONSIBILITY_LEVEL: ({
      secondaryDriver,
    }) => {
      send({ type: Event.ANSWER, answers: { secondaryDriver } });
    },
    INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_WITH_ALCOHOL: ({
      secondaryDriver,
    }) => {
      send({ type: Event.ANSWER, answers: { secondaryDriver } });
    },
    INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_ALCOHOL_LEVEL: ({
      secondaryDriver,
    }) => {
      send({ type: Event.ANSWER, answers: { secondaryDriver } });
    },
    INFORMATION_SECONDARY_CANCELLATION_RECORD: ({ secondaryDriver }) => {
      send({ type: Event.ANSWER, answers: { secondaryDriver } });
    },
    INFORMATION_SECONDARY_CANCELLATION_RECORD_DATE: ({ secondaryDriver }) => {
      send({ type: Event.ANSWER, answers: { secondaryDriver } });
    },
    INFORMATION_SECONDARY_CANCELLATION_RECORD_SUSPENDED_DURATION: ({ secondaryDriver }) => {
      send({ type: Event.ANSWER, answers: { secondaryDriver } });
    },
    INFORMATION_SECONDARY_CANCELLATION_RECORD_IS_CLAIM: ({ secondaryDriver }) => {
      send({ type: Event.ANSWER, answers: { secondaryDriver } });
    },
    INFORMATION_SECONDARY_CANCELLATION_RECORD_REASON: ({ secondaryDriver }) => {
      send({ type: Event.ANSWER, answers: { secondaryDriver } });
    },
    INFORMATION_SECONDARY_CANCELLATION_RECORD_ALCOHOL_LEVEL: ({ secondaryDriver }) => {
      send({ type: Event.ANSWER, answers: { secondaryDriver } });
    },
    INFORMATION_SECONDARY_CANCELLATION_RECORD_CLAIM_DATE: ({ secondaryDriver }) => {
      send({ type: Event.ANSWER, answers: { secondaryDriver } });
    },
    INFORMATION_SECONDARY_CANCELLATION_RECORD_CLAIM_RESPONSIBILITY_LEVEL: ({ secondaryDriver }) => {
      send({ type: Event.ANSWER, answers: { secondaryDriver } });
    },
    INFORMATION_SECONDARY_CANCELLATION_RECORD_CLAIM_WITH_ALCOHOL: ({ secondaryDriver }) => {
      send({ type: Event.ANSWER, answers: { secondaryDriver } });
    },
    INFORMATION_SECONDARY_CANCELLATION_RECORD_CLAIM_ALCOHOL_LEVEL: ({ secondaryDriver }) => {
      send({ type: Event.ANSWER, answers: { secondaryDriver } });
    },
    INFORMATION_SECONDARY_CANCELLATION_RECORD_HIT_AND_RUN: ({ secondaryDriver }) => {
      send({ type: Event.ANSWER, answers: { secondaryDriver } });
    },
    INFORMATION_SECONDARY_ADD_CANCELLATION_RECORD: ({ secondaryDriver }) => {
      send({ type: Event.ANSWER, answers: { secondaryDriver } });
    },
    INFORMATION_SECONDARY_CLAIM_RECORD_DATE: ({ secondaryDriver }) => {
      send({ type: Event.ANSWER, answers: { secondaryDriver } });
    },
    INFORMATION_SECONDARY_CLAIM_RECORD_DRIVER: ({ secondaryDriver }) => {
      send({ type: Event.ANSWER, answers: { secondaryDriver } });
    },
    INFORMATION_SECONDARY_CLAIM_RECORD_NATURE: ({ secondaryDriver }) => {
      send({ type: Event.ANSWER, answers: { secondaryDriver } });
    },
    INFORMATION_SECONDARY_CLAIM_RECORD_QUANTITY: ({ secondaryDriver }) => {
      send({ type: Event.ANSWER, answers: { secondaryDriver } });
    },
    INFORMATION_SECONDARY_CLAIM_RECORD_RESPONSIBILITY_LEVEL: ({ secondaryDriver }) => {
      send({ type: Event.ANSWER, answers: { secondaryDriver } });
    },
    INFORMATION_SECONDARY_CLAIM_RECORD_WITH_ALCOHOL: ({ secondaryDriver }) => {
      send({ type: Event.ANSWER, answers: { secondaryDriver } });
    },
    INFORMATION_SECONDARY_CLAIM_RECORD_ALCOHOL_LEVEL: ({ secondaryDriver }) => {
      send({ type: Event.ANSWER, answers: { secondaryDriver } });
    },
    INFORMATION_SECONDARY_ADD_CLAIM_RECORD: () => {
      // @devs: Handled in screen
    },
    INFORMATION_SECONDARY_INSURANCE_RECORD_SUMMARY: () => {
      sendContinue();
    },
    INFORMATION_PRIMARY_DETAILS: async (values) => {
      const {
        hasAcceptedCommercialCommunication,
        hasAcceptedPartnersCommunication,
        primaryDriver,
        privacyPolicyAccepted,
        subscriberAutoCompletedAddress,
        subscriberEmail,
      } = values;

      const subscriberPhone = formatPhone(values.subscriberPhone!);

      const validationErrors = await validateHolderInformations(
        subscriberAutoCompletedAddress!,
        subscriberEmail!,
        subscriberPhone,
      );

      if (validationErrors) {
        return validationErrors;
      }

      const { formattedAddress, street, zipCode, city } = subscriberAutoCompletedAddress!;

      send({
        type: Event.ANSWER,
        answers: {
          primaryDriver,
          subscriberFirstName: primaryDriver?.firstName,
          subscriberLastName: primaryDriver?.lastName,
          subscriberPhone,
          subscriberEmail,
          subscriberCompleteAdress: formattedAddress,
          subscriberAdress1: street,
          subscriberCodePostal: zipCode,
          subscriberCommune: city,
          privacyPolicyAccepted,
          hasAcceptedCommercialCommunication,
          hasAcceptedPartnersCommunication,
        },
      });

      return undefined;
    },
  };
};
