import type { ReactNode } from 'react';
import { CancellationRecordHitAndRunTemplate } from '../../../../components/ScreenTemplates/InformationTemplates/InsuranceRecordTemplates/CancellationRecordTemplates/CancellationRecordHitAndRunTemplate';
import { useSubscriptionFsmState } from '../../../../fsm/context';

export function PrimaryCancellationRecordHitAndRunScreen(): ReactNode {
  const {
    context: { editingInsuranceRecord },
  } = useSubscriptionFsmState();

  return (
    <CancellationRecordHitAndRunTemplate
      title="Avez-vous commis un délit de fuite ou refusé d'obtempérer&nbsp;?"
      fieldName={`primaryDriver.cancellationOrSuspensionRecords[${editingInsuranceRecord.index}].isHitAndRun`}
    />
  );
}
