import { Typography } from '@ornikar/kitt-universal';
import * as Sentry from '@sentry/react';
import { format, max } from 'date-fns';
import { fr } from 'date-fns/locale';
import type { ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import type { GeocodingDto } from '../../../apis/getAddressSuggestions';
import { getInsurerNameSuggestions } from '../../../apis/getInsurerNameSuggestions';
import { sendHamonLawInformation } from '../../../apis/sendHamonLawInformation';
import type { TarificationResultOK } from '../../../apis/types/Quotation';
import { ScreenTemplateWithValidation } from '../../../components/ScreenTemplates/ScreenTemplateWithValidation';
import { useToast } from '../../../components/Toast/useToast';
import { HAMON_DELAY_DAYS } from '../../../constants/coverageDate';
import { HamonFormTypeEnum } from '../../../fsm/answers';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { Event } from '../../../fsm/types';
import { useAsync } from '../../../hooks/useAsync';
import { sendEvent } from '../../../utils/mixpanel';
import { AutoCompleteHamonForm } from './AutoCompleteHamonForm';
import { ManualHamonForm } from './ManualHamonForm';
import { ManualHamonFormLegacy } from './ManualHamonFormLegacy';

export function HamonLawInformationScreen(): ReactNode {
  const { openErrorToast } = useToast();
  const { context } = useSubscriptionFsmState();
  const { values } = useFormState();
  const [isLoading, setIsLoading] = useState(false);
  const [currentHamonName, setCurrentHamonName] = useState<string | undefined>();
  const { submit } = useForm();

  const state = useSubscriptionFsmState();

  const send = useSubscriptionFsmDispatch();

  const [hamonFormType, setHamonFormType] = useState<HamonFormTypeEnum>(
    state.context.answers.hamonFormType || HamonFormTypeEnum.AutoComplete,
  );

  const tarificationResult = state.context.tarificationResult as TarificationResultOK;

  const [insurersList, insurersError, insurersLoading] = useAsync(async () => {
    if (hamonFormType === HamonFormTypeEnum.AutoComplete) {
      return getInsurerNameSuggestions('');
    }
    return [];
  }, [hamonFormType]);

  useEffect(() => {
    send(Event.ANSWER_WITHOUT_CONTINUING, {
      answers: {
        hamonFormType,
      },
    });
  }, [hamonFormType, send]);

  useEffect(() => {
    if (insurersError) {
      setHamonFormType(HamonFormTypeEnum.Manual);
    }
  }, [insurersError]);

  const insurersChoices = useMemo(() => {
    return (
      insurersList
        ?.map((insurer) => ({ label: insurer.name, value: insurer.name }))
        .sort((a, b) => a.label.localeCompare(b.label)) || []
    );
  }, [insurersList]);

  const handleSubmit = async (): Promise<void> => {
    setIsLoading(true);

    const hamonAdressData: Record<string, string> = (() => {
      switch (hamonFormType) {
        case HamonFormTypeEnum.Legacy:
        case HamonFormTypeEnum.Manual:
          return values.hamonAutoCompletedAddress;
        case HamonFormTypeEnum.AutoComplete:
          return insurersList
            ?.map((data) => {
              return {
                street: data.address,
                zipCode: data.zipCode,
                city: data.city,
                name: data.name,
                cedex: data.cedex,
              };
            })
            .find((insurer) => insurer.name === values.hamonName);
        default:
          return undefined;
      }
    })();

    try {
      await sendHamonLawInformation(tarificationResult.quoteId, {
        hamonAdress: hamonAdressData?.street,
        hamonName: values.hamonName,
        hamonCodePostal: hamonAdressData?.zipCode,
        hamonCommune: hamonAdressData?.city,
        hamonContractRef: values.hamonContractRef,
        hamonCedex: hamonAdressData?.cedex,
      });

      submit();
    } catch (error) {
      Sentry.captureException(error);

      openErrorToast(error as Error);
    } finally {
      setIsLoading(false);
    }
    sendEvent('Step Validation', {
      type: 'Insurer Hamon Law',
      autocomplete: hamonFormType === HamonFormTypeEnum.AutoComplete,
      insurer_name: values.hamonName,
      insurer_address: `${hamonAdressData?.street}, ${hamonAdressData?.zipCode as string}, ${hamonAdressData?.city}`,
    });
  };

  const initialFormattedAddress = useRef<GeocodingDto>({
    street: context.answers.hamonAdress || '',
    zipCode: context.answers.hamonCodePostal || '',
    city: context.answers.hamonCommune || '',
    formattedAddress: context.answers.hamonCompleteAdress || '',
  });

  const fieldsToValidate = useMemo(() => {
    const defaultFields = ['hamonName', 'hamonAutoCompletedAddress', 'hamonContractRef', 'hamonWant'];
    switch (hamonFormType) {
      case HamonFormTypeEnum.AutoComplete:
        if (insurersError) {
          return defaultFields;
        }
        return defaultFields.filter((field) => field !== 'hamonAutoCompletedAddress');
      case HamonFormTypeEnum.Legacy:
      case HamonFormTypeEnum.Manual:
        return defaultFields;
      default:
        return [];
    }
  }, [hamonFormType, insurersError]);

  const handleBackToInsurersList = useCallback((): void => {
    setHamonFormType(HamonFormTypeEnum.AutoComplete);
    sendEvent('Form Field Rollback', {
      type: 'Insurer Hamon Law',
    });
  }, []);

  const handleAddInsurer = useCallback((): void => {
    send(Event.ANSWER_WITHOUT_CONTINUING, {
      answers: {
        hamonName: currentHamonName,
      },
    });
    setHamonFormType(HamonFormTypeEnum.Manual);
    sendEvent('Form Field Completed', {
      type: 'Insurer Hamon Law',
      autocomplete: false,
      insurer_name: currentHamonName,
    });
  }, [currentHamonName, send]);

  const hamonForm = useMemo(() => {
    const handleSelectHamonName = (value: string | number): void => {
      sendEvent('Form Field Completed', {
        type: 'Insurer Hamon Law',
        autocomplete: true,
        insurer_name: value,
      });
    };

    switch (hamonFormType) {
      case HamonFormTypeEnum.AutoComplete:
        return (
          <AutoCompleteHamonForm
            isLoading={insurersLoading}
            choices={insurersChoices}
            onAddInsurerPress={handleAddInsurer}
            onHamonNameChange={(value: string) => setCurrentHamonName(value)}
            onHamonNameSelected={handleSelectHamonName}
          />
        );
      case HamonFormTypeEnum.Legacy:
        return (
          <ManualHamonFormLegacy initialFormattedAddress={initialFormattedAddress} hamonWantValue={values.hamonWant} />
        );
      case HamonFormTypeEnum.Manual:
        return (
          <ManualHamonForm
            initialFormattedAddress={initialFormattedAddress}
            initialHamonName={currentHamonName}
            onBackToInsurersListPress={handleBackToInsurersList}
          />
        );
      default:
        return null;
    }
  }, [
    currentHamonName,
    hamonFormType,
    insurersChoices,
    insurersLoading,
    values.hamonWant,
    handleAddInsurer,
    handleBackToInsurersList,
  ]);

  const effectiveDate = max([
    new Date(context.answers.dateEffetSouhaite ?? 0),
    new Date(tarificationResult.effectiveDate),
  ]);

  return (
    <ScreenTemplateWithValidation
      hideSaveQuoteButton
      fieldNames={fieldsToValidate}
      title="Votre contrat d'assurance a plus d'un an&nbsp;! Nous nous occupons de sa résiliation."
      highlightTitle="Quand débute mon contrat avec Ornikar&nbsp;?"
      highlightContent={
        <>
          <Typography.Text>
            Il faut compter {HAMON_DELAY_DAYS} jours pour la procédure de résiliation auprès de votre assureur actuel.
          </Typography.Text>
          <Typography.Text>
            Votre aventure avec nous commencera donc le{' '}
            <Typography.Text variant="bold">{format(effectiveDate, 'd MMMM yyyy', { locale: fr })}</Typography.Text>.
          </Typography.Text>
          <Typography.Text variant="bold">
            Soyez tranquille, vous êtes couvert par votre assurance actuelle jusqu&apos;à l&apos;activation de votre
            nouveau contrat.
          </Typography.Text>
        </>
      }
      highlightExpandable={false}
      submitButton={{ loading: isLoading }}
      backButtonLabel="Étape précédente"
      onSubmit={handleSubmit}
    >
      <Typography.Text>
        En effet, selon la loi Consommation 2014, dite loi Hamon, c’est votre nouvel assureur qui procède à la
        résiliation du contrat.
      </Typography.Text>
      {hamonForm}
    </ScreenTemplateWithValidation>
  );
}
