import { api } from './api';
import { vehicleLegalInformationToServerRequest } from './mappers/vehicleLegalInformationMapper';
import type { VehicleLegalInformation } from './types/VehicleLegalInformation';

export const sendVehicleLegalInformation = async (
  vehicleLegalInformation: VehicleLegalInformation,
  quoteId: string | number,
): Promise<void> => {
  const requestBody = vehicleLegalInformationToServerRequest(vehicleLegalInformation);

  await api(`api/v1/quotes/${quoteId}/vehicle-info`, {
    method: 'PATCH',
    json: requestBody,
  });
};
