import { api } from './api';
import { mapPaymentPeriodicityToServerPaymentPeriodicity } from './mappers/paymentOptionsMapper';
import type { ServerEligibilitiesResult } from './types/Eligibilities';
import type { PackageCode } from './types/Formula';
import type { PaymentPeriodicity } from './types/PaymentOptions';
import type { TarificationResultOK } from './types/Quotation';

export interface SaveQuoteResultOK {
  ok: true;
  hamonPossible: boolean;
  deviceNeeded: boolean;
  validationNeeded: boolean;
  validationNeededMessage?: string;
  fullOnlinePossible: boolean;
}

export interface SaveQuoteResultKO {
  ok: false;
  error: string;
}

export type SaveQuoteResult = SaveQuoteResultOK | SaveQuoteResultKO;

export async function saveQuote(
  tarificationResult: TarificationResultOK,
  formuleRef: PackageCode,
  paymentPeriodicity: PaymentPeriodicity,
): Promise<SaveQuoteResult> {
  const { quoteId } = tarificationResult;

  const requestBody = {
    packageReference: formuleRef,
    paymentPeriodicity: mapPaymentPeriodicityToServerPaymentPeriodicity(paymentPeriodicity),
  };

  await api(`api/v1/quotes/${quoteId}/packages`, {
    method: 'POST',
    json: requestBody,
  });

  const eligibilities = await api<ServerEligibilitiesResult>(`api/v1/quotes/${quoteId}/eligibilities`);

  return {
    ok: true,
    hamonPossible: eligibilities.isEligibleToHamonLaw,
    deviceNeeded: false,
    validationNeeded: false,
    validationNeededMessage: undefined,
    fullOnlinePossible: true,
  };
}
