import { Typography, View } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { MrhRedirection } from '../../../components/MrhRedirection';
import { IllustratedTemplate } from '../../../components/ScreenTemplates/IllustratedTemplate';
import { MRH_REDIRECTION_FEATURE, NO_OFFER_SCREEN_MRH_REDIRECTION_FEATURE } from '../../../constants/flagshipKeys';
import { useSubscriptionFsmState } from '../../../fsm/context';
import { useFsFlag } from '../../../setup/flagship';
import DesktopIllustration from './assets/illustration-desktop.svg';
import MobileIllustration from './assets/illustration-mobile.svg';
import styles from './styles.module.css';

const alt = "Conductrice au volant d'une voiture";

export function CannotQuoteScreen(): ReactNode {
  const {
    context: {
      answers: { primaryDriver },
    },
  } = useSubscriptionFsmState();

  const mrhRedirectionFeatureCannotQuoteScreen = useFsFlag(NO_OFFER_SCREEN_MRH_REDIRECTION_FEATURE, false, {
    shouldSendVariationToMixpanel: true,
  });

  const mrhRedirectionFeature = useFsFlag(MRH_REDIRECTION_FEATURE, false, {
    shouldSendVariationToMixpanel: true,
  });

  return (
    <IllustratedTemplate
      mobileIllustration={<img src={MobileIllustration} alt={alt} />}
      desktopIllustration={<img src={DesktopIllustration} alt={alt} />}
      title={
        primaryDriver?.firstName
          ? `${primaryDriver.firstName}, nous n’avons hélas pas d’offre adaptée.`
          : 'Nous n’avons hélas pas d’offre adaptée.'
      }
    >
      <div className={styles.Description}>
        <Typography.Text variant="bold">Merci pour vos réponses et votre confiance.</Typography.Text>
        <Typography.Text>
          {' Nous sommes sincèrement désolés et espérons que vous trouverez une solution.'}
        </Typography.Text>
      </div>

      {mrhRedirectionFeature.getValue() && mrhRedirectionFeatureCannotQuoteScreen.getValue() ? (
        <View marginY={{ base: 'kitt.6', small: 'kitt.10' }}>
          <MrhRedirection slug="CANNOT_QUOTE" />
        </View>
      ) : null}
    </IllustratedTemplate>
  );
}
