import type { ReactNode } from 'react';
import { FullWidthLayout } from './FullWidthLayout';

export interface SubscriptionFlowLayoutProps {
  children: NonNullable<ReactNode>;
  headerVisible: boolean;
  onOpenHelperModal?: () => void;
  saveQuoteButtonVisible?: boolean;
  helperButtonVisible?: boolean;
  backgroundColor?: 'white' | 'seashell';
  shouldHaveLateOceanHeader?: boolean;
  shouldRenderFooter?: boolean;
}

export function SubscriptionFlowLayout({
  children,
  headerVisible = true,
  onOpenHelperModal,
  saveQuoteButtonVisible = true,
  helperButtonVisible = true,
  backgroundColor = 'white',
  shouldHaveLateOceanHeader = false,
  shouldRenderFooter = true,
}: SubscriptionFlowLayoutProps): ReactNode {
  return (
    <FullWidthLayout
      headerVisible={headerVisible}
      saveQuoteButtonVisible={saveQuoteButtonVisible}
      helperButtonVisible={helperButtonVisible}
      backgroundColor={backgroundColor}
      shouldHaveLateOceanHeader={shouldHaveLateOceanHeader}
      shouldRenderFooter={shouldRenderFooter}
      onOpenHelperModal={onOpenHelperModal}
    >
      {children}
    </FullWidthLayout>
  );
}
