import { Input } from '@ornikar/kitt';
import { Typography, TypographyLink, View } from '@ornikar/kitt-universal';
import * as Sentry from '@sentry/react';
import { useCallback, useState } from 'react';
import type { ReactNode } from 'react';
import { useField, useForm } from 'react-final-form';
import { getVehicleByLicensePlate } from '../../../apis/vehicules';
import { Field } from '../../../components/Field';
import { ScreenTemplate } from '../../../components/ScreenTemplates/ScreenTemplate';
import type { VehicleFormValues } from '../../../forms/answers';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { Event } from '../../../fsm/types';
import { sendEvent } from '../../../utils/mixpanel';
import EUIcon from './EUIcon.svg';
import styles from './styles.module.css';

export function VehicleLicensePlateScreen(): ReactNode {
  const [isLoading, setIsLoading] = useState(false);
  const { change, submit } = useForm();
  const {
    input: { value: vehiculeImmatriculation },
  } = useField<string>('vehiculeImmatriculation');
  const [error, setError] = useState<string | undefined>();
  const { context } = useSubscriptionFsmState();

  const send = useSubscriptionFsmDispatch();

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);

    try {
      const isSamePlate = vehiculeImmatriculation === context.answers.vehiculeImmatriculation;

      const vehicleData = isSamePlate
        ? { vehiculeVersionOptions: context.availableVersions }
        : await getVehicleByLicensePlate(vehiculeImmatriculation);

      Object.entries(vehicleData).forEach(([vehicleFieldKey, vehicleFieldValue]) =>
        // set vehicle info (vehicleBrand, vehicleModel...)
        change(vehicleFieldKey as keyof VehicleFormValues, vehicleFieldValue),
      );

      submit();
    } catch (error_) {
      Sentry.captureException(error_);
      setError('Votre numéro de plaque ne semble pas fonctionner ? Essayez la recherche par marque');
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehiculeImmatriculation]);

  const isFieldEmpty = vehiculeImmatriculation.length === 0;

  return (
    <ScreenTemplate
      title="Quelle est votre plaque d'immatriculation&nbsp;?"
      submitButton={{
        label: 'Confirmer',
        disabled: Boolean(error) || isFieldEmpty,
        loading: isLoading,
      }}
      onSubmit={handleSubmit}
    >
      <div className={styles.LicensePlateInput}>
        <div className={styles.LicensePlateFieldPrefix}>
          <img className={styles.LicensePlateRing} src={EUIcon} alt="european union" />
          <span>F</span>
        </div>
        <Field
          autoFocus
          className={styles.LicensePlateInputComponent}
          style={{ paddingLeft: 34 }}
          component={Input}
          name="vehiculeImmatriculation"
          placeholder="AA123AA"
          disabled={isLoading}
          format={(value: string) => value?.toUpperCase() || ''}
          onFocus={() => setError(undefined)}
          onBlur={() => {
            if (isFieldEmpty) {
              setError('Veuillez saisir une plaque valide.');
            }
          }}
        />
      </div>
      {Boolean(error) && (
        <View marginLeft="kitt.3">
          <Typography.Text color="danger" base="body-small">
            {error}
          </Typography.Text>
        </View>
      )}

      <View marginTop="kitt.2">
        <Typography.Text color="black-light">
          Gagnez du temps en cherchant par plaque ! Vous ne la connaissez pas ? Cherchez{' '}
          <TypographyLink
            variant="bold"
            color="primary"
            onPress={() => {
              sendEvent('Go To Screen', {
                from_state: 'VEHICULE_LICENSE_PLATE',
                to_state: 'VEHICULE_BRAND',
              });
              send({ type: Event.SKIP });
            }}
          >
            par&nbsp;marque
          </TypographyLink>
          .
        </Typography.Text>
      </View>
    </ScreenTemplate>
  );
}
