import type { TransitionConfig } from 'xstate';
import { setReachedSummaryScreenSectionBuilder } from '../actions/general';
import type { Context, ReturnEvent, Section } from '../types';
import { screensBySection } from '../types';

export function generateTargets(section: Section): TransitionConfig<Context, ReturnEvent>[] {
  return screensBySection[section].map((state) => {
    return {
      target: state,
      cond: (_: Context, event: ReturnEvent) => event.destination === state,
      actions: setReachedSummaryScreenSectionBuilder([section], false),
    };
  });
}
