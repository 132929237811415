import { useRequiredValidator } from '@ornikar/react-validators';
import type { ReactNode } from 'react';
import { terminationRecordCountOptionsList } from '../../../../../../constants/mappers/information';
import type { NonPaymentTerminationInsurerCount } from '../../../../../../fsm/answers';
import type { RadioScreenTemplateProps } from '../../../../RadioScreenTemplate';
import { RadioScreenTemplate } from '../../../../RadioScreenTemplate';

interface TerminationRecordNonPaymentCountTemplateProps
  extends Pick<RadioScreenTemplateProps<NonPaymentTerminationInsurerCount>, 'fieldName' | 'title'> {}

export function TerminationRecordNonPaymentCountTemplate(
  props: TerminationRecordNonPaymentCountTemplateProps,
): ReactNode {
  const requiredValidator = useRequiredValidator();

  return <RadioScreenTemplate {...props} choices={terminationRecordCountOptionsList} validate={requiredValidator} />;
}
