import { XRegularIcon } from '@ornikar/kitt-icons/phosphor';
import { Button, IconButton, InputText, Message, NavigationModal, Typography, VStack } from '@ornikar/kitt-universal';
import * as Sentry from '@sentry/react';
import type { ReactNode } from 'react';
import { useCallback, useState } from 'react';
import { useFormState } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { getVehicleByLicensePlate, listVersions } from '../../../../../apis/vehicules';
import { Field } from '../../../../../components/Field';
import { useLicensePlateValidator } from '../../../../../forms/validation/sections/vehicle/licensePlate';
import type { VehicleFsmAnswers } from '../../../../../fsm/answers';
import { useSubscriptionFsmState } from '../../../../../fsm/context';

interface Props {
  onClose: () => void;
  onChangeStep: (newStep: number) => void;
  setCurrentVehicleData: (vehiculeData: Partial<VehicleFsmAnswers>) => void;
}

export function ImmatriculationStep({ onClose, onChangeStep, setCurrentVehicleData }: Props): ReactNode {
  const licensePlateValidator = useLicensePlateValidator();
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { context } = useSubscriptionFsmState();
  const { values } = useFormState();

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);

    try {
      const vehicleData = await getVehicleByLicensePlate(values.vehiculeImmatriculation as string);
      const vehiculeVersionOptions = await listVersions(
        vehicleData.vehiculeMarque,
        vehicleData.vehiculeModele,
        vehicleData.vehiculePuissanceFiscale,
        vehicleData.vehiculeCarburant,
      );

      setCurrentVehicleData({ ...vehicleData, vehiculeVersionOptions });

      onChangeStep(1);
    } catch (error_) {
      Sentry.captureException(error_);
      setError('Votre numéro de plaque ne semble pas fonctionner.');
    } finally {
      setIsLoading(false);
    }
  }, [values.vehiculeImmatriculation, setCurrentVehicleData, onChangeStep]);

  return (
    <NavigationModal
      header={
        <NavigationModal.Header right={<IconButton icon={<XRegularIcon />} onPress={onClose} />}>
          <Typography.Paragraph variant="bold" textAlign="center">
            Mon véhicule
          </Typography.Paragraph>
        </NavigationModal.Header>
      }
      body={
        <NavigationModal.Body>
          <Typography.Header4 base="header4" variant="bold" textAlign="center">
            <FormattedMessage
              id="vehicule.immatriculation.verification.title"
              defaultMessage="Votre plaque d’immatriculation est-elle correcte ?"
            />
          </Typography.Header4>
          <VStack marginTop={24}>
            <Field
              component={InputText}
              name="vehiculeImmatriculation"
              label="Plaque d’immatriculation"
              defaultValue={context.answers.vehiculeImmatriculation}
              placeholder="AA123AA"
              format={(value: string) => value?.trim().toUpperCase() || ''}
              validate={licensePlateValidator}
            />
            {Boolean(error) && (
              <Typography.Paragraph color="danger" base="body-small">
                {error}
              </Typography.Paragraph>
            )}
          </VStack>
          <VStack marginTop={16}>
            <Message type="warning">
              <FormattedMessage
                id="vehicule.immatriculation.verification.warning"
                defaultMessage="Après vérification, nous constatons un décalage entre cette plaque d’immatriculation et votre véhicule renseigné au moment du devis. En validant cette plaque, le tarif proposé sera recalculé."
              />
            </Message>
          </VStack>
        </NavigationModal.Body>
      }
      footer={
        <NavigationModal.Footer>
          <VStack justifyContent="center" position="relative">
            <Button
              stretch
              type="primary"
              disabled={!values.vehiculeImmatriculation || isLoading}
              onPress={handleSubmit}
            >
              Valider cette immatriculation
            </Button>
          </VStack>
        </NavigationModal.Footer>
      }
    />
  );
}
