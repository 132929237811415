import { v4 as uuidv4 } from 'uuid';
import type { StateMachineInitContext } from '../types';

export const getUrlSearchParamsService = async (
  context: StateMachineInitContext,
): Promise<Partial<StateMachineInitContext>> => {
  const searchParams = new URLSearchParams(window.location.search);

  const leadId = searchParams.get('lead_id') || localStorage.getItem('leadId') || uuidv4();
  const quoteId = searchParams.get('quote_id');
  const [submissionId, submissionNumber] = searchParams.get('devis')?.split('~') || [undefined, undefined];
  const agentEmail = searchParams.get('agent') || undefined;

  localStorage.setItem('leadId', leadId);

  return {
    ...context,
    leadId,
    quoteId,
    submissionId,
    submissionNumber,
    agentEmail,
  };
};
