export const capitalize = (string: string): string => {
  if (string.length === 0) {
    return string;
  }

  if (string.length === 1) {
    return string.toUpperCase();
  }

  const [firstLetter, ...rest] = [...string];

  return `${firstLetter.toUpperCase()}${rest.join('')}`;
};
