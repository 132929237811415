import { assign } from 'xstate';
import { AssureDepuisType, DejaAssureAvecNomType, HamonWantType } from '../../apis/types/LongQuoteAnswers';
import type { AnswerAssign } from './types';

export const hamonCheck: AnswerAssign = assign((context, event) => {
  if (
    event.answers.vehiculeDejaAssureAvecNom === DejaAssureAvecNomType.Non ||
    event.answers.vehiculeDejaAssureAvecNom === DejaAssureAvecNomType.OuiPasAMonNom ||
    event.answers.vehiculeAssureDepuis === AssureDepuisType.Moins12Mois ||
    context.answers.vehiculeDejaAssureAvecNom === DejaAssureAvecNomType.Non ||
    context.answers.vehiculeDejaAssureAvecNom === DejaAssureAvecNomType.OuiPasAMonNom ||
    context.answers.vehiculeAssureDepuis === AssureDepuisType.Moins12Mois
  ) {
    return {
      answers: {
        ...context.answers,
        hamonWant: HamonWantType.No,
        hamonContractRef: undefined,
        hamonName: undefined,
        hamonAdress: undefined,
        hamonCodePostal: undefined,
        hamonCommune: undefined,
      },
    };
  }

  return {};
});
