import type { BrandLogoProps } from '@ornikar/react-brand-logo';
import { BrandLogo, OrnikarSvg } from '@ornikar/react-brand-logo';
import type { ReactNode } from 'react';
import type { Except } from 'type-fest';

interface LogoProps extends Except<BrandLogoProps, 'svg'> {}

export function Logo({ variant = 'primary', ...rest }: LogoProps): ReactNode {
  return <BrandLogo svg={OrnikarSvg} variant={variant} {...rest} />;
}
