import { startOfToday, subYears } from 'date-fns';
import { type ReactNode, useMemo } from 'react';
import { useField } from 'react-final-form';
import { RadioScreenTemplate } from '../../../components/ScreenTemplates/RadioScreenTemplate';
import { useSubscriptionFsmDispatch } from '../../../fsm/context';
import { Event } from '../../../fsm/types';
import { formatDate } from '../../../utils/date';

const field = 'vehiculeMiseEnCirculation';

export function VehicleDefaultCirculationDateScreen(): ReactNode {
  const {
    input: { value: vehiculeMiseEnCirculation },
  } = useField(field);

  const send = useSubscriptionFsmDispatch();

  const choices = useMemo(() => {
    const options = [
      {
        label: 'Plus de 10 ans',
        yearsToSub: 15,
      },
      {
        label: 'Entre 5 ans et 10 ans',
        yearsToSub: 10,
      },
      {
        label: 'Entre 1 an et 5 ans',
        yearsToSub: 5,
      },
      {
        label: 'Moins d’1 an',
        yearsToSub: 1,
      },
    ];

    return options.map(({ label, yearsToSub }) => ({
      label,
      value: formatDate(subYears(startOfToday(), yearsToSub)),
    }));
  }, []);

  const handleSubmit = (): void => {
    send(Event.DEFAULT_ANSWER, {
      answers: {
        vehiculeMiseEnCirculation,
      },
    });
  };

  return (
    <RadioScreenTemplate fieldName={field} title="Votre véhicule a..." choices={choices} onSubmit={handleSubmit} />
  );
}
