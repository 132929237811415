import { executeEducationRecaptchaAction as executeRecaptchaAction } from '@ornikar/auth';
import * as Sentry from '@sentry/react';
import { HTTPError } from 'ky';
import type { LongQuoteAnswers } from '../fsm/answers';
import { sendEvent } from '../utils/mixpanel';
import { api } from './api';
import { mapLongQuoteAnswersToServerRequest } from './mappers/longQuoteAnswersMapper';
import type { ServerTarificationResult, TarificationResult } from './types/Quotation';

function validateCompleteLongQuote(answers: Partial<LongQuoteAnswers>): LongQuoteAnswers {
  if (!answers.dateEffetSouhaite) {
    Sentry.captureException(new Error('empty dateEffetSouhaite on longquote'), {
      extra: answers,
    });
  }

  if (!answers.subscriberCommune || !answers.subscriberCodePostal || !answers.subscriberAdress1) {
    Sentry.captureException(new Error('incomplete adresse on longquote'), {
      extra: answers,
    });
  }

  return answers as LongQuoteAnswers;
}

const ANORMAL_TARIFICATION_LOADING_TIME_IN_SECONDS = [15, 20, 30];

const sendMixPanelEventForAnormalTarificationLoadingTime = (duration: number): void =>
  sendEvent('Anormal Tarification Loading Time', {
    tarification_loading_time: duration,
  });

function statusToValidationError(status: number): boolean | undefined {
  return status ? status >= 400 && status < 500 : undefined;
}

export async function getQuote(
  partialAnswers: Partial<LongQuoteAnswers>,
  segmentAnonymousId?: string,
  agentEmail?: string,
): Promise<TarificationResult> {
  let recaptchaToken: string | undefined;

  // block the quote request if recaptcha validation throw an error in production
  if (import.meta.env.MODE === 'production') {
    recaptchaToken = await executeRecaptchaAction('insurance-quote');
  }

  let hasReceivedResponse = false;

  const answers = validateCompleteLongQuote(partialAnswers);
  const requestBody = mapLongQuoteAnswersToServerRequest(answers);

  ANORMAL_TARIFICATION_LOADING_TIME_IN_SECONDS.forEach((value) => {
    setTimeout(() => {
      if (!hasReceivedResponse) {
        sendMixPanelEventForAnormalTarificationLoadingTime(value);
      }
    }, value * 1000);
  });

  try {
    const res = await api<ServerTarificationResult>('api/v1/quotes', {
      method: 'POST',
      json: { ...requestBody, segmentAnonymousId, agentEmail },
      headers: recaptchaToken
        ? {
            'Recaptcha-Token': recaptchaToken,
          }
        : undefined,
      timeout: 40000,
    });

    if (res.error === undefined) {
      return {
        isOk: true,
        packages: res.packages.map((formule) => ({ ...formule, code: formule.reference })),
        quoteId: res.quoteId,
        submissionId: res.submissionId,
        submissionNumber: res.submissionNumber,
        hasUnderwritingIssues: res.hasUnderwritingIssues,
        effectiveDate: res.effectiveDate,
        hasDiscount: res.hasDiscount,
        segmentUserId: res.segmentUserId,
      };
    }

    const statusCode = typeof res.error === 'string' ? res.statusCode : parseInt(res.error.code, 10);

    return {
      isOk: false,
      error: typeof res.error === 'string' ? res.error : res.error?.message,
      quoteId: res.quoteId,
      isValidationError: statusToValidationError(statusCode),
    };
  } catch (error) {
    if (error instanceof HTTPError) {
      const errorCode = error.response.status;
      const errorBody = await error.response.json();

      return {
        isOk: false,
        error: error.message,
        quoteId: errorBody?.quoteId,
        isValidationError: statusToValidationError(errorCode as number),
      };
    }

    throw error;
  } finally {
    hasReceivedResponse = true;
  }
}
