import { Civility, LicenseType } from '../../apis/types/LongQuoteAnswers';
import {
  BloodAlcoholLevel,
  CancellationOrSuspensionReason,
  ClaimNature,
  DriverType,
  NonPaymentTerminationInsurerCount,
  ResponsibilityLevel,
  SecondaryRelationType,
  SuspensionDuration,
  TerminationReason,
  UninsuredVehicleOwnershipDuration,
  YesNoQuestion,
} from '../../fsm/answers';
import { mapOptionsListToOptionsDict } from './utils';

export const yearsSinceHighestBonusOptionsList = [
  { label: "Moins d'un an", value: 0 },
  { label: '1 an', value: 1 },
  { label: '2 ans', value: 2 },
  { label: '3 ans', value: 3 },
  { label: '4 ans', value: 4 },
  { label: '5 ans', value: 5 },
  { label: '6 ans', value: 6 },
  { label: '7 ans', value: 7 },
  { label: '8 ans', value: 8 },
  { label: '9 ans', value: 9 },
  { label: '10 ans', value: 10 },
  { label: '11 ans', value: 11 },
  { label: '12 ans', value: 12 },
  { label: 'Plus de 12 ans', value: 13 },
];

export const yearsSinceHighestBonusOptionsDict = mapOptionsListToOptionsDict<number>(yearsSinceHighestBonusOptionsList);

export const hasTerminationRecordOptionsList = [
  {
    label: 'Non',
    value: YesNoQuestion.NO,
  },
  {
    label: 'Oui',
    value: YesNoQuestion.YES,
  },
];

export const hasTerminationRecordOptionsDict = mapOptionsListToOptionsDict<YesNoQuestion>(
  hasTerminationRecordOptionsList,
);

export const hasHitAndRunOptionsList = [
  {
    label: 'Non',
    value: YesNoQuestion.NO,
  },
  {
    label: 'Oui',
    value: YesNoQuestion.YES,
  },
];

export const hasHitAndRunOptionsDict = mapOptionsListToOptionsDict<YesNoQuestion>(hasHitAndRunOptionsList);

export const terminationRecordReasonOptionsList = [
  { label: "Non-paiement d'assurance", value: TerminationReason.NonPayment },
  { label: 'Suspension de permis', value: TerminationReason.DriversLicenseSuspension },
  { label: 'Annulation de permis', value: TerminationReason.DriversLicenseCancellation },
  { label: 'Un ou plusieurs sinistres', value: TerminationReason.Claims },
  { label: 'Dossier incomplet', value: TerminationReason.IncompleteSupportingDocumentation },
  { label: 'Fausse déclaration', value: TerminationReason.FalseDeclaration },
  { label: 'Aucune de ces raisons', value: TerminationReason.Other },
];

export const terminationRecordReasonOptionsDict = mapOptionsListToOptionsDict<TerminationReason>(
  terminationRecordReasonOptionsList,
);

export const terminationRecordDurationOptionsList = [
  {
    label: "Moins d'1 mois",
    value: UninsuredVehicleOwnershipDuration.LessThanOneMonth,
  },
  {
    label: '1 mois',
    value: UninsuredVehicleOwnershipDuration.OneMonth,
  },
  {
    label: '2 mois',
    value: UninsuredVehicleOwnershipDuration.TwoMonths,
  },
  {
    label: '3 mois',
    value: UninsuredVehicleOwnershipDuration.ThreeMonths,
  },
  {
    label: '4 mois',
    value: UninsuredVehicleOwnershipDuration.FourMonths,
  },
  {
    label: '5 mois',
    value: UninsuredVehicleOwnershipDuration.FiveMonths,
  },
  {
    label: '6 mois ou plus',
    value: UninsuredVehicleOwnershipDuration.SixMonthsOrMore,
  },
];

export const terminationRecordDurationOptionsDict = mapOptionsListToOptionsDict<UninsuredVehicleOwnershipDuration>(
  terminationRecordDurationOptionsList,
);

export const terminationRecordCountOptionsList = [
  { label: '1', value: NonPaymentTerminationInsurerCount.One },
  { label: '2', value: NonPaymentTerminationInsurerCount.Two },
  { label: '3 ou plus', value: NonPaymentTerminationInsurerCount.ThreeAndMore },
];

export const terminationRecordCountOptionsDict = mapOptionsListToOptionsDict<NonPaymentTerminationInsurerCount>(
  terminationRecordCountOptionsList,
);

export const suspensionDurationOptionsList = [
  {
    label: "Moins d'1 mois",
    value: SuspensionDuration.LessThanOneMonth,
  },
  {
    label: '1 mois',
    value: SuspensionDuration.OneMonth,
  },
  {
    label: '2 mois',
    value: SuspensionDuration.TwoMonths,
  },
  {
    label: '3 mois',
    value: SuspensionDuration.ThreeMonths,
  },
  {
    label: '4 mois',
    value: SuspensionDuration.FourMonths,
  },
  {
    label: '5 mois',
    value: SuspensionDuration.FiveMonths,
  },
  {
    label: '6 mois',
    value: SuspensionDuration.SixMonths,
  },
  {
    label: '7 mois',
    value: SuspensionDuration.SevenMonths,
  },
  {
    label: '8 mois',
    value: SuspensionDuration.EightMonths,
  },
  {
    label: '9 mois',
    value: SuspensionDuration.NineMonths,
  },
  {
    label: '10 mois',
    value: SuspensionDuration.TenMonths,
  },
  {
    label: '11 mois',
    value: SuspensionDuration.ElevenMonths,
  },
  {
    label: '12 mois',
    value: SuspensionDuration.TwelveMonths,
  },
  {
    label: '13 mois',
    value: SuspensionDuration.ThirteenMonths,
  },
  {
    label: '14 mois',
    value: SuspensionDuration.FourteenMonths,
  },
  {
    label: '15 mois',
    value: SuspensionDuration.FifteenMonths,
  },
  {
    label: '16 mois',
    value: SuspensionDuration.SixteenMonths,
  },
  {
    label: '17 mois',
    value: SuspensionDuration.SeventeenMonths,
  },
  {
    label: '18 mois ou plus',
    value: SuspensionDuration.EighteenMonthsOrMore,
  },
];

export const suspensionDurationOptionsDict =
  mapOptionsListToOptionsDict<SuspensionDuration>(suspensionDurationOptionsList);

export const cancellationOrSuspensionReasonOptionsList = [
  { label: 'Perte des points du permis', value: CancellationOrSuspensionReason.LackOfPoints },
  { label: 'Excès de vitesse', value: CancellationOrSuspensionReason.Speeding },
  { label: "Contrôle positif à l'alcool", value: CancellationOrSuspensionReason.DrivingUnderInfluenceOfAlcohol },
  { label: 'Contrôle positif aux stupéfiants', value: CancellationOrSuspensionReason.DrivingUnderInfluenceOfDrugs },
  {
    label: "Contrôle positif à l'alcool et aux stupéfiants",
    value: CancellationOrSuspensionReason.DrivingUnderInfluenceOfAlcoholAndDrugs,
  },
  { label: 'Aucune de ces raisons', value: CancellationOrSuspensionReason.Other },
];

export const cancellationOrSuspensionReasonOptionsDict = mapOptionsListToOptionsDict<CancellationOrSuspensionReason>(
  cancellationOrSuspensionReasonOptionsList,
);

export const claimRecordResponsibilityLevelOptionsList = [
  { label: 'Responsable', value: ResponsibilityLevel.Responsible },
  { label: 'Partiellement responsable', value: ResponsibilityLevel.PartiallyResponsible },
  { label: 'Non responsable', value: ResponsibilityLevel.NonResponsible },
];

export const claimRecordResponsibilityLevelOptionsDict = mapOptionsListToOptionsDict<ResponsibilityLevel>(
  claimRecordResponsibilityLevelOptionsList,
);

export const bloodAlcoholLevelOptionsList = [
  { label: '0,20 à 0,79 g/litre de sang', value: BloodAlcoholLevel.From200To799mgPerLiter },
  { label: '0,80 à 1,99 g/litre de sang', value: BloodAlcoholLevel.From800To1999mgPerLiter },
  { label: '2,00 g/litre de sang ou plus', value: BloodAlcoholLevel.From2000mgPerLiter },
];

export const bloodAlcoholLevelOptionsDict =
  mapOptionsListToOptionsDict<BloodAlcoholLevel>(bloodAlcoholLevelOptionsList);

export const claimRecordNatureOptionsList = [
  { label: 'Accident corporel', value: ClaimNature.BodilyAccident },
  { label: 'Accident matériel', value: ClaimNature.MaterialAccident },
  { label: 'Vol', value: ClaimNature.Theft },
  { label: 'Incendie', value: ClaimNature.Fire },
  { label: 'Bris de glace', value: ClaimNature.BrokenGlass },
  { label: 'Autre (catastrophe naturelle, catastrophe technologique...)', value: ClaimNature.Other },
];

export const claimRecordNatureOptionsDict = mapOptionsListToOptionsDict<ClaimNature>(claimRecordNatureOptionsList);

export const claimRecordDriverOptionsList = [
  { label: 'Moi-même', value: DriverType.Myself },
  { label: 'Un autre conducteur', value: DriverType.AnotherDriver },
];

export const claimRecordDriverOptionsDict = mapOptionsListToOptionsDict<DriverType>(claimRecordDriverOptionsList);

export const secondaryDriverTypeOptionsList = [
  { label: 'Mon / Ma Conjoint·e', value: SecondaryRelationType.Conjoint },
  { label: 'Un de mes enfants', value: SecondaryRelationType.Enfants },
  { label: 'Une autre personne de mon entourage', value: SecondaryRelationType.Entourage },
];

export const secondaryDriverTypeOptionsDict =
  mapOptionsListToOptionsDict<SecondaryRelationType>(secondaryDriverTypeOptionsList);

export const secondayDriverLicenseTypeOptionsDict = {
  [LicenseType.french]: 'En France',
  [LicenseType.european]: "Dans un autre pays d'Europe",
  [LicenseType.other]: 'Autre',
};

export const secondaryDriverCivilityOptionsDict = {
  [Civility.Femme]: 'Mme.',
  [Civility.Homme]: 'M.',
};
