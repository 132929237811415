import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useState } from 'react';

export const ContactModalContext = createContext<boolean>(false);
export const SetContactModalContext = createContext<(visible: boolean) => void>(() => {});

interface ContactModalProviderProps {
  children: NonNullable<ReactNode>;
}

export function ContactModalProvider({ children }: ContactModalProviderProps): ReactNode {
  const [visible, setVisible] = useState<boolean>(false);

  const setContactModal = useCallback((visibleValue: boolean): void => {
    setVisible(visibleValue);
  }, []);

  return (
    <SetContactModalContext.Provider value={setContactModal}>
      <ContactModalContext.Provider value={visible}>{children}</ContactModalContext.Provider>
    </SetContactModalContext.Provider>
  );
}

export const useContactModal = (): boolean => useContext(ContactModalContext);
export const useSetContactModal = (): ((_: boolean) => void) => useContext(SetContactModalContext);
