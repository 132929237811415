import type { ReactNode } from 'react';
import { LicenseType } from '../../../apis/types/LongQuoteAnswers';
import { RadioScreenTemplate } from '../../../components/ScreenTemplates/RadioScreenTemplate';
import { makeChoices } from '../../../fsm/answers';
import { Driver } from '../../../fsm/types';
import { useIsLicenseDateInFuture } from '../../../hooks/useIsLicenseDateInFuture';
import europeanFlag from './assets/europeanFlag.svg';
import frenchFlag from './assets/frenchFlag.svg';
import styles from './styles.module.css';

const choices = makeChoices(LicenseType, [
  [
    LicenseType.french,
    <div className={styles.Choice}>
      En France <img src={frenchFlag} alt="drapeau français" />
    </div>,
  ],
  [
    LicenseType.european,
    <div className={styles.Choice}>
      Dans un autre pays d'Europe <img src={europeanFlag} alt="drapeau européen" />
    </div>,
  ],
  [LicenseType.other, 'Autre'],
]);

export interface LicenseTypeScreenProps {
  driver: Driver;
}

export function LicenseTypeScreen({ driver }: LicenseTypeScreenProps): ReactNode {
  const isLicenseDateInFuture = useIsLicenseDateInFuture(driver);

  const title = `Où ${driver === Driver.Primary ? 'votre' : 'son'} permis de conduire ${
    driver === Driver.Primary ? 'vous' : 'lui'
  } ${isLicenseDateInFuture ? 'sera-t-il' : 'a-t-il été'} délivré\u00A0?`;

  return (
    <RadioScreenTemplate
      fieldName={`${driver}Driver.licenseType`}
      choices={choices}
      title={title}
      highlightTitle="Quels sont les autres pays d’Europe&nbsp;?"
      highlightContent={
        <ul>
          <li>Les pays de l’Union Européenne,</li>
          <li>Les pays de l’Espace Economique Européen : Islande, Liechtenstein et Norvège,</li>
          <li>Le Royaume-Uni, si le permis a été délivré avant le 1er janvier 2021.</li>
        </ul>
      }
    />
  );
}
