export enum ServerPaymentPeriodicity {
  ANNUALLY = 'ANNUALLY',
  MONTHLY = 'MONTHLY',
}

export interface ServerPaymentOptions {
  paymentPeriodicity: ServerPaymentPeriodicity;
}

export enum PaymentPeriodicity {
  ANNUALLY = 'ANNUEL',
  MONTHLY = 'MENSUEL',
}

export interface PaymentOptions {
  paymentPeriodicity: PaymentPeriodicity;
}

export interface Amount {
  cents: number;
  formatted: string;
  currency: string; // IsoCurrencyCode
}

export interface PaymentOptionsResult {
  installmentAmount: Amount;
  downPaymentAmount: Amount;
}

export interface ServerPaymentOptionsResult {
  installmentAmount: Amount;
  downPaymentAmount: Amount;
}
