import type { ReactNode } from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';
import type { Except } from 'type-fest';
import { useSubscriptionFsmState } from '../../fsm/context';
import { useTimeout } from '../../hooks/useTimeout';
import { sendErrorMessageEvent } from '../../utils/mixpanel';
import { SetToastContext, ToastContext } from './ToastContext';
import type { ToastType } from '.';

const DURATION_OF_TOAST_CLOSING_ANIMATION = 300;

export interface ToastValue {
  message: ReactNode;
  type?: ToastType;
  isVisible: boolean;
  timer?: number;
  error?: Error;
}

export type ToastDefinition = Except<ToastValue, 'isVisible'>;

export interface UseToastReturnValue {
  openToast: (toast: ToastDefinition) => void;
  openErrorToast: (error: Error) => void;
  closeToast: () => void;
}

export const useToast = (): UseToastReturnValue => {
  const { context } = useSubscriptionFsmState();
  const setCurrentToast = useContext(SetToastContext);
  const currentToast = useContext(ToastContext);

  const [newToast, setNewToast] = useState<ToastDefinition | null>(null);
  const [isClosing, setIsClosing] = useState(false);

  const setNewToastAsCurrent = useCallback(() => {
    const toast: ToastValue = {
      ...newToast,
      message: newToast?.message,
      type: newToast?.type || 'info',
      isVisible: true,
    };

    if (toast.type === 'error') {
      const { tarificationResult } = context;

      sendErrorMessageEvent('toast message', toast.message?.toString(), toast.error?.message, {
        ...(tarificationResult?.isOk && { job_number: tarificationResult.submissionNumber }),
      });
    }

    setCurrentToast(toast);

    setNewToast(null);
  }, [setCurrentToast, setNewToast, newToast, context]);

  const hideCurrentToast = useCallback(() => {
    setCurrentToast((current) => ({
      ...current,
      message: current?.message,
      type: current?.type || 'info',
      isVisible: false,
    }));
    setIsClosing(true);
  }, [setCurrentToast]);

  // open toast if new toast
  useEffect(() => {
    if (newToast && !isClosing) {
      if (currentToast?.isVisible) {
        hideCurrentToast();
      } else {
        setNewToastAsCurrent();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newToast]);

  // set closing timer on new toast
  useTimeout(
    () => {
      hideCurrentToast();
    },
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    currentToast && currentToast.isVisible && currentToast.timer ? currentToast.timer : null,
  );

  // on toast closed
  useTimeout(
    () => {
      setIsClosing(false);
      if (newToast) {
        setNewToastAsCurrent();
      }
    },
    isClosing ? DURATION_OF_TOAST_CLOSING_ANIMATION : null,
  );

  const openToast = useCallback(
    (toast: ToastDefinition) => {
      setNewToast(toast);
    },
    [setNewToast],
  );

  const closeToast = useCallback(() => {
    hideCurrentToast();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openErrorToast = useCallback(
    (error: Error) => {
      setNewToast({
        message: 'Une erreur est survenue',
        timer: 5000,
        type: 'error',
        error,
      });
    },
    [setNewToast],
  );

  return { openToast, openErrorToast, closeToast };
};
