import type { ReactNode } from 'react';
import { CancellationRecordSuspendedDurationTemplate } from '../../../../components/ScreenTemplates/InformationTemplates/InsuranceRecordTemplates/CancellationRecordTemplates/CancellationRecordSuspendedDurationTemplate';
import { useSubscriptionFsmState } from '../../../../fsm/context';

export function SecondaryCancellationRecordSuspendedDurationScreen(): ReactNode {
  const {
    context: { editingInsuranceRecord },
  } = useSubscriptionFsmState();

  return (
    <CancellationRecordSuspendedDurationTemplate
      fieldName={`secondaryDriver.cancellationOrSuspensionRecords[${editingInsuranceRecord.index}].suspensionDuration`}
      title="Combien de temps son permis a-t-il été suspendu&nbsp;?"
    />
  );
}
