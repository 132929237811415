import { RadioBehaviour } from '@ornikar/kitt';
import { Typography } from '@ornikar/kitt-universal';
import cx from 'classnames';
import type { ComponentProps, ReactNode } from 'react';
import type { Except } from 'type-fest';
import styles from './styles.module.css';

type PartialBy<T, K extends keyof T> = Except<T, K> & Partial<Pick<T, K>>;
type TypographyProps = ComponentProps<typeof Typography>;

const getLabelProps = (
  checked: boolean,
  withSubText: boolean,
): PartialBy<{ color: TypographyProps['color']; variant: TypographyProps['variant'] }, 'color'> =>
  checked
    ? {
        color: 'white',
        variant: 'bold',
      }
    : { variant: withSubText ? 'bold' : 'regular' };

const getSubTextProps = (checked: boolean): PartialBy<{ color: TypographyProps['color'] }, 'color'> =>
  checked
    ? {
        color: 'white-light',
      }
    : {};

interface RadioBlockProps<T> {
  id: string;
  onClick: ComponentProps<typeof RadioBehaviour>['onChange'];
  children: ReactNode;
  checked: boolean;
  name: string;
  value: T;
  subText?: string;
}

export function RadioBlock<T = string>({
  id,
  onClick,
  children,
  checked,
  name,
  value,
  subText,
}: RadioBlockProps<T>): ReactNode {
  return (
    <RadioBehaviour
      id={id}
      name={name}
      checked={checked}
      value={value as unknown as string}
      className={cx(styles.Container)}
      onChange={onClick}
    >
      {checked && (
        <div>
          <div />
        </div>
      )}
      <div
        className={cx(styles.RadioContainer, {
          [styles.Checked]: checked,
          [styles.WithSubText]: Boolean(subText),
        })}
      >
        <div className={styles.LabelContainer}>
          <div
            className={cx(styles.OuterRadio, {
              [styles.Checked]: checked,
            })}
          >
            <div
              className={cx(styles.InnerRadio, {
                [styles.Checked]: checked,
              })}
            />
          </div>
          <Typography.Text {...getLabelProps(checked, Boolean(subText))}>{children}</Typography.Text>
        </div>
        {subText && <Typography.Text {...getSubTextProps(checked)}>{subText}coucou</Typography.Text>}
      </div>
    </RadioBehaviour>
  );
}
