import type { IntlValidator } from '@ornikar/react-validators';
import { useComposeValidators, useDateValidator, useRequiredValidator, useValidator } from '@ornikar/react-validators';
import { createValidDateAfterValidator, createValidDateBeforeValidator } from '@ornikar/validators';
import { addDays, format, subDays } from 'date-fns';
import { defineMessages } from 'react-intl';
import { validatorMessages } from '../../messages';

const messages = defineMessages({
  validDateAfter: {
    id: 'VehiculeAcquisitionAndCoverageScreen.validDateAfterMessage',
    defaultMessage: "La date d'achat doit être postérieure à la date de mise en circulation du véhicule ({date}).",
  },
  validDateBefore: {
    id: 'VehiculeAcquisitionAndCoverageScreen.validDateBeforeMessage',
    defaultMessage:
      'La date d’achat de votre véhicule doit être inférieure à 3 mois pour que nous puissions vous afficher votre tarif sur-mesure.',
  },
});

const useDateAfterValidator = (vehiculeMiseEnCirculation: string): IntlValidator<string> =>
  useValidator(
    createValidDateAfterValidator(() => format(subDays(new Date(vehiculeMiseEnCirculation), 1), 'yyyy-MM-dd')),
    messages.validDateAfter,
    { date: format(new Date(vehiculeMiseEnCirculation), 'dd/MM/yyyy') },
  );

const useDateBeforeValidator = (): IntlValidator<string> =>
  useValidator(
    createValidDateBeforeValidator(() => format(addDays(new Date(), 90), 'yyyy-MM-dd')),
    messages.validDateBefore,
  );

export const useVehicleAcquisitionDateValidator = (vehiculeMiseEnCirculation: string): IntlValidator<string> => {
  return useComposeValidators(
    useRequiredValidator(),
    useDateValidator(validatorMessages.dateFormatValidator),
    useDateAfterValidator(vehiculeMiseEnCirculation),
    useDateBeforeValidator(),
  );
};
