import type { BillingInformation } from '../apis/getBillingInformation';
import { OrnikarPackageLevel, type Package, type PaymentPeriodicity } from '../apis/types/Formula';
import { PaymentPeriodicity as PaymentOptionsPeriodicity } from '../apis/types/PaymentOptions';
import { useSubscriptionFsmState } from '../fsm/context';

export function getPaymentPeriodicityAmount(
  paymentPeriodicity: PaymentPeriodicity | PaymentOptionsPeriodicity,
  formula: Package,
): number {
  const isMonthly = paymentPeriodicity === 'monthly' || paymentPeriodicity === PaymentOptionsPeriodicity.MONTHLY;

  const price = isMonthly ? formula.monthlyPrice?.cents : formula.yearlyPrice.cents - formula.annualFees.cents;

  return price ?? 0;
}

export function usePaymentPeriodicityDetails(): {
  periodicity: PaymentPeriodicity;
  price: number;
} {
  const {
    context: { paymentPeriodicity, formulePickedObject: selectedFormula },
  } = useSubscriptionFsmState();

  return {
    periodicity: paymentPeriodicity!,
    price: selectedFormula ? getPaymentPeriodicityAmount(paymentPeriodicity!, selectedFormula) : 0,
  };
}

export const getAmountDetails = (
  billingData: BillingInformation,
  formulaName?: OrnikarPackageLevel,
  paymentPeriodicity?: PaymentPeriodicity,
): string => {
  let base = 'Ce montant correspond à';
  const parts = [];

  base += paymentPeriodicity === 'monthly' ? ' vos deux premières mensualités' : ' douze mois de couverture';

  if (formulaName !== OrnikarPackageLevel.THIRD_PARTY) {
    parts.push(`à la taxe attentat de ${billingData.upFrontPaymentTerrorismTaxAmount.formatted}`);
  }

  if (billingData.applicationFeeAmount.cents > 0) {
    parts.push(`aux frais de dossier de ${billingData.applicationFeeAmount.formatted}`);
  }

  if (parts.length === 1) {
    base += ` et ${parts[0]}`;
  }

  if (parts.length === 2) {
    base += `, ${parts[0]} et ${parts[1]}`;
  }

  return `${base}.`;
};
