import { HStack, Typography, View } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import type { Package } from '../../../../../../../apis/types/Formula';
import { PaymentPeriodicity } from '../../../../../../../apis/types/PaymentOptions';
import { formatPrice } from '../../../../../../../utils/formatPrice';
import { getPaymentPeriodicityAmount } from '../../../../../../../utils/price';

export interface TabsProps {
  packages: Package[];
  formuleSelected?: Package;
  paymentPeriodicity: PaymentPeriodicity;
  onClick: (formule: Package) => void;
}

export function Tabs({ packages, formuleSelected = packages[0], paymentPeriodicity, onClick }: TabsProps): ReactNode {
  return (
    <HStack backgroundColor="kitt.white" borderTopRadius="kitt.4">
      {packages.map((packageItem, index) => {
        const activeIndex = packages.findIndex(({ name }) => name === formuleSelected.name);
        const isActive = activeIndex === index;
        const isLeftActive = activeIndex === index - 1;
        const isRightActive = activeIndex === index + 1;

        const isFirstTab = index === 0;
        const isLastTab = index === packages.length - 1;
        const isMiddleTab = !isFirstTab && !isLastTab;

        const price = getPaymentPeriodicityAmount(paymentPeriodicity, packageItem);

        const [amount, cents] = formatPrice(price).split(',');

        return (
          <View
            key={packageItem.name}
            flex={1}
            marginX={isMiddleTab ? 1 : 0}
            paddingTop="kitt.4"
            paddingX="kitt.3"
            paddingBottom="kitt.2"
            backgroundColor={isActive ? 'kitt.white' : 'kitt.palettes.lateOcean.moonPurpleLight1'}
            borderTopLeftRadius={isFirstTab ? 'kitt.4' : undefined}
            borderTopRightRadius={isLastTab ? 'kitt.4' : undefined}
            borderBottomLeftRadius={isLeftActive ? 'kitt.2' : undefined}
            borderBottomRightRadius={isRightActive ? 'kitt.2' : undefined}
            style={{
              transitionDuration: '300ms',
              transitionProperty: 'background-color, border-radius',
            }}
            onMouseUp={() => onClick(packageItem)}
          >
            <Typography.Text base="body-xsmall" variant="bold" color={isActive ? 'primary' : 'black'}>
              {packageItem.name}
            </Typography.Text>
            <HStack marginTop="kitt.1">
              <Typography.Text base="header3" medium="header4" variant="bold">
                {amount},
                <Typography.Text base="header4" medium="header5" variant="bold">
                  {cents} €
                </Typography.Text>
              </Typography.Text>
            </HStack>
            <Typography.Text base="body-xsmall" color="black-anthracite">
              {paymentPeriodicity === PaymentPeriodicity.MONTHLY ? '/mois' : '/an'}
            </Typography.Text>
          </View>
        );
      })}
    </HStack>
  );
}
