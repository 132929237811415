import { SUMMARY_SCREENS, Section, State } from './fsm/types';
import { getFromNativeApp } from './utils/nativeApp';
import { getSection } from './utils/sections';

const exitFlowScreens = [
  State.CANNOT_QUOTE,
  State.INSURED_SINCE_LESS_THAN_A_YEAR,
  State.NEEDS_SALES_VALIDATION,
  State.TARIFICATION_LOADING,
];

export const getIsSaveQuoteButtonVisible = (state: State): boolean =>
  ![
    State.HAMON_LAW_INFORMATION,
    State.VEHICLE_LEGAL_INFORMATION,
    State.LRE_INFORMATION,
    State.WEBAPP_INTRODUCTION,
    State.REGISTER_IBAN,
    State.CONFIRM_SEPA_MANDATE,
    State.CREDIT_CARD_PAYMENT,
    State.REVERSE_SUBSCRIPTION,
    State.CANNOT_RETRIEVE_QUOTE,
    State.FORMULES,
    ...exitFlowScreens,
  ].includes(state);

export const getIsHelperButtonVisible = (state: State): boolean => {
  const section = getSection(state);

  const isQuotationFlow =
    section &&
    [Section.VEHICULE, Section.DRIVING, Section.PRIMARY_DRIVER, Section.SECONDARY_DRIVER, Section.INFORMATION].includes(
      section,
    );

  const isExitFlowScreen = exitFlowScreens.includes(state);

  return !(isQuotationFlow || isExitFlowScreen);
};

export const getIsHeaderVisible = (state: State, isDesktop: boolean): boolean => {
  return !(isDesktop && getFromNativeApp()) && (isDesktop || !SUMMARY_SCREENS.includes(state));
};
