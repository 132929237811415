import { api } from './api';
import { mapElectronicRegisteredMailToServerRequest } from './mappers/electronicRegisteredMailMapper';
import type { ElectronicRegisteredMail } from './types/ElectronicRegisteredMail';

export const sendElectronicRegisteredMail = async (
  quoteId: string | number,
  electronicRegisteredMail: ElectronicRegisteredMail,
): Promise<void> => {
  const requestBody = mapElectronicRegisteredMailToServerRequest(electronicRegisteredMail);

  await api(`api/v1/quotes/${quoteId}/electronic-registered-mail`, {
    method: 'PATCH',
    json: requestBody,
  });
};
