import { InputText, TypographyLink, VStack } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import type { GeocodingDto } from '../../../apis/getAddressSuggestions';
import { AddressAutocompleteFormField } from '../../../components/AddressAutocompleteFormField';
import { Field } from '../../../components/Field';
import { useHamonNameValidator } from '../../../forms/validation/sections/insurance/hamon';
import { ContractRef } from './Components/ContractRef';
import { HamonWantCheckbox } from './Components/HamonWantCheckbox';

export interface ManualHamonFormProps {
  initialFormattedAddress: React.MutableRefObject<GeocodingDto>;
  initialHamonName?: string;
  hideBackLink?: boolean;
  onBackToInsurersListPress?: () => void;
}

export function ManualHamonForm({
  initialFormattedAddress,
  initialHamonName,
  hideBackLink,
  onBackToInsurersListPress,
}: ManualHamonFormProps): ReactNode {
  const hamonNameValidator = useHamonNameValidator();
  return (
    <VStack
      space="kitt.4"
      marginTop="kitt.8"
      padding="kitt.6"
      borderWidth={1}
      borderRadius="kitt.5"
      borderColor="kitt.palettes.lateOcean.warmEmbrace"
    >
      <Field
        component={InputText}
        label="Nom de votre assureur"
        name="hamonName"
        validate={hamonNameValidator}
        initialValue={initialHamonName}
        value={initialHamonName}
      />

      {hideBackLink ? null : (
        <TypographyLink variant="bold" onPress={onBackToInsurersListPress}>
          <FormattedMessage id="hamonForm.backToInsurersList" defaultMessage="Revenir à la liste des assureurs" />
        </TypographyLink>
      )}

      <AddressAutocompleteFormField
        label="Adresse de votre assureur"
        name="hamonAutoCompletedAddress"
        initialFormattedAddress={initialFormattedAddress.current}
      />
      <ContractRef />
      <HamonWantCheckbox />
    </VStack>
  );
}
