import type { ReactNode } from 'react';
import { ClaimRecordResponsibilityLevelTemplate } from '../../../../components/ScreenTemplates/InformationTemplates/InsuranceRecordTemplates/ClaimRecordTemplates/ClaimRecordResponsibilityLevelTemplate';
import { ResponsibilityLevel } from '../../../../fsm/answers';
import { useSubscriptionFsmState } from '../../../../fsm/context';

export function PrimaryCancellationRecordClaimResponsibilityLevelScreen(): ReactNode {
  const {
    context: { editingInsuranceRecord },
  } = useSubscriptionFsmState();

  return (
    <ClaimRecordResponsibilityLevelTemplate
      title="Quel était votre niveau de responsabilité&nbsp;?"
      fieldName={`primaryDriver.cancellationOrSuspensionRecords[${editingInsuranceRecord.index}].claimRecord.responsibilityLevel`}
      filteredChoices={[ResponsibilityLevel.Responsible, ResponsibilityLevel.PartiallyResponsible]}
    />
  );
}
