import { useRequiredValidator } from '@ornikar/react-validators';
import type { ReactNode } from 'react';
import type { Except } from 'type-fest';
import { hasTerminationRecordOptionsList } from '../../../../../../constants/mappers/information';
import type { YesNoQuestion } from '../../../../../../fsm/answers';
import type { RadioScreenTemplateProps } from '../../../../RadioScreenTemplate';
import { RadioScreenTemplate } from '../../../../RadioScreenTemplate';

interface HasTerminationRecordTemplateProps extends Except<RadioScreenTemplateProps<YesNoQuestion>, 'choices'> {}

export function HasTerminationRecordTemplate({ fieldName, ...props }: HasTerminationRecordTemplateProps): ReactNode {
  const requiredValidator = useRequiredValidator();

  return (
    <RadioScreenTemplate
      {...props}
      fieldName={fieldName}
      choices={hasTerminationRecordOptionsList}
      validate={requiredValidator}
    />
  );
}
