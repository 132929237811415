import type { ReactNode } from 'react';
import { CancellationRecordSuspendedDurationTemplate } from '../../../../components/ScreenTemplates/InformationTemplates/InsuranceRecordTemplates/CancellationRecordTemplates/CancellationRecordSuspendedDurationTemplate';

export function PrimaryTerminationRecordLicenseSuspendedDurationScreen(): ReactNode {
  return (
    <CancellationRecordSuspendedDurationTemplate
      fieldName="primaryDriver.terminationRecords[0].cancellationOrSuspensionRecord.suspensionDuration"
      title="Combien de temps votre permis a-t-il été suspendu&nbsp;?"
    />
  );
}
