import { InputText } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import type { GeocodingDto } from '../../../apis/getAddressSuggestions';
import { HamonWantType } from '../../../apis/types/LongQuoteAnswers';
import { AddressAutocompleteFormField } from '../../../components/AddressAutocompleteFormField';
import { Field } from '../../../components/Field';
import { RadioBlockGroup } from '../../../components/RadioBlockGroup';
import {
  useHamonContractRefValidator,
  useHamonNameValidator,
  useSingleHamonWantValidator,
} from '../../../forms/validation/sections/insurance/hamon';
import { makeChoices } from '../../../fsm/answers';
import styles from './styles.module.css';

enum HamonWantYesTypeEnum {
  Yes = '1',
}

const singleHamonChoice = makeChoices(HamonWantYesTypeEnum, [
  [
    HamonWantYesTypeEnum.Yes,
    'Je confirme que mon contrat a plus d’un an et donne mandat à Ornikar pour sa résiliation.',
  ],
]);

export interface ManualHamonFormLegacyrops {
  initialFormattedAddress: React.MutableRefObject<GeocodingDto>;
  hamonWantValue: HamonWantType;
}

export function ManualHamonFormLegacy({
  initialFormattedAddress,
  hamonWantValue,
}: ManualHamonFormLegacyrops): ReactNode {
  const singleHamonWantValidator = useSingleHamonWantValidator();
  const hamonContractRefValidator = useHamonContractRefValidator();
  const hamonNameValidator = useHamonNameValidator();

  return (
    <>
      <Field
        component={RadioBlockGroup}
        name="hamonWant"
        choices={singleHamonChoice}
        validate={singleHamonWantValidator}
        className={styles.MandatField}
      />

      {hamonWantValue === HamonWantType.Yes && (
        <div className={styles.ContractFields}>
          <Field
            component={InputText}
            label="Numéro du contrat à résilier"
            placeholder="FR00 00X0X0"
            name="hamonContractRef"
            validate={hamonContractRefValidator}
          />

          <Field
            component={InputText}
            label="Nom de votre assureur"
            name="hamonName"
            placeholder="L’Agence Tous Risques"
            validate={hamonNameValidator}
          />

          <AddressAutocompleteFormField
            label="Adresse de votre assureur"
            placeholder="Saisissez l’adresse de votre assureur"
            name="hamonAutoCompletedAddress"
            initialFormattedAddress={initialFormattedAddress.current}
          />
        </div>
      )}
    </>
  );
}
