import { api } from './api';
import type { Amount } from './types/PaymentOptions';

export interface BillingInformation {
  installmentAmount: Amount;
  upFrontPaymentTotalAmount: Amount;
  upFrontPaymentTerrorismTaxAmount: Amount;
  upFrontPaymentInMonths: number;
  applicationFeeAmount: Amount;
}

export const getBillingInformation = (quoteId: string | number): Promise<BillingInformation> =>
  api<BillingInformation>(`api/v1/quotes/${quoteId}/billing`);
