import { DialogModal } from '@ornikar/kitt-universal';
import { useRequiredValidator } from '@ornikar/react-validators';
import type { ReactNode } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { InconsistencyModal } from '../../../components/InconsistencyModal/InconsistencyModal';
import { RadioScreenTemplate } from '../../../components/ScreenTemplates/RadioScreenTemplate';
import type { LongQuoteAnswers } from '../../../fsm/answers';
import { AccidentType, ClaimQuantity, TerminationReason, makeChoices } from '../../../fsm/answers';
import { useSubscriptionFsmDispatch } from '../../../fsm/context';
import { Driver, Event } from '../../../fsm/types';
import { DateFromYearsAgo } from '../../../utils/date';
import { sendEvent } from '../../../utils/mixpanel';

const claimQuantityChoices = (hasAnyClaimRelatedToCancellation: boolean) => {
  if (hasAnyClaimRelatedToCancellation) {
    return makeChoices(
      {
        None: ClaimQuantity.None,
        Many: ClaimQuantity.Many,
      },
      [
        [ClaimQuantity.None, 'Non'],
        [ClaimQuantity.Many, 'Oui'],
      ],
    );
  }

  return makeChoices(ClaimQuantity, [
    [ClaimQuantity.None, 'Non, aucun'],
    [ClaimQuantity.One, 'Oui, un'],
    [ClaimQuantity.Many, 'Oui, plusieurs'],
  ]);
};

interface ClaimRecordQuantityScreenProps {
  driver: Driver;
}

export function ClaimRecordQuantityScreen({ driver }: ClaimRecordQuantityScreenProps): ReactNode {
  const send = useSubscriptionFsmDispatch();
  const requiredValidator = useRequiredValidator();
  const threeYearsAgo = DateFromYearsAgo(3);
  const driverKey: `${typeof driver}Driver` = `${driver}Driver`;

  const fieldName = `${driverKey}.claimQuantity`;

  const modalContent = `${
    driver === Driver.Primary ? 'Votre' : 'Son'
  } assurance auto a été résiliée suite à un sinistre. ${
    driver === Driver.Primary ? 'Vous avez' : 'Il a'
  } donc au moins un sinistre déclaré depuis le ${threeYearsAgo}.`;

  const {
    values: {
      [driverKey]: { claimQuantity, cancellationOrSuspensionRecords, terminationRecords },
    },
  } = useFormState<LongQuoteAnswers>();

  const hasAnyClaimRelatedToCancellation = Boolean(
    cancellationOrSuspensionRecords?.some((record) => record.accident !== AccidentType.None),
  );

  const title = useMemo(() => {
    const driverToAddress = driver === Driver.Primary ? 'Avez-vous' : 'A-t-il';

    if (hasAnyClaimRelatedToCancellation) {
      return `C'est noté. ${driverToAddress} eu un autre sinistre depuis le ${threeYearsAgo}\u00A0?`;
    }

    return `${driverToAddress} déclaré des sinistres depuis le ${threeYearsAgo}\u00A0?`;
  }, [driver, hasAnyClaimRelatedToCancellation, threeYearsAgo]);

  const reasonForTermination = terminationRecords?.[0]?.reason;

  const [isModalVisible, setIsModalVisible] = useState(false);

  const { submit } = useForm();

  const onSubmit = useCallback(() => {
    if (reasonForTermination === TerminationReason.Claims && claimQuantity === ClaimQuantity.None) {
      setIsModalVisible(true);
      sendEvent('Display Claim Incoherence Modal');
      return;
    }

    submit();
  }, [claimQuantity, reasonForTermination, submit]);

  return (
    <>
      <RadioScreenTemplate
        fieldName={fieldName}
        title={title}
        choices={claimQuantityChoices(hasAnyClaimRelatedToCancellation)}
        highlightTitle="Qu'est-ce qu'un sinistre auto&nbsp;?"
        highlightContent={
          <>
            Bris de glace, accident, vol, incendie, vandalisme, accident (responsable ou non)... Il s'agit de tous les
            dommages matériels et corporels que vous avez déclaré à votre assureur.
            <br />
            Pour avoir l'historique complet de vos sinistres depuis le {threeYearsAgo}, rendez-vous sur votre Relevé
            d'Information
            <br />
            Ces données sont vérifiées lors de l'envoi des pièces justificatives obligatoires demandées par votre
            assureur.
          </>
        }
        validate={requiredValidator}
        onSubmit={onSubmit}
      />
      <DialogModal.ModalBehaviour visible={isModalVisible} onClose={() => setIsModalVisible(false)}>
        <InconsistencyModal
          title="Nous avons relevé une incohérence"
          content={<div>{modalContent}</div>}
          mainButton={{
            label: 'Je modifie le motif de résiliation',
            onClick: () => {
              sendEvent('Solve Claim Incoherence', {
                solution: 'change termination reason',
              });
              send(Event.REDIRECT);
            },
          }}
          secondButton={{
            label: `${driver === Driver.Primary ? "J'ai" : 'Il a'} bien eu au moins un sinistre`,
            onClick: () => {
              sendEvent('Solve Claim Incoherence', {
                solution: 'add a claim',
              });
              setIsModalVisible(false);
            },
          }}
        />
      </DialogModal.ModalBehaviour>
    </>
  );
}
