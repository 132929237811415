import { NavigationModal, View } from '@ornikar/kitt-universal';
import * as Sentry from '@sentry/react';
import { differenceInCalendarDays, format, isSameDay } from 'date-fns';
import { type ReactNode, useEffect, useMemo, useState } from 'react';
import { sendCallbackRequest } from '../../apis/sendCallbackRequest';
import { WEB_CALLBACK_CONFIRMATION } from '../../constants/eventName';
import { useSubscriptionFsmState } from '../../fsm/context';
import { useWebCallbackScheduleData } from '../../hooks/useWebCallbackScheduleData';
import type { Time } from '../../utils/businessDates';
import { buildDateTimeFromSlots } from '../../utils/businessDates';
import { formatDateWithDayLabelAndMonth } from '../../utils/date';
import { sendEvent } from '../../utils/mixpanel';
import { capitalize } from '../../utils/string';
import { CallbackModuleModalView } from '../CallbackModuleModalView';
import type { CallbackScheduleItem } from '../CallbackScheduleModule';
import { CallbackStatusEnum } from '../CallbackStatusView';
import { CallbackForm, type CallbackFormValues } from './CallbackForm';

interface CallbackModuleModalProps {
  isVisible: boolean;
  onClose: () => void;
}

export interface UseCallbackModule {
  daySlot?: CallbackScheduleItem<Date>;
  timeSlot?: CallbackScheduleItem<[Time, Time]>;
  callDueDate?: Date;
  setDaySlot: (daySlot?: CallbackScheduleItem<Date>) => void;
  setTimeSlot: (timeSlot?: CallbackScheduleItem<[Time, Time]>) => void;
}

export function useCallbackModule(): UseCallbackModule {
  const [daySlot, setDaySlot] = useState<CallbackScheduleItem<Date>>();
  const [timeSlot, setTimeSlot] = useState<CallbackScheduleItem<[Time, Time]>>();
  const [callDueDate, setCallDueDate] = useState<Date>();

  useEffect(() => {
    if (daySlot && timeSlot) {
      setCallDueDate(buildDateTimeFromSlots(daySlot.value, timeSlot.value));
      return;
    }
    setCallDueDate(undefined);
  }, [daySlot, timeSlot]);

  return {
    daySlot,
    timeSlot,
    callDueDate,
    setDaySlot,
    setTimeSlot,
  };
}

export function CallbackModuleModal({ isVisible, onClose }: CallbackModuleModalProps): ReactNode {
  const state = useSubscriptionFsmState();
  const subscriberPhone = state.context?.answers.subscriberPhone ?? '';
  const callbackModule = useCallbackModule();
  const webCallbackScheduleData = useWebCallbackScheduleData();
  const { daySlot, callDueDate, setDaySlot, setTimeSlot } = callbackModule;
  const [status, setStatus] = useState<CallbackStatusEnum>();
  const [dayItems, setDayItems] = useState<CallbackScheduleItem<Date>[]>([]);
  const [shouldDisplaySwitch, setShouldDisplaySwitch] = useState<boolean>(false);
  const [timeSlotRangeInMinutes, setTimeSlotRangeInMinutes] = useState<number>(30);

  useEffect(() => {
    if (webCallbackScheduleData) {
      setDayItems(
        webCallbackScheduleData.daySlotsList.map((day, index) => {
          return { id: index, value: day, label: capitalize(formatDateWithDayLabelAndMonth(day)) };
        }),
      );
      setShouldDisplaySwitch(webCallbackScheduleData.isBusinessHours);
      setTimeSlotRangeInMinutes(webCallbackScheduleData.timeSlotRangeInMinutes);
    }
  }, [webCallbackScheduleData]);

  const timeItems = useMemo(() => {
    if (daySlot && webCallbackScheduleData) {
      const timeSlotsOfDay = webCallbackScheduleData.timeSlotsPerDate.find((slot) => {
        return isSameDay(slot.day, daySlot.value);
      });
      if (timeSlotsOfDay) {
        return timeSlotsOfDay.timeSlots.map((time, index) => {
          return { id: index, value: time, label: time.join(' - ') };
        });
      }
    }
    return [];
  }, [daySlot, webCallbackScheduleData]);

  const handleSubmit = async ({ phoneNumber, additionalMessage }: CallbackFormValues): Promise<void> => {
    try {
      await sendCallbackRequest({
        anonymousId: state.context?.segmentAnonymousId ?? '',
        bu: 'INSU',
        pageName: state.value as string,
        fromComparator: new URLSearchParams(window.location.search).get('utm_source') === 'comparateur',
        callDueDate: callDueDate?.toISOString(),
        scheduled: !!callDueDate,
        phoneNumber,
        comment: additionalMessage,
        email: state.context?.answers.subscriberEmail ?? '',
      });
      setStatus(CallbackStatusEnum.Success);
    } catch (error) {
      Sentry.captureException(error);
      setStatus(CallbackStatusEnum.Error);
      return;
    }

    sendEvent(WEB_CALLBACK_CONFIRMATION, {
      web_callback_type: callDueDate ? 'booking' : 'web_callback',
      web_callback_day: callDueDate ? `J+${differenceInCalendarDays(callDueDate, new Date())}` : 'J+0',
      web_callback_hour: callDueDate ? format(callDueDate, 'a..aa') : undefined,
      phone_number_modification: subscriberPhone !== phoneNumber,
      comment_addition: additionalMessage,
    });
  };

  const handleCancel = (): void => {
    onClose();
    setTimeout(() => {
      setDaySlot(undefined);
      setTimeSlot(undefined);
      setStatus(undefined);
    }, 500);
  };

  return (
    <NavigationModal.ModalBehaviour visible={isVisible} onClose={onClose}>
      <View width="100%">
        <CallbackForm.Form initialValues={{ phoneNumber: subscriberPhone }} onSubmit={handleSubmit}>
          <CallbackModuleModalView
            shouldDisplaySwitch={shouldDisplaySwitch}
            timeSlotRangeInMinutes={timeSlotRangeInMinutes}
            callbackModule={callbackModule}
            status={status}
            dayItems={dayItems}
            timeItems={timeItems}
            setStatus={setStatus}
            onCancel={handleCancel}
            onClose={onClose}
          />
        </CallbackForm.Form>
      </View>
    </NavigationModal.ModalBehaviour>
  );
}
