import { useRequiredValidator } from '@ornikar/react-validators';
import type { ReactNode } from 'react';
import type { Except } from 'type-fest';
import { UnderTheInfluenceOf, makeChoices } from '../../../../../../fsm/answers';
import type { RadioScreenTemplateProps } from '../../../../RadioScreenTemplate';
import { RadioScreenTemplate } from '../../../../RadioScreenTemplate';

interface ClaimRecordWithAlcoholTemplateProps extends Except<RadioScreenTemplateProps<UnderTheInfluenceOf>, 'choices'> {
  fieldName: string;
}

const choices = makeChoices(UnderTheInfluenceOf, [
  [UnderTheInfluenceOf.None, 'Non'],
  [UnderTheInfluenceOf.Alcohol, 'Oui, sous l’emprise d’alcool'],
  [UnderTheInfluenceOf.Drugs, 'Oui, sous l’emprise de stupéfiants'],
  [UnderTheInfluenceOf.AlcoholAndDrugs, 'Oui, sous l’emprise d’alcool et de stupéfiants'],
]);

export function ClaimRecordWithAlcoholTemplate({ title, fieldName }: ClaimRecordWithAlcoholTemplateProps): ReactNode {
  const requiredValidator = useRequiredValidator();

  return <RadioScreenTemplate fieldName={fieldName} choices={choices} title={title} validate={requiredValidator} />;
}
