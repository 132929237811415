import get from 'lodash/get';
import type { DoneInvokeEvent } from 'xstate';
import type { NestedPaths, TypeFromPath } from '../../../types/utility';
import type { LongQuoteAnswers } from '../../answers';
import type { AnswerEvent, Context, Events } from '../../types';
import { Event } from '../../types';

export function isAnswerEquals<K extends NestedPaths<LongQuoteAnswers>>(
  pathString: K | ((context: Context) => K),
  condition: (value: TypeFromPath<LongQuoteAnswers, K>) => boolean,
): (context: Context, event: Events) => boolean {
  return (context: Context, event: Events) => {
    const computedPathString = typeof pathString === 'function' ? pathString(context) : pathString;

    if (event.type === Event.ANSWER && (event as AnswerEvent).answers) {
      const value = get(event.answers, computedPathString) as unknown as TypeFromPath<LongQuoteAnswers, K>;

      if (value !== undefined && value !== null) {
        return condition(value);
      }
    }

    const value = get(context.answers, computedPathString) as unknown as TypeFromPath<LongQuoteAnswers, K>;

    return condition(value);
  };
}

export const not =
  <T extends Events | DoneInvokeEvent<any>>(guardDefinition: (context: Context, event: T) => boolean) =>
  (context: Context, event: T): boolean =>
    !guardDefinition(context, event);

export const and =
  <T extends Events | DoneInvokeEvent<any>>(...guardDefinitions: ((context: Context, event: T) => boolean)[]) =>
  (context: Context, event: T): boolean =>
    guardDefinitions.every((guardDefinition) => guardDefinition(context, event));

export const or =
  <T extends Events | DoneInvokeEvent<any>>(...guardDefinitions: ((context: Context, event: T) => boolean)[]) =>
  (context: Context, event: T): boolean =>
    guardDefinitions.some((guardDefinition) => guardDefinition(context, event));
