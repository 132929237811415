import { api } from './api';
import { hamonLawInformationToServerRequest } from './mappers/hamonLawInformationMapper';
import type { HamonLawInformation } from './types/HamonLawInformation';

export const sendHamonLawInformation = async (
  quoteId: string | number,
  hamonInformation: HamonLawInformation,
): Promise<void> => {
  const requestBody = hamonLawInformationToServerRequest(hamonInformation);

  await api(`api/v1/quotes/${quoteId}/hamon-law`, {
    method: 'PATCH',
    json: requestBody,
  });
};
