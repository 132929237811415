import type { HighlightProps } from '@ornikar/kitt-universal';
import { Highlight, Typography } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import styles from './styles.module.css';

export interface AddonInfoModalContentItemProps {
  icon?: ReactNode;
  title: ReactNode;
  description: ReactNode;
  isHighlighted?: boolean;
  variant?: HighlightProps['variant'];
}

export function AddonInfoModalContentItem({
  icon,
  title,
  description,
  isHighlighted = false,
  variant = 'regular',
}: AddonInfoModalContentItemProps): ReactNode {
  const content = (
    <div className={styles.Container}>
      <div className={styles.Title}>
        {icon}
        <Typography.Text base="body" variant="bold">
          {title}
        </Typography.Text>
      </div>
      <div className={styles.Content}>
        <Typography.Text>{description}</Typography.Text>
      </div>
    </div>
  );

  return isHighlighted ? <Highlight variant={variant}>{content}</Highlight> : content;
}
