import type { ReactNode } from 'react';
import { AddClaimRecordTemplate } from '../../../../components/ScreenTemplates/InformationTemplates/InsuranceRecordTemplates/ClaimRecordTemplates/AddClaimRecordTemplate';
import { useSubscriptionFsmDispatch } from '../../../../fsm/context';
import { Event } from '../../../../fsm/types';
import { DateFromYearsAgo } from '../../../../utils/date';

export function PrimaryAddClaimRecordScreen(): ReactNode {
  const send = useSubscriptionFsmDispatch();
  const threeYearsAgo = DateFromYearsAgo(3);

  return (
    <AddClaimRecordTemplate
      title={`C’est noté. Avez-vous eu un autre sinistre depuis le ${threeYearsAgo}\u00A0?`}
      onAddClaim={() => send({ type: Event.ADD_CLAIM })}
      onContinue={() => send({ type: Event.CONTINUE })}
    />
  );
}
