import { Typography, TypographyEmoji } from '@ornikar/kitt-universal';
import { useRequiredValidator } from '@ornikar/react-validators';
import * as Sentry from '@sentry/react';
import type { ReactNode } from 'react';
import { useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { sendElectronicRegisteredMail } from '../../../apis/sendElectronicRegisteredMail';
import { ElectronicRegisteredMail } from '../../../apis/types/ElectronicRegisteredMail';
import type { TarificationResultOK } from '../../../apis/types/Quotation';
import { Field } from '../../../components/Field';
import { RadioBlockGroup } from '../../../components/RadioBlockGroup';
import { ScreenTemplateWithValidation } from '../../../components/ScreenTemplates/ScreenTemplateWithValidation';
import { useToast } from '../../../components/Toast/useToast';
import { makeChoices } from '../../../fsm/answers';
import { useSubscriptionFsmState } from '../../../fsm/context';

const choices = makeChoices(ElectronicRegisteredMail, [
  [
    ElectronicRegisteredMail.Yes,
    (checked: boolean) => (
      <Typography.Text color={checked ? 'white' : 'black'} variant={checked ? 'bold' : 'regular'}>
        J’opte pour les lettres recommandées électroniques <TypographyEmoji emoji="👌" />
      </Typography.Text>
    ),
  ],
  [ElectronicRegisteredMail.No, 'Je préfère les lettres recommandées papier'],
]);

const fieldName = 'electronicRegisteredMail';

export function LREInformationScreen(): ReactNode {
  const [isLoading, setIsLoading] = useState(false);
  const { openErrorToast } = useToast();

  const requiredValidation = useRequiredValidator();
  const { submit } = useForm();
  const { values } = useFormState();
  const {
    context: { tarificationResult },
  } = useSubscriptionFsmState();

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      await sendElectronicRegisteredMail(
        (tarificationResult as TarificationResultOK).quoteId,
        values.electronicRegisteredMail as ElectronicRegisteredMail,
      );

      submit();
    } catch (error) {
      Sentry.captureException(error);

      openErrorToast(error as Error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ScreenTemplateWithValidation
      hideSaveQuoteButton
      fieldNames={[fieldName]}
      title="Comment souhaitez-vous recevoir vos lettres recommandées&nbsp;?"
      highlightTitle="Quels avantages avec la lettre recommandée électronique&nbsp;?"
      highlightContent={
        <ul>
          <li>Pas besoin d’aller en bureau de poste, rendez-vous directement dans votre boîte mail.</li>
          <li>Zéro papier à stocker, retrouvez facilement vos documents.</li>
        </ul>
      }
      submitButton={{ loading: isLoading }}
      backButtonLabel="Étape précédente"
      onSubmit={handleSubmit}
    >
      <Field component={RadioBlockGroup} name={fieldName} choices={choices} validate={requiredValidation} />
    </ScreenTemplateWithValidation>
  );
}
