import { useEffect } from 'react';
import { useSubscriptionFsmStateValue } from '../fsm/context';
import type { State } from '../fsm/types';
import { sendEvent } from '../utils/mixpanel';

/**
 * This hook sends a mixpanel event each time the state change.
 */
export function useAnalyticsStateChange(): void {
  const stateMachineValue = useSubscriptionFsmStateValue() as State;

  useEffect(() => {
    sendEvent('Display Insurance State');
  }, [stateMachineValue]);
}
