import { Typography, VStack, View } from '@ornikar/kitt-universal';
import { addMinutes } from 'date-fns';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { formatDateWithDayLabelAndMonth, formatDateWithHoursAndMinutes } from '../../utils/date';
import HandPeaceIllustration from './assets/hand-peace.svg';

interface CallbackSuccessViewProps {
  timeSlotRangeInMinutes: number;
  booking?: Date;
}

export function CallbackSuccessView({ timeSlotRangeInMinutes, booking }: CallbackSuccessViewProps): ReactNode {
  return (
    <VStack space="kitt.6" alignItems="center">
      <View width={{ base: 89, medium: 57 }} height={{ base: 126, medium: 81 }}>
        <img src={HandPeaceIllustration} alt="Peace" />
      </View>
      <VStack space="kitt.2">
        <Typography.Text variant="bold" textAlign="center">
          {booking ? (
            <FormattedMessage
              id="callbackStatusView.success.booking.subtitle"
              defaultMessage="Votre rendez-vous est confirmé pour le {date} de {startHour} à {endHour}. "
              values={{
                date: formatDateWithDayLabelAndMonth(booking),
                startHour: formatDateWithHoursAndMinutes(booking),
                endHour: formatDateWithHoursAndMinutes(addMinutes(booking, timeSlotRangeInMinutes)),
              }}
            />
          ) : (
            <FormattedMessage
              id="callbackStatusView.success.call.subtitle"
              defaultMessage="Parfait ! Un conseiller va revenir vers vous au plus vite. "
            />
          )}
        </Typography.Text>
        <Typography.Text textAlign="center">
          {booking ? (
            <FormattedMessage
              id="callbackStatusView.success.booking.description"
              defaultMessage="Un conseiller vous appellera directement sur le numéro renseigné précédemment."
            />
          ) : (
            <FormattedMessage
              id="callbackStatusView.success.call.description"
              defaultMessage="Vous recevrez l’appel directement sur le numéro renseigné précédemment."
            />
          )}
        </Typography.Text>
      </VStack>
    </VStack>
  );
}
