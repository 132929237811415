import { useRequiredValidator } from '@ornikar/react-validators';
import type { ReactNode } from 'react';
import type { Except } from 'type-fest';
import { AccidentType, makeChoices } from '../../../../../../fsm/answers';
import type { RadioScreenTemplateProps } from '../../../../RadioScreenTemplate';
import { RadioScreenTemplate } from '../../../../RadioScreenTemplate';

type CancellationRecordIsClaimTemplateProps = Except<RadioScreenTemplateProps<AccidentType>, 'choices'>;

const choices = makeChoices(AccidentType, [
  [AccidentType.None, 'Non'],
  [AccidentType.MaterialAccident, 'Oui, matériel'],
  [AccidentType.BodilyAccident, 'Oui, corporel'],
]);

export function CancellationRecordIsClaimTemplate(props: CancellationRecordIsClaimTemplateProps): ReactNode {
  const requiredValidator = useRequiredValidator();

  return <RadioScreenTemplate {...props} choices={choices} validate={requiredValidator} />;
}
