import { useRequiredValidator } from '@ornikar/react-validators';
import type { ReactNode } from 'react';
import { ScreenTemplate } from '../../../components/ScreenTemplates/ScreenTemplate';
import { VersionAutoComplete } from './VersionAutocomplete';

export function VehicleVersionScreen(): ReactNode {
  const validator = useRequiredValidator();

  return (
    <ScreenTemplate
      title="Quelle est sa version&nbsp;?"
      highlightTitle="Où trouver la version ?"
      highlightContent={
        <div>
          Rendez-vous au <b>repère D2</b> de votre <b>carte grise (certificat d’immatriculation)</b>
        </div>
      }
      hideSubmitButtonOnFirstEditionOfField="vehiculeVersion"
    >
      <VersionAutoComplete name="vehiculeVersion" validate={validator} />
    </ScreenTemplate>
  );
}
