import { IllustratedIcon } from '@ornikar/illustrated-icons';
import { HStack, Typography, View } from '@ornikar/kitt-universal';
import type { ReactElement, ReactNode } from 'react';

interface HelperCardProps {
  icon: ReactElement;
  children: ReactNode;
}

export function HelperCard({ icon: Icon, children }: HelperCardProps): ReactNode {
  return (
    <HStack
      alignItems="center"
      backgroundColor="kitt.palettes.lateOcean.moonPurpleLight1"
      borderRadius={10}
      padding="kitt.2"
      space="kitt.4"
    >
      <IllustratedIcon icon={Icon} size={48} />
      <View flex={1}>
        <Typography.Text base="body-small">{children}</Typography.Text>
      </View>
    </HStack>
  );
}
