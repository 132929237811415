import type { ReactNode } from 'react';
import { TerminationRecordReasonTemplate } from '../../../../components/ScreenTemplates/InformationTemplates/InsuranceRecordTemplates/TerminationRecordTemplates/TerminationRecordReasonTemplate';

export function SecondaryTerminationRecordReasonScreen(): ReactNode {
  return (
    <TerminationRecordReasonTemplate
      fieldName="secondaryDriver.terminationRecords[0].reason"
      highlightContent="Cette information se trouve sur son Relevé d'Informations."
    />
  );
}
