import type { ReactNode } from 'react';
import { RegistrationDocumentPartnerLink } from '../../../components/RegistrationDocumentPartner';
import { RadioScreenTemplate } from '../../../components/ScreenTemplates/RadioScreenTemplate';
import { useRegistrationDocumentOwnerIsSubscriberValidator } from '../../../forms/validation/sections/driving/registrationDocumentOwner';
import { RegistrationDocumentOwnerIsSubscriberType, makeChoices } from '../../../fsm/answers';
import { useSubscriptionFsmState } from '../../../fsm/context';
import { isRegistrationDocumentPartnerLinkDisplayed } from '../../../fsm/guards/flagship';
import { isBeforeToday } from '../../../fsm/validators';

const registrationDocumentIsOnlyUserNameChoices = makeChoices(RegistrationDocumentOwnerIsSubscriberType, [
  [RegistrationDocumentOwnerIsSubscriberType.Oui, 'Oui'],
  [RegistrationDocumentOwnerIsSubscriberType.Non, 'Non'],
]);

export function DrivingRegistrationDocumentOwnerIsSubscriberScreen(): ReactNode {
  const registrationDocumentOwnerIsSubscriberValidator = useRegistrationDocumentOwnerIsSubscriberValidator();
  const {
    context: { answers },
  } = useSubscriptionFsmState();
  const isVehicleInPossession = answers.vehiculeAcquisition && isBeforeToday(answers.vehiculeAcquisition);

  return (
    <RadioScreenTemplate
      title={
        <>
          Dernière question avant de passer à l’étape concernant vos informations. La carte grise (certificat
          d’immatriculation) du véhicule{' '}
          <span style={{ whiteSpace: 'nowrap' }}>{isVehicleInPossession ? 'est' : 'sera-t'}-elle</span> uniquement à
          votre <span style={{ whiteSpace: 'nowrap' }}>nom&nbsp;?</span>
        </>
      }
      fieldName="registrationDocumentOwnerIsSubscriber"
      choices={registrationDocumentIsOnlyUserNameChoices}
      highlightTitle="Qui est le propriétaire du véhicule ?"
      highlightContent={
        <>
          <div>
            C’est la personne qui est inscrite sur la carte grise (certificat d’immatriculation) sur les champs{' '}
            <b>C.1</b> et <b>C.4.1</b>.
          </div>
          {isRegistrationDocumentPartnerLinkDisplayed() ? (
            <>
              <br />
              <RegistrationDocumentPartnerLink />
            </>
          ) : null}
        </>
      }
      validate={registrationDocumentOwnerIsSubscriberValidator}
    />
  );
}
