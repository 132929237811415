import { ArrowLeftRegularIcon, ArrowRightRegularIcon, SparkleFillIcon } from '@ornikar/kitt-icons/phosphor';
import { Button, HStack, Icon, IconButton, Typography, VStack, View, theme } from '@ornikar/kitt-universal';
import { type PropsWithChildren, type ReactNode, useEffect } from 'react';
import { DEFAULT_ANSWERS_BY_SECTION } from '../../../constants/defaultAnswersBySection';
import { useSubscriptionFsmState, useSubscriptionFsmStateValue } from '../../../fsm/context';
import type { State } from '../../../fsm/types';
import { useGoBack } from '../../../hooks/useGoBack';
import { useInsuranceDesktopMediaQuery } from '../../../hooks/useInsuranceDesktopMediaQuery';
import { delayPromise } from '../../../utils/promise';
import { Breadcrumbs } from '../../Breadcrumbs';
import { ButtonSection } from '../../ButtonSection';
import { ConstrainedWidth } from '../../ConstrainedWidth';
import { ExitDoorHighlight } from '../../exitDoor/ExitDoorHighlight';

interface RecapScreenTemplateProps {
  title: ReactNode;
  illustration: ReactNode;
  onConfirm: () => void;
}
export function RecapScreenTemplate({
  title,
  illustration,
  onConfirm,
  children,
}: PropsWithChildren<RecapScreenTemplateProps>): ReactNode {
  const [canBack, goBack] = useGoBack();
  const isDesktop = useInsuranceDesktopMediaQuery();
  const {
    context: { defaultAnswers },
  } = useSubscriptionFsmState();

  const state = useSubscriptionFsmStateValue() as State;

  const hasDefaultAnswers = defaultAnswers.some((defaultAnswer) =>
    DEFAULT_ANSWERS_BY_SECTION[state]?.includes(defaultAnswer),
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === 'Enter' && !event.defaultPrevented) {
        event.preventDefault();
        delayPromise(async () => onConfirm(), 0);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onConfirm]);

  const illustrationCard = (
    <View
      width={isDesktop ? 277 : '100%'}
      height={isDesktop ? 295 : '100%'}
      borderRadius={isDesktop ? 'kitt.4' : 0}
      padding={isDesktop ? 'kitt.6' : 'kitt.4'}
      backgroundColor="kitt.palettes.lateOcean.lateOceanLight1"
    >
      <VStack
        alignSelf="center"
        maxWidth={isDesktop ? '100%' : 608}
        width="100%"
        justifyContent="space-between"
        height="100%"
      >
        {!isDesktop && canBack && <IconButton icon={<ArrowLeftRegularIcon />} color="ghost" onPress={goBack} />}
        <Typography.Text style={{ fontFamily: 'Transducer', fontSize: 32, lineHeight: 38 }} color="kitt.white">
          {title}
        </Typography.Text>
        <View alignSelf="end">{illustration}</View>
      </VStack>
    </View>
  );

  const informationConfirmationSection = (
    <VStack
      flex={1}
      space="kitt.8"
      paddingX={isDesktop ? 0 : 'kitt.4'}
      minWidth={isDesktop ? 483 : 0}
      maxWidth={isDesktop ? '100%' : 608}
      width={isDesktop ? null : '100%'}
      alignSelf={isDesktop ? 'auto' : 'center'}
    >
      {hasDefaultAnswers && (
        <View marginTop={{ base: 'kitt.2', large: 0 }}>
          <ExitDoorHighlight backgroundColor={theme.palettes.lateOcean['lavender.3']}>
            Pour vous proposer une estimation de tarif, les informations que vous avez déclarées ne pas connaître ont
            été complétées avec des réponses standards (
            <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
              <Icon icon={<SparkleFillIcon />} size="kitt.5" color={theme.palettes.lateOcean['eggshell.9']} />
            </div>
            ) . Vous devrez les corriger pour continuer l’aventure avec nous.
          </ExitDoorHighlight>
        </View>
      )}

      <VStack space="kitt.2">{children}</VStack>
      {isDesktop && (
        <VStack alignSelf={isDesktop ? 'flex-end' : 'center'} space="kitt.3">
          <Button type="primary" onPress={onConfirm}>
            <HStack alignItems="center" space="kitt.2">
              Confirmer ces informations
              {isDesktop && <Icon size="kitt.4" color="kitt.secondary" icon={<ArrowRightRegularIcon />} />}
            </HStack>
          </Button>
        </VStack>
      )}
    </VStack>
  );

  const Container = isDesktop ? HStack : VStack;

  return (
    <>
      <VStack
        alignItems="center"
        minHeight={{ base: 'calc(100vh - 10px)', large: 'calc(100vh - 60px);' }}
        paddingBottom="kitt.8"
      >
        {isDesktop && (
          <HStack width="100%" justifyContent="center" marginY="kitt.8">
            <Breadcrumbs />
          </HStack>
        )}
        <Container
          width="100%"
          maxWidth={isDesktop ? 576 : undefined}
          justifyContent="center"
          space={isDesktop ? 'kitt.8' : 'kitt.4'}
          marginTop={isDesktop ? 'kitt.4' : 0}
          marginBottom="kitt.8"
        >
          {illustrationCard}
          {informationConfirmationSection}
        </Container>
      </VStack>
      <ConstrainedWidth>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            delayPromise(async () => onConfirm(), 0);
          }}
        >
          <ButtonSection
            isSubmitButtonVisible={!isDesktop}
            submitButton={{
              label: 'Confirmer ces informations',
            }}
          />
        </form>
      </ConstrainedWidth>
    </>
  );
}
