import type { PaymentOptions, ServerPaymentOptions } from '../types/PaymentOptions';
import { PaymentPeriodicity, ServerPaymentPeriodicity } from '../types/PaymentOptions';

export const mapPaymentPeriodicityToServerPaymentPeriodicity = (
  paymentPeriodicity: PaymentPeriodicity,
): ServerPaymentPeriodicity => {
  switch (paymentPeriodicity) {
    case PaymentPeriodicity.ANNUALLY:
      return ServerPaymentPeriodicity.ANNUALLY;
    case PaymentPeriodicity.MONTHLY:
      return ServerPaymentPeriodicity.MONTHLY;
    default:
      throw new Error('Unknown paymentPeriodicity');
  }
};

export const mapPaymentOptionsToServerRequest = ({ paymentPeriodicity }: PaymentOptions): ServerPaymentOptions => {
  return {
    paymentPeriodicity: mapPaymentPeriodicityToServerPaymentPeriodicity(paymentPeriodicity),
  };
};
