import { VStack } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import type { Time } from '../../../utils/businessDates';
import type { CallbackScheduleItem } from '../../CallbackScheduleModule';
import { CallbackScheduleModule } from '../../CallbackScheduleModule';

export interface TimeSlotsScheduleProps {
  timeItems: CallbackScheduleItem<[Time, Time]>[];
  selectedItemId?: number;
  onPress: () => void;
}

export function TimeSlotsSchedule({ timeItems, selectedItemId, onPress }: TimeSlotsScheduleProps): ReactNode {
  return (
    <VStack space="kitt.6" width="100%">
      <CallbackScheduleModule
        title={<FormattedMessage id="callbackModuleModal.selectATimeSlot" defaultMessage="Choisissez un créneau" />}
        selectedItemId={selectedItemId}
        items={timeItems}
        onPress={onPress}
      />
    </VStack>
  );
}
