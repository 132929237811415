import { getRemoteQuoteFromPolicyCenter } from '../../../apis/getRemoteQuoteFromPolicyCenter';
import { sendAgentEmail } from '../../../apis/sendAgentEmail';
import { clearUrlSearchParam } from '../../../utils/urlSearchParam';
import { initializeContext } from '../../context';
import { State } from '../../types';
import { sanitizeRemoteQuote } from '../../validators';
import type { StateMachineInitContext } from '../types';
import { getLocaleMachineConfigService } from './getLocaleMachineConfig';
import { getVehicleInfo } from './getVehicleInfos';

export const fetchRemoteQuoteFromPolicyCenterService = async (
  context: StateMachineInitContext,
): Promise<Partial<StateMachineInitContext>> => {
  const { answers, tarificationResult } = await getRemoteQuoteFromPolicyCenter(
    context.submissionId!,
    context.submissionNumber!,
  );

  if (answers && tarificationResult) {
    if (context.agentEmail && tarificationResult.quoteId) {
      await sendAgentEmail(context.agentEmail, tarificationResult.quoteId);
    }

    const { quoteAnswers, reachedSummaryScreenSections } = sanitizeRemoteQuote(answers);
    const { availableVersions, loadedVehicleInfo } = await getVehicleInfo(
      answers.vehiculeMarque,
      answers.vehiculeModele,
      answers.vehiculePuissanceFiscale,
      answers.vehiculeCarburant,
    );

    return {
      config: {
        id: 'remote-machine',
        context: initializeContext({
          lastQuoteRequest: quoteAnswers,
          answers: quoteAnswers,
          availableCarburants: loadedVehicleInfo?.availableEnergies,
          availablePuissanceFiscales: loadedVehicleInfo?.availableHorsePower,
          availableVersions,
          tarificationResult,
          agentEmail: context.agentEmail,
          reachedSummaryScreenSections,
        }),
        stateValue: State.FORMULES,
      },
    };
  }

  clearUrlSearchParam();

  const { config } = await getLocaleMachineConfigService(context);

  return {
    ...context,
    config: {
      ...config!,
      stateValue: State.CANNOT_RETRIEVE_QUOTE,
    },
  };
};
