import { SubmitButton } from '@ornikar/react-forms-universal';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

export function ConfirmButton(): ReactNode {
  return (
    <SubmitButton stretch enableWhenPristine>
      <FormattedMessage id="callbackModuleModal.confirm" defaultMessage="Confirmer le rappel" />
    </SubmitButton>
  );
}
