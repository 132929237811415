import { addMonths, addYears } from 'date-fns';
import {
  Civility,
  FinancementType,
  LicenseType,
  LogementType,
  MaritalStatus,
  ParkingType,
  Profession,
  TitulaireCarteGriseType,
  UsageType,
} from '../../../apis/types/LongQuoteAnswers';
import { CountryCode } from '../../../utils/country';
import { formatDate } from '../../../utils/date';
import {
  ClaimQuantity,
  DeclareSecondaryDriverType,
  DriverHasBeenCovered,
  RegistrationDocumentOwnerIsSubscriberType,
  YesNoQuestion,
} from '../../answers';
import type { StateMachineConfig } from '../../types';
import { State } from '../../types';

export const YOUNG_DRIVER: Pick<StateMachineConfig, 'id' | 'context' | 'stateValue'> = {
  id: 'preFilled-YOUNG_DRIVER',
  context: {
    answers: {
      vehiculePreSelectionType: 1,
      vehiculeMarque: 'CITROEN',
      vehiculeModele: 'XSARA PICASSO',
      vehiculePuissanceFiscale: 5,
      vehiculeCarburant: 'GASOIL',
      vehiculeMiseEnCirculation: '2012-01-12',
      vehiculeImmatriculation: 'AC123AB',
      vehiculeVersion: 'CI41042',
      vehiculeVersionName: 'CITROEN XSARA PICASSO 1.6 HDI 92',
      vehiculeAcquisition: formatDate(new Date()),
      vehiculeFinancement: FinancementType.comptant,
      dateEffetSouhaite: formatDate(new Date()),
      primaryDriver: {
        licenseDate: formatDate(addMonths(new Date(), -21)),
        licenseType: LicenseType.french,
        accompaniedDriving: YesNoQuestion.YES,
        hasBeenCovered: DriverHasBeenCovered.OuiEnContinu,
        crm: 95,
        cancellationOrSuspensionRecords: [],
        hasBeenCancelledOrSuspended: YesNoQuestion.NO,
        claimQuantity: ClaimQuantity.None,
        claimRecords: [],
        firstName: 'Matéo',
        lastName: 'Lejeune',
        civility: Civility.Homme,
        birthDate: formatDate(addYears(new Date(), -22)),
        birthCity: 'Paris',
        birthCountryCode: CountryCode.FR,
        maritalStatus: MaritalStatus.Célibataire,
        profession: Profession.Salarié,
        hasTermination: YesNoQuestion.NO,
      },
      usage: UsageType.leisure,
      kilometersPerYear: 5000,
      parkingLocation: ParkingType.garage,
      parkingCodePostal: '33000',
      parkingCommune: 'BORDEAUX',
      housingType: LogementType.Appartement,
      isPropertyOwner: YesNoQuestion.NO,
      registrationDocumentOwnerIsSubscriber: RegistrationDocumentOwnerIsSubscriberType.Oui,
      titulaireCartegrise: TitulaireCarteGriseType.Souscripteur,
      declareSecondaryDriver: DeclareSecondaryDriverType.Non,
      secondaryDriver: {
        insurancePeriods: [],
        terminationRecords: [],
        cancellationOrSuspensionRecords: [],
        claimRecords: [],
      },
      subscriberPhone: '+33611223344',
      subscriberEmail: 'test.mateolejeune@ornikar.com',
      subscriberCompleteAdress: '1 Rue de la Paix, 75002 Paris, France',
      subscriberAdress1: '1 Rue de la Paix',
      subscriberCodePostal: '75002',
      subscriberCommune: 'Paris',
      privacyPolicyAccepted: true,
      hasAcceptedCommercialCommunication: false,
      hasAcceptedPartnersCommunication: false,
    },
    defaultAnswers: [],
    isLoading: false,
    availableBrands: [],
    availableModels: [],
    availableCarburants: ['ESSENCE', 'GASOIL', 'GNV', 'GPL'],
    availablePuissanceFiscales: [4, 5, 6, 7, 8, 10],
    availableVersions: [
      {
        version: '1.4 16V STEEL',
        energy: 'ESSENCE',
        tax_horsepower: 6,
        is_utility: false,
        id: 'FI26278',
      },
      {
        version: '1.4 MULTIAIR 105 EASY',
        energy: 'ESSENCE',
        tax_horsepower: 6,
        is_utility: false,
        id: 'FI26325',
      },
      {
        version: '1.4 MULTIAIR 105 LOUNGE',
        energy: 'ESSENCE',
        tax_horsepower: 6,
        is_utility: false,
        id: 'FI26328',
      },
      {
        version: "55 '6 SPEED'",
        energy: 'ESSENCE',
        tax_horsepower: 6,
        is_utility: false,
        id: 'FI26002',
      },
      {
        version: '55 6 SPEED',
        energy: 'ESSENCE',
        tax_horsepower: 6,
        is_utility: false,
        id: 'FI26035',
      },
      {
        version: '55S',
        energy: 'ESSENCE',
        tax_horsepower: 6,
        is_utility: true,
        id: 'FI26018',
      },
      {
        version: '55 S',
        energy: 'ESSENCE',
        tax_horsepower: 6,
        is_utility: true,
        id: 'FI26053',
      },
      {
        version: '60',
        energy: 'ESSENCE',
        tax_horsepower: 6,
        is_utility: false,
        id: 'FI26028',
      },
      {
        version: '75 ELX',
        energy: 'ESSENCE',
        tax_horsepower: 6,
        is_utility: false,
        id: 'FI26014',
      },
      {
        version: '75 HSD',
        energy: 'ESSENCE',
        tax_horsepower: 6,
        is_utility: false,
        id: 'FI26021',
      },
      {
        version: '75 SX',
        energy: 'ESSENCE',
        tax_horsepower: 6,
        is_utility: false,
        id: 'FI26017',
      },
      {
        version: '85 ELX 16V',
        energy: 'ESSENCE',
        tax_horsepower: 6,
        is_utility: false,
        id: 'FI26090',
      },
      {
        version: '85 STILE 16V',
        energy: 'ESSENCE',
        tax_horsepower: 6,
        is_utility: false,
        id: 'FI26124',
      },
      {
        version: '85 STILE 16V CLIM',
        energy: 'ESSENCE',
        tax_horsepower: 6,
        is_utility: false,
        id: 'FI26125',
      },
      {
        version: '85 SX 16V',
        energy: 'ESSENCE',
        tax_horsepower: 6,
        is_utility: false,
        id: 'FI26094',
      },
      {
        version: '95 16V CLASS',
        energy: 'ESSENCE',
        tax_horsepower: 6,
        is_utility: false,
        id: 'FI26230',
      },
      {
        version: '95 16V EMOTION',
        energy: 'ESSENCE',
        tax_horsepower: 6,
        is_utility: false,
        id: 'FI26232',
      },
      {
        version: '95 16V SPORTING',
        energy: 'ESSENCE',
        tax_horsepower: 6,
        is_utility: false,
        id: 'FI26234',
      },
      {
        version: 'CABRIO 16V',
        energy: 'ESSENCE',
        tax_horsepower: 6,
        is_utility: false,
        id: 'FI26096',
      },
      {
        version: 'CABRIO 85 ELX',
        energy: 'ESSENCE',
        tax_horsepower: 6,
        is_utility: false,
        id: 'FI26092',
      },
      {
        version: 'GT',
        energy: 'ESSENCE',
        tax_horsepower: 6,
        is_utility: false,
        id: 'FI26016',
      },
      {
        version: 'S',
        energy: 'ESSENCE',
        tax_horsepower: 6,
        is_utility: false,
        id: 'FI26049',
      },
    ],
    hasReachedRestitution: true,
    isDisplayingFicNotification: false,
    hasReceivedQuoteMailNotification: false,
    reachedSummaryScreenSections: {
      VEHICULE: true,
      DRIVING: true,
      PRIMARY_DRIVER: true,
      SECONDARY_DRIVER: true,
    },
    editingInsuranceRecord: {
      index: 0,
    },
  },
  stateValue: State.INFORMATION_PRIMARY_DETAILS,
};
