import { ArrowCounterClockwiseRegularIcon } from '@ornikar/kitt-icons/phosphor';
import { HStack, IconButton, InputFeedback, Typography, VStack, View } from '@ornikar/kitt-universal';
import type { FieldProps } from '@ornikar/react-forms';
import * as Sentry from '@sentry/react';
import debounce from 'lodash/debounce';
import type { ReactNode } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { api } from '../../../apis/api';
import { Dropdown } from '../../../components/Dropdown';
import { Field } from '../../../components/Field';
import { useCommuneValidator } from '../../../forms/validation/sections/driving/commune';
import { sendNoOptionsFoundEvent } from '../../../utils/mixpanel';
import styles from './styles.module.css';

const doFetchCommunes = async (codePostal: string): Promise<string[]> => {
  const items = await api<{ commune: string }[]>(`communes/${codePostal}`);

  return items.map(({ commune }) => commune);
};

enum Status {
  default = 'default',
  loading = 'loading',
  success = 'success',
  failure = 'failure',
  empty = 'empty',
}

function HiddenParkingCommuneField({ validate }: { validate: FieldProps<any, Record<string, string>>['validate'] }) {
  return (
    <Field component={Dropdown} choices={[]} name="parkingCommune" validate={validate} className={styles.Hidden} />
  );
}

export function CommunesField(): ReactNode {
  const fetchingId = useRef(0);
  const [communes, setCommunes] = useState<string[]>([]);
  const [status, setStatus] = useState<Status>(Status.default);

  const { change, blur } = useForm();
  const { values, errors } = useFormState({
    subscription: { values: true, errors: true },
  });

  const communeValidator = useCommuneValidator();

  const { parkingCodePostal: codePostal } = values;
  const isValidCodePostal = errors?.parkingCodePostal === undefined && codePostal?.length === 5;

  const debouncedFetch = debounce(async (id: number, cp: string) => {
    try {
      setStatus(Status.loading);
      setCommunes([]);
      const localCommunes = await doFetchCommunes(cp);
      if (id === fetchingId.current) {
        if (localCommunes.length === 0) {
          setStatus(Status.empty);
          sendNoOptionsFoundEvent('parkingCommune');
        } else {
          setCommunes(localCommunes);
          setStatus(Status.success);
          if (localCommunes.length === 1) {
            // prefill city if unique
            change('parkingCommune', localCommunes[0]);
            // trigger validation
            blur('parkingCommune');
          }
        }
      }
    } catch (error) {
      Sentry.captureException(error);
      setStatus(Status.failure);
    }
  }, 500);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchCommunes = useCallback(debouncedFetch as (id: number, cp: string) => void, []);

  const refreshCommunes = useCallback(() => {
    if (isValidCodePostal) {
      fetchingId.current += 1;
      fetchCommunes(fetchingId.current, codePostal as string);
    }
  }, [isValidCodePostal, fetchCommunes, codePostal]);

  useEffect(() => {
    if (isValidCodePostal) {
      fetchCommunes(fetchingId.current, codePostal as string);
    } else {
      setStatus(Status.default);
      setCommunes([]);
    }
    return () => {
      fetchingId.current += 1;
    };
  }, [codePostal, fetchCommunes, isValidCodePostal]);

  if (status === Status.default) {
    return (
      <VStack>
        <View paddingBottom={10}>
          <Typography.Text>Ville</Typography.Text>
        </View>
        <HStack
          justifyContent="space-between"
          alignItems="center"
          height="min-content"
          minHeight="kitt.10"
          paddingLeft={15}
          backgroundColor="#f2f2f2"
          borderColor="#e5e5e5"
          borderWidth={2}
          borderRadius="kitt.2"
        >
          <Typography.Text color="#828282">Choisir la commune</Typography.Text>
        </HStack>
        {/* HiddenField is used to trigger the validation */}
        <HiddenParkingCommuneField validate={communeValidator} />
      </VStack>
    );
  }

  if (status === Status.failure || status === Status.empty) {
    return (
      <VStack>
        <View paddingBottom={10}>
          <Typography.Text>Ville</Typography.Text>
        </View>
        <HStack
          justifyContent="space-between"
          alignItems="center"
          height="min-content"
          minHeight="kitt.10"
          paddingLeft={15}
          backgroundColor="#f2f2f2"
          borderColor="#e5e5e5"
          borderWidth={2}
          borderRadius="kitt.2"
        >
          <Typography.Text color="#828282">
            {status === Status.failure ? 'Erreur' : 'Aucune ville trouvée'}
          </Typography.Text>
          {status === Status.failure && (
            <IconButton icon={<ArrowCounterClockwiseRegularIcon />} onPress={refreshCommunes} />
          )}
        </HStack>
        {errors?.parkingCommune ? (
          <div className={styles.ErrorMessage}>
            <InputFeedback state="invalid">{errors.parkingCommune.message}</InputFeedback>
          </div>
        ) : null}
        {/* HiddenField is used to trigger the validation */}
        <HiddenParkingCommuneField validate={communeValidator} />
      </VStack>
    );
  }

  return (
    <Field
      component={Dropdown}
      choices={communes.map((item) => ({ value: item, label: item }))}
      name="parkingCommune"
      label="Ville"
      placeholder={status === Status.loading ? 'Chargement...' : 'Choisir la commune'}
      validate={communeValidator}
    />
  );
}
