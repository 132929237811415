import type { VStackProps } from '@ornikar/kitt-universal';
import { Typography, VStack } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';

interface FieldProps {
  name: string;
  value: string;
  marginBottom?: VStackProps['marginBottom'];
}

export function Field({ name, value, marginBottom }: FieldProps): ReactNode {
  return (
    <VStack space="kitt.1" marginBottom={marginBottom}>
      <Typography.Paragraph base="body">{name}</Typography.Paragraph>
      <Typography.Paragraph base="body" variant="bold">
        {value}
      </Typography.Paragraph>
    </VStack>
  );
}
