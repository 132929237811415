import type { MachineConfig } from 'xstate';
import { createMachine } from 'xstate';
import { sendErrorToSentry } from '../actions/utils';
import type { Context } from '../types';
import { State } from '../types';
import { setContext } from './actions/setContext';
import { isFetched, isRemoteQuoteFromCoreApi, isRemoteQuoteFromPolicyCenter } from './guards/remoteQuote';
import { fetchRemoteQuoteFromCoreApiService } from './services/fetchRemoteQuoteFromCoreApi';
import { fetchRemoteQuoteFromPolicyCenterService } from './services/fetchRemoteQuoteFromPolicyCenter';
import { getLocaleMachineConfigService } from './services/getLocaleMachineConfig';
import { getUrlSearchParamsService } from './services/getUrlSearchParams';
import { overrideStateMachineConfig } from './services/overrideStateMachineConfig';
import { sendVisit } from './services/sendVisit';
import type { StateMachineInitContext, StateMachineInitDispatch, StateMachineInitSchema } from './types';
import { StateMachineInitState } from './types';

export const initialContext: StateMachineInitContext = {
  config: {
    id: '',
    context: {} as Context,
    stateValue: State.VEHICULE_SEARCH_CHOICE,
  },
  isFetched: false,
  leadId: null,
  quoteId: null,
  submissionId: null,
  submissionNumber: null,
};

const stateMachineInitConfig: MachineConfig<StateMachineInitContext, StateMachineInitSchema, StateMachineInitDispatch> =
  {
    initial: StateMachineInitState.GETTING_URL_SEARCH_PARAMS,
    context: initialContext,
    states: {
      [StateMachineInitState.GETTING_URL_SEARCH_PARAMS]: {
        invoke: {
          src: getUrlSearchParamsService,
          onDone: {
            target: StateMachineInitState.SENDING_VISIT,
            actions: setContext,
          },
          onError: {
            actions: [sendErrorToSentry],
            target: StateMachineInitState.END,
          },
        },
      },
      [StateMachineInitState.SENDING_VISIT]: {
        invoke: {
          src: sendVisit,
          onDone: {
            target: StateMachineInitState.CONFIG_OVERRIDE,
            actions: setContext,
          },
          onError: {
            actions: [sendErrorToSentry],
            target: StateMachineInitState.END,
          },
        },
      },
      [StateMachineInitState.CONFIG_OVERRIDE]: {
        invoke: {
          src: overrideStateMachineConfig,
          onDone: [
            {
              target: StateMachineInitState.FETCHING_REMOTE_QUOTE_FROM_POLICY_CENTER,
              cond: isRemoteQuoteFromPolicyCenter,
              actions: setContext,
            },
            {
              target: StateMachineInitState.FETCHING_REMOTE_QUOTE_FROM_CORE_API,
              cond: isRemoteQuoteFromCoreApi,
              actions: setContext,
            },
            {
              target: StateMachineInitState.GETTING_LOCAL_MACHINE_CONFIG,
              actions: setContext,
            },
          ],
          onError: {
            actions: [sendErrorToSentry],
            target: StateMachineInitState.END,
          },
        },
      },
      [StateMachineInitState.FETCHING_REMOTE_QUOTE_FROM_CORE_API]: {
        invoke: {
          src: fetchRemoteQuoteFromCoreApiService,
          onDone: [
            {
              target: StateMachineInitState.END,
              cond: isFetched,
              actions: setContext,
            },
            {
              target: StateMachineInitState.GETTING_LOCAL_MACHINE_CONFIG,
              actions: setContext,
            },
          ],
          onError: {
            target: StateMachineInitState.GETTING_LOCAL_MACHINE_CONFIG,
            actions: [sendErrorToSentry, setContext],
          },
        },
      },
      [StateMachineInitState.FETCHING_REMOTE_QUOTE_FROM_POLICY_CENTER]: {
        invoke: {
          src: fetchRemoteQuoteFromPolicyCenterService,
          onDone: {
            target: StateMachineInitState.END,
            actions: setContext,
          },
          onError: {
            target: StateMachineInitState.GETTING_LOCAL_MACHINE_CONFIG,
            actions: [sendErrorToSentry, setContext],
          },
        },
      },
      [StateMachineInitState.GETTING_LOCAL_MACHINE_CONFIG]: {
        invoke: {
          src: getLocaleMachineConfigService,
          onDone: {
            target: StateMachineInitState.END,
            actions: setContext,
          },
          onError: {
            target: StateMachineInitState.END,
            actions: [sendErrorToSentry],
          },
        },
      },
      [StateMachineInitState.END]: { type: 'final' },
    },
  };

export const stateMachineInitMachine = createMachine(stateMachineInitConfig);
