import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';
import { useSubscriptionFsmState } from '../fsm/context';
import type { State } from '../fsm/types';
import { MAPPED_STATES_TO_SECTIONS } from '../fsm/types';

export function useRegisterDataToMixpanel(): void {
  const state = useSubscriptionFsmState();

  useEffect(() => {
    const insuranceSubscriptionProperty = mixpanel.get_property('insurance_subscription');

    mixpanel.register({
      insurance_subscription: {
        ...insuranceSubscriptionProperty,
        step_slug: MAPPED_STATES_TO_SECTIONS[state.value as State] || 'none',
        state_slug: state.value,
        email: state.context?.answers.subscriberEmail,
      },
    });
  }, [state.context, state.value]);
}
