import type { ReactNode } from 'react';
import { RadioScreenTemplate } from '../../../../components/ScreenTemplates/RadioScreenTemplate';
import { secondaryDriverTypeOptionsList } from '../../../../constants/mappers/information';

export function SecondaryRelationScreen(): ReactNode {
  return (
    <RadioScreenTemplate
      title="Il s'agit de..."
      fieldName="secondaryRelation"
      choices={secondaryDriverTypeOptionsList}
      highlightTitle="Précision importante"
      highlightContent="Cette information est vérifiée lors de l'envoi des pièces justificatives obligatoires demandées par votre assureur."
    />
  );
}
