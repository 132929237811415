import { HStack, View } from '@ornikar/kitt-universal';
import { type ReactNode, useState } from 'react';
import { OrnikarPackageLevel } from '../../../../../apis/types/Formula';
import type { Package, PackageCoverage } from '../../../../../apis/types/Formula';
import type { PaymentPeriodicity } from '../../../../../apis/types/PaymentOptions';
import { CoverageInfoModal } from './components/CoverageInfoModal';
import { Tab } from './components/Tab';

export interface FormulaTabsDesktopProps {
  packages: Package[];
  paymentPeriodicity: PaymentPeriodicity;
  onClick: (formule: Package) => void;
  formuleSelected?: Package;
  isLoading?: boolean;
  onCoverageViewed: (label: string) => void;
}

export function FormulesTabsDesktop({
  packages,
  paymentPeriodicity,
  onClick,
  formuleSelected,
  isLoading = false,
  onCoverageViewed,
}: FormulaTabsDesktopProps): ReactNode {
  const coverages = packages.find((current) => current.name === OrnikarPackageLevel.ALL_RISK)?.coverages;
  const [coverage, setCoverage] = useState<PackageCoverage | undefined>(undefined);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showInfoModal = (currentCoverage: PackageCoverage): void => {
    setCoverage(currentCoverage);
    setIsModalVisible(true);
    onCoverageViewed(currentCoverage.label);
  };

  const closeModal = (): void => {
    setIsModalVisible(false);
  };

  return (
    <View>
      <HStack justifyContent="space-between" space="kitt.8">
        {packages?.map((formula) => (
          <Tab
            key={formula.name}
            formula={formula}
            coverages={coverages}
            showInfoModal={showInfoModal}
            paymentPeriodicity={paymentPeriodicity}
            selected={(!formuleSelected && formula.isRecommended) || formuleSelected === formula}
            isLoading={isLoading}
            onClick={onClick}
          />
        ))}
      </HStack>
      <CoverageInfoModal coverage={coverage} isVisible={isModalVisible} onClose={closeModal} />
    </View>
  );
}
