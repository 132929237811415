import type { IntlValidator } from '@ornikar/react-validators';
import { useComposeValidators, useDateValidator, useRequiredValidator, useValidator } from '@ornikar/react-validators';
import { createValidDateBeforeValidator } from '@ornikar/validators';
import { addDays, format } from 'date-fns';
import { defineMessages } from 'react-intl';
import { validatorMessages } from '../../messages';

const messages = defineMessages({
  dateBeforeValidator: {
    id: 'VehiculeSelectionScreen.dateCirculationBeforeDateValidator',
    defaultMessage:
      "Veuillez rentrer une date avant la date d'achat du véhicule, ou avant aujourd'hui si vous ne l'avez pas encore renseigné.",
  },
});

const useDateBeforeCirculation = (vehiculeAcquisition: string | undefined): IntlValidator<string> => {
  return useValidator(
    createValidDateBeforeValidator(() => {
      const maxDate = addDays(new Date(vehiculeAcquisition || Date.now()), 1);
      return format(maxDate, 'yyyy-MM-dd');
    }),
    messages.dateBeforeValidator,
  );
};

export const useVehiculeMiseEnCirculationValidator = (vehiculeAcquisition: string): IntlValidator<string> => {
  return useComposeValidators(
    useRequiredValidator(),
    useDateValidator(validatorMessages.dateFormatValidator),
    useDateBeforeCirculation(vehiculeAcquisition!),
  );
};
