/**
 * Clear a given search param or all search params if no `paramName` is passed
 *
 * @param paramName - Search param to clear. If undefined, all search params are clear
 */
export const clearUrlSearchParam = (paramName?: string): void => {
  const searchParams = new URLSearchParams(window.location.search);

  let url = window.location.pathname;
  if (paramName) {
    searchParams.delete(paramName);

    if (searchParams.toString()) {
      url += `?${searchParams.toString()}`;
    }
  }
  window.history.replaceState('', '', url);
};

export const replaceUrlSearchParam = (paramName: string, paramValue: string): void => {
  const searchParams = new URLSearchParams(window.location.search);

  searchParams.set(paramName, paramValue);

  const url = `${window.location.pathname}?${searchParams.toString()}`;
  window.history.replaceState('', '', url);
};
