import type { ReactNode } from 'react';
import { useSubscriptionFsmStateValue } from '../../fsm/context';
import type { State } from '../../fsm/types';

// Allow AB Tasty to identify the current screen
export function ABTastyIdentifier(): ReactNode {
  const state = useSubscriptionFsmStateValue() as State;

  return <div id="ab-tasty-selector" className={state} />;
}
