import { useComposeValidators, useDateValidator, useRequiredValidator } from '@ornikar/react-validators';
import type { ReactNode } from 'react';
import { DateField } from '../../../components/DateField';
import { ScreenTemplateWithValidation } from '../../../components/ScreenTemplates/ScreenTemplateWithValidation';
import {
  useDateAfterNYearsAgoValidator,
  // useDateBeforeTomorrowValidator,
} from '../../../forms/validation/sharedValidators';
import type { DriverScreenProps } from '../../types/DriverScreenProps';

const useTerminationRecordDateValidator = () =>
  useComposeValidators(
    useRequiredValidator(),
    useDateValidator(),
    useDateAfterNYearsAgoValidator(3),
    /**
     * We authorize dates in the future for Sales to resubscribe
     * Ornikar customer with A1 and April contracts.
     * https://ornikar.atlassian.net/browse/ING-986
     * TODO [>1]: We must uncomment this validator when it's done.
     */
    // useDateBeforeTomorrowValidator(),
  );

export function TerminationRecordDateScreen({ driver }: DriverScreenProps): ReactNode {
  const validator = useTerminationRecordDateValidator();
  const fieldName = `${driver}Driver.terminationRecords[0].date`;

  return (
    <ScreenTemplateWithValidation title="A quelle date&nbsp;?" fieldNames={[fieldName]}>
      <DateField name={fieldName} validate={validator} />
    </ScreenTemplateWithValidation>
  );
}
