import type { ReactNode } from 'react';
import { CancellationRecordHitAndRunTemplate } from '../../../../components/ScreenTemplates/InformationTemplates/InsuranceRecordTemplates/CancellationRecordTemplates/CancellationRecordHitAndRunTemplate';

export function SecondaryTerminationRecordLicenseCanceledOrSuspendedHitAndRunScreen(): ReactNode {
  return (
    <CancellationRecordHitAndRunTemplate
      title="A-t-il commis un délit de fuite ou refusé d'obtempérer&nbsp;?"
      fieldName="secondaryDriver.terminationRecords[0].cancellationOrSuspensionRecord.isHitAndRun"
    />
  );
}
