import { PlusRegularIcon } from '@ornikar/kitt-icons/phosphor';
import { Icon, Typography, View } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';

export function Separator(): ReactNode {
  return (
    <View
      marginTop="-kitt.10"
      marginBottom="kitt.10"
      borderTopColor="kitt.palettes.lateOcean.lateOceanLight2"
      borderTopWidth="1"
      height="0"
      alignItems="center"
    >
      <View
        marginTop="-10"
        backgroundColor="kitt.palettes.lateOcean.lateOceanLight1"
        width="kitt.30"
        alignItems="center"
      >
        <Typography.Paragraph>
          <Icon icon={<PlusRegularIcon />} color="kitt.palettes.lateOcean.lateOceanLight2" />{' '}
        </Typography.Paragraph>
      </View>
    </View>
  );
}
