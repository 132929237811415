import type { ServerVehicleLegalInformation, VehicleLegalInformation } from '../types/VehicleLegalInformation';

export function vehicleLegalInformationToServerRequest({
  licensePlate,
  odometer,
}: VehicleLegalInformation): ServerVehicleLegalInformation {
  return {
    licensePlate,
    odometer,
  };
}
