import { Typography } from '@ornikar/kitt-universal';
import cx from 'classnames';
import type { PropsWithChildren, ReactNode } from 'react';
import { useCallback } from 'react';
import { onEnterKeyPressed } from '../../utils/keyboard';
import styles from './styles.module.css';

type ToggleProps = PropsWithChildren<{
  value: boolean;
  onChange: (newValue: boolean) => void;
}>;

export function Toggle({ value, onChange, children }: ToggleProps): ReactNode {
  const toggleValue = useCallback(() => {
    onChange(!value);
  }, [value, onChange]);

  return (
    <div className={cx(styles.Toggle, { [styles.Checked]: value })}>
      <Typography.Text
        variant="bold"
        onPress={() => {
          toggleValue();
        }}
      >
        {children}
      </Typography.Text>
      <div
        className={styles.Capsule}
        role="button"
        tabIndex={0}
        aria-label="Toggle"
        onKeyDown={(e) => {
          onEnterKeyPressed(e, toggleValue);
        }}
        onClick={() => {
          toggleValue();
        }}
      >
        <div className={styles.Circle} />
      </div>
    </div>
  );
}
