import { XRegularIcon } from '@ornikar/kitt-icons/phosphor';
import { CardModal, HStack, IconButton, NavigationModal, Typography, VStack, View } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { useEffect } from 'react';
import { Query, useMediaQuery } from '../../hooks/useMediaQuery';
import { sendEvent } from '../../utils/mixpanel';
import { AddonInfoModalContentItem } from './components/AddonInfoModalContentItem';
import type { AddonInfoModalContentItemProps } from './components/AddonInfoModalContentItem';

interface InfoModalProps {
  addonName: string;
  isVisible: boolean;
  title: ReactNode;
  subtitle: ReactNode;
  subtitleIcon: ReactNode;
  items: AddonInfoModalContentItemProps[];
  onClose: () => void;
}

export function AddonInfoModal({
  addonName,
  isVisible,
  title,
  subtitle,
  subtitleIcon,
  items = [],
  onClose,
}: InfoModalProps): ReactNode {
  const isMedium = useMediaQuery(Query.MEDIUM);

  useEffect(() => {
    if (isVisible) {
      sendEvent('Learn More About Option', {
        option_name: addonName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const modalHeader = (
    <View minWidth="100%">
      <Typography.Paragraph variant="bold">{title}</Typography.Paragraph>
    </View>
  );

  const modalBody = (
    <View minWidth="100%">
      <HStack alignItems="center" space="kitt.2" paddingX="kitt.2" marginBottom="30px">
        {subtitleIcon}
        <View flex="1 1 auto">
          <Typography.Text base="body" variant="bold">
            {subtitle}
          </Typography.Text>
        </View>
      </HStack>

      <VStack space="kitt.6">
        {items.map((item, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <AddonInfoModalContentItem key={idx} {...item} />
        ))}
      </VStack>
    </View>
  );

  return isMedium ? (
    <CardModal.ModalBehaviour visible={isVisible} onClose={onClose}>
      <CardModal>
        <CardModal.Header right={<IconButton icon={<XRegularIcon />} onPress={() => onClose()} />} title={title} />
        <CardModal.Body overflowY="auto" maxHeight="70vh">
          {modalBody}
        </CardModal.Body>
      </CardModal>
    </CardModal.ModalBehaviour>
  ) : (
    <NavigationModal.ModalBehaviour visible={isVisible} onClose={onClose}>
      <NavigationModal
        header={
          <NavigationModal.Header right={<IconButton icon={<XRegularIcon />} onPress={onClose} />}>
            {modalHeader}
          </NavigationModal.Header>
        }
        body={
          <NavigationModal.Body overflowY="auto" height="90vh">
            {modalBody}
          </NavigationModal.Body>
        }
      />
    </NavigationModal.ModalBehaviour>
  );
}
