import { format, isBefore, startOfDay } from 'date-fns';
import { sortPeriods } from '../apis/computeInsuranceHistory';
import { PaymentStatus } from '../apis/payment';
import { SubscribeStatus } from '../apis/types';
import { DejaAssureAvecNomType } from '../apis/types/LongQuoteAnswers';
import type { Version } from '../apis/vehicules';
import { getVersionDetails } from '../apis/vehicules';
import {
  COMPANY_FUNDING_TYPES,
  REQUIRED_REGISTRATION_DOCUMENT_OWNERS_FOR_COMPANY_FUNDING,
} from '../forms/validation/sections/driving/registrationDocumentOwner';
import { THREE_YEARS_AGO } from '../forms/validation/sections/informations/insurancePeriodValidator';
import { removeNilValuesInObject } from '../utils/object';
import { DeclareSecondaryDriverType, DriverHasBeenCovered, VehiculePreSelectionType } from './answers';
import type { LongQuoteAnswers } from './answers';
import type { Section, StateMachineConfig } from './types';
import { State as StateValue } from './types';

const TODAY = startOfDay(new Date());

export const isBeforeToday = (date: string): boolean => isBefore(new Date(date), TODAY);

const verifyQuoteStateValue = (
  answers: Partial<LongQuoteAnswers>,
): {
  answers?: Partial<LongQuoteAnswers>;
  stateValue: StateValue;
  reachedSummaryScreenSections: Partial<Record<Section, boolean>>;
  hasReachedRestitution: boolean;
  availableVersions?: Version[];
} => {
  let reachedSummaryScreenSections = {
    VEHICULE: false,
    DRIVING: false,
    PRIMARY_DRIVER: false,
    SECONDARY_DRIVER: false,
  };

  let hasReachedRestitution = false;

  if (answers.vehiculeMarque === undefined) {
    if (answers.vehiculePreSelectionType === VehiculePreSelectionType.BRAND) {
      return {
        reachedSummaryScreenSections,
        hasReachedRestitution,
        stateValue: StateValue.VEHICULE_BRAND,
      };
    }

    if (answers.vehiculePreSelectionType === VehiculePreSelectionType.LICENSE_PLATE) {
      return {
        reachedSummaryScreenSections,
        hasReachedRestitution,
        stateValue: StateValue.VEHICULE_LICENSE_PLATE,
      };
    }

    return {
      reachedSummaryScreenSections,
      hasReachedRestitution,
      stateValue: StateValue.VEHICULE_SEARCH_CHOICE,
    };
  }
  if (answers.vehiculeModele === undefined) {
    return {
      reachedSummaryScreenSections,
      hasReachedRestitution,
      stateValue: StateValue.VEHICULE_MODEL,
    };
  }
  if (answers.vehiculePuissanceFiscale === undefined) {
    return {
      reachedSummaryScreenSections,
      hasReachedRestitution,
      stateValue: StateValue.VEHICULE_PUISSANCE_FISCALE,
    };
  }
  if (answers.vehiculeCarburant === undefined) {
    return {
      reachedSummaryScreenSections,
      hasReachedRestitution,
      stateValue: StateValue.VEHICULE_CARBURANT,
    };
  }
  if (answers.vehiculeMiseEnCirculation === undefined) {
    return {
      reachedSummaryScreenSections,
      hasReachedRestitution,
      stateValue: StateValue.VEHICULE_MISE_EN_CIRCULATION,
    };
  }
  if (answers.vehiculeVersion === undefined || answers.vehiculeIsUtility === undefined) {
    return {
      reachedSummaryScreenSections,
      hasReachedRestitution,
      stateValue: StateValue.VEHICULE_VERSION,
    };
  }

  if (answers.vehiculeAcquisition === undefined) {
    return {
      reachedSummaryScreenSections,
      hasReachedRestitution,
      stateValue: StateValue.VEHICULE_ACQUISITON_DATE,
    };
  }

  if (answers.dateEffetSouhaite === undefined || isBeforeToday(answers.dateEffetSouhaite)) {
    return {
      answers: {
        ...answers,
        dateEffetSouhaite: undefined,
      },
      reachedSummaryScreenSections,
      hasReachedRestitution,
      stateValue: StateValue.VEHICULE_COVERING_DATE,
    };
  }

  const vehicleAlreadyInPossession = isBeforeToday(answers.dateEffetSouhaite);

  if (vehicleAlreadyInPossession && answers.vehiculeDejaAssureAvecNom === undefined) {
    return {
      reachedSummaryScreenSections,
      hasReachedRestitution,
      stateValue: StateValue.VEHICULE_IS_COVERED,
    };
  }

  if (
    vehicleAlreadyInPossession &&
    answers.vehiculeDejaAssureAvecNom === DejaAssureAvecNomType.OuiAMonNom &&
    answers.vehiculeAssureDepuis === undefined
  ) {
    return {
      reachedSummaryScreenSections,
      hasReachedRestitution,
      stateValue: StateValue.VEHICULE_COVERED_SINCE,
    };
  }

  if (
    vehicleAlreadyInPossession &&
    answers.vehiculeDejaAssureAvecNom === DejaAssureAvecNomType.Non &&
    answers.vehicleLastInsuranceEndDate === undefined
  ) {
    return {
      reachedSummaryScreenSections,
      hasReachedRestitution,
      stateValue: StateValue.VEHICLE_LAST_INSURANCE_END_DATE,
    };
  }

  if (answers.vehiculeFinancement === undefined) {
    return {
      reachedSummaryScreenSections,
      hasReachedRestitution,
      stateValue: StateValue.VEHICULE_FUNDING,
    };
  }

  /**
   * Driving
   */

  reachedSummaryScreenSections = {
    ...reachedSummaryScreenSections,
    VEHICULE: true,
  };

  if (answers.primaryDriver?.licenseDate === undefined) {
    return {
      reachedSummaryScreenSections,
      hasReachedRestitution,
      stateValue: StateValue.DRIVING_PRIMARY_LICENSE_DATE,
    };
  }

  if (answers.primaryDriver?.accompaniedDriving === undefined) {
    return {
      reachedSummaryScreenSections,
      hasReachedRestitution,
      stateValue: StateValue.DRIVING_PRIMARY_WAS_ACCOMPANIED,
    };
  }

  if (answers.usage === undefined) {
    return {
      reachedSummaryScreenSections,
      hasReachedRestitution,
      stateValue: StateValue.DRIVING_VEHICULE_USAGE,
    };
  }

  if (answers.kilometersPerYear === undefined) {
    return {
      reachedSummaryScreenSections,
      hasReachedRestitution,
      stateValue: StateValue.DRIVING_KILOMETERS_PER_YEAR,
    };
  }

  if (answers.parkingLocation === undefined) {
    return {
      reachedSummaryScreenSections,
      hasReachedRestitution,
      stateValue: StateValue.DRIVING_VEHICULE_PARKING,
    };
  }

  if (answers.parkingCodePostal === undefined || answers.parkingCommune === undefined) {
    return {
      reachedSummaryScreenSections,
      hasReachedRestitution,
      stateValue: StateValue.DRIVING_CITY,
    };
  }

  if (answers.registrationDocumentOwnerIsSubscriber === undefined || answers.titulaireCartegrise === undefined) {
    return {
      reachedSummaryScreenSections,
      hasReachedRestitution,
      stateValue: StateValue.DRIVING_REGISTRATION_DOCUMENT_OWNER_IS_SUBSCRIBER,
    };
  }

  if (
    COMPANY_FUNDING_TYPES.includes(answers.vehiculeFinancement) &&
    !REQUIRED_REGISTRATION_DOCUMENT_OWNERS_FOR_COMPANY_FUNDING.includes(answers.titulaireCartegrise)
  ) {
    return {
      reachedSummaryScreenSections,
      hasReachedRestitution,
      stateValue: StateValue.DRIVING_REGISTRATION_DOCUMENT_OWNER_IS_SUBSCRIBER,
    };
  }

  /**
   * INFORMATION
   */

  reachedSummaryScreenSections = {
    ...reachedSummaryScreenSections,
    DRIVING: true,
  };

  const primaryHasBeenCovered = answers.primaryDriver?.hasBeenCovered;

  if (primaryHasBeenCovered === undefined) {
    return {
      reachedSummaryScreenSections,
      hasReachedRestitution,
      stateValue: StateValue.INFORMATION_PRIMARY_HAS_BEEN_COVERED,
    };
  }

  const FORMATTED_THREE_YEARS_AGO = format(THREE_YEARS_AGO, 'yyyy-MM-dd');
  const primaryInsurancePeriods = answers.primaryDriver?.insurancePeriods;
  const firstPeriodStartDate =
    primaryInsurancePeriods && primaryInsurancePeriods.length > 0 ? sortPeriods(primaryInsurancePeriods)[0].start : '';

  if (
    primaryHasBeenCovered === DriverHasBeenCovered.OuiPartiellement &&
    (primaryInsurancePeriods === undefined ||
      primaryInsurancePeriods?.length === 0 ||
      FORMATTED_THREE_YEARS_AGO.localeCompare(firstPeriodStartDate) > 0)
  ) {
    return {
      reachedSummaryScreenSections,
      hasReachedRestitution,
      stateValue: StateValue.INFORMATION_PRIMARY_HAS_BEEN_COVERED,
    };
  }

  if (
    (primaryHasBeenCovered === DriverHasBeenCovered.OuiPartiellement ||
      primaryHasBeenCovered === DriverHasBeenCovered.OuiEnContinu ||
      primaryHasBeenCovered === DriverHasBeenCovered.NonSurPeriode) &&
    answers.primaryDriver?.crm === undefined
  ) {
    return {
      reachedSummaryScreenSections,
      hasReachedRestitution,
      stateValue: StateValue.INFORMATION_PRIMARY_BONUS_MALUS,
    };
  }

  if (
    (primaryHasBeenCovered === DriverHasBeenCovered.OuiPartiellement ||
      primaryHasBeenCovered === DriverHasBeenCovered.OuiEnContinu ||
      primaryHasBeenCovered === DriverHasBeenCovered.NonSurPeriode) &&
    answers.primaryDriver?.crm === 50 &&
    answers.primaryDriver?.yearsSinceHighestBonus === undefined
  ) {
    return {
      reachedSummaryScreenSections,
      hasReachedRestitution,
      stateValue: StateValue.INFORMATION_PRIMARY_BONUS50,
    };
  }

  reachedSummaryScreenSections = {
    ...reachedSummaryScreenSections,
    PRIMARY_DRIVER: true,
  };

  if (answers.declareSecondaryDriver === undefined) {
    return {
      reachedSummaryScreenSections,
      hasReachedRestitution,
      stateValue: StateValue.INFORMATION_DECLARE_SECONDARY_DRIVER,
    };
  }

  const { secondaryDriver } = answers;
  const secondaryHasBeenCovered = secondaryDriver?.hasBeenCovered;

  if (
    answers.declareSecondaryDriver === DeclareSecondaryDriverType.Oui &&
    (secondaryDriver?.firstName === undefined ||
      secondaryDriver?.lastName === undefined ||
      secondaryDriver?.civility === undefined ||
      secondaryDriver?.birthDate === undefined)
  ) {
    return {
      reachedSummaryScreenSections,
      hasReachedRestitution,
      stateValue: StateValue.INFORMATION_SECONDARY_DETAILS,
    };
  }

  if (answers.declareSecondaryDriver === DeclareSecondaryDriverType.Oui && secondaryDriver?.licenseDate === undefined) {
    return {
      reachedSummaryScreenSections,
      hasReachedRestitution,
      stateValue: StateValue.INFORMATION_SECONDARY_LICENSE_DATE,
    };
  }

  if (
    answers.declareSecondaryDriver === DeclareSecondaryDriverType.Oui &&
    secondaryDriver?.accompaniedDriving === undefined
  ) {
    return {
      reachedSummaryScreenSections,
      hasReachedRestitution,
      stateValue: StateValue.INFORMATION_SECONDARY_LICENSE_DATE,
    };
  }

  if (answers.declareSecondaryDriver === DeclareSecondaryDriverType.Oui && secondaryHasBeenCovered === undefined) {
    return {
      reachedSummaryScreenSections,
      hasReachedRestitution,
      stateValue: StateValue.INFORMATION_SECONDARY_HAS_BEEN_COVERED,
    };
  }

  if (
    answers.declareSecondaryDriver === DeclareSecondaryDriverType.Oui &&
    secondaryHasBeenCovered === DriverHasBeenCovered.OuiPartiellement &&
    (answers.secondaryDriver?.insurancePeriods === undefined || answers.secondaryDriver?.insurancePeriods?.length === 0)
  ) {
    return {
      reachedSummaryScreenSections,
      hasReachedRestitution,
      stateValue: StateValue.INFORMATION_SECONDARY_HAS_BEEN_COVERED,
    };
  }

  if (
    answers.declareSecondaryDriver === DeclareSecondaryDriverType.Oui &&
    (secondaryHasBeenCovered === DriverHasBeenCovered.OuiPartiellement ||
      secondaryHasBeenCovered === DriverHasBeenCovered.OuiEnContinu ||
      secondaryHasBeenCovered === DriverHasBeenCovered.NonSurPeriode) &&
    answers.secondaryDriver?.crm === undefined
  ) {
    return {
      reachedSummaryScreenSections,
      hasReachedRestitution,
      stateValue: StateValue.INFORMATION_SECONDARY_BONUS_MALUS,
    };
  }

  if (
    answers.declareSecondaryDriver === DeclareSecondaryDriverType.Oui &&
    (secondaryHasBeenCovered === DriverHasBeenCovered.OuiPartiellement ||
      secondaryHasBeenCovered === DriverHasBeenCovered.OuiEnContinu ||
      secondaryHasBeenCovered === DriverHasBeenCovered.NonSurPeriode) &&
    answers.secondaryDriver?.crm === 50 &&
    answers.secondaryDriver?.yearsSinceHighestBonus === undefined
  ) {
    return {
      reachedSummaryScreenSections,
      hasReachedRestitution,
      stateValue: StateValue.INFORMATION_SECONDARY_BONUS50,
    };
  }

  reachedSummaryScreenSections = {
    ...reachedSummaryScreenSections,
    SECONDARY_DRIVER: true,
  };

  hasReachedRestitution = true;

  return {
    reachedSummaryScreenSections,
    hasReachedRestitution,
    stateValue: StateValue.INFORMATION_PRIMARY_DETAILS,
  };
};

export const sanitizeRemoteQuote = (
  quoteAnswers: Partial<LongQuoteAnswers>,
): {
  quoteAnswers: Partial<LongQuoteAnswers>;
  reachedSummaryScreenSections?: Partial<Record<Section, boolean>>;
  stateValue: StateValue;
} => {
  const quoteAnswersWithoutNilValues = removeNilValuesInObject(quoteAnswers);
  const { answers, reachedSummaryScreenSections } = verifyQuoteStateValue(quoteAnswersWithoutNilValues);

  return {
    quoteAnswers: {
      ...quoteAnswersWithoutNilValues,
      ...answers,
    },
    reachedSummaryScreenSections,
    stateValue: StateValue.VEHICULE_SEARCH_CHOICE,
  };
};

const hasAlreadyPaid = (localeStateMachine: StateMachineConfig): boolean => {
  return (
    localeStateMachine.context.subscribeResult?.status === SubscribeStatus.SUBSCRIBED ||
    localeStateMachine.context.postPaymentResult?.status === PaymentStatus.OK
  );
};

export const sanitizeLocaleStateMachine = async (
  localeStateMachine: StateMachineConfig,
): Promise<StateMachineConfig> => {
  if (hasAlreadyPaid(localeStateMachine)) {
    // we do not want to reset the state after payment
    return localeStateMachine;
  }

  let localeAnswers = localeStateMachine.context.answers;

  if (!!localeAnswers.vehiculeVersion && localeAnswers.vehiculeIsUtility === undefined) {
    const versionDetails = await getVersionDetails(localeAnswers.vehiculeVersion);

    localeAnswers = {
      ...localeAnswers,
      vehiculeIsUtility: versionDetails.is_utility,
    };
  }

  // if the user was before payment, make sure the state is valid before restoring it
  const { answers, stateValue, reachedSummaryScreenSections, hasReachedRestitution, availableVersions } =
    verifyQuoteStateValue(localeAnswers);

  return {
    ...localeStateMachine,
    context: {
      ...localeStateMachine.context,
      answers: {
        ...localeStateMachine.context.answers,
        subscriberPhone: localeStateMachine.context.answers.subscriberPhone?.startsWith('+')
          ? localeStateMachine.context.answers.subscriberPhone
          : `+33${localeStateMachine.context.answers.subscriberPhone || ''}`,
        ...answers,
      },
      reachedSummaryScreenSections,
      hasReachedRestitution,
      availableVersions: availableVersions ?? localeStateMachine.context.availableVersions,
    },
    stateValue,
  };
};
