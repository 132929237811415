import { HStack, Image, Typography, VStack } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import Illustration from './assets/hand-phone.svg';

export function PostSubscription(): ReactNode {
  return (
    <VStack space="kitt.4">
      <Typography.Header3 base="header3">Une fois assuré, restons connectés</Typography.Header3>
      <HStack space={{ base: 'kitt.4', medium: 'kitt.8' }}>
        <Typography.Text>
          Sur votre Espace Client, accédez en 1 clic à toutes vos garanties, vos remboursements et contactez votre
          conseiller quand vous voulez.
        </Typography.Text>
        <Image src={Illustration} alt="Hand holding phone illustration" height={156} width={125} />
      </HStack>
    </VStack>
  );
}
