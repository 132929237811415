import type { ReactNode } from 'react';
import { HasTerminationRecordTemplate } from '../../../../components/ScreenTemplates/InformationTemplates/InsuranceRecordTemplates/TerminationRecordTemplates/HasTerminationRecordTemplate';
import { DateFromYearsAgo } from '../../../../utils/date';

export function PrimaryHasTerminationRecordScreen(): ReactNode {
  const threeYearsAgo = DateFromYearsAgo(3);

  return (
    <HasTerminationRecordTemplate
      title={`Votre contrat a-t-il été résilié par votre assureur depuis le ${threeYearsAgo}\u00A0?`}
      fieldName="primaryDriver.hasTermination"
    />
  );
}
