import { InfoRegularIcon, XRegularIcon } from '@ornikar/kitt-icons/phosphor';
import { HStack, Icon, IconButton, NavigationModal, Tooltip, Typography, VStack, View } from '@ornikar/kitt-universal';
import { type ReactNode } from 'react';
import type { PackageCoverage } from '../../../../../../../apis/types/Formula';
import { parseMarkdown } from '../../../markdown-parser';
import styles from './styles.module.css';

export interface CoverageInfoModalProps {
  coverage?: PackageCoverage;
  isVisible: boolean;
  onClose: () => void;
}

export function CoverageInfoModal({ coverage, isVisible, onClose }: CoverageInfoModalProps): ReactNode {
  return (
    <NavigationModal.ModalBehaviour visible={isVisible} onClose={onClose}>
      <NavigationModal
        header={
          <NavigationModal.Header right={<IconButton icon={<XRegularIcon />} onPress={onClose} />}>
            <Typography.Paragraph base="body" variant="bold">
              {coverage?.label}
            </Typography.Paragraph>
          </NavigationModal.Header>
        }
        body={
          <NavigationModal.Body>
            <VStack space="kitt.2">
              <View>
                <Typography.Paragraph base="body">
                  <span
                    dangerouslySetInnerHTML={{ __html: parseMarkdown(coverage?.description || '') }}
                    className={styles.CardContentDescription}
                  />
                </Typography.Paragraph>
              </View>
              {coverage?.reimbursements?.length ? (
                <View>
                  <Typography.Paragraph base="body" variant="bold">
                    Vos remboursements
                  </Typography.Paragraph>
                  {coverage?.reimbursements.map((reimbursement) => (
                    <Typography.Paragraph key={reimbursement} base="body">
                      {reimbursement}
                    </Typography.Paragraph>
                  ))}
                </View>
              ) : null}
              {coverage?.deductibles?.length ? (
                <View marginTop="kitt.4">
                  <HStack alignItems="center">
                    <Typography.Paragraph base="body" variant="bold">
                      Vos franchises{' '}
                    </Typography.Paragraph>
                    <Tooltip
                      content="La franchise est le montant qui indique à partir de quelle somme votre indemnisation s’active et du montant qui reste à votre charge. Par exemple, si vous avez une franchise de 100€ et que le montant de l’incident s’élève à 2 000 €, nous vous rembourserons de 1 900€."
                      position="top"
                    >
                      <Icon icon={<InfoRegularIcon />} size={20} color="black" />
                    </Tooltip>
                  </HStack>
                  {coverage?.deductibles.map((deductible) => (
                    <Typography.Paragraph key={deductible} base="body">
                      {deductible}
                    </Typography.Paragraph>
                  ))}
                </View>
              ) : null}
            </VStack>
          </NavigationModal.Body>
        }
      />
    </NavigationModal.ModalBehaviour>
  );
}
