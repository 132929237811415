import { VStack, View } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { Logo } from '../Logo';
import { FooterLinks } from './components/FooterLinks';
import { FooterSocials } from './components/FooterSocials';

export function FooterContent(): ReactNode {
  return (
    <VStack
      flexDirection={{ large: 'row' }}
      justifyContent={{ large: 'space-between' }}
      width="100%"
      maxWidth={{ large: 1280 }}
      marginTop={{ medium: 0 }}
      marginX={{ medium: 'auto' }}
      paddingTop="kitt.10"
    >
      <Logo variant="white" height={{ base: 24, small: 35 }} flex={{ large: '0 0 190px' }} />

      <View flex={{ large: 1 }} marginTop={0}>
        <VStack marginTop={{ base: 10, large: 0 }} alignItems={{ base: 'center', large: 'flex-end' }}>
          <FooterLinks />
        </VStack>
        <FooterSocials />
      </View>
    </VStack>
  );
}
