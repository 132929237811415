import type { JsonValue } from 'type-fest';

const getItem = (key: string): string | null => {
  try {
    return localStorage.getItem(key);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const setItem = (key: string, item: string): void => {
  try {
    localStorage.setItem(key, item);
  } catch (error) {
    console.error(error);
  }
};

const removeItem = (key: string): void => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error(error);
  }
};

const preserveUndefinedReplacer = (key: string, value: JsonValue): JsonValue => (value === undefined ? null : value);

const replaceNullByUndefinedReviver = (key: string, value: JsonValue): JsonValue | undefined => {
  if (value === null) {
    return undefined;
  }

  return value;
};

export const safeGetJSONFromLocalStorage = <K>(key: string): K => {
  const json = getItem(key);
  let value = null;
  if (json) {
    try {
      value = JSON.parse(json, replaceNullByUndefinedReviver);
      if (value.length > 0) throw new Error('old version');
    } catch (error) {
      removeItem(key);
    }
  }
  return value;
};

export const saveJSONLocalStorage = (key: string, value: unknown): void => {
  setItem(key, JSON.stringify(value, preserveUndefinedReplacer));
};
