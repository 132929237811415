import { Typography, View } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';

const currentYear = new Date().getFullYear();

export function FooterLegal(): ReactNode {
  return (
    <View marginTop="kitt.15" color="kitt.palettes.lateOcean.black555">
      <Typography.Paragraph>
        Ornikar Assurances, société par actions simplifiée au capital de 10&nbsp;000 euros dont le siège social est
        situé 170 Boulevard de la Villette - 75019 Paris 19. Ornikar Assurances en sa qualité d’intermédiaire en
        assurance est régie par le Code des assurances et immatriculée auprès de l’Orias sous le numéro
        200&nbsp;053&nbsp;80 (
        <a href="https://orias.fr/" target="_blank" rel="noreferrer">
          https://orias.fr
        </a>
        ). ORNIKAR ASSURANCES est enregistrée auprès de la SAS CNPM - MÉDIATION - CONSOMMATION. En cas de litige, le
        consommateur pourra déposer sa réclamation sur le site internet{' '}
        <a href="https://cnpm-mediation-consommation.eu" target="_blank" rel="noreferrer">
          https://cnpm-mediation-consommation.eu
        </a>
        ou par voie postale en écrivant à "CNPM MÉDIATION-CONSOMMATION" - 27 avenue de la Libération - 44400
        SAINT-CHAMOND. ©&nbsp;{currentYear}&nbsp;
        <br />
        Ce site est protégé par reCAPTCHA et la{' '}
        <a href="https://policies.google.com/privacy">politique de confidentialité</a> ainsi que les{' '}
        <a href="https://policies.google.com/terms">conditions d'utilisation</a> Google sont respectées.{' '}
      </Typography.Paragraph>
    </View>
  );
}
