import type { ReactNode } from 'react';
import { ClaimRecordWithAlcoholTemplate } from '../../../../components/ScreenTemplates/InformationTemplates/InsuranceRecordTemplates/ClaimRecordTemplates/ClaimRecordWithAlcoholTemplate';

export function SecondaryTerminationRecordLicenseCanceledOrSuspendedClaimWithAlcoholScreen(): ReactNode {
  return (
    <ClaimRecordWithAlcoholTemplate
      title="Était-il sous l'emprise d'alcool ou de stupéfiants lors de cet accident&nbsp;?"
      fieldName="secondaryDriver.terminationRecords[0].cancellationOrSuspensionRecord.claimRecord.underTheInfluenceOf"
    />
  );
}
