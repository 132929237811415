import { InputText } from '@ornikar/kitt-universal';
import * as Sentry from '@sentry/react';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { sendVehicleLegalInformation } from '../../../apis/sendVehicleLegalInformations';
import type { TarificationResultOK } from '../../../apis/types/Quotation';
import { getVehicleByLicensePlate } from '../../../apis/vehicules';
import { Field } from '../../../components/Field';
import { ScreenTemplateWithValidation } from '../../../components/ScreenTemplates/ScreenTemplateWithValidation';
import { useToast } from '../../../components/Toast/useToast';
import { useLicensePlateValidator } from '../../../forms/validation/sections/vehicle/licensePlate';
import { useMileageValidator } from '../../../forms/validation/sections/vehicle/mileage';
import { useSubscriptionFsmState } from '../../../fsm/context';
import { sendEvent } from '../../../utils/mixpanel';
import { CorrectVehicleModal } from './components/CorrectVehicleModal';
import styles from './styles.module.css';

const fieldNames = ['vehiculeImmatriculation', 'kilometrageCompteur'];

export function VehicleLegalInformationScreen(): ReactNode {
  const { openErrorToast } = useToast();
  const [step, setStep] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const licensePlateValidator = useLicensePlateValidator();
  const mileageValidator = useMileageValidator();
  const {
    context: { answers, tarificationResult },
  } = useSubscriptionFsmState();

  const [isLoading, setIsLoading] = useState(false);

  const handleStep = (newStep: number): void => {
    setStep(newStep);
  };

  const { submit } = useForm();
  const { values } = useFormState();

  const handleSubmit = async (): Promise<void> => {
    setIsLoading(true);

    try {
      const vehicleImmatriculation: string = (values.vehiculeImmatriculation as string) ?? '';
      let isVehicleDataDifferentFromAnswers = false;

      await sendVehicleLegalInformation(
        {
          licensePlate: vehicleImmatriculation,
          odometer: parseInt(values.kilometrageCompteur as string, 10),
        },
        (tarificationResult as TarificationResultOK).quoteId,
      );

      if (!vehicleImmatriculation.startsWith('WW')) {
        const vehicleData = await getVehicleByLicensePlate(vehicleImmatriculation);

        const vehicleDataKeys = ['vehiculeMarque', 'vehiculeCarburant', 'vehiculeModele', 'vehiculePuissanceFiscale'];

        isVehicleDataDifferentFromAnswers = vehicleDataKeys.some(
          (key) => answers?.[key as keyof typeof answers] !== vehicleData?.[key as keyof typeof vehicleData],
        );

        if (values.vehiculeImmatriculation !== answers.vehiculeImmatriculation) {
          if (isVehicleDataDifferentFromAnswers) {
            sendEvent('Insurance - Mismatch Between LicensePlate And Model');
            setStep(0);
            setOpenModal(true);
          } else {
            submit();
          }
        } else {
          submit();
        }
      }
    } catch (error) {
      Sentry.captureException(error);

      // @TODO: we probably should try to handle this in a more generic way
      sendEvent('Insurance - Error License Plate');

      openErrorToast(error as Error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    sendEvent('Insurance - Display Insurance Step', {
      state_slug: 'vehicle_legal_information',
      step_slug: 'vehicle_legal_information',
    });
  }, []);

  return (
    <ScreenTemplateWithValidation
      hideSaveQuoteButton
      fieldNames={fieldNames}
      title="Entrez les informations suivantes pour que nous finalisions votre contrat"
      className={styles.Column}
      submitButton={{ loading: isLoading }}
      backButtonLabel="Étape précédente"
      highlightTitle="Pourquoi ces informations sont nécessaires ?"
      highlightContent="Car elles sont indispensables pour que nous puissions générer votre contrat d’assurance auto."
      onSubmit={handleSubmit}
    >
      <Field
        component={InputText}
        name="vehiculeImmatriculation"
        label="Numéro d’immatriculation"
        defaultValue={answers.vehiculeImmatriculation}
        placeholder="AA123AA"
        format={(value: string) => value?.trim().toUpperCase() || ''}
        validate={licensePlateValidator}
      />
      <Field
        component={InputText}
        type="number"
        name="kilometrageCompteur"
        label="Kilométrage au compteur"
        format={(value: string) => (value?.trim() || '').replace(/[^0-9]/gi, '')}
        placeholder="10000"
        validate={mileageValidator}
      />
      <CorrectVehicleModal
        step={step}
        visible={openModal}
        onChangeStep={handleStep}
        onClose={() => setOpenModal(false)}
      />
    </ScreenTemplateWithValidation>
  );
}
