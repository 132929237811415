import { CaretDownRegularIcon, CheckRegularIcon, InfoRegularIcon, XRegularIcon } from '@ornikar/kitt-icons/phosphor';
import { HStack, Icon, Tooltip, Typography, VStack, View } from '@ornikar/kitt-universal';
import { type ReactNode, useEffect, useRef, useState } from 'react';
import { parseMarkdown } from '../../../markdown-parser';
import styles from './styles.module.css';

interface OptionDropdownProps {
  name: string;
  isEnabled?: boolean;
  description: string;
  reimbursements: string[];
  deductibles: string[];
  isOpen?: boolean;
  open?: () => void;
}

export function OptionDropdown({
  name,
  isEnabled = false,
  description,
  reimbursements,
  deductibles,
  isOpen,
  open,
}: OptionDropdownProps): ReactNode {
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState(contentRef.current?.clientHeight ?? 0);

  const deductibleTooltip =
    "La franchise est le montant qui indique à partir de quelle somme votre indemnisation s'active et du montant qui reste à votre charge. Par exemple, si vous avez une franchise de 100\u00A0€ et que le montant de l'incident s'élève à 2\u202F000\u00A0€, nous vous rembourserons de 1\u202F900\u00A0€.)";

  useEffect(() => {
    const handleResize = () => setContentHeight(contentRef.current?.clientHeight ?? 0);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setContentHeight(contentRef.current?.clientHeight ?? 0);
  }, [contentRef.current?.clientHeight]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (containerRef.current) {
      if (isOpen) {
        containerRef.current.style.height = `${contentHeight + 8}px`;
        timeout = setTimeout(() => {
          if (containerRef.current) {
            containerRef.current.style.overflow = 'visible';
          }
        }, 300);
      } else {
        containerRef.current.style.height = '0';
        containerRef.current.style.overflow = 'hidden';
      }
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isEnabled, isOpen, contentHeight]);

  const isEnabledIcon = isEnabled ? <CheckRegularIcon /> : <XRegularIcon />;

  return (
    <VStack>
      <HStack alignItems="center" space="kitt.2" onMouseUp={open}>
        <Icon size="kitt.5" color={isEnabled ? 'kitt.success' : 'kitt.danger'} icon={isEnabledIcon} />
        <Typography.Paragraph base="body" variant={isOpen ? 'bold' : 'regular'} flex={1}>
          {name}
        </Typography.Paragraph>
        <View
          style={{
            transform: `rotate(${isOpen ? 180 : 0}deg)`,
            transitionDuration: '300ms',
            transitionProperty: 'transform',
          }}
        >
          <Icon size="kitt.5" color="kitt.black" icon={<CaretDownRegularIcon />} />
        </View>
      </HStack>

      <div ref={containerRef} className={styles.CardContentContainer}>
        <VStack ref={contentRef} space="kitt.4">
          <View marginTop="kitt.4">
            <span
              dangerouslySetInnerHTML={{ __html: parseMarkdown(description) }}
              className={styles.CardContentDescription}
            />
          </View>

          <View>
            <Typography.Paragraph base="body" variant="bold" color="kitt.black">
              Vos remboursements
            </Typography.Paragraph>
            <Typography.Paragraph base="body" color="kitt.black">
              {reimbursements[0]}
            </Typography.Paragraph>
          </View>

          {deductibles?.length ? (
            <View>
              <HStack alignItems="center" space="kitt.2">
                <Typography.Paragraph base="body" variant="bold" color="kitt.black">
                  Votre franchise
                </Typography.Paragraph>
                <Tooltip content={deductibleTooltip} position="bottom">
                  <Icon size="kitt.5" color="kitt.black" icon={<InfoRegularIcon />} />
                </Tooltip>
              </HStack>
              <Typography.Paragraph base="body" color="kitt.black">
                {deductibles[0]}
              </Typography.Paragraph>
            </View>
          ) : null}
        </VStack>
      </div>
    </VStack>
  );
}
