import { parse } from 'date-fns';
import { useMemo } from 'react';
import type { ReactNode } from 'react';
import { RadioScreenTemplate } from '../../../components/ScreenTemplates/RadioScreenTemplate';
import { vehicleFundingOptionsList } from '../../../constants/mappers/vehicle';
import { useVehicleFundingValidator } from '../../../forms/validation/sections/vehicle/vehicleFunding';
import { useSubscriptionFsmState } from '../../../fsm/context';

export function VehicleFundingMethodScreen(): ReactNode {
  const {
    context: { answers },
  } = useSubscriptionFsmState();
  const vehicleFundingValidator = useVehicleFundingValidator();

  const isVehicleInPossession = useMemo(
    () => parse(answers.vehiculeAcquisition!, 'yyyy-MM-dd', new Date()) < new Date(),
    [answers.vehiculeAcquisition],
  );

  return (
    <RadioScreenTemplate
      title={
        <div>
          Merci, dernière question sur votre véhicule. Comment{' '}
          {isVehicleInPossession ? "l'avez-vous financé" : 'allez-vous le financer'}
          &nbsp;?
        </div>
      }
      fieldName="vehiculeFinancement"
      choices={vehicleFundingOptionsList}
      highlightTitle="Précision importante"
      highlightContent="Cette information est vérifiée lors de l'envoi des pièces justificatives obligatoires demandées par votre assureur."
      validate={vehicleFundingValidator}
    />
  );
}
