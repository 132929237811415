import * as Sentry from '@sentry/react';
import { HTTPError, TimeoutError } from 'ky';

/**
 * Disable errors originating from injected scripts such as Google Tag Manager (https://github.com/getsentry/sentry-javascript/issues/3147#issuecomment-768978043) or Chrome extensions
 * It should prevent sending errors like "Can't find variable: fbq", "ttq is not defined", etc
 */
function isInjectedCode(event: Sentry.Event): boolean {
  const frames = event.exception?.values?.[0]?.stacktrace?.frames;

  if (frames && frames.length > 0) {
    const firstFrame = frames[0];

    if (firstFrame.filename === '<anonymous>') {
      return true;
    }

    const lastFrame = frames[frames.length - 1];

    if (lastFrame.filename === window.location.pathname) {
      return true;
    }

    if (
      frames.some(
        (frame) =>
          frame.filename &&
          /^https?:\/\/www\.googletagmanager\.com|\/gtm\.js$|^chrome-extension:\/\//.test(frame.filename),
      )
    ) {
      return true;
    }
  }

  return false;
}

export function initializeSentry(): void {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.MODE,
    ignoreErrors: [HTTPError.name, TimeoutError.name],
    normalizeDepth: 8,
    beforeSend: (event, hint) => {
      if (import.meta.env.DEV) {
        console.error(hint.originalException || hint.syntheticException || event); // eslint-disable-line no-console

        return null; // This drops the event and nothing will be sent to sentry
      }

      if (isInjectedCode(event) || hint.originalException === 'Timeout') {
        return null;
      }

      return event;
    },
  });
}
