import { TitulaireCarteGriseType } from '../../apis/types/LongQuoteAnswers';
import { RegistrationDocumentOwnerIsSubscriberType } from '../answers';
import type { AnswerAssign } from './types';
import { assignAnswer } from './utils';

export const changeRegistrationDocumentOwnerToSubscriber: AnswerAssign = assignAnswer(
  {
    path: 'registrationDocumentOwnerIsSubscriber',
    value: RegistrationDocumentOwnerIsSubscriberType.Oui,
  },
  {
    path: 'titulaireCartegrise',
    value: TitulaireCarteGriseType.Souscripteur,
  },
);
