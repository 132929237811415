import { useState } from 'react';
import type { ReactNode } from 'react';
import { ToastCenter } from './ToastCenter';
import type { ToastContextValue } from './ToastContext';
import { SetToastContext, ToastContext } from './ToastContext';

interface ToastProviderProps {
  children: ReactNode;
}

export function ToastProvider({ children }: ToastProviderProps): ReactNode {
  const [toast, setToast] = useState<ToastContextValue>({
    isVisible: false,
    message: '',
    type: 'info',
  });

  return (
    <ToastContext.Provider value={toast}>
      <SetToastContext.Provider value={setToast}>
        {children}
        <ToastCenter />
      </SetToastContext.Provider>
    </ToastContext.Provider>
  );
}
