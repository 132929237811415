import { HStack, PageLoader, Typography, VStack, View } from '@ornikar/kitt-universal';
import type { IntlValidatorReturnType } from '@ornikar/react-validators';
import * as Sentry from '@sentry/react';
import { addMonths } from 'date-fns';
import { type ReactNode, useCallback, useEffect, useState } from 'react';
import { useFormState } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import type { PromoCodeCheckEligibilityResponse } from '../../../apis/promoCode';
import { promoCodeSetSession } from '../../../apis/promoCode';
import { setEffectiveDate } from '../../../apis/setEffectiveDate';
import { HamonWantType } from '../../../apis/types/LongQuoteAnswers';
import { CallbackModule } from '../../../components/CallbackModule';
import { useAddonsPrice } from '../../../components/FormulaPrice/hooks/useAddonsPrice';
import { useDeductiblesPrice } from '../../../components/FormulaPrice/hooks/useDeductiblesPrice';
import { PromoCode } from '../../../components/PromoCode/PromoCode';
import { ScreenTemplateWithSideAndBottomBars } from '../../../components/ScreenTemplates/ScreenTemplateWithSideAndBottomBars';
import { useToast } from '../../../components/Toast/useToast';
import { MONTH_INTERVAL_MAX } from '../../../constants/coverageDate';
import { APPLICATION_FEES_FUNNEL_POSITION } from '../../../constants/flagshipKeys';
import { useVehiculeCoverageDateValidator } from '../../../forms/validation/sections/vehicle/vehiculeCoverageDateValidator';
import { type Addon, DeductibleName } from '../../../fsm/answers';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import type { Context } from '../../../fsm/types';
import { Event } from '../../../fsm/types';
import { useBillingInformation } from '../../../hooks/useBillingInformation';
import { useHamonEffectiveDate } from '../../../hooks/useHamonEffectiveDate';
import { useInsuranceDesktopMediaQuery } from '../../../hooks/useInsuranceDesktopMediaQuery';
import { useInsuranceMediaQuery } from '../../../hooks/useInsuranceMediaQuery';
import { useFsFlag } from '../../../setup/flagship';
import { formatDateForUI } from '../../../utils/date';
import { formatPrice } from '../../../utils/formatPrice';
import { getHamonDate } from '../../../utils/getHamonDate';
import { sendEvent } from '../../../utils/mixpanel';
import { usePaymentPeriodicityDetails } from '../../../utils/price';
import { AddonsList } from './Components/AddonsList';
import { DateDisplay } from './Components/DateDisplay';
import { DeductiblesList } from './Components/DeductiblesList';
import { HamonHighlight } from './Components/HamonHighlight';
import { PaymentDetails } from './Components/PaymentDetails';

const calculateAddons = (context: Context): [string, Addon][] => {
  const addons = context.answers.addons
    ? Object.entries(context.answers.addons).filter((option) => option[1].isSelected)
    : [];

  return addons;
};

export function ShoppingCartScreen(): ReactNode {
  const applicationFeesFunnelPositionFlag = useFsFlag<boolean>(APPLICATION_FEES_FUNNEL_POSITION, false, {
    shouldSendVariationToMixpanel: true,
  });

  const { context } = useSubscriptionFsmState();
  const send = useSubscriptionFsmDispatch();
  const isDesktop = useInsuranceDesktopMediaQuery();
  const { isMedium } = useInsuranceMediaQuery();
  const effectiveDate = useHamonEffectiveDate(Event.ANSWER_WITHOUT_CONTINUING, Event.SHOPPING_CART_ERROR);
  const billingData = useBillingInformation();

  const [validateButtonDisabled, setValidateButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (effectiveDate) {
      setValidateButtonDisabled(false);
    }
  }, [effectiveDate]);

  const addons = calculateAddons(context);

  const formulaPickedObject = context.formulePickedObject ?? null;

  // This list is filtered to avoid unmnanaged deductibles to appear
  const deductibles =
    context.quoteOptions?.deductibles?.filter((deductible) =>
      Object.values(DeductibleName).includes(deductible.name),
    ) ?? [];
  const selectedDeductibles = context.answers.deductibles;

  const addonsPrice = useAddonsPrice();
  const deductiblesPrice = useDeductiblesPrice();
  const { price: formulaPrice, periodicity: paymentPeriodicity } = usePaymentPeriodicityDetails();

  const hasDeductible = (deductibles && deductibles.length > 0) ?? false;

  const fullFormulaPrice = formulaPrice + deductiblesPrice;
  const totalPrice = formulaPrice + deductiblesPrice + addonsPrice;
  const { hamonWant } = context.answers;

  const [promoCode, setPromoCode] = useState('');
  const { values } = useFormState();
  const [promoCodeError, setPromoCodeError] = useState<string>();
  const [promoCodeResponse, setPromoCodeResponse] = useState<PromoCodeCheckEligibilityResponse>();
  const { openErrorToast } = useToast();
  const submissionId = context.formulePickedObject?.submissionId || '';

  const minDate = hamonWant === HamonWantType.Yes ? getHamonDate(new Date()) : new Date();
  const maxDate = addMonths(new Date(), MONTH_INTERVAL_MAX);
  const disableValidateButton = (visibility: boolean): void => {
    setValidateButtonDisabled(visibility);
  };

  const vehiculeCoverageDateValidator = useVehiculeCoverageDateValidator(
    context.answers.vehiculeAcquisition ?? '',
    hamonWant,
  );

  const manageValidationError = (data: IntlValidatorReturnType): void => {
    if (data && data?.message?.props.id !== 'dateFormatValidator') {
      sendEvent('Error', { type: data?.message?.props.id });
    }
  };

  const sendDate = async (): Promise<boolean> => {
    let isDateValid = false;
    try {
      setIsLoading(true);
      sendEvent('Changed date');
      isDateValid = await setEffectiveDate(
        context.tarificationResult?.quoteId || '',
        values.dateEffetSouhaite as string,
      );
      send(Event.ANSWER_WITHOUT_CONTINUING, {
        answers: {
          dateEffetSouhaite: values.dateEffetSouhaite,
        },
      });
      if (!isDateValid) {
        send(Event.SHOPPING_CART_ERROR, new Error('Cannot change effective date'));
      }
    } catch (error) {
      Sentry.captureException(error);
      send(Event.SHOPPING_CART_ERROR, error as Error);
    } finally {
      setIsLoading(false);
    }
    return isDateValid;
  };

  const handleSubmit = useCallback(async () => {
    try {
      if (!promoCodeError && promoCodeResponse) {
        await promoCodeSetSession({
          submissionId,
          couponCode: promoCode,
        });
      }
      send({ type: Event.CONTINUE });
    } catch (error) {
      Sentry.captureException(error);

      openErrorToast(error as Error);
    }
  }, [promoCodeError, promoCodeResponse, send, submissionId, promoCode, openErrorToast]);

  return (
    <ScreenTemplateWithSideAndBottomBars
      hideSaveQuoteButton
      submitButton={{ disabled: validateButtonDisabled, loading: isLoading }}
      sideBar={<CallbackModule light />}
      onSubmit={handleSubmit}
    >
      <VStack space={{ base: 'kitt.6', medium: 'kitt.8' }}>
        <Typography.Paragraph textAlign="center" base="header3">
          <FormattedMessage
            id="shoppingCart.yourTailormadeInsuranceAt"
            defaultMessage="Votre assurance sur mesure à {price}"
            values={{
              price: <Typography.Text color="primary">{formatPrice(totalPrice)}&nbsp;€</Typography.Text>,
            }}
          />
          &nbsp;
          <Typography.Text base="header4" color="primary">
            {paymentPeriodicity === 'monthly' ? (
              <FormattedMessage id="shoppingCart.periodicity.monthly" defaultMessage="/mois" />
            ) : (
              <FormattedMessage id="shoppingCart.periodicity.yearly" defaultMessage="/an" />
            )}
          </Typography.Text>
        </Typography.Paragraph>
        <VStack
          backgroundColor="kitt.white"
          borderColor="kitt.palettes.lateOcean.black100"
          borderWidth="1"
          borderRadius="kitt.5"
          zIndex={10}
        >
          {hamonWant === HamonWantType.Yes ? (
            <>
              <Typography.Text style={{ padding: 24 }}>
                <FormattedMessage id="shoppingCart.contractStart" defaultMessage="Début du contrat :" />{' '}
                <Typography.Text variant="bold">{formatDateForUI(effectiveDate)}</Typography.Text>
              </Typography.Text>
              <HamonHighlight />
            </>
          ) : (
            <DateDisplay
              fieldName="dateEffetSouhaite"
              defaultValue={effectiveDate}
              label={<FormattedMessage id="shoppingCart.contractStart" defaultMessage="Début du contrat :" />}
              validate={vehiculeCoverageDateValidator}
              minDate={minDate}
              maxDate={maxDate}
              onValidated={manageValidationError}
              onButtonPress={sendDate}
              onEditorVisibilityChange={disableValidateButton}
            />
          )}
        </VStack>
        <View
          backgroundColor="kitt.palettes.lateOcean.lateOceanLight1"
          paddingX={isDesktop ? 'kitt.6' : 'kitt.4'}
          paddingY={isDesktop ? 'kitt.8' : 'kitt.6'}
          borderRadius="kitt.6"
        >
          <VStack>
            <HStack
              marginBottom={hasDeductible || addons.length > 0 ? 'kitt.6' : 'kitt.0'}
              justifyContent="space-between"
              alignItems="top"
            >
              <Typography.Paragraph base="body" variant="bold" color="white">
                <FormattedMessage id="shoppingCart.formula" defaultMessage="Formule" /> {formulaPickedObject?.name}
              </Typography.Paragraph>
              <VStack>
                <View>
                  <Typography.Paragraph base="body-medium" variant="bold" color="kitt.palettes.lateOcean.goldCrayola">
                    {formatPrice(fullFormulaPrice)}&nbsp;€
                  </Typography.Paragraph>
                </View>
                <View marginTop="-kitt.2">
                  <Typography.Paragraph textAlign="right" base="body-small" color="kitt.palettes.lateOcean.goldCrayola">
                    {paymentPeriodicity === 'monthly' ? (
                      <FormattedMessage id="shoppingCart.formula.periodicity.monthly" defaultMessage="/mois" />
                    ) : (
                      <FormattedMessage id="shoppingCart.formula.periodicity.yearly" defaultMessage="/an" />
                    )}
                  </Typography.Paragraph>
                </View>
              </VStack>
            </HStack>
            {hasDeductible && <DeductiblesList selectedDeductibles={selectedDeductibles} deductibles={deductibles} />}
            {addons.length > 0 && !hasDeductible ? <View marginBottom="kitt.14" /> : null}
            {addons.length > 0 && (
              <AddonsList
                addons={addons}
                addonsPrice={addonsPrice}
                hasDeductible={hasDeductible}
                paymentPeriodicity={paymentPeriodicity}
              />
            )}
          </VStack>
        </View>

        {applicationFeesFunnelPositionFlag.getValue() ? (
          billingData ? (
            <PaymentDetails
              billingData={billingData}
              formulaName={formulaPickedObject?.name}
              paymentPeriodicity={paymentPeriodicity}
            />
          ) : (
            <View alignItems="center">
              <PageLoader />
            </View>
          )
        ) : null}

        <PromoCode
          promoCode={promoCode}
          setPromoCode={setPromoCode}
          promoCodeError={promoCodeError}
          setPromoCodeError={setPromoCodeError}
          promoCodeResponse={promoCodeResponse}
          setPromoCodeResponse={setPromoCodeResponse}
          submissionId={submissionId}
          formula={formulaPickedObject?.name}
          paymentFrequency={paymentPeriodicity}
        />
        {!isMedium && (
          <View marginTop="kitt.2">
            <CallbackModule light />
          </View>
        )}
      </VStack>
    </ScreenTemplateWithSideAndBottomBars>
  );
}
