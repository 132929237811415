import type { ReactNode } from 'react';
import { TerminationRecordDurationWithoutInsuranceTemplate } from '../../../../components/ScreenTemplates/InformationTemplates/InsuranceRecordTemplates/TerminationRecordTemplates/TerminationRecordDurationWithoutInsuranceTemplate';

export function PrimaryTerminationRecordDurationWithoutInsuranceScreen(): ReactNode {
  return (
    <TerminationRecordDurationWithoutInsuranceTemplate
      title="Suite à cette résiliation, combien de temps votre véhicule est resté sans assurance&nbsp;?"
      fieldName="primaryDriver.terminationRecords[0].duration"
    />
  );
}
