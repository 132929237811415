import { DropdownSelect } from '@ornikar/kitt';
import { ArrowLeftRegularIcon, XRegularIcon } from '@ornikar/kitt-icons/phosphor';
import { Button, IconButton, NavigationModal, Typography, VStack } from '@ornikar/kitt-universal';
import { useRequiredValidator } from '@ornikar/react-validators';
import * as Sentry from '@sentry/react';
import type { ReactNode } from 'react';
import { useMemo, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { Field } from '../../../../../components/Field';
import { InputHighlight } from '../../../../../components/InputHighlight';
import type { VehicleFsmAnswers } from '../../../../../fsm/answers';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../../../fsm/context';
import { Event } from '../../../../../fsm/types';

interface Props {
  onClose: () => void;
  onChangeStep: (newStep: number) => void;
  currentVehicleData: Partial<VehicleFsmAnswers>;
  onSubmit?: (vehicleImmatriculation: string) => void;
}

export function VehicleStep({ onClose, onChangeStep, onSubmit, currentVehicleData }: Props): ReactNode {
  const validator = useRequiredValidator();
  const {
    context: { answers },
  } = useSubscriptionFsmState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const { values } = useFormState();
  const { change } = useForm();

  const send = useSubscriptionFsmDispatch();

  const choices = useMemo(
    () =>
      currentVehicleData?.vehiculeVersionOptions?.map(({ version, id }: { version: string; id: string }) => ({
        label: version,
        value: id,
      })) || [],
    [currentVehicleData],
  );

  const handleSubmit = (): void => {
    setIsLoading(true);

    try {
      if (answers.vehiculeVersion !== values.newVehiculeVersion) {
        send({
          type: Event.ANSWER,
          answers: {
            ...answers,
            ...currentVehicleData,
            vehiculeVersion: values.newVehiculeVersion,
            hasDoneRevalidation: false,
          },
        });
      } else if (onSubmit) {
        onSubmit(values.vehiculeImmatriculation as string);
      } else {
        send({
          type: Event.CONTINUE,
        });
      }
    } catch (error_) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      Sentry.captureException(error_);
      setError(error_);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <NavigationModal
      header={
        <NavigationModal.Header
          right={<IconButton icon={<XRegularIcon />} onPress={onClose} />}
          left={<IconButton icon={<ArrowLeftRegularIcon />} onPress={() => onChangeStep(0)} />}
        >
          <Typography.Paragraph textAlign="center" variant="bold">
            Mon véhicule
          </Typography.Paragraph>
        </NavigationModal.Header>
      }
      body={
        <NavigationModal.Body style={{ zIndex: 1000 }}>
          <VStack marginBottom={24}>
            <Typography.Header4 base="header4" variant="bold" textAlign="center">
              Quelle est la version de votre {currentVehicleData.vehiculeMarque} {currentVehicleData.vehiculeModele}
              &nbsp;?
            </Typography.Header4>
          </VStack>
          <Field
            component={DropdownSelect}
            name="newVehiculeVersion"
            choices={choices}
            defaultValue={answers.vehiculeVersion}
            validate={validator}
            value={values.newVehiculeVersion}
            placeholder="Sélectionner"
            onChange={(value: string | number) => {
              change('newVehiculeVersion', value);
            }}
          />
          {Boolean(error) && (
            <Typography.Paragraph color="danger" base="body-small">
              {error.message}
            </Typography.Paragraph>
          )}
          <InputHighlight title="Où trouver la version ?">
            <div>
              Rendez-vous au <b>repère D2</b> de votre <b>carte grise (certificat d’immatriculation)</b>
            </div>
          </InputHighlight>
        </NavigationModal.Body>
      }
      footer={
        <NavigationModal.Footer>
          <VStack justifyContent="center" position="relative">
            <Button
              stretch
              type="primary"
              disabled={(!values.vehiculeVersion && !values.newVehiculeVersion) ?? isLoading}
              onPress={handleSubmit}
            >
              {values.vehiculeVersion !== values.newVehiculeVersion ? 'Recalculer mon tarif' : 'Continuer'}
            </Button>
          </VStack>
        </NavigationModal.Footer>
      }
    />
  );
}
