import { LockRegularIcon } from '@ornikar/kitt-icons/phosphor';
import { HStack, Typography, TypographyIcon } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';

export function SecurePayment(): ReactNode {
  return (
    <HStack space="kitt.2" alignItems="center">
      <TypographyIcon icon={<LockRegularIcon />} color="primary" />
      <Typography.Text variant="bold">Paiement sécurisé</Typography.Text>
    </HStack>
  );
}
