import { format, subYears } from 'date-fns';
import {
  AssureDepuisType,
  Civility,
  DejaAssureAvecNomType,
  FinancementType,
  LicenseType,
  LogementType,
  MaritalStatus,
  ParkingType,
  Profession,
  TitulaireCarteGriseType,
  UsageType,
} from '../../../apis/types/LongQuoteAnswers';
import { CountryCode } from '../../../utils/country';
import {
  ClaimQuantity,
  DeclareSecondaryDriverType,
  DriverHasBeenCovered,
  InsuranceRecordType,
  NonPaymentTerminationInsurerCount,
  RegistrationDocumentOwnerIsSubscriberType,
  TerminationReason,
  UninsuredVehicleOwnershipDuration,
  YesNoQuestion,
} from '../../answers';
import type { StateMachineConfig } from '../../types';
import { State } from '../../types';

export const SUCCESSFUL_QUOTE: Pick<StateMachineConfig, 'id' | 'context' | 'stateValue'> = {
  id: 'preFilled-SUCCESSFUL_QUOTE',
  context: {
    answers: {
      vehiculeMarque: 'CITROEN',
      vehiculeModele: 'XSARA PICASSO',
      vehiculePuissanceFiscale: 5,
      vehiculeCarburant: 'GASOIL',
      vehiculeMiseEnCirculation: '2012-01-12',
      vehiculeImmatriculation: 'AC123AB',
      vehiculeVersion: 'CI41042',
      vehiculeVersionName: 'CITROEN XSARA PICASSO 1.6 HDI 92',
      vehiculeIsUtility: false,
      vehiculeVersionOptions: [],
      vehiculeDejaAssureAvecNom: DejaAssureAvecNomType.OuiAMonNom,
      vehiculeAcquisition: '2017-12-07',
      vehiculeFinancement: FinancementType.comptant,
      dateEffetSouhaite: format(new Date(), 'yyyy-MM-dd'),
      vehiculeAssureDepuis: AssureDepuisType.Plus12Mois,
      primaryDriver: {
        licenseDate: '2011-08-11',
        licenseType: LicenseType.french,
        accompaniedDriving: YesNoQuestion.NO,
        firstName: 'Jean',
        lastName: 'Michel',
        birthDate: '1991-08-11',
        birthCity: 'Paris',
        birthCountryCode: CountryCode.FR,
        civility: Civility.Homme,
        crm: 95,
        maritalStatus: MaritalStatus.Célibataire,
        profession: Profession.Salarié,
        hasBeenCovered: DriverHasBeenCovered.OuiEnContinu,
        insurancePeriods: [],
        hasTermination: YesNoQuestion.YES,
        terminationRecords: [
          {
            type: InsuranceRecordType.Termination,
            date: format(subYears(new Date(), 2), 'yyyy-MM-dd'),
            reason: TerminationReason.NonPayment,
            terminationCount: NonPaymentTerminationInsurerCount.One,
            duration: UninsuredVehicleOwnershipDuration.LessThanOneMonth,
          },
        ],
        hasBeenCancelledOrSuspended: YesNoQuestion.NO,
        claimQuantity: ClaimQuantity.None,
      },
      usage: UsageType.leisureAndWorkHomeTrips,
      kilometersPerYear: 10000,
      parkingLocation: ParkingType.parking,
      parkingCodePostal: '29000',
      parkingCommune: 'QUIMPER',
      isPropertyOwner: YesNoQuestion.YES,
      housingType: LogementType.Appartement,
      registrationDocumentOwnerIsSubscriber: RegistrationDocumentOwnerIsSubscriberType.Oui,
      titulaireCartegrise: TitulaireCarteGriseType.Souscripteur,
      subscriberEmail: 'ins.warrior@ornikar.com',
      subscriberPhone: '+33612345678',
      subscriberAdress1: '89 Rue de Clignancourt',
      subscriberCivilite: Civility.Homme,
      subscriberCodePostal: '75018',
      subscriberCommune: 'Paris',
      subscriberFirstName: 'Jean',
      subscriberLastName: 'Michel',
      subscriberCompleteAdress: '89 Rue de Clignancourt, 75018 Paris, France',
      declareSecondaryDriver: DeclareSecondaryDriverType.Non,
      vehiculePreSelectionType: 1,
      privacyPolicyAccepted: true,
    },
    defaultAnswers: [],
    isLoading: false,
    availableBrands: [],
    availableModels: [],
    availableCarburants: [],
    availablePuissanceFiscales: [],
    availableVersions: [],
    hasReachedRestitution: true,
    isDisplayingFicNotification: false,
    formulePickedObject: undefined,
    tarificationResult: undefined,
    devisCreationResult: undefined,
    subscribeResult: undefined,
    reachedSummaryScreenSections: {
      VEHICULE: true,
      DRIVING: true,
      PRIMARY_DRIVER: true,
      SECONDARY_DRIVER: true,
    },
    error: undefined,
    isFetchingLicensePlate: false,
    postPaymentResult: undefined,
    editingInsuranceRecord: { index: 0 },
    hasReceivedQuoteMailNotification: false,
  },
  stateValue: State.INFORMATION_PRIMARY_DETAILS,
};
