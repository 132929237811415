import { useEffect, useState } from 'react';
import type { StateMachine } from 'xstate';
import { interpret } from 'xstate';
import { initializeMachine } from '../fsm';
import { getAnalyticsConfig } from '../fsm/actions/analytics';
import { stateMachineInitMachine } from '../fsm/init';
import { StateMachineInitState } from '../fsm/init/types';
import type { Context, Events, Schema } from '../fsm/types';
import { useSegmentAnonymousId } from '../providers/AnalyticsProvider';
import { sendInitiateInsuranceLongQuoteGTMEvent, sendResumeInsuranceLongQuoteGTMEvent } from '../utils/dataLayer';
import { sendEvent } from '../utils/mixpanel';

const getMachineId = (): string => `machine-${Date.now()}`;

type InsuranceSubscriptionStateMachine = StateMachine<
  Context,
  Schema,
  Events,
  {
    value: any;
    context: Context;
  }
>;

export const useStateMachineRetriever = (): {
  stateMachine: InsuranceSubscriptionStateMachine;
  isLoadingStateMachine: boolean;
} => {
  const [stateMachine, setStateMachine] = useState<InsuranceSubscriptionStateMachine>(initializeMachine());
  const [isLoadingStateMachine, setIsLoadingStateMachine] = useState(true);

  const searchParams = new URLSearchParams(window.location.search);
  const shouldResetFsm = searchParams.get('reset');
  const isYoungDriver = searchParams.get('youngDriver');
  const segmentAnonymousId = useSegmentAnonymousId();

  useEffect(() => {
    if (!segmentAnonymousId) {
      return () => {};
    }

    const initMachineInterpreter = interpret(stateMachineInitMachine)
      .onTransition((state) => {
        if (state.value === StateMachineInitState.END) {
          const { config } = state.context;

          if (shouldResetFsm) {
            sendEvent('Initiate Insurance Long Quote', {
              category_started: isYoungDriver ? 'jeune_conducteur' : 'autre',
              step_slug: null,
              state_slug: null,
            });
            sendInitiateInsuranceLongQuoteGTMEvent();
          } else {
            sendEvent('Resume Insurance Long Quote', { step_slug: null, state_slug: null });
            sendResumeInsuranceLongQuoteGTMEvent(state.context.config.context.answers?.subscriberEmail);
          }

          setStateMachine(
            initializeMachine(
              config.id || getMachineId(),
              config.stateValue,
              { ...config.context, segmentAnonymousId },
              getAnalyticsConfig(),
            ),
          );

          setIsLoadingStateMachine(false);

          initMachineInterpreter.stop();
        }
      })
      .start();

    return () => {
      initMachineInterpreter.stop();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmentAnonymousId]);

  return {
    stateMachine,
    isLoadingStateMachine,
  };
};
