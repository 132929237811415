import type { TooltipProps } from '@ornikar/kitt';
import { Tooltip } from '@ornikar/kitt';
import { InfoFillIcon } from '@ornikar/kitt-icons/phosphor';
import { Typography, TypographyIcon, View } from '@ornikar/kitt-universal';
import type { TypographyProps } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';

export interface InfoTooltipProps {
  position: TooltipProps['position'];
  tooltip: TooltipProps['content'];
  color?: TypographyProps['color'] | 'black-200';
}

export function InfoTooltip({ position, tooltip, color = 'black-light' }: InfoTooltipProps): ReactNode {
  return (
    <View width="fit-content">
      <Tooltip content={<Typography.Text color="kitt.white">{tooltip}</Typography.Text>} position={position}>
        <View margin="kitt.3">
          <TypographyIcon align="center" icon={<InfoFillIcon />} color={color === 'black-200' ? undefined : color} />
        </View>
      </Tooltip>
    </View>
  );
}
