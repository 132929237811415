import { useMemo } from 'react';
import { useSubscriptionFsmStateValue } from '../../fsm/context';
import { MAPPED_STATES_TO_SECTIONS } from '../../fsm/types';
import type { Section, State } from '../../fsm/types';
import { useInsuranceDesktopMediaQuery } from '../useInsuranceDesktopMediaQuery';
import type { SectionConfig } from './types';
import { sectionsConfig } from './types';

export type UseBreadcrumbsReturnValue = SectionConfig<Section> & {
  index: number;
};

export const useBreadcrumbs = (): UseBreadcrumbsReturnValue => {
  const isDesktop = useInsuranceDesktopMediaQuery();
  const stateValue = useSubscriptionFsmStateValue();
  const currentSection = useMemo(() => MAPPED_STATES_TO_SECTIONS[stateValue as State]!, [stateValue]);

  const currentIndex = useMemo(
    () => sectionsConfig.findIndex(({ section }) => section === currentSection),
    [currentSection],
  );

  const currentItem = useMemo(() => sectionsConfig[currentIndex], [currentIndex]);

  const currentPercent = useMemo(() => {
    if (isDesktop) {
      return currentItem?.percent;
    }
    return (100 / sectionsConfig.length) * (currentIndex + 1);
  }, [currentItem, currentIndex, isDesktop]);

  return {
    ...currentItem,
    percent: currentPercent,
    index: currentIndex,
  };
};
