import { Typography, View } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { FooterContent } from './FooterContent';
import { FooterLegal } from './FooterLegal';

export function Footer(): ReactNode {
  return (
    <View
      backgroundColor="kitt.black"
      paddingTop={0}
      paddingBottom={{
        base: 'kitt.10',
        large: 'kitt.8',
      }}
      paddingX={{
        base: 'kitt.5',
        medium: 'kitt.10',
        large: 'kitt.12',
      }}
    >
      <FooterContent />
      <View marginX="auto" marginY={0} maxWidth={{ large: 1280 }}>
        <Typography.Paragraph textAlign="center" base="body-small">
          <FooterLegal />
        </Typography.Paragraph>
      </View>
    </View>
  );
}
