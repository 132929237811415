import type { IntlValidator } from '@ornikar/react-validators';
import { useComposeValidators, useRequiredValidator, useValidator } from '@ornikar/react-validators';
import { createMaxLengthValidator, invalidTypeSymbol } from '@ornikar/validators';
import type { ValidatorType } from '../../types';
import { onlyUpperAlphaNum } from '../../utils';

export const MAX_LICENSE_PLATE_LENGTH = 9;

export const validateLicensePlate: ValidatorType = () => (value: string) => {
  if (!value) {
    return undefined;
  }

  if (!onlyUpperAlphaNum(value.trim().replace(/-/g, '').toUpperCase())) {
    return invalidTypeSymbol;
  }

  return undefined;
};

export const useLicensePlateValidator = (): IntlValidator<string> =>
  useComposeValidators(
    useRequiredValidator(),
    useValidator(createMaxLengthValidator(9), {
      id: 'PaymentInformationScreen.maxLicensePlateLengthValidator',
      defaultMessage: "Le numéro d'immatriculation est trop long",
    }),
    useValidator(validateLicensePlate(), {
      id: 'PaymentInformationScreen.licensePlateFormatValidator',
      defaultMessage: "Le format du numéro d'immatriculation est invalide",
    }),
  );
