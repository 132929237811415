import { getIn } from 'final-form';
import type { ReactNode } from 'react';
import { useFormState } from 'react-final-form';
import type { ScreenTemplateWithSideAndBottomBarsProps } from '../ScreenTemplateWithSideAndBottomBars';
import { ScreenTemplateWithSideAndBottomBars } from '../ScreenTemplateWithSideAndBottomBars';

interface ScreenTemplateWithBarsAndValidationProps extends ScreenTemplateWithSideAndBottomBarsProps {
  fieldNames: string[];
}

export function ScreenTemplateWithBarsAndValidation({
  fieldNames,
  submitButton,
  ...props
}: ScreenTemplateWithBarsAndValidationProps): ReactNode {
  const { errors = {} } = useFormState();
  const hasErrors = fieldNames.some((fieldName) => getIn(errors, fieldName)); // We need getIn() to resolve complex path like "something[0].foo"

  return (
    <ScreenTemplateWithSideAndBottomBars
      {...props}
      submitButton={{
        ...submitButton,
        disabled: hasErrors,
      }}
      onBeforeSubmit={() => {
        if (hasErrors) {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }}
    />
  );
}
