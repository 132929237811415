import { Glassbreakage, IllustratedIcon, Light, Triangle } from '@ornikar/illustrated-icons';
import { type ReactNode, useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { OrnikarPackageLevel } from '../../apis/types/Formula';
import type { QuoteOption } from '../../apis/types/QuoteOptions';
import { DeductibleName } from '../../fsm/answers';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../fsm/context';
import { Event } from '../../fsm/types';
import { formatPrice } from '../../utils/formatPrice';
import { FormattedMessageWithRichTextFormatting } from '../../utils/intl';
import { sendEvent } from '../../utils/mixpanel';
import { DeductibleItem } from './components';
import styles from './styles.module.css';

const messages = defineMessages({
  deductiblesTousrisquesDeductible1Title: {
    id: 'deductibles.tousrisques.deductible1.title',
    defaultMessage: 'Remplacer un pare-brise ou une vitre cassée\u00A0?',
  },
  deductiblesTousrisquesDeductible1Description: {
    id: 'deductibles.tousrisques.deductible1.description',
    defaultMessage:
      'Cet incident - aussi appelé bris de glace - est très courant.{br}Pour un remplacement, vous souhaitez que le montant à votre charge soit de:',
  },
  deductiblesIntermediaireDeductible1Title: {
    id: 'deductibles.intermediaire.deductible1.title',
    defaultMessage: 'Remplacer un pare-brise ou une vitre cassée\u00A0?',
  },
  deductiblesIntermediaireDeductible1Description: {
    id: 'deductibles.intermediaire.deductible1.description',
    defaultMessage:
      'Cet incident - aussi appelé bris de glace - est très courant.{br}Pour un remplacement, vous souhaitez que le montant à votre charge soit de:',
  },
  deductiblesDetailsBDGTitle: {
    id: 'deductibles.details.BDG.title',
    defaultMessage: 'Franchise bris de glace',
  },
  deductiblesDetailsBDGSubtitle: {
    id: 'deductibles.details.BDG.subtitle',
    defaultMessage: 'Remplacer un pare-brise ou une vitre cassée\u00A0?',
  },
  deductiblesDetailsBDGPart1Title: {
    id: 'deductibles.details.BDG.part_1.title',
    defaultMessage: 'Si ces éléments de votre véhicule se cassent ou subissent un impact : ',
  },
  deductiblesDetailsBDGPart1Text: {
    id: 'deductibles.details.BDG.part_1.text',
    defaultMessage:
      '<li>pare-brise,</li><li>glaces latérales,</li><li>lunette arrière,</li><li>toits ouvrant transparents,</li><li>optiques de phares.</li>',
  },
  deductiblesDetailsBDGPart2Title: {
    id: 'deductibles.details.BDG.part_2.title',
    defaultMessage: 'Quelle indemnisation\u00A0?',
  },
  deductiblesDetailsBDGPart2Text: {
    id: 'deductibles.details.BDG.part_2.text',
    defaultMessage:
      'Nous vous remboursons les frais de remplacement. Le montant restant à votre charge sera de {minAmount}€{hasBaseAmount, select, true {, {baseAmount}€} ou {maxAmount}€, selon votre choix à la souscription.',
  },
  deductiblesDetailsBDGTipsTitle: {
    id: 'deductibles.details.BDG.tips.title',
    defaultMessage: 'Notre conseil',
  },
  deductiblesDetailsBDGTipsText: {
    id: 'deductibles.details.BDG.tips.text',
    defaultMessage:
      'Si le coût de remplacement est inférieur au montant de la franchise, vous ne serez pas indemnisé.{br}Si vous le pouvez, nous vous conseillons donc d’opter pour la franchise à 0€.',
  },
  deductiblesDetailsIntermediaireFireText: {
    id: 'deductibles.details.intermédiaire.fire.part_1.text',
    defaultMessage: '<li>volé,</li><li>incendié.</li>',
  },
  deductiblesDetailsTousRisquesFireText: {
    id: 'deductibles.details.tousrisques.fire.part_1.text',
    defaultMessage: '<li>volé,</li><li>incendié,</li><li>endommagé,</li><li>détruit.</li>',
  },

  deductiblesDetailsFirePart1Title: {
    id: 'deductibles.details.fire.part_1.title',
    defaultMessage: 'Quand votre véhicule a été : ',
  },
  deductiblesDetailsFirePart2Text: {
    id: 'deductibles.details.fire.part_2.text',
    defaultMessage:
      'Nous vous remboursons les frais de réparations.{br}Le montant restant à votre charge sera de {minAmount}€{hasBaseAmount, select, true {, {baseAmount}€} other {}} ou {maxAmount}€, selon votre choix à la souscription.',
  },
  deductiblesDetailsFirePart2Title: {
    id: 'deductibles.details.fire.part_2.title',
    defaultMessage: 'Nous assurons : ',
  },
  deductiblesDetailsFireSubtitle: {
    id: 'deductibles.details.fire.subtitle',
    defaultMessage: 'Un vol ou un incendie a abîmé votre voiture ?',
  },
  deductiblesDetailsFireTipsText: {
    id: 'deductibles.details.fire.tips.text',
    defaultMessage:
      "Si le coût des réparations est inférieur au montant de la franchise, vous ne serez pas indeminsé.{br}Si vous le pouvez, nous vous conseillons donc d'opter pour la franchise à 110€.",
  },
  deductiblesDetailsFireTipsTitle: {
    id: 'deductibles.details.fire.tips.title',
    defaultMessage: 'Notre conseil',
  },
  deductiblesDetailsFireTitle: {
    id: 'deductibles.details.fire.title',
    defaultMessage: 'Franchise vol ou incendie',
  },
  deductiblesIntermediaireFireDescription: {
    id: 'deductibles.intermediaire.fire.description',
    defaultMessage:
      "Les réparations peuvent s'avérer très coûteuses.{br}Pour une réparation ou un remboursement, le montant à votre charge est de :",
  },
  deductiblesIntermediaireFireTitle: {
    id: 'deductibles.intermediaire.fire.title',
    defaultMessage: 'Un vol ou un incendie a abîmé votre voiture ?',
  },
  deductiblesTousrisquesFireDescription: {
    id: 'deductibles.tousrisques.fire.description',
    defaultMessage:
      "Les réparations peuvent s'avérer très coûteuses.{br}Pour une réparation ou un remboursement, le montant à votre charge est de :",
  },
  deductiblesTousrisquesFireTitle: {
    id: 'deductibles.tousrisques.fire.title',
    defaultMessage: 'Un vol ou un incendie a abîmé votre voiture ?',
  },
});

type DeductibleList = Record<string, { name: string; term: { name: string | undefined; value: string } }>;

export function DeductibleCard(): ReactNode {
  const {
    context: { quoteOptions, formulePickedObject, answers },
  } = useSubscriptionFsmState();

  const send = useSubscriptionFsmDispatch();

  const isIntermediaire = formulePickedObject?.name === OrnikarPackageLevel.INTERMEDIARY;

  const deductiblesMapper = {
    [DeductibleName.OML_WIND_SCREEN]: {
      icon: <IllustratedIcon icon={<Glassbreakage />} />,
      title: (
        <FormattedMessage
          {...(isIntermediaire
            ? messages.deductiblesIntermediaireDeductible1Title
            : messages.deductiblesTousrisquesDeductible1Title)}
        />
      ),
      description: (
        <FormattedMessageWithRichTextFormatting
          {...(isIntermediaire
            ? messages.deductiblesIntermediaireDeductible1Description
            : messages.deductiblesTousrisquesDeductible1Description)}
        />
      ),
      infoModalTitle: <FormattedMessage {...messages.deductiblesDetailsBDGTitle} />,
      infoModalSubtitle: <FormattedMessage {...messages.deductiblesDetailsBDGSubtitle} />,
      infoModalContent: (values: Record<string, string | number | boolean | undefined>) => {
        return [
          {
            title: <FormattedMessageWithRichTextFormatting {...messages.deductiblesDetailsBDGPart1Title} />,
            description: <FormattedMessageWithRichTextFormatting {...messages.deductiblesDetailsBDGPart1Text} />,
          },
          {
            title: <FormattedMessageWithRichTextFormatting {...messages.deductiblesDetailsBDGPart2Title} />,
            description: (
              <FormattedMessageWithRichTextFormatting {...messages.deductiblesDetailsBDGPart2Text} values={values} />
            ),
          },
          {
            icon: <IllustratedIcon icon={<Light />} />,
            title: <FormattedMessageWithRichTextFormatting {...messages.deductiblesDetailsBDGTipsTitle} />,
            description: <FormattedMessageWithRichTextFormatting {...messages.deductiblesDetailsBDGTipsText} />,
            isHighlighted: true,
          },
        ];
      },
    },
    [DeductibleName.OML_FIRE_THEFT_DTA]: {
      icon: <IllustratedIcon icon={<Triangle />} />,
      title: (
        <FormattedMessageWithRichTextFormatting
          {...(isIntermediaire ? messages.deductiblesIntermediaireFireTitle : messages.deductiblesTousrisquesFireTitle)}
        />
      ),
      description: (
        <FormattedMessageWithRichTextFormatting
          {...(isIntermediaire
            ? messages.deductiblesIntermediaireFireDescription
            : messages.deductiblesTousrisquesFireDescription)}
        />
      ),
      infoModalTitle: <FormattedMessageWithRichTextFormatting {...messages.deductiblesDetailsFireTitle} />,
      infoModalSubtitle: <FormattedMessageWithRichTextFormatting {...messages.deductiblesDetailsFireSubtitle} />,
      infoModalContent: (values: Record<string, string | number | boolean | undefined>) => {
        return [
          {
            title: <FormattedMessageWithRichTextFormatting {...messages.deductiblesDetailsFirePart1Title} />,
            description: (
              <FormattedMessageWithRichTextFormatting
                {...(isIntermediaire
                  ? messages.deductiblesDetailsIntermediaireFireText
                  : messages.deductiblesDetailsTousRisquesFireText)}
              />
            ),
          },
          {
            title: <FormattedMessageWithRichTextFormatting {...messages.deductiblesDetailsFirePart2Title} />,
            description: (
              <FormattedMessageWithRichTextFormatting {...messages.deductiblesDetailsFirePart2Text} values={values} />
            ),
          },
          {
            icon: <IllustratedIcon icon={<Light />} />,
            title: <FormattedMessageWithRichTextFormatting {...messages.deductiblesDetailsFireTipsTitle} />,
            description: (
              <FormattedMessageWithRichTextFormatting {...messages.deductiblesDetailsFireTipsText} values={values} />
            ),
            isHighlighted: true,
          },
        ];
      },
    },
  };

  const [deductibles, setDeductibles] = useState<DeductibleList>(answers?.deductibles ?? {});

  const sendAnswer = (name: string, term: string, option: QuoteOption | undefined): void => {
    sendEvent('Choose Franchise Version', {
      franchise_name: name,
      franchise_version: term,
    });

    const currentDeductible = {
      [name]: {
        name,
        term: {
          name: option?.term?.name,
          value: term,
          packageSubtype: option?.term?.packageSubtype,
        },
      },
    };
    const deductibleAnswer = { ...deductibles, ...currentDeductible };
    setDeductibles(deductibleAnswer);

    send(Event.ANSWER_WITHOUT_CONTINUING, {
      answers: {
        deductibles: deductibleAnswer,
      },
    });
  };

  return (
    <div className={styles.DeductibleCard}>
      {quoteOptions
        ? quoteOptions.deductibles
            .filter(({ name }) => name in deductiblesMapper)
            .map(({ name, options }) => {
              const values =
                options.length > 2
                  ? {
                      minAmount: formatPrice(options[0].amount.cents),
                      baseAmount: formatPrice(options[1].amount.cents),
                      maxAmount: formatPrice(options[2].amount.cents),
                      hasBaseAmount: true,
                    }
                  : {
                      minAmount: formatPrice(options[0].amount.cents),
                      hasBaseAmount: false,
                      baseAmount: -1,
                      maxAmount: formatPrice(options[1].amount.cents),
                    };
              return (
                <DeductibleItem
                  key={name}
                  icon={deductiblesMapper[name].icon}
                  title={deductiblesMapper[name].title}
                  description={deductiblesMapper[name].description}
                  name={name}
                  options={options}
                  infoModalTitle={deductiblesMapper[name].infoModalTitle}
                  infoModalSubtitle={deductiblesMapper[name].infoModalSubtitle}
                  infoModalContent={deductiblesMapper[name].infoModalContent(values)}
                  sendAnswer={sendAnswer}
                />
              );
            })
        : null}
    </div>
  );
}
