import type { QuoteFsmAnswers } from '../../fsm/answers';
import type { QuoteOptionsBody } from '../types/QuoteOptions';

export function mapAnswersAddonsToQuoteOptionsAddons(
  answersAddons: QuoteFsmAnswers['addons'] = {},
): QuoteOptionsBody['addOns'] {
  return Object.values(answersAddons)
    .filter(({ isSelected }) => isSelected)
    .map(({ name, term }) => ({ name, term }));
}

export function mapAnswersDeductiblesToQuoteOptionsDeductibles(
  answersDeductibles: QuoteFsmAnswers['deductibles'] = {},
): QuoteOptionsBody['deductibles'] {
  return Object.values(answersDeductibles).map(({ name, term }) => ({ name, term }));
}
