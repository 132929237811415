import { uniq } from 'lodash';
import type { AssignAction, DoneInvokeEvent } from 'xstate';
import { assign } from 'xstate';
import type { LongQuoteAnswers } from '../answers';
import type { AnswerEvent, Context, DefaultAnswersEnum, Section } from '../types';

export const setReachedSummaryScreenSectionBuilder = (
  sections: Section[],
  value: boolean,
): AssignAction<Context, any> =>
  assign<Context, any>((context: Context) => {
    return {
      reachedSummaryScreenSections: {
        ...context.reachedSummaryScreenSections,
        ...Object.fromEntries(sections.map((section) => [section, value])),
      },
    };
  });

export const copyAnswers = assign((context: Context, event: AnswerEvent) => {
  return {
    answers: {
      ...context.answers,
      ...event.answers,
      addons: {
        ...context.answers.addons,
        ...event.answers.addons,
      },
    },
  };
});

export const copyDataToAnswers = assign((context: Context, event: DoneInvokeEvent<Partial<LongQuoteAnswers>>) => ({
  answers: { ...context.answers, ...event.data },
}));

export const setDefaultAnswers = (...defaultAnswers: DefaultAnswersEnum[]): AssignAction<Context, AnswerEvent> =>
  assign((context: Context) => ({
    defaultAnswers: uniq([...context.defaultAnswers, ...defaultAnswers]),
  }));

export const unsetDefaultAnswers = (...defaultAnswers: DefaultAnswersEnum[]): AssignAction<Context, AnswerEvent> =>
  assign((context: Context) => ({
    defaultAnswers: context.defaultAnswers.filter((defaultAnswer) => !defaultAnswers.includes(defaultAnswer)),
  }));
