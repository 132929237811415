import { addDays, isAfter, startOfDay } from 'date-fns';
import { DeclareSecondaryDriverType, DriverHasBeenCovered } from '../answers';
import type { Context, Driver, Events } from '../types';
import { DefaultAnswersEnum } from '../types';
import { isAnswerEquals } from './utils';

export const driverHasBeenCoveredPartially = (driver: Driver): ((context: Context, event: Events) => boolean) =>
  isAnswerEquals(
    `${driver}Driver.hasBeenCovered`,
    (answer): boolean => answer === DriverHasBeenCovered.OuiPartiellement,
  );

export const driverHasBeenCoveredContinuously = (driver: Driver): ((context: Context, event: Events) => boolean) =>
  isAnswerEquals(`${driver}Driver.hasBeenCovered`, (answer): boolean => answer === DriverHasBeenCovered.OuiEnContinu);

export const driverHasBeenCoveredOnPeriod = (driver: Driver): ((context: Context, event: Events) => boolean) =>
  isAnswerEquals(`${driver}Driver.hasBeenCovered`, (answer): boolean => answer !== DriverHasBeenCovered.NonSurPeriode);

export const driverHasNotBeenCoveredOnPeriod = (driver: Driver): ((context: Context, event: Events) => boolean) =>
  isAnswerEquals(`${driver}Driver.hasBeenCovered`, (answer): boolean => answer === DriverHasBeenCovered.NonSurPeriode);

export const driverHasNeverBeenCovered = (driver: Driver): ((context: Context, event: Events) => boolean) =>
  isAnswerEquals(`${driver}Driver.hasBeenCovered`, (answer): boolean => answer === DriverHasBeenCovered.NonJamais);

export const hasDeclaredSecondaryDriver = isAnswerEquals(
  'declareSecondaryDriver',
  (answer): boolean => answer === DeclareSecondaryDriverType.Oui,
);

export const driverHas50PercentBonus = (driver: Driver): ((context: Context, event: Events) => boolean) =>
  isAnswerEquals(`${driver}Driver.crm`, (answer): boolean => answer === 50);

export const primaryDriverHasDefaultLicenseDate = (context: Context): boolean =>
  context.defaultAnswers.includes(DefaultAnswersEnum.PRIMARY_DRIVER_LICENSE_DATE);

export const hasUniqueDefaultLicenseDateOption = (context: Context): boolean =>
  isAfter(addDays(startOfDay(new Date()), 30), new Date(context.answers.dateEffetSouhaite!));
