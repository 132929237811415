import { PlusRegularIcon, TrashRegularIcon } from '@ornikar/kitt-icons/phosphor';
import { Button, HStack, Typography, VStack, View } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { InformationList } from '../../../../components/InformationList/InformationList';
import { InformationEntry } from '../../../../components/InformationList/components/InformationEntry';
import { RecapScreenTemplate } from '../../../../components/ScreenTemplates/RecapScreenTemplate';
import {
  hasTerminationRecordOptionsDict,
  secondaryDriverCivilityOptionsDict,
  secondaryDriverTypeOptionsDict,
  secondayDriverLicenseTypeOptionsDict,
} from '../../../../constants/mappers/information';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../../fsm/context';
import { Driver, Event, State } from '../../../../fsm/types';
import { formatDateForUI } from '../../../../utils/date';
import { InsuranceRecordSummaryV2 } from '../../InsuranceRecordSummaryV2';
import FingersIllustration from './assets/2fingers.svg';

export function SecondaryDriverSummaryScreen(): ReactNode {
  const send = useSubscriptionFsmDispatch();

  const moveToScreen = (destination: State): void => {
    send(Event.RETURN_TO_SCREEN, { destination });
  };

  const {
    context: {
      answers: { secondaryDriver, secondaryRelation },
    },
  } = useSubscriptionFsmState();

  const hasSecondaryDriver = !!secondaryDriver?.lastName;

  return (
    <RecapScreenTemplate
      title="Mon 2nd conducteur"
      illustration={<img src={FingersIllustration} alt="Illustration d'une main montrant deux doigts" />}
      onConfirm={() => {
        send(Event.CONTINUE);
      }}
    >
      {hasSecondaryDriver ? (
        <>
          <HStack justifyContent="space-between">
            <Typography.h4>À propos du second conducteur</Typography.h4>
            <Button icon={<TrashRegularIcon />} onPress={() => send(Event.RESTITUTION_DELETE_SECONDARY_DRIVER)} />
          </HStack>
          <VStack space="kitt.10">
            <InformationList>
              <InformationEntry
                label="Prénom"
                value={secondaryDriver!.firstName!}
                onPress={() => moveToScreen(State.INFORMATION_SECONDARY_DETAILS)}
              />
              <InformationEntry
                label="Nom"
                value={secondaryDriver!.lastName!}
                onPress={() => moveToScreen(State.INFORMATION_SECONDARY_DETAILS)}
              />
              <InformationEntry
                label="Civilité"
                value={secondaryDriverCivilityOptionsDict[secondaryDriver!.civility!]}
                onPress={() => moveToScreen(State.INFORMATION_SECONDARY_DETAILS)}
              />
              <InformationEntry
                label="Date de naissance"
                value={formatDateForUI(secondaryDriver!.birthDate!)}
                onPress={() => moveToScreen(State.INFORMATION_SECONDARY_DETAILS)}
              />
              <InformationEntry
                label="Il s'agit de"
                value={secondaryDriverTypeOptionsDict[secondaryRelation!]}
                onPress={() => moveToScreen(State.INFORMATION_SECONDARY_RELATION)}
              />
              <InformationEntry
                label="Obtention du permis"
                value={formatDateForUI(secondaryDriver!.licenseDate!)}
                onPress={() => moveToScreen(State.INFORMATION_SECONDARY_LICENSE_DATE)}
              />
              <InformationEntry
                label="Lieu d'obtention du permis"
                value={secondayDriverLicenseTypeOptionsDict[secondaryDriver!.licenseType!]}
                onPress={() => moveToScreen(State.INFORMATION_SECONDARY_LICENSE_TYPE)}
              />
              <InformationEntry
                label="Conduite accompagnée"
                value={hasTerminationRecordOptionsDict[secondaryDriver!.accompaniedDriving!]}
                onPress={() => moveToScreen(State.INFORMATION_SECONDARY_WAS_ACCOMPANIED)}
              />
            </InformationList>
            <InsuranceRecordSummaryV2 driverType={Driver.Secondary} />
          </VStack>
        </>
      ) : (
        <VStack space="kitt.4">
          <Typography.h4>Vous n'avez pas ajouté de second conducteur.</Typography.h4>
          <View alignSelf={{ base: 'stretch', large: 'flex-end' }}>
            <Button
              stretch={{ base: true, large: false }}
              icon={<PlusRegularIcon />}
              onPress={() => send(Event.RESTITUTION_EDIT_SECONDARY_DRIVER)}
            >
              Ajouter un second conducteur
            </Button>
          </View>
        </VStack>
      )}
    </RecapScreenTemplate>
  );
}
