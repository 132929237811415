import { CaretLeftRegularIcon } from '@ornikar/kitt-icons/phosphor';
import { Button } from '@ornikar/kitt-universal';
import cx from 'classnames';
import { isEqual } from 'lodash';
import type { ReactNode } from 'react';
import { Fragment, useCallback, useMemo } from 'react';
import { useFormState } from 'react-final-form';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../fsm/context';
import { mapAnswersToFormValues } from '../../fsm/mappers/mapAnswersToFormValues';
import { Event, Section, State } from '../../fsm/types';
import { sectionsConfig } from '../../hooks/Breadcrumbs/types';
import { useBreadcrumbs } from '../../hooks/Breadcrumbs/useBreadcrumbs';
import { useGoBack } from '../../hooks/useGoBack';
import { useInsuranceDesktopMediaQuery } from '../../hooks/useInsuranceDesktopMediaQuery';
import { Breadcrumb } from './Breadcrumb';
import styles from './styles.module.css';

export interface BreadcrumbsProps {
  className?: string;
}

export function Breadcrumbs({ className }: BreadcrumbsProps): ReactNode {
  const [, goBack] = useGoBack();
  const isDesktop = useInsuranceDesktopMediaQuery();
  const { context, value: stateValue } = useSubscriptionFsmState();
  const { values } = useFormState();
  const send = useSubscriptionFsmDispatch();

  const isSummarySectionReachable = (section: Section) => {
    const sections = Object.keys(Section);
    const sectionIndex = sections.indexOf(section);
    const currentAndPreviousSections = sections.slice(0, sectionIndex + 1);

    return currentAndPreviousSections.every((previousSection) => {
      return context.reachedSummaryScreenSections[previousSection as Section] !== false;
    });
  };

  const sectionToEventMapper = {
    [Section.VEHICULE]: {
      event: Event.NAVIGATE_TO_VEHICULE,
      cond: isSummarySectionReachable(Section.VEHICULE),
    },
    [Section.DRIVING]: {
      event: Event.NAVIGATE_TO_DRIVING,
      cond: isSummarySectionReachable(Section.DRIVING),
    },
    [Section.PRIMARY_DRIVER]: {
      event: Event.NAVIGATE_TO_PRIMARY_DRIVER_SUMMARY,
      cond: isSummarySectionReachable(Section.PRIMARY_DRIVER),
    },
    [Section.SECONDARY_DRIVER]: {
      event: Event.NAVIGATE_TO_SECONDARY_DRIVER_SUMMARY,
      cond: isSummarySectionReachable(Section.SECONDARY_DRIVER),
    },
    [Section.INFORMATION]: {
      event: Event.NAVIGATE_TO_INFORMATION,
      cond: isSummarySectionReachable(Section.SECONDARY_DRIVER),
    },
    [Section.TARIFICATION]: {
      event: Event.NAVIGATE_TO_TARIFICATION,
      cond: context.tarificationResult?.isOk,
    },
  };

  const handleNavigation = (section: Section): void => {
    if (sectionToEventMapper[section]?.cond && State.WEBAPP_INTRODUCTION !== stateValue) {
      send(sectionToEventMapper[section].event);
    }
  };

  const currentItem = useBreadcrumbs();

  const isQuoteRequestUpdated = useMemo(
    () => context.tarificationResult && !isEqual(mapAnswersToFormValues(context.lastQuoteRequest || {}), values),
    [context.lastQuoteRequest, context.tarificationResult, values],
  );

  const hasExceptionRule = useCallback(
    (section: Section) => {
      if (section === Section.TARIFICATION) {
        // if user go back update his quote, we want to disable the navigation to the tarification section
        if (isQuoteRequestUpdated && Section.TARIFICATION !== currentItem.section) {
          return true;
        }
      }

      return false;
    },
    [isQuoteRequestUpdated, currentItem.section],
  );

  return (
    <div className={cx(styles.Breadcrumbs, { [styles.isMobile]: !isDesktop }, className)}>
      {isDesktop ? (
        sectionsConfig.map(({ section, copy }, idx) => (
          <Fragment key={section}>
            <Breadcrumb
              hasSlash
              index={idx}
              currentIndex={currentItem.index}
              isEnabled={
                idx === 0 ||
                section === currentItem.section ||
                (sectionToEventMapper[section].cond && !hasExceptionRule(section))
              }
              onClick={() => handleNavigation(section)}
            >
              {copy}
            </Breadcrumb>
          </Fragment>
        ))
      ) : (
        <>
          <div className={styles.BackButton}>
            <Button icon={<CaretLeftRegularIcon />} type="subtle-dark" onPress={goBack} />
          </div>
          <Breadcrumb
            key={currentItem.section}
            isEnabled
            index={currentItem.index}
            currentIndex={currentItem.index}
            onClick={() => handleNavigation(currentItem.section)}
          >
            {currentItem.copy}
          </Breadcrumb>
        </>
      )}

      <div className={styles.ProgressBarContainer}>
        <div className={styles.ProgressBarActive} style={{ width: `${currentItem.percent}%` }} />
      </div>
    </div>
  );
}
