import type { ReactNode } from 'react';
import { useField } from 'react-final-form';
import type { FieldProps } from '../../../components/Field';
import { Field } from '../../../components/Field';
import { PhoneInput } from '../../../components/PhoneInput';
import styles from './styles.module.css';

interface PhoneNumberFieldProps extends Pick<FieldProps<string>, 'name' | 'validate'> {}

export function PhoneNumberField({ name, validate }: PhoneNumberFieldProps): ReactNode {
  const {
    input: { value, onChange, onBlur },
  } = useField(name);

  return (
    <Field
      component={PhoneInput}
      className={styles.Field}
      name={name}
      label="Votre numéro de mobile"
      value={value}
      validate={validate}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
}
