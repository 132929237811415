import type { Deductible, DeductibleName } from '../../../fsm/answers';
import { useSubscriptionFsmState } from '../../../fsm/context';

export const useDeductiblesPrice = (): number => {
  const {
    context: { answers, quoteOptions },
  } = useSubscriptionFsmState();

  if (answers.deductibles && quoteOptions) {
    return (
      Object.values(answers.deductibles)
        // eslint-disable-next-line unicorn/no-array-reduce
        .reduce((acc: number, { name, term }: Deductible) => {
          const foundQuoteOption = quoteOptions.deductibles.find((deductible) => deductible.name === name);

          if (foundQuoteOption) {
            const foundOption = foundQuoteOption.options.find((option) => term.value === option.term?.value);

            if (foundOption) {
              return acc + foundOption.cost.cents;
            }
          }

          return acc;
        }, 0)
    );
  }

  return 0;
};

export const useDeductibleSortedAmounts = (deductibleName: DeductibleName): number[] => {
  const {
    context: { quoteOptions },
  } = useSubscriptionFsmState();

  const deductible = quoteOptions?.deductibles?.find(({ name }) => name === deductibleName);

  const amounts = deductible?.options.map(({ amount }) => amount.cents).sort();

  return amounts || [];
};
