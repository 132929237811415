import { PencilLineRegularIcon } from '@ornikar/kitt-icons/phosphor';
import { Button, Typography, VStack, View } from '@ornikar/kitt-universal';
import * as Sentry from '@sentry/react';
import { format } from 'date-fns';
import type { ReactNode } from 'react';
import { useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { createSepaMandate } from '../../../apis/createSepaMandate';
import type { TarificationResultOK } from '../../../apis/types/Quotation';
import { Card } from '../../../components/Card';
import { ScreenTemplate } from '../../../components/ScreenTemplates/ScreenTemplate';
import { useToast } from '../../../components/Toast/useToast';
import { useSubscriptionFsmState } from '../../../fsm/context';
import { useGoBack } from '../../../hooks/useGoBack';
import { Field } from './components/Field';
import styles from './styles.module.css';

const CREDITOR_ID = 'G827ZZZSDDBARC0000007495895';

export function SepaInformationConfirmationScreen(): ReactNode {
  const { openErrorToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const { submit } = useForm();
  const { values } = useFormState();
  const [, goBack] = useGoBack();
  const {
    context: { tarificationResult },
  } = useSubscriptionFsmState();

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      await createSepaMandate((tarificationResult as TarificationResultOK).quoteId, values.ibanNumber as string);

      submit();
    } catch (error) {
      Sentry.captureException(error);

      openErrorToast(error as Error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ScreenTemplate
      hideSaveQuoteButton
      title="Vérifiez vos informations et autorisez le mandat"
      submitButton={{ label: 'Confirmer', loading: isLoading }}
      backButtonLabel="Étape précédente"
      onSubmit={handleSubmit}
    >
      <View marginBottom="kitt.4">
        <Typography.Paragraph base="header5">Mandat de prélèvement automatique SEPA :</Typography.Paragraph>
      </View>
      <Field marginBottom="kitt.6" name="Type de transaction" value="Récurrent" />
      <Field marginBottom="kitt.6" name="Date" value={format(new Date(), 'dd/MM/yyyy')} />
      <Card className={styles.Card}>
        <Field name="IBAN" value={values.ibanNumber} />
        <Field name="Nom du titulaire" value={values.ibanTitulaire} />
        <View marginLeft="-kitt.4">
          <Button icon={<PencilLineRegularIcon />} type="subtle" onPress={() => goBack()}>
            Modifier
          </Button>
        </View>
      </Card>

      <VStack space="kitt.3">
        <Typography.Paragraph color="black-light" base="body-small">
          Ornikar, 170 Boulevard de la Villette - 75019 Paris 19
          <br />
          Identifiant créditeur {CREDITOR_ID}
        </Typography.Paragraph>
        <Typography.Paragraph color="black-light" base="body-small">
          Nous vous avertirons par e-mail au moins 3 jours ouvrables à l&apos;avance en cas de changement de la date, de
          la fréquence ou du montant de vos paiements.
        </Typography.Paragraph>
        <Typography.Paragraph color="black-light" base="body-small">
          En signant ce mandat de prélèvement, vous autorisez Ornikar à envoyer des instructions à votre banque pour
          débiter votre compte, et votre banque à débiter votre compte conformément aux instructions de Ornikar. Vous
          bénéficiez d’un droit de remboursement par votre banque selon les conditions décrites dans la convention que
          vous avez passée avec elle. Toute demande de remboursement doit être présentée dans les 8 semaines suivant la
          date de débit de votre compte. Vos droits sont expliqués dans un document que vous pouvez obtenir auprès de
          votre banque.
        </Typography.Paragraph>
      </VStack>
    </ScreenTemplate>
  );
}
