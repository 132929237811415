import type { PropsWithChildren, ReactElement, ReactNode } from 'react';
import { Children, cloneElement, isValidElement } from 'react';
import type { InformationEntryProps } from './components/InformationEntry';

export function InformationList({ children }: PropsWithChildren): ReactNode {
  return Children.toArray(children)
    .filter((child) => isValidElement(child))
    .map((child, index, { length }) =>
      cloneElement(child as ReactElement<InformationEntryProps>, {
        hasSeparator: index < length - 1,
      }),
    );
}
