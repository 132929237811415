import invert from 'lodash/invert';

export enum CountryCode {
  'AD' = 'AD',
  'AE' = 'AE',
  'AF' = 'AF',
  'AG' = 'AG',
  'AI' = 'AI',
  'AL' = 'AL',
  'AM' = 'AM',
  'AO' = 'AO',
  'AQ' = 'AQ',
  'AR' = 'AR',
  'AS' = 'AS',
  'AT' = 'AT',
  'AU' = 'AU',
  'AW' = 'AW',
  'AZ' = 'AZ',
  'BA' = 'BA',
  'BB' = 'BB',
  'BD' = 'BD',
  'BE' = 'BE',
  'BF' = 'BF',
  'BG' = 'BG',
  'BH' = 'BH',
  'BI' = 'BI',
  'BJ' = 'BJ',
  'BL' = 'BL',
  'BM' = 'BM',
  'BN' = 'BN',
  'BO' = 'BO',
  'BR' = 'BR',
  'BS' = 'BS',
  'BT' = 'BT',
  'BV' = 'BV',
  'BW' = 'BW',
  'BY' = 'BY',
  'BZ' = 'BZ',
  'CA' = 'CA',
  'CC' = 'CC',
  'CD' = 'CD',
  'CF' = 'CF',
  'CG' = 'CG',
  'CH' = 'CH',
  'CI' = 'CI',
  'CK' = 'CK',
  'CL' = 'CL',
  'CM' = 'CM',
  'CN' = 'CN',
  'CO' = 'CO',
  'CR' = 'CR',
  'CU' = 'CU',
  'CV' = 'CV',
  'CX' = 'CX',
  'CY' = 'CY',
  'CZ' = 'CZ',
  'DE' = 'DE',
  'DJ' = 'DJ',
  'DK' = 'DK',
  'DM' = 'DM',
  'DO' = 'DO',
  'DZ' = 'DZ',
  'EC' = 'EC',
  'EE' = 'EE',
  'EG' = 'EG',
  'EH' = 'EH',
  'ER' = 'ER',
  'ES' = 'ES',
  'ET' = 'ET',
  'FI' = 'FI',
  'FJ' = 'FJ',
  'FK' = 'FK',
  'FM' = 'FM',
  'FO' = 'FO',
  'FR' = 'FR',
  'GA' = 'GA',
  'GB' = 'GB',
  'GD' = 'GD',
  'GE' = 'GE',
  'GF' = 'GF',
  'GH' = 'GH',
  'GI' = 'GI',
  'GL' = 'GL',
  'GM' = 'GM',
  'GN' = 'GN',
  'GP' = 'GP',
  'GQ' = 'GQ',
  'GR' = 'GR',
  'GS' = 'GS',
  'GT' = 'GT',
  'GU' = 'GU',
  'GW' = 'GW',
  'GY' = 'GY',
  'HK' = 'HK',
  'HM' = 'HM',
  'HN' = 'HN',
  'HR' = 'HR',
  'HT' = 'HT',
  'HU' = 'HU',
  'ID' = 'ID',
  'IE' = 'IE',
  'IL' = 'IL',
  'IN' = 'IN',
  'IO' = 'IO',
  'IQ' = 'IQ',
  'IR' = 'IR',
  'IS' = 'IS',
  'IT' = 'IT',
  'JM' = 'JM',
  'JO' = 'JO',
  'JP' = 'JP',
  'KE' = 'KE',
  'KG' = 'KG',
  'KH' = 'KH',
  'KI' = 'KI',
  'KM' = 'KM',
  'KN' = 'KN',
  'KP' = 'KP',
  'KR' = 'KR',
  'KW' = 'KW',
  'KY' = 'KY',
  'KZ' = 'KZ',
  'LA' = 'LA',
  'LB' = 'LB',
  'LC' = 'LC',
  'LI' = 'LI',
  'LK' = 'LK',
  'LR' = 'LR',
  'LS' = 'LS',
  'LT' = 'LT',
  'LU' = 'LU',
  'LV' = 'LV',
  'LY' = 'LY',
  'MA' = 'MA',
  'MC' = 'MC',
  'MD' = 'MD',
  'ME' = 'ME',
  'MF' = 'MF',
  'MG' = 'MG',
  'MH' = 'MH',
  'MK' = 'MK',
  'ML' = 'ML',
  'MM' = 'MM',
  'MN' = 'MN',
  'MO' = 'MO',
  'MP' = 'MP',
  'MQ' = 'MQ',
  'MR' = 'MR',
  'MS' = 'MS',
  'MT' = 'MT',
  'MU' = 'MU',
  'MV' = 'MV',
  'MW' = 'MW',
  'MX' = 'MX',
  'MY' = 'MY',
  'MZ' = 'MZ',
  'NA' = 'NA',
  'NC' = 'NC',
  'NE' = 'NE',
  'NF' = 'NF',
  'NG' = 'NG',
  'NI' = 'NI',
  'NL' = 'NL',
  'NO' = 'NO',
  'NP' = 'NP',
  'NR' = 'NR',
  'NU' = 'NU',
  'NZ' = 'NZ',
  'OM' = 'OM',
  'PA' = 'PA',
  'PE' = 'PE',
  'PF' = 'PF',
  'PG' = 'PG',
  'PH' = 'PH',
  'PK' = 'PK',
  'PL' = 'PL',
  'PM' = 'PM',
  'PN' = 'PN',
  'PR' = 'PR',
  'PS' = 'PS',
  'PT' = 'PT',
  'PW' = 'PW',
  'PY' = 'PY',
  'QA' = 'QA',
  'RE' = 'RE',
  'RO' = 'RO',
  'RS' = 'RS',
  'RU' = 'RU',
  'RW' = 'RW',
  'SA' = 'SA',
  'SB' = 'SB',
  'SC' = 'SC',
  'SD' = 'SD',
  'SE' = 'SE',
  'SG' = 'SG',
  'SH' = 'SH',
  'SI' = 'SI',
  'SJ' = 'SJ',
  'SK' = 'SK',
  'SL' = 'SL',
  'SM' = 'SM',
  'SN' = 'SN',
  'SO' = 'SO',
  'SR' = 'SR',
  'ST' = 'ST',
  'SV' = 'SV',
  'SY' = 'SY',
  'SZ' = 'SZ',
  'TC' = 'TC',
  'TD' = 'TD',
  'TF' = 'TF',
  'TG' = 'TG',
  'TH' = 'TH',
  'TJ' = 'TJ',
  'TK' = 'TK',
  'TL' = 'TL',
  'TM' = 'TM',
  'TN' = 'TN',
  'TO' = 'TO',
  'TR' = 'TR',
  'TT' = 'TT',
  'TV' = 'TV',
  'TW' = 'TW',
  'TZ' = 'TZ',
  'UA' = 'UA',
  'UG' = 'UG',
  'UM' = 'UM',
  'US' = 'US',
  'UY' = 'UY',
  'UZ' = 'UZ',
  'VA' = 'VA',
  'VC' = 'VC',
  'VE' = 'VE',
  'VG' = 'VG',
  'VI' = 'VI',
  'VN' = 'VN',
  'VU' = 'VU',
  'WF' = 'WF',
  'WS' = 'WS',
  'YE' = 'YE',
  'YT' = 'YT',
  'ZA' = 'ZA',
  'ZM' = 'ZM',
  'ZW' = 'ZW',
}

export const countryCodeToCountryNameMapping = {
  [CountryCode.AD]: 'Andorre',
  [CountryCode.AE]: 'Émirats arabes unis',
  [CountryCode.AF]: 'Afghanistan',
  [CountryCode.AG]: 'Antigua-et-Barbuda',
  [CountryCode.AI]: 'Anguilla',
  [CountryCode.AL]: 'Albanie',
  [CountryCode.AM]: 'Arménie',
  [CountryCode.AO]: 'Angola',
  [CountryCode.AQ]: 'Antarctique',
  [CountryCode.AR]: 'Argentine',
  [CountryCode.AS]: 'Samoa américaines',
  [CountryCode.AT]: 'Autriche',
  [CountryCode.AU]: 'Australie',
  [CountryCode.AW]: 'Aruba',
  [CountryCode.AZ]: 'Azerbaïdjan',
  [CountryCode.BA]: 'Bosnie-Herzégovine',
  [CountryCode.BB]: 'Barbade',
  [CountryCode.BD]: 'Bangladesh',
  [CountryCode.BE]: 'Belgique',
  [CountryCode.BF]: 'Burkina Faso',
  [CountryCode.BG]: 'Bulgarie',
  [CountryCode.BH]: 'Bahreïn',
  [CountryCode.BI]: 'Burundi',
  [CountryCode.BJ]: 'Bénin',
  [CountryCode.BL]: 'Saint-Barthélémy',
  [CountryCode.BM]: 'Bermudes',
  [CountryCode.BN]: 'Brunei',
  [CountryCode.BO]: 'Bolivie',
  [CountryCode.BR]: 'Brésil',
  [CountryCode.BS]: 'Bahamas',
  [CountryCode.BT]: 'Bhoutan',
  [CountryCode.BV]: 'Île Bouvet (Bouvetoya)',
  [CountryCode.BW]: 'Botswana',
  [CountryCode.BY]: 'Biélorussie',
  [CountryCode.BZ]: 'Belize',
  [CountryCode.CA]: 'Canada',
  [CountryCode.CC]: 'Îles Cocos (Keeling)',
  [CountryCode.CD]: 'Congo - Kinshasa (243)',
  [CountryCode.CF]: 'République centrafricaine',
  [CountryCode.CG]: 'Congo - Brazzaville',
  [CountryCode.CH]: 'Suisse',
  [CountryCode.CI]: "Côte d'Ivoire",
  [CountryCode.CK]: 'Îles Cook',
  [CountryCode.CL]: 'Chili',
  [CountryCode.CM]: 'Cameroun',
  [CountryCode.CN]: 'Chine',
  [CountryCode.CO]: 'Colombie',
  [CountryCode.CR]: 'Costa Rica',
  [CountryCode.CU]: 'Cuba',
  [CountryCode.CV]: 'Cap-Vert',
  [CountryCode.CX]: 'Île Christmas',
  [CountryCode.CY]: 'Chypre',
  [CountryCode.CZ]: 'République tchèque',
  [CountryCode.DE]: 'Allemagne',
  [CountryCode.DJ]: 'Djibouti',
  [CountryCode.DK]: 'Danemark',
  [CountryCode.DM]: 'Dominique',
  [CountryCode.DO]: 'République dominicaine',
  [CountryCode.DZ]: 'Algérie',
  [CountryCode.EC]: 'Équateur',
  [CountryCode.EE]: 'Estonie',
  [CountryCode.EG]: 'Égypte',
  [CountryCode.EH]: 'Sahara occidental',
  [CountryCode.ER]: 'Érythrée',
  [CountryCode.ES]: 'Espagne',
  [CountryCode.ET]: 'Éthiopie',
  [CountryCode.FI]: 'Finlande',
  [CountryCode.FJ]: 'Fidji, République des Fidji',
  [CountryCode.FK]: 'Îles Falkland (Malouines)',
  [CountryCode.FM]: 'Micronésie, États fédérés de',
  [CountryCode.FO]: 'Féroé, Iles',
  [CountryCode.FR]: 'France',
  [CountryCode.GA]: 'Gabon',
  [CountryCode.GB]: 'Royaume-Uni',
  [CountryCode.GD]: 'Grenade',
  [CountryCode.GE]: 'Géorgie',
  [CountryCode.GF]: 'Guyane française',
  [CountryCode.GH]: 'Ghana',
  [CountryCode.GI]: 'Gibraltar',
  [CountryCode.GL]: 'Groenland',
  [CountryCode.GM]: 'Gambie',
  [CountryCode.GN]: 'Guinée',
  [CountryCode.GP]: 'Guadeloupe',
  [CountryCode.GQ]: 'Guinée équatoriale',
  [CountryCode.GR]: 'Grèce',
  [CountryCode.GS]: 'Géorgie du Sud et Îles Sandwich du Sud',
  [CountryCode.GT]: 'Guatemala',
  [CountryCode.GU]: 'Guam',
  [CountryCode.GW]: 'Guinée-Bissau',
  [CountryCode.GY]: 'Guyana',
  [CountryCode.HK]: 'Hong-Kong, RAS (Chine)',
  [CountryCode.HM]: 'Îles Heard et McDonald',
  [CountryCode.HN]: 'Honduras',
  [CountryCode.HR]: 'Croatie',
  [CountryCode.HT]: 'Haïti',
  [CountryCode.HU]: 'Hongrie',
  [CountryCode.ID]: 'Indonésie',
  [CountryCode.IE]: 'Irlande',
  [CountryCode.IL]: 'Israël',
  [CountryCode.IN]: 'Inde',
  [CountryCode.IO]: "Territoire britannique de l'océan Indien (archipel de Chagos)",
  [CountryCode.IQ]: 'Irak',
  [CountryCode.IR]: 'Iran',
  [CountryCode.IS]: 'Islande',
  [CountryCode.IT]: 'Italie',
  [CountryCode.JM]: 'Jamaïque',
  [CountryCode.JO]: 'Jordanie',
  [CountryCode.JP]: 'Japon',
  [CountryCode.KE]: 'Kenya',
  [CountryCode.KG]: 'Kirghizistan, République kirghize',
  [CountryCode.KH]: 'Cambodge',
  [CountryCode.KI]: 'Kiribati',
  [CountryCode.KM]: 'Comores, Iles',
  [CountryCode.KN]: 'Saint-Kitts-et-Nevis',
  [CountryCode.KP]: 'Corée du Nord',
  [CountryCode.KR]: 'Corée du Sud',
  [CountryCode.KW]: 'Koweït',
  [CountryCode.KY]: 'Îles Caïman',
  [CountryCode.KZ]: 'Kazakhstan',
  [CountryCode.LA]: 'Laos',
  [CountryCode.LB]: 'Liban',
  [CountryCode.LC]: 'Sainte-Lucie',
  [CountryCode.LI]: 'Liechtenstein',
  [CountryCode.LK]: 'Sri Lanka',
  [CountryCode.LR]: 'Libéria',
  [CountryCode.LS]: 'Lesotho',
  [CountryCode.LT]: 'Lituanie',
  [CountryCode.LU]: 'Luxembourg',
  [CountryCode.LV]: 'Lettonie',
  [CountryCode.LY]: 'Libye',
  [CountryCode.MA]: 'Maroc',
  [CountryCode.MC]: 'Monaco',
  [CountryCode.MD]: 'Moldavie',
  [CountryCode.ME]: 'Monténégro',
  [CountryCode.MF]: 'Saint-Martin',
  [CountryCode.MG]: 'Madagascar',
  [CountryCode.MH]: 'Îles Marshall',
  [CountryCode.MK]: 'Macédoine',
  [CountryCode.ML]: 'Mali',
  [CountryCode.MM]: 'Myanmar [Birmanie]',
  [CountryCode.MN]: 'Mongolie',
  [CountryCode.MO]: 'Macao, RAS (Chine)',
  [CountryCode.MP]: 'Îles Mariannes septentrionales',
  [CountryCode.MQ]: 'Martinique',
  [CountryCode.MR]: 'Mauritanie',
  [CountryCode.MS]: 'Montserrat',
  [CountryCode.MT]: 'Malte',
  [CountryCode.MU]: 'Maurice',
  [CountryCode.MV]: 'Maldives',
  [CountryCode.MW]: 'Malawi',
  [CountryCode.MX]: 'Mexico',
  [CountryCode.MY]: 'Malaisie',
  [CountryCode.MZ]: 'Mozambique',
  [CountryCode.NA]: 'Namibie',
  [CountryCode.NC]: 'Nouvelle-Calédonie',
  [CountryCode.NE]: 'Niger',
  [CountryCode.NF]: 'Île Norfolk',
  [CountryCode.NG]: 'Nigeria',
  [CountryCode.NI]: 'Nicaragua',
  [CountryCode.NL]: 'Pays-Bas',
  [CountryCode.NO]: 'Norvège',
  [CountryCode.NP]: 'Népal',
  [CountryCode.NR]: 'Nauru',
  [CountryCode.NU]: 'Niué',
  [CountryCode.NZ]: 'Nouvelle-Zélande',
  [CountryCode.OM]: 'Oman',
  [CountryCode.PA]: 'Panama',
  [CountryCode.PE]: 'Pérou',
  [CountryCode.PF]: 'Polynésie française',
  [CountryCode.PG]: 'Papouasie-Nouvelle-Guinée',
  [CountryCode.PH]: 'Philippines',
  [CountryCode.PK]: 'Pakistan',
  [CountryCode.PL]: 'Pologne',
  [CountryCode.PM]: 'Saint-Pierre-et-Miquelon',
  [CountryCode.PN]: 'Île Pitcairn',
  [CountryCode.PR]: 'Porto Rico',
  [CountryCode.PS]: 'Autorité palestinienne intérimaire autonome',
  [CountryCode.PT]: 'Portugal',
  [CountryCode.PW]: 'Palaos',
  [CountryCode.PY]: 'Paraguay',
  [CountryCode.QA]: 'Qatar',
  [CountryCode.RE]: 'Réunion',
  [CountryCode.RO]: 'Roumanie',
  [CountryCode.RS]: 'Serbie',
  [CountryCode.RU]: 'Russie',
  [CountryCode.RW]: 'Rwanda',
  [CountryCode.SA]: 'Arabie saoudite',
  [CountryCode.SB]: 'Îles Salomon',
  [CountryCode.SC]: 'Seychelles',
  [CountryCode.SD]: 'Soudan',
  [CountryCode.SE]: 'Suède',
  [CountryCode.SG]: 'Singapour',
  [CountryCode.SH]: 'Sainte-Hélène',
  [CountryCode.SI]: 'Slovénie',
  [CountryCode.SJ]: 'Îles Svalbard et Jan Mayen',
  [CountryCode.SK]: 'Slovaquie',
  [CountryCode.SL]: 'Sierra Leone',
  [CountryCode.SM]: 'Saint-Marin',
  [CountryCode.SN]: 'Sénégal',
  [CountryCode.SO]: 'Somalie',
  [CountryCode.SR]: 'Suriname',
  [CountryCode.ST]: 'Sao Tomé-et-Principe',
  [CountryCode.SV]: 'El Salvador',
  [CountryCode.SY]: 'Syrie',
  [CountryCode.SZ]: 'Swaziland',
  [CountryCode.TC]: 'Îles Turques et Caïques',
  [CountryCode.TD]: 'Tchad',
  [CountryCode.TF]: 'Terres australes françaises',
  [CountryCode.TG]: 'Togo',
  [CountryCode.TH]: 'Thaïlande',
  [CountryCode.TJ]: 'Tadjikistan',
  [CountryCode.TK]: 'Tokelau (Îles Tokelau)',
  [CountryCode.TL]: 'Timor-Leste',
  [CountryCode.TM]: 'Turkménistan',
  [CountryCode.TN]: 'Tunisie',
  [CountryCode.TO]: 'Tonga',
  [CountryCode.TR]: 'Turquie',
  [CountryCode.TT]: 'Trinité-et-Tobago',
  [CountryCode.TV]: 'Tuvalu',
  [CountryCode.TW]: 'Taïwan',
  [CountryCode.TZ]: 'Tanzanie',
  [CountryCode.UA]: 'Ukraine',
  [CountryCode.UG]: 'Ouganda',
  [CountryCode.UM]: 'Îles mineures éloignées des États-Unis',
  [CountryCode.US]: 'États-Unis',
  [CountryCode.UY]: 'Uruguay',
  [CountryCode.UZ]: 'Ouzbékistan',
  [CountryCode.VA]: 'Vatican',
  [CountryCode.VC]: 'St- Vincent-et-les Grenadines',
  [CountryCode.VE]: 'Venezuela',
  [CountryCode.VG]: 'Îles Vierges britanniques',
  [CountryCode.VI]: 'Îles Vierges américaines',
  [CountryCode.VN]: 'Vietnam',
  [CountryCode.VU]: 'Vanuatu',
  [CountryCode.WF]: 'Îles Wallis-et-Futuna',
  [CountryCode.WS]: 'Samoa',
  [CountryCode.YE]: 'Yémen',
  [CountryCode.YT]: 'Mayotte',
  [CountryCode.ZA]: 'Afrique du Sud',
  [CountryCode.ZM]: 'Zambie',
  [CountryCode.ZW]: 'Zimbabwe',
};

export const countryNameToCountryCodeMapping = invert(countryCodeToCountryNameMapping) as Record<string, CountryCode>;
