import { VStack, View } from '@ornikar/kitt-universal';
import { type ReactNode, useMemo, useState } from 'react';
import React from 'react';
import { OrnikarPackageLevel } from '../../../../../apis/types/Formula';
import type { PackageCoverage } from '../../../../../apis/types/Formula';
import { OptionDropdown } from './components/OptionDropdown';
import { OptionsSeparator } from './components/OptionsSeparator';
import type { TabsProps } from './components/Tabs';
import { Tabs } from './components/Tabs';

interface PackageWithUniqueCoverages {
  name: OrnikarPackageLevel;
  coverages: PackageCoverage[];
  isActive: boolean;
}

interface FormulesTabsMobileProps extends TabsProps {
  onCoverageViewed: (label: string) => void;
}

export function FormulesTabsMobile({
  packages,
  formuleSelected = packages[0],
  paymentPeriodicity,
  onClick,
  onCoverageViewed,
}: FormulesTabsMobileProps): ReactNode {
  const [openedItemLabel, setOpenedItemLabel] = useState<string | null>(null);

  const toggleAccordion = (label: string): void => {
    setOpenedItemLabel((currentLabel) => {
      const nextLabel = currentLabel !== label ? label : null;

      if (nextLabel) {
        onCoverageViewed(nextLabel);
      }

      return nextLabel;
    });
  };

  const packagesWithUniqueCoverages: PackageWithUniqueCoverages[] = useMemo(() => {
    const sortedPackages = [...packages].sort((a, b) => a.coverages.length - b.coverages.length);

    return sortedPackages.map((packageItem, index) => {
      const uniqueCoverages = packageItem.coverages.filter((coverage) => {
        const previousPackageCoverages = sortedPackages[index - 1]?.coverages ?? [];

        return previousPackageCoverages.every(({ label }) => coverage.label !== label);
      });

      return {
        name: packageItem.name,
        coverages: uniqueCoverages,
        isActive: index <= sortedPackages.findIndex(({ name }) => formuleSelected.name === name),
      };
    });
  }, [packages, formuleSelected]);

  return (
    <VStack>
      <Tabs
        packages={packages}
        formuleSelected={formuleSelected}
        paymentPeriodicity={paymentPeriodicity}
        onClick={onClick}
      />
      <View backgroundColor="kitt.white" borderBottomRadius="kitt.5" paddingX="kitt.4" paddingY="kitt.6">
        <VStack space="kitt.4">
          {packagesWithUniqueCoverages.map(({ name, coverages, isActive }) => {
            return (
              <React.Fragment key={name}>
                <OptionsSeparator
                  isActive={isActive}
                  formuleName={name === OrnikarPackageLevel.THIRD_PARTY ? undefined : name}
                />

                {coverages.map((coverage) => (
                  <OptionDropdown
                    key={coverage.label}
                    name={coverage.label}
                    isEnabled={isActive}
                    description={coverage.description}
                    deductibles={coverage.deductibles}
                    reimbursements={coverage.reimbursements}
                    isOpen={openedItemLabel === coverage.label}
                    open={() => toggleAccordion(coverage.label)}
                  />
                ))}
              </React.Fragment>
            );
          })}
        </VStack>
      </View>
    </VStack>
  );
}
