import { startOfDay } from 'date-fns';
import { formatDate } from '../../utils/date';
import { DeclareSecondaryDriverType } from '../answers';
import type { Context, Driver, Event } from '../types';
import type { AnswerAssign, SimpleEventAssign } from './types';
import { assignAnswer } from './utils';

export const resetDriverHasBeenCovered: (driver: Driver) => SimpleEventAssign<Event.ANSWER> = (driver) =>
  assignAnswer(`${driver}Driver.hasBeenCovered`, undefined);

export const resetDriverBonusYearsAndInsurancePeriods: (driver: Driver) => SimpleEventAssign<Event.ANSWER> = (driver) =>
  assignAnswer(
    { path: `${driver}Driver.yearsSinceHighestBonus`, value: undefined },
    { path: `${driver}Driver.insurancePeriods`, value: [] },
  );

export const setInsurancePeriods: (driver: Driver) => AnswerAssign = (driver) =>
  assignAnswer(`${driver}Driver.insurancePeriods`, (context: Context) => {
    const previousPeriods = context.answers[`${driver}Driver`]?.insurancePeriods;
    return previousPeriods?.length
      ? previousPeriods
      : [
          {
            start: '',
            end: '',
          },
        ];
  });

export const resetInsurancePeriods: (driver: Driver) => AnswerAssign = (driver) =>
  assignAnswer(`${driver}Driver.insurancePeriods`, []);

export const declareSecondaryDriver: SimpleEventAssign<Event.RESTITUTION_EDIT_SECONDARY_DRIVER> = assignAnswer(
  'declareSecondaryDriver',
  DeclareSecondaryDriverType.Oui,
);

export const deleteSecondaryDriver: SimpleEventAssign<Event.RESTITUTION_DELETE_SECONDARY_DRIVER | Event.ANSWER> =
  assignAnswer(
    {
      path: 'declareSecondaryDriver',
      value: DeclareSecondaryDriverType.Non,
    },
    {
      path: 'secondaryDriver',
      value: {
        hasBeenCovered: undefined,
        firstName: undefined,
        lastName: undefined,
        birthDate: undefined,
        civility: undefined,
        licenseDate: undefined,
        accompaniedDriving: undefined,
        crm: undefined,
        yearsSinceHighestBonus: undefined,
        maritalStatus: undefined,
        insurancePeriods: [],
        profession: undefined,
        claimQuantity: undefined,
        terminationRecords: [],
        cancellationOrSuspensionRecords: [],
        claimRecords: [],
      },
    },
    {
      path: 'secondaryRelation',
      value: undefined,
    },
  );

export const setPrimaryDriverUniqueDefaultLicenseDate = assignAnswer('primaryDriver.licenseDate', () =>
  formatDate(startOfDay(new Date())),
);
