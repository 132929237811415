import { AssureDepuisType, DejaAssureAvecNomType, FinancementType } from '../../apis/types/LongQuoteAnswers';
import { mapOptionsListToOptionsDict } from './utils';

export const vehicleInsuredSinceOptionsList = [
  {
    label: "Depuis plus d'un an",
    value: AssureDepuisType.Plus12Mois,
  },
  {
    label: "Depuis moins d'un an",
    value: AssureDepuisType.Moins12Mois,
  },
];

export const vehicleInsuredSinceOptionsDict =
  mapOptionsListToOptionsDict<AssureDepuisType>(vehicleInsuredSinceOptionsList);

export const vehicleInsuredByOptionsList = [
  {
    label: 'Oui à mon nom',
    value: DejaAssureAvecNomType.OuiAMonNom,
  },
  {
    label: 'Oui, mais pas à mon nom',
    value: DejaAssureAvecNomType.OuiPasAMonNom,
  },
  {
    label: 'Non',
    value: DejaAssureAvecNomType.Non,
  },
];

export const vehicleInsuredByOptionsDict =
  mapOptionsListToOptionsDict<DejaAssureAvecNomType>(vehicleInsuredByOptionsList);

export const vehicleFundingOptionsList = [
  {
    label: 'Au comptant',
    value: FinancementType.comptant,
  },
  {
    label: 'À crédit',
    value: FinancementType.credit,
  },
  {
    label: 'En location longue durée (LLD) ',
    value: FinancementType.LLD,
  },
  {
    label: "En location avec option d'achat (LOA)",
    value: FinancementType.LOA,
  },
  {
    label: 'Autre (héritage, don...)',
    value: FinancementType.Don,
  },
];

export const vehicleFundingOptionsDict = mapOptionsListToOptionsDict<FinancementType>(vehicleFundingOptionsList);
