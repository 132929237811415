import { Form } from '@ornikar/react-forms';
import type { ReactNode } from 'react';
import { useEffect, useMemo } from 'react';
import { MemoizedStateSwitch as StateSwitch } from '../StateSwitch';
import { ABTastyIdentifier } from '../components/ABTastyIdentifier';
import { LoadingScreen } from '../components/LoadingScreen';
import { SubscriptionFlowLayout } from '../components/SubscriptionFlowLayout';
import type { FormValues } from '../forms/answers';
import { useSubmission } from '../forms/submission';
import { SubscriptionFsmProvider, useSubscriptionFsmState } from '../fsm/context';
import { mapAnswersToFormValues } from '../fsm/mappers/mapAnswersToFormValues';
import { MAPPED_STATES_TO_SECTIONS, Section, State } from '../fsm/types';
import { useAnalyticsStateChange } from '../hooks/useAnalyticsStateChange';
import { useCripsTracking } from '../hooks/useCrispTracking';
import { useInsuranceDesktopMediaQuery } from '../hooks/useInsuranceDesktopMediaQuery';
import { useRegisterDataToMixpanel } from '../hooks/useRegisterDataToMixpanel';
import { useSaveStateMachineConfig } from '../hooks/useSaveStateMachineConfig';
import { useStateMachineRetriever } from '../hooks/useStateMachineRetriever';
import { getIsHeaderVisible, getIsHelperButtonVisible, getIsSaveQuoteButtonVisible } from '../layoutConfig';
import { sendNeedHelpGTMEvent } from '../utils/dataLayer';
import { sendEvent } from '../utils/mixpanel';

const beforeUnload = (e: BeforeUnloadEvent) => {
  const event = e || window.event;

  if (event) {
    e.returnValue = 'Les modifications que vous avez apportées ne seront peut-être pas enregistrées.';
  }

  return 'Les modifications que vous avez apportées ne seront peut-être pas enregistrées.';
};

export function InsuranceSubscription(): ReactNode {
  const { context, value: fsmState } = useSubscriptionFsmState();

  const state = fsmState as State;
  const submit = useSubmission();
  const isDesktop = useInsuranceDesktopMediaQuery();

  useSaveStateMachineConfig();
  useRegisterDataToMixpanel();
  useAnalyticsStateChange();
  useCripsTracking();

  const sendOpenHelpSectionEvent = () => {
    sendEvent('Open Help Section');
    sendNeedHelpGTMEvent(context.answers.subscriberEmail);
  };

  const headerVisible = getIsHeaderVisible(state, isDesktop);

  const saveQuoteButtonVisible = getIsSaveQuoteButtonVisible(state as State);
  const helperButtonVisible = getIsHelperButtonVisible(state);

  useEffect(() => {
    if (state === State.WEBAPP_INTRODUCTION || state === State.FORMULES || state === State.CANNOT_QUOTE) {
      window.removeEventListener('beforeunload', beforeUnload);
    } else if (!window.onbeforeunload) {
      window.addEventListener('beforeunload', beforeUnload);
    }

    window.scrollTo(0, 0);
  }, [state]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialValues = useMemo(() => mapAnswersToFormValues(context.answers), [context.answers, fsmState]);

  const currentSection = MAPPED_STATES_TO_SECTIONS[fsmState as State];
  const backgroundColor = currentSection === Section.TARIFICATION ? 'seashell' : 'white';

  const shouldHaveLateOceanHeader = state === State.FORMULES;

  return (
    <SubscriptionFlowLayout
      headerVisible={headerVisible}
      saveQuoteButtonVisible={saveQuoteButtonVisible}
      helperButtonVisible={helperButtonVisible}
      backgroundColor={backgroundColor}
      shouldHaveLateOceanHeader={shouldHaveLateOceanHeader}
      shouldRenderFooter={state !== State.FORMULES && state !== State.CREDIT_CARD_PAYMENT}
      onOpenHelperModal={sendOpenHelpSectionEvent}
    >
      <Form<FormValues> initialValues={initialValues} render={() => <StateSwitch />} onSubmit={submit} />
    </SubscriptionFlowLayout>
  );
}

export function InsuranceSubscriptionProvider(): ReactNode {
  const { stateMachine, isLoadingStateMachine } = useStateMachineRetriever();

  if (isLoadingStateMachine) {
    return <LoadingScreen />;
  }

  return (
    // @dev: We need to force rerender the provider to restart the state machine.
    // Might be more performant with useService. To investigate
    <SubscriptionFsmProvider key={stateMachine.id} machine={stateMachine}>
      <InsuranceSubscription />
      <ABTastyIdentifier />
    </SubscriptionFsmProvider>
  );
}
