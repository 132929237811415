import { omit } from 'lodash';
import { useEffect } from 'react';
import { SENSITIVE_ANSWERS } from '../constants/sensitiveAnswers';
import { useSubscriptionFsmState } from '../fsm/context';
import { STATE_MACHINE_CONFIG_KEY } from '../fsm/types';
import { saveJSONLocalStorage } from '../utils/safelocalstorage';

export function useSaveStateMachineConfig(): void {
  const state = useSubscriptionFsmState();

  useEffect(() => {
    saveJSONLocalStorage(STATE_MACHINE_CONFIG_KEY, {
      context: { ...state.context, answers: omit(state.context.answers, SENSITIVE_ANSWERS) },
      stateValue: state.value,
    });
  }, [state.context, state.value]);
}
