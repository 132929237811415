import { format } from 'date-fns';
import {
  AssureDepuisType,
  DejaAssureAvecNomType,
  FinancementType,
  ParkingType,
  TitulaireCarteGriseType,
  UsageType,
} from '../../../apis/types/LongQuoteAnswers';
import { RegistrationDocumentOwnerIsSubscriberType, YesNoQuestion } from '../../answers';
import type { StateMachineConfig } from '../../types';
import { State } from '../../types';

export const PRIMARY_HAS_BEEN_COVERED: Pick<StateMachineConfig, 'id' | 'context' | 'stateValue'> = {
  id: 'preFilled-HAS_BEEN_COVERED',
  context: {
    answers: {
      vehiculeMarque: 'CITROEN',
      vehiculeModele: 'XSARA PICASSO',
      vehiculePuissanceFiscale: 5,
      vehiculeCarburant: 'GASOIL',
      vehiculeMiseEnCirculation: '2012-01-12',
      vehiculeImmatriculation: 'AC123AB',
      vehiculeVersion: 'CI41042',
      vehiculeVersionName: 'CITROEN XSARA PICASSO 1.6 HDI 92',
      vehiculePreSelectionType: 1,
      vehiculeIsUtility: false,
      vehiculeVersionOptions: [],
      vehiculeDejaAssureAvecNom: DejaAssureAvecNomType.OuiAMonNom,
      vehiculeAcquisition: '2012-01-01',
      vehiculeFinancement: FinancementType.comptant,
      dateEffetSouhaite: format(new Date(), 'yyyy-MM-dd'),
      vehiculeAssureDepuis: AssureDepuisType.Plus12Mois,
      primaryDriver: {
        licenseDate: '2009-01-01',
        accompaniedDriving: YesNoQuestion.YES,
      },
      usage: UsageType.leisure,
      kilometersPerYear: 15000,
      parkingLocation: ParkingType.public,
      parkingCodePostal: '75013',
      parkingCommune: 'PARIS 13',
      registrationDocumentOwnerIsSubscriber: RegistrationDocumentOwnerIsSubscriberType.Oui,
      titulaireCartegrise: TitulaireCarteGriseType.Souscripteur,
    },
    defaultAnswers: [],
    isLoading: false,
    availableBrands: [],
    availableModels: [],
    availableCarburants: ['ELECTRIQUE', 'ESSENCE', 'GASOIL'],
    availablePuissanceFiscales: [1, 2, 4, 5, 6, 7, 8],
    availableVersions: [
      {
        brand: 'PEUGEOT',
        model: '106',
        version: 'ATOLL',
        body: 'BERLINE',
        energy: 'ESSENCE',
        transmission: 'MANUELLE',
        tax_horsepower: 4,
        mines_type_code: '1CCDZ2',
        cnit_code: '',
        built_from: '1994-01-01',
        built_to: '1994-12-31',
        seats: 5,
        empty_weight: 780,
        gross_weight_rating: 1220,
        is_utility: false,
        ids: ['PE18069', 'PE18068'],
        id: 'PE18069',
      } as any,
      {
        brand: 'PEUGEOT',
        model: '106',
        version: 'BAHIA 1.0',
        body: 'BERLINE 5 PORTES',
        energy: 'ESSENCE',
        transmission: 'MANUELLE',
        tax_horsepower: 4,
        mines_type_code: '1ACDZE',
        cnit_code: '',
        built_from: '1997-01-01',
        built_to: '1997-12-31',
        seats: 5,
        empty_weight: 815,
        gross_weight_rating: 1235,
        is_utility: false,
        ids: ['PE18161', 'PE18160'],
        id: 'PE18161',
      },
      {
        brand: 'PEUGEOT',
        model: '106',
        version: 'CARTE NOIRE OU ROUGE',
        body: 'BERLINE',
        energy: 'ESSENCE',
        transmission: 'MANUELLE',
        tax_horsepower: 4,
        mines_type_code: '1AHDZ8',
        cnit_code: '',
        built_from: '1994-01-01',
        built_to: '1994-12-31',
        seats: 5,
        empty_weight: 800,
        gross_weight_rating: 1240,
        is_utility: false,
        ids: ['PE18052', 'PE18051'],
        id: 'PE18052',
      },
      {
        brand: 'PEUGEOT',
        model: '106',
        version: 'CARTOON 1.0',
        body: 'BERLINE 3 PORTES',
        energy: 'ESSENCE',
        transmission: 'MANUELLE',
        tax_horsepower: 4,
        mines_type_code: '1CCDZE',
        cnit_code: '',
        built_from: '1997-01-01',
        built_to: '1999-12-31',
        seats: 5,
        empty_weight: 795,
        gross_weight_rating: 1215,
        is_utility: false,
        ids: ['PE18181', 'PE18182'],
        id: 'PE18181',
      },
      {
        brand: 'PEUGEOT',
        model: '106',
        version: 'CHERIE FM',
        body: 'BERLINE',
        energy: 'ESSENCE',
        transmission: 'MANUELLE',
        tax_horsepower: 4,
        mines_type_code: '1AHDZ8',
        cnit_code: '',
        built_from: '1995-01-01',
        built_to: '1995-12-31',
        seats: 5,
        empty_weight: 800,
        gross_weight_rating: 1240,
        is_utility: false,
        ids: ['PE18084', 'PE18083'],
        id: 'PE18084',
      },
      {
        brand: 'PEUGEOT',
        model: '106',
        version: 'CLIM',
        body: 'BERLINE',
        energy: 'ESSENCE',
        transmission: 'MANUELLE',
        tax_horsepower: 4,
        mines_type_code: '1CHDZ8',
        cnit_code: '',
        built_from: '1995-01-01',
        built_to: '1995-12-31',
        seats: 5,
        empty_weight: 780,
        gross_weight_rating: 1220,
        is_utility: false,
        ids: ['PE18085'],
        id: 'PE18085',
      },
      {
        brand: 'PEUGEOT',
        model: '106',
        version: 'COLOR LINE 1.1E',
        body: 'BERLINE 3 PORTES',
        energy: 'ESSENCE',
        transmission: 'MANUELLE',
        tax_horsepower: 4,
        mines_type_code: '1CHFXF',
        cnit_code: '',
        built_from: '2000-01-01',
        built_to: '2003-07-01',
        seats: 5,
        empty_weight: 890,
        gross_weight_rating: 1295,
        is_utility: false,
        ids: ['PE18231', 'PE18230'],
        id: 'PE18231',
      },
      {
        brand: 'PEUGEOT',
        model: '106',
        version: 'GREEN',
        body: 'BERLINE',
        energy: 'ESSENCE',
        transmission: 'MANUELLE',
        tax_horsepower: 4,
        mines_type_code: '1AHDZ8',
        cnit_code: '',
        built_from: '1994-01-01',
        built_to: '1996-12-31',
        seats: 5,
        empty_weight: 800,
        gross_weight_rating: 1240,
        is_utility: false,
        ids: ['PE18054', 'PE18055'],
        id: 'PE18054',
      },
      {
        brand: 'PEUGEOT',
        model: '106',
        version: 'ITINEA 1.0',
        body: 'BERLINE 3 PORTES',
        energy: 'ESSENCE',
        transmission: 'MANUELLE',
        tax_horsepower: 4,
        mines_type_code: '1CCDZE',
        cnit_code: '',
        built_from: '1998-01-01',
        built_to: '1998-12-31',
        seats: 5,
        empty_weight: 795,
        gross_weight_rating: 1215,
        is_utility: false,
        ids: ['PE18198', 'PE18199'],
        id: 'PE18198',
      },
      {
        brand: 'PEUGEOT',
        model: '106',
        version: 'JINGLE',
        body: 'BERLINE',
        energy: 'ESSENCE',
        transmission: 'MANUELLE',
        tax_horsepower: 4,
        mines_type_code: '1CHDZ8',
        cnit_code: '',
        built_from: '1995-01-01',
        built_to: '1995-12-31',
        seats: 5,
        empty_weight: 780,
        gross_weight_rating: 1220,
        is_utility: false,
        ids: ['PE18076'],
        id: 'PE18076',
      },
      {
        brand: 'PEUGEOT',
        model: '106',
        version: 'KID',
        body: 'BERLINE',
        energy: 'ESSENCE',
        transmission: 'MANUELLE',
        tax_horsepower: 4,
        mines_type_code: '1ACDZ2',
        cnit_code: '',
        built_from: '1994-01-01',
        built_to: '1996-12-31',
        seats: 5,
        empty_weight: 800,
        gross_weight_rating: 1240,
        is_utility: false,
        ids: ['PE18048', 'PE18047'],
        id: 'PE18048',
      },
      {
        brand: 'PEUGEOT',
        model: '106',
        version: 'KID 1.0',
        body: 'BERLINE 5 PORTES',
        energy: 'ESSENCE',
        transmission: 'MANUELLE',
        tax_horsepower: 4,
        mines_type_code: '1ACDZD',
        cnit_code: '',
        built_from: '1996-01-01',
        built_to: '1996-12-31',
        seats: 5,
        empty_weight: 815,
        gross_weight_rating: 1235,
        is_utility: false,
        ids: ['PE18106', 'PE18141', 'PE18140', 'PE18105'],
        id: 'PE18106',
      },
      {
        brand: 'PEUGEOT',
        model: '106',
        version: 'OPEN',
        body: 'BERLINE',
        energy: 'ESSENCE',
        transmission: 'MANUELLE',
        tax_horsepower: 4,
        mines_type_code: '1CHDZ8',
        cnit_code: '',
        built_from: '1996-01-01',
        built_to: '1996-12-31',
        seats: 5,
        empty_weight: 780,
        gross_weight_rating: 1220,
        is_utility: false,
        ids: ['PE18092', 'PE18093'],
        id: 'PE18092',
      },
      {
        brand: 'PEUGEOT',
        model: '106',
        version: 'OPEN 1.0E',
        body: 'BERLINE 5 PORTES',
        energy: 'ESSENCE',
        transmission: 'MANUELLE',
        tax_horsepower: 4,
        mines_type_code: '1ACDZE',
        cnit_code: '',
        built_from: '1998-01-01',
        built_to: '2000-04-01',
        seats: 5,
        empty_weight: 815,
        gross_weight_rating: 1235,
        is_utility: false,
        ids: ['PE18201', 'PE18200'],
        id: 'PE18201',
      },
      {
        brand: 'PEUGEOT',
        model: '106',
        version: 'OPEN 1.1E',
        body: 'BERLINE 5 PORTES',
        energy: 'ESSENCE',
        transmission: 'MANUELLE',
        tax_horsepower: 4,
        mines_type_code: '1AHFXF',
        cnit_code: '',
        built_from: '2000-01-01',
        built_to: '2000-09-11',
        seats: 5,
        empty_weight: 910,
        gross_weight_rating: 1315,
        is_utility: false,
        ids: ['PE18234', 'PE18235'],
        id: 'PE18234',
      },
      {
        brand: 'PEUGEOT',
        model: '106',
        version: 'OPEN PACK 1.0E',
        body: 'BERLINE 5 PORTES',
        energy: 'ESSENCE',
        transmission: 'MANUELLE',
        tax_horsepower: 4,
        mines_type_code: '1ACDZE',
        cnit_code: '',
        built_from: '1999-01-01',
        built_to: '2000-04-01',
        seats: 5,
        empty_weight: 815,
        gross_weight_rating: 1235,
        is_utility: false,
        ids: ['PE18220', 'PE18221'],
        id: 'PE18220',
      },
      {
        brand: 'PEUGEOT',
        model: '106',
        version: 'OPEN PACK 1.1E',
        body: 'BERLINE 3 PORTES',
        energy: 'ESSENCE',
        transmission: 'MANUELLE',
        tax_horsepower: 4,
        mines_type_code: '1CHFXF',
        cnit_code: '',
        built_from: '2000-01-01',
        built_to: '2001-01-02',
        seats: 5,
        empty_weight: 890,
        gross_weight_rating: 1295,
        is_utility: false,
        ids: ['PE18233', 'PE18232'],
        id: 'PE18233',
      },
      {
        brand: 'PEUGEOT',
        model: '106',
        version: 'POP ART 1.1E',
        body: 'BERLINE 5 PORTES',
        energy: 'ESSENCE',
        transmission: 'MANUELLE',
        tax_horsepower: 4,
        mines_type_code: '1AHFXF',
        cnit_code: '',
        built_from: '2002-03-01',
        built_to: '2003-07-01',
        seats: 5,
        empty_weight: 910,
        gross_weight_rating: 1315,
        is_utility: false,
        ids: ['PE18259', 'PE18258'],
        id: 'PE18259',
      },
      {
        brand: 'PEUGEOT',
        model: '106',
        version: 'QUIKSILVER 1.1E',
        body: 'BERLINE 3 PORTES',
        energy: 'ESSENCE',
        transmission: 'MANUELLE',
        tax_horsepower: 4,
        mines_type_code: '1CHFXF',
        cnit_code: '',
        built_from: '2000-01-01',
        built_to: '2003-07-01',
        seats: 5,
        empty_weight: 890,
        gross_weight_rating: 1295,
        is_utility: false,
        ids: ['PE18236'],
        id: 'PE18236',
      },
      {
        brand: 'PEUGEOT',
        model: '106',
        version: 'VITAL',
        body: 'BERLINE',
        energy: 'ESSENCE',
        transmission: 'MANUELLE',
        tax_horsepower: 4,
        mines_type_code: '1CCDZ2',
        cnit_code: '',
        built_from: '1995-01-01',
        built_to: '1996-12-31',
        seats: 5,
        empty_weight: 780,
        gross_weight_rating: 1220,
        is_utility: false,
        ids: ['PE18081', 'PE18082'],
        id: 'PE18081',
      },
      {
        brand: 'PEUGEOT',
        model: '106',
        version: 'XN',
        body: 'BERLINE',
        energy: 'ESSENCE',
        transmission: 'MANUELLE',
        tax_horsepower: 4,
        mines_type_code: '1CC1A1',
        cnit_code: '',
        built_from: '1992-01-01',
        built_to: '1992-12-31',
        seats: 5,
        empty_weight: 760,
        gross_weight_rating: 1200,
        is_utility: false,
        ids: ['PE18001', 'PE18056', 'PE18057', 'PE18002', 'PE18012', 'PE18011', 'PE18010', 'PE18009', 'PE18003'],
        id: 'PE18001',
      },
      {
        brand: 'PEUGEOT',
        model: '106',
        version: 'XR',
        body: 'BERLINE',
        energy: 'ESSENCE',
        transmission: 'MANUELLE',
        tax_horsepower: 4,
        mines_type_code: '1AHDZ8',
        cnit_code: '',
        built_from: '1994-01-01',
        built_to: '1995-12-31',
        seats: 5,
        empty_weight: 800,
        gross_weight_rating: 1240,
        is_utility: false,
        ids: ['PE18058', 'PE18005', 'PE18004', 'PE18059'],
        id: 'PE18058',
      },
      {
        brand: 'PEUGEOT',
        model: '106',
        version: 'XT',
        body: 'BERLINE',
        energy: 'ESSENCE',
        transmission: 'MANUELLE',
        tax_horsepower: 4,
        mines_type_code: '1CHDZ8',
        cnit_code: '',
        built_from: '1994-01-01',
        built_to: '1994-12-31',
        seats: 5,
        empty_weight: 780,
        gross_weight_rating: 1220,
        is_utility: false,
        ids: ['PE18061', 'PE18060'],
        id: 'PE18061',
      },
      {
        brand: 'PEUGEOT',
        model: '106',
        version: 'ZEN 1.0E',
        body: 'BERLINE 3 PORTES',
        energy: 'ESSENCE',
        transmission: 'MANUELLE',
        tax_horsepower: 4,
        mines_type_code: '1CCDZE',
        cnit_code: '',
        built_from: '2000-01-01',
        built_to: '2000-12-31',
        seats: 5,
        empty_weight: 795,
        gross_weight_rating: 1215,
        is_utility: false,
        ids: ['PE18225', 'PE18224'],
        id: 'PE18225',
      },
      {
        brand: 'PEUGEOT',
        model: '106',
        version: 'ZEN 1.1E',
        body: 'BERLINE 5 PORTES',
        energy: 'ESSENCE',
        transmission: 'MANUELLE',
        tax_horsepower: 4,
        mines_type_code: '1AHFXF',
        cnit_code: '',
        built_from: '2000-01-01',
        built_to: '2002-12-02',
        seats: 5,
        empty_weight: 910,
        gross_weight_rating: 1315,
        is_utility: false,
        ids: ['PE18237', 'PE18238'],
        id: 'PE18237',
      },
      {
        brand: 'PEUGEOT',
        model: '106',
        version: 'ZENITH',
        body: 'BERLINE',
        energy: 'ESSENCE',
        transmission: 'MANUELLE',
        tax_horsepower: 4,
        mines_type_code: '1CCDZ2',
        cnit_code: '',
        built_from: '1993-01-01',
        built_to: '1993-12-31',
        seats: 5,
        empty_weight: 780,
        gross_weight_rating: 1220,
        is_utility: false,
        ids: ['PE18044', 'PE18045'],
        id: 'PE18044',
      },
    ],
    hasReachedRestitution: false,
    isDisplayingFicNotification: false,
    formulePickedObject: undefined,
    tarificationResult: undefined,
    devisCreationResult: undefined,
    subscribeResult: undefined,
    reachedSummaryScreenSections: {
      VEHICULE: true,
      DRIVING: true,
      PRIMARY_DRIVER: true,
      SECONDARY_DRIVER: false,
    },
    error: undefined,
    isFetchingLicensePlate: false,
    postPaymentResult: undefined,
    editingInsuranceRecord: { index: 0 },
    hasReceivedQuoteMailNotification: false,
  },
  stateValue: State.INFORMATION_PRIMARY_HAS_BEEN_COVERED,
};
