import { Typography } from '@ornikar/kitt-universal';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import type { ReactNode } from 'react';
import { useState } from 'react';
import { CallbackModuleModal } from '../../../components/CallbackModuleModal';
import { IllustratedTemplate } from '../../../components/ScreenTemplates/IllustratedTemplate';
import { useSubscriptionFsmState } from '../../../fsm/context';
import DesktopIllustration from './assets/illustration-desktop.svg';
import MobileIllustration from './assets/illustration-mobile.svg';
import { getNextOccurringMonth } from './dateUtil';
import styles from './styles.module.css';

const alt =
  "Conductrice à l'avant d'une voiture qui fait un geste de la main, avec un chien à l'arrière qui sort sa tête par la fenêtre";

export function InsuredSinceLessThanOneYearScreen(): ReactNode {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const {
    context: { answers },
  } = useSubscriptionFsmState();

  return (
    <IllustratedTemplate
      mobileIllustration={<img src={MobileIllustration} alt={alt} />}
      desktopIllustration={<img src={DesktopIllustration} alt={alt} />}
      title={`${answers.primaryDriver?.firstName || ''}, votre véhicule est assuré depuis moins d'un an.`}
      buttonLabel="Prendre RDV avec un conseiller"
      onButtonClick={() => setIsModalVisible(true)}
    >
      <div className={styles.Description}>
        <Typography.Text>
          Une résiliation anticipée de votre contrat est possible si vous déménagez, changez de statut marital ou de
          profession.
          <br />
          <br />
          <b>
            On a hâte de vous retrouver en{' '}
            {answers.vehicleCurrentContractAnniversaryMonth
              ? format(getNextOccurringMonth(answers.vehicleCurrentContractAnniversaryMonth), 'MMMM yyyy', {
                  locale: fr,
                })
              : 'prochainement'}
            {', '}
          </b>
          à l'échéance de votre contrat actuel, pour finaliser votre souscription !
          <br />
          <br />
          <b>Vous pouvez prétendre à une résiliation anticipée ? N’hésitez pas à nous contacter.</b>
        </Typography.Text>
      </div>
      <CallbackModuleModal isVisible={isModalVisible} onClose={() => setIsModalVisible(false)} />
    </IllustratedTemplate>
  );
}
