import * as Sentry from '@sentry/react';
import type { DateType, VehicleFsmAnswers } from '../fsm/answers';
import { api } from './api';

export type BrandName = string;
export type ModelName = string;

type EnergieValue = string;
type SRACode = string;
type VersionName = string;
type ImmatriculationNumber = string;
type HorsePowerValue = number;

export function listBrands(): Promise<BrandName[]> {
  return api<BrandName[]>('vehicles/brands');
}

export interface Model {
  name: ModelName;
  puissances_fiscales: HorsePowerValue[];
  energies: EnergieValue[];
}
/* eslint-enable camelcase */

export function listModelsWithPossibleValues(brand: string): Promise<Model[]> {
  return api<Model[]>(`vehicles/brands/${brand}/models`);
}

interface MergedVersion {
  ids: SRACode[];
  version: VersionName;

  tax_horsepower: number;
  energy: string;

  is_utility: boolean;
  /* These fields are provided by the server, but
  *  unecessary on frontend for now.
  "brand": BrandName,
  "model": ModelName,
  "body": "BERLINE 5 PORTES",
  "transmission": "AUTOMATIQUE",
  "mines_type_code": "F3DGZT",
  "cnit_code": "M10PGTVP",
  "built_from": "2008-01-01",
  "built_to": "3000-01-01",
  "seats": 5,
  "empty_weight": 1535,
  "gross_weight_rating": 2082 */
}

export interface Version extends Omit<MergedVersion, 'ids'> {
  id: SRACode;
}

export async function listVersions(
  brand: BrandName | undefined,
  model: ModelName | undefined,
  taxHorsepower: HorsePowerValue | undefined,
  energy: EnergieValue | undefined,
): Promise<Version[]> {
  if (!brand || !model || !taxHorsepower || !energy) return [];
  const searchParams = new URLSearchParams({
    brand,
    model,
    energy,
    tax_horsepower: taxHorsepower.toString(),
  });

  const mergedVersions = await api<MergedVersion[]>(`api/v2/vehicles/versions?${searchParams.toString()}`);

  return mergedVersions.map((version) => {
    const sortedIds = [...version.ids].sort((a, b) => a.localeCompare(b));
    return {
      ...version,
      ids: sortedIds,
      id: sortedIds[0],
    };
  });
}

export async function filterValidEnergies(
  brand: BrandName | undefined,
  model: ModelName | undefined,
  taxHorsepower: HorsePowerValue | undefined,
  energies: EnergieValue[] | undefined,
): Promise<EnergieValue[]> {
  if (!brand || !model || !taxHorsepower || !energies) return energies || [];

  const versionPromises = energies.map((energy) =>
    listVersions(brand, model, taxHorsepower, energy).then((versions) => ({
      energy,
      versions,
    })),
  );

  const validEnergies = (await Promise.all(versionPromises))
    .filter(({ versions }) => versions.length > 0)
    .map(({ energy }) => energy);
  return validEnergies;
}

export const getVersionDetails = (versionId: string): Promise<Version> => {
  return api<Version>(`vehicles/versions/${versionId}`);
};

interface VehicleInformations {
  specifications: {
    brand: string;
    model: string;
    energy: string;

    tax_horsepower: number;

    first_registration_date: DateType;

    last_registration_date: DateType;
  };
  versions: MergedVersion[];
}

export enum DataNeoErrorCode {
  InvalidLicensePlate = 'INVALID_LICENSE_PLATE',
  LicensePlateNotFound = 'LICENSE_PLATE_NOT_FOUND',
  VersionNotFound = 'VERSION_NOT_FOUND',
  QuotaExceeded = 'QUOTA_EXCEEDED',
  // RateLimited = "RATE_LIMITED",
  APIError = 'API_ERROR',
}

const getErrorMessage = (error: DataNeoErrorCode | string): string => {
  switch (error) {
    case DataNeoErrorCode.InvalidLicensePlate:
      return "Merci de spécifier une plaque d'immatriculation valide";
    case DataNeoErrorCode.LicensePlateNotFound:
      return "Nos service ne sont pas en mesure de trouver votre plaque d'immatriculation";
    case DataNeoErrorCode.VersionNotFound:
      return 'Impossible de trouver une version correspondante à votre véhicule';
    case DataNeoErrorCode.QuotaExceeded:
      return 'Notre service de recherche par immatriculation est temporairement indisponible';
    case DataNeoErrorCode.APIError:
      return 'Une erreur est survenue durant la recherche. Veuillez réessayer';
    default:
      return 'Une erreur est survenue durant la recherche. Veuillez réessayer';
  }
};

export async function getVehicleByLicensePlate(
  licensePlate: ImmatriculationNumber,
): Promise<Partial<VehicleFsmAnswers>> {
  try {
    const fetchedVehicle = await api<VehicleInformations>(`api/v2/vehicles/license-plate/${licensePlate}`);

    const versionName = fetchedVehicle.versions.length === 1 ? fetchedVehicle.versions[0].version : undefined;

    return {
      vehiculeImmatriculation: licensePlate,
      vehiculeMarque: fetchedVehicle.specifications.brand,
      vehiculeModele: fetchedVehicle.specifications.model,
      vehiculeCarburant: fetchedVehicle.specifications.energy,
      vehiculePuissanceFiscale: fetchedVehicle.specifications.tax_horsepower,
      vehiculeMiseEnCirculation: fetchedVehicle.specifications.first_registration_date,
      vehiculeVersionOptions: fetchedVehicle.versions.map(({ ids, ...version }) => ({
        ...version,
        id: ids[0],
      })),
      vehiculeVersion: fetchedVehicle.versions.length === 1 ? fetchedVehicle.versions[0].ids[0] : undefined,
      vehiculeVersionName: versionName,
    };
  } catch (error: any) {
    Sentry.captureException(error);

    throw new Error(getErrorMessage(error.message as string));
  }
}
