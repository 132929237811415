import { useRequiredValidator } from '@ornikar/react-validators';
import type { ReactNode } from 'react';
import type { ClaimNature } from '../../../../../../fsm/answers';
import { RadioScreenTemplate } from '../../../../RadioScreenTemplate';
import type { RadioScreenTemplateProps } from '../../../../RadioScreenTemplate';

interface ClaimRecordNatureTemplateProps extends RadioScreenTemplateProps<ClaimNature> {}

export function ClaimRecordNatureTemplate(props: ClaimRecordNatureTemplateProps): ReactNode {
  const requiredValidator = useRequiredValidator();

  return <RadioScreenTemplate {...props} validate={requiredValidator} />;
}
