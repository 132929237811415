import { PlusRegularIcon } from '@ornikar/kitt-icons/phosphor';
import { Button, View } from '@ornikar/kitt-universal';
import { format, isToday, subYears } from 'date-fns';
import { useMemo, useState } from 'react';
import type { ReactNode } from 'react';
import { useField } from 'react-final-form';
import { Civility, DejaAssureAvecNomType } from '../../../../apis/types/LongQuoteAnswers';
import { MODAL_VIEWED } from '../../../../constants/eventName';
import type { Period } from '../../../../fsm/answers';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../../fsm/context';
import { Driver, Event } from '../../../../fsm/types';
import { DateInconsistencyModal } from '../../../../screens/Information/DriverHasBeenCoveredScreen/components/DateInconsistencyModal';
import { sendEvent } from '../../../../utils/mixpanel';
import type { PrimaryOrSecondaryScreenTemplateType } from '../../../drivingSection/types';
import { ScreenTemplate } from '../../ScreenTemplate';
import { PeriodRow } from './PeriodRow';

const FORMATTED_THREE_YEARS_AGO = format(subYears(new Date(), 3), 'dd/MM/yyyy');

export function InformationInsurancePeriodsTemplate({ driverType }: PrimaryOrSecondaryScreenTemplateType): ReactNode {
  const isPrimaryDriver = driverType === Driver.Primary;
  const {
    context: { answers },
  } = useSubscriptionFsmState();
  const send = useSubscriptionFsmDispatch();
  const attribut: Civility = answers[`${driverType}Driver`]?.civility || Civility.Homme;
  const [modalVisible, setModalVisible] = useState(false);

  const fieldName = `${driverType}Driver.insurancePeriods`;

  const {
    input: { value: insurancePeriods, onChange, onBlur },
    meta: { error },
  } = useField<Period[]>(fieldName);

  const isContinueDisabled = insurancePeriods ? insurancePeriods.some((period) => !period.start || !period.end) : true;

  const shouldCheckConsistency =
    isPrimaryDriver && answers.primaryDriver && answers.vehiculeDejaAssureAvecNom === DejaAssureAvecNomType.OuiAMonNom;

  const isAddDisabled = useMemo(() => {
    if (error?.some((e: any) => e)) {
      return true;
    }

    return isContinueDisabled;
  }, [error, isContinueDisabled]);

  const handleSubmit = (): void => {
    if (shouldCheckConsistency && !insurancePeriods.some((period) => isToday(new Date(period.end)))) {
      setModalVisible(true);
      sendEvent(MODAL_VIEWED, {
        modal_name: 'INS - Inconsistency in Declaration',
      });
    } else {
      send(Event.ANSWER, {
        answers: {
          [`${driverType}Driver`]: { ...answers[`${driverType}Driver`], insurancePeriods },
        },
      });
    }
  };
  return (
    <ScreenTemplate
      title={
        isPrimaryDriver
          ? `Pouvez-vous renseigner la ou les périodes durant lesquelles vous avez été assuré${
              attribut === Civility.Femme ? 'e' : ''
            } depuis le ${FORMATTED_THREE_YEARS_AGO} ?`
          : `Pouvez-vous renseigner la ou les périodes durant lesquelles ${
              attribut === Civility.Femme ? 'elle' : 'il'
            } a été assuré${attribut === Civility.Femme ? 'e' : ''} depuis le ${FORMATTED_THREE_YEARS_AGO} ?`
      }
      highlightTitle="Comment renseigner votre / vos périodes d’assurance ?"
      highlightContent={
        <>
          Si vous en avez connu plusieurs, renseignez la première puis cliquez sur “Ajouter une période
          d&apos;assurance” pour compléter.
          <br />
          <br />
          Enfin, cliquez sur “Continuer” lorsque vous avez fini de renseigner vos périodes d&apos;assurance, afin de
          passer à l’étape suivante.
        </>
      }
      submitButton={{
        disabled: isContinueDisabled,
      }}
      onSubmit={handleSubmit}
    >
      {insurancePeriods
        ? insurancePeriods.map((_, idx) => (
            <PeriodRow
              // eslint-disable-next-line react/no-array-index-key
              key={idx}
              idx={idx}
              fieldName={fieldName}
              periods={insurancePeriods}
              onDelete={
                insurancePeriods.length > 1
                  ? () => {
                      onChange(insurancePeriods.filter((_p, i) => i !== idx));
                    }
                  : undefined
              }
            />
          ))
        : null}
      <View marginTop="kitt.5">
        <Button
          icon={<PlusRegularIcon />}
          disabled={isAddDisabled}
          onPress={() => {
            onChange([
              ...insurancePeriods,
              {
                start: '',
                end: '',
              },
            ]);
            onBlur();
          }}
        >
          Ajouter une période d&apos;assurance
        </Button>
      </View>

      <DateInconsistencyModal
        mainButtonClick={() => {
          send(Event.INSURANCE_DATE_INCONSISTENCY);
        }}
        gender={Civility.Femme ? 'female' : 'male'}
        secondButtonClick={() => {
          setModalVisible(false);
        }}
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
      />
    </ScreenTemplate>
  );
}
