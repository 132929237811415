import { Typography, View } from '@ornikar/kitt-universal';
import { type ReactNode } from 'react';
import { defineMessages } from 'react-intl';
import { HAMON_DELAY_DAYS } from '../../../../constants/coverageDate';
import { FormattedMessageWithRichTextFormatting } from '../../../../utils/intl';

const messages = defineMessages({
  hamon: {
    id: 'shoppingCart.hamon',
    defaultMessage: `Votre contrat commence à cette date car la procédure de résiliation auprès de votre assureur actuel prend <b>${HAMON_DELAY_DAYS} jours à partir d’aujourd’hui.</b>{br}{br}Soyez tranquille, vous êtes couvert par votre assurance actuelle jusqu’à l’activation de votre nouveau contrat.`,
  },
});

export function HamonHighlight(): ReactNode {
  return (
    <View
      backgroundColor="kitt.palettes.lateOcean.warmEmbraceLight1"
      paddingX={{ base: 'kitt.4', medium: 'kitt.6' }}
      paddingY="kitt.4"
      borderBottomRadius="kitt.5"
    >
      <Typography.Paragraph base="body-small">
        <FormattedMessageWithRichTextFormatting {...messages.hamon} />
      </Typography.Paragraph>
    </View>
  );
}
