import { useRequiredValidator } from '@ornikar/react-validators';
import type { ReactNode } from 'react';
import { cancellationOrSuspensionReasonOptionsList } from '../../../../../../constants/mappers/information';
import type { CancellationOrSuspensionReason } from '../../../../../../fsm/answers';
import type { RadioScreenTemplateProps } from '../../../../RadioScreenTemplate';
import { RadioScreenTemplate } from '../../../../RadioScreenTemplate';

interface CancellationRecordReasonTemplateProps
  extends Pick<RadioScreenTemplateProps<CancellationOrSuspensionReason>, 'fieldName' | 'title'> {}

export function CancellationRecordReasonTemplate(props: CancellationRecordReasonTemplateProps): ReactNode {
  const requiredValidator = useRequiredValidator();

  return (
    <RadioScreenTemplate {...props} choices={cancellationOrSuspensionReasonOptionsList} validate={requiredValidator} />
  );
}
