import { Car, DocuSigned, IllustratedIcon, PhoneCalling, Send } from '@ornikar/illustrated-icons';
import { DownloadSimpleRegularIcon } from '@ornikar/kitt-icons/phosphor';
import { Button, Typography } from '@ornikar/kitt-universal';
import { useState } from 'react';
import type { ReactNode } from 'react';
import { Civility } from '../../../apis/types/LongQuoteAnswers';
import { CallbackModuleModal } from '../../../components/CallbackModuleModal';
import { IllustratedTemplate } from '../../../components/ScreenTemplates/IllustratedTemplate';
import { Timeline } from '../../../components/Timeline';
import { useSubscriptionFsmState } from '../../../fsm/context';
import DocumentsListInstructionsFile from './assets/documents-list-instructions.pdf';
import DesktopIllustration from './assets/illustration-desktop.svg';
import MobileIllustration from './assets/illustration-mobile.svg';
import styles from './styles.module.css';

const alt = "Quelqu'un observant une voiture à la loupe";

export function ReverseSubscriptionScreen(): ReactNode {
  const {
    context: { answers },
  } = useSubscriptionFsmState();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const isWoman = answers.primaryDriver!.civility! === Civility.Femme;

  return (
    <IllustratedTemplate
      mobileIllustration={<img src={MobileIllustration} alt={alt} />}
      desktopIllustration={<img src={DesktopIllustration} alt={alt} />}
      title={`Merci ${answers.primaryDriver!.firstName!}. Voici les prochaines étapes.`}
      buttonLabel="Prendre RDV avec un conseiller"
      onButtonClick={() => setIsModalVisible(true)}
    >
      <Timeline
        items={[
          {
            icon: <IllustratedIcon icon={<PhoneCalling />} />,
            title: 'Échange avec un conseiller',
            description: `Vous pouvez choisir votre jour et horaire préférés ou demander à être rappelé${
              isWoman ? 'e' : ''
            } dans la journée.`,
          },
          {
            icon: <IllustratedIcon icon={<Send />} />,
            title: 'Envoi des documents nécessaires',
            description: (
              <>
                <Typography.Text base="body" color="black-anthracite">
                  Vous recevez par mail un récapitulatif de documents à nous faire parvenir à l’adresse{' '}
                </Typography.Text>
                <Typography.Text base="body" variant="bold" color="black-anthracite">
                  documents@ornikar.com
                </Typography.Text>
                <Button
                  type="subtle"
                  icon={<DownloadSimpleRegularIcon />}
                  href={DocumentsListInstructionsFile}
                  hrefAttrs={{ target: '_blank' }}
                >
                  Voir la liste des documents
                </Button>
              </>
            ),
          },
          {
            icon: <IllustratedIcon icon={<DocuSigned />} />,
            title: 'Finalisation de votre souscription',
            description:
              'Votre conseiller vous contacte par téléphone et vous pourrez procéder au paiement en toute sécurité.',
          },
          {
            icon: <IllustratedIcon icon={<Car />} />,
            title: 'Prenez la route...',
            description: `...Vous êtes désormais bien protégé${isWoman ? 'e' : ''}\u00A0!`,
          },
        ]}
        className={styles.Timeline}
      />
      <CallbackModuleModal isVisible={isModalVisible} onClose={() => setIsModalVisible(false)} />
    </IllustratedTemplate>
  );
}
