import { HStack, Typography, TypographyLink, VStack, theme } from '@ornikar/kitt-universal';
import { useState } from 'react';
import type { ReactNode } from 'react';
import { Query, useMediaQuery } from '../../hooks/useMediaQuery';
import { PromoCodeAmount } from './PromoCodeAmount';
import { PromoCodeModal } from './PromoCodeModal';

interface PromoCodeCardProps {
  amount?: number;
  minimumPrimeAmount?: number;
  promoCode: string;
  campaignStartDate?: string;
  campaignEndDate?: string;
  generalConditionsURL?: string;
}

export function PromoCodeCard({ amount = 0, ...otherProps }: PromoCodeCardProps): ReactNode {
  const isMedium = useMediaQuery(Query.MEDIUM);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const text = isMedium
    ? 'remboursés après souscription grâce à ce code promo !'
    : 'après souscription grâce à ce code promo !';

  return (
    <>
      <HStack
        space="kitt.4"
        paddingY={isMedium ? 'kitt.4' : 'kitt.1'}
        paddingX="kitt.4"
        borderRadius={4}
        backgroundColor={theme.palettes.lateOcean['lavender.7']}
        alignItems="center"
        justifyContent="flex-start"
      >
        <PromoCodeAmount amount={amount} />
        <VStack flexShrink={1}>
          <Typography.Text>{text}</Typography.Text>
          <TypographyLink
            variant="regular"
            onPress={() => {
              setIsModalVisible(true);
            }}
          >
            En savoir plus
          </TypographyLink>
        </VStack>
      </HStack>
      <PromoCodeModal
        isVisible={isModalVisible}
        amount={amount}
        onClose={() => {
          setIsModalVisible(false);
        }}
        {...otherProps}
      />
    </>
  );
}
