import { QuestionFillIcon } from '@ornikar/kitt-icons/phosphor';
import { HStack, TypographyIcon, TypographyLink } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { EXIT_DOOR_EVENT } from '../../../constants/eventName';
import { VehiculePreSelectionType } from '../../../fsm/answers';
import { useSubscriptionFsmState } from '../../../fsm/context';
import { sendEvent } from '../../../utils/mixpanel';

interface ExitDoorLinkProps {
  children: NonNullable<ReactNode>;
  onPress: () => void;
}

export function ExitDoorLink({ children, onPress }: ExitDoorLinkProps): ReactNode {
  const { context, value: stateValue } = useSubscriptionFsmState();

  const searchType =
    context.answers.vehiculePreSelectionType === VehiculePreSelectionType.LICENSE_PLATE ? 'plate' : 'brand';

  const handlePress = (): void => {
    sendEvent(EXIT_DOOR_EVENT, {
      cta_action: 'activate_exit_door',
      cta_name: 'ins_exit_door_cta',
      cta_text: stateValue,
      search_type: searchType,
    });

    onPress();
  };

  return (
    <HStack space="kitt.2" alignItems="center">
      <TypographyIcon icon={<QuestionFillIcon />} size="kitt.8" />
      <TypographyLink variant="bold" color="primary" onPress={handlePress}>
        {children}
      </TypographyLink>
    </HStack>
  );
}
