import type { VehicleFsmAnswers } from '../../../fsm/answers';
import { useSubscriptionFsmDispatch } from '../../../fsm/context';
import { Event } from '../../../fsm/types';
import type { VehicleFormValues } from '../../answers';
import type { Submissions } from '../types';

export const useVehicleSubmissions: () => Submissions<VehicleFormValues, VehicleFsmAnswers> = () => {
  const send = useSubscriptionFsmDispatch();

  return {
    LANDING: () => {
      // @dev handled in screen
    },
    VEHICULE_SEARCH_CHOICE: ({ vehiculePreSelectionType }) => {
      send({
        type: Event.ANSWER,
        answers: {
          vehiculePreSelectionType,
        },
      });
    },
    VEHICULE_LICENSE_PLATE: (vehicleData) => {
      send({
        type: Event.ANSWER,
        answers: { ...vehicleData },
      });
    },
    VEHICULE_BRAND: ({ vehiculeMarque, availableBrands }) => {
      send({ type: Event.ANSWER, answers: { vehiculeMarque, availableBrands } });
    },
    VEHICULE_MODEL: ({ vehiculeModele, availableModels, availableCarburants, availablePuissanceFiscales }) => {
      send({
        type: Event.ANSWER,
        answers: { vehiculeModele, availableModels, availableCarburants, availablePuissanceFiscales },
      });
    },
    VEHICULE_PUISSANCE_FISCALE: ({ vehiculePuissanceFiscale }) => {
      send({ type: Event.ANSWER, answers: { vehiculePuissanceFiscale } });
    },
    VEHICULE_CARBURANT: ({ vehiculeCarburant }) => {
      send({ type: Event.ANSWER, answers: { vehiculeCarburant } });
    },
    VEHICULE_MISE_EN_CIRCULATION: ({ vehiculeMiseEnCirculation }) => {
      send({ type: Event.ANSWER, answers: { vehiculeMiseEnCirculation } });
    },
    VEHICULE_VERSION: ({ vehiculeVersion, vehiculeIsUtility, availableVersions }) => {
      send({ type: Event.ANSWER, answers: { vehiculeVersion, vehiculeIsUtility, availableVersions } });
    },
    VEHICULE_ACQUISITON_DATE: ({ vehiculeAcquisition }) => {
      send({ type: Event.ANSWER, answers: { vehiculeAcquisition } });
    },
    VEHICULE_COVERING_DATE: ({ dateEffetSouhaite }) => {
      send({ type: Event.ANSWER, answers: { dateEffetSouhaite } });
    },
    VEHICULE_IS_COVERED: ({ vehiculeDejaAssureAvecNom }) => {
      send({ type: Event.ANSWER, answers: { vehiculeDejaAssureAvecNom } });
    },
    VEHICULE_IS_COVERED_INCONSISTENCY: ({ vehiculeDejaAssureAvecNom }) => {
      send({ type: Event.ANSWER, answers: { vehiculeDejaAssureAvecNom } });
    },
    VEHICULE_COVERED_SINCE: ({ vehiculeAssureDepuis }) => {
      send({ type: Event.ANSWER, answers: { vehiculeAssureDepuis } });
    },
    VEHICLE_CURRENT_CONTRACT_ANNIVERSARY_MONTH: ({ vehicleCurrentContractAnniversaryMonth }) => {
      send({ type: Event.ANSWER, answers: { vehicleCurrentContractAnniversaryMonth } });
    },
    VEHICLE_LAST_INSURANCE_END_DATE: ({ vehicleLastInsuranceEndDate }) => {
      send({ type: Event.ANSWER, answers: { vehicleLastInsuranceEndDate } });
    },
    VEHICLE_LAST_INSURANCE_END_DATE_INCONSISTENCY: ({ vehicleLastInsuranceEndDate }) => {
      send({ type: Event.ANSWER, answers: { vehicleLastInsuranceEndDate } });
    },
    VEHICULE_FUNDING: ({ vehiculeFinancement }) => {
      send({ type: Event.ANSWER, answers: { vehiculeFinancement } });
    },
  };
};
