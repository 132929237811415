import type { ReactNode } from 'react';
import { InsuranceRecordAlcoholLevelTemplate } from '../../../../components/ScreenTemplates/InformationTemplates/InsuranceRecordTemplates/InsuranceRecordAlcoholLevelTemplate';

export function SecondaryTerminationRecordLicenseCanceledOrSuspendedAlcoholLevelScreen(): ReactNode {
  return (
    <InsuranceRecordAlcoholLevelTemplate
      title="Quel était son taux d'alcoolémie lors de ce contrôle&nbsp;?"
      fieldName="secondaryDriver.terminationRecords[0].cancellationOrSuspensionRecord.bloodAlcoholLevel"
    />
  );
}
