import type { IntlValidator } from '@ornikar/react-validators';
import { useValidator } from '@ornikar/react-validators';
import { invalidTypeSymbol } from '@ornikar/validators';
import { parsePhoneNumber } from 'libphonenumber-js/max';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  phoneNumber: {
    id: 'validators.phoneNumber.invalid',
    defaultMessage: 'Veuillez rentrer un numéro valide',
  },
});

export const phoneNumberValidatorFunction = (value: string | undefined): typeof invalidTypeSymbol | undefined => {
  if (value) {
    try {
      const phoneNumber = parsePhoneNumber(value);

      if (phoneNumber.getType() === 'MOBILE' && phoneNumber.isValid()) {
        return undefined;
      }
    } catch {
      return invalidTypeSymbol;
    }
  }
  return invalidTypeSymbol;
};

export const usePhoneNumberValidator = (): IntlValidator =>
  useValidator(phoneNumberValidatorFunction, messages.phoneNumber);
