import { FORM_ERROR } from 'final-form';
import type { GeocodingDto } from '../../../apis/getAddressSuggestions';
import { CONTACT_VALIDATION_GENERIC_DEFAULT_ERROR, validateContact } from '../../../apis/validateContact';

export const validateHolderInformations = async (
  subscriberAutoCompletedAddress: GeocodingDto,
  subscriberEmail: string,
  subscriberPhone: string,
): Promise<Record<string, unknown> | undefined> => {
  const { street, zipCode, city } = subscriberAutoCompletedAddress;

  if (!street || !zipCode || !city) {
    return { subscriberAutoCompletedAddress: 'Veuillez rentrer une adresse valide' };
  }

  const { phoneReport, emailReport } = await validateContact(subscriberPhone, subscriberEmail);

  if (!phoneReport.valid || !emailReport.valid) {
    if (phoneReport.report && phoneReport.report[0] === CONTACT_VALIDATION_GENERIC_DEFAULT_ERROR) {
      return { [FORM_ERROR]: 'Vérifiez votre numéro de téléphone et adresse email' };
    }

    return {
      subscriberPhone: phoneReport.report?.[0],
      subscriberEmail: emailReport.report?.[0],
      isEmailDomainBlacklisted: emailReport.report?.[0] === "Ce nom de domaine n'est encore pas supporté",
    };
  }

  return undefined;
};
