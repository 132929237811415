import { getFilteredSelectableAddons } from '../../../fsm/actions/addons';
import type { Addon } from '../../../fsm/answers';
import { useSubscriptionFsmState } from '../../../fsm/context';

export const useAddonsPrice = (): number => {
  const {
    context: { answers, quoteOptions },
  } = useSubscriptionFsmState();

  if (answers.addons && quoteOptions) {
    return (
      Object.values(getFilteredSelectableAddons(answers.addons))
        .filter(({ isSelected }) => isSelected)
        // eslint-disable-next-line unicorn/no-array-reduce
        .reduce((acc: number, { name, term }: Addon) => {
          const foundQuoteOption = quoteOptions.addOns.find((addOn) => addOn.name === name);

          if (foundQuoteOption) {
            const foundOption = foundQuoteOption.options.find((option) => option.term === term);

            if (foundOption) {
              return acc + foundOption.cost.cents;
            }
          }

          return acc;
        }, 0)
    );
  }

  return 0;
};
