import { ExternalLink, Typography, TypographyLink } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { REGISTRATION_DOCUMENT_PARTNER_LINK_CLICK } from '../../constants/eventName';
import { useSubscriptionFsmState } from '../../fsm/context';
import { sendEvent } from '../../utils/mixpanel';

export interface RegistrationDocumentPartnerLinkProps {
  textColor?: string;
}

export function RegistrationDocumentPartnerLink({ textColor }: RegistrationDocumentPartnerLinkProps): ReactNode {
  const state = useSubscriptionFsmState();

  const handlePress = (): void => {
    sendEvent(REGISTRATION_DOCUMENT_PARTNER_LINK_CLICK, {
      state_slug: state.value,
      cta_action: 'registration_certification_link_clicked',
      cta_name: 'notre service partenaire',
    });
  };

  return (
    <Typography.Text color={textColor}>
      Vous n’avez pas encore votre carte grise ? Obtenez la en 48h grâce à{' '}
      <ExternalLink
        href="https://certimat.fr/prescripteurs?partner=5361"
        as={TypographyLink}
        color="primary"
        variant="bold"
        onPress={handlePress}
      >
        notre service partenaire
      </ExternalLink>
      , ou faites la demande sur le site de l’ANTS.
    </Typography.Text>
  );
}
