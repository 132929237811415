import type { DropdownSelectProps } from '@ornikar/kitt';
import { DropdownSelect, InputAutocomplete } from '@ornikar/kitt';
import type { ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useInsuranceDesktopMediaQuery } from '../../hooks/useInsuranceDesktopMediaQuery';
import { useSubmitWithDelay } from '../../hooks/useSubmitWithDelay';
import styles from './styles.module.css';

interface LocalInputAutoCompleteProps<Value extends string | number> {
  choices: { label: string; value: Value; className?: string }[];
  value: Value;
  name: string;
  delayCloseOnBlur?: boolean;
  onChange: (value: Value) => void;
  onCustomInputValueChange?: (value: string) => void;
  onBlur: () => void;
  scrollTarget?: string;
  placeholder?: string;
  disabled?: boolean;
  isLoading?: boolean;
  emptyComponent?: ReactNode;
  autoComplete?: string;
}

export function LocalInputAutoComplete<Value extends string | number>({
  choices,
  value,
  name,
  delayCloseOnBlur,
  onChange,
  onCustomInputValueChange,
  onBlur,
  scrollTarget,
  placeholder,
  disabled,
  isLoading,
  emptyComponent,
  autoComplete,
}: LocalInputAutoCompleteProps<Value>): ReactNode {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const selectedItemNameRef = useRef(choices.find((choice) => choice.value === value)?.label);
  const results = useMemo(
    () => choices?.filter(({ label }) => label?.toLowerCase().includes(search.toLowerCase())) || [],
    [choices, search],
  );

  const scrollList = useCallback(() => {
    if (!scrollTarget) return;
    const targ = document.querySelector(scrollTarget) as HTMLElement | null;
    // @TODO: this should be a ref
    const list = document.querySelector('.Autocomplete ul') as HTMLElement | null;
    if (targ && list) list.scrollTo({ top: targ.offsetTop });
  }, [scrollTarget]);

  useEffect(scrollList, [choices, scrollList]);

  useEffect(() => {
    if (choices && choices.length > 0) {
      if (!value) {
        setSearch('');
        return;
      }
      const item = choices.find((i) => i.value === value);
      if (!item) {
        setSearch('');
      } else {
        setSearch(item.label);
      }
    } else if (choices.length === 0) {
      selectedItemNameRef.current = undefined;
      setSearch('');
    }
  }, [value, choices]);

  return (
    <div className={styles.Autocomplete}>
      <InputAutocomplete
        inputProps={{
          value: search,
          name,
          ref: inputRef,
          disabled,
          placeholder,
          autoComplete,
          onFocus: () => {
            if (inputRef?.current) {
              inputRef.current.select();
            }
            setOpen(true);
            setTimeout(scrollList, 10);
          },
          onKeyUp: (e) => {
            if (e.key === 'Enter') {
              setOpen(false);
            } else {
              setOpen(true);
            }
          },
          onBlur: () => {
            if (selectedItemNameRef.current && selectedItemNameRef.current !== search) {
              setSearch(selectedItemNameRef.current);
            }
            if (delayCloseOnBlur) {
              setTimeout(() => {
                setOpen(false);
              }, 10);
            } else {
              setOpen(false);
            }
          },
        }}
        // placeholder={placeholder}
        emptyComponent={() => (
          <InputAutocomplete.Item item={null}>
            {isLoading ? 'Chargement...' : emptyComponent || 'Pas de résultat'}
          </InputAutocomplete.Item>
        )}
        itemToString={(item) => (item as unknown as { label: string }).label}
        isOpen={isOpen}
        onInputValueChange={(searchValue) => {
          if (typeof searchValue === 'string') {
            let formattedSearchValue = searchValue;
            if (typeof value === 'number') {
              formattedSearchValue = formattedSearchValue.replace(/,/g, '.');
            }

            setSearch(formattedSearchValue);
          }
          if (onCustomInputValueChange) {
            onCustomInputValueChange(searchValue);
          }
        }}
        onSelect={(item) => {
          if (item) {
            setOpen(false);
            onChange(item.value as Value);
            onBlur();

            selectedItemNameRef.current = item.label;
          }
        }}
      >
        {results.map((item) => (
          <InputAutocomplete.Item key={item.value} item={item} className={item.className}>
            {item.label}
          </InputAutocomplete.Item>
        ))}
      </InputAutocomplete>
    </div>
  );
}

export interface LocalAutoCompleteInputProps<Value extends string | number> extends LocalInputAutoCompleteProps<Value> {
  submitOnChange?: boolean;
  autoComplete?: string;
}

export function LocalAutoCompleteInput<Value extends string | number>({
  onChange,
  submitOnChange,
  autoComplete,
  ...rest
}: LocalAutoCompleteInputProps<Value>): ReactNode {
  const isDesktop = useInsuranceDesktopMediaQuery();
  const submitWithDelay = useSubmitWithDelay();

  return isDesktop ? (
    <LocalInputAutoComplete
      {...rest}
      autoComplete={autoComplete}
      onChange={(value) => {
        if (onChange) {
          onChange(value);
        }
        if (submitOnChange) {
          submitWithDelay();
        }
      }}
    />
  ) : (
    <DropdownSelect
      {...(rest as DropdownSelectProps<Value>)}
      placeholder="Sélectionner"
      onChange={(value: Value) => {
        if (onChange) {
          onChange(value);
        }
        rest.onBlur();
        if (submitOnChange) {
          submitWithDelay();
        }
      }}
    />
  );
}
