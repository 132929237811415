import { differenceInMonths, format, isBefore, parse, subYears } from 'date-fns';
import { DriverHasBeenCovered, ServerAncienneAssuranceType } from '../fsm/answers';
import type { DateType, Period } from '../fsm/answers';
import type { APIDate, ServerInsurancePeriod } from './types/LongQuoteAnswers';

const TODAY = new Date();
const FORMATTED_TODAY = format(TODAY, 'yyyy-MM-dd');
const THREE_YEARS_AGO = subYears(new Date(), 3);

const getDifferenceInMonths = (from: APIDate, to: APIDate): number =>
  differenceInMonths(parse(to, 'yyyy-MM-dd', new Date()), parse(from, 'yyyy-MM-dd', new Date()));

export const sortPeriods = (insurancePeriods: Period[]): Period[] =>
  insurancePeriods.sort((a, b) => a.end.localeCompare(b.end));

export const computeAncienneAssurance = (
  coveredType: DriverHasBeenCovered,
  insurancePeriods: Period[],
): ServerAncienneAssuranceType => {
  if (coveredType === DriverHasBeenCovered.NonJamais || coveredType === DriverHasBeenCovered.NonSurPeriode) {
    return ServerAncienneAssuranceType.No;
  }

  if (coveredType === DriverHasBeenCovered.OuiEnContinu) {
    return ServerAncienneAssuranceType.Continu36;
  }

  const lastPeriod = sortPeriods(insurancePeriods)[insurancePeriods.length - 1];

  if (lastPeriod.end !== FORMATTED_TODAY) {
    return ServerAncienneAssuranceType.Interrompu;
  }

  const monthCountOfLastInteruptedPeriod = getDifferenceInMonths(lastPeriod.start, lastPeriod.end);

  if (monthCountOfLastInteruptedPeriod >= 36) {
    return ServerAncienneAssuranceType.Continu36;
  }

  if (monthCountOfLastInteruptedPeriod >= 24) {
    return ServerAncienneAssuranceType.Continu24;
  }

  if (monthCountOfLastInteruptedPeriod >= 12) {
    return ServerAncienneAssuranceType.Continu12;
  }

  return ServerAncienneAssuranceType.Less1year;
};

export const computeHasBeenCovered = (
  ancienneAssurance: ServerAncienneAssuranceType,
  insurancePeriodsPrincipalDriver: ServerInsurancePeriod[],
): DriverHasBeenCovered => {
  const insuredContinu36 = insurancePeriodsPrincipalDriver.some(
    ({ startDate, endDate }) => getDifferenceInMonths(startDate, endDate) >= 36,
  );

  if (ancienneAssurance === ServerAncienneAssuranceType.No && insurancePeriodsPrincipalDriver.length === 0) {
    return DriverHasBeenCovered.NonJamais;
  }

  if (ancienneAssurance === ServerAncienneAssuranceType.Continu36 && insuredContinu36) {
    return DriverHasBeenCovered.OuiEnContinu;
  }

  return DriverHasBeenCovered.OuiPartiellement;
};

export const computeInsurancePeriods = (
  coveredType: DriverHasBeenCovered,
  licenseDate: DateType,
  insurancePeriods?: Period[],
): Period[] => {
  if (coveredType === DriverHasBeenCovered.OuiEnContinu) {
    const parsedLicenseDate = parse(licenseDate, 'yyyy-MM-dd', new Date());

    if (isBefore(parsedLicenseDate, THREE_YEARS_AGO)) {
      return [
        {
          start: format(THREE_YEARS_AGO, 'yyyy-MM-dd'),
          end: FORMATTED_TODAY,
        },
      ];
    }

    return [
      {
        start: licenseDate,
        end: FORMATTED_TODAY,
      },
    ];
  }

  return insurancePeriods || [];
};
