import type { ReactNode } from 'react';
import { InformationContainer } from '../../../../../components/InformationContainer/InformationContainer';
import { InformationList } from '../../../../../components/InformationList/InformationList';
import { InformationEntry } from '../../../../../components/InformationList/components/InformationEntry';
import {
  bloodAlcoholLevelOptionsDict,
  claimRecordResponsibilityLevelOptionsDict,
  hasHitAndRunOptionsDict,
  suspensionDurationOptionsDict,
} from '../../../../../constants/mappers/information';
import { AccidentType, type CancellationOrSuspensionRecord } from '../../../../../fsm/answers';
import {
  generateCancellationOrSuspensionCardTitle,
  getCancellationOrSuspensionReason,
  underTheInfluenceOfSummaryDict,
} from '../../helpers';

interface CancellationOrSuspensionCardProps {
  cancellationOrSuspension: Required<CancellationOrSuspensionRecord>;
  onEdit: () => void;
  onDelete: () => void;
}

export function CancellationOrSuspensionCard({
  cancellationOrSuspension,
  onEdit,
  onDelete,
}: CancellationOrSuspensionCardProps): ReactNode {
  const title = generateCancellationOrSuspensionCardTitle(cancellationOrSuspension);
  const reason = getCancellationOrSuspensionReason(cancellationOrSuspension);

  return (
    <InformationContainer title={title} onEdit={onEdit} onDelete={onDelete}>
      <InformationList>
        {cancellationOrSuspension.suspensionDuration && (
          <InformationEntry
            label="Durée"
            value={suspensionDurationOptionsDict[cancellationOrSuspension.suspensionDuration]}
          />
        )}
        <InformationEntry label="Motif" value={reason}>
          {cancellationOrSuspension.bloodAlcoholLevel && (
            <InformationEntry
              isChild
              hasSeparator
              label="À"
              value={bloodAlcoholLevelOptionsDict[cancellationOrSuspension.bloodAlcoholLevel]}
            />
          )}
          {[AccidentType.MaterialAccident, AccidentType.BodilyAccident].includes(cancellationOrSuspension.accident) && (
            <>
              <InformationEntry
                isChild
                hasSeparator
                label="Responsabilité"
                value={
                  claimRecordResponsibilityLevelOptionsDict[cancellationOrSuspension.claimRecord.responsibilityLevel]
                }
              />
              <InformationEntry
                isChild
                label="Sous emprise"
                value={underTheInfluenceOfSummaryDict[cancellationOrSuspension.claimRecord.underTheInfluenceOf]}
              >
                {cancellationOrSuspension.claimRecord.bloodAlcoholLevel && (
                  <InformationEntry
                    isChild
                    label="À"
                    value={bloodAlcoholLevelOptionsDict[cancellationOrSuspension.claimRecord.bloodAlcoholLevel]}
                  />
                )}
              </InformationEntry>
            </>
          )}
        </InformationEntry>
        <InformationEntry
          label="Refus d'obtempérer"
          value={hasHitAndRunOptionsDict[cancellationOrSuspension.isHitAndRun]}
        />
      </InformationList>
    </InformationContainer>
  );
}
