import type { ReactNode } from 'react';
import { AddonScreenTemplate } from '../../../components/ScreenTemplates/AddonScreenTemplate';
import { AddonName } from '../../../fsm/answers';
import { useSendPageNameToSegment } from '../../../providers/AnalyticsProvider';
import { VehicleLoanCard } from './VehicleLoanCard';

export function VehicleLoanScreen(): ReactNode {
  useSendPageNameToSegment();
  return (
    <AddonScreenTemplate addonName={AddonName.OML_VEHICLE_LOAN}>
      <VehicleLoanCard />
    </AddonScreenTemplate>
  );
}
