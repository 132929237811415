import { Input } from '@ornikar/kitt';
import { Typography } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { useEffect, useMemo } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { listBrands } from '../../../../apis/vehicules';
import type { FieldProps } from '../../../../components/Field';
import { Field } from '../../../../components/Field';
import type { LocalAutoCompleteInputProps } from '../../../../components/LocalAutocompleteInput';
import { LocalAutoCompleteInput } from '../../../../components/LocalAutocompleteInput';
import type { VehicleFormValues } from '../../../../forms/answers';
import { useAsync } from '../../../../hooks/useAsync';
import { serviceUnavailable } from '../../../../utils/errorMessages';
import { sendNoOptionsFoundEvent } from '../../../../utils/mixpanel';

interface BrandAutoCompleteProps extends Pick<FieldProps<string>, 'label' | 'name' | 'validate'> {
  disabled?: boolean;
}

export function BrandAutoComplete(props: BrandAutoCompleteProps): ReactNode {
  const [brands, brandsError, brandsLoading] = useAsync(() => {
    if (!props.disabled) {
      return listBrands();
    }

    return Promise.resolve([]);
  }, [props.disabled]);

  const brandsChoices = useMemo(() => {
    return brands?.map((brand) => ({ label: brand, value: brand })) || [];
  }, [brands]);

  useEffect(() => {
    if (!brandsLoading && (!brands || brands.length === 0)) {
      sendNoOptionsFoundEvent(props.name as keyof VehicleFormValues);
    }
  }, [brandsLoading, brands, props.name]);

  const { values } = useFormState<VehicleFormValues>({ subscription: { values: true } });
  const { reset, change } = useForm<VehicleFormValues>();

  if (brandsError) {
    return (
      <>
        <Field {...props} disabled component={Input} />
        <Typography.Text color="kitt.danger">{serviceUnavailable}</Typography.Text>
      </>
    );
  }

  if (props.disabled) {
    return <Field {...props} component={Input} />;
  }

  return (
    <Field<string, LocalAutoCompleteInputProps<string>>
      {...props}
      submitOnChange
      component={LocalAutoCompleteInput}
      isLoading={brandsLoading}
      placeholder={brandsLoading ? 'Chargement...' : ''}
      choices={brandsChoices || []}
      onChange={(value) => {
        if (values[props.name as keyof VehicleFormValues] !== value) {
          reset();
          change(props.name as keyof VehicleFormValues, value);
        }
      }}
    />
  );
}
