import { View } from '@ornikar/kitt-universal';
import cx from 'classnames';
import type { ReactNode } from 'react';
import { useInsuranceDesktopMediaQuery } from '../../hooks/useInsuranceDesktopMediaQuery';
import { ContactModal } from '../ContactModal';
import { Footer } from '../Footer';
import { Header } from '../Header';
import { LateOceanHeader } from '../LateOceanHeader/LateOceanHeader';
import { NumberCopyBanner } from '../NumberCopyBanner';
import styles from './styles.module.css';

export interface FullWidthLayoutProps {
  children: NonNullable<ReactNode>;
  headerVisible: boolean;
  onOpenHelperModal?: () => void;
  saveQuoteButtonVisible?: boolean;
  helperButtonVisible?: boolean;
  backgroundColor?: 'white' | 'seashell';
  shouldHaveLateOceanHeader?: boolean;
  shouldRenderFooter?: boolean;
}

export function FullWidthLayout({
  children,
  headerVisible = true,
  onOpenHelperModal,
  saveQuoteButtonVisible = true,
  helperButtonVisible = true,
  backgroundColor = 'white',
  shouldHaveLateOceanHeader = false,
  shouldRenderFooter = true,
}: FullWidthLayoutProps): ReactNode {
  const isDesktop = useInsuranceDesktopMediaQuery();
  const isLateOceanHeader = shouldHaveLateOceanHeader && !isDesktop;
  const LayoutHeader = isLateOceanHeader ? LateOceanHeader : Header;

  const background = backgroundColor === 'white' ? 'white' : 'kitt.palettes.lateOcean.seaShell';

  return (
    <View backgroundColor={background}>
      {headerVisible && (
        <LayoutHeader
          saveQuoteButtonVisible={saveQuoteButtonVisible}
          helperButtonVisible={helperButtonVisible}
          backgroundColor={backgroundColor}
          onOpenHelperModal={onOpenHelperModal}
        />
      )}
      <div
        className={cx(styles.FullWidthLayout, {
          [styles.FullWidthWithHeader]: headerVisible,
          [styles.FullWidthWithFixedHeader]: isLateOceanHeader,
        })}
      >
        {children}
        {shouldRenderFooter ? <Footer /> : null}
      </div>
      <NumberCopyBanner />
      <ContactModal />
    </View>
  );
}
