import * as Sentry from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';
import { type WebCallbackScheduleData, getWebCallbackScheduleData } from '../apis/getWebCallbackScheduleData';
import { useToast } from '../components/Toast/useToast';

export const useWebCallbackScheduleData = (): WebCallbackScheduleData | undefined => {
  const { openErrorToast } = useToast();

  const [webCallbackScheduleData, setWebCallbackScheduleData] = useState<WebCallbackScheduleData>();

  const fetchWebCallbackScheduleData = useCallback(async () => {
    try {
      const data = await getWebCallbackScheduleData();
      const formattedData = {
        ...data,
        daySlotsList: data.daySlotsList.map((day) => new Date(day)),
        timeSlotsPerDate: data.timeSlotsPerDate.map((timeSlotOfDay) => ({
          day: new Date(timeSlotOfDay.day),
          timeSlots: timeSlotOfDay.timeSlots,
        })),
      };
      setWebCallbackScheduleData(formattedData);
    } catch (error) {
      Sentry.captureException(error);

      openErrorToast(error as Error);
    }
  }, [openErrorToast]);

  useEffect(() => {
    fetchWebCallbackScheduleData();
  }, [fetchWebCallbackScheduleData]);

  return webCallbackScheduleData;
};
