import { getFilteredSelectableAddons } from '../../../fsm/actions/addons';
import type { AddonName } from '../../../fsm/answers';
import { useSubscriptionFsmState } from '../../../fsm/context';

export const useSelectedAddonsNames = (): AddonName[] => {
  const {
    context: { answers },
  } = useSubscriptionFsmState();

  if (answers.addons) {
    return Object.values(getFilteredSelectableAddons(answers.addons))
      .filter(({ isSelected }) => isSelected)
      .map(({ name }) => name);
  }

  return [];
};
