import type { ReactNode } from 'react';
import { LinkList, LinkListItem } from './LinkList';

declare global {
  interface Window {
    openAxeptioCookies: () => void;
  }
}

export function FooterLinks(): ReactNode {
  return (
    <>
      <LinkList blockOnMobile>
        <LinkListItem href="/actualites">Actualités</LinkListItem>
        <LinkListItem href="/a-propos">{'À\u00A0propos'}</LinkListItem>
        <LinkListItem href="/a-propos/conditions-generales-d-utilisation">CGU</LinkListItem>
        <LinkListItem href="/a-propos/conditions-generales-de-vente">CGV</LinkListItem>
        <LinkListItem target="_blank" href="https://careers.ornikar.com/">
          Carrières
        </LinkListItem>
        <LinkListItem target="_blank" href="https://help.ornikar.com">
          FAQ
        </LinkListItem>
        <LinkListItem href="/a-propos/contact">Contact</LinkListItem>
        <LinkListItem target="_blank" href="https://www.onroad.to/">
          Onroad.to
        </LinkListItem>
      </LinkList>
      <LinkList blockOnMobile>
        <LinkListItem href="/a-propos/mentions-legales">Mentions légales</LinkListItem>
        <LinkListItem href="/a-propos/charte-de-confidentialite">Charte de confidentialité</LinkListItem>
        <LinkListItem href="/a-propos/cookies">Politique de cookies</LinkListItem>
        <LinkListItem
          onClick={() => {
            if (window.openAxeptioCookies) {
              window.openAxeptioCookies();
            }
          }}
        >
          Gérer mes cookies
        </LinkListItem>
      </LinkList>
    </>
  );
}
