import { Icon, InputAutocomplete } from '@ornikar/kitt';
import type { ReactNode } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useField } from 'react-final-form';
import type {
  GeocodingDto,
  GeocodingListDto,
  PartialGeocodingDto,
  PartialGeocodingListDto,
} from '../../apis/getAddressSuggestions';
import { GeocodingProvider, getAddressDetails, getAddressSuggestions } from '../../apis/getAddressSuggestions';
import type { InformationFormValues } from '../../forms/answers';
import { useAsyncDebouncedMemo } from '../../hooks/useAsyncDebouncedMemo';
import { sendNoOptionsFoundEvent } from '../../utils/mixpanel';
import styles from './styles.module.css';

const MIN_USER_INPUT_LENGTH_FOR_API_CALL = 3;
const DEBOUNCE_DELAY_FOR_API_CALL = 950;

export interface CustomInputAddressProps {
  name: string;
  placeholder?: string;
  initialFormattedAddress?: GeocodingDto;
  emptyMessage?: string | ReactNode;
  onChange: (value?: GeocodingDto) => void;
  onBlur: () => void;
}

export function CustomInputAddress({
  name,
  initialFormattedAddress,
  placeholder,
  emptyMessage = 'Pas de résultat',
  onBlur,
  onChange,
}: CustomInputAddressProps): ReactNode {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [userInput, setUserInput] = useState(initialFormattedAddress?.formattedAddress || '');
  const [lastAddress, setLastAddress] = useState(
    initialFormattedAddress
      ? {
          ...initialFormattedAddress,
          suggestedAddress: initialFormattedAddress?.formattedAddress,
        }
      : undefined,
  );
  const [loading, setLoading] = useState(false);
  const {
    input: { onChange: updateAddress },
  } = useField(name, {
    initialValue: initialFormattedAddress,
  });

  const suggestionsResponse: GeocodingListDto | PartialGeocodingListDto | undefined = useAsyncDebouncedMemo(
    async (signal) => {
      if (!userInput || userInput.length < MIN_USER_INPUT_LENGTH_FOR_API_CALL || !isOpen) {
        return undefined;
      }

      // to avoid unnecessary second call to getAddressSuggestions on item selected
      const matchingSuggestion: PartialGeocodingDto | undefined =
        suggestionsResponse &&
        (suggestionsResponse.suggestions as PartialGeocodingDto[])?.find(
          (suggestion) => suggestion.formattedAddress === userInput,
        );

      if (matchingSuggestion) {
        setLoading(false);
        return {
          suggestions: [matchingSuggestion],
          source: suggestionsResponse ? suggestionsResponse.source : GeocodingProvider.GOOGLE_MAPS,
        };
      }

      const newSuggestionsResponse = await getAddressSuggestions(userInput, signal);
      setLoading(false);
      return newSuggestionsResponse;
    },
    DEBOUNCE_DELAY_FOR_API_CALL,
    [userInput],
    { cancelable: true },
  );

  useEffect(() => {
    if (!loading && (!suggestionsResponse?.suggestions || suggestionsResponse.suggestions.length === 0)) {
      sendNoOptionsFoundEvent(name as keyof InformationFormValues);
    }
  }, [suggestionsResponse?.suggestions, loading, name]);

  return (
    <InputAutocomplete
      inputProps={{
        ref: inputRef,
        value: userInput,
        placeholder,
        onFocus: () => {
          if (inputRef?.current) {
            inputRef.current.select();
          }
        },
        onBlur: () => {
          setOpen(false);

          if (!userInput) {
            updateAddress(undefined);
            onChange(undefined);
          }

          if (userInput && lastAddress && userInput !== lastAddress.suggestedAddress) {
            setUserInput(lastAddress.suggestedAddress);
          }

          if (onBlur) {
            onBlur();
          }
        },
      }}
      isOpen={isOpen}
      itemToString={(item: GeocodingDto) => item.formattedAddress}
      emptyComponent={() => (
        <InputAutocomplete.Item item={null}>
          <span className={styles.EmptyComponent}>{loading ? 'Chargement...' : emptyMessage}</span>
        </InputAutocomplete.Item>
      )}
      onInputValueChange={(value) => {
        const isInputLengthEnough = value.length >= MIN_USER_INPUT_LENGTH_FOR_API_CALL;

        if (isOpen && !isInputLengthEnough) {
          setOpen(false);
        }

        if (!isOpen && isInputLengthEnough && value !== lastAddress?.suggestedAddress) {
          setOpen(true);
        }

        setUserInput(value);

        if (isInputLengthEnough) {
          setLoading(true);
        }

        if (!value) {
          setLastAddress({
            city: '',
            zipCode: '',
            formattedAddress: '',
            street: '',
            suggestedAddress: '',
          });
        }
      }}
      onOuterClick={() => setOpen(false)}
      onSelect={async (item: GeocodingDto | PartialGeocodingDto) => {
        if (item) {
          const addressDetails = await getAddressDetails((item as PartialGeocodingDto).placeId);

          setUserInput(addressDetails.formattedAddress);
          updateAddress(addressDetails);
          onChange(addressDetails);
          setLastAddress({
            ...addressDetails,
            suggestedAddress: item.formattedAddress,
          });
          inputRef?.current?.blur();
        }
      }}
    >
      {(suggestionsResponse?.suggestions || []).map((suggestion) => (
        <InputAutocomplete.Item key={suggestion.formattedAddress} item={suggestion}>
          <Icon name="map-pin" /> {suggestion.formattedAddress}
        </InputAutocomplete.Item>
      ))}
    </InputAutocomplete>
  );
}
