import { VStack } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { InformationList } from '../../../components/InformationList/InformationList';
import { InformationEntry } from '../../../components/InformationList/components/InformationEntry';
import { crmHumanFriendlySentence } from '../../../components/ScreenTemplates/InformationTemplates/InformationBonusMalusTemplate/getCrmOptions';
import {
  hasTerminationRecordOptionsDict,
  terminationRecordCountOptionsDict,
  terminationRecordDurationOptionsDict,
  terminationRecordReasonOptionsDict,
  yearsSinceHighestBonusOptionsDict,
} from '../../../constants/mappers/information';
import { DriverHasBeenCovered, InsuranceRecordType, TerminationReason, YesNoQuestion } from '../../../fsm/answers';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import type { Driver } from '../../../fsm/types';
import { Event, State } from '../../../fsm/types';
import { DateFromYearsAgo, formatDateForUI } from '../../../utils/date';
import { RecordsSection } from './components/RecordsSection';
import { SectionTitle } from './components/SectionTitle';
import { generateHasBeenCoveredText } from './helpers';

interface InsuranceRecordSummaryScreenV2Props {
  driverType: Driver;
}

export function InsuranceRecordSummaryV2({ driverType }: InsuranceRecordSummaryScreenV2Props): ReactNode {
  const {
    context: { answers },
  } = useSubscriptionFsmState();

  const send = useSubscriptionFsmDispatch();

  const driver = answers[`${driverType}Driver`]!;
  const upperDriverType = driverType.toUpperCase();

  const threeYearsAgo = DateFromYearsAgo(3);
  const hasBeenCoveredText = generateHasBeenCoveredText(driver, threeYearsAgo);

  const moveToScreen = (destination: string): void => {
    send(Event.RETURN_TO_SCREEN, { destination: State[destination as keyof typeof State] });
  };

  return (
    <VStack space={{ base: 'kitt.8', large: 'kitt.10' }}>
      <VStack space="kitt.4" borderBottomWidth={1} borderBottomColor="kitt.separator">
        <SectionTitle title="Historique d’assurance" fromDate={threeYearsAgo} />

        <InformationList>
          <InformationEntry
            label="Assuré"
            value={hasBeenCoveredText}
            onPress={() => moveToScreen(`INFORMATION_${upperDriverType}_HAS_BEEN_COVERED`)}
          >
            {driver.hasBeenCovered !== DriverHasBeenCovered.NonJamais && (
              <>
                <InformationEntry
                  hasSeparator
                  isChild
                  label="Bonus-Malus"
                  value={crmHumanFriendlySentence(driver.crm!)}
                  onPress={() => moveToScreen(`INFORMATION_${upperDriverType}_BONUS_MALUS`)}
                >
                  {driver.crm === 50 && (
                    <InformationEntry
                      label="Depuis"
                      value={yearsSinceHighestBonusOptionsDict[driver.yearsSinceHighestBonus!]}
                      onPress={() => moveToScreen(`INFORMATION_${upperDriverType}_BONUS50`)}
                    />
                  )}
                </InformationEntry>
                <InformationEntry
                  isChild
                  label="Résiliation par l'assureur"
                  value={hasTerminationRecordOptionsDict[driver.hasTermination!]}
                  onPress={() => moveToScreen(`INFORMATION_${upperDriverType}_HAS_TERMINATION_RECORD`)}
                >
                  {driver.hasTermination === YesNoQuestion.YES && driver.terminationRecords?.length && (
                    <>
                      <InformationEntry
                        isChild
                        hasSeparator
                        label="Date"
                        value={formatDateForUI(driver.terminationRecords[0].date)}
                        onPress={() => moveToScreen(`INFORMATION_${upperDriverType}_TERMINATION_RECORD_DATE`)}
                      />
                      <InformationEntry
                        isChild
                        hasSeparator
                        label="Motif"
                        value={terminationRecordReasonOptionsDict[driver.terminationRecords[0].reason]}
                        onPress={() => moveToScreen(`INFORMATION_${upperDriverType}_TERMINATION_RECORD_REASON`)}
                      />
                      <InformationEntry
                        isChild
                        hasSeparator={driver.terminationRecords[0].reason === TerminationReason.NonPayment}
                        label="Véhicule non assuré"
                        value={terminationRecordDurationOptionsDict[driver.terminationRecords[0].duration]}
                        onPress={() =>
                          moveToScreen(`INFORMATION_${upperDriverType}_TERMINATION_RECORD_DURATION_WITHOUT_INSURANCE`)
                        }
                      />
                      {driver.terminationRecords[0].reason === TerminationReason.NonPayment && (
                        <InformationEntry
                          isChild
                          label="Autre résiliation pour non paiement"
                          value={terminationRecordCountOptionsDict[driver.terminationRecords[0].terminationCount!]}
                          onPress={() =>
                            moveToScreen(
                              `INFORMATION_${upperDriverType}_TERMINATION_RECORD_TERMINATION_COUNT_FOR_NON_PAYMENT`,
                            )
                          }
                        />
                      )}
                    </>
                  )}
                </InformationEntry>
              </>
            )}
          </InformationEntry>
        </InformationList>
      </VStack>

      <RecordsSection
        recordType={InsuranceRecordType.CancellationOrSuspension}
        records={driver.cancellationOrSuspensionRecords}
      />

      <RecordsSection recordType={InsuranceRecordType.Claim} records={driver.claimRecords} />
    </VStack>
  );
}
