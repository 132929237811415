import { DropdownSelect } from '@ornikar/kitt';
import type { ReactNode } from 'react';
import { useForm } from 'react-final-form';
import { Profession } from '../../../apis/types/LongQuoteAnswers';
import type { FieldProps } from '../../../components/Field';
import { Field } from '../../../components/Field';
import { makeChoices } from '../../../fsm/answers';
import styles from './styles.module.css';

const professionOptions = makeChoices(Profession, [
  [Profession.Salarié, 'Salarié'],
  [Profession.Etudiant, 'Étudiant'],
  [Profession.ProfessionLibérale, 'Profession libérale'],
  [Profession.Entrepreneur, "Entrepreneur / Chef d'entreprise"],
  [Profession.Agriculteur, 'Agriculteur'],
  [Profession.Commercant, 'Commerçant'],
  [Profession.Artisan, 'Artisan'],
  [Profession.Fonctionnaire, 'Fonctionnaire'],
  [Profession.Ecclésiastique, 'Ecclésiastique'],
  [Profession.VRP, 'Voyageur, Représentant ou Placier (VRP)'],
  [Profession.Retraité, 'Retraité'],
  [Profession.SansProfession, 'Sans profession'],
]) as { label: string; value: Profession }[];

export function ProfessionField({ validate }: Pick<FieldProps<string>, 'validate'>): ReactNode {
  const { change, blur } = useForm();

  return (
    <Field
      component={DropdownSelect}
      name="primaryDriver.profession"
      label="Votre profession"
      choices={professionOptions}
      validate={validate}
      placeholder="Choisissez une profession"
      className={styles.Field}
      onChange={(value: string | number) => {
        change('primaryDriver?.profession', value);
        blur('primaryDriver?.profession');
      }}
    />
  );
}
