import { UrgencyCase } from '@ornikar/illustrated-icons';
import type { ReactNode } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { AddonCard } from '../../../../components/AddonCard';
import { AddonName } from '../../../../fsm/answers';
import { useSubscriptionFsmState } from '../../../../fsm/context';
import { formatPrice } from '../../../../utils/formatPrice';
import { infoModalContent } from './infoModalContent';

const messages = defineMessages({
  addonsPdcDescription: {
    id: 'addons.pdc.description',
    defaultMessage:
      "Protéger votre véhicule c'est bien, vous protéger vous même, c'est essentiel. Cette garantie double la couverture du conducteur en cas de frais médicaux ou de pertes de revenus, liés à un accident.",
  },
  addonsPdcSubtitle: {
    id: 'addons.pdc.subtitle',
    defaultMessage: 'Couvert jusqu’à 500 000€ en cas d’accident',
  },
  addonsPdcTitle: {
    id: 'addons.pdc.title',
    defaultMessage: 'Protection du conducteur étendue',
  },
});

export function DriverProtection500Card(): ReactNode {
  const {
    context: { quoteOptions, paymentPeriodicity },
  } = useSubscriptionFsmState();

  const addon = quoteOptions?.addOns.find(({ name }) => name === AddonName.OML_DRIVER_PROTECTION_500);

  const price = addon!.options[0].cost.cents;

  return (
    <AddonCard
      addonName="Protection du conducteur étendue"
      tag={<FormattedMessage {...messages.addonsPdcTitle} />}
      pagination="2/3"
      icon={<UrgencyCase />}
      title={<FormattedMessage {...messages.addonsPdcSubtitle} />}
      description={<FormattedMessage {...messages.addonsPdcDescription} />}
      price={`+${formatPrice(price)}€`}
      paymentPeriodicity={paymentPeriodicity!}
      infoModalContent={infoModalContent}
    />
  );
}
