import { VStack } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import type { CallbackScheduleItem } from '../../CallbackScheduleModule';
import { CallbackScheduleModule } from '../../CallbackScheduleModule';

export interface DaySlotsScheduleProps {
  selectedItemId?: number;
  dayItems: CallbackScheduleItem<Date>[];
  onPress: () => void;
}

export function DaySlotsSchedule({ dayItems, selectedItemId, onPress }: DaySlotsScheduleProps): ReactNode {
  return (
    <VStack space="kitt.6" width="100%">
      <CallbackScheduleModule
        title={<FormattedMessage id="callbackModuleModal.selectADay" defaultMessage="Choisissez un jour" />}
        selectedItemId={selectedItemId}
        items={dayItems}
        onPress={onPress}
      />
    </VStack>
  );
}
