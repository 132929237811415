import { CaretDownRegularIcon, CaretUpRegularIcon } from '@ornikar/kitt-icons/phosphor';
import { HStack, Highlight, Icon, Typography, View } from '@ornikar/kitt-universal';
import cx from 'classnames';
import type { ReactChild, ReactNode } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useInsuranceDesktopMediaQuery } from '../../hooks/useInsuranceDesktopMediaQuery';
import { onEnterKeyPressed } from '../../utils/keyboard';
import { sendEvent } from '../../utils/mixpanel';
import styles from './styles.module.css';

interface InputHighlightProps {
  title?: ReactChild;
  titleIcon?: ReactNode;
  onExpand?: () => void;
  children: ReactChild;
  className?: string;
  condensed?: boolean;
  expandable?: boolean;
  defaultExpanded?: boolean;
}

export function InputHighlight({
  title,
  titleIcon,
  onExpand,
  children,
  className,
  condensed = false,
  expandable = true,
  defaultExpanded = false,
}: InputHighlightProps): ReactNode {
  const isMobile = !useInsuranceDesktopMediaQuery();
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentOverflowHidden, setContentOverflowHidden] = useState(condensed || isMobile);

  useEffect(() => {
    setContentOverflowHidden(condensed || isMobile);
  }, [condensed, isMobile]);

  const timeoutRef = useRef<number>();

  const contentHeight = contentRef.current?.scrollHeight ?? 0;

  const toggleExpand = () => {
    window.clearTimeout(timeoutRef.current);

    if (isExpanded) {
      setContentOverflowHidden(true);
    } else {
      timeoutRef.current = window.setTimeout(() => {
        setContentOverflowHidden(false);
      }, 300);

      if (onExpand) {
        onExpand();
      }

      sendEvent('Expand Highlight');
    }
    setIsExpanded((current) => !current);
  };

  return (
    <div className={cx(styles.Card, className)}>
      <Highlight>
        <div className={styles.InnerContainer}>
          {expandable && (condensed || isMobile) ? (
            <>
              <div
                role="button"
                tabIndex={0}
                className={styles.Header}
                onClick={toggleExpand}
                onKeyDown={(e) => onEnterKeyPressed(e, toggleExpand)}
              >
                {titleIcon}
                <Typography.Text flex={1} variant="bold">
                  {title}
                </Typography.Text>
                <Icon color="black" icon={isExpanded ? <CaretUpRegularIcon /> : <CaretDownRegularIcon />} />
              </div>
              <div
                ref={contentRef}
                className={styles.Content}
                style={{
                  maxHeight: isExpanded ? contentHeight : 0,
                  overflow: contentOverflowHidden ? 'hidden' : 'unset',
                }}
              >
                <View marginTop={title ? 'kitt.3' : 0}>
                  <Typography.Text>{children}</Typography.Text>
                </View>
              </div>
            </>
          ) : (
            <>
              <Typography.Text variant="bold">{title}</Typography.Text>
              <HStack
                marginTop={title ? 'kitt.3' : 0}
                space={32}
                style={{
                  transitionProperty: 'max-height',
                  transitionTimingFunction: 'linear',
                  transitionDuration: '200ms',
                }}
              >
                <Typography.Text>{children}</Typography.Text>
              </HStack>
            </>
          )}
        </div>
      </Highlight>
    </div>
  );
}
