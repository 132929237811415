import type { ReactNode } from 'react';
import { HasTerminationRecordTemplate } from '../../../../components/ScreenTemplates/InformationTemplates/InsuranceRecordTemplates/TerminationRecordTemplates/HasTerminationRecordTemplate';
import { DateFromYearsAgo } from '../../../../utils/date';

export function SecondaryHasTerminationRecordScreen(): ReactNode {
  const threeYearsAgo = DateFromYearsAgo(3);

  return (
    <HasTerminationRecordTemplate
      title={`Son contrat a-t-il été résilié par son assureur depuis le ${threeYearsAgo}\u00A0?`}
      fieldName="secondaryDriver.hasTermination"
    />
  );
}
