import { useEffect, useMemo } from 'react';
import { useSubscriptionFsmState } from '../fsm/context';
import type { State } from '../fsm/types';
import { sendEvent } from '../utils/mixpanel';

function pushCrisp(...args: (string | (() => void))[]) {
  return (window as any).$crisp?.push(args);
}

export function useCripsTracking(): void {
  const fsmState = useSubscriptionFsmState();

  const value = fsmState.value as State;
  const { context } = useSubscriptionFsmState();

  const tarifResult = useMemo(() => {
    return context.tarificationResult;
  }, [context.tarificationResult]);

  const longQuoteId = useMemo(() => (tarifResult?.isOk ? tarifResult.quoteId : undefined), [tarifResult]);

  useEffect(() => {
    pushCrisp('on', 'chat:opened', () => {
      sendEvent('Open Chat Session', {
        long_quote_id: longQuoteId,
      });
    });

    return () => {
      pushCrisp('off', 'chat:opened');
    };
  }, [longQuoteId, value]);
}
