import { assign } from 'xstate';
import type { Package } from '../../apis/types/Formula';
import type { TarificationResultOK } from '../../apis/types/Quotation';
import type { SimpleEventAssign } from './types';

export const savePickedFormule: SimpleEventAssign<any> = assign((context) => {
  const { packages } = context.tarificationResult as TarificationResultOK;
  const formulePickedObject = packages.find((formule: Package) => formule.reference === context.answers.formulePicked);
  return { formulePickedObject };
});

export const setDefaultDeductibles: SimpleEventAssign<any> = assign((context) => {
  if (!context.quoteOptions?.deductibles) {
    return {};
  }

  const { deductibles } = context.quoteOptions;
  const { answers } = context;

  // eslint-disable-next-line unicorn/no-array-reduce
  const defaultDeductibles = deductibles.reduce((acc, { name, options }) => {
    const defaultOption = options.find(({ cost }) => cost.cents === 0);
    const selectedOption = answers?.deductibles?.[name];

    return Object.assign(acc, {
      [name]: {
        name,
        term: selectedOption?.term ?? defaultOption?.term,
      },
    });
  }, {});

  return {
    answers: {
      ...context.answers,
      deductibles: defaultDeductibles,
    },
  };
});

export const resetSubscription: SimpleEventAssign<any> = assign((context) => ({
  tarificationResult: undefined,
  formulePickedObject: undefined,
  devisCreationResult: undefined,
  subscribeResult: undefined,
  answers: {
    ...context.answers,
    deductibles: undefined,
    addons: undefined,
    formulePicked: undefined,
    hamonAdress: undefined,
    hamonCodePostal: undefined,
    hamonCommune: undefined,
    hamonWant: undefined,
    hamonContractRef: undefined,
    hamonName: undefined,
  },
}));
