import type { DoneInvokeEvent } from 'xstate';
import type { StateMachineInitContext } from '../types';

export const isRemoteQuoteFromCoreApi = (context: StateMachineInitContext): boolean => {
  return Boolean(context.quoteId);
};

export const isRemoteQuoteFromPolicyCenter = (context: StateMachineInitContext): boolean => {
  return Boolean(context.submissionId && context.submissionNumber);
};

export const isFetched = (_: StateMachineInitContext, event: DoneInvokeEvent<StateMachineInitContext>): boolean => {
  return event.data.isFetched;
};
