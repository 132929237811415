import { Button, Tag, Typography } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { addonNameMapping } from '../../../constants/options';
import { useSubscriptionFsmState } from '../../../fsm/context';
import { formatPeriodicity } from '../../../utils/formatPeriodicity';
import { formatPrice } from '../../../utils/formatPrice';
import { usePaymentPeriodicityDetails } from '../../../utils/price';
import { useAddonsPrice } from '../hooks/useAddonsPrice';
import { useDeductiblesPrice } from '../hooks/useDeductiblesPrice';
import { useSelectedAddonsNames } from '../hooks/useSelectedAddonsNames';
import styles from './styles.module.css';

interface FormulaPriceCardProps {
  buttonLabel?: string;
  onSubmit?: () => void;
}

export function FormulaPriceCard({ buttonLabel = 'Continuer', onSubmit }: FormulaPriceCardProps): ReactNode {
  const { price: formulaPrice, periodicity: paymentPeriodicity } = usePaymentPeriodicityDetails();

  const {
    context: { formulePickedObject },
  } = useSubscriptionFsmState();

  const selectedAddonsNames = useSelectedAddonsNames();

  const packageLevel = formulePickedObject?.name;

  const addonsPrice = useAddonsPrice();
  const deductiblesPrice = useDeductiblesPrice();
  const price = formatPrice(formulaPrice + addonsPrice + deductiblesPrice);
  const periodicity = formatPeriodicity(paymentPeriodicity);

  return (
    <div className={styles.OfferCard}>
      <Typography.Text base="header4" variant="bold" textAlign="center">
        {packageLevel}
        <br />
        <Typography.Text color="primary">
          {price}€/{periodicity}
        </Typography.Text>
      </Typography.Text>

      {selectedAddonsNames.length > 0 ? (
        <div className={styles.CardTags}>
          {selectedAddonsNames.map((addonName) => (
            <Tag key={addonName} label={addonNameMapping[addonName]} type="primary" />
          ))}
        </div>
      ) : null}

      {onSubmit ? (
        <div className={styles.SubmitButton}>
          <Button stretch type="primary" onPress={onSubmit}>
            {buttonLabel}
          </Button>
        </div>
      ) : null}
    </div>
  );
}
