import type { DropdownSelectProps } from '@ornikar/kitt';
import { DropdownSelect } from '@ornikar/kitt';
import type { ReactNode } from 'react';
import type { Except } from 'type-fest';
import { useSubmitWithDelay } from '../../hooks/useSubmitWithDelay';

export function LocalDropdownSelect<Values extends string | number>(
  props: Except<DropdownSelectProps<Values>, 'onChange'> & {
    onChange: (value: Values) => void;
    submitOnChange?: boolean;
  },
): ReactNode {
  const submitWithDelay = useSubmitWithDelay();

  return (
    <DropdownSelect
      {...(props as DropdownSelectProps<Values>)}
      onChange={(value: Values) => {
        if (props.onChange) props.onChange(value);
        if (props.onBlur) props.onBlur();
        if (props.submitOnChange) {
          submitWithDelay();
        }
      }}
    />
  );
}
