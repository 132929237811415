import { PageLoader, Typography, VStack } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { Logo } from '../Logo';

export function LoadingScreen(): ReactNode {
  return (
    <VStack minHeight="100vh" height="100vh" justifyContent="center" alignItems="center" space="kitt.4">
      <Logo height={40} />
      <Typography.Text color="black-light">Redirection vers Ornikar en cours...</Typography.Text>
      <PageLoader />
    </VStack>
  );
}
