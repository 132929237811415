import type { ReactNode } from 'react';
import { memo, useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { PageLoader } from './components/PageLoader';
import { useSubscriptionFsmStateValue } from './fsm/context';
import { Driver, State } from './fsm/types';
import { CannotQuoteScreen } from './screens/Contact/CannotQuoteScreen';
import { ContactUsEndQuoteScreen } from './screens/Contact/ContactUsEndQuoteScreen';
import { InsuredSinceLessThanOneYearScreen } from './screens/Contact/InsuredSinceLessThanOneYearScreen';
import { ReverseSubscriptionScreen } from './screens/Contact/ReverseSubscriptionScreen';
import { DrivingCityScreen } from './screens/Driving/DrivingCityScreen';
import { DrivingHousingTypeScreen } from './screens/Driving/DrivingHousingTypeScreen';
import { DrivingIsPropertyOwnerScreen } from './screens/Driving/DrivingIsPropertyOwnerScreen';
import { DrivingKilometersPerYearScreen } from './screens/Driving/DrivingKilometersPerYearScreen';
import { DrivingPrimaryDefaultLicenseDateScreen } from './screens/Driving/DrivingPrimaryDefaultLicenseDateScreen';
import { DrivingPrimaryLicenseDateScreen } from './screens/Driving/DrivingPrimaryLicenseDateScreen';
import { DrivingPrimaryWasAccompaniedScreen } from './screens/Driving/DrivingPrimaryWasAccompaniedScreen';
import { DrivingRegistrationDocumentOwnerIsSubscriberScreen } from './screens/Driving/DrivingRegistrationDocumentOwnerIsSubscriberScreen';
import { DrivingRegistrationDocumentOwnerScreen } from './screens/Driving/DrivingRegistrationDocumentOwnerScreen';
import { DrivingSummaryScreen } from './screens/Driving/DrivingSummaryScreen';
import { DrivingVehicleParkingScreen } from './screens/Driving/DrivingVehicleParkingScreen';
import { DrivingVehicleUsageScreen } from './screens/Driving/DrivingVehicleUsageScreen';
import { CancellationOrSuspensionRecordDateScreen } from './screens/Information/CancellationOrSuspensionRecordDateScreen';
import { CancellationOrSuspensionRecordScreen } from './screens/Information/CancellationOrSuspensionRecordScreen';
import { CancellationRecordIsClaimScreen } from './screens/Information/CancellationRecordIsClaimScreen';
import { ClaimRecordDateScreen } from './screens/Information/ClaimRecordDateScreen';
import { ClaimRecordQuantityScreen } from './screens/Information/ClaimRecordQuantityScreen';
import { DriverDetailsScreen } from './screens/Information/DriverDetailsScreen';
import { DriverHasBeenCoveredScreen } from './screens/Information/DriverHasBeenCoveredScreen';
import { InformationDeclareSecondaryDriverScreen } from './screens/Information/InformationDeclareSecondaryDriverScreen';
import { LicenseTypeScreen } from './screens/Information/LicenseTypeScreen';
import { PrimaryAddClaimRecordScreen } from './screens/Information/PrimaryDriver/PrimaryAddClaimRecordScreen';
import { PrimaryAddInsuranceRecordScreen } from './screens/Information/PrimaryDriver/PrimaryAddInsuranceRecordScreen';
import { PrimaryBonus50Screen } from './screens/Information/PrimaryDriver/PrimaryBonus50Screen';
import { PrimaryBonusMalusScreen } from './screens/Information/PrimaryDriver/PrimaryBonusMalusScreen';
import { PrimaryCancellationRecordAlcoholLevelScreen } from './screens/Information/PrimaryDriver/PrimaryCancellationRecordAlcoholLevelScreen';
import { PrimaryCancellationRecordClaimAlcoholLevelScreen } from './screens/Information/PrimaryDriver/PrimaryCancellationRecordClaimAlcoholLevelScreen';
import { PrimaryCancellationRecordClaimResponsibilityLevelScreen } from './screens/Information/PrimaryDriver/PrimaryCancellationRecordClaimResponsibilityLevelScreen';
import { PrimaryCancellationRecordClaimWithAlcoholScreen } from './screens/Information/PrimaryDriver/PrimaryCancellationRecordClaimWithAlcoholScreen';
import { PrimaryCancellationRecordHitAndRunScreen } from './screens/Information/PrimaryDriver/PrimaryCancellationRecordHitAndRunScreen';
import { PrimaryCancellationRecordReasonScreen } from './screens/Information/PrimaryDriver/PrimaryCancellationRecordReasonScreen';
import { PrimaryCancellationRecordSuspendedDurationScreen } from './screens/Information/PrimaryDriver/PrimaryCancellationRecordSuspendedDurationScreen';
import { PrimaryClaimRecordAlcoholLevelScreen } from './screens/Information/PrimaryDriver/PrimaryClaimRecordAlcoholLevelScreen';
import { PrimaryClaimRecordDriverScreen } from './screens/Information/PrimaryDriver/PrimaryClaimRecordDriverScreen';
import { PrimaryClaimRecordNatureScreen } from './screens/Information/PrimaryDriver/PrimaryClaimRecordNatureScreen';
import { PrimaryClaimRecordResponsibilityLevelScreen } from './screens/Information/PrimaryDriver/PrimaryClaimRecordResponsibilityLevelScreen';
import { PrimaryClaimRecordWithAlcoholScreen } from './screens/Information/PrimaryDriver/PrimaryClaimRecordWithAlcoholScreen';
import { PrimaryHasTerminationRecordScreen } from './screens/Information/PrimaryDriver/PrimaryHasTerminationRecordScreen';
import { PrimaryInsurancePeriodsScreen } from './screens/Information/PrimaryDriver/PrimaryInsurancePeriodsScreen';
import { PrimaryInsuranceRecordSummaryScreen } from './screens/Information/PrimaryDriver/PrimaryInsuranceRecordSummaryScreen';
import { PrimaryTerminationRecordDurationWithoutInsuranceScreen } from './screens/Information/PrimaryDriver/PrimaryTerminationRecordDurationWithoutInsuranceScreen';
import { PrimaryTerminationRecordLicenseCanceledOrSuspendedAlcoholLevelScreen } from './screens/Information/PrimaryDriver/PrimaryTerminationRecordLicenseCanceledOrSuspendedAlcoholLevelScreen';
import { PrimaryTerminationRecordLicenseCanceledOrSuspendedClaimAlcoholLevelScreen } from './screens/Information/PrimaryDriver/PrimaryTerminationRecordLicenseCanceledOrSuspendedClaimAlcoholLevelScreen';
import { PrimaryTerminationRecordLicenseCanceledOrSuspendedClaimResponsibilityLevelScreen } from './screens/Information/PrimaryDriver/PrimaryTerminationRecordLicenseCanceledOrSuspendedClaimResponsibilityLevelScreen';
import { PrimaryTerminationRecordLicenseCanceledOrSuspendedClaimWithAlcoholScreen } from './screens/Information/PrimaryDriver/PrimaryTerminationRecordLicenseCanceledOrSuspendedClaimWithAlcoholScreen';
import { PrimaryTerminationRecordLicenseCanceledOrSuspendedHitAndRunScreen } from './screens/Information/PrimaryDriver/PrimaryTerminationRecordLicenseCanceledOrSuspendedHitAndRunScreen';
import { PrimaryTerminationRecordLicenseCanceledOrSuspendedIsClaimScreen } from './screens/Information/PrimaryDriver/PrimaryTerminationRecordLicenseCanceledOrSuspendedIsClaimScreen';
import { PrimaryTerminationRecordLicenseCanceledOrSuspendedReasonScreen } from './screens/Information/PrimaryDriver/PrimaryTerminationRecordLicenseCanceledOrSuspendedReasonScreen';
import { PrimaryTerminationRecordLicenseSuspendedDurationScreen } from './screens/Information/PrimaryDriver/PrimaryTerminationRecordLicenseSuspendedDurationScreen';
import { PrimaryTerminationRecordNonPaymentCountScreen } from './screens/Information/PrimaryDriver/PrimaryTerminationRecordNonPaymentCountScreen';
import { PrimaryTerminationRecordReasonScreen } from './screens/Information/PrimaryDriver/PrimaryTerminationRecordReasonScreen';
import { SecondaryAddClaimRecordScreen } from './screens/Information/SecondaryDriver/SecondaryAddClaimRecordScreen';
import { SecondaryAddInsuranceRecordScreen } from './screens/Information/SecondaryDriver/SecondaryAddInsuranceRecordScreen';
import { SecondaryBonus50Screen } from './screens/Information/SecondaryDriver/SecondaryBonus50Screen';
import { SecondaryBonusMalusScreen } from './screens/Information/SecondaryDriver/SecondaryBonusMalusScreen';
import { SecondaryCancellationRecordAlcoholLevelScreen } from './screens/Information/SecondaryDriver/SecondaryCancellationRecordAlcoholLevelScreen';
import { SecondaryCancellationRecordClaimAlcoholLevelScreen } from './screens/Information/SecondaryDriver/SecondaryCancellationRecordClaimAlcoholLevelScreen';
import { SecondaryCancellationRecordClaimResponsibilityLevelScreen } from './screens/Information/SecondaryDriver/SecondaryCancellationRecordClaimResponsibilityLevelScreen';
import { SecondaryCancellationRecordClaimWithAlcoholScreen } from './screens/Information/SecondaryDriver/SecondaryCancellationRecordClaimWithAlcoholScreen';
import { SecondaryCancellationRecordHitAndRunScreen } from './screens/Information/SecondaryDriver/SecondaryCancellationRecordHitAndRunScreen';
import { SecondaryCancellationRecordReasonScreen } from './screens/Information/SecondaryDriver/SecondaryCancellationRecordReasonScreen';
import { SecondaryCancellationRecordSuspendedDurationScreen } from './screens/Information/SecondaryDriver/SecondaryCancellationRecordSuspendedDurationScreen';
import { SecondaryClaimRecordAlcoholLevelScreen } from './screens/Information/SecondaryDriver/SecondaryClaimRecordAlcoholLevelScreen';
import { SecondaryClaimRecordDriverScreen } from './screens/Information/SecondaryDriver/SecondaryClaimRecordDriverScreen';
import { SecondaryClaimRecordNatureScreen } from './screens/Information/SecondaryDriver/SecondaryClaimRecordNatureScreen';
import { SecondaryClaimRecordResponsibilityLevelScreen } from './screens/Information/SecondaryDriver/SecondaryClaimRecordResponsibilityLevelScreen';
import { SecondaryClaimRecordWithAlcoholScreen } from './screens/Information/SecondaryDriver/SecondaryClaimRecordWithAlcoholScreen';
import { SecondaryDriverSummaryScreen } from './screens/Information/SecondaryDriver/SecondaryDriverSummaryScreen';
import { SecondaryHasTerminationRecordScreen } from './screens/Information/SecondaryDriver/SecondaryHasTerminationRecordScreen';
import { SecondaryInsurancePeriodsScreen } from './screens/Information/SecondaryDriver/SecondaryInsurancePeriodsScreen';
import { SecondaryLicenseDateScreen } from './screens/Information/SecondaryDriver/SecondaryLicenseDateScreen';
import { SecondaryRelationScreen } from './screens/Information/SecondaryDriver/SecondaryRelationScreen';
import { SecondaryTerminationRecordLicenseCanceledOrSuspendedAlcoholLevelScreen } from './screens/Information/SecondaryDriver/SecondaryTerminationRecordLicenseCanceledOrSuspendedAlcoholLevelScreen';
import { SecondaryTerminationRecordLicenseCanceledOrSuspendedClaimAlcoholLevelScreen } from './screens/Information/SecondaryDriver/SecondaryTerminationRecordLicenseCanceledOrSuspendedClaimAlcoholLevelScreen';
import { SecondaryTerminationRecordLicenseCanceledOrSuspendedClaimResponsibilityLevelScreen } from './screens/Information/SecondaryDriver/SecondaryTerminationRecordLicenseCanceledOrSuspendedClaimResponsibilityLevelScreen';
import { SecondaryTerminationRecordLicenseCanceledOrSuspendedClaimWithAlcoholScreen } from './screens/Information/SecondaryDriver/SecondaryTerminationRecordLicenseCanceledOrSuspendedClaimWithAlcoholScreen';
import { SecondaryTerminationRecordLicenseCanceledOrSuspendedHitAndRunScreen } from './screens/Information/SecondaryDriver/SecondaryTerminationRecordLicenseCanceledOrSuspendedHitAndRunScreen';
import { SecondaryTerminationRecordLicenseCanceledOrSuspendedIsClaimScreen } from './screens/Information/SecondaryDriver/SecondaryTerminationRecordLicenseCanceledOrSuspendedIsClaimScreen';
import { SecondaryTerminationRecordLicenseCanceledOrSuspendedReasonScreen } from './screens/Information/SecondaryDriver/SecondaryTerminationRecordLicenseCanceledOrSuspendedReasonScreen';
import { SecondaryTerminationRecordLicenseSuspendedDurationScreen } from './screens/Information/SecondaryDriver/SecondaryTerminationRecordLicenseSuspendedDurationScreen';
import { SecondaryTerminationRecordReasonScreen } from './screens/Information/SecondaryDriver/SecondaryTerminationRecordReasonScreen';
import { SecondaryWasAccompaniedScreen } from './screens/Information/SecondaryDriver/SecondaryWasAccompaniedScreen';
import { TerminationRecordDateScreen } from './screens/Information/TerminationRecordDateScreen';
import { CreditCardPaymentWithAdyenScreen } from './screens/Insurance/CreditCardPaymentWithAdyenScreen';
import { HamonLawInformationScreen } from './screens/Insurance/HamonLawInformationScreen';
import { IbanScreen } from './screens/Insurance/IbanScreen';
import { LREInformationScreen } from './screens/Insurance/LREInformationScreen';
import { SepaInformationConfirmationScreen } from './screens/Insurance/SepaInformationConfirmationScreen';
import { ShoppingCartScreen } from './screens/Insurance/ShoppingCartScreen';
import { VehicleLegalInformationScreen } from './screens/Insurance/VehicleLegalInformationScreen';
import { WebappIntroductionScreen } from './screens/Insurance/WebappIntroductionScreen';
import { AddonsIntroductionScreen } from './screens/Options/AddonsIntroductionScreen';
import { Assistance0KmScreen } from './screens/Options/Assistance0kmScreen';
import { DeductiblesSelectionScreen } from './screens/Options/DeductiblesSelectionScreen';
import { DriverProtection500Screen } from './screens/Options/DriverProtection500Screen';
import { VehicleLoanScreen } from './screens/Options/VehicleLoanScreen';
import { CannotRetrieveQuoteScreen } from './screens/Tarification/CannotRetrieveQuoteScreen';
import { FormulesScreen } from './screens/Tarification/FormulesScreen';
import { TarificationLoadingScreen } from './screens/Tarification/TarificationLoadingScreen';
import { VehicleAcquisitionDateScreen } from './screens/Vehicle/VehicleAcquisitionDateScreen';
import { VehicleBrandScreen } from './screens/Vehicle/VehicleBrandScreen';
import { VehicleCarburantScreen } from './screens/Vehicle/VehicleCarburantScreen';
import { VehicleCirculationDateScreen } from './screens/Vehicle/VehicleCirculationDateScreen';
import { VehicleCoverageDateScreen } from './screens/Vehicle/VehicleCoverageDateScreen';
import { VehicleCoveredSinceScreen } from './screens/Vehicle/VehicleCoveredSinceScreen';
import { VehicleCurrentContractAnniversaryMonthScreen } from './screens/Vehicle/VehicleCurrentContractAnniversaryMonthScreen';
import { VehicleDefaultCirculationDateScreen } from './screens/Vehicle/VehicleDefaultCirculationDateScreen';
import { VehicleFundingMethodScreen } from './screens/Vehicle/VehicleFundingMethodScreen';
import { VehicleIsCoveredScreen } from './screens/Vehicle/VehicleIsCoveredScreen';
import { VehicleLastInsuranceEndDateScreen } from './screens/Vehicle/VehicleLastInsuranceEndDateScreen';
import { VehicleLicensePlateScreen } from './screens/Vehicle/VehicleLicensePlateScreen';
import { VehicleModelScreen } from './screens/Vehicle/VehicleModelScreen';
import { VehiclePuissanceFiscaleScreen } from './screens/Vehicle/VehiclePuissanceFiscaleScreen';
import { VehicleSearchChoiceScreen } from './screens/Vehicle/VehicleSearchChoiceScreen';
import { VehicleSummaryScreen } from './screens/Vehicle/VehicleSummaryScreen';
import { VehicleVersionScreen } from './screens/Vehicle/VehicleVersionScreen';

function unknownState(state: never): never {
  throw new Error(`Unknown state ${state as string}`);
}

function StateSwitch(): ReactNode {
  const state = useSubscriptionFsmStateValue() as State;

  useEffect(() => {
    if ((window as any).hj) {
      hotjar.stateChange(`/assurance-auto/souscription?state=${state}`);
    }
  }, [state]);

  switch (state) {
    // MY VEHICLE SECTION
    case State.VEHICULE_SEARCH_CHOICE: {
      return <VehicleSearchChoiceScreen />;
    }
    case State.VEHICULE_BRAND: {
      return <VehicleBrandScreen />;
    }
    case State.VEHICULE_MODEL: {
      return <VehicleModelScreen />;
    }
    case State.VEHICULE_PUISSANCE_FISCALE: {
      return <VehiclePuissanceFiscaleScreen />;
    }
    case State.VEHICULE_CARBURANT: {
      return <VehicleCarburantScreen />;
    }
    case State.VEHICULE_MISE_EN_CIRCULATION: {
      return <VehicleCirculationDateScreen />;
    }
    case State.VEHICULE_DEFAULT_MISE_EN_CIRCULATION: {
      return <VehicleDefaultCirculationDateScreen />;
    }
    case State.VEHICULE_VERSION: {
      return <VehicleVersionScreen />;
    }
    case State.VEHICULE_LICENSE_PLATE: {
      return <VehicleLicensePlateScreen />;
    }
    case State.VEHICULE_ACQUISITON_DATE: {
      return <VehicleAcquisitionDateScreen />;
    }
    case State.VEHICULE_COVERING_DATE: {
      return <VehicleCoverageDateScreen />;
    }
    case State.VEHICULE_IS_COVERED: {
      return <VehicleIsCoveredScreen />;
    }
    case State.VEHICULE_IS_COVERED_INCONSISTENCY: {
      return <VehicleIsCoveredScreen />;
    }
    case State.VEHICULE_COVERED_SINCE: {
      return <VehicleCoveredSinceScreen />;
    }
    case State.VEHICLE_CURRENT_CONTRACT_ANNIVERSARY_MONTH: {
      return <VehicleCurrentContractAnniversaryMonthScreen />;
    }
    case State.VEHICLE_LAST_INSURANCE_END_DATE: {
      return <VehicleLastInsuranceEndDateScreen />;
    }
    case State.VEHICLE_LAST_INSURANCE_END_DATE_INCONSISTENCY: {
      return <VehicleLastInsuranceEndDateScreen />;
    }
    case State.VEHICULE_FUNDING: {
      return <VehicleFundingMethodScreen />;
    }
    case State.VEHICLE_SUMMARY: {
      return <VehicleSummaryScreen />;
    }
    // MY DRIVING SECTION
    case State.DRIVING_PRIMARY_LICENSE_DATE: {
      return <DrivingPrimaryLicenseDateScreen />;
    }
    case State.DRIVING_PRIMARY_DEFAULT_LICENSE_DATE: {
      return <DrivingPrimaryDefaultLicenseDateScreen />;
    }
    case State.DRIVING_PRIMARY_LICENSE_TYPE: {
      return <LicenseTypeScreen driver={Driver.Primary} />;
    }
    case State.DRIVING_PRIMARY_WAS_ACCOMPANIED: {
      return <DrivingPrimaryWasAccompaniedScreen />;
    }
    case State.DRIVING_VEHICULE_USAGE: {
      return <DrivingVehicleUsageScreen />;
    }
    case State.DRIVING_KILOMETERS_PER_YEAR: {
      return <DrivingKilometersPerYearScreen />;
    }
    case State.DRIVING_VEHICULE_PARKING: {
      return <DrivingVehicleParkingScreen />;
    }
    case State.DRIVING_CITY: {
      return <DrivingCityScreen />;
    }
    case State.DRIVING_HOUSING_TYPE: {
      return <DrivingHousingTypeScreen />;
    }
    case State.DRIVING_IS_PROPERTY_OWNER: {
      return <DrivingIsPropertyOwnerScreen />;
    }
    case State.DRIVING_REGISTRATION_DOCUMENT_OWNER_IS_SUBSCRIBER: {
      return <DrivingRegistrationDocumentOwnerIsSubscriberScreen />;
    }
    case State.DRIVING_REGISTRATION_DOCUMENT_OWNER: {
      return <DrivingRegistrationDocumentOwnerScreen />;
    }
    case State.DRIVING_SUMMARY: {
      return <DrivingSummaryScreen />;
    }
    // MY INFORMATION SECTION - PRIMARY DRIVER
    case State.INFORMATION_PRIMARY_HAS_BEEN_COVERED: {
      return <DriverHasBeenCoveredScreen driver={Driver.Primary} />;
    }
    case State.INFORMATION_PRIMARY_INSURANCE_PERIODS: {
      return <PrimaryInsurancePeriodsScreen />;
    }
    case State.INFORMATION_PRIMARY_BONUS_MALUS: {
      return <PrimaryBonusMalusScreen />;
    }
    case State.INFORMATION_PRIMARY_BONUS50: {
      return <PrimaryBonus50Screen />;
    }

    // RAG SECTIONs PRIMARY
    case State.INFORMATION_PRIMARY_HAS_TERMINATION_RECORD: {
      return <PrimaryHasTerminationRecordScreen />;
    }

    case State.INFORMATION_PRIMARY_TERMINATION_RECORD_DATE: {
      return <TerminationRecordDateScreen driver={Driver.Primary} />;
    }

    case State.INFORMATION_PRIMARY_TERMINATION_RECORD_REASON: {
      return <PrimaryTerminationRecordReasonScreen />;
    }

    case State.INFORMATION_PRIMARY_TERMINATION_RECORD_DURATION_WITHOUT_INSURANCE: {
      return <PrimaryTerminationRecordDurationWithoutInsuranceScreen />;
    }

    case State.INFORMATION_PRIMARY_TERMINATION_RECORD_TERMINATION_COUNT_FOR_NON_PAYMENT: {
      return <PrimaryTerminationRecordNonPaymentCountScreen />;
    }

    case State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_DATE: {
      return <CancellationOrSuspensionRecordDateScreen linkedToATermination driver={Driver.Primary} />;
    }

    case State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_SUSPENDED_DURATION: {
      return <PrimaryTerminationRecordLicenseSuspendedDurationScreen />;
    }

    case State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_IS_CLAIM: {
      return <PrimaryTerminationRecordLicenseCanceledOrSuspendedIsClaimScreen />;
    }

    case State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_REASON: {
      return <PrimaryTerminationRecordLicenseCanceledOrSuspendedReasonScreen />;
    }

    case State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_ALCOHOL_LEVEL: {
      return <PrimaryTerminationRecordLicenseCanceledOrSuspendedAlcoholLevelScreen />;
    }

    case State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_HIT_AND_RUN: {
      return <PrimaryTerminationRecordLicenseCanceledOrSuspendedHitAndRunScreen />;
    }

    case State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_DATE: {
      return <ClaimRecordDateScreen linkedToATermination driver={Driver.Primary} />;
    }

    case State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_RESPONSIBILITY_LEVEL: {
      return <PrimaryTerminationRecordLicenseCanceledOrSuspendedClaimResponsibilityLevelScreen />;
    }

    case State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_WITH_ALCOHOL: {
      return <PrimaryTerminationRecordLicenseCanceledOrSuspendedClaimWithAlcoholScreen />;
    }

    case State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_ALCOHOL_LEVEL: {
      return <PrimaryTerminationRecordLicenseCanceledOrSuspendedClaimAlcoholLevelScreen />;
    }

    case State.INFORMATION_PRIMARY_CANCELLATION_RECORD: {
      return <CancellationOrSuspensionRecordScreen driver={Driver.Primary} />;
    }

    case State.INFORMATION_PRIMARY_CANCELLATION_RECORD_DATE: {
      return <CancellationOrSuspensionRecordDateScreen driver={Driver.Primary} />;
    }

    case State.INFORMATION_PRIMARY_CANCELLATION_RECORD_SUSPENDED_DURATION: {
      return <PrimaryCancellationRecordSuspendedDurationScreen />;
    }

    case State.INFORMATION_PRIMARY_CANCELLATION_RECORD_IS_CLAIM: {
      return <CancellationRecordIsClaimScreen driver={Driver.Primary} />;
    }

    case State.INFORMATION_PRIMARY_CANCELLATION_RECORD_REASON: {
      return <PrimaryCancellationRecordReasonScreen />;
    }

    case State.INFORMATION_PRIMARY_CANCELLATION_RECORD_ALCOHOL_LEVEL: {
      return <PrimaryCancellationRecordAlcoholLevelScreen />;
    }

    case State.INFORMATION_PRIMARY_CANCELLATION_RECORD_HIT_AND_RUN: {
      return <PrimaryCancellationRecordHitAndRunScreen />;
    }

    case State.INFORMATION_PRIMARY_CANCELLATION_RECORD_CLAIM_DATE: {
      return <ClaimRecordDateScreen linkedToACancellationOrSuspension driver={Driver.Primary} />;
    }

    case State.INFORMATION_PRIMARY_CANCELLATION_RECORD_CLAIM_RESPONSIBILITY_LEVEL: {
      return <PrimaryCancellationRecordClaimResponsibilityLevelScreen />;
    }

    case State.INFORMATION_PRIMARY_CANCELLATION_RECORD_CLAIM_WITH_ALCOHOL: {
      return <PrimaryCancellationRecordClaimWithAlcoholScreen />;
    }

    case State.INFORMATION_PRIMARY_CANCELLATION_RECORD_CLAIM_ALCOHOL_LEVEL: {
      return <PrimaryCancellationRecordClaimAlcoholLevelScreen />;
    }

    case State.INFORMATION_PRIMARY_ADD_CANCELLATION_RECORD: {
      return <CancellationOrSuspensionRecordScreen anotherOneRecord driver={Driver.Primary} />;
    }

    case State.INFORMATION_PRIMARY_CLAIM_RECORD_QUANTITY: {
      return <ClaimRecordQuantityScreen driver={Driver.Primary} />;
    }

    case State.INFORMATION_PRIMARY_CLAIM_RECORD_NATURE: {
      return <PrimaryClaimRecordNatureScreen />;
    }

    case State.INFORMATION_PRIMARY_CLAIM_RECORD_DATE: {
      return <ClaimRecordDateScreen driver={Driver.Primary} />;
    }

    case State.INFORMATION_PRIMARY_CLAIM_RECORD_DRIVER: {
      return <PrimaryClaimRecordDriverScreen />;
    }

    case State.INFORMATION_PRIMARY_CLAIM_RECORD_RESPONSIBILITY_LEVEL: {
      return <PrimaryClaimRecordResponsibilityLevelScreen />;
    }

    case State.INFORMATION_PRIMARY_CLAIM_RECORD_WITH_ALCOHOL: {
      return <PrimaryClaimRecordWithAlcoholScreen />;
    }

    case State.INFORMATION_PRIMARY_CLAIM_RECORD_ALCOHOL_LEVEL: {
      return <PrimaryClaimRecordAlcoholLevelScreen />;
    }

    case State.INFORMATION_PRIMARY_ADD_CLAIM_RECORD: {
      return <PrimaryAddClaimRecordScreen />;
    }

    case State.INFORMATION_PRIMARY_INSURANCE_RECORD_SUMMARY: {
      return <PrimaryInsuranceRecordSummaryScreen />;
    }

    case State.INFORMATION_PRIMARY_ADD_INSURANCE_RECORD: {
      return <PrimaryAddInsuranceRecordScreen />;
    }

    // MY INFORMATION SECTION - SECONDARY DRIVER
    case State.INFORMATION_DECLARE_SECONDARY_DRIVER: {
      return <InformationDeclareSecondaryDriverScreen />;
    }
    case State.INFORMATION_SECONDARY_DETAILS: {
      return <DriverDetailsScreen driverType={Driver.Secondary} />;
    }
    case State.INFORMATION_SECONDARY_LICENSE_DATE: {
      return <SecondaryLicenseDateScreen />;
    }
    case State.INFORMATION_SECONDARY_LICENSE_TYPE: {
      return <LicenseTypeScreen driver={Driver.Secondary} />;
    }
    case State.INFORMATION_SECONDARY_WAS_ACCOMPANIED: {
      return <SecondaryWasAccompaniedScreen />;
    }
    case State.INFORMATION_SECONDARY_RELATION: {
      return <SecondaryRelationScreen />;
    }
    case State.INFORMATION_SECONDARY_HAS_BEEN_COVERED: {
      return <DriverHasBeenCoveredScreen driver={Driver.Secondary} />;
    }
    case State.INFORMATION_SECONDARY_INSURANCE_PERIODS: {
      return <SecondaryInsurancePeriodsScreen />;
    }
    case State.INFORMATION_SECONDARY_BONUS_MALUS: {
      return <SecondaryBonusMalusScreen />;
    }
    case State.INFORMATION_SECONDARY_BONUS50: {
      return <SecondaryBonus50Screen />;
    }

    // RAG SECTIONS SECONDARY
    case State.INFORMATION_SECONDARY_HAS_TERMINATION_RECORD: {
      return <SecondaryHasTerminationRecordScreen />;
    }

    case State.INFORMATION_SECONDARY_TERMINATION_RECORD_DATE: {
      return <TerminationRecordDateScreen driver={Driver.Secondary} />;
    }

    case State.INFORMATION_SECONDARY_TERMINATION_RECORD_REASON: {
      return <SecondaryTerminationRecordReasonScreen />;
    }

    case State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_SUSPENDED_DURATION: {
      return <SecondaryTerminationRecordLicenseSuspendedDurationScreen />;
    }

    case State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_DATE: {
      return <CancellationOrSuspensionRecordDateScreen linkedToATermination driver={Driver.Secondary} />;
    }

    case State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_IS_CLAIM: {
      return <SecondaryTerminationRecordLicenseCanceledOrSuspendedIsClaimScreen />;
    }

    case State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_REASON: {
      return <SecondaryTerminationRecordLicenseCanceledOrSuspendedReasonScreen />;
    }

    case State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_ALCOHOL_LEVEL: {
      return <SecondaryTerminationRecordLicenseCanceledOrSuspendedAlcoholLevelScreen />;
    }

    case State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_HIT_AND_RUN: {
      return <SecondaryTerminationRecordLicenseCanceledOrSuspendedHitAndRunScreen />;
    }

    case State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_DATE: {
      return <ClaimRecordDateScreen linkedToATermination driver={Driver.Secondary} />;
    }

    case State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_RESPONSIBILITY_LEVEL: {
      return <SecondaryTerminationRecordLicenseCanceledOrSuspendedClaimResponsibilityLevelScreen />;
    }

    case State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_WITH_ALCOHOL: {
      return <SecondaryTerminationRecordLicenseCanceledOrSuspendedClaimWithAlcoholScreen />;
    }

    case State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_ALCOHOL_LEVEL: {
      return <SecondaryTerminationRecordLicenseCanceledOrSuspendedClaimAlcoholLevelScreen />;
    }

    case State.INFORMATION_SECONDARY_CANCELLATION_RECORD: {
      return <CancellationOrSuspensionRecordScreen driver={Driver.Secondary} />;
    }

    case State.INFORMATION_SECONDARY_CANCELLATION_RECORD_DATE: {
      return <CancellationOrSuspensionRecordDateScreen driver={Driver.Secondary} />;
    }

    case State.INFORMATION_SECONDARY_CANCELLATION_RECORD_SUSPENDED_DURATION: {
      return <SecondaryCancellationRecordSuspendedDurationScreen />;
    }

    case State.INFORMATION_SECONDARY_CANCELLATION_RECORD_IS_CLAIM: {
      return <CancellationRecordIsClaimScreen driver={Driver.Secondary} />;
    }

    case State.INFORMATION_SECONDARY_CANCELLATION_RECORD_REASON: {
      return <SecondaryCancellationRecordReasonScreen />;
    }

    case State.INFORMATION_SECONDARY_CANCELLATION_RECORD_ALCOHOL_LEVEL: {
      return <SecondaryCancellationRecordAlcoholLevelScreen />;
    }

    case State.INFORMATION_SECONDARY_CANCELLATION_RECORD_HIT_AND_RUN: {
      return <SecondaryCancellationRecordHitAndRunScreen />;
    }

    case State.INFORMATION_SECONDARY_CANCELLATION_RECORD_CLAIM_DATE: {
      return <ClaimRecordDateScreen linkedToACancellationOrSuspension driver={Driver.Secondary} />;
    }

    case State.INFORMATION_SECONDARY_CANCELLATION_RECORD_CLAIM_RESPONSIBILITY_LEVEL: {
      return <SecondaryCancellationRecordClaimResponsibilityLevelScreen />;
    }

    case State.INFORMATION_SECONDARY_CANCELLATION_RECORD_CLAIM_WITH_ALCOHOL: {
      return <SecondaryCancellationRecordClaimWithAlcoholScreen />;
    }

    case State.INFORMATION_SECONDARY_CANCELLATION_RECORD_CLAIM_ALCOHOL_LEVEL: {
      return <SecondaryCancellationRecordClaimAlcoholLevelScreen />;
    }

    case State.INFORMATION_SECONDARY_ADD_CANCELLATION_RECORD: {
      return <CancellationOrSuspensionRecordScreen anotherOneRecord driver={Driver.Secondary} />;
    }

    case State.INFORMATION_SECONDARY_CLAIM_RECORD_QUANTITY: {
      return <ClaimRecordQuantityScreen driver={Driver.Secondary} />;
    }

    case State.INFORMATION_SECONDARY_CLAIM_RECORD_NATURE: {
      return <SecondaryClaimRecordNatureScreen />;
    }

    case State.INFORMATION_SECONDARY_CLAIM_RECORD_DATE: {
      return <ClaimRecordDateScreen driver={Driver.Secondary} />;
    }

    case State.INFORMATION_SECONDARY_CLAIM_RECORD_DRIVER: {
      return <SecondaryClaimRecordDriverScreen />;
    }

    case State.INFORMATION_SECONDARY_CLAIM_RECORD_RESPONSIBILITY_LEVEL: {
      return <SecondaryClaimRecordResponsibilityLevelScreen />;
    }

    case State.INFORMATION_SECONDARY_CLAIM_RECORD_WITH_ALCOHOL: {
      return <SecondaryClaimRecordWithAlcoholScreen />;
    }

    case State.INFORMATION_SECONDARY_CLAIM_RECORD_ALCOHOL_LEVEL: {
      return <SecondaryClaimRecordAlcoholLevelScreen />;
    }

    case State.INFORMATION_SECONDARY_ADD_CLAIM_RECORD: {
      return <SecondaryAddClaimRecordScreen />;
    }

    case State.INFORMATION_SECONDARY_INSURANCE_RECORD_SUMMARY: {
      return <SecondaryDriverSummaryScreen />;
    }

    case State.INFORMATION_SECONDARY_ADD_INSURANCE_RECORD: {
      return <SecondaryAddInsuranceRecordScreen />;
    }

    case State.INFORMATION_PRIMARY_DETAILS: {
      return <DriverDetailsScreen driverType={Driver.Primary} />;
    }
    // QUOTE SECTION
    case State.NEEDS_SALES_VALIDATION: {
      return <ContactUsEndQuoteScreen />;
    }
    case State.CANNOT_QUOTE: {
      return <CannotQuoteScreen />;
    }
    case State.CANNOT_RETRIEVE_QUOTE: {
      return <CannotRetrieveQuoteScreen />;
    }
    case State.INSURED_SINCE_LESS_THAN_A_YEAR: {
      return <InsuredSinceLessThanOneYearScreen />;
    }
    case State.REVERSE_SUBSCRIPTION: {
      return <ReverseSubscriptionScreen />;
    }
    // Between QUOTE and LICENSE_PLATE
    case State.FORMULES: {
      return <FormulesScreen />;
    }

    /** -- NOTTINGHAM POST QUOTATION SECTIONS -- Start */

    // ADDONS
    case State.OPTIONS_INTRODUCTION: {
      return <AddonsIntroductionScreen />;
    }
    case State.ADDON_0KM_ASSISTANCE: {
      return <Assistance0KmScreen />;
    }
    case State.ADDON_DRIVER_PROTECTION_500: {
      return <DriverProtection500Screen />;
    }
    case State.ADDON_VEHICLE_LOAN: {
      return <VehicleLoanScreen />;
    }
    case State.DEDUCTIBLES_SELECTION: {
      return <DeductiblesSelectionScreen />;
    }

    // SHOPPING_CART
    case State.SHOPPING_CART: {
      return <ShoppingCartScreen />;
    }

    // HAMON LAW
    case State.HAMON_LAW_INFORMATION: {
      return <HamonLawInformationScreen />;
    }
    // LAST REQUIRED INFORMATION
    case State.VEHICLE_LEGAL_INFORMATION: {
      return <VehicleLegalInformationScreen />;
    }
    case State.LRE_INFORMATION: {
      return <LREInformationScreen />;
    }
    // PAYMENT SECTION
    case State.REGISTER_IBAN: {
      return <IbanScreen />;
    }
    case State.CONFIRM_SEPA_MANDATE: {
      return <SepaInformationConfirmationScreen />;
    }
    case State.CREDIT_CARD_PAYMENT: {
      return <CreditCardPaymentWithAdyenScreen />;
    }

    /** -- NOTTINGHAM POST QUOTATION SECTIONS -- End */

    case State.WEBAPP_INTRODUCTION: {
      return <WebappIntroductionScreen />;
    }

    case State.TARIFICATION_LOADING: {
      return <TarificationLoadingScreen />;
    }

    case State.VEHICULE_FETCH_VERSIONS:
    case State.POST_FORMULE_DISPATCH:
    case State.SENDING_SELECTED_OPTIONS_AND_CLEARING_PROMO_CODE: {
      return <PageLoader />;
    }

    default:
      return unknownState(state);
  }
}

export const MemoizedStateSwitch = memo(StateSwitch);
