import { useRequiredValidator } from '@ornikar/react-validators';
import type { ReactNode } from 'react';
import { RadioScreenTemplate } from '../../../components/ScreenTemplates/RadioScreenTemplate';
import { housingTypeOptionsList } from '../../../constants/mappers/driving';

export function DrivingHousingTypeScreen(): ReactNode {
  const requiredValidator = useRequiredValidator();

  return (
    <RadioScreenTemplate
      fieldName="housingType"
      title="Vous logez dans..."
      choices={housingTypeOptionsList}
      validate={requiredValidator}
    />
  );
}
