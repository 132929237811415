import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Switch } from '../../../Switch';
import { SwitchStateEnum } from '../../constants';
import styles from './styles.module.css';

export interface CallbackModeSwitchProps {
  value: any;
  onChange: (value: SwitchStateEnum) => void;
}

export function CallbackModeSwitch({ value, onChange }: CallbackModeSwitchProps): ReactNode {
  return (
    <Switch
      options={[
        {
          label: <FormattedMessage id="callbackModuleModal.option.asSoonAsPossible" defaultMessage="Au plus vite" />,
          value: SwitchStateEnum.AsSoonAsPossible,
        },
        {
          label: <FormattedMessage id="callbackModuleModal.option.choseASlot" defaultMessage="Choisir un créneau" />,
          value: SwitchStateEnum.ChoseASlot,
        },
      ]}
      value={value}
      className={styles.BeingCalledBackOptionsSwitch}
      onChange={onChange}
    />
  );
}
