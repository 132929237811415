import { useRequiredValidator } from '@ornikar/react-validators';
import type { ReactNode } from 'react';
import { hasHitAndRunOptionsList } from '../../../../../../constants/mappers/information';
import type { YesNoQuestion } from '../../../../../../fsm/answers';
import type { RadioScreenTemplateProps } from '../../../../RadioScreenTemplate';
import { RadioScreenTemplate } from '../../../../RadioScreenTemplate';

interface CancellationRecordHitAndRunTemplateProps
  extends Pick<RadioScreenTemplateProps<YesNoQuestion>, 'fieldName' | 'title'> {}

export function CancellationRecordHitAndRunTemplate(props: CancellationRecordHitAndRunTemplateProps): ReactNode {
  const requiredValidator = useRequiredValidator();

  return <RadioScreenTemplate {...props} choices={hasHitAndRunOptionsList} validate={requiredValidator} />;
}
