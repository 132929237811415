import { useMediaQuery } from './useMediaQuery';

interface InsuranceMediaQuery {
  isWide: boolean;
  isLarge: boolean;
  isMedium: boolean;
  isSmall: boolean;
  isExtraSmall: boolean;
}

export const useInsuranceMediaQuery = (): InsuranceMediaQuery => {
  const isWide = useMediaQuery('(min-width: 1440px)');
  const isLarge = useMediaQuery('(min-width: 1279px)');
  const isMedium = useMediaQuery('(min-width: 1023px)');
  const isSmall = useMediaQuery('(min-width: 767px)');
  const isExtraSmall = useMediaQuery('(min-width: 375px)');

  return {
    isWide,
    isLarge,
    isMedium,
    isSmall,
    isExtraSmall,
  };
};
