import { CaretLeftRegularIcon } from '@ornikar/kitt-icons/phosphor';
import { Button, Typography, View } from '@ornikar/kitt-universal';
import type { ReactElement, ReactNode } from 'react';
import { cloneElement } from 'react';
import { useGoBack } from '../../../hooks/useGoBack';
import { useInsuranceDesktopMediaQuery } from '../../../hooks/useInsuranceDesktopMediaQuery';
import { Breadcrumbs } from '../../Breadcrumbs';
import { ConstrainedWidth } from '../../ConstrainedWidth';
import styles from './styles.module.css';

export interface IllustratedTemplateProps {
  mobileIllustration: ReactElement;
  desktopIllustration: ReactElement;
  title: ReactNode;
  children: ReactNode;
  buttonLabel?: ReactNode;
  onButtonClick?: () => void;
  hideBreadcrumbs?: boolean;
  hideBackButton?: boolean;
}

export function IllustratedTemplate({
  mobileIllustration,
  desktopIllustration,
  title,
  children,
  buttonLabel,
  onButtonClick,
  hideBreadcrumbs,
  hideBackButton,
}: IllustratedTemplateProps): ReactNode {
  const isDesktop = useInsuranceDesktopMediaQuery();
  const [canBack, goBack] = useGoBack();

  return (
    <div className={styles.Container}>
      {isDesktop ? (
        hideBreadcrumbs ? (
          <div style={{ marginTop: 30 }} />
        ) : (
          <Breadcrumbs className={styles.Breadcrumbs} />
        )
      ) : (
        cloneElement(mobileIllustration, { className: styles.MobileIllustration })
      )}
      <ConstrainedWidth className={styles.ContentContainer}>
        {isDesktop && canBack && !hideBackButton ? (
          <View marginBottom={32}>
            <Button icon={<CaretLeftRegularIcon />} iconPosition="left" type="subtle-dark" onPress={goBack}>
              Étape précédente
            </Button>
          </View>
        ) : null}
        {isDesktop ? desktopIllustration : null}
        <View marginTop="kitt.10" marginBottom="kitt.4">
          {typeof title === 'string' ? <Typography.Text base="header3">{title}</Typography.Text> : title}
        </View>
        <div className={styles.DescriptionContainer}>{children}</div>
        {isDesktop && onButtonClick ? (
          <div className={styles.ButtonContainer}>
            <Button type="primary" onPress={onButtonClick}>
              {buttonLabel}
            </Button>
          </div>
        ) : null}
      </ConstrainedWidth>
      {!isDesktop && onButtonClick ? (
        <div className={styles.FixedBottomBar}>
          <Button stretch type="primary" onPress={onButtonClick}>
            {buttonLabel}
          </Button>
        </div>
      ) : null}
    </div>
  );
}
