import type { ReactNode } from 'react';
import { InformationContainer } from '../../../../../components/InformationContainer/InformationContainer';
import { InformationList } from '../../../../../components/InformationList/InformationList';
import { InformationEntry } from '../../../../../components/InformationList/components/InformationEntry';
import {
  bloodAlcoholLevelOptionsDict,
  claimRecordDriverOptionsDict,
  claimRecordNatureOptionsDict,
  claimRecordResponsibilityLevelOptionsDict,
} from '../../../../../constants/mappers/information';
import { ClaimNature, type ClaimRecord, DriverType } from '../../../../../fsm/answers';
import { formatDateForUI } from '../../../../../utils/date';
import { underTheInfluenceOfSummaryDict } from '../../helpers';

interface ClaimCardProps {
  claim: ClaimRecord;
  onEdit: () => void;
  onDelete: () => void;
}

export function ClaimCard({ claim, onEdit, onDelete }: ClaimCardProps): ReactNode {
  const title = `${claimRecordNatureOptionsDict[claim.nature]} du ${formatDateForUI(claim.date)}`;

  return (
    <InformationContainer title={title} onEdit={onEdit} onDelete={onDelete}>
      {[ClaimNature.MaterialAccident, ClaimNature.BodilyAccident].includes(claim.nature) && (
        <InformationList>
          <InformationEntry label="Conducteur au volant" value={claimRecordDriverOptionsDict[claim.driver]} />
          <InformationEntry
            label="Responsabilité"
            value={claimRecordResponsibilityLevelOptionsDict[claim.responsibilityLevel]}
          >
            {claim.driver === DriverType.Myself && (
              <InformationEntry label="Sous emprise" value={underTheInfluenceOfSummaryDict[claim.underTheInfluenceOf]}>
                {claim.bloodAlcoholLevel && (
                  <InformationEntry label="À" value={bloodAlcoholLevelOptionsDict[claim.bloodAlcoholLevel]} />
                )}
              </InformationEntry>
            )}
          </InformationEntry>
        </InformationList>
      )}
    </InformationContainer>
  );
}
