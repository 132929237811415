import type { ReactNode } from 'react';
import { AddonScreenTemplate } from '../../../components/ScreenTemplates/AddonScreenTemplate';
import { AddonName } from '../../../fsm/answers';
import { Assistance0KmCard } from './Assistance0KmCard';

export function Assistance0KmScreen(): ReactNode {
  return (
    <AddonScreenTemplate addonName={AddonName.OML_ASSISTANCE_0_KM_ADDON}>
      <Assistance0KmCard />
    </AddonScreenTemplate>
  );
}
