import type { ReactNode } from 'react';
import { CancellationRecordIsClaimTemplate } from '../../../../components/ScreenTemplates/InformationTemplates/InsuranceRecordTemplates/CancellationRecordTemplates/CancellationRecordIsClaimTemplate';
import { TerminationReason } from '../../../../fsm/answers';
import { useSubscriptionFsmState } from '../../../../fsm/context';

export function PrimaryTerminationRecordLicenseCanceledOrSuspendedIsClaimScreen(): ReactNode {
  const {
    context: {
      answers: { primaryDriver },
    },
  } = useSubscriptionFsmState();

  const withdrawalType = primaryDriver?.terminationRecords?.[0].reason;
  const title = `Cette ${
    withdrawalType === TerminationReason.DriversLicenseCancellation ? 'annulation' : 'suspension'
  } est-elle liée à un accident\u00A0?`;

  return (
    <CancellationRecordIsClaimTemplate
      fieldName="primaryDriver.terminationRecords[0].cancellationOrSuspensionRecord.accident"
      title={title}
    />
  );
}
