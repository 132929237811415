import { CaretLeftRegularIcon } from '@ornikar/kitt-icons/phosphor';
import type { ButtonProps } from '@ornikar/kitt-universal';
import { Button, Typography } from '@ornikar/kitt-universal';
import cx from 'classnames';
import type { ReactChild, ReactNode } from 'react';
import type React from 'react';
import { useForm } from 'react-final-form';
import { useSubscriptionFsmState } from '../../../fsm/context';
import { useDetectAndroidKeyboard } from '../../../hooks/useDetectAndroidKeyboard';
import { useFieldHasBeenAlreadyEdited } from '../../../hooks/useFieldHasBeenAlreadyEdited';
import { useGoBack } from '../../../hooks/useGoBack';
import { useInsuranceDesktopMediaQuery } from '../../../hooks/useInsuranceDesktopMediaQuery';
import { useInsuranceMediaQuery } from '../../../hooks/useInsuranceMediaQuery';
import { usePageBottom } from '../../../hooks/usePageBottom';
import { delayPromise } from '../../../utils/promise';
import { Breadcrumbs } from '../../Breadcrumbs';
import { InputHighlight } from '../../InputHighlight';
import { SaveQuoteButton } from '../../SaveQuoteButton';
import { SubmitButton } from '../../SubmitButton';
import styles from './styles.module.css';

export type ValidationButtonProps = ButtonProps & {
  label?: string;
  href?: string;
  loading?: boolean;
  disabled?: boolean;
};

export interface ScreenTemplateWithSideAndBottomBarsProps {
  title?: ReactChild;
  hideSaveQuoteButton?: boolean;
  hideSubmitButton?: boolean;
  hideSubmitButtonOnFirstEditionOfField?: string;
  hideBreadcrumbs?: boolean;
  submitButton?: Partial<ValidationButtonProps>;
  highlightTitle?: ReactChild;
  highlightContent?: ReactChild;
  highlightExpandable?: boolean;
  disablePaddings?: boolean;
  shouldDisplayBackButton?: boolean;
  className?: string;
  children: ReactNode;
  style?: React.CSSProperties;
  onBeforeSubmit?: () => void;
  onSubmit?: (event?: React.FormEvent<HTMLFormElement>) => void;
  backButtonLabel?: ReactNode;
  disableForm?: boolean;
  sideBar: ReactNode;
  bottomBar?: ReactNode;
}

export function ScreenTemplateWithSideAndBottomBars({
  title,
  hideSaveQuoteButton = false,
  hideSubmitButton = false,
  hideSubmitButtonOnFirstEditionOfField,
  hideBreadcrumbs = false,
  submitButton = {
    label: 'Continuer',
    disabled: false,
    loading: false,
  },
  highlightTitle,
  highlightContent,
  highlightExpandable,
  disablePaddings,
  shouldDisplayBackButton,
  className,
  style,
  children,
  onBeforeSubmit,
  onSubmit,
  backButtonLabel = 'Étape précédente',
  disableForm = false,
  sideBar,
  bottomBar,
}: ScreenTemplateWithSideAndBottomBarsProps): ReactNode {
  const isDesktop = useInsuranceDesktopMediaQuery();
  const [canBack, goBack] = useGoBack();
  const isAndroidKeyboardOpen = useDetectAndroidKeyboard();
  const { isMedium } = useInsuranceMediaQuery();
  const { submit } = useForm();
  const hasReachedBottom = usePageBottom();

  const {
    context: { isLoading },
  } = useSubscriptionFsmState();
  const fieldHasBeenAlreadyEdited = useFieldHasBeenAlreadyEdited(hideSubmitButtonOnFirstEditionOfField ?? '');

  const isSubmitButtonVisible =
    !hideSubmitButton && (!hideSubmitButtonOnFirstEditionOfField || fieldHasBeenAlreadyEdited);

  const isButtonLoading = isLoading || submitButton?.loading;

  const content = (
    <div className={styles.Wrapper}>
      <div className={cx(styles.Layout, { [styles.WithSideBar]: isMedium })}>
        {isDesktop && !hideBreadcrumbs ? (
          <div className={styles.Breadcrumbs}>
            <Breadcrumbs />
          </div>
        ) : null}
        {(canBack || shouldDisplayBackButton) && isDesktop ? (
          <div className={styles.BackButton}>
            <Button icon={<CaretLeftRegularIcon />} iconPosition="left" type="subtle-dark" onPress={goBack}>
              {backButtonLabel}
            </Button>
          </div>
        ) : null}
        <div className={styles.Content}>
          {title ? (
            <div className={styles.Title}>
              <Typography.h4 variant="bold" textAlign="center">
                {title}
              </Typography.h4>
            </div>
          ) : null}
          <div className={className}>{children}</div>
          {highlightContent ? (
            <InputHighlight title={highlightTitle} expandable={highlightExpandable}>
              {highlightContent}
            </InputHighlight>
          ) : null}
          {!isAndroidKeyboardOpen && (isSubmitButtonVisible || !hideSaveQuoteButton) ? (
            <div className={styles.ButtonSection} style={disablePaddings ? { margin: 0 } : undefined}>
              {isSubmitButtonVisible ? (
                <SubmitButton
                  disabled={submitButton?.disabled || isButtonLoading}
                  isLoading={isButtonLoading}
                  className={styles.SubmitButton}
                >
                  {submitButton?.label || 'Continuer'}
                </SubmitButton>
              ) : null}
              {!isDesktop && !hideSaveQuoteButton ? <SaveQuoteButton type="subtle" /> : null}
            </div>
          ) : null}
        </div>
        {isMedium ? (
          <div className={styles.SideBarContainer}>
            <div className={styles.StickySideBar}>{sideBar}</div>
          </div>
        ) : bottomBar ? (
          <div
            className={styles.BottomBar}
            style={{
              transition: 'bottom 0.3s ease-in-out',
              bottom: hasReachedBottom ? '-120px' : '0',
            }}
          >
            {bottomBar}
          </div>
        ) : null}
      </div>
    </div>
  );

  const commonProps = {
    style: { paddingBottom: disablePaddings ? 0 : undefined, ...style },
  };

  return disableForm ? (
    <div {...commonProps}>{content}</div>
  ) : (
    <form
      {...commonProps}
      onSubmit={async (e) => {
        e.preventDefault();

        if (onBeforeSubmit) {
          onBeforeSubmit();
        }

        if (onSubmit) {
          delayPromise(async () => onSubmit(e), 0);
        } else {
          /**
           * @devs delay is here to fix a desynchronization issue with `submitting` final-form state
           * Solution found here (different issue, same solution) -> https://github.com/final-form/react-final-form/issues/761#issuecomment-603163705
           */
          delayPromise(submit, 0);
        }
      }}
    >
      {content}
    </form>
  );
}
