import type { ReactNode } from 'react';
import { TerminationRecordNonPaymentCountTemplate } from '../../../../components/ScreenTemplates/InformationTemplates/InsuranceRecordTemplates/TerminationRecordTemplates/TerminationRecordNonPaymentCountTemplate';
import { DateFromYearsAgo } from '../../../../utils/date';

export function PrimaryTerminationRecordNonPaymentCountScreen(): ReactNode {
  const threeYearsAgo = DateFromYearsAgo(3);
  return (
    <TerminationRecordNonPaymentCountTemplate
      title={`Combien d'assureurs vous ont résilié depuis le ${threeYearsAgo} pour non paiement d'assurance\u00A0?`}
      fieldName="primaryDriver.terminationRecords[0].terminationCount"
    />
  );
}
