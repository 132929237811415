import authTranslationsFR from '@ornikar/auth/translations/fr-FR.json';
import { OrnikarIntlProvider } from '@ornikar/react-intl';
import validatorsTranslationsFR from '@ornikar/react-validators/translations/fr-FR.json';
import type { ReactNode } from 'react';
import translationsFR from '../translations/fr-FR.json';

const messages: Record<string, Record<string, string>> = {
  'fr-FR': { ...translationsFR, ...authTranslationsFR, ...validatorsTranslationsFR },
};

interface IntlProviderProps {
  children: ReactNode;
}

export function IntlProvider({ children }: IntlProviderProps): ReactNode {
  const locale = 'fr-FR';
  return (
    <OrnikarIntlProvider locale={locale} messages={messages[locale]}>
      {children}
    </OrnikarIntlProvider>
  );
}
