import { useRef } from 'react';

const DEFAULT_DELAY_IN_MS = 300;

export function useDebounce(callback: (...args: any) => void, delay: number = DEFAULT_DELAY_IN_MS): () => void {
  const timeout = useRef<NodeJS.Timeout | null>(null);

  const debounce = (): void => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      callback();
    }, delay);
  };

  return debounce;
}
