import { useRequiredValidator } from '@ornikar/react-validators';
import type { ReactNode } from 'react';
import type { Except } from 'type-fest';
import { claimRecordResponsibilityLevelOptionsList } from '../../../../../../constants/mappers/information';
import { ResponsibilityLevel } from '../../../../../../fsm/answers';
import type { RadioScreenTemplateProps } from '../../../../RadioScreenTemplate';
import { RadioScreenTemplate } from '../../../../RadioScreenTemplate';

interface ClaimRecordResponsibilityLevelTemplateProps
  extends Except<RadioScreenTemplateProps<ResponsibilityLevel>, 'choices'> {
  fieldName: string;
  filteredChoices?: ResponsibilityLevel[];
}

export function ClaimRecordResponsibilityLevelTemplate({
  title,
  fieldName,
  filteredChoices = [
    ResponsibilityLevel.Responsible,
    ResponsibilityLevel.PartiallyResponsible,
    ResponsibilityLevel.NonResponsible,
  ],
}: ClaimRecordResponsibilityLevelTemplateProps): ReactNode {
  const requiredValidator = useRequiredValidator();

  return (
    <RadioScreenTemplate
      fieldName={fieldName}
      choices={claimRecordResponsibilityLevelOptionsList.filter((choice) => filteredChoices.includes(choice.value))}
      title={title}
      validate={requiredValidator}
      highlightTitle="Où trouver le niveau de responsabilité&nbsp;?"
      highlightContent="Sur votre Relevé d'Information qui vous renseigne sur le caractère responsable ou non des sinistres que vous avez déclarés."
    />
  );
}
