import type { ReactNode } from 'react';
import { RadioScreenTemplate } from '../../../components/ScreenTemplates/RadioScreenTemplate';
import { vehicleInsuredByOptionsList } from '../../../constants/mappers/vehicle';
import { useVehicleIsCoveredValidator } from '../../../forms/validation/sections/vehicle/vehicleIsCovered';

export function VehicleIsCoveredScreen(): ReactNode {
  const vehicleIsCoveredValidator = useVehicleIsCoveredValidator();

  return (
    <RadioScreenTemplate
      title="Votre véhicule est-il actuellement assuré&nbsp;?"
      highlightTitle="Notre conseil"
      highlightContent={
        <div>
          Un véhicule n’ayant pas été assuré pendant plusieurs jours après sa date d’achat, peut ne pas être assurable
          ou bien faire augmenter votre tarif.
          <br />
          <br />
          Il est donc préférable d’assurer votre véhicule dès que vous l’avez en votre possession.
        </div>
      }
      fieldName="vehiculeDejaAssureAvecNom"
      choices={vehicleInsuredByOptionsList}
      validate={vehicleIsCoveredValidator}
    />
  );
}
