import { VStack } from '@ornikar/kitt-universal';
import { type ReactNode } from 'react';
import { ContractRef } from './Components/ContractRef';
import { HamonWantCheckbox } from './Components/HamonWantCheckbox';
import { InsurerNameAutoComplete } from './Components/InsurerNameAutoComplete';

export interface AutoCompleteHamonFormProps {
  isLoading: boolean;
  choices: {
    label: string;
    value: string;
  }[];
  onAddInsurerPress: () => void;
  onHamonNameChange?: (value: string) => void;
  onHamonNameSelected?: (value: string | number) => void;
}

export function AutoCompleteHamonForm({
  isLoading,
  choices,
  onAddInsurerPress,
  onHamonNameChange,
  onHamonNameSelected,
}: AutoCompleteHamonFormProps): ReactNode {
  return (
    <VStack
      space="kitt.4"
      marginTop="kitt.8"
      padding="kitt.6"
      borderWidth={1}
      borderRadius="kitt.5"
      borderColor="kitt.palettes.lateOcean.black100"
      backgroundColor="kitt.white"
    >
      <InsurerNameAutoComplete
        isLoading={isLoading}
        choices={choices}
        onAddInsurerPress={onAddInsurerPress}
        onInputValueChange={onHamonNameChange}
        onChange={onHamonNameSelected}
      />
      <ContractRef />
      <HamonWantCheckbox />
    </VStack>
  );
}
