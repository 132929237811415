import { useCallback, useState } from 'react';
import type { ReactNode } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { AssureDepuisType } from '../../../apis/types/LongQuoteAnswers';
import { LoiHamonModal } from '../../../components/LoiHamonModal';
import { RadioScreenTemplate } from '../../../components/ScreenTemplates/RadioScreenTemplate';
import { vehicleInsuredSinceOptionsList } from '../../../constants/mappers/vehicle';
import { useVehicleCoveredSinceValidator } from '../../../forms/validation/sections/vehicle/vehicleCoveredSince';
import { delayPromise } from '../../../utils/promise';

export function VehicleCoveredSinceScreen(): ReactNode {
  const { values } = useFormState({
    subscription: {
      values: true,
    },
  });
  const [modalVisible, setModalVisible] = useState(false);
  const { submit } = useForm();
  const vehicleCoveredSinceValidator = useVehicleCoveredSinceValidator();

  const onSubmit = useCallback(() => {
    if (values.vehiculeAssureDepuis === AssureDepuisType.Moins12Mois) {
      setModalVisible(true);
    } else {
      delayPromise(submit);
    }
  }, [submit, values.vehiculeAssureDepuis]);

  return (
    <>
      <RadioScreenTemplate
        title="Depuis quand êtes-vous chez votre assureur actuel&nbsp;?"
        fieldName="vehiculeAssureDepuis"
        choices={vehicleInsuredSinceOptionsList}
        highlightTitle="Nous résilions pour vous"
        highlightContent={
          <div>
            <b>. Si votre contrat a plus d&apos;1 an</b> votre assurance actuelle sera résiliée par nos soins dès lors
            que vous souhaitez assurer le même véhicule.
            <br />
            En raison des démarches que nous mènerons auprès de votre assureur actuel, votre nouveau contrat prendra
            effet au bout d’un mois environ.
            <br />
            Mais pas de panique, vous restez assuré par votre ancien contrat pendant toute la durée des démarches.
            <br />
            <br />
            <b>. Si votre contrat a moins d&apos;1 an</b>, Nous pouvons vous afficher votre tarif sur-mesure, mais, dans
            certains cas, nous ne pourrons pas vous assurer avant que votre contrat actuel ne fête ses 1 an.
          </div>
        }
        validate={vehicleCoveredSinceValidator}
        onSubmit={onSubmit}
      />
      <LoiHamonModal visible={modalVisible} onClose={() => setModalVisible(false)} />
    </>
  );
}
