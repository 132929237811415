import { useContext } from 'react';
import type { ReactNode } from 'react';
import { ToastContext } from './ToastContext';
import styles from './styles.module.css';
import { useToast } from './useToast';
import { Toast } from '.';

export function ToastCenter(): ReactNode {
  const toast = useContext(ToastContext);
  const { closeToast } = useToast();

  return (
    <Toast
      message={toast?.message}
      type={toast?.type}
      isVisible={toast?.isVisible || false}
      className={styles.FloatingToast}
      onClose={closeToast}
    />
  );
}
