import { Checkbox, Typography } from '@ornikar/kitt-universal';
import { type ReactNode } from 'react';
import { Field } from '../../../../components/Field';
import { useHamonCheckboxValidator } from '../../../../forms/validation/sections/insurance/hamon';

export function HamonWantCheckbox(): ReactNode {
  const hamonCheckboxValidator = useHamonCheckboxValidator();
  return (
    <Field name="hamonWant" validate={hamonCheckboxValidator} type="checkbox" component={Checkbox}>
      <Typography.Text>
        Je confirme que mon contrat
        <Typography.Text variant="bold"> a plus d’un an et donne mandat à Ornikar </Typography.Text>
        pour sa résiliation.
      </Typography.Text>
    </Field>
  );
}
