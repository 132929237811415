import type { MachineOptions } from 'xstate';
import type { PostPaymentResult } from '../apis/payment';
import type { SaveQuoteResultOK } from '../apis/saveQuote';
import type { SubscribeResult } from '../apis/types';
import type { Package, PaymentPeriodicity } from '../apis/types/Formula';
import type { TarificationResult, TarificationResultKO, TarificationResultOK } from '../apis/types/Quotation';
import type { QuoteOptions } from '../apis/types/QuoteOptions';
import type { BrandName, Model, Version } from '../apis/vehicules';
import type { FormValues } from '../forms/answers';
import type { InsuranceRecordType, LongQuoteAnswers } from './answers';

export enum FsmErrorCode {
  NO_PROVIDER_AVAILABLE = 'NO_PROVIDER_AVAILABLE',
}

export enum DefaultAnswersEnum {
  VEHICULE_PUISSANCE_FISCALE = 'vehiculePuissanceFiscale',
  PRIMARY_DRIVER_LICENSE_DATE = 'primaryDriver.licenseDate',
  VEHICULE_MISE_EN_CIRCULATION = 'vehiculeMiseEnCirculation',
  VEHICULE_VERSION = 'vehiculeVersion',
  VEHICULE_IS_UTILITY = 'vehiculeIsUtility',
}

export interface Context {
  answers: Partial<LongQuoteAnswers>;
  defaultAnswers: DefaultAnswersEnum[];
  segmentAnonymousId?: string;
  segmentUserId?: string;
  isLoading: boolean;
  lastQuoteRequest?: Partial<LongQuoteAnswers>;

  availableBrands: BrandName[];
  availableModels: Model[];
  availableCarburants: string[];
  availablePuissanceFiscales: number[];
  availableVersions: Version[];

  hasReachedRestitution: boolean;
  isDisplayingFicNotification: boolean;
  formulePickedObject?: Package;
  optionsSelected?: string[];
  paymentPeriodicity?: PaymentPeriodicity;
  hasReceivedQuoteMailNotification: boolean;

  tarificationResult?: TarificationResult;
  devisCreationResult?: SaveQuoteResultOK;
  subscribeResult?: SubscribeResult;
  postPaymentResult?: PostPaymentResult;
  quoteOptions?: QuoteOptions;

  error?: FsmErrorCode;
  isFetchingLicensePlate?: boolean;

  reachedSummaryScreenSections: Partial<Record<Section, boolean>>;
  quoteId?: string;
  leadId?: string;
  agentEmail?: string;
  isFromAggregator?: boolean;

  /** RAG SPECIFICS */
  editingInsuranceRecord: {
    recordType?: InsuranceRecordType;
    driver?: Driver;
    index: number;
  };
}

export function isTarificationOK(
  tarification: TarificationResultOK | TarificationResultKO | undefined,
): tarification is TarificationResultOK {
  return tarification !== undefined && (tarification as TarificationResultKO).error === undefined;
}

export enum State {
  // MY VEHICLE SECTION
  VEHICULE_SEARCH_CHOICE = 'VEHICULE_SEARCH_CHOICE',
  VEHICULE_BRAND = 'VEHICULE_BRAND',
  VEHICULE_MODEL = 'VEHICULE_MODEL',
  VEHICULE_PUISSANCE_FISCALE = 'VEHICULE_PUISSANCE_FISCALE',
  VEHICULE_CARBURANT = 'VEHICULE_CARBURANT',
  VEHICULE_MISE_EN_CIRCULATION = 'VEHICULE_MISE_EN_CIRCULATION',
  VEHICULE_DEFAULT_MISE_EN_CIRCULATION = 'VEHICULE_DEFAULT_MISE_EN_CIRCULATION',
  VEHICULE_FETCH_VERSIONS = 'VEHICULE_FETCH_VERSIONS',
  VEHICULE_VERSION = 'VEHICULE_VERSION',
  VEHICULE_LICENSE_PLATE = 'VEHICULE_LICENSE_PLATE',
  VEHICULE_ACQUISITON_DATE = 'VEHICULE_ACQUISITON_DATE',
  VEHICULE_COVERING_DATE = 'VEHICULE_COVERING_DATE',
  VEHICULE_COVERED_SINCE = 'VEHICULE_COVERED_SINCE',
  VEHICLE_CURRENT_CONTRACT_ANNIVERSARY_MONTH = 'VEHICLE_CURRENT_CONTRACT_ANNIVERSARY_MONTH',
  VEHICLE_LAST_INSURANCE_END_DATE = 'VEHICLE_LAST_INSURANCE_END_DATE',
  VEHICLE_LAST_INSURANCE_END_DATE_INCONSISTENCY = 'VEHICLE_LAST_INSURANCE_END_DATE_INCONSISTENCY',
  VEHICULE_IS_COVERED = 'VEHICULE_IS_COVERED',
  VEHICULE_IS_COVERED_INCONSISTENCY = 'VEHICULE_IS_COVERED_INCONSISTENCY',
  VEHICULE_FUNDING = 'VEHICULE_FUNDING',
  VEHICLE_SUMMARY = 'VEHICLE_SUMMARY',
  // MY DRIVING SECTION
  DRIVING_PRIMARY_LICENSE_DATE = 'DRIVING_PRIMARY_LICENSE_DATE',
  DRIVING_PRIMARY_DEFAULT_LICENSE_DATE = 'DRIVING_PRIMARY_DEFAULT_LICENSE_DATE',
  DRIVING_PRIMARY_LICENSE_TYPE = 'DRIVING_PRIMARY_LICENSE_TYPE',
  DRIVING_PRIMARY_WAS_ACCOMPANIED = 'DRIVING_PRIMARY_WAS_ACCOMPANIED',
  DRIVING_VEHICULE_USAGE = 'DRIVING_VEHICULE_USAGE',
  DRIVING_KILOMETERS_PER_YEAR = 'DRIVING_KILOMETERS_PER_YEAR',
  DRIVING_VEHICULE_PARKING = 'DRIVING_VEHICULE_PARKING',
  DRIVING_CITY = 'DRIVING_CITY',
  DRIVING_HOUSING_TYPE = 'DRIVING_HOUSING_TYPE',
  DRIVING_IS_PROPERTY_OWNER = 'DRIVING_IS_PROPERTY_OWNER',
  DRIVING_REGISTRATION_DOCUMENT_OWNER_IS_SUBSCRIBER = 'DRIVING_REGISTRATION_DOCUMENT_OWNER_IS_SUBSCRIBER',
  DRIVING_REGISTRATION_DOCUMENT_OWNER = 'DRIVING_REGISTRATION_DOCUMENT_OWNER',
  DRIVING_SUMMARY = 'DRIVING_SUMMARY',
  // INFORMATION
  INFORMATION_PRIMARY_HAS_BEEN_COVERED = 'INFORMATION_PRIMARY_HAS_BEEN_COVERED',
  INFORMATION_PRIMARY_INSURANCE_PERIODS = 'INFORMATION_PRIMARY_INSURANCE_PERIODS',
  INFORMATION_PRIMARY_BONUS_MALUS = 'INFORMATION_PRIMARY_BONUS_MALUS',
  INFORMATION_PRIMARY_BONUS50 = 'INFORMATION_PRIMARY_BONUS50',

  /** RAG FLOW PRIMARY */

  // TERMINATION (INSURANCE CONTRACT)
  INFORMATION_PRIMARY_HAS_TERMINATION_RECORD = 'INFORMATION_PRIMARY_HAS_TERMINATION_RECORD',
  INFORMATION_PRIMARY_TERMINATION_RECORD_DATE = 'INFORMATION_PRIMARY_TERMINATION_RECORD_DATE',
  INFORMATION_PRIMARY_TERMINATION_RECORD_REASON = 'INFORMATION_PRIMARY_TERMINATION_RECORD_REASON',
  INFORMATION_PRIMARY_TERMINATION_RECORD_DURATION_WITHOUT_INSURANCE = 'INFORMATION_PRIMARY_TERMINATION_RECORD_DURATION_WITHOUT_INSURANCE',
  INFORMATION_PRIMARY_TERMINATION_RECORD_TERMINATION_COUNT_FOR_NON_PAYMENT = 'INFORMATION_PRIMARY_TERMINATION_RECORD_TERMINATION_COUNT_FOR_NON_PAYMENT',
  INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_DATE = 'INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_DATE',
  INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_SUSPENDED_DURATION = 'INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_SUSPENDED_DURATION',
  INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_IS_CLAIM = 'INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_IS_CLAIM',
  INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_REASON = 'INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_REASON',
  INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_ALCOHOL_LEVEL = 'INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_ALCOHOL_LEVEL',
  INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_HIT_AND_RUN = 'INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_HIT_AND_RUN',
  INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_DATE = 'INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_DATE',
  INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_RESPONSIBILITY_LEVEL = 'INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_RESPONSIBILITY_LEVEL',
  INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_WITH_ALCOHOL = 'INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_WITH_ALCOHOL',
  INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_ALCOHOL_LEVEL = 'INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_ALCOHOL_LEVEL',

  // CANCELLATIONS OR SUSPENSIONS (LICENSE)
  INFORMATION_PRIMARY_CANCELLATION_RECORD = 'INFORMATION_PRIMARY_CANCELLATION_RECORD',
  INFORMATION_PRIMARY_CANCELLATION_RECORD_DATE = 'INFORMATION_PRIMARY_CANCELLATION_RECORD_DATE',
  INFORMATION_PRIMARY_CANCELLATION_RECORD_SUSPENDED_DURATION = 'INFORMATION_PRIMARY_CANCELLATION_RECORD_SUSPENDED_DURATION',
  INFORMATION_PRIMARY_CANCELLATION_RECORD_IS_CLAIM = 'INFORMATION_PRIMARY_CANCELLATION_RECORD_IS_CLAIM',
  INFORMATION_PRIMARY_CANCELLATION_RECORD_REASON = 'INFORMATION_PRIMARY_CANCELLATION_RECORD_REASON',
  INFORMATION_PRIMARY_CANCELLATION_RECORD_ALCOHOL_LEVEL = 'INFORMATION_PRIMARY_CANCELLATION_RECORD_ALCOHOL_LEVEL',
  INFORMATION_PRIMARY_CANCELLATION_RECORD_HIT_AND_RUN = 'INFORMATION_PRIMARY_CANCELLATION_RECORD_HIT_AND_RUN',
  INFORMATION_PRIMARY_CANCELLATION_RECORD_CLAIM_DATE = 'INFORMATION_PRIMARY_CANCELLATION_RECORD_CLAIM_DATE',
  INFORMATION_PRIMARY_CANCELLATION_RECORD_CLAIM_RESPONSIBILITY_LEVEL = 'INFORMATION_PRIMARY_CANCELLATION_RECORD_CLAIM_RESPONSIBILITY_LEVEL',
  INFORMATION_PRIMARY_CANCELLATION_RECORD_CLAIM_WITH_ALCOHOL = 'INFORMATION_PRIMARY_CANCELLATION_RECORD_CLAIM_WITH_ALCOHOL',
  INFORMATION_PRIMARY_CANCELLATION_RECORD_CLAIM_ALCOHOL_LEVEL = 'INFORMATION_PRIMARY_CANCELLATION_RECORD_CLAIM_ALCOHOL_LEVEL',
  INFORMATION_PRIMARY_ADD_CANCELLATION_RECORD = 'INFORMATION_PRIMARY_ADD_CANCELLATION_RECORD',

  // CLAIMS
  INFORMATION_PRIMARY_CLAIM_RECORD_QUANTITY = 'INFORMATION_PRIMARY_CLAIM_RECORD_QUANTITY',
  INFORMATION_PRIMARY_CLAIM_RECORD_NATURE = 'INFORMATION_PRIMARY_CLAIM_RECORD_NATURE',
  INFORMATION_PRIMARY_CLAIM_RECORD_DATE = 'INFORMATION_PRIMARY_CLAIM_RECORD_DATE',
  INFORMATION_PRIMARY_CLAIM_RECORD_DRIVER = 'INFORMATION_PRIMARY_CLAIM_RECORD_DRIVER',
  INFORMATION_PRIMARY_CLAIM_RECORD_RESPONSIBILITY_LEVEL = 'INFORMATION_PRIMARY_CLAIM_RECORD_RESPONSIBILITY_LEVEL',
  INFORMATION_PRIMARY_CLAIM_RECORD_WITH_ALCOHOL = 'INFORMATION_PRIMARY_CLAIM_RECORD_WITH_ALCOHOL',
  INFORMATION_PRIMARY_CLAIM_RECORD_ALCOHOL_LEVEL = 'INFORMATION_PRIMARY_CLAIM_RECORD_ALCOHOL_LEVEL',
  INFORMATION_PRIMARY_ADD_CLAIM_RECORD = 'INFORMATION_PRIMARY_ADD_CLAIM_RECORD',

  // SUMMARY
  INFORMATION_PRIMARY_INSURANCE_RECORD_SUMMARY = 'INFORMATION_PRIMARY_INSURANCE_RECORD_SUMMARY',
  INFORMATION_PRIMARY_ADD_INSURANCE_RECORD = 'INFORMATION_PRIMARY_ADD_INSURANCE_RECORD',

  /** RAG FLOW PRIMARY END */

  INFORMATION_DECLARE_SECONDARY_DRIVER = 'INFORMATION_DECLARE_SECONDARY_DRIVER',
  INFORMATION_SECONDARY_DETAILS = 'INFORMATION_SECONDARY_DETAILS',
  INFORMATION_SECONDARY_LICENSE_DATE = 'INFORMATION_SECONDARY_LICENSE_DATE',
  INFORMATION_SECONDARY_LICENSE_TYPE = 'INFORMATION_SECONDARY_LICENSE_TYPE',
  INFORMATION_SECONDARY_WAS_ACCOMPANIED = 'INFORMATION_SECONDARY_WAS_ACCOMPANIED',
  INFORMATION_SECONDARY_RELATION = 'INFORMATION_SECONDARY_RELATION',
  INFORMATION_SECONDARY_HAS_BEEN_COVERED = 'INFORMATION_SECONDARY_HAS_BEEN_COVERED',
  INFORMATION_SECONDARY_INSURANCE_PERIODS = 'INFORMATION_SECONDARY_INSURANCE_PERIODS',
  INFORMATION_SECONDARY_BONUS_MALUS = 'INFORMATION_SECONDARY_BONUS_MALUS',
  INFORMATION_SECONDARY_BONUS50 = 'INFORMATION_SECONDARY_BONUS50',

  /** RAG FLOW SECONDARY */

  // TERMINATION (INSURANCE CONTRACT)
  INFORMATION_SECONDARY_HAS_TERMINATION_RECORD = 'INFORMATION_SECONDARY_HAS_TERMINATION_RECORD',
  INFORMATION_SECONDARY_TERMINATION_RECORD_DATE = 'INFORMATION_SECONDARY_TERMINATION_RECORD_DATE',
  INFORMATION_SECONDARY_TERMINATION_RECORD_REASON = 'INFORMATION_SECONDARY_TERMINATION_RECORD_REASON',
  INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_DATE = 'INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_DATE',
  INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_SUSPENDED_DURATION = 'INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_SUSPENDED_DURATION',
  INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_IS_CLAIM = 'INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_IS_CLAIM',
  INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_REASON = 'INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_REASON',
  INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_ALCOHOL_LEVEL = 'INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_ALCOHOL_LEVEL',
  INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_HIT_AND_RUN = 'INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_HIT_AND_RUN',
  INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_DATE = 'INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_DATE',
  INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_RESPONSIBILITY_LEVEL = 'INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_RESPONSIBILITY_LEVEL',
  INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_WITH_ALCOHOL = 'INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_WITH_ALCOHOL',
  INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_ALCOHOL_LEVEL = 'INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_ALCOHOL_LEVEL',

  // CANCELLATIONS OR SUSPENSIONS (LICENSE)
  INFORMATION_SECONDARY_CANCELLATION_RECORD = 'INFORMATION_SECONDARY_CANCELLATION_RECORD',
  INFORMATION_SECONDARY_CANCELLATION_RECORD_DATE = 'INFORMATION_SECONDARY_CANCELLATION_RECORD_DATE',
  INFORMATION_SECONDARY_CANCELLATION_RECORD_SUSPENDED_DURATION = 'INFORMATION_SECONDARY_CANCELLATION_RECORD_SUSPENDED_DURATION',
  INFORMATION_SECONDARY_CANCELLATION_RECORD_IS_CLAIM = 'INFORMATION_SECONDARY_CANCELLATION_RECORD_IS_CLAIM',
  INFORMATION_SECONDARY_CANCELLATION_RECORD_REASON = 'INFORMATION_SECONDARY_CANCELLATION_RECORD_REASON',
  INFORMATION_SECONDARY_CANCELLATION_RECORD_ALCOHOL_LEVEL = 'INFORMATION_SECONDARY_CANCELLATION_RECORD_ALCOHOL_LEVEL',
  INFORMATION_SECONDARY_CANCELLATION_RECORD_HIT_AND_RUN = 'INFORMATION_SECONDARY_CANCELLATION_RECORD_HIT_AND_RUN',
  INFORMATION_SECONDARY_CANCELLATION_RECORD_CLAIM_DATE = 'INFORMATION_SECONDARY_CANCELLATION_RECORD_CLAIM_DATE',
  INFORMATION_SECONDARY_CANCELLATION_RECORD_CLAIM_RESPONSIBILITY_LEVEL = 'INFORMATION_SECONDARY_CANCELLATION_RECORD_CLAIM_RESPONSIBILITY_LEVEL',
  INFORMATION_SECONDARY_CANCELLATION_RECORD_CLAIM_WITH_ALCOHOL = 'INFORMATION_SECONDARY_CANCELLATION_RECORD_CLAIM_WITH_ALCOHOL',
  INFORMATION_SECONDARY_CANCELLATION_RECORD_CLAIM_ALCOHOL_LEVEL = 'INFORMATION_SECONDARY_CANCELLATION_RECORD_CLAIM_ALCOHOL_LEVEL',
  INFORMATION_SECONDARY_ADD_CANCELLATION_RECORD = 'INFORMATION_SECONDARY_ADD_CANCELLATION_RECORD',

  // CLAIMS
  INFORMATION_SECONDARY_CLAIM_RECORD_QUANTITY = 'INFORMATION_SECONDARY_CLAIM_RECORD_QUANTITY',
  INFORMATION_SECONDARY_CLAIM_RECORD_NATURE = 'INFORMATION_SECONDARY_CLAIM_RECORD_NATURE',
  INFORMATION_SECONDARY_CLAIM_RECORD_DATE = 'INFORMATION_SECONDARY_CLAIM_RECORD_DATE',
  INFORMATION_SECONDARY_CLAIM_RECORD_DRIVER = 'INFORMATION_SECONDARY_CLAIM_RECORD_DRIVER',
  INFORMATION_SECONDARY_CLAIM_RECORD_RESPONSIBILITY_LEVEL = 'INFORMATION_SECONDARY_CLAIM_RECORD_RESPONSIBILITY_LEVEL',
  INFORMATION_SECONDARY_CLAIM_RECORD_WITH_ALCOHOL = 'INFORMATION_SECONDARY_CLAIM_RECORD_WITH_ALCOHOL',
  INFORMATION_SECONDARY_CLAIM_RECORD_ALCOHOL_LEVEL = 'INFORMATION_SECONDARY_CLAIM_RECORD_ALCOHOL_LEVEL',
  INFORMATION_SECONDARY_ADD_CLAIM_RECORD = 'INFORMATION_SECONDARY_ADD_CLAIM_RECORD',

  // SUMMARY
  INFORMATION_SECONDARY_INSURANCE_RECORD_SUMMARY = 'INFORMATION_SECONDARY_INSURANCE_RECORD_SUMMARY',
  INFORMATION_SECONDARY_ADD_INSURANCE_RECORD = 'INFORMATION_SECONDARY_ADD_INSURANCE_RECORD',

  /** RAG FLOW SECONDARY END */

  // RESTITUTITON
  INFORMATION_PRIMARY_DETAILS = 'INFORMATION_PRIMARY_DETAILS',
  // QUOTE SECTION
  NEEDS_SALES_VALIDATION = 'NEEDS_SALES_VALIDATION',
  CANNOT_QUOTE = 'CANNOT_QUOTE',
  CANNOT_RETRIEVE_QUOTE = 'CANNOT_RETRIEVE_QUOTE',
  INSURED_SINCE_LESS_THAN_A_YEAR = 'INSURED_SINCE_LESS_THAN_A_YEAR',
  REVERSE_SUBSCRIPTION = 'REVERSE_SUBSCRIPTION',
  // Between QUOTE and LICENSE_PLATE
  FORMULES = 'FORMULES',

  /** -- POST QUOTATION -- Start */

  /** -- ADDON -- Start */
  OPTIONS_INTRODUCTION = 'OPTIONS_INTRODUCTION',
  ADDON_0KM_ASSISTANCE = 'ADDON_0KM_ASSISTANCE',
  ADDON_DRIVER_PROTECTION_500 = 'ADDON_DRIVER_PROTECTION_500',
  ADDON_VEHICLE_LOAN = 'ADDON_VEHICLE_LOAN',
  DEDUCTIBLES_SELECTION = 'DEDUCTIBLES_SELECTION',
  SENDING_SELECTED_OPTIONS_AND_CLEARING_PROMO_CODE = 'SENDING_SELECTED_OPTIONS_AND_CLEARING_PROMO_CODE',
  /** -- ADDON -- End */

  SHOPPING_CART = 'SHOPPING_CART',

  POST_FORMULE_DISPATCH = 'POST_FORMULE_DISPATCH',
  HAMON_LAW_INFORMATION = 'HAMON_LAW_INFORMATION',
  VEHICLE_LEGAL_INFORMATION = 'VEHICLE_LEGAL_INFORMATION',
  LRE_INFORMATION = 'LRE_INFORMATION',
  REGISTER_IBAN = 'REGISTER_IBAN',
  CONFIRM_SEPA_MANDATE = 'CONFIRM_SEPA_MANDATE',
  CREDIT_CARD_PAYMENT = 'CREDIT_CARD_PAYMENT',
  /** -- POST QUOTATION -- End */

  // CONFIRMATION SECTION
  WEBAPP_INTRODUCTION = 'WEBAPP_INTRODUCTION',

  // The _END sections are used to redirect to restitution
  // after each block if the user comes from restitution

  // Dispatch
  // DISPATCH_REUSE = 'DISPATCH_REUSE',
  TARIFICATION_LOADING = 'TARIFICATION_LOADING',
}

export interface Schema {
  states: Record<State, Record<string, unknown>>;
}

export enum RestitutionEditionTargetSections {
  VEHICULE_INFOS = 'VEHICULE_INFOS',
  VEHICULE_USAGE = 'VEHICULE_USAGE',
  VEHICULE_LOCATION = 'VEHICULE_LOCATION',
  BONUS_MALUS = 'BONUS_MALUS',
  SECONDARY_DRIVER = 'SECONDARY_DRIVER',
}

export type RestitutionEditionEventType = `RESTITUTION_EDIT_${RestitutionEditionTargetSections}`;

export enum Event {
  NAVIGATE_TO_DRIVING = 'NAVIGATE_TO_DRIVING',
  NAVIGATE_TO_VEHICULE = 'NAVIGATE_TO_VEHICULE',
  NAVIGATE_TO_PRIMARY_DRIVER_SUMMARY = 'NAVIGATE_TO_PRIMARY_DRIVER_SUMMARY',
  NAVIGATE_TO_SECONDARY_DRIVER_SUMMARY = 'NAVIGATE_TO_SECONDARY_DRIVER_SUMMARY',
  NAVIGATE_TO_INFORMATION = 'NAVIGATE_TO_INFORMATION',
  NAVIGATE_TO_TARIFICATION = 'NAVIGATE_TO_TARIFICATION',
  BACK = 'BACK',
  CONTINUE = 'CONTINUE',
  SKIP = 'SKIP',
  RESTITUTION_EDIT_VEHICULE_INFOS = 'RESTITUTION_EDIT_VEHICULE_INFOS',
  RESTITUTION_EDIT_VEHICULE_USAGE = 'RESTITUTION_EDIT_VEHICULE_USAGE',
  RESTITUTION_EDIT_VEHICULE_LOCATION = 'RESTITUTION_EDIT_VEHICULE_LOCATION',
  RESTITUTION_EDIT_BONUS_MALUS = 'RESTITUTION_EDIT_BONUS_MALUS',
  RESTITUTION_EDIT_SECONDARY_DRIVER = 'RESTITUTION_EDIT_SECONDARY_DRIVER',
  RESTITUTION_DELETE_SECONDARY_DRIVER = 'RESTITUTION_DELETE_SECONDARY_DRIVER',
  FORMULE_SELECTED = 'FORMULE_SELECTED',
  ANSWER = 'ANSWER',
  ANSWER_WITHOUT_CONTINUING = 'ANSWER_WITHOUT_CONTINUING',
  DEFAULT_ANSWER = 'DEFAULT_ANSWER',
  ADD_CLAIM = 'ADD_CLAIM',
  ADD_SUSPENSION = 'ADD_SUSPENSION',
  ADD_CANCELLATION = 'ADD_CANCELLATION',
  START_ADDING_INSURANCE_RECORD = 'START_ADDING_INSURANCE_RECORD',
  EDIT_INSURANCE_RECORD = 'EDIT_INSURANCE_RECORD',
  DELETE_INSURANCE_RECORD = 'DELETE_INSURANCE_RECORD',
  PAYMENT_ERROR = 'PAYMENT_ERROR',
  PAYMENT_SUCCESS = 'PAYMENT_SUCCESS',
  QUOTE_MAIL_NOTIFICATION_RECEIVED = 'QUOTE_MAIL_NOTIFICATION_RECEIVED',
  REDIRECT = 'REDIRECT',
  INSURANCE_DATE_INCONSISTENCY = 'INSURANCE_DATE_INCONSISTENCY',
  SHOPPING_CART_ERROR = 'SHOPPING_CART_ERROR',
  RETURN_TO_SCREEN = 'RETURN_TO_SCREEN',
}

export interface SimpleEvent<E extends Event> {
  type: E;
}

export interface ErrorEvent<E extends Event> extends SimpleEvent<E> {
  data: Error;
}

export interface AnswerEvent {
  type: Event.ANSWER | Event.ANSWER_WITHOUT_CONTINUING | Event.DEFAULT_ANSWER;
  answers: Partial<LongQuoteAnswers & FormValues>;
}

export interface FormuleSelectedEvent {
  type: Event.FORMULE_SELECTED;
  answers: Partial<LongQuoteAnswers>;
  results: SaveQuoteResultOK;
  options: QuoteOptions;
  paymentPeriodicity: PaymentPeriodicity;
}

export interface ReturnEvent {
  type: Event.RETURN_TO_SCREEN;
  destination: State;
}

export enum Section {
  VEHICULE = 'VEHICULE', // All vehicule info
  DRIVING = 'DRIVING', // How vehicule will be used (usage, parking)
  PRIMARY_DRIVER = 'PRIMARY_DRIVER', // Primary driver info
  SECONDARY_DRIVER = 'SECONDARY_DRIVER', // Secondary driver info
  INFORMATION = 'INFORMATION', //
  TARIFICATION = 'TARIFICATION', // Who subscribes to the policy, restitution & formule page
}

export type NavEventType =
  | Event.NAVIGATE_TO_VEHICULE
  | Event.NAVIGATE_TO_DRIVING
  | Event.NAVIGATE_TO_PRIMARY_DRIVER_SUMMARY
  | Event.NAVIGATE_TO_SECONDARY_DRIVER_SUMMARY
  | Event.NAVIGATE_TO_INFORMATION
  | Event.NAVIGATE_TO_TARIFICATION;

export interface EditInsuranceRecordEvent {
  type: Event.EDIT_INSURANCE_RECORD;
  recordType: string;
  recordIndex: number;
}

export interface DeleteInsuranceRecordEvent {
  type: Event.DELETE_INSURANCE_RECORD;
  recordType: string;
  recordIndex: number;
}
export interface PaymentEvent {
  type: Event.PAYMENT_ERROR | Event.PAYMENT_SUCCESS;
  conversionValue: string;
}

export type Events =
  | { type: NavEventType }
  | SimpleEvent<Event.BACK>
  | SimpleEvent<Event.CONTINUE>
  | SimpleEvent<Event.SKIP>
  | { type: RestitutionEditionEventType }
  | SimpleEvent<Event.RESTITUTION_DELETE_SECONDARY_DRIVER>
  | FormuleSelectedEvent
  | AnswerEvent
  | SimpleEvent<Event.REDIRECT>
  | ReturnEvent
  /** RAG start */
  | SimpleEvent<Event.ADD_CLAIM>
  | SimpleEvent<Event.ADD_SUSPENSION>
  | SimpleEvent<Event.ADD_CANCELLATION>
  | SimpleEvent<Event.START_ADDING_INSURANCE_RECORD>
  | EditInsuranceRecordEvent
  | DeleteInsuranceRecordEvent
  /** RAG end */
  | PaymentEvent
  | SimpleEvent<Event.QUOTE_MAIL_NOTIFICATION_RECEIVED>
  | SimpleEvent<Event.INSURANCE_DATE_INCONSISTENCY>
  | ErrorEvent<Event.SHOPPING_CART_ERROR>;

export const screensBySection = Object.freeze({
  [Section.VEHICULE]: [
    State.VEHICULE_SEARCH_CHOICE,
    State.VEHICULE_BRAND,
    State.VEHICULE_MODEL,
    State.VEHICULE_PUISSANCE_FISCALE,
    State.VEHICULE_CARBURANT,
    State.VEHICULE_MISE_EN_CIRCULATION,
    State.VEHICULE_DEFAULT_MISE_EN_CIRCULATION,
    State.VEHICULE_FETCH_VERSIONS,
    State.VEHICULE_VERSION,
    State.VEHICULE_LICENSE_PLATE,
    State.VEHICULE_ACQUISITON_DATE,
    State.VEHICULE_COVERING_DATE,
    State.VEHICULE_IS_COVERED,
    State.VEHICULE_COVERED_SINCE,
    State.VEHICLE_CURRENT_CONTRACT_ANNIVERSARY_MONTH,
    State.VEHICLE_LAST_INSURANCE_END_DATE,
    State.VEHICULE_FUNDING,
    State.VEHICLE_SUMMARY,
  ],
  [Section.DRIVING]: [
    State.DRIVING_PRIMARY_LICENSE_DATE,
    State.DRIVING_PRIMARY_DEFAULT_LICENSE_DATE,
    State.DRIVING_PRIMARY_LICENSE_TYPE,
    State.DRIVING_PRIMARY_WAS_ACCOMPANIED,
    State.DRIVING_VEHICULE_USAGE,
    State.DRIVING_KILOMETERS_PER_YEAR,
    State.DRIVING_VEHICULE_PARKING,
    State.DRIVING_CITY,
    State.DRIVING_HOUSING_TYPE,
    State.DRIVING_IS_PROPERTY_OWNER,
    State.DRIVING_REGISTRATION_DOCUMENT_OWNER_IS_SUBSCRIBER,
    State.DRIVING_REGISTRATION_DOCUMENT_OWNER,
    State.DRIVING_SUMMARY,
  ],
  [Section.PRIMARY_DRIVER]: [
    // Primary Driver
    State.INFORMATION_PRIMARY_HAS_BEEN_COVERED,
    State.INFORMATION_PRIMARY_INSURANCE_PERIODS,
    State.INFORMATION_PRIMARY_BONUS_MALUS,
    State.INFORMATION_PRIMARY_BONUS50,
    State.INFORMATION_PRIMARY_HAS_TERMINATION_RECORD,
    State.INFORMATION_PRIMARY_TERMINATION_RECORD_DATE,
    State.INFORMATION_PRIMARY_TERMINATION_RECORD_REASON,
    State.INFORMATION_PRIMARY_TERMINATION_RECORD_DURATION_WITHOUT_INSURANCE,
    State.INFORMATION_PRIMARY_TERMINATION_RECORD_TERMINATION_COUNT_FOR_NON_PAYMENT,
    State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_SUSPENDED_DURATION,
    State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_IS_CLAIM,
    State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_REASON,
    State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_ALCOHOL_LEVEL,
    State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_HIT_AND_RUN,
    State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_DATE,
    State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_RESPONSIBILITY_LEVEL,
    State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_WITH_ALCOHOL,
    State.INFORMATION_PRIMARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_ALCOHOL_LEVEL,
    State.INFORMATION_PRIMARY_CANCELLATION_RECORD,
    State.INFORMATION_PRIMARY_CANCELLATION_RECORD_SUSPENDED_DURATION,
    State.INFORMATION_PRIMARY_CANCELLATION_RECORD_IS_CLAIM,
    State.INFORMATION_PRIMARY_CANCELLATION_RECORD_REASON,
    State.INFORMATION_PRIMARY_CANCELLATION_RECORD_ALCOHOL_LEVEL,
    State.INFORMATION_PRIMARY_CANCELLATION_RECORD_HIT_AND_RUN,
    State.INFORMATION_PRIMARY_CANCELLATION_RECORD_CLAIM_DATE,
    State.INFORMATION_PRIMARY_CANCELLATION_RECORD_CLAIM_RESPONSIBILITY_LEVEL,
    State.INFORMATION_PRIMARY_CANCELLATION_RECORD_CLAIM_WITH_ALCOHOL,
    State.INFORMATION_PRIMARY_CANCELLATION_RECORD_CLAIM_ALCOHOL_LEVEL,
    State.INFORMATION_PRIMARY_ADD_CANCELLATION_RECORD,
    State.INFORMATION_PRIMARY_CLAIM_RECORD_QUANTITY,
    State.INFORMATION_PRIMARY_CLAIM_RECORD_NATURE,
    State.INFORMATION_PRIMARY_CLAIM_RECORD_DATE,
    State.INFORMATION_PRIMARY_CLAIM_RECORD_DRIVER,
    State.INFORMATION_PRIMARY_CLAIM_RECORD_RESPONSIBILITY_LEVEL,
    State.INFORMATION_PRIMARY_CLAIM_RECORD_WITH_ALCOHOL,
    State.INFORMATION_PRIMARY_CLAIM_RECORD_ALCOHOL_LEVEL,
    State.INFORMATION_PRIMARY_ADD_CLAIM_RECORD,
    State.INFORMATION_PRIMARY_INSURANCE_RECORD_SUMMARY,
    State.INFORMATION_PRIMARY_ADD_INSURANCE_RECORD,
  ],
  [Section.SECONDARY_DRIVER]: [
    // Secondary Driver
    State.INFORMATION_DECLARE_SECONDARY_DRIVER,
    State.INFORMATION_SECONDARY_DETAILS,
    State.INFORMATION_SECONDARY_LICENSE_DATE,
    State.INFORMATION_SECONDARY_LICENSE_TYPE,
    State.INFORMATION_SECONDARY_WAS_ACCOMPANIED,
    State.INFORMATION_SECONDARY_RELATION,
    State.INFORMATION_SECONDARY_HAS_BEEN_COVERED,
    State.INFORMATION_SECONDARY_INSURANCE_PERIODS,
    State.INFORMATION_SECONDARY_BONUS_MALUS,
    State.INFORMATION_SECONDARY_BONUS50,
    State.INFORMATION_SECONDARY_HAS_TERMINATION_RECORD,
    State.INFORMATION_SECONDARY_TERMINATION_RECORD_DATE,
    State.INFORMATION_SECONDARY_TERMINATION_RECORD_REASON,
    State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_SUSPENDED_DURATION,
    State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_IS_CLAIM,
    State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_REASON,
    State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_ALCOHOL_LEVEL,
    State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_HIT_AND_RUN,
    State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_DATE,
    State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_RESPONSIBILITY_LEVEL,
    State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_WITH_ALCOHOL,
    State.INFORMATION_SECONDARY_TERMINATION_RECORD_LICENSE_CANCELED_OR_SUSPENDED_CLAIM_ALCOHOL_LEVEL,
    State.INFORMATION_SECONDARY_CANCELLATION_RECORD,
    State.INFORMATION_SECONDARY_CANCELLATION_RECORD_SUSPENDED_DURATION,
    State.INFORMATION_SECONDARY_CANCELLATION_RECORD_IS_CLAIM,
    State.INFORMATION_SECONDARY_CANCELLATION_RECORD_REASON,
    State.INFORMATION_SECONDARY_CANCELLATION_RECORD_ALCOHOL_LEVEL,
    State.INFORMATION_SECONDARY_CANCELLATION_RECORD_HIT_AND_RUN,
    State.INFORMATION_SECONDARY_CANCELLATION_RECORD_CLAIM_DATE,
    State.INFORMATION_SECONDARY_CANCELLATION_RECORD_CLAIM_RESPONSIBILITY_LEVEL,
    State.INFORMATION_SECONDARY_CANCELLATION_RECORD_CLAIM_WITH_ALCOHOL,
    State.INFORMATION_SECONDARY_CANCELLATION_RECORD_CLAIM_ALCOHOL_LEVEL,
    State.INFORMATION_SECONDARY_ADD_CANCELLATION_RECORD,
    State.INFORMATION_SECONDARY_CLAIM_RECORD_QUANTITY,
    State.INFORMATION_SECONDARY_CLAIM_RECORD_NATURE,
    State.INFORMATION_SECONDARY_CLAIM_RECORD_DATE,
    State.INFORMATION_SECONDARY_CLAIM_RECORD_DRIVER,
    State.INFORMATION_SECONDARY_CLAIM_RECORD_RESPONSIBILITY_LEVEL,
    State.INFORMATION_SECONDARY_CLAIM_RECORD_WITH_ALCOHOL,
    State.INFORMATION_SECONDARY_CLAIM_RECORD_ALCOHOL_LEVEL,
    State.INFORMATION_SECONDARY_ADD_CLAIM_RECORD,
    State.INFORMATION_SECONDARY_INSURANCE_RECORD_SUMMARY,
    State.INFORMATION_SECONDARY_ADD_INSURANCE_RECORD,
  ],
  [Section.INFORMATION]: [
    State.INFORMATION_PRIMARY_DETAILS,
    State.CANNOT_QUOTE,
    State.INSURED_SINCE_LESS_THAN_A_YEAR,
    State.REVERSE_SUBSCRIPTION,
  ],
  [Section.TARIFICATION]: [
    State.TARIFICATION_LOADING,
    State.FORMULES,
    State.NEEDS_SALES_VALIDATION,
    State.CANNOT_RETRIEVE_QUOTE,
    State.DEDUCTIBLES_SELECTION,
    State.ADDON_0KM_ASSISTANCE,
    State.ADDON_DRIVER_PROTECTION_500,
    State.ADDON_VEHICLE_LOAN,
    State.OPTIONS_INTRODUCTION,
    State.LRE_INFORMATION,
    State.REGISTER_IBAN,
    State.POST_FORMULE_DISPATCH,
    State.HAMON_LAW_INFORMATION,
    State.VEHICLE_LEGAL_INFORMATION,
    State.REGISTER_IBAN,
    State.CONFIRM_SEPA_MANDATE,
    State.CREDIT_CARD_PAYMENT,
    State.WEBAPP_INTRODUCTION,
    State.SHOPPING_CART,
  ],
});

export const MAPPED_STATES_TO_SECTIONS: Partial<Record<keyof typeof State, Section>> = {};
Object.entries(screensBySection).forEach(([section, states]) => {
  states.forEach((state: State) => {
    MAPPED_STATES_TO_SECTIONS[state] = section as Section;
  });
});

export const SUMMARY_SCREENS: State[] = [
  State.VEHICLE_SUMMARY,
  State.DRIVING_SUMMARY,
  State.INFORMATION_PRIMARY_INSURANCE_RECORD_SUMMARY,
  State.INFORMATION_SECONDARY_INSURANCE_RECORD_SUMMARY,
];

export const ScreenTitles: Record<keyof typeof Section, string> = Object.freeze({
  [Section.VEHICULE]: 'Mon véhicule',
  [Section.DRIVING]: 'Ma conduite',
  [Section.PRIMARY_DRIVER]: 'Mon historique',
  [Section.SECONDARY_DRIVER]: 'Mon second conducteur',
  [Section.INFORMATION]: 'Mes informations',
  [Section.TARIFICATION]: 'Mon tarif',
});

export type InsuranceFsmConfig = Pick<MachineOptions<Context, Events>, 'actions' | 'guards'>;

export const STATE_MACHINE_CONFIG_KEY = 'STATE_MACHINE_CONFIG';

export interface StateMachineConfig {
  id: string;
  context: Context;
  stateValue: State;
}

export enum Driver {
  Primary = 'primary',
  Secondary = 'secondary',
}

export type AttributType = 'il' | 'elle';
