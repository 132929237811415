import { api } from './api';
import type { SetEffectiveDateResult } from './types/EffectiveDate';

export async function setEffectiveDate(quoteId: string | number, effectiveDate: string): Promise<boolean> {
  const setEffectiveDateResult = await api<SetEffectiveDateResult>(`api/v1/quotes/${quoteId}/effective-date`, {
    method: 'POST',
    json: {
      effectiveDate,
    },
  });

  return setEffectiveDateResult.isValid;
}
