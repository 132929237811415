import type {
  CancellationOrSuspensionRecord,
  ClaimRecord,
  DriverInsuranceHistory,
  TerminationRecord,
} from '../../fsm/answers';
import {
  AccidentType,
  CancellationOrSuspensionReason,
  ClaimNature,
  ClaimQuantity,
  DriverType,
  InsuranceRecordType,
  ResponsibilityLevel,
  UnderTheInfluenceOf,
  WithdrawalType,
  YesNoQuestion,
} from '../../fsm/answers';
import type {
  AbstractCancellationOrSuspension,
  BasicClaim,
  ClaimWithAccidentWithClaimantAsDriver,
  ServerCancellationOrSuspensionRecord,
  ServerClaimRecord,
  ServerInsuranceRecord,
  ServerTerminationRecord,
} from '../types/InsuranceRecord';
import { ServerInsuranceRecordType } from '../types/InsuranceRecord';

export function mapClaimRecordToServerRequest(record: ClaimRecord): ServerClaimRecord {
  const baseClaim: BasicClaim = {
    recordType: ServerInsuranceRecordType.Claim,
    date: record.date,
    type: record.nature,
  };

  if (![ClaimNature.BodilyAccident, ClaimNature.MaterialAccident].includes(record.nature)) {
    return baseClaim;
  }

  if (record.driver === DriverType.Myself) {
    return {
      ...baseClaim,
      claimantAsDriver: true,
      responsibilityLevel: record.responsibilityLevel,
      underTheInfluenceOf: record.underTheInfluenceOf,
      bloodAlcoholLevel: record.bloodAlcoholLevel,
    };
  }

  return {
    ...baseClaim,
    claimantAsDriver: false,
    responsibilityLevel: record.responsibilityLevel,
  };
}

export function mapCancellationOrSuspensionRecordToServerRequest(
  record: CancellationOrSuspensionRecord,
): ServerCancellationOrSuspensionRecord {
  const baseCancellationOrSuspension: AbstractCancellationOrSuspension = {
    recordType: ServerInsuranceRecordType.CancellationOrSuspension,
    date: record.date,
    isCancellation: record.withdrawalType === WithdrawalType.Cancellation,
    suspensionDuration: record.suspensionDuration,
    hitAndRunOrRefusalToComply: record.isHitAndRun === YesNoQuestion.YES,
  };

  if (record.accident !== AccidentType.None) {
    return {
      ...baseCancellationOrSuspension,
      isDueToAccident: true,
      claim: mapClaimRecordToServerRequest({
        ...record.claimRecord!,
        // The nature of a claim responsible to a cancellation / suspension
        // can only be Bodily or Material Accident.
        // It's asked on the "accident" screen
        nature: ClaimNature[record.accident!],
        // A claim responsible to a cancellation / suspension
        // can't be done by another driver
        driver: DriverType.Myself,
      }) as ClaimWithAccidentWithClaimantAsDriver,
    };
  }

  return {
    ...baseCancellationOrSuspension,
    isDueToAccident: false,
    reason: record.reason,
    bloodAlcoholLevel: record.bloodAlcoholLevel,
  };
}

export function mapTerminationRecordToServerRequest(record: TerminationRecord): ServerTerminationRecord {
  return {
    reason: record.reason,
    recordType: ServerInsuranceRecordType.Termination,
    date: record.date,
    uninsuredVehicleOwnershipDuration: record.duration,
    cancellationOrSuspension: record.cancellationOrSuspensionRecord
      ? mapCancellationOrSuspensionRecordToServerRequest(record.cancellationOrSuspensionRecord)
      : undefined,
    nonPaymentTerminationInsurerCount: record.terminationCount,
  };
}

export function mapInsuranceRecordsToServerRequest(
  terminationRecords: TerminationRecord[] | undefined,
  cancellationOrSuspensionRecords: CancellationOrSuspensionRecord[] | undefined,
  claimRecords: ClaimRecord[] | undefined,
): ServerInsuranceRecord[] {
  const serverTerminationRecords = terminationRecords
    ? terminationRecords.map(mapTerminationRecordToServerRequest)
    : [];

  const serverCancellationOrSuspensionRecords = cancellationOrSuspensionRecords
    ? cancellationOrSuspensionRecords.map(mapCancellationOrSuspensionRecordToServerRequest)
    : [];

  const serverClaimRecords = claimRecords ? claimRecords.map(mapClaimRecordToServerRequest) : [];

  return [...serverTerminationRecords, ...serverCancellationOrSuspensionRecords, ...serverClaimRecords];
}

export function mapServerRequestToCancellationOrSuspensionRecord(
  record: ServerCancellationOrSuspensionRecord,
): CancellationOrSuspensionRecord {
  const getClaimRecordType = (type: ClaimNature): AccidentType => {
    if (Object.values(AccidentType).includes(type as unknown as AccidentType)) {
      return type as unknown as AccidentType;
    }

    return AccidentType.None;
  };

  const getReason = (underTheInfluenceOf: UnderTheInfluenceOf): CancellationOrSuspensionReason => {
    const reasonMapper = {
      [UnderTheInfluenceOf.None]: CancellationOrSuspensionReason.Other,
      [UnderTheInfluenceOf.Alcohol]: CancellationOrSuspensionReason.DrivingUnderInfluenceOfAlcohol,
      [UnderTheInfluenceOf.Drugs]: CancellationOrSuspensionReason.DrivingUnderInfluenceOfDrugs,
      [UnderTheInfluenceOf.AlcoholAndDrugs]: CancellationOrSuspensionReason.DrivingUnderInfluenceOfAlcoholAndDrugs,
    };

    return reasonMapper[underTheInfluenceOf];
  };

  const baseCancellationOrSuspension = {
    withdrawalType: record.isCancellation ? WithdrawalType.Cancellation : WithdrawalType.Suspension,
    date: record.date,
    suspensionDuration: record.suspensionDuration,
    isHitAndRun: record.hitAndRunOrRefusalToComply ? YesNoQuestion.YES : YesNoQuestion.NO,
  };

  if (record.isDueToAccident) {
    const claimRecord: ClaimRecord = {
      type: InsuranceRecordType.Claim,
      nature: record.claim.type,
      date: record.claim.date,
      driver: record.claim.claimantAsDriver ? DriverType.Myself : DriverType.AnotherDriver,
      responsibilityLevel: record.claim.responsibilityLevel,
      underTheInfluenceOf: record.claim.underTheInfluenceOf,
      bloodAlcoholLevel: record.claim.bloodAlcoholLevel,
    };

    return {
      ...baseCancellationOrSuspension,
      type: InsuranceRecordType.CancellationOrSuspension,
      accident: getClaimRecordType(record.claim.type),
      reason: getReason(record.claim.underTheInfluenceOf),
      claimRecord,
    };
  }

  return {
    ...baseCancellationOrSuspension,
    type: InsuranceRecordType.CancellationOrSuspension,
    reason: record.reason,
    bloodAlcoholLevel: record.bloodAlcoholLevel,
  };
}

export function mapServerRequestToClaimRecord(record: ServerClaimRecord): ClaimRecord {
  return {
    type: InsuranceRecordType.Claim,
    nature: record.type,
    date: record.date,
    driver:
      'claimantAsDriver' in record && record.claimantAsDriver === false ? DriverType.AnotherDriver : DriverType.Myself,
    responsibilityLevel:
      'responsibilityLevel' in record ? record.responsibilityLevel : ResponsibilityLevel.NonResponsible,
    underTheInfluenceOf: 'underTheInfluenceOf' in record ? record.underTheInfluenceOf : UnderTheInfluenceOf.None,
    bloodAlcoholLevel: 'bloodAlcoholLevel' in record ? record.bloodAlcoholLevel : undefined,
  };
}

export function mapServerRequestToTerminationRecord(record: ServerTerminationRecord): TerminationRecord {
  return {
    type: InsuranceRecordType.Termination,
    date: record.date,
    reason: record.reason,
    duration: record.uninsuredVehicleOwnershipDuration,
    cancellationOrSuspensionRecord: record.cancellationOrSuspension
      ? mapServerRequestToCancellationOrSuspensionRecord(record.cancellationOrSuspension)
      : undefined,
  };
}

export function mapServerRequestToInsuranceRecords(
  serverInsuranceRecords: ServerInsuranceRecord[],
): DriverInsuranceHistory {
  const cancellationOrSuspensionRecords = serverInsuranceRecords
    .filter(({ recordType }) => recordType === ServerInsuranceRecordType.CancellationOrSuspension)
    .map((record) => mapServerRequestToCancellationOrSuspensionRecord(record as ServerCancellationOrSuspensionRecord));

  const hasBeenCancelledOrSuspended = cancellationOrSuspensionRecords?.[0]?.withdrawalType ?? YesNoQuestion.NO;

  const claimRecords = serverInsuranceRecords
    .filter(({ recordType }) => recordType === ServerInsuranceRecordType.Claim)
    .map((record, index) => {
      return mapServerRequestToClaimRecord(record as ServerClaimRecord);
    });

  let claimQuantity = ClaimQuantity.None;

  if (claimRecords.length > 0) {
    claimQuantity = claimRecords.length === 1 ? ClaimQuantity.One : ClaimQuantity.Many;
  }

  const terminationRecords = serverInsuranceRecords
    .filter(({ recordType }) => recordType === ServerInsuranceRecordType.Termination)
    .map((record) => mapServerRequestToTerminationRecord(record as ServerTerminationRecord));

  const hasTermination = terminationRecords.length > 0 ? YesNoQuestion.YES : YesNoQuestion.NO;

  return {
    cancellationOrSuspensionRecords,
    hasBeenCancelledOrSuspended,
    claimRecords,
    claimQuantity,
    terminationRecords,
    hasTermination,
  };
}
