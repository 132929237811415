import { Button, Typography, VStack, View } from '@ornikar/kitt-universal';
import { type ReactNode, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ADVISOR_PHONE_NUMBER } from '../../constants/callback';
import { WEB_CALLBACK_CLICK } from '../../constants/eventName';
import { useInsuranceDesktopMediaQuery } from '../../hooks/useInsuranceDesktopMediaQuery';
import { useWebCallbackScheduleData } from '../../hooks/useWebCallbackScheduleData';
import { sendEvent } from '../../utils/mixpanel';
import { CallbackModuleModal } from '../CallbackModuleModal';
import CallbackIllustration from './assets/callback-illustration.svg';

export interface CallbackModuleProps {
  light?: boolean;
}

export function CallbackModule({ light }: CallbackModuleProps): ReactNode {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isInBusinessHours, setIsInBusinessHours] = useState(false);
  const isDesktop = useInsuranceDesktopMediaQuery();
  const webCallbackScheduleData = useWebCallbackScheduleData();

  useEffect(() => {
    setIsInBusinessHours(!!webCallbackScheduleData?.isBusinessHours);
  }, [webCallbackScheduleData]);

  const sendMixpanelCallbackEvent = (buttonType: string): void => {
    sendEvent(WEB_CALLBACK_CLICK, {
      web_callback_button_location: 'card',
      web_callback_button_type: buttonType,
      opening_hours: isInBusinessHours,
    });
  };

  const callAdvisor = (): void => {
    sendMixpanelCallbackEvent('call');
    window.open(`tel:${ADVISOR_PHONE_NUMBER}`);
  };

  const callback = (): void => {
    sendMixpanelCallbackEvent('callback');
    setIsModalVisible(true);
  };

  return (
    <>
      <View
        backgroundColor="kitt.white"
        padding={light ? 'kitt.4' : 'kitt.6'}
        borderRadius="kitt.5"
        borderColor="kitt.palettes.lateOcean.black100"
        borderWidth={1}
        borderStyle="solid"
        flexDirection={light && !isDesktop ? 'row' : 'column'}
      >
        {light && !isDesktop ? (
          <View width="69" marginRight="kitt.6" display="flex" justifyContent="center">
            <img src={CallbackIllustration} alt="main avec un téléphone" />
          </View>
        ) : null}
        <View flex={1}>
          <View
            alignItems={light && !isDesktop ? 'left' : 'center'}
            flexDirection="column"
            marginBottom={light && !isDesktop ? 'kitt.4' : 0}
          >
            <View marginBottom={light && !isDesktop ? 'kitt.1' : 'kitt.2'}>
              <Typography.Header4 base={light ? 'header5' : 'header4'}>
                <FormattedMessage id="callbackmodule.question" defaultMessage="Une question ?" />
              </Typography.Header4>
            </View>
            <Typography.Text
              color="black-light"
              textAlign={light && !isDesktop ? 'start' : 'center'}
              base={light ? 'body-xsmall' : 'body-small'}
            >
              {isInBusinessHours || light ? (
                <FormattedMessage
                  id="callbackmodule.messageWorkingHour"
                  defaultMessage="Notre équipe est là pour vous du lundi au vendredi de 9h à 20h."
                  values={{ isDesktop: isDesktop ? 'true' : 'false' }}
                />
              ) : (
                <FormattedMessage
                  id="callbackmodule.messageOutWorkingHour"
                  defaultMessage="Notre équipe est disponible du lundi au vendredi de 9h à 20h. Vous pouvez planifiez un rappel pour être contacté à notre retour."
                />
              )}
            </Typography.Text>
            {light && !isDesktop ? null : (
              <View width={light ? 69 : 111} marginY="kitt.6">
                <img src={CallbackIllustration} alt="main avec un téléphone" />
              </View>
            )}
          </View>
          <VStack space="kitt.2">
            <Button stretch type={light ? 'default' : 'primary'} onPress={callback}>
              <FormattedMessage id="callbackmodule.button.beingCalledback" defaultMessage="Être rappelé" />
            </Button>
            {light || !isInBusinessHours ? null : (
              <Button stretch onPress={callAdvisor}>
                <FormattedMessage id="callbackmodule.button.callAdvisor" defaultMessage="Appeler un conseiller" />
              </Button>
            )}
          </VStack>
        </View>
      </View>
      <CallbackModuleModal isVisible={isModalVisible} onClose={() => setIsModalVisible(false)} />
    </>
  );
}
