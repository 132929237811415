import { HStack, Pressable, Typography, View, useKittTheme } from '@ornikar/kitt-universal';
import type { TypographyBodyType } from '@ornikar/kitt-universal/dist/definitions/typography/Typography';
import { type ReactNode, useState } from 'react';
import { toggleColors } from './colors';

export interface ToggleProps {
  label?: string;
  size?: 'medium' | 'large';
  value: boolean;
  isDisabled?: boolean;
  onChange?: (value: boolean) => void;
  variant?: 'default' | 'accent';
}

export function Toggle({
  label,
  size = 'medium',
  isDisabled = false,
  value,
  onChange = () => {},
  variant = 'default',
}: ToggleProps): ReactNode {
  const [isHover, setIsHover] = useState(false);
  const [isPressed, setIsPressed] = useState(false);

  const handlePress = (): void => {
    onChange(!value);
  };

  const handlePressIn = (): void => {
    setIsPressed(true);
  };

  const handlePressOut = (): void => {
    setIsPressed(false);
  };

  const handleHoverIn = (): void => {
    setIsHover(true);
  };

  const handleHoverOut = (): void => {
    setIsHover(false);
  };

  const theme = useKittTheme();

  const backgroundColorDefault =
    (isDisabled && toggleColors.regular.disabled.backgroundColor) ||
    (value ? toggleColors.regular.checked.backgroundColor : toggleColors.regular.default.backgroundColor);

  const backgroundColorAccent =
    (isDisabled && toggleColors.accent.disabled.backgroundColor) ||
    (value ? toggleColors.accent.checked.backgroundColor : toggleColors.accent.default.backgroundColor);

  const backgroundColor = {
    default: {
      variant: backgroundColorDefault,
      hover: value
        ? toggleColors.regular.checked.hover.backgroundColor
        : toggleColors.regular.default.hover.backgroundColor,
    },
    accent: {
      variant: backgroundColorAccent,
      hover: value
        ? toggleColors.accent.checked.hover.backgroundColor
        : toggleColors.accent.default.hover.backgroundColor,
    },
  };

  const labelColor = {
    default: {
      disabled: {
        color: toggleColors.regular.label.disabled.color,
      },
      enabled: {
        color: toggleColors.regular.label.default.color,
      },
    },
    accent: {
      disabled: {
        color: toggleColors.accent.label.disabled.color,
      },
      enabled: {
        color: toggleColors.accent.label.default.color,
      },
    },
  };

  const borderColor = {
    default: {
      borderColor: toggleColors.regular.pressed.borderColor,
      shadowColor: toggleColors.regular.pressed.shadowColor,
    },
    accent: {
      borderColor: toggleColors.accent.pressed.borderColor,
      shadowColor: toggleColors.accent.pressed.shadowColor,
    },
  };

  const variantSpacing = {
    medium: {
      spacing: 24,
      size: 18,
      width: 48,
      height: 24,
      padding: 3,
      labelSize: 'body' as TypographyBodyType,
    },
    large: {
      labelSize: 'body-medium' as TypographyBodyType,
      padding: 4,
      spacing: 32,
      size: 24,
      width: 64,
      height: 32,
    },
  };

  const Container = isDisabled ? View : Pressable;

  const buttonAnimation = {
    transform: `translateX(${value ? variantSpacing[size].spacing : 0}px)`,
    transitionProperty: 'all',
    transitionTimingFunction: 'ease',
    transitionDuration: '200ms',
    left: isHover ? (value ? -2 : 2) : 0,
  };

  const hoverBackgroundAnimation = {
    transitionProperty: 'background-color',
    transitionDuration: '200ms',
    transitionTimingFunction: 'ease',
    backgroundColor: isHover ? backgroundColor[variant].hover : backgroundColor[variant].variant,
  };

  return (
    <HStack>
      <Container
        isDisabled={isDisabled}
        onPress={handlePress}
        onHoverIn={handleHoverIn}
        onHoverOut={handleHoverOut}
        onPressIn={handlePressIn}
        onPressOut={handlePressOut}
      >
        <View
          style={[
            hoverBackgroundAnimation,
            {
              borderRadius: 100,
              alignItems: 'center',
              borderWidth: 1,
              boxShadow: isPressed ? `0px 0px 0px 1px ${borderColor[variant].shadowColor}` : '',
              borderColor: isPressed ? borderColor[variant].borderColor : theme.kitt.colors.transparent,
            },
          ]}
        >
          <HStack
            width={variantSpacing[size].width}
            height={variantSpacing[size].height}
            padding={variantSpacing[size].padding}
            alignItems="center"
          >
            <View style={[buttonAnimation]}>
              <View
                borderRadius="full"
                backgroundColor="kitt.white"
                width={variantSpacing[size].size}
                height={variantSpacing[size].size}
              />
            </View>
          </HStack>
        </View>
      </Container>
      <View marginLeft="kitt.2" padding={0} flexShrink={1}>
        <Typography.Text
          color={isDisabled ? labelColor[variant].disabled.color : labelColor[variant].enabled.color}
          base={variantSpacing[size].labelSize}
          ellipsizeMode="clip"
        >
          {label}
        </Typography.Text>
      </View>
    </HStack>
  );
}
