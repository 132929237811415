import { DriverHasBeenCovered } from '../fsm/answers';
import { useSubscriptionFsmState } from '../fsm/context';

export function useDocumentsNeeded(): string[] {
  const {
    context: {
      answers: { primaryDriver },
    },
  } = useSubscriptionFsmState();

  const documentsList: string[] = ['Permis de conduire', 'Carte grise'];

  const hasPrimaryAlreadyBeenCovered = primaryDriver?.hasBeenCovered !== DriverHasBeenCovered.NonJamais;
  const hasPrimaryNotBeenCoveredOnPeriod = primaryDriver?.hasBeenCovered === DriverHasBeenCovered.NonSurPeriode;

  if (hasPrimaryAlreadyBeenCovered && !(hasPrimaryNotBeenCoveredOnPeriod && primaryDriver?.crm === 100)) {
    documentsList.push("Relevé d'informations");
  }

  return documentsList;
}
