import { useRequiredValidator } from '@ornikar/react-validators';
import type { ReactNode } from 'react';
import type { Except } from 'type-fest';
import { bloodAlcoholLevelOptionsList } from '../../../../../constants/mappers/information';
import type { BloodAlcoholLevel } from '../../../../../fsm/answers';
import type { RadioScreenTemplateProps } from '../../../RadioScreenTemplate';
import { RadioScreenTemplate } from '../../../RadioScreenTemplate';

interface InsuranceRecordAlcoholLevelTemplateProps
  extends Except<RadioScreenTemplateProps<BloodAlcoholLevel>, 'choices'> {
  fieldName: string;
}

export function InsuranceRecordAlcoholLevelTemplate({
  title,
  fieldName,
}: InsuranceRecordAlcoholLevelTemplateProps): ReactNode {
  const requiredValidator = useRequiredValidator();

  return (
    <RadioScreenTemplate
      fieldName={fieldName}
      choices={bloodAlcoholLevelOptionsList}
      title={title}
      validate={requiredValidator}
    />
  );
}
