import { Breakdown } from '@ornikar/illustrated-icons';
import type { ReactNode } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { AddonCard } from '../../../../components/AddonCard';
import { Toggle } from '../../../../components/ToggleLegacy';
import { addonNameMapping } from '../../../../constants/options';
import { AddonName } from '../../../../fsm/answers';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../../fsm/context';
import { Event } from '../../../../fsm/types';
import { formatPrice } from '../../../../utils/formatPrice';
import { sendEvent } from '../../../../utils/mixpanel';
import { infoModalContent } from './infoModalContent';

const messages = defineMessages({
  assistanceDescription: {
    id: 'addons.assistance.description',
    defaultMessage:
      "Nous vous assistons partout, y compris à moins de 30 km de votre domicile avec l'extension de garantie Assistance 0km.",
  },
  assistanceSubtitle: {
    id: 'addons.assistance.subtitle',
    defaultMessage: "Dépanné n'importe où, même en bas de chez vous",
  },
  assistanceTitle: {
    id: 'addons.assistance.title',
    defaultMessage: 'Assistance 0km',
  },
  assistanceToggleText: {
    id: 'addons.assistance.toggle_text',
    defaultMessage: 'Prêt d’un véhicule de remplacement',
  },
});

export function Assistance0KmCard(): ReactNode {
  const send = useSubscriptionFsmDispatch();

  const {
    context: { answers, quoteOptions, paymentPeriodicity },
  } = useSubscriptionFsmState();

  const mainAddon = quoteOptions?.addOns.find(({ name }) => name === AddonName.OML_ASSISTANCE_0_KM_ADDON);
  const optionalAddon = quoteOptions?.addOns.find(({ name }) => name === AddonName.OML_VEHICLE_REPLACEMENT);

  const isOptionalAddonSelected = answers.addons?.OMLVehicleReplacement?.isSelected ?? false;

  const sendAnswer = (isSelected: boolean): void => {
    sendEvent('Choose Option Version', {
      version_name: AddonName.OML_VEHICLE_REPLACEMENT,
      version_value: isSelected,
    });

    send(Event.ANSWER_WITHOUT_CONTINUING, {
      answers: {
        addons: {
          [AddonName.OML_VEHICLE_REPLACEMENT]: {
            name: AddonName.OML_VEHICLE_REPLACEMENT,
            term: null,
            isSelected,
          },
        },
      },
    });
  };

  let price = mainAddon!.options[0].cost.cents;

  if (isOptionalAddonSelected) {
    price += optionalAddon!.options[0].cost.cents;
  }

  return (
    <AddonCard
      addonName={addonNameMapping[mainAddon!.name]}
      tag={<FormattedMessage {...messages.assistanceTitle} />}
      pagination="1/3"
      icon={<Breakdown />}
      title={<FormattedMessage {...messages.assistanceSubtitle} />}
      description={<FormattedMessage {...messages.assistanceDescription} />}
      price={`+${formatPrice(price)}€`}
      paymentPeriodicity={paymentPeriodicity!}
      infoModalContent={infoModalContent}
    >
      <Toggle
        value={isOptionalAddonSelected}
        onChange={() => {
          sendAnswer(!isOptionalAddonSelected);
        }}
      >
        <FormattedMessage {...messages.assistanceToggleText} />
      </Toggle>
    </AddonCard>
  );
}
