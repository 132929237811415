import type { ReactNode } from 'react';
import { ClaimRecordResponsibilityLevelTemplate } from '../../../../components/ScreenTemplates/InformationTemplates/InsuranceRecordTemplates/ClaimRecordTemplates/ClaimRecordResponsibilityLevelTemplate';
import { DriverType } from '../../../../fsm/answers';
import { useSubscriptionFsmState } from '../../../../fsm/context';

export function PrimaryClaimRecordResponsibilityLevelScreen(): ReactNode {
  const {
    context: {
      editingInsuranceRecord,
      answers: { primaryDriver },
    },
  } = useSubscriptionFsmState();
  const claimRecordIndex = editingInsuranceRecord.index;
  const claimRecord = primaryDriver?.claimRecords?.[claimRecordIndex];

  return (
    <ClaimRecordResponsibilityLevelTemplate
      title={`Quel était ${
        claimRecord?.driver === DriverType.AnotherDriver ? 'son' : 'votre'
      } niveau de responsabilité lors de l'accident\u00A0?`}
      fieldName={`primaryDriver.claimRecords[${claimRecordIndex}].responsibilityLevel`}
    />
  );
}
