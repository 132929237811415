export function getUtmParamsAsSearchParams(): URLSearchParams {
  const searchParams = new URLSearchParams();

  // eslint-disable-next-line no-restricted-syntax
  for (const [name, value] of new URLSearchParams(window.location.search)) {
    if (name.startsWith('utm_')) {
      searchParams.append(name, value);
    }
  }

  return searchParams;
}

export function getUtmParamsAsObject(): Record<string, string> {
  const utmSearchParams = getUtmParamsAsSearchParams();
  const utmObject: Record<string, string> = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const [name, value] of utmSearchParams) {
    utmObject[name] = value;
  }
  return utmObject;
}
