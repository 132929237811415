import { Keys } from '@ornikar/illustrated-icons';
import type { ReactNode } from 'react';
import { AddonCard } from '../../../../components/AddonCard';
import { AddonName } from '../../../../fsm/answers';
import { useSubscriptionFsmState } from '../../../../fsm/context';
import { formatPrice } from '../../../../utils/formatPrice';
import { infoModalContent } from './infoModalContent';

export function VehicleLoanCard(): ReactNode {
  const {
    context: { quoteOptions, paymentPeriodicity },
  } = useSubscriptionFsmState();

  const addon = quoteOptions?.addOns.find(({ name }) => name === AddonName.OML_VEHICLE_LOAN);

  const price = addon!.options[0].cost.cents;

  return (
    <AddonCard
      addonName="Prêt de volant"
      tag="Prêt de volant"
      pagination="3/3"
      icon={<Keys />}
      title="Prêt occasionnel de votre véhicule en toute sécurité"
      description="Un parent, un ami qui prend le volant de votre véhicule de temps en temps ? Avec cette option, le conducteur bénéficie des mêmes garanties que vous."
      price={`+${formatPrice(price)}€`}
      paymentPeriodicity={paymentPeriodicity!}
      infoModalContent={infoModalContent}
    />
  );
}
