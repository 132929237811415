import { CaretLeftRegularIcon } from '@ornikar/kitt-icons/phosphor';
import type { ButtonProps } from '@ornikar/kitt-universal';
import { Button, Typography, VStack, View } from '@ornikar/kitt-universal';
import type { ReactChild, ReactNode } from 'react';
import type React from 'react';
import { useForm } from 'react-final-form';
import { useSubscriptionFsmState } from '../../../fsm/context';
import { useFieldHasBeenAlreadyEdited } from '../../../hooks/useFieldHasBeenAlreadyEdited';
import { useGoBack } from '../../../hooks/useGoBack';
import { useInsuranceDesktopMediaQuery } from '../../../hooks/useInsuranceDesktopMediaQuery';
import { delayPromise } from '../../../utils/promise';
import { Breadcrumbs } from '../../Breadcrumbs';
import { ButtonSection } from '../../ButtonSection';
import { ConstrainedWidth } from '../../ConstrainedWidth';
import { InputHighlight } from '../../InputHighlight';
import styles from './styles.module.css';

export type ValidationButtonProps = ButtonProps & {
  label?: ReactNode;
  href?: string;
  loading?: boolean;
  disabled?: boolean;
};

export interface ScreenTemplateProps {
  title?: ReactChild;
  hideSaveQuoteButton?: boolean;
  hideSubmitButton?: boolean;
  hideSubmitButtonOnFirstEditionOfField?: string;
  hideBreadcrumbs?: boolean;
  submitButton?: Partial<ValidationButtonProps>;
  highlightTitle?: ReactChild;
  highlightContent?: ReactChild;
  highlightExpandable?: boolean;
  maxWidth?: string;
  titleMaxWidth?: string;
  disablePaddings?: boolean;
  shouldDisplayBackButton?: boolean;
  className?: string;
  children: NonNullable<ReactNode>;
  style?: React.CSSProperties;
  onBeforeSubmit?: () => void;
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  backButtonLabel?: ReactNode;
  disableForm?: boolean;
}

export function ScreenTemplate({
  title,
  hideSaveQuoteButton = false,
  hideSubmitButton = false,
  hideSubmitButtonOnFirstEditionOfField,
  hideBreadcrumbs = false,
  submitButton = {
    label: 'Continuer',
    disabled: false,
    loading: false,
  },
  highlightTitle,
  highlightContent,
  highlightExpandable,
  maxWidth,
  titleMaxWidth,
  disablePaddings,
  shouldDisplayBackButton,
  className,
  style,
  children,
  onBeforeSubmit,
  onSubmit,
  backButtonLabel = 'Étape précédente',
  disableForm = false,
}: ScreenTemplateProps): ReactNode {
  const isDesktop = useInsuranceDesktopMediaQuery();
  const [canBack, goBack] = useGoBack();

  const {
    context: { isLoading },
  } = useSubscriptionFsmState();
  const { submit } = useForm();
  const fieldHasBeenAlreadyEdited = useFieldHasBeenAlreadyEdited(hideSubmitButtonOnFirstEditionOfField ?? '');

  const isSubmitButtonVisible =
    !hideSubmitButton && (!hideSubmitButtonOnFirstEditionOfField || fieldHasBeenAlreadyEdited);

  const isButtonLoading = isLoading || submitButton?.loading;

  const content = (
    <>
      <div className={styles.Header}>
        {isDesktop && !hideBreadcrumbs ? <Breadcrumbs className={styles.Breadcrumbs} /> : null}

        <ConstrainedWidth maxWidth={titleMaxWidth || maxWidth} padding={disablePaddings ? 0 : undefined}>
          {(canBack || shouldDisplayBackButton) && isDesktop ? (
            <View marginBottom={32} marginLeft={-22}>
              <Button icon={<CaretLeftRegularIcon />} type="subtle-dark" onPress={goBack}>
                {backButtonLabel}
              </Button>
            </View>
          ) : null}
          {title ? (
            <VStack width="100%" justifyContent="center" marginBottom={32}>
              <Typography.h4 variant="bold" textAlign="center">
                {title}
              </Typography.h4>
            </VStack>
          ) : null}
        </ConstrainedWidth>
      </div>
      <ConstrainedWidth maxWidth={maxWidth} padding={disablePaddings ? 0 : undefined}>
        <div className={className}>{children}</div>

        {highlightContent ? (
          <InputHighlight title={highlightTitle} expandable={highlightExpandable}>
            {highlightContent}
          </InputHighlight>
        ) : null}

        <ButtonSection
          isSubmitButtonVisible={isSubmitButtonVisible}
          hideSaveQuoteButton={hideSaveQuoteButton}
          disablePaddings={disablePaddings}
          isButtonLoading={isButtonLoading}
          submitButton={submitButton}
        />
      </ConstrainedWidth>
    </>
  );

  const commonProps = {
    className: styles.ScreenTemplate,
    style: { paddingBottom: disablePaddings ? 0 : undefined, ...style },
  };

  return disableForm ? (
    <div {...commonProps}>{content}</div>
  ) : (
    <form
      {...commonProps}
      onSubmit={async (e) => {
        e.preventDefault();

        if (onBeforeSubmit) {
          onBeforeSubmit();
        }

        if (onSubmit) {
          delayPromise(async () => onSubmit(e), 0);
        } else {
          /**
           * @devs delay is here to fix a desynchronization issue with `submitting` final-form state
           * Solution found here (different issue, same solution) -> https://github.com/final-form/react-final-form/issues/761#issuecomment-603163705
           */
          delayPromise(submit, 0);
        }
      }}
    >
      {content}
    </form>
  );
}
