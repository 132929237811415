import type { Version } from '../../../apis/vehicules';
import { listModelsWithPossibleValues, listVersions } from '../../../apis/vehicules';

const prepareVehiculeModels = async (
  vehicleBrand?: string,
  vehicleModel?: string,
): Promise<{ availableEnergies: string[]; availableHorsePower: number[] } | undefined> => {
  if (vehicleBrand && vehicleModel) {
    const models = await listModelsWithPossibleValues(vehicleBrand);
    if (models) {
      const pickedModel = models.find((model) => model.name === vehicleModel);

      return {
        availableEnergies: pickedModel?.energies || [],
        availableHorsePower: pickedModel?.puissances_fiscales || [],
      };
    }
  }
  return undefined;
};

export const getVehicleInfo = async (
  brand?: string,
  model?: string,
  taxHorsePower?: number,
  energy?: string,
): Promise<{
  availableVersions: Version[];
  loadedVehicleInfo: { availableEnergies: string[]; availableHorsePower: number[] } | undefined;
}> => {
  const [availableVersions, loadedVehicleInfo] = await Promise.all([
    listVersions(brand, model, taxHorsePower, energy),

    prepareVehiculeModels(brand, model),
  ]);

  return { availableVersions, loadedVehicleInfo };
};
