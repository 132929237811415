import type React from 'react';
import { useEffect, useState } from 'react';

interface BaseState {
  loading: boolean;
}
type ResultState<Result> = BaseState & { result: Result | null; error: null };
type ErrorState = BaseState & { result: null; error: Error };

type UseAsyncState<Result> = ResultState<Result> | ErrorState;

export function useAsync<Result>(
  fetcher: () => Promise<Result>,
  dependencies: React.DependencyList,
): [Result | null, Error | null, boolean] {
  const [state, setState] = useState<UseAsyncState<Result>>({
    result: null,
    error: null,
    loading: true,
  });

  useEffect(() => {
    let cancelled = false;
    if (!state.loading) setState({ ...state, loading: true });

    fetcher().then(
      (res) => {
        if (!cancelled) setState({ result: res, error: null, loading: false });
      },
      (error) => {
        if (!cancelled) setState({ result: null, error, loading: false });
      },
    );
    return () => {
      cancelled = true;
    };
    // The exhaustive-deps check will be made on call site
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
  return [state.result, state.error, state.loading];
}
