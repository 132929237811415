import { Pressable, Typography, VStack, View } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import type { Time } from '../../utils/businessDates';

interface ChoiceButtonProps {
  bgColor: string;
  hoveredBgColor: string;
  title: ReactNode;
  isSelected?: boolean;
  handlePress: () => void;
}

function ChoiceButton({ bgColor, hoveredBgColor, title, isSelected, handlePress }: ChoiceButtonProps): ReactNode {
  return (
    <Pressable
      height={42}
      borderRadius={10}
      backgroundColor={isSelected ? hoveredBgColor : bgColor}
      alignItems="center"
      justifyContent="center"
      _hover={{ backgroundColor: hoveredBgColor }}
      onPress={handlePress}
    >
      {({ isHovered }) => (
        <Typography.Text variant="bold" color={isHovered || isSelected ? 'white' : 'primary'}>
          {title}
        </Typography.Text>
      )}
    </Pressable>
  );
}

const renderMoreOrLessButton = (
  nbItemsToDisplay: number,
  maxNbItemsToDisplay: number,
  itemsLength: number,
  handlePress: (nbItemsToDisplay: number) => void,
): ReactNode | null => {
  if (nbItemsToDisplay === itemsLength && nbItemsToDisplay !== maxNbItemsToDisplay) {
    return (
      <ChoiceButton
        title={
          <FormattedMessage
            id="callbackModule.slotChoice.displayLessSlots"
            defaultMessage="Afficher moins de créneaux"
          />
        }
        bgColor="kitt.palettes.lateOcean.moonPurple"
        hoveredBgColor="kitt.palettes.lateOcean.lateOceanLight2"
        handlePress={() => handlePress(maxNbItemsToDisplay)}
      />
    );
  }

  if (itemsLength > maxNbItemsToDisplay) {
    return (
      <ChoiceButton
        title={
          <FormattedMessage
            id="callbackModule.slotChoice.displayMoreSlots"
            defaultMessage="Afficher plus de créneaux"
          />
        }
        bgColor="kitt.palettes.lateOcean.moonPurple"
        hoveredBgColor="kitt.palettes.lateOcean.lateOceanLight2"
        handlePress={() => handlePress(itemsLength)}
      />
    );
  }

  return null;
};

export interface CallbackScheduleItem<T> {
  id: number;
  label: string;
  value: T;
}

interface CallbackScheduleModuleProps {
  title: ReactNode | string;
  items: CallbackScheduleItem<Date | [Time, Time]>[];
  selectedItemId?: number;
  onPress: (event: any) => void;
}

export function CallbackScheduleModule({
  title,
  items,
  selectedItemId,
  onPress,
}: CallbackScheduleModuleProps): ReactNode {
  const maxNbItemsToDisplay = 5;
  const [nbItemsToDisplay, setNbItemsToDisplay] = useState(maxNbItemsToDisplay);

  return (
    <VStack
      backgroundColor="kitt.palettes.lateOcean.moonPurpleLight1"
      borderRadius="kitt.4"
      paddingTop="kitt.6"
      paddingBottom="kitt.4"
      paddingX="kitt.4"
      flex={1}
    >
      <View alignItems="center">
        <Typography.Header5 base="header5" variant="bold" color="primary">
          {title}
        </Typography.Header5>
      </View>

      <VStack paddingTop="kitt.6" space="kitt.2">
        {items.slice(0, nbItemsToDisplay).map((item) => (
          <ChoiceButton
            key={item.id}
            title={item.label}
            isSelected={item.id === selectedItemId}
            bgColor="white"
            hoveredBgColor="kitt.palettes.lateOcean.lateOceanLight1"
            handlePress={() => onPress(item)}
          />
        ))}
        {renderMoreOrLessButton(nbItemsToDisplay, maxNbItemsToDisplay, items.length, setNbItemsToDisplay)}
      </VStack>
    </VStack>
  );
}
