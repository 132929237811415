import { useMemo } from 'react';
import type { ReactNode } from 'react';
import { ScreenTemplate } from '../../../components/ScreenTemplates/ScreenTemplate';
import { useModelValidator } from '../../../forms/validation/sections/vehicle/model';
import { VehiculePreSelectionType } from '../../../fsm/answers';
import { useSubscriptionFsmState } from '../../../fsm/context';
import { ModeleAutocomplete } from './ModeleAutocomplete';

export function VehicleModelScreen(): ReactNode {
  const modelValidator = useModelValidator();
  const { context } = useSubscriptionFsmState();

  const isFirstSection = useMemo(
    () => context.answers.vehiculePreSelectionType === VehiculePreSelectionType.BRAND,
    [context.answers.vehiculePreSelectionType],
  );

  return (
    <ScreenTemplate
      title={<>{isFirstSection ? <>C&apos;est parti&nbsp;! </> : null}De quel modèle s’agit-il&nbsp;?</>}
      highlightTitle="Où trouver le modèle ?"
      highlightContent={
        <div>
          Rendez-vous au <b>repère D.3</b> de votre <b>carte grise (certificat d’immatriculation).</b>
        </div>
      }
      hideSubmitButtonOnFirstEditionOfField="vehiculeModele"
    >
      <ModeleAutocomplete name="vehiculeModele" validate={modelValidator} />
    </ScreenTemplate>
  );
}
