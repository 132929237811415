import { HStack, Image, Typography, VStack } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import Arrow from './assets/arrow.svg';
import Star from './assets/star.svg';

export interface RecommendationProps {
  position: 'left' | 'middle' | 'right';
  content: string | ReactNode;
}

export function Recommendation({ position, content }: RecommendationProps): ReactNode {
  const unionPosition = {
    left: 'flex-start',
    middle: 'center',
    right: 'flex-end',
  };

  return (
    <HStack justifyContent={unionPosition[position]} borderRadius="kitt.4">
      <VStack maxWidth="100%">
        <HStack
          alignItems="center"
          space={10}
          backgroundColor="#08D48E"
          paddingX="kitt.4"
          paddingY="kitt.2"
          borderRadius="kitt.4"
          borderColor="kitt.black"
          borderWidth={1}
          borderStyle="solid"
          style={{ boxShadow: '0px 2px 0px 0px #000' }}
        >
          <Image src={Star} alt="étoile" height="kitt.8" width="kitt.8" />
          <Typography.Text variant="bold" color="kitt.black" base="body-small">
            {content}
          </Typography.Text>
        </HStack>

        <HStack justifyContent={unionPosition[position]} width="100%" margin={-2}>
          <Image src={Arrow} alt="union" height={12} width={32} marginX="kitt.4" />
        </HStack>
      </VStack>
    </HStack>
  );
}
