import { ArrowLeftRegularIcon, PhoneRegularIcon } from '@ornikar/kitt-icons/phosphor';
import { Button, Icon, Pressable, Typography, View } from '@ornikar/kitt-universal';
import { NotificationCenter } from '@ornikar/react-notification';
import cx from 'classnames';
import type { ReactNode } from 'react';
import { useCallback } from 'react';
import { WEB_CALLBACK_FEATURE } from '../../constants/flagshipKeys';
import { useSetContactModal } from '../../contexts/contactModal';
import { useBreadcrumbs } from '../../hooks/Breadcrumbs/useBreadcrumbs';
import { useGoBack } from '../../hooks/useGoBack';
import { useInsuranceDesktopMediaQuery } from '../../hooks/useInsuranceDesktopMediaQuery';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { useFsFlag } from '../../setup/flagship';
import { onEnterKeyPressed } from '../../utils/keyboard';
import { CallbackHeaderButton } from '../CallbackHeaderButton';
import { Logo } from '../Logo';
import { SaveQuoteButton } from '../SaveQuoteButton';
import messageCircle from './messageCircle.svg';
import styles from './styles.module.css';

interface HeaderProps {
  onOpenHelperModal?: () => void;
  saveQuoteButtonVisible?: boolean;
  helperButtonVisible?: boolean;
  backgroundColor?: 'white' | 'seashell';
}

export function Header({
  onOpenHelperModal,
  saveQuoteButtonVisible = true,
  helperButtonVisible = true,
  backgroundColor = 'white',
}: HeaderProps): ReactNode {
  const webCallbackFlag = useFsFlag<boolean>(WEB_CALLBACK_FEATURE, false);
  const setContactModal = useSetContactModal();
  const isDesktop = useInsuranceDesktopMediaQuery();
  const item = useBreadcrumbs();
  const isSiderBreakpoint = useMediaQuery('min-width: 920px');

  const [canBack, goBack] = useGoBack();

  const openContactModal = useCallback(() => {
    if (onOpenHelperModal) {
      onOpenHelperModal();
    }
    setContactModal(true);
  }, [setContactModal, onOpenHelperModal]);

  const background = backgroundColor === 'white' ? 'white' : 'kitt.palettes.lateOcean.seaShell';

  return (
    <View
      position="sticky"
      height={isSiderBreakpoint ? 'kitt.18' : 'kitt.16'}
      maxHeight={isSiderBreakpoint ? 'kitt.18' : 'kitt.16'}
      top="0"
      left="0"
      right="0"
      borderBottomColor="kitt.separator"
      borderBottomWidth="1"
      zIndex="2"
      backgroundColor={background}
    >
      {isDesktop ? (
        <div className={styles.HeaderContent}>
          <a href="/assurance-auto" aria-label="Go to home page">
            <Logo height={{ base: 22, medium: 28 }} />
          </a>
          {helperButtonVisible || saveQuoteButtonVisible ? (
            <div className={styles.Actions}>
              {helperButtonVisible ? (
                webCallbackFlag.getValue() ? (
                  <CallbackHeaderButton />
                ) : (
                  <Button type="primary" icon={<PhoneRegularIcon />} onPress={openContactModal}>
                    Besoin d’aide ?
                  </Button>
                )
              ) : null}
              {saveQuoteButtonVisible ? <SaveQuoteButton /> : <div />}
            </div>
          ) : null}
        </div>
      ) : (
        <>
          <div className={styles.HeaderContent}>
            {canBack ? (
              <Pressable aria-label="Back" onPress={goBack}>
                <Icon icon={<ArrowLeftRegularIcon />} size={24} color="black" />
              </Pressable>
            ) : (
              <div />
            )}

            <Typography.Text variant="bold" color="primary">
              {item.copy}
            </Typography.Text>

            {helperButtonVisible ? (
              webCallbackFlag.getValue() ? (
                <CallbackHeaderButton />
              ) : (
                <div
                  role="button"
                  tabIndex={-1}
                  className={cx(styles.HelpIconButton, styles.Right)}
                  onClick={openContactModal}
                  onKeyDown={(e) => onEnterKeyPressed(e, openContactModal)}
                >
                  <img src={messageCircle} className={styles.Icon} alt="helper icon button" />
                </div>
              )
            ) : (
              <div />
            )}
          </div>

          <div className={styles.ProgressBar} style={{ width: `${item.percent}%` }} />
        </>
      )}

      <NotificationCenter layoutComponent="div" />
    </View>
  );
}
