import { useRequiredValidator } from '@ornikar/react-validators';
import type { ReactNode } from 'react';
import { ScreenTemplate } from '../../../components/ScreenTemplates/ScreenTemplate';
import { PuissanceFiscaleField } from './PuissanceFiscaleField';

export function VehiclePuissanceFiscaleScreen(): ReactNode {
  const requiredValidator = useRequiredValidator();
  return (
    <ScreenTemplate
      title="Quelle est sa puissance fiscale&nbsp;?"
      highlightTitle="Où trouver la puissance fiscale ?"
      highlightContent={
        <div>
          Rendez-vous au <b>repère P.6</b> de votre <b>carte grise (certificat d’immatriculation)</b>
        </div>
      }
      hideSubmitButtonOnFirstEditionOfField="vehiculePuissanceFiscale"
    >
      <PuissanceFiscaleField name="vehiculePuissanceFiscale" validate={requiredValidator} />
    </ScreenTemplate>
  );
}
