import { HStack, Highlight, Typography, View } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import NumberPlate from '../assets/Numberplate.svg';

export interface VehicleDetailsProps {
  circulationDate: string;
  vehicleBrand: string;
  vehicleModel: string;
  vehiclePower: string;
  vehicleFuel: string;
}

export function VehicleDetails({
  circulationDate,
  vehicleBrand,
  vehicleModel,
  vehiclePower,
  vehicleFuel,
}: VehicleDetailsProps): ReactNode {
  return (
    <Highlight variant="secondary">
      <HStack space="kitt.4">
        <img src={NumberPlate} alt="A car" width="40px" />
        <View flex={1}>
          <Typography.Paragraph>
            {vehicleBrand}, {vehicleModel}, {vehiclePower}CV - {vehicleFuel}
          </Typography.Paragraph>
          <Typography.Paragraph>Mise en circulation le {circulationDate!}</Typography.Paragraph>
        </View>
      </HStack>
    </Highlight>
  );
}
