import type { PropsWithChildren, ReactNode } from 'react';
import type { AddonName } from '../../../fsm/answers';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { Event } from '../../../fsm/types';
import { useInsuranceMediaQuery } from '../../../hooks/useInsuranceMediaQuery';
import { sendEvent } from '../../../utils/mixpanel';
import { FancyButton } from '../../FancyButton';
import { FancyIconButton } from '../../FancyIconButton';
import { FormulaPriceBottomBar } from '../../FormulaPrice/FormulaPriceBottomBar';
import { FormulaPriceCard } from '../../FormulaPrice/FormulaPriceCard';
import { ScreenTemplateWithSideAndBottomBars } from '../ScreenTemplateWithSideAndBottomBars';
import noIcon from './assets/no.svg';
import yesIcon from './assets/yes.svg';
import styles from './styles.module.css';

interface AddonScreenTemplateProps {
  addonName: AddonName;
}

export function AddonScreenTemplate({ addonName, children }: PropsWithChildren<AddonScreenTemplateProps>): ReactNode {
  const send = useSubscriptionFsmDispatch();
  const { isMedium } = useInsuranceMediaQuery();
  const AcceptationButton = isMedium ? FancyButton : FancyIconButton;

  const {
    context: { answers },
  } = useSubscriptionFsmState();

  const isAddonSelected = answers.addons?.[addonName]?.isSelected;

  const sendAnswer = (isSelected: boolean): void => {
    sendEvent('Decide On Option', {
      option_name: addonName,
      include_option: isSelected,
      decision_method: 'click',
      decision_step: 'option_state',
    });

    send(Event.ANSWER, {
      answers: {
        addons: {
          [addonName]: { name: addonName, term: null, isSelected },
        },
      },
    });
  };

  const handleSubmit = typeof isAddonSelected === 'boolean' ? () => sendAnswer(isAddonSelected) : undefined;

  return (
    <ScreenTemplateWithSideAndBottomBars
      hideSaveQuoteButton
      hideSubmitButton
      backButtonLabel="Étape précédente"
      sideBar={<FormulaPriceCard buttonLabel="Continuer" onSubmit={handleSubmit} />}
      bottomBar={<FormulaPriceBottomBar buttonLabel="Continuer" onSubmit={handleSubmit} />}
      onSubmit={handleSubmit}
    >
      <div className={styles.ScreenWrapper}>
        {children}

        <div className={styles.ButtonsWrapper}>
          <AcceptationButton
            isActive={isAddonSelected === false}
            icon={noIcon}
            iconAlt="croix de refus"
            onClick={() => {
              sendAnswer(false);
            }}
          >
            Non merci
          </AcceptationButton>
          <AcceptationButton
            isActive={isAddonSelected === true}
            icon={yesIcon}
            iconAlt="check de validation"
            onClick={() => {
              sendAnswer(true);
            }}
          >
            Oui, j’ajoute
          </AcceptationButton>
        </div>
      </div>
    </ScreenTemplateWithSideAndBottomBars>
  );
}
