import sha256 from 'crypto-js/sha256';

/**
 * For more information on these events
 * https://docs.google.com/spreadsheets/d/1XW2e1jmnsmkxb0lrxZUvg6_A91aQKqlSx0H6WVDlnrU/edit#gid=0
 */

type GTMEvent = 'Clickbutton' | 'FakePageview';

type GTMEventName =
  | 'Initiate_Insurance_Long_Quote'
  | 'Resume_Insurance_Long_Quote'
  | 'Save_Insurance_Long_Quote'
  | 'Sum_up_Insurance_Long_Quote'
  | 'Fill_Form_Insurance_Long_Quote'
  | 'Besoin_Aide'
  | 'Insurance-Failed_Insurance_Long_Quote'
  | 'Insurance-Not_Available_Insurance_Long_Quote'
  | 'Insurance-Success_Insurance_Long_Quote'
  | 'Insurance - Initiate Checkout Payment'
  | 'Insurance - Payment Success'
  | 'Insurance - Welcome_Page'
  | 'User_Login_Subscription_Insurance';

type YesOrNo = 'yes' | 'no';

type DataLayerProps = {
  event: GTMEvent;
  eventLabel: GTMEventName;
  email?: string;
} & (
  | {
      client?: YesOrNo;
    }
  | {
      client: YesOrNo;
      score: 'OK' | 'KO';
    }
  | { value: string }
);

declare global {
  interface Window {
    dataLayer: unknown[];
  }
}

function pushToDataLayer(props: DataLayerProps): void {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({ ...props, email: props.email ? sha256(props.email).toString() : undefined });
}

export const sendInitiateInsuranceLongQuoteGTMEvent = (): void =>
  pushToDataLayer({
    event: 'Clickbutton',
    eventLabel: 'Initiate_Insurance_Long_Quote',
  });

export const sendResumeInsuranceLongQuoteGTMEvent = (email?: string): void =>
  pushToDataLayer({
    event: 'Clickbutton',
    eventLabel: 'Resume_Insurance_Long_Quote',
    email,
  });

export const sendSaveInsuranceLongQuoteGTMEvent = (email?: string): void =>
  pushToDataLayer({
    event: 'Clickbutton',
    eventLabel: 'Save_Insurance_Long_Quote',
    email,
  });

export const sendSumUpInsuranceLongQuoteGTMEvent = (): void =>
  pushToDataLayer({
    event: 'Clickbutton',
    eventLabel: 'Sum_up_Insurance_Long_Quote',
  });

export const sendFillFormInsuranceLongQuoteGTMEvent = (email: string): void =>
  pushToDataLayer({
    event: 'Clickbutton',
    eventLabel: 'Fill_Form_Insurance_Long_Quote',
    email,
  });

export const sendNeedHelpGTMEvent = (email?: string): void =>
  pushToDataLayer({
    event: 'Clickbutton',
    eventLabel: 'Besoin_Aide',
    email,
  });

// Quote request failed due to some technical issues
export const sendFailedInsuranceLongQuoteGTMEvent = (email?: string): void =>
  pushToDataLayer({
    event: 'FakePageview',
    eventLabel: 'Insurance-Failed_Insurance_Long_Quote',
    email,
  });

/**
 * Quote request failed due to non insurable risk profile
 * Unused due to a technical blocker, planned to be fixed (see https://ornikar.atlassian.net/browse/ING-920)
 */
export const sendNotAvailableInsuranceLongQuoteGTMEvent = (email?: string): void =>
  pushToDataLayer({
    event: 'FakePageview',
    eventLabel: 'Insurance-Not_Available_Insurance_Long_Quote',
    email,
  });

interface SendSuccessInsuranceLongQuoteGTMEventProps {
  hasDiscount: boolean;
  email: string;
  hasEducationAccount: boolean;
}

export const sendSuccessInsuranceLongQuoteGTMEvent = ({
  hasDiscount,
  email,
  hasEducationAccount,
}: SendSuccessInsuranceLongQuoteGTMEventProps): void =>
  pushToDataLayer({
    event: 'FakePageview',
    eventLabel: 'Insurance-Success_Insurance_Long_Quote',
    score: hasDiscount ? 'OK' : 'KO',
    client: hasEducationAccount ? 'yes' : 'no',
    email,
  });

export const sendInitiateCheckoutPaymentEvent = (conversionValue: string): void =>
  pushToDataLayer({
    event: 'Clickbutton',
    eventLabel: 'Insurance - Initiate Checkout Payment',
    value: conversionValue,
  });

export const sendPaymentSuccessEvent = (conversionValue: string, email?: string): void =>
  pushToDataLayer({
    event: 'FakePageview',
    eventLabel: 'Insurance - Payment Success',
    value: conversionValue,
    email,
  });

export const sendUserLoginSuccessEvent = (): void =>
  pushToDataLayer({
    event: 'Clickbutton',
    eventLabel: 'User_Login_Subscription_Insurance',
  });

export const sendWelcomePageReachedEvent = (email?: string): void =>
  pushToDataLayer({
    event: 'FakePageview',
    eventLabel: 'Insurance - Welcome_Page',
    email,
  });
