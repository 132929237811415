import { api } from './api';

interface PaymentSession {
  id: string;
  data: string;
  returnUrl: string;
  amount: number;
  currency: string;
}

export const getPaymentSession = (quoteId: string | number): Promise<PaymentSession> =>
  api<PaymentSession>(`api/v1/quotes/${quoteId}/payment/session`, {
    method: 'POST',
  });
