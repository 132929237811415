import { differenceInYears, startOfToday, toDate } from 'date-fns';
import type { DateType } from '../../../../fsm/answers';

const MIN_CRM = [100, 95, 90, 85, 80, 76, 72, 68, 64, 60, 57, 54, 51, 50];
const CRM_VALUES = Array.from({ length: 301 }).map((_, idx) => idx);

export const crmHumanFriendlySentence = (crm: number): string => {
  let humanFriendlyCrm = (crm / 100).toString();

  if (humanFriendlyCrm.length === 1) {
    humanFriendlyCrm += '.00';
  }

  humanFriendlyCrm = humanFriendlyCrm.padEnd(4, '0');

  if (crm < 50 || crm > 350) {
    return humanFriendlyCrm;
  }

  if (crm === 100) {
    return `${humanFriendlyCrm}, ni bonus, ni malus`;
  }

  if (crm < 100) {
    return `${humanFriendlyCrm}, bonus de ${100 - crm}%`;
  }

  return `${humanFriendlyCrm}, malus de ${crm - 100}%`;
};

export const getCrmOptions = (licenseObtention?: DateType): { label: string; value: number }[] => {
  let min = 50;

  if (licenseObtention) {
    min = MIN_CRM[differenceInYears(startOfToday(), toDate(new Date(licenseObtention)))] || 50;
  }

  return CRM_VALUES.filter((value) => value >= min).map((value) => ({
    label: crmHumanFriendlySentence(value),
    value,
  }));
};
