import type { DrivingFsmAnswers, VehicleFsmAnswers } from '../../../fsm/answers';
import { useSubscriptionFsmDispatch } from '../../../fsm/context';
import { Event } from '../../../fsm/types';
import type { DrivingFormValues, InformationFormValues, VehicleFormValues } from '../../answers';
import type { Submissions } from '../types';

type DrivingSubmissionFormValues = VehicleFormValues & DrivingFormValues & InformationFormValues;
type DrivingSubmissionFsmAnswers = VehicleFsmAnswers & DrivingFsmAnswers;

export const useDrivingSubmissions: () => Submissions<
  DrivingSubmissionFormValues,
  DrivingSubmissionFsmAnswers
> = () => {
  const send = useSubscriptionFsmDispatch();

  return {
    DRIVING_PRIMARY_LICENSE_DATE: ({ primaryDriver }) => {
      send({
        type: Event.ANSWER,
        answers: {
          primaryDriver,
        },
      });
    },
    DRIVING_PRIMARY_LICENSE_TYPE: ({ primaryDriver }) => {
      send({
        type: Event.ANSWER,
        answers: {
          primaryDriver,
        },
      });
    },
    DRIVING_PRIMARY_WAS_ACCOMPANIED: ({ primaryDriver }) => {
      send({
        type: Event.ANSWER,
        answers: {
          primaryDriver,
        },
      });
    },
    DRIVING_VEHICULE_USAGE: ({ usage }) => {
      send({ type: Event.ANSWER, answers: { usage } });
    },
    DRIVING_KILOMETERS_PER_YEAR: ({ kilometersPerYear }) => {
      send({ type: Event.ANSWER, answers: { kilometersPerYear } });
    },
    DRIVING_VEHICULE_PARKING: ({ parkingLocation }) => {
      send({ type: Event.ANSWER, answers: { parkingLocation } });
    },
    DRIVING_CITY: ({ parkingCodePostal, parkingCommune }) => {
      send({ type: Event.ANSWER, answers: { parkingCodePostal, parkingCommune } });
    },
    DRIVING_HOUSING_TYPE: ({ housingType }) => {
      send({ type: Event.ANSWER, answers: { housingType } });
    },
    DRIVING_IS_PROPERTY_OWNER: ({ isPropertyOwner }) => {
      send({ type: Event.ANSWER, answers: { isPropertyOwner } });
    },
    DRIVING_REGISTRATION_DOCUMENT_OWNER_IS_SUBSCRIBER: ({ registrationDocumentOwnerIsSubscriber }) => {
      send({ type: Event.ANSWER, answers: { registrationDocumentOwnerIsSubscriber } });
    },
    DRIVING_REGISTRATION_DOCUMENT_OWNER: ({ titulaireCartegrise }) => {
      send({ type: Event.ANSWER, answers: { titulaireCartegrise } });
    },
  };
};
