import DOMPurify from 'dompurify';
import { marked } from 'marked';

/**
 * @param markdown that will be parsed to html
 * @param sanitize should sanitize to avoid XSS. Default to `true`
 * @returns `markdown` as html
 */
export const parseMarkdown = (markdown: string, sanitize = true): string => {
  const html = marked.parse(markdown) as string;

  if (sanitize) {
    // sanitize the html to avoid XSS
    return DOMPurify.sanitize(html);
  }

  return html;
};
