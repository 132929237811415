import { CheckRegularIcon, XRegularIcon } from '@ornikar/kitt-icons/phosphor';
import { HStack, Icon, TypographyLink, View } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import type { PackageCoverage } from '../../../../../../apis/types/Formula';

export interface PackageElementProps {
  coverage: PackageCoverage;
  isAvailable: boolean;
  showInfoModal: (coverage: PackageCoverage) => void;
}
export function PackageElement({ coverage, isAvailable, showInfoModal }: PackageElementProps): ReactNode {
  return (
    <View>
      <HStack alignItems="center" space="kitt.2">
        <Icon
          icon={isAvailable ? <CheckRegularIcon /> : <XRegularIcon />}
          color={isAvailable ? 'kitt.correct' : 'kitt.danger'}
        />
        <TypographyLink
          variant="regular"
          color={isAvailable ? 'black' : 'black-light'}
          onPress={() => showInfoModal(coverage)}
        >
          {coverage.label}
        </TypographyLink>
      </HStack>
    </View>
  );
}
