import { DefaultAnswersEnum, State } from '../fsm/types';

export const DEFAULT_ANSWERS_BY_SECTION: Partial<Record<State, DefaultAnswersEnum[]>> = {
  [State.DRIVING_SUMMARY]: [DefaultAnswersEnum.PRIMARY_DRIVER_LICENSE_DATE],
  [State.VEHICLE_SUMMARY]: [
    DefaultAnswersEnum.VEHICULE_MISE_EN_CIRCULATION,
    DefaultAnswersEnum.VEHICULE_PUISSANCE_FISCALE,
    DefaultAnswersEnum.VEHICULE_VERSION,
  ],
};
