import { getRemoteQuoteFromCoreApi } from '../../../apis/getRemoteQuoteFromCoreApi';
import { initializeContext } from '../../context';
import { sanitizeRemoteQuote } from '../../validators';
import type { StateMachineInitContext } from '../types';
import { getVehicleInfo } from './getVehicleInfos';

export const fetchRemoteQuoteFromCoreApiService = async (
  context: StateMachineInitContext,
): Promise<Partial<StateMachineInitContext>> => {
  const rawQuote = await getRemoteQuoteFromCoreApi(context.quoteId!, context.leadId!);

  if (rawQuote) {
    const { quoteAnswers: answers, stateValue, reachedSummaryScreenSections } = sanitizeRemoteQuote(rawQuote);

    const { availableVersions, loadedVehicleInfo } = await getVehicleInfo(
      answers.vehiculeMarque,
      answers.vehiculeModele,
      answers.vehiculePuissanceFiscale,
      answers.vehiculeCarburant,
    );

    return {
      config: {
        id: 'remote-machine',
        context: initializeContext({
          answers,
          availableVersions,
          availableCarburants: loadedVehicleInfo?.availableEnergies,
          availablePuissanceFiscales: loadedVehicleInfo?.availableHorsePower,
          isFromAggregator: true,
          reachedSummaryScreenSections,
        }),
        stateValue,
      },
      isFetched: true,
    };
  }

  return {
    ...context,
    isFetched: false,
  };
};
