import { Button } from '@ornikar/kitt';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { useField } from 'react-final-form';
import {
  useDateInsurancePeriodValidator,
  useEndDateInsurancePeriodValidator,
} from '../../../../forms/validation/sections/informations/insurancePeriodValidator';
import type { Period } from '../../../../fsm/answers';
import { Query, useMediaQuery } from '../../../../hooks/useMediaQuery';
import { DateField } from '../../../DateField';
import styles from './styles.module.css';

interface PeriodRowProps {
  fieldName: string;
  idx: number;
  periods: Period[];
  onDelete?: () => void;
}

export function PeriodRow({ fieldName, idx, periods, onDelete }: PeriodRowProps): ReactNode {
  const isMedium = useMediaQuery(Query.MEDIUM);

  const startFieldName = `${fieldName}[${idx}].start`;
  const endFieldName = `${fieldName}[${idx}].end`;

  const {
    input: { value: start },
  } = useField(startFieldName);

  const forbiddenPeriods = useMemo(() => (periods ? periods.filter((_, i) => i !== idx) : []), [idx, periods]);

  const startDatePeriodValidator = useDateInsurancePeriodValidator(forbiddenPeriods);

  const endDatePeriodValidator = useEndDateInsurancePeriodValidator(start as string, forbiddenPeriods);

  return (
    <div className={styles.PeriodRowContainer}>
      <div className={styles.PeriodRow}>
        <div className={styles.Dates}>
          <DateField name={startFieldName} label="Du" validate={startDatePeriodValidator} />
          <DateField name={endFieldName} label="Au" validate={endDatePeriodValidator} />
        </div>
      </div>
      {onDelete ? (
        isMedium ? (
          <Button icon="trash" className={styles.TrashButton} onClick={() => onDelete()} />
        ) : (
          <Button stretch icon="trash" className={styles.TrashButton} onClick={() => onDelete()}>
            Supprimer l&apos;intervalle
          </Button>
        )
      ) : null}
    </div>
  );
}
