import type { ReactNode } from 'react';
import { CancellationRecordSuspendedDurationTemplate } from '../../../../components/ScreenTemplates/InformationTemplates/InsuranceRecordTemplates/CancellationRecordTemplates/CancellationRecordSuspendedDurationTemplate';

export function SecondaryTerminationRecordLicenseSuspendedDurationScreen(): ReactNode {
  return (
    <CancellationRecordSuspendedDurationTemplate
      fieldName="secondaryDriver.terminationRecords[0].cancellationOrSuspensionRecord.suspensionDuration"
      title="Combien de temps son permis a-t-il été suspendu&nbsp;?"
    />
  );
}
