import { ArrowLeftRegularIcon } from '@ornikar/kitt-icons/phosphor';
import { HStack, Icon, Pressable } from '@ornikar/kitt-universal';
import { type ReactNode, useEffect, useState } from 'react';
import { useGoBack } from '../../hooks/useGoBack';
import { CallbackHeaderButton } from '../CallbackHeaderButton';
import { Logo } from '../Logo';

export function LateOceanHeader(): ReactNode {
  const [isScrolled, setIsScrolled] = useState(false);
  const [, goBack] = useGoBack();

  useEffect(() => {
    const handleScroll: EventListener = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <HStack
      position="fixed"
      zIndex={1}
      width="100%"
      backgroundColor="kitt.palettes.lateOcean.lateOceanLight1"
      paddingX="kitt.4"
      paddingY="kitt.2"
      justifyContent="space-between"
      alignItems="center"
    >
      <Pressable padding={10} onPress={goBack}>
        <Icon icon={<ArrowLeftRegularIcon />} color="white" size="kitt.5" />
      </Pressable>
      {!isScrolled && (
        <a href="/assurance-auto" aria-label="Go to home page">
          <Logo variant="white" height={30} />
        </a>
      )}
      <CallbackHeaderButton variant="new" />
    </HStack>
  );
}
