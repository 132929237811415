import { IllustratedIcon, Light } from '@ornikar/illustrated-icons';
import { defineMessages } from 'react-intl';
import type { AddonInfoModalContentItemProps } from '../../../../components/AddonInfoModal/components/AddonInfoModalContentItem';
import { FormattedMessageWithRichTextFormatting } from '../../../../utils/intl';

export const messages = defineMessages({
  assistanceDetailPart1Text: {
    id: 'addons.assistance.detail.part_1.text',
    defaultMessage:
      '<li>tombez en panne (dont batterie ou pneu),</li><li>êtes victime d’un accident</li><li>subissez un vol.</li>',
  },
  assistanceDetailPart1Detail: {
    id: 'addons.assistance.detail.part_1.title',
    defaultMessage: 'Quand vous : ',
  },
  assistanceDetailPart2Text: {
    id: 'addons.assistance.detail.part_2.text',
    defaultMessage:
      "<li>l'assistance et le remorquage de votre véhicule en moins de 45 minutes en direction du garage le plus proche,</li><li>votre rapatriement et celui de vos passagers à domicile,</li><li>votre hébergement et celui pour vos passagers, si nécessaire.</li>",
  },
  assistanceDetailPart2Title: {
    id: 'addons.assistance.detail.part_2.title',
    defaultMessage: 'Nous assurons : ',
  },
  assistanceDetailPart3Text: {
    id: 'addons.assistance.detail.part_3.text',
    defaultMessage:
      "<li>Jusqu’à 180 € pour un remorquage en journée comme de nuit</li><li>Jusqu’à 80€ par nuit d'hébergement</li>",
  },
  assistanceDetailPart3Title: {
    id: 'addons.assistance.detail.part_3.title',
    defaultMessage: 'Quelle indemnisation ?',
  },
  assistanceDetailPrecisionText: {
    id: 'addons.assistance.detail.precision.text',
    defaultMessage:
      'Nous vous prêtons un véhicule quand le vôtre : <li>est immobilisé plus de 24h, </li><li>nécessite au moins 2h de réparation.</li>',
  },
  assistanceDetailPrecisionTitle: {
    id: 'addons.assistance.detail.precision.title',
    defaultMessage: "Si vous optez pour l'option Véhicule de remplacement",
  },
  assistanceDetailTipsTitle: {
    id: 'addons.assistance.detail.tips.title',
    defaultMessage: 'Notre conseil',
  },
  assistanceDetailTipsTxt: {
    id: 'addons.assistance.detail.tips.txt',
    defaultMessage:
      'On n’est jamais à l’abri des petits accidents du quotidien ! La panne le matin devant son garage, le problème de batterie... Cette garantie vous sera vraiment utile pour ne pas gonfler la note.',
  },
});

export const infoModalContent: AddonInfoModalContentItemProps[] = [
  {
    title: <FormattedMessageWithRichTextFormatting {...messages.assistanceDetailPart1Detail} />,
    description: <FormattedMessageWithRichTextFormatting {...messages.assistanceDetailPart1Text} />,
  },
  {
    title: <FormattedMessageWithRichTextFormatting {...messages.assistanceDetailPart2Title} />,
    description: <FormattedMessageWithRichTextFormatting {...messages.assistanceDetailPart2Text} />,
  },
  {
    icon: <IllustratedIcon icon={<Light />} />,
    title: <FormattedMessageWithRichTextFormatting {...messages.assistanceDetailTipsTitle} />,
    description: <FormattedMessageWithRichTextFormatting {...messages.assistanceDetailTipsTxt} />,
    isHighlighted: true,
  },
  {
    title: <FormattedMessageWithRichTextFormatting {...messages.assistanceDetailPart3Title} />,
    description: <FormattedMessageWithRichTextFormatting {...messages.assistanceDetailPart3Text} />,
  },
  {
    title: <FormattedMessageWithRichTextFormatting {...messages.assistanceDetailPrecisionTitle} />,
    description: <FormattedMessageWithRichTextFormatting {...messages.assistanceDetailPrecisionText} />,
    isHighlighted: true,
    variant: 'secondary',
  },
];
