import { DialogModal } from '@ornikar/kitt-universal';
import { format } from 'date-fns';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { InconsistencyModal } from '../../../../components/InconsistencyModal/InconsistencyModal';
import Illustration from '../assets/car-at-traffic-light.svg';

export interface DateInconsistencyModalProps {
  date?: Date;
  gender: 'male' | 'female';
  visible?: boolean;
  mainButtonClick: () => void;
  secondButtonClick: () => void;
  onClose: () => void;
}
export function DateInconsistencyModal({
  date,
  gender,
  visible,
  mainButtonClick,
  secondButtonClick,
  onClose,
}: DateInconsistencyModalProps): ReactNode {
  return (
    <DialogModal.ModalBehaviour visible={visible} onClose={onClose}>
      <InconsistencyModal
        title={
          <FormattedMessage
            id="dateinconsistencymodal.title"
            defaultMessage="Il y a une incohérence dans votre déclaration"
          />
        }
        content={
          date ? (
            <FormattedMessage
              id="dateinconsistencymodal.contentwithdate"
              defaultMessage="Si vous n’avez pas été {gender, select,
                female {assurée} 
                other {assuré}
              } depuis le {date}, votre véhicule ne peut pas être actuellement assuré à votre nom."
              values={{
                date: format(date, 'dd/MM/yyyy'),
                gender,
              }}
            />
          ) : (
            <FormattedMessage
              id="dateinconsistencymodal.contentwithoutdate"
              defaultMessage="Si vous n’êtes pas {gender, select, 
                female {assurée} 
                other {assuré}
              } aujourd’hui, votre véhicule ne peut pas être actuellement assuré à votre nom."
              values={{
                gender,
              }}
            />
          )
        }
        illustration={<img src={Illustration} alt="car at traffic light" width={148} />}
        mainButton={{
          label: (
            <FormattedMessage
              id="dateinconsistencymodal.noinsurance"
              defaultMessage="Mon véhicule n'est pas assuré à mon nom"
            />
          ),
          onClick: mainButtonClick,
        }}
        secondButton={{
          label: (
            <FormattedMessage id="dateinconsistencymodal.insurance" defaultMessage="Je suis actuellement assuré" />
          ),
          onClick: secondButtonClick,
        }}
      />
    </DialogModal.ModalBehaviour>
  );
}
