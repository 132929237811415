import type { ReactNode } from 'react';
import { CancellationRecordReasonTemplate } from '../../../../components/ScreenTemplates/InformationTemplates/InsuranceRecordTemplates/CancellationRecordTemplates/CancellationRecordReasonTemplate';
import { TerminationReason } from '../../../../fsm/answers';
import { useSubscriptionFsmState } from '../../../../fsm/context';

export function SecondaryTerminationRecordLicenseCanceledOrSuspendedReasonScreen(): ReactNode {
  const {
    context: {
      answers: { secondaryDriver },
    },
  } = useSubscriptionFsmState();

  const title = `Pour quel motif son permis a-t-il été ${
    secondaryDriver?.terminationRecords?.[0].reason === TerminationReason.DriversLicenseCancellation
      ? 'annulé'
      : 'suspendu'
  }\u00A0?`;

  return (
    <CancellationRecordReasonTemplate
      title={title}
      fieldName="secondaryDriver.terminationRecords[0].cancellationOrSuspensionRecord.reason"
    />
  );
}
