import { api } from '../../../apis/api';
import { getUtmParamsAsSearchParams } from '../../../utils/getUtmParams';

export const sendVisit = async (): Promise<void> => {
  const utmParams = getUtmParamsAsSearchParams();

  await api(`visit?${utmParams.toString()}`, {
    method: 'POST',
  });
};
