import type { IntlValidator } from '@ornikar/react-validators';
import {
  useComposeValidators,
  useRequiredCheckValidator,
  useRequiredValidator,
  useValidator,
} from '@ornikar/react-validators';
import { createRangeLengthValidator } from '@ornikar/validators';

const MIN_IBAN_LENGTH = 15;
const MAX_IBAN_LENGTH = 34;

export const useIbanValidator = (): IntlValidator<string> =>
  useComposeValidators(
    useRequiredValidator(),
    useValidator(createRangeLengthValidator({ min: MIN_IBAN_LENGTH, max: MAX_IBAN_LENGTH }), {
      id: 'PaymentInformationScreen.IBANFormatValidator',
      defaultMessage: "Le format de l'IBAN est invalide",
    }),
  );

export const useIbanCheckboxValidator = (): IntlValidator =>
  useRequiredCheckValidator({
    id: 'components.InsuranceSubscription.requiredCheckConditions',
    defaultMessage: 'Vous devez accepter les conditions',
  });

export const useIbanTitulaireValidator = (): IntlValidator<string> => useRequiredValidator();
