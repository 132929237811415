import { RadioButtonGroup, Typography, TypographyLink } from '@ornikar/kitt-universal';
import type { PropsWithChildren, ReactNode } from 'react';
import { useState } from 'react';
import type { QuoteOption } from '../../../apis/types/QuoteOptions';
import { deductibleNameMapping } from '../../../constants/options';
import type { DeductibleName, DeductibleTerm } from '../../../fsm/answers';
import { useSubscriptionFsmState } from '../../../fsm/context';
import { formatPrice } from '../../../utils/formatPrice';
import { AddonInfoModal } from '../../AddonInfoModal';
import type { AddonInfoModalContentItemProps } from '../../AddonInfoModal/components/AddonInfoModalContentItem';
import styles from './styles.module.css';

export interface DeductibleItemProps {
  icon: ReactNode;
  title: ReactNode;
  description: ReactNode;
  name: DeductibleName;
  options: QuoteOption[];
  infoModalTitle: ReactNode;
  infoModalSubtitle: ReactNode;
  infoModalContent: AddonInfoModalContentItemProps[];
  sendAnswer: (name: string, term: string, option: QuoteOption | undefined) => void;
}

export function DeductibleItem({
  icon,
  title,
  description,
  name,
  options,
  infoModalTitle,
  infoModalSubtitle,
  infoModalContent,
  sendAnswer,
}: PropsWithChildren<DeductibleItemProps>): ReactNode {
  const [isAddonInfoModalVisible, setIsAddonInfoModalVisible] = useState(false);

  const {
    context: { answers },
  } = useSubscriptionFsmState();

  const changeValue = (term: DeductibleTerm['value']): void => {
    const selectedOption = options.find((option) => option.term?.value === term);
    sendAnswer(name, term, selectedOption);
  };
  return (
    <>
      <div className={styles.DeductibleItem}>
        <div className={styles.Header}>
          {icon}
          <Typography.Text variant="bold">{title}</Typography.Text>
        </div>

        <Typography.Text>{description}</Typography.Text>

        <RadioButtonGroup
          stretch
          value={answers.deductibles?.[name]?.term.value}
          onChange={(value) => {
            changeValue(value);
          }}
        >
          {options.map(({ term, amount }) => (
            <RadioButtonGroup.RadioButton key={term?.value} value={term?.value}>
              <Typography.Text>{formatPrice(amount.cents)}€</Typography.Text>
            </RadioButtonGroup.RadioButton>
          ))}
        </RadioButtonGroup>

        {infoModalContent.length > 0 ? (
          <div className={styles.BottomRow}>
            <TypographyLink
              noUnderline
              role="button"
              variant="bold"
              color="primary"
              onPress={() => setIsAddonInfoModalVisible(true)}
            >
              En savoir plus
            </TypographyLink>
          </div>
        ) : null}
      </div>
      <AddonInfoModal
        addonName={deductibleNameMapping[name]}
        isVisible={isAddonInfoModalVisible}
        title={infoModalTitle}
        subtitle={infoModalSubtitle}
        subtitleIcon={icon}
        items={infoModalContent}
        onClose={() => setIsAddonInfoModalVisible(false)}
      />
    </>
  );
}
