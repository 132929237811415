import type {
  BloodAlcoholLevel,
  CancellationOrSuspensionReason,
  ClaimNature,
  NonPaymentTerminationInsurerCount,
  ResponsibilityLevel,
  SuspensionDuration,
  TerminationReason,
  UnderTheInfluenceOf,
  UninsuredVehicleOwnershipDuration,
} from '../../fsm/answers';
import type { APIDate } from './LongQuoteAnswers';

export enum ServerInsuranceRecordType {
  Termination = 'Termination',
  CancellationOrSuspension = 'CancellationOrSuspension',
  Claim = 'Claim',
}

interface AbstractDriverInsuranceRecord {
  recordType: ServerInsuranceRecordType;
}

export interface BasicClaim extends AbstractDriverInsuranceRecord {
  recordType: ServerInsuranceRecordType.Claim;
  type: ClaimNature;
  date: APIDate;
}

export interface ClaimWithAccidentWithClaimantAsDriver extends BasicClaim {
  claimantAsDriver: true;
  responsibilityLevel: ResponsibilityLevel;
  underTheInfluenceOf: UnderTheInfluenceOf;
  bloodAlcoholLevel?: BloodAlcoholLevel;
}

export interface ClaimWithAccidentWithoutClaimantAsDriver extends BasicClaim {
  claimantAsDriver: false;
  responsibilityLevel: ResponsibilityLevel;
}

export interface AbstractCancellationOrSuspension extends AbstractDriverInsuranceRecord {
  recordType: ServerInsuranceRecordType.CancellationOrSuspension;
  date: APIDate;
  isCancellation: boolean;
  suspensionDuration?: SuspensionDuration;
  hitAndRunOrRefusalToComply: boolean;
}

export type ServerClaimRecord =
  | BasicClaim
  | ClaimWithAccidentWithClaimantAsDriver
  | ClaimWithAccidentWithoutClaimantAsDriver;

export type ServerCancellationOrSuspensionRecord =
  | CancellationOrSuspensionWithoutClaim
  | CancellationOrSuspensionWithClaim;

export interface CancellationOrSuspensionWithoutClaim extends AbstractCancellationOrSuspension {
  isDueToAccident: false;
  reason: CancellationOrSuspensionReason;
  bloodAlcoholLevel?: BloodAlcoholLevel;
}

export interface CancellationOrSuspensionWithClaim extends AbstractCancellationOrSuspension {
  isDueToAccident: true;
  claim: ClaimWithAccidentWithClaimantAsDriver;
}

export interface ServerTerminationRecord extends AbstractDriverInsuranceRecord {
  recordType: ServerInsuranceRecordType.Termination;
  date: APIDate;
  reason: TerminationReason;
  uninsuredVehicleOwnershipDuration: UninsuredVehicleOwnershipDuration;
  nonPaymentTerminationInsurerCount?: NonPaymentTerminationInsurerCount;
  cancellationOrSuspension?: ServerCancellationOrSuspensionRecord;
}

export type ServerInsuranceRecord = ServerTerminationRecord | ServerCancellationOrSuspensionRecord | ServerClaimRecord;
