import { addYears, setMonth } from 'date-fns';
import type { VehicleCurrentContractAnniversaryMonthType } from '../../../fsm/answers';

export function getNextOccurringMonth(month: VehicleCurrentContractAnniversaryMonthType): Date {
  const today = new Date(Date.now());
  const targetMonthDate = setMonth(today, month - 1);

  if (((today.getMonth() + 1) as VehicleCurrentContractAnniversaryMonthType) >= month) {
    return addYears(targetMonthDate, 1);
  }

  return targetMonthDate;
}
