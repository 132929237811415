import { Input } from '@ornikar/kitt';
import { customProperties } from '@ornikar/kitt/dist/theme-late-ocean';
import type { DayValue, Locale } from '@ornikar/react-modern-calendar-datepicker';
import ModernDatePicker from '@ornikar/react-modern-calendar-datepicker';
import type { ChangeEvent, ReactNode } from 'react';
import { useEffect, useMemo, useState } from 'react';
import type { DateType } from '../../fsm/answers';
import styles from './styles.module.css';

const customLocale: Locale = {
  // months list by order
  months: [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ],

  weekDays: [
    {
      name: 'Lundi',
      short: 'L',
    },
    {
      name: 'Mardi',
      short: 'M',
    },
    {
      name: 'Mercredi',
      short: 'M',
    },
    {
      name: 'Jeudi',
      short: 'J',
    },
    {
      name: 'Vendredi',
      short: 'V',
    },
    {
      name: 'Samedi',
      short: 'S',
      isWeekend: true,
    },
    {
      name: 'Dimanche',
      short: 'D',
      isWeekend: true,
    },
  ],

  weekStartingIndex: 6,

  getToday(gregorainTodayObject) {
    return gregorainTodayObject;
  },

  toNativeDate(date) {
    return new Date(date.year, date.month - 1, date.day);
  },

  getMonthLength(date) {
    return new Date(date.year, date.month, 0).getDate();
  },

  transformDigit(digit) {
    return digit;
  },

  nextMonth: 'Mois suivant',
  previousMonth: 'Mois précédent',
  openMonthSelector: 'Ouvrir le sélecteur de mois',
  openYearSelector: "Ouvrir le sélecteur d'année",
  closeMonthSelector: 'Fermer le sélecteur de mois',
  closeYearSelector: "Fermer le sélecteur d'année",
  defaultPlaceholder: 'Sélectionner...',

  // for input range value
  from: 'du',
  to: 'au',

  // used for input value when multi dates are selected
  digitSeparator: ',',

  // if your provide -2 for example, year will be 2 digited
  yearLetterSkip: 0,

  // is your language rtl or ltr?
  isRtl: false,
};

interface DatePickerProps {
  name: string;
  defaultValue?: DateType;
  value?: DateType;
  minDate?: DateType | Date;
  maxDate?: DateType | Date;
  onFocus?: (e: FocusEvent) => void;
  onBlur: () => void;
  onChange: (value: DateType, e?: ChangeEvent<DateType>) => void;
}

export function DatePicker({
  name,
  value,
  defaultValue,
  minDate = '1970-01-01',
  maxDate = '3000-01-01',
  onFocus,
  onChange,
  onBlur,
}: DatePickerProps): ReactNode {
  const [selectedDay, setSelectedDay] = useState<DayValue>();

  const formattedMinDate = useMemo(() => {
    if (typeof minDate === 'string') {
      return new Date(minDate);
    }

    return minDate;
  }, [minDate]);

  const formattedMaxDate = useMemo(() => {
    if (typeof maxDate === 'string') {
      return new Date(maxDate);
    }

    return maxDate;
  }, [maxDate]);

  useEffect(() => {
    if (defaultValue) {
      onChange(defaultValue);
    }
  }, [defaultValue, onChange]);

  useEffect(() => {
    if (value && value.length === 10) {
      const selectedDate = new Date(value);

      if (selectedDate.toString() === 'Invalid Date') {
        setSelectedDay(undefined);
        return;
      }

      const day = selectedDate.getDate();
      const month = selectedDate.getMonth() + 1;
      const year = selectedDate.getFullYear();

      setSelectedDay({
        day,
        month,
        year,
      });
    } else {
      setSelectedDay(undefined);
    }
  }, [value, setSelectedDay]);

  const minimumDate = {
    day: formattedMinDate.getDate(),
    month: formattedMinDate.getMonth() + 1,
    year: formattedMinDate.getFullYear(),
  };

  return (
    <ModernDatePicker
      inputName={name}
      wrapperClassName={styles.DatePickerOverrides}
      value={selectedDay}
      calendarPopperPosition="bottom"
      minimumDate={minimumDate}
      maximumDate={{
        day: formattedMaxDate.getDate(),
        month: formattedMaxDate.getMonth() + 1,
        year: formattedMaxDate.getFullYear(),
      }}
      locale={customLocale}
      focusedDate={formattedMinDate > new Date() ? minimumDate : undefined}
      colorPrimary={customProperties['--kitt-primary-color']}
      colorPrimaryLight={customProperties['--kitt-primary-color']}
      renderInput={({ ref }) => (
        <>
          <Input ref={ref} className={styles.HiddenInput} />
          <div className={styles.DateInput}>
            <Input
              adaptive
              type="date"
              name={name}
              value={value}
              placeholder="AAAA-MM-JJ"
              onChange={onChange}
              onBlur={(newValue: string) => {
                if (newValue) {
                  const [year, month, day] = newValue.split('-');
                  onChange(`${year}-${month ? month.padStart(2, '0') : ''}-${day ? day.padStart(2, '0') : ''}`);
                  onBlur();
                }
              }}
              onFocus={onFocus}
            />
          </div>
        </>
      )}
      onChange={(newDay: DayValue) => {
        if (newDay) {
          onChange(
            `${newDay.year}-${newDay.month.toString().padStart(2, '0')}-${newDay.day.toString().padStart(2, '0')}`,
          );
          onBlur();
        }
      }}
    />
  );
}
