import { Button, HStack, LoaderIcon, Pressable, Typography, VStack, View } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import type { Package, PackageCoverage } from '../../../../../../apis/types/Formula';
import { PaymentPeriodicity } from '../../../../../../apis/types/PaymentOptions';
import { useMediaQuery } from '../../../../../../hooks/useMediaQuery';
import { formatPrice } from '../../../../../../utils/formatPrice';
import { getPaymentPeriodicityAmount } from '../../../../../../utils/price';
import { PackageElement } from './PackageElement';

const getPriceSplitted = (price: number | undefined): { priceEuros: string; priceCents: string } => {
  if (!price) {
    return { priceEuros: '0', priceCents: '00' };
  }
  const priceSplitted = formatPrice(price).split(',');
  return { priceEuros: priceSplitted[0], priceCents: priceSplitted.length === 2 ? priceSplitted[1] : '00' };
};

export interface TabProps {
  formula: Package;
  coverages: PackageCoverage[] | undefined;
  showInfoModal: (coverage: PackageCoverage) => void;
  paymentPeriodicity: PaymentPeriodicity;
  onClick: (formula: Package) => void;
  selected: boolean;
  isLoading: boolean;
}

export function Tab({
  formula: currentPackage,
  coverages,
  showInfoModal,
  paymentPeriodicity,
  onClick,
  selected,
  isLoading,
}: TabProps): ReactNode {
  const packageElements = coverages?.map((coverage) => (
    <PackageElement
      key={coverage.label}
      coverage={coverage}
      isAvailable={currentPackage.coverages.some((val) => val.label === coverage.label)}
      showInfoModal={showInfoModal}
    />
  ));

  const price = getPaymentPeriodicityAmount(paymentPeriodicity, currentPackage);

  const { priceEuros, priceCents } = getPriceSplitted(price);

  const titleOnTwoLines = useMediaQuery('(min-width: 1400px)');

  const titlePart = (isHovered: boolean): ReactNode => {
    return (
      <>
        <View marginRight="kitt.4">
          <Typography.h4 color={isHovered || selected ? 'primary' : 'black'}>{currentPackage.name}</Typography.h4>
        </View>
        <View>
          <HStack alignItems="baseline">
            <Typography.h3>{priceEuros}</Typography.h3>
            <Typography.h4>,{priceCents}&nbsp;€ </Typography.h4>
            <Typography.Paragraph base="body-xsmall">
              {paymentPeriodicity === PaymentPeriodicity.MONTHLY ? '/mois' : '/an'}
            </Typography.Paragraph>
          </HStack>
        </View>
      </>
    );
  };

  return (
    <Pressable
      _hover={{
        backgroundColor: 'kitt.palettes.lateOcean.moonPurpleLight1',
        borderColor: 'kitt.palettes.lateOcean.moonPurpleLight1',
      }}
      style={{ transitionDuration: '0.3s', transform: selected ? 'scale(1.02)' : undefined }}
      borderRadius="kitt.5"
      padding="kitt.6"
      paddingTop="kitt.4"
      backgroundColor="kitt.white"
      flexGrow={1}
      flexShrink={1}
      onPress={() => {
        onClick(currentPackage);
      }}
    >
      {({ isHovered }) => (
        <VStack space="kitt.6">
          <VStack space="kitt.4">
            {titleOnTwoLines ? (
              <HStack alignItems="baseline" justifyContent="space-between">
                {titlePart(isHovered)}
              </HStack>
            ) : (
              <VStack alignItems="baseline" justifyContent="space-between">
                {titlePart(isHovered)}
              </VStack>
            )}

            <Button
              stretch
              type="primary"
              icon={isLoading && selected ? <LoaderIcon /> : undefined}
              disabled={isLoading}
              onPress={() => {
                onClick(currentPackage);
              }}
            >
              {isLoading && selected ? 'Patientez' : 'Choisir cette formule'}
            </Button>
          </VStack>
          <VStack space="kitt.6">{packageElements}</VStack>
        </VStack>
      )}
    </Pressable>
  );
}
