import { useSubscriptionFsmState, useSubscriptionFsmStateValue } from '../../fsm/context';
import type { State } from '../../fsm/types';
import type { FormValues } from '../answers';
import { useDrivingSubmissions } from './sections/driving';
import { useInformationSubmissions } from './sections/information';
import { useInsuranceSubmissions } from './sections/insurance';
import { useVehicleSubmissions } from './sections/vehicle';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useSubmission = () => {
  const fsmState = useSubscriptionFsmStateValue();
  const { context } = useSubscriptionFsmState();

  const vehicleSubmission = useVehicleSubmissions();
  const drivingSubmission = useDrivingSubmissions();
  const informationSubmission = useInformationSubmissions();
  const insuranceSubmission = useInsuranceSubmissions();

  const submissions = {
    ...vehicleSubmission,
    ...drivingSubmission,
    ...informationSubmission,
    ...insuranceSubmission,
  };

  const submit = async (formValues: Partial<FormValues>) => {
    const submission = submissions[fsmState as State];
    if (submission) {
      const res = await submission(formValues, context.answers);
      return res || undefined;
    }
    return undefined;
  };

  return submit;
};
