import { useComposeValidators, useDateValidator, useRequiredValidator } from '@ornikar/react-validators';
import type { ReactNode } from 'react';
import { DateField } from '../../../components/DateField';
import { ScreenTemplateWithValidation } from '../../../components/ScreenTemplates/ScreenTemplateWithValidation';
import {
  useDateAfterNYearsAgoValidator,
  useDateAfterThreeYearsAgoValidator,
  useDateBeforeTomorrowValidator,
} from '../../../forms/validation/sharedValidators';
import { useSubscriptionFsmState } from '../../../fsm/context';
import type { Driver } from '../../../fsm/types';
import type { DriverScreenProps } from '../../types/DriverScreenProps';

interface ClaimRecordDateScreenProps extends DriverScreenProps {
  linkedToATermination?: boolean;
  linkedToACancellationOrSuspension?: boolean;
}

const useClaimDateWithin3YearsValidator = () =>
  useComposeValidators(
    useRequiredValidator(),
    useDateValidator(),
    useDateAfterThreeYearsAgoValidator(),
    useDateBeforeTomorrowValidator(),
  );

const useClaimDateWithin5YearsValidator = () =>
  useComposeValidators(
    useRequiredValidator(),
    useDateValidator(),
    useDateAfterNYearsAgoValidator(5),
    useDateBeforeTomorrowValidator(),
  );

export function ClaimRecordDateScreen({
  driver,
  linkedToATermination,
  linkedToACancellationOrSuspension,
}: ClaimRecordDateScreenProps): ReactNode {
  const dateWithin3YearsValidator = useClaimDateWithin3YearsValidator();
  const dateWithin5YearsValidator = useClaimDateWithin5YearsValidator();

  const title =
    linkedToATermination || linkedToACancellationOrSuspension
      ? 'À quelle date cet accident est-il survenu\u00A0?'
      : 'A quelle date a-t-il eu lieu\u00A0?';

  const {
    context: {
      editingInsuranceRecord: { index },
    },
  } = useSubscriptionFsmState();

  const driverKey: `${Driver}Driver` = `${driver}Driver`;

  const fieldName = linkedToATermination
    ? `${driverKey}.terminationRecords[0].cancellationOrSuspensionRecord.claimRecord.date`
    : linkedToACancellationOrSuspension
    ? `${driverKey}.cancellationOrSuspensionRecords[${index}].claimRecord.date`
    : `${driverKey}.claimRecords[${index}].date`;

  return (
    <ScreenTemplateWithValidation
      fieldNames={[fieldName]}
      title={title}
      highlightTitle="Où trouver l'historique&nbsp;?"
      highlightContent="L'historique de vos sinistres se trouve sur votre Relevé d'Information"
    >
      <DateField
        name={fieldName}
        validate={
          linkedToATermination || linkedToACancellationOrSuspension
            ? dateWithin5YearsValidator
            : dateWithin3YearsValidator
        }
      />
    </ScreenTemplateWithValidation>
  );
}
