import type { DependencyList } from 'react';
import { useEffect, useState } from 'react';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';

interface UseAsyncDebouncedMemoOptions {
  cancelable: boolean;
}

export const useAsyncDebouncedMemo = <ReturnType>(
  callback: (signal: AbortSignal) => Promise<ReturnType>,
  delay: number,
  dependencies: DependencyList,
  options: UseAsyncDebouncedMemoOptions,
): ReturnType | undefined => {
  const [value, setValue] = useState<ReturnType>();

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const timeout = setTimeout(() => {
      callback(signal)
        .then(setValue)
        .catch((error) => {
          if (error.name !== 'AbortError') {
            throw error;
          }
        });
    }, delay);

    return () => {
      clearTimeout(timeout);
      if (options.cancelable) {
        controller.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(dependencies || []), options.cancelable, delay]);

  return value;
};
