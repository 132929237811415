import { ScreenTitles, Section } from '../../fsm/types';

export interface SectionConfig<T> {
  percent: number;
  copy: string;
  section: T;
}

export const sectionsConfig: SectionConfig<Section>[] = [
  {
    percent: 13.24,
    copy: ScreenTitles[Section.VEHICULE],
    section: Section.VEHICULE,
  },
  {
    percent: 28.11,
    copy: ScreenTitles[Section.DRIVING],
    section: Section.DRIVING,
  },
  {
    percent: 45.41,
    copy: ScreenTitles[Section.PRIMARY_DRIVER],
    section: Section.PRIMARY_DRIVER,
  },
  {
    percent: 70.22,
    copy: ScreenTitles[Section.SECONDARY_DRIVER],
    section: Section.SECONDARY_DRIVER,
  },
  {
    percent: 89.57,
    copy: ScreenTitles[Section.INFORMATION],
    section: Section.INFORMATION,
  },
  {
    percent: 100,
    copy: ScreenTitles[Section.TARIFICATION],
    section: Section.TARIFICATION,
  },
];
