import { useRequiredValidator } from '@ornikar/react-validators';
import type { ReactNode } from 'react';
import { useEffect } from 'react';
import { useField } from 'react-final-form';
import type { Except } from 'type-fest';
import { CancellationOrSuspensionOrClaim, makeChoices } from '../../../../../fsm/answers';
import type { RadioScreenTemplateProps } from '../../../RadioScreenTemplate';
import { RadioScreenTemplate } from '../../../RadioScreenTemplate';

interface AddInsuranceRecordTemplateProps
  extends Except<RadioScreenTemplateProps<CancellationOrSuspensionOrClaim>, 'choices' | 'fieldName'> {
  onAddSuspension: () => void;
  onAddCancellation: () => void;
  onAddClaim: () => void;
}

const fieldName = '_addInsuranceRecord';

const choices = makeChoices(CancellationOrSuspensionOrClaim, [
  [CancellationOrSuspensionOrClaim.Suspension, 'Une suspension'],
  [CancellationOrSuspensionOrClaim.Cancellation, 'Une annulation'],
  [CancellationOrSuspensionOrClaim.Claim, 'Un sinistre'],
]);

export function AddInsuranceRecordTemplate({
  title,
  onAddSuspension,
  onAddCancellation,
  onAddClaim,
}: AddInsuranceRecordTemplateProps): ReactNode {
  const requiredValidator = useRequiredValidator();
  const {
    input: { value, onChange },
  } = useField(fieldName);

  const insuranceRecordType = value as CancellationOrSuspensionOrClaim;

  // Reset value as it is temporary
  useEffect(() => {
    return () => {
      onChange(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RadioScreenTemplate<CancellationOrSuspensionOrClaim>
      fieldName={fieldName}
      choices={choices}
      title={title}
      validate={requiredValidator}
      onSubmit={() => {
        switch (insuranceRecordType) {
          case CancellationOrSuspensionOrClaim.Cancellation:
            onAddCancellation();
            break;
          case CancellationOrSuspensionOrClaim.Suspension:
            onAddSuspension();
            break;
          case CancellationOrSuspensionOrClaim.Claim:
            onAddClaim();
            break;
          default:
            throw new Error(`Submitted unknown answer: ${String(insuranceRecordType)}`);
        }
      }}
    />
  );
}
