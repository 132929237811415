import { useMemo } from 'react';

export const useVehicleCoverageMinDate = (vehicleAcquisition: string): Date =>
  useMemo(() => {
    const today = new Date();
    const acquisitionDate = new Date(vehicleAcquisition!);

    if (today.getTime() - acquisitionDate.getTime() >= 0) {
      return today;
    }

    return acquisitionDate;
  }, [vehicleAcquisition]);
