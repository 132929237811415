import type { IntlValidator } from '@ornikar/react-validators';
import { useRequiredCheckValidator, useRequiredValidator, useValidator } from '@ornikar/react-validators';
import type { ValidatorCreator, ValidatorReturnType } from '@ornikar/validators';
import { invalidTypeSymbol } from '@ornikar/validators';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  hamonWantValidator: {
    id: 'HamonWant.hamonWantValidator',
    defaultMessage: 'Votre accord est nécéssaire pour résilier votre contrat actuel.',
  },
});

export const createExactValueValidator: ValidatorCreator<string> =
  (exactValue) =>
  (value): ValidatorReturnType => {
    if (value === exactValue) return undefined;
    return invalidTypeSymbol;
  };

export const useSingleHamonWantValidator = (): IntlValidator =>
  useValidator(createExactValueValidator('1'), messages.hamonWantValidator);

export const useDualHamonWantValidator = (): IntlValidator => useRequiredValidator();
export const useHamonContractRefValidator = (): IntlValidator => useRequiredValidator();
export const useHamonNameValidator = (): IntlValidator => useRequiredValidator();
export const useHamonCheckboxValidator = (): IntlValidator => useRequiredCheckValidator(messages.hamonWantValidator);
