import type { DoneInvokeEvent } from 'xstate';
import { AssureDepuisType } from '../../apis/types/LongQuoteAnswers';
import type { TarificationResult, TarificationResultKO, TarificationResultOK } from '../../apis/types/Quotation';
import type { Context } from '../types';

export const tarificationFailed = (_: Context, event: DoneInvokeEvent<TarificationResult>): boolean => {
  return event.data.isOk === false;
};

export const noProviderAvailable = (_: Context, event: DoneInvokeEvent<TarificationResultKO>): boolean => {
  return event.data.error === 'No provider available at the moment';
};

export const isInsuredSinceLessThanAYear = (context: Context): boolean => {
  // TODO [>1]: get this information from quote response
  return context.answers.vehiculeAssureDepuis === AssureDepuisType.Moins12Mois;
};

export const needSalesValidation = (context: Context): boolean => {
  return context.devisCreationResult?.validationNeeded || false;
};

export const hasUnderwritingIssues = (_: Context, event: DoneInvokeEvent<TarificationResultOK>): boolean => {
  return event.data.hasUnderwritingIssues === true;
};
