export type PackageCode = string;

export interface PackageCoverage {
  deductibles: string[];
  description: string;
  label: string;
  reimbursements: string[];
}

export interface Amount {
  cents: number;
  currency: string;
}

export type PaymentPeriodicity = 'monthly' | 'yearly';

// ⚠️ To be synced with source enum backend-side
export enum OrnikarPackageLevel {
  THIRD_PARTY = 'Tiers',
  INTERMEDIARY = 'Intermédiaire',
  ALL_RISK = 'Tous risques',
}

export interface Package {
  reference: string;
  code: PackageCode;
  name: OrnikarPackageLevel;
  product: string;
  isRecommended?: boolean;
  isMonthlyPaymentAllowed: boolean;
  yearlyPrice: Amount;
  monthlyPrice?: Amount;
  submissionId: string;
  coverages: PackageCoverage[];
  annualFees: Amount;
}
