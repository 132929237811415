import { VStack } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { DateField } from '../../../components/DateField';
import { ScreenTemplateWithValidation } from '../../../components/ScreenTemplates/ScreenTemplateWithValidation';
import { ExitDoorLink } from '../../../components/exitDoor/ExitDoorLink';
import { useVehiculeMiseEnCirculationValidator } from '../../../forms/validation/sections/vehicle/vehiculeMiseEnCirculation';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { Event } from '../../../fsm/types';

const fieldName = 'vehiculeMiseEnCirculation';

export function VehicleCirculationDateScreen(): ReactNode {
  const { context } = useSubscriptionFsmState();
  const send = useSubscriptionFsmDispatch();
  const vehiculeMiseEnCirculationValidator = useVehiculeMiseEnCirculationValidator(
    context.answers.vehiculeAcquisition!,
  );

  const handleNoCirculationDate = (): void => {
    send(Event.DEFAULT_ANSWER);
  };

  return (
    <ScreenTemplateWithValidation
      fieldNames={[fieldName]}
      title="Quelle est sa date de mise en circulation&nbsp;?"
      highlightTitle="Où trouver la date de mise en circulation ?"
      highlightContent={
        <div>
          Rendez-vous au <b>repère B</b> de votre <b>carte grise (certificat d’immatriculation)</b> juste à côté du
          numéro d&apos;immatriculation du véhicule.
        </div>
      }
    >
      <VStack space="kitt.4" marginBottom={{ base: 'kitt.8', large: 0 }}>
        <DateField name={fieldName} validate={vehiculeMiseEnCirculationValidator} />
        <ExitDoorLink onPress={handleNoCirculationDate}>Je ne connais pas la date de mise en circulation.</ExitDoorLink>
      </VStack>
    </ScreenTemplateWithValidation>
  );
}
