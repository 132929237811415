import { Typography } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import Lottie from 'react-lottie';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { carData } from './car';
import styles from './styles.module.css';

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: carData('#4c34e0'),
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export function TarificationLoadingScreen(): ReactNode {
  const isTablet = useMediaQuery('(min-width: 600px)');

  return (
    <div className={styles.TarificationLoadingScreen}>
      <Lottie options={lottieOptions} height={130} width={isTablet ? 400 : 300} />
      <div className={styles.Message}>
        <Typography.Text base="header3">
          Attachez votre ceinture, votre tarif arrive dans quelques secondes !
        </Typography.Text>
      </div>
    </div>
  );
}
