import { KittThemeProvider } from '@ornikar/kitt-universal';
import { NotificationProvider } from '@ornikar/react-notification';
import * as Sentry from '@sentry/react';
import type { ReactNode } from 'react';
import { ToastProvider } from './components/Toast/ToastProvider';
import { ContactModalProvider } from './contexts/contactModal';
import { AnalyticsProvider } from './providers/AnalyticsProvider';
import { InsuranceSubscriptionProvider } from './providers/InsuranceSubscriptionProvider';
import { IntlProvider } from './providers/IntlProvider';

export function App(): ReactNode {
  return (
    <AnalyticsProvider>
      <KittThemeProvider>
        <Sentry.ErrorBoundary>
          <IntlProvider>
            <ContactModalProvider>
              <NotificationProvider>
                <ToastProvider>
                  <InsuranceSubscriptionProvider />
                </ToastProvider>
              </NotificationProvider>
            </ContactModalProvider>
          </IntlProvider>
        </Sentry.ErrorBoundary>
      </KittThemeProvider>
    </AnalyticsProvider>
  );
}
