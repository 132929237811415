import cx from 'classnames';
import type { ReactNode } from 'react';
import type { PhoneInputProps as ReactPhoneInputProps } from 'react-phone-input-2';
import ReactPhoneInput from 'react-phone-input-2';
import fr from 'react-phone-input-2/lang/fr.json';
import type { Except } from 'type-fest';
import styles from './styles.module.css';

interface PhoneInputProps
  extends Except<ReactPhoneInputProps, 'inputProps' | 'countryCodeEditable' | 'localization' | 'country'> {
  name: string;
  dropdownClassname?: string;
  onChange: (event: any) => void;
  onBlur: (event: any) => void;
}

export function PhoneInput({ name, dropdownClassname, onChange, onBlur, ...props }: PhoneInputProps): ReactNode {
  return (
    <ReactPhoneInput
      {...props}
      inputProps={{
        name,
        onBlur,
      }}
      countryCodeEditable={false}
      masks={{ fr: '..........' }}
      localization={fr}
      country="fr"
      inputClass={styles.Input}
      buttonClass={styles.Button}
      dropdownClass={cx(styles.Dropdown, dropdownClassname)}
      containerClass={styles.Container}
      onChange={(value, data, event) => {
        if (event.type === 'change') {
          onChange(event);
          return;
        }

        if ('countryCode' in data) {
          onChange(`+${value}`);
        }
      }}
    />
  );
}
