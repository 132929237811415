import { isAfter, startOfToday } from 'date-fns';
import { useSubscriptionFsmState } from '../fsm/context';
import type { Driver } from '../fsm/types';

export const useIsLicenseDateInFuture = (driver: Driver): boolean => {
  const { context } = useSubscriptionFsmState();

  const licenseDate = context.answers[`${driver}Driver`]?.licenseDate;

  if (!licenseDate) {
    return false;
  }

  return isAfter(new Date(licenseDate), startOfToday());
};
