import type { ReactNode } from 'react';
import { ParkingType } from '../../../apis/types/LongQuoteAnswers';
import { RadioScreenTemplate } from '../../../components/ScreenTemplates/RadioScreenTemplate';
import { makeChoices } from '../../../fsm/answers';

const parkingChoices = makeChoices(ParkingType, [
  [ParkingType.public, 'Dans la rue ou dans un parking public'],
  [
    ParkingType.parking,
    "Dans un parking privé clos (par exemple le parking d'une résidence à ciel ouvert mais fermé par une grille)",
  ],
  [ParkingType.garage, 'Dans un box privé clos'],
]);

export function DrivingVehicleParkingScreen(): ReactNode {
  return (
    <RadioScreenTemplate
      title="Le soir, où sera garé votre véhicule&nbsp;?"
      highlightTitle="Besoin de précision ?"
      highlightContent={
        <div>
          Votre réponse nous permettra de vous proposer le niveau de remboursement le plus adapté aux risques encourus
          par votre véhicule.
        </div>
      }
      fieldName="parkingLocation"
      choices={parkingChoices}
    />
  );
}
