import { addMinutes, startOfDay } from 'date-fns';

export type Time = `${number}:${number}`;

export type ActiveHours = [Time, Time][];

/**
 *
 * @param time - Represents an hour in the format "hh:mm"
 * @returns the number of minutes elapsed since 0:00
 */
const fromTimeToElapsedMinutesToday = (time: Time): number => {
  const [hours, minutes] = time.split(':').map((v) => parseInt(v, 10));
  return typeof hours === 'number' && typeof minutes === 'number' ? hours * 60 + minutes : 0;
};

/**
 *
 * @param daySlot - day slot as Date
 * @param hourSlot - hour slot as [Time, Time]
 * @returns a date built from given daySlot and timeSlot
 */
export const buildDateTimeFromSlots = (daySlot: Date, hourSlot: [Time, Time]): Date => {
  const day = startOfDay(daySlot);
  const slotBegginningInMinutes = fromTimeToElapsedMinutesToday(hourSlot[0]);
  return addMinutes(day, slotBegginningInMinutes);
};
