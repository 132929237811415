import type { IntlValidator } from '@ornikar/react-validators';
import { useValidator } from '@ornikar/react-validators';
import type { Validator } from '@ornikar/validators';
import { invalidTypeSymbol } from '@ornikar/validators';
import { defineMessages } from 'react-intl';
import type { GeocodingDto } from '../../../../apis/getAddressSuggestions';

const messages = defineMessages({
  autocompleteAddress: {
    id: 'validators.autocompleteAddress.invalid',
    defaultMessage: 'Veuillez rentrer une adresse valide',
  },
});

const autocompleteAddressValidatorFunction: Validator<GeocodingDto> = (value) => {
  const valid = value && Object.keys(value).every(Boolean);

  if (valid) {
    return undefined;
  }
  return invalidTypeSymbol;
};

export const useAutocompleteAddressValidator = (): IntlValidator<GeocodingDto> =>
  useValidator(autocompleteAddressValidatorFunction, messages.autocompleteAddress);
