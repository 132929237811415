import type { ReactNode } from 'react';
import { useField } from 'react-final-form';
import { ScreenTemplate } from '../../../components/ScreenTemplates/ScreenTemplate';
import { VehiculePreSelectionType } from '../../../fsm/answers';
import { useSubscriptionFsmState } from '../../../fsm/context';
import { useSubmitWithDelay } from '../../../hooks/useSubmitWithDelay';
import Car from './assets/car.svg';
import Immatriculation from './assets/immatriculation.svg';
import { ChoiceButton } from './components/ChoiceButton';
import styles from './styles.module.css';

const choices = [
  {
    illustration: <img src={Immatriculation} alt="Illustration d'une plaque d'immatriculation" />,
    title: 'Rechercher par plaque',
    subtitle: "C'est plus rapide !",
    value: VehiculePreSelectionType.LICENSE_PLATE,
  },
  {
    illustration: <img src={Car} alt="Illustration d'une voiture" />,
    title: 'Rechercher par marque',
    value: VehiculePreSelectionType.BRAND,
  },
];

export function VehicleSearchChoiceScreen(): ReactNode {
  const { context } = useSubscriptionFsmState();
  const submitWithDelay = useSubmitWithDelay();

  const {
    input: { onChange, value: selection },
  } = useField('vehiculePreSelectionType', {
    subscription: {
      value: true,
    },
  });

  const handleSelect = (value: VehiculePreSelectionType): void => {
    onChange(value);
    submitWithDelay();
  };

  const title = context.answers.primaryDriver?.firstName
    ? `Bienvenue ${context.answers.primaryDriver.firstName}\u00A0! Commençons par trouver votre véhicule.`
    : 'Bienvenue\u00A0! Commençons par trouver votre véhicule.';

  return (
    <ScreenTemplate hideSubmitButton={context.answers.vehiculePreSelectionType === undefined} title={title}>
      <div className={styles.Choices}>
        {choices.map(({ value, ...rest }) => (
          <ChoiceButton {...rest} key={value} isSelected={selection === value} onSelect={() => handleSelect(value)} />
        ))}
      </div>
    </ScreenTemplate>
  );
}
