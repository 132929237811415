import type { ReactNode } from 'react';
import { RecapScreenTemplate } from '../../../../components/ScreenTemplates/RecapScreenTemplate';
import { useSubscriptionFsmDispatch } from '../../../../fsm/context';
import { Driver, Event } from '../../../../fsm/types';
import { InsuranceRecordSummaryV2 } from '../../InsuranceRecordSummaryV2';
import SteeringWheelIllustration from './assets/steering-wheel.svg';

export function PrimaryInsuranceRecordSummaryScreen(): ReactNode {
  const send = useSubscriptionFsmDispatch();

  return (
    <RecapScreenTemplate
      title="Mon historique"
      illustration={<img src={SteeringWheelIllustration} alt="Illustration d'un volant" />}
      onConfirm={() => send(Event.CONTINUE)}
    >
      <InsuranceRecordSummaryV2 driverType={Driver.Primary} />
    </RecapScreenTemplate>
  );
}
