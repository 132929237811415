import type { ReactNode } from 'react';
import { RadioScreenTemplate } from '../../../components/ScreenTemplates/RadioScreenTemplate';
import { DeclareSecondaryDriverType, makeChoices } from '../../../fsm/answers';

const declareSecondaryDriverChoices = makeChoices(DeclareSecondaryDriverType, [
  [DeclareSecondaryDriverType.Oui, 'Oui'],
  [DeclareSecondaryDriverType.Non, 'Non'],
]);

export function InformationDeclareSecondaryDriverScreen(): ReactNode {
  return (
    <RadioScreenTemplate
      title="Souhaitez-vous qu'un autre conducteur bénéficie de vos garanties&nbsp;?"
      highlightTitle="Notre conseil"
      highlightContent="Répondez oui si une autre personne de votre entourage conduira régulièrement le véhicule. Cela vous permettra d'être remboursé en cas de pépin, que vous soyez au volant ou non."
      fieldName="declareSecondaryDriver"
      choices={declareSecondaryDriverChoices}
    />
  );
}
