import type { IntlValidator } from '@ornikar/react-validators';
import { useComposeValidators, useDateValidator, useRequiredValidator, useValidator } from '@ornikar/react-validators';
import type { Validator } from '@ornikar/validators';
import { createValidDateAfterValidator, createValidDateBeforeValidator } from '@ornikar/validators';
import { format, parse, subYears } from 'date-fns';
import { YesNoQuestion } from '../../../../fsm/answers';
import { validatorMessages } from '../../messages';
import { TOMORROW } from '../../sharedValidators';

const MINIMUM_BIRTHDATE = '1899-12-31';

const createPastDateValidator = () => createValidDateBeforeValidator(() => TOMORROW);
const createBeforeDateValidator = () =>
  createValidDateAfterValidator(() => format(new Date(MINIMUM_BIRTHDATE), 'yyyy-MM-dd'));

export const createDateYearDiffWithAccompaniedDrivingValidator = (
  date: string,
  accompaniedDriving: YesNoQuestion,
): Validator<string | undefined> => {
  if (!date) {
    return () => undefined;
  }

  const minAge = accompaniedDriving === YesNoQuestion.YES ? 17 : 18;

  const validDateBefore = format(subYears(parse(date, 'yyyy-MM-dd', new Date()), minAge), 'yyyy-MM-dd');
  return createValidDateBeforeValidator(() => validDateBefore);
};

export const createDateYearDiffHigherThan17Validator = (date: string): Validator<string | undefined> => {
  if (!date) {
    return () => undefined;
  }
  const validDateBefore = format(subYears(parse(date, 'yyyy-MM-dd', new Date()), 17), 'yyyy-MM-dd');
  return createValidDateBeforeValidator(() => validDateBefore);
};

export const useBirthDateValidator = (
  licenseDate: string,
  contractDate: string,
  accompaniedDriving: YesNoQuestion,
): IntlValidator => {
  return useComposeValidators(
    useRequiredValidator(),
    useDateValidator(validatorMessages.dateFormatValidator),
    useValidator(createPastDateValidator(), validatorMessages.pastDateValidator),
    useValidator(createBeforeDateValidator(), {
      id: 'birthdayDateAfter1900Validator',
      defaultMessage: 'Veuillez renseigner une date postérieure à 1900',
    }),
    // If no licenseDate, validator return undefined (no error)
    useValidator(createDateYearDiffWithAccompaniedDrivingValidator(licenseDate, accompaniedDriving), {
      id: 'licenseDateAtLeastWithAccompaniedDrivingValidator',
      defaultMessage: `Vous deviez avoir au moins ${
        accompaniedDriving === YesNoQuestion.YES ? '17' : '18'
      } ans à la date d’obtention de votre permis de conduire. Modifiez votre date de naissance ou bien votre date d’obtention du permis.`,
    }),
    useValidator(createDateYearDiffHigherThan17Validator(contractDate), {
      id: 'contractDateAtLeastSeventeenValidator',
      defaultMessage: 'Le conducteur doit avoir au moins 17 ans à date de début de contrat',
    }),
  );
};
