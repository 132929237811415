import { createRoot } from 'react-dom/client';
import { App } from './App';
import { initializeFlagship } from './setup/flagship';
import { initializeMixpanel } from './setup/mixpanel';
import { initializeSentry } from './setup/sentry';

const container = document.getElementById('app');

if (container) {
  initializeSentry();
  initializeMixpanel();
  initializeFlagship();

  createRoot(container).render(<App />);
}
