import { IllustratedIcon } from '@ornikar/illustrated-icons';
import { Tag, Typography, TypographyLink } from '@ornikar/kitt-universal';
import type { PropsWithChildren, ReactElement, ReactNode } from 'react';
import { useState } from 'react';
import type { PaymentPeriodicity } from '../../apis/types/Formula';
import { Query, useMediaQuery } from '../../hooks/useMediaQuery';
import { AddonInfoModal } from '../AddonInfoModal';
import type { AddonInfoModalContentItemProps } from '../AddonInfoModal/components/AddonInfoModalContentItem';
import styles from './styles.module.css';

export interface AddonCardProps {
  addonName: string;
  tag: ReactNode;
  pagination?: string;
  icon: ReactElement;

  title: ReactNode;
  description: ReactNode;
  price: string;
  paymentPeriodicity: PaymentPeriodicity;
  infoModalContent: AddonInfoModalContentItemProps[];
}

export function AddonCard({
  addonName,
  tag,
  pagination,
  icon,
  title,
  description,
  paymentPeriodicity,
  price,
  infoModalContent,
  children,
}: PropsWithChildren<AddonCardProps>): ReactNode {
  const isMedium = useMediaQuery(Query.MEDIUM);

  const [isAddonInfoModalVisible, setIsAddonInfoModalVisible] = useState(false);

  return (
    <>
      <div className={styles.AddonCard}>
        <div className={styles.TopRow}>
          <Tag label={tag} type="primary" />
          <Typography.Text variant="regular" color="black-light" base="body-small">
            {pagination}
          </Typography.Text>
        </div>

        <div className={styles.Header}>
          <IllustratedIcon icon={icon} />
          <Typography.Text variant="bold">{title}</Typography.Text>
        </div>

        <Typography.Text>{description}</Typography.Text>

        {children}

        <div className={styles.BottomRow}>
          <TypographyLink
            noUnderline
            role="button"
            variant="bold"
            color="primary"
            onPress={() => setIsAddonInfoModalVisible(true)}
          >
            En savoir plus
          </TypographyLink>

          <Typography.Text variant="bold">
            <Typography.Text base={isMedium ? 'header4' : 'header3'}>{price}</Typography.Text>
            <Typography.Text base={isMedium ? 'header5' : 'header4'}>
              {paymentPeriodicity === 'monthly' ? '/mois' : '/an'}
            </Typography.Text>
          </Typography.Text>
        </div>
      </div>
      <AddonInfoModal
        addonName={addonName}
        isVisible={isAddonInfoModalVisible}
        title={tag}
        subtitle={title}
        subtitleIcon={<IllustratedIcon icon={icon} />}
        items={infoModalContent}
        onClose={() => setIsAddonInfoModalVisible(false)}
      />
    </>
  );
}
