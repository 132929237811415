import type { ReactNode } from 'react';
import { AddInsuranceRecordTemplate } from '../../../../components/ScreenTemplates/InformationTemplates/InsuranceRecordTemplates/AddInsuranceRecordTemplate';
import { useSubscriptionFsmDispatch } from '../../../../fsm/context';
import { Event } from '../../../../fsm/types';

export function SecondaryAddInsuranceRecordScreen(): ReactNode {
  const send = useSubscriptionFsmDispatch();

  return (
    <AddInsuranceRecordTemplate
      title="Que souhaitez-vous ajouter&nbsp;?"
      onAddSuspension={() => send(Event.ADD_SUSPENSION)}
      onAddCancellation={() => send(Event.ADD_CANCELLATION)}
      onAddClaim={() => send(Event.ADD_CLAIM)}
    />
  );
}
