import { Typography } from '@ornikar/kitt-universal';
import cx from 'classnames';
import type { ReactNode } from 'react';
import styles from './styles.module.css';

interface Option<T> {
  label: ReactNode;
  value: T;
}

export interface SwitchProps<T extends string> {
  options: Option<T>[];
  value: T;
  onChange: (value: T) => void;
  className?: string;
}

export function Switch<T extends string>({ options, value, onChange, className }: SwitchProps<T>): ReactNode {
  const handleOnKeyDown = (itemValue: T) => (event: any) => {
    if (event.keyCode === 13) onChange(itemValue);
  };

  return (
    <div className={cx(styles.Switch, className)}>
      {options.map((item) => {
        const isSelected = value === item.value;

        return (
          <div
            key={item.value}
            role="button"
            tabIndex={0}
            className={cx(styles.SwitchItem, { [styles.Selected]: isSelected })}
            onClick={() => onChange(item.value)}
            onKeyDown={handleOnKeyDown(item.value)}
          >
            <Typography.Text variant="bold" color={isSelected ? 'white' : 'black-light'}>
              {item.label}
            </Typography.Text>
          </div>
        );
      })}
    </div>
  );
}
