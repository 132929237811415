import * as Sentry from '@sentry/react';
import type { Action, ActionObject, AssignAction, DoneInvokeEvent, EventObject } from 'xstate';
import { assign } from 'xstate';
import type { NestedPaths, TypeFromPath } from '../../../types/utility';
import { deepCloneAndAssign } from '../../../utils/deepClone';
import type { LongQuoteAnswers } from '../../answers';
import type { Context } from '../../types';
import type { TypedAssign } from '../types';

export type AssignAnswerPath<K extends NestedPaths<LongQuoteAnswers>, E extends ActionObject<Context, EventObject>> =
  | K
  | ((context: Context, event: E) => K);

export type AssignAnswersPath<K extends NestedPaths<LongQuoteAnswers>, E extends ActionObject<Context, EventObject>> =
  | K
  | ((context: Context, event: E) => K | K[]);

export type AssignAnswerValueFunction<
  K extends NestedPaths<LongQuoteAnswers>,
  E extends ActionObject<Context, EventObject>,
> = (context: Context, event: E) => TypeFromPath<LongQuoteAnswers, K> | undefined;

export type AssignAnswerValue<K extends NestedPaths<LongQuoteAnswers>, E extends ActionObject<Context, EventObject>> =
  | TypeFromPath<LongQuoteAnswers, K>
  | undefined
  | AssignAnswerValueFunction<K, E>;

// @TODO: test this function
export const assignAnswer = <K extends NestedPaths<LongQuoteAnswers>, E extends ActionObject<Context, EventObject>>(
  firstParams: { path: AssignAnswerPath<K, E>; value: AssignAnswerValue<K, E> } | AssignAnswersPath<K, E>,
  ...restParams: { path: AssignAnswerPath<K, E>; value: AssignAnswerValue<K, E> }[] | [AssignAnswerValue<K, E>]
): AssignAction<Context, E> =>
  assign((context: Context, event: E) => {
    const finalContext = {
      answers: context.answers as LongQuoteAnswers,
    };

    let reducableParams: { path: K; value: AssignAnswerValue<K, E> }[] = [];

    const hasOnlyOneAssignement = typeof firstParams === 'function' || typeof firstParams === 'string';

    if (hasOnlyOneAssignement) {
      const value = restParams[0] as any;
      const p = typeof firstParams === 'function' ? firstParams(context, event) : firstParams;
      const paths = typeof p === 'string' ? [p] : p;

      reducableParams = paths.map((path) => ({
        path,
        value: typeof value === 'function' ? value(context, event) : value,
      }));
      // eslint-disable-next-line no-prototype-builtins
    } else if (typeof restParams[0] === 'object' && restParams[0].hasOwnProperty('path')) {
      reducableParams = [
        {
          path: typeof firstParams.path === 'function' ? firstParams.path(context, event) : firstParams.path,
          value:
            typeof firstParams.value === 'function'
              ? (firstParams.value as AssignAnswerValueFunction<K, E>)(context, event)
              : firstParams.value,
        },
        ...(restParams as any[]).map(({ path, value }) => ({
          path: typeof path === 'function' ? path(context, event) : path,
          value: typeof value === 'function' ? value(context, event) : value,
        })),
      ];
    } else {
      throw new Error(`Wrong usage of assignAnswer function
      | Expected usage:
       1. ('foo.bar.baz', <anyValue>) => ... OR
       2. ({ path: 'foo.bar.baz', value: <anyValue> }, { path: 'foo.bil', value: <anyValue> }, ...) => ...
      `);
    }

    reducableParams.forEach(({ path, value }: { path: K; value: any }) => {
      const finalValue = typeof value === 'function' ? value(context) : value;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      finalContext.answers = deepCloneAndAssign(path, finalValue, finalContext.answers);
    });

    return finalContext;
  });

// @TODO: test this function
export const resetAnswer = <K extends NestedPaths<LongQuoteAnswers>, E extends ActionObject<Context, EventObject>>(
  paths: AssignAnswersPath<K, E> | K[],
): TypedAssign<E> => {
  if (Array.isArray(paths)) {
    const params = paths.map((path) => ({ path, value: undefined }));
    return assignAnswer<K, E>(params[0], ...params.slice(1));
  }

  return assignAnswer<K, E>(paths, undefined);
};

export const sendErrorToSentry: Action<any, DoneInvokeEvent<Error>> = (_, event): void => {
  Sentry.captureException(event.data);
};
