import type { IntlValidator } from '@ornikar/react-validators';
import { useComposeValidators, useDateValidator, useRequiredValidator, useValidator } from '@ornikar/react-validators';
import { createValidDateAfterValidator, invalidTypeSymbol } from '@ornikar/validators';
import { format as formatDate, subDays, subYears } from 'date-fns';
import { defineMessages } from 'react-intl';
import type { DateType, Period } from '../../../../fsm/answers';
import { useDateBeforeTomorrowValidator } from '../../sharedValidators';

export const validatorMessages = defineMessages({
  dateFormatValidator: {
    id: 'startDateFormatValidator',
    defaultMessage: 'Veuillez saisir une date valide',
  },
  dateAfterThreeYearsAgoValidator: {
    id: 'InsurancePeriods.dateAfterThreeYearsAgoValidator',
    defaultMessage: 'La date doit subvenir à partir du {date}.',
  },
  periodEndAfterPeriodStartValidator: {
    id: 'periodEndAfterPeriodStartValidator',
    defaultMessage: 'La date de fin doit être postérieure à la date début',
  },
  dateNotInAlreadyDeclaredPeriodValidator: {
    id: 'dateNotInAlreadyDeclaredPeriodValidator',
    defaultMessage: 'La date ne peut pas être dans une période déjà déclarée',
  },
});

export const THREE_YEARS_AGO = subYears(new Date(), 3);

const useDateAfterThreeYearsAgoValidator = (): IntlValidator<string> =>
  useValidator(
    createValidDateAfterValidator(() => formatDate(subDays(THREE_YEARS_AGO, 1), 'yyyy-MM-dd')),
    validatorMessages.dateAfterThreeYearsAgoValidator,
    { date: formatDate(THREE_YEARS_AGO, 'dd/MM/yyyy') },
  );

const useDateNotBetweenPeriodsValidator = (periods: Period[], isEndDate = false): IntlValidator<string> =>
  useValidator((value: DateType): typeof invalidTypeSymbol | undefined => {
    if (!value) return undefined;

    for (let i = 0; i < periods.length; i++) {
      if (isEndDate) {
        if (value.localeCompare(periods[i].start) >= 0 && value.localeCompare(periods[i].end) < 0) {
          return invalidTypeSymbol;
        }
      }

      if (value.localeCompare(periods[i].start) > 0 && value.localeCompare(periods[i].end) <= 0) {
        return invalidTypeSymbol;
      }
    }

    return undefined;
  }, validatorMessages.dateNotInAlreadyDeclaredPeriodValidator);

export const useDateInsurancePeriodValidator = (forbiddenPeriods: Period[], isEndDate = false): IntlValidator<string> =>
  useComposeValidators(
    useRequiredValidator(),
    useDateValidator(validatorMessages.dateFormatValidator),
    useDateAfterThreeYearsAgoValidator(),
    useDateBeforeTomorrowValidator(),
    useDateNotBetweenPeriodsValidator(forbiddenPeriods, isEndDate),
  );

export const useEndDateInsurancePeriodValidator = (
  startDate: string,
  forbiddenPeriods: Period[],
): IntlValidator<string> =>
  useComposeValidators(
    useDateInsurancePeriodValidator(forbiddenPeriods, true),
    useValidator(
      createValidDateAfterValidator(() => startDate),
      validatorMessages.periodEndAfterPeriodStartValidator,
    ),
  );
