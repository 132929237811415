import { Typography, VStack, View } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import HumIllustration from './assets/hum.svg';

export function CallbackErrorView(): ReactNode {
  return (
    <VStack space="kitt.6" alignItems="center">
      <View width={106} height={185}>
        <img src={HumIllustration} alt="Hum..." />
      </View>
      <VStack space="kitt.2">
        <Typography.Text variant="bold" textAlign="center">
          <FormattedMessage id="callbackStatusView.error.subtitle" defaultMessage="Mince..." />
        </Typography.Text>
        <Typography.Text textAlign="center">
          <FormattedMessage
            id="callbackStatusView.error.description"
            defaultMessage="Une erreur a eu lieu lors de votre demande de rappel. Nous vous invitons à réessayer ultérieurement."
          />
        </Typography.Text>
      </VStack>
    </VStack>
  );
}
