import { useRequiredValidator } from '@ornikar/react-validators';
import type { ReactNode } from 'react';
import { useEffect } from 'react';
import { useField } from 'react-final-form';
import type { Except } from 'type-fest';
import { YesNoQuestion, makeChoices } from '../../../../../../fsm/answers';
import type { RadioScreenTemplateProps } from '../../../../RadioScreenTemplate';
import { RadioScreenTemplate } from '../../../../RadioScreenTemplate';

interface AddClaimRecordTemplateProps extends Except<RadioScreenTemplateProps<YesNoQuestion>, 'choices' | 'fieldName'> {
  onAddClaim: () => void;
  onContinue: () => void;
}

const choices = makeChoices(YesNoQuestion, [
  [YesNoQuestion.NO, 'Non'],
  [YesNoQuestion.YES, 'Oui'],
]);

const temporaryFieldName = 'addClaim_temporary';

export function AddClaimRecordTemplate({ title, onAddClaim, onContinue }: AddClaimRecordTemplateProps): ReactNode {
  const requiredValidator = useRequiredValidator();
  const {
    input: { value, onChange },
  } = useField(temporaryFieldName);

  // Reset value as it is temporary
  useEffect(() => {
    return () => {
      onChange(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RadioScreenTemplate
      fieldName={temporaryFieldName}
      choices={choices}
      title={title}
      validate={requiredValidator}
      onSubmit={() => {
        if (value === YesNoQuestion.YES) {
          if (onAddClaim) onAddClaim();
        } else if (onContinue) onContinue();
      }}
    />
  );
}
