import type { IntlValidator } from '@ornikar/react-validators';
import { useComposeValidators, useDateValidator, useRequiredValidator, useValidator } from '@ornikar/react-validators';
import type { Validator } from '@ornikar/validators';
import { invalidTypeSymbol } from '@ornikar/validators';
import { addYears, isAfter, parse, subDays } from 'date-fns';
import type { LongQuoteAnswers } from '../../../../fsm/answers';
import type { Driver } from '../../../../fsm/types';
import { validatorMessages } from '../../messages';
import { useDateBeforeTomorrowValidator } from '../../sharedValidators';

export const useDateAfterAdultValidator = (driver: Driver): IntlValidator => {
  return useComposeValidators(
    useDateValidator(validatorMessages.dateFormatValidator),
    // @dev We cannot use createValidDateAfterValidator because we need access to values
    useValidator(
      ((value: string, values: Partial<LongQuoteAnswers>) => {
        if (!value) return undefined;

        const driverBirthDate = values[`${driver}Driver`]?.birthDate;
        if (!driverBirthDate) {
          return undefined;
        }

        const adultBirthdate = subDays(addYears(new Date(driverBirthDate), 17), 1);
        const valueDate = parse(value, 'yyyy-MM-dd', new Date());

        return isAfter(valueDate, adultBirthdate) ? undefined : invalidTypeSymbol;
      }) as Validator,
      {
        id: 'minAdultBirthdayValidator',
        defaultMessage: "Il semblerait que cette personne n'avait pas 17 ans à cette date",
      },
    ),
  );
};

export const useLicenseDateValidator = (driver: Driver): IntlValidator => {
  const licenseDateValidator = useComposeValidators(
    useRequiredValidator(),
    useDateValidator(),
    useDateBeforeTomorrowValidator(),
  );

  const licenseDateWidthBirthDateValidator = useComposeValidators(
    licenseDateValidator,
    useDateAfterAdultValidator(driver),
  );

  if (driver) {
    return licenseDateWidthBirthDateValidator;
  }

  return licenseDateValidator;
};
