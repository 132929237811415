import type { ReactNode } from 'react';
import { CancellationRecordHitAndRunTemplate } from '../../../../components/ScreenTemplates/InformationTemplates/InsuranceRecordTemplates/CancellationRecordTemplates/CancellationRecordHitAndRunTemplate';
import { useSubscriptionFsmState } from '../../../../fsm/context';

export function SecondaryCancellationRecordHitAndRunScreen(): ReactNode {
  const {
    context: { editingInsuranceRecord },
  } = useSubscriptionFsmState();

  return (
    <CancellationRecordHitAndRunTemplate
      title="A-t-il commis un délit de fuite ou refusé d'obtempérer&nbsp;?"
      fieldName={`secondaryDriver.cancellationOrSuspensionRecords[${editingInsuranceRecord.index}].isHitAndRun`}
    />
  );
}
