import type { ChangeEvent, ReactNode } from 'react';
import type { SetOptional } from 'type-fest';
import { RadioBlock } from './RadioBlock';
import styles from './styles.module.css';

type OnChange<T> = (value: T, event: ChangeEvent<HTMLInputElement>) => void;

const handleChange: <T>(
  value: T,
  event: ChangeEvent<HTMLInputElement>,
  onChange: OnChange<T>,
  onBlur?: () => void,
  onFocus?: () => void,
) => void = (value, event, onChange, onBlur, onFocus) => {
  if (onFocus) {
    onFocus();
  }
  onChange(value, event);
  if (onBlur) {
    onBlur();
  }
};

export interface ChoiceWithRender<T = string> {
  render: (checked: boolean) => ReactNode;
  value: T;
  subText?: string;
}
export interface ChoiceWithLabel<T = string> {
  label: ReactNode;
  value: T;
  subText?: string;
}

export interface RadioBlockGroupProps<T = string> {
  id: string;
  name: string;
  value?: T;
  onChange: OnChange<T>;
  choices: (ChoiceWithLabel<T> | ChoiceWithRender<T>)[];
  onBlur?: () => void;
  onFocus?: () => void;
}

export function RadioBlockGroup<T = string>({
  id,
  name,
  value,
  onBlur,
  onFocus,
  onChange,
  choices,
}: RadioBlockGroupProps<T>): ReactNode {
  return (
    <div className={styles.Group}>
      {choices.map((choice): ReactNode => {
        return (
          <RadioBlockGroup.RadioBlock<T>
            key={choice.value as unknown as string}
            value={choice.value}
            id={`${id}-${choice.value as unknown as string}`}
            name={name}
            checked={value === choice.value}
            subText={choice.subText}
            onClick={(e: ChangeEvent<HTMLInputElement>): void =>
              handleChange(choice.value, e, onChange, onBlur, onFocus)
            }
          >
            {(choice as SetOptional<ChoiceWithRender<T>, 'render'>).render
              ? (choice as ChoiceWithRender<T>).render(value === choice.value)
              : (choice as ChoiceWithLabel<T>).label}
          </RadioBlockGroup.RadioBlock>
        );
      })}
    </div>
  );
}

RadioBlockGroup.RadioBlock = RadioBlock;
