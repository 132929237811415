import { TypographyLink } from '@ornikar/kitt-universal';
import cx from 'classnames';
import type { PropsWithChildren, ReactNode } from 'react';
import styles from './styles.module.css';

interface ItemProps {
  children: string;
  href?: string;
  onClick?: () => void;
  target?: string;
}

export function LinkListItem({ children, href, onClick, target }: ItemProps): ReactNode {
  return (
    <span className={styles.Item}>
      <TypographyLink noUnderline href={href} hrefAttrs={{ target }} color="white" variant="bold" onPress={onClick}>
        {children}
      </TypographyLink>
    </span>
  );
}

interface LinkListProps {
  blockOnMobile?: boolean;
}

export function LinkList({ blockOnMobile = false, children }: PropsWithChildren<LinkListProps>): ReactNode {
  return <span className={cx({ [styles.BlockOnMobile]: blockOnMobile })}>{children}</span>;
}
