import type { ReactNode } from 'react';
import { CancellationRecordReasonTemplate } from '../../../../components/ScreenTemplates/InformationTemplates/InsuranceRecordTemplates/CancellationRecordTemplates/CancellationRecordReasonTemplate';
import { WithdrawalType } from '../../../../fsm/answers';
import { useSubscriptionFsmState } from '../../../../fsm/context';

export function PrimaryCancellationRecordReasonScreen(): ReactNode {
  const {
    context: {
      editingInsuranceRecord,
      answers: { primaryDriver },
    },
  } = useSubscriptionFsmState();

  const title = `Pour quel motif votre permis a-t-il été ${
    primaryDriver?.cancellationOrSuspensionRecords?.[editingInsuranceRecord.index].withdrawalType ===
    WithdrawalType.Cancellation
      ? 'annulé'
      : 'suspendu'
  }\u00A0?`;

  return (
    <CancellationRecordReasonTemplate
      title={title}
      fieldName={`primaryDriver.cancellationOrSuspensionRecords[${editingInsuranceRecord.index}].reason`}
    />
  );
}
