import type { ReactNode } from 'react';
import { ClaimRecordWithAlcoholTemplate } from '../../../../components/ScreenTemplates/InformationTemplates/InsuranceRecordTemplates/ClaimRecordTemplates/ClaimRecordWithAlcoholTemplate';
import { useSubscriptionFsmState } from '../../../../fsm/context';

export function SecondaryCancellationRecordClaimWithAlcoholScreen(): ReactNode {
  const {
    context: { editingInsuranceRecord },
  } = useSubscriptionFsmState();

  return (
    <ClaimRecordWithAlcoholTemplate
      title="Était-il sous l'emprise d'alcool ou de stupéfiants lors de cet accident&nbsp;?"
      fieldName={`secondaryDriver.cancellationOrSuspensionRecords[${editingInsuranceRecord.index}].claimRecord.underTheInfluenceOf`}
    />
  );
}
