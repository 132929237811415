import { Typography } from '@ornikar/kitt-universal';
import cx from 'classnames';
import type { PropsWithChildren, ReactNode } from 'react';
import styles from './styles.module.css';

type FancyIconButtonProps = PropsWithChildren<{
  icon?: string;
  iconAlt?: string;
  className?: string;
  isActive?: boolean;
  onClick: () => void;
}>;

export function FancyIconButton({
  icon,
  iconAlt,
  className,
  isActive = false,
  onClick,
  children,
}: FancyIconButtonProps): ReactNode {
  return (
    <div className={cx(styles.FancyIconButton, className)}>
      <button
        type="button"
        className={cx(styles.Button, { [styles.isActive]: isActive })}
        onClick={!isActive ? onClick : undefined}
      >
        <div className={styles.InnerContainer}>{icon ? <img src={icon} alt={iconAlt || ''} /> : null}</div>
      </button>
      <Typography.Text variant="bold">{children}</Typography.Text>
    </div>
  );
}
