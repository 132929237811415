import type { ReactNode } from 'react';
import { Field } from '../../../components/Field';
import { ScreenTemplate } from '../../../components/ScreenTemplates/ScreenTemplate';
import { SliderInput } from '../../../components/SliderInput';
import { Driver } from '../../../fsm/types';
import { useIsLicenseDateInFuture } from '../../../hooks/useIsLicenseDateInFuture';

export function DrivingKilometersPerYearScreen(): ReactNode {
  const isLicenseDateInFuture = useIsLicenseDateInFuture(Driver.Primary);
  const title = `Approximativement, combien de kilomètres ${
    isLicenseDateInFuture ? 'ferez' : 'faites'
  }-vous par an\u00A0?`;

  return (
    <ScreenTemplate
      title={title}
      highlightTitle="Vous ne connaissez pas votre kilométrage exact ?"
      highlightContent={
        <div>
          Ce n&apos;est pas grave, nous souhaitons juste une estimation, car cette information aura un impact sur votre
          tarif.
          <br />
          <br />
          <b>Astuce :</b> en moyenne, les Français parcourent 15 000 km/an. Ce chiffre est généralement plus faible pour
          les personnes vivant en ville.
          <br />
          <br />
          Si vous avez donné un kilométrage trop haut, vous pourrez changer cette information par téléphone avec un
          autre conseiller expert, il pourra également être ajusté au renouvellement de votre contrat.
        </div>
      }
    >
      <Field
        component={SliderInput}
        name="kilometersPerYear"
        min={1000}
        minLabel="Moins de 1 000 km"
        max={50000}
        maxLabel="Plus de 50 000 km"
        step={1000}
        formatValue={(val: number) => {
          if (val === 1000) {
            return '≤ 1 000';
          }

          if (val === 50000) {
            return '≥ 50 000';
          }

          return val.toLocaleString('fr-FR').replace('\u202F', ' ');
        }}
        unit="km"
      />
    </ScreenTemplate>
  );
}
