import { Typography } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import styles from './styles.module.css';

export interface ItemProps {
  icon: ReactNode;
  title: string;
  description: ReactNode;
}

export function Item({ icon, title, description }: ItemProps): ReactNode {
  return (
    <div className={styles.Wrapper}>
      <div className={styles.Icon}>
        {icon}
        <div className={styles.Line} />
      </div>
      <div className={styles.Content}>
        <div className={styles.Title}>
          <Typography.Text base="body" variant="bold">
            {title}
          </Typography.Text>
        </div>
        <div>
          {typeof description === 'string' ? (
            <Typography.Text base="body" color="black-anthracite">
              {description}
            </Typography.Text>
          ) : (
            description
          )}
        </div>
      </div>
    </div>
  );
}
