import { useEffect, useState } from 'react';

export const usePageBottom = (): boolean => {
  const [reachedBottom, setReachedBottom] = useState(false);

  useEffect(() => {
    const handleScroll = (): void => {
      const { offsetHeight, scrollTop } = document.documentElement;
      const { innerHeight } = window;

      const hasReachedBottom = offsetHeight - (innerHeight + scrollTop) <= 0;

      setReachedBottom(hasReachedBottom);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return reachedBottom;
};
