import { IllustratedIcon, Light } from '@ornikar/illustrated-icons';
import { Typography, VStack } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { DeductibleCard } from '../../../components/DeductibleCard';
import { FormulaPriceBottomBar } from '../../../components/FormulaPrice/FormulaPriceBottomBar';
import { FormulaPriceCard } from '../../../components/FormulaPrice/FormulaPriceCard';
import { InputHighlight } from '../../../components/InputHighlight';
import { ScreenTemplateWithSideAndBottomBars } from '../../../components/ScreenTemplates/ScreenTemplateWithSideAndBottomBars';
import { useSubscriptionFsmDispatch } from '../../../fsm/context';
import { Event } from '../../../fsm/types';
import { Query, useMediaQuery } from '../../../hooks/useMediaQuery';
import { FormattedMessageWithRichTextFormatting } from '../../../utils/intl';
import styles from './styles.module.css';

const messages = defineMessages({
  deductiblesTitle: {
    id: 'deductibles.title',
    defaultMessage: 'Enfin, ajustez vos franchises ',
  },
  deductiblesDescription: {
    id: 'deductibles.description',
    defaultMessage:
      'Une franchise, c’est le montant déduit de votre remboursement en cas de sinistre.{br}Plus la franchise est élevée, plus votre somme à payer chaque mois sera réduite.',
  },
  deductiblesHelperTitle: {
    id: 'deductibles.helper.title',
    defaultMessage: 'Mieux comprendre l’enjeu de ces franchises',
  },
  deductiblesHelperDescription: {
    id: 'deductibles.helper.description',
    defaultMessage:
      'Imaginez que votre pare-brise d’une valeur de 800 euros doive être remplacé : quel est le montant que vous pouvez vous-même prendre en charge pour le remplacer\u00A0?{br}{br}<b>Si vous souhaitez être remboursé en totalité lors du sinistre,</b> misez sur une franchise à 0€ et des cotisations plus légèrement élevées.',
  },
});

export function DeductiblesSelectionScreen(): ReactNode {
  const isMedium = useMediaQuery(Query.MEDIUM);
  const send = useSubscriptionFsmDispatch();

  const handleSubmit = (): void => {
    send(Event.CONTINUE);
  };

  return (
    <ScreenTemplateWithSideAndBottomBars
      hideSaveQuoteButton
      hideSubmitButton
      backButtonLabel="Étape précédente"
      sideBar={<FormulaPriceCard buttonLabel="Continuer" onSubmit={handleSubmit} />}
      bottomBar={<FormulaPriceBottomBar buttonLabel="Continuer" onSubmit={handleSubmit} />}
      onSubmit={handleSubmit}
    >
      <VStack space="kitt.4">
        <Typography.Text textAlign={isMedium ? 'initial' : 'center'} base="header4">
          <FormattedMessage {...messages.deductiblesTitle} />
        </Typography.Text>
        <Typography.Text textAlign={isMedium ? 'initial' : 'center'}>
          <FormattedMessageWithRichTextFormatting
            id={messages.deductiblesDescription.id}
            defaultMessage={
              isMedium
                ? messages.deductiblesDescription.defaultMessage
                : messages.deductiblesDescription.defaultMessage.split('{br}').join('{br}{br}')
            }
          />
        </Typography.Text>
        <InputHighlight
          condensed
          titleIcon={<IllustratedIcon icon={<Light />} />}
          title={<FormattedMessage {...messages.deductiblesHelperTitle} />}
          className={styles.Highlight}
        >
          <VStack>
            <FormattedMessageWithRichTextFormatting {...messages.deductiblesHelperDescription} />
          </VStack>
        </InputHighlight>
        <DeductibleCard />
      </VStack>
    </ScreenTemplateWithSideAndBottomBars>
  );
}
