import type { ReactNode } from 'react';
import { InsuranceRecordAlcoholLevelTemplate } from '../../../../components/ScreenTemplates/InformationTemplates/InsuranceRecordTemplates/InsuranceRecordAlcoholLevelTemplate';

export function PrimaryTerminationRecordLicenseCanceledOrSuspendedAlcoholLevelScreen(): ReactNode {
  return (
    <InsuranceRecordAlcoholLevelTemplate
      title="Quel était votre taux d'alcoolémie lors de ce contrôle&nbsp;?"
      fieldName="primaryDriver.terminationRecords[0].cancellationOrSuspensionRecord.bloodAlcoholLevel"
    />
  );
}
