import type { ReactNode } from 'react';
import { ClaimRecordResponsibilityLevelTemplate } from '../../../../components/ScreenTemplates/InformationTemplates/InsuranceRecordTemplates/ClaimRecordTemplates/ClaimRecordResponsibilityLevelTemplate';
import { ResponsibilityLevel } from '../../../../fsm/answers';

export function PrimaryTerminationRecordLicenseCanceledOrSuspendedClaimResponsibilityLevelScreen(): ReactNode {
  return (
    <ClaimRecordResponsibilityLevelTemplate
      title="Quel était votre niveau de responsabilité&nbsp;?"
      fieldName="primaryDriver.terminationRecords[0].cancellationOrSuspensionRecord.claimRecord.responsibilityLevel"
      filteredChoices={[ResponsibilityLevel.Responsible, ResponsibilityLevel.PartiallyResponsible]}
    />
  );
}
