import { HStack, Image, Tag, Typography, VStack, View } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { RegistrationDocumentPartnerLink } from '../../../../../components/RegistrationDocumentPartner';
import { Timeline } from '../../../../../components/Timeline';
import { isRegistrationDocumentPartnerLinkDisplayed } from '../../../../../fsm/guards/flagship';
import NumberOneIcon from './assets/number-one.svg';
import NumberThreeIcon from './assets/number-three.svg';
import NumberTwoIcon from './assets/number-two.svg';
import ThumbUpIcon from './assets/thumb-up.svg';

export function NextSteps(): ReactNode {
  return (
    <VStack space="kitt.6">
      <Typography.Header3 base="header3">Plus que quelques minutes avant d’être assuré</Typography.Header3>
      <Timeline
        items={[
          {
            icon: <Image src={NumberOneIcon} alt="step one icon" size={32} marginX="kitt.4" />,
            title: 'Personnalisez votre couverture',
            description: (
              <VStack space="kitt.1">
                <Typography.Text color="black-anthracite">
                  Après avoir choisi votre formule, vous pourrez ajuster vos franchises et ajouter des options&nbsp;:
                </Typography.Text>
                <HStack space="kitt.1" flexWrap="wrap">
                  <View marginBottom={{ base: 'kitt.1' }}>
                    <Tag type="primary" label={<Typography.Text base="body-small">Assistance 0km</Typography.Text>} />
                  </View>
                  <View marginBottom={{ base: 'kitt.1' }}>
                    <Tag
                      type="primary"
                      label={<Typography.Text base="body-small">Protection du conducteur étendue</Typography.Text>}
                    />
                  </View>
                  <View marginBottom={{ base: 'kitt.1' }}>
                    <Tag
                      type="primary"
                      label={<Typography.Text base="body-small">Véhicule de remplacement</Typography.Text>}
                    />
                  </View>
                  <Tag type="primary" label={<Typography.Text base="body-small">Prêt de volant</Typography.Text>} />
                </HStack>
                {isRegistrationDocumentPartnerLinkDisplayed() ? (
                  <RegistrationDocumentPartnerLink textColor="black-anthracite" />
                ) : null}
              </VStack>
            ),
          },
          {
            icon: <Image src={NumberTwoIcon} alt="step two icon" size={32} marginX="kitt.4" />,
            title: 'Finalisez votre souscription',
            description: 'En transmettant votre IBAN et en réglant un premier montant par carte bancaire.',
          },
          {
            icon: <Image src={NumberThreeIcon} alt="step three icon" size={32} marginX="kitt.4" />,
            title: 'Signez votre contrat',
            description: 'Directement en ligne, depuis votre Espace Client Ornikar Assurance.',
          },
          {
            icon: <Image src={ThumbUpIcon} alt="thumb up icon" size={48} marginX="kitt.2" />,
            title: 'Roulez assuré',
            description:
              'Vous êtes assuré immédiatement ! Vous avez 30 jours pour envoyer les documents qui vous permettront d’obtenir votre assurance définitive.',
          },
        ]}
      />
    </VStack>
  );
}
