import { Typography, TypographyLink, View } from '@ornikar/kitt-universal';
import { useRequiredValidator } from '@ornikar/react-validators';
import type { ReactNode } from 'react';
import { ScreenTemplate } from '../../../components/ScreenTemplates/ScreenTemplate';
import { useSubscriptionFsmDispatch } from '../../../fsm/context';
import { Event } from '../../../fsm/types';
import { sendEvent } from '../../../utils/mixpanel';
import { BrandAutoComplete } from './BrandAutocomplete/BrandAutocomplete';

export function VehicleBrandScreen(): ReactNode {
  const validator = useRequiredValidator();
  const send = useSubscriptionFsmDispatch();

  return (
    <ScreenTemplate
      title="C'est parti&nbsp;! Quelle est la marque de votre véhicule&nbsp;?"
      highlightTitle="Où trouver la marque ?"
      highlightContent={
        <div>
          Rendez-vous au <b>repère D.1</b> de votre <b>carte grise (certificat d’immatriculation)</b>
        </div>
      }
      hideSubmitButtonOnFirstEditionOfField="vehiculeMarque"
    >
      <BrandAutoComplete name="vehiculeMarque" validate={validator} />

      <View marginTop="kitt.2">
        <Typography.Paragraph color="black-light">
          Gagnez du temps, en cherchant{' '}
          <TypographyLink
            variant="bold"
            color="primary"
            onPress={() => {
              sendEvent('Go To Screen', {
                from_state: 'VEHICULE_BRAND',
                to_state: 'VEHICULE_LICENSE_PLATE',
              });
              send({ type: Event.SKIP });
            }}
          >
            par&nbsp;immatriculation
          </TypographyLink>
          .
        </Typography.Paragraph>
      </View>
    </ScreenTemplate>
  );
}
