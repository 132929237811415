import { VStack } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import type { FieldProps } from '../../../components/Field';
import { Field } from '../../../components/Field';
import { LocalDropdownSelect } from '../../../components/LocalDropdownSelect';
import { ExitDoorLink } from '../../../components/exitDoor/ExitDoorLink';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { Event } from '../../../fsm/types';

interface PuissanceFiscaleFieldProps extends Pick<FieldProps<string>, 'name' | 'label' | 'validate'> {}

export function PuissanceFiscaleField(props: PuissanceFiscaleFieldProps): ReactNode {
  const { context } = useSubscriptionFsmState();
  const send = useSubscriptionFsmDispatch();

  const choices = useMemo(() => {
    return context.availablePuissanceFiscales.sort((a, b) => a - b).map((v) => ({ label: `${v}CV`, value: v }));
  }, [context.availablePuissanceFiscales]);

  const setVehiculePuissanceFiscaleDefaultValue = (): void => {
    send(Event.DEFAULT_ANSWER, {
      answers: {
        vehiculePuissanceFiscale: choices[0].value,
      },
    });
  };

  return (
    <VStack space="kitt.4" marginBottom={{ base: 'kitt.8', large: 0 }} zIndex={1}>
      <Field {...props} submitOnChange component={LocalDropdownSelect} placeholder="Sélectionner" choices={choices} />

      <ExitDoorLink onPress={setVehiculePuissanceFiscaleDefaultValue}>
        Je ne connais pas la puissance fiscale du véhicule.
      </ExitDoorLink>
    </VStack>
  );
}
