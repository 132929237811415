import { Typography } from '@ornikar/kitt-universal';
import cx from 'classnames';
import type { PropsWithChildren, ReactNode } from 'react';
import styles from './styles.module.css';

type FancyButtonProps = PropsWithChildren<{
  icon?: string;
  iconAlt?: string;
  className?: string;
  isActive?: boolean;
  onClick: () => void;
}>;

export function FancyButton({
  icon,
  iconAlt,
  className,
  onClick,
  isActive = false,
  children,
}: FancyButtonProps): ReactNode {
  return (
    <button
      type="button"
      className={cx(styles.FancyButton, { [styles.isActive]: isActive }, className)}
      onClick={!isActive ? onClick : undefined}
    >
      <div className={styles.InnerContainer}>
        {icon ? <img src={icon} alt={iconAlt || ''} /> : null}
        <Typography.Text variant="bold" color={isActive ? 'white' : 'black'}>
          {children}
        </Typography.Text>
      </div>
    </button>
  );
}
