import { HStack, Tag, Typography, View } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';

interface OptionsSeparatorProps {
  formuleName?: string;
  isActive?: boolean;
}

export function OptionsSeparator({ formuleName, isActive = false }: OptionsSeparatorProps): ReactNode {
  return (
    <HStack alignItems="center" space="kitt.2" marginBottom="kitt.4" marginTop={formuleName ? 'kitt.4' : null}>
      <Typography.Text
        base="body-small"
        variant={isActive ? 'bold' : 'regular'}
        color={isActive ? 'black' : 'black-light'}
      >
        {formuleName ? 'Inclus dans la formule' : 'Toujours inclus'}
      </Typography.Text>
      {formuleName && (
        <Tag
          type={isActive ? 'primary' : 'default'}
          label={
            <Typography.Paragraph base="body-small" color={isActive ? 'kitt.primary' : 'kitt.black'}>
              {formuleName}
            </Typography.Paragraph>
          }
        />
      )}
      <View borderBottomColor="kitt.separator" borderBottomWidth="1" flex="1" />
    </HStack>
  );
}
