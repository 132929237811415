import { PencilLineRegularIcon } from '@ornikar/kitt-icons/phosphor';
import { Button, HStack, IconButton, Typography, VStack, View } from '@ornikar/kitt-universal';
import type { IntlValidator, IntlValidatorReturnType } from '@ornikar/react-validators';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import type { ReactNode } from 'react';
import type { AnyObject } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { DateField } from '../../../../components/DateField';
import { DatePicker } from '../../../../components/DatePicker';
import { Field } from '../../../../components/Field';
import { useInsuranceDesktopMediaQuery } from '../../../../hooks/useInsuranceDesktopMediaQuery';
import { useInsuranceMediaQuery } from '../../../../hooks/useInsuranceMediaQuery';
import { formatDateForUI } from '../../../../utils/date';

export interface DateDisplayProps {
  fieldName: string;
  defaultValue: string;
  label: string | ReactNode;
  validate: IntlValidator<string>;
  onValidated: (data: IntlValidatorReturnType) => void;
  onEditorVisibilityChange?: (visibility: boolean) => void;
  onButtonPress: () => Promise<boolean>;
  minDate: Date;
  maxDate: Date;
}

export function DateDisplay({
  defaultValue,
  fieldName,
  label,
  onEditorVisibilityChange,
  onButtonPress,
  onValidated,
  validate,
  minDate,
  maxDate,
}: DateDisplayProps): ReactNode {
  const [inEdition, setInEdition] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const { isMedium } = useInsuranceMediaQuery();

  const handleBlur = (val: string): void => {
    setValue(val);
  };

  useEffect(() => {
    if (onEditorVisibilityChange) {
      onEditorVisibilityChange(inEdition);
    }
  }, [inEdition, onEditorVisibilityChange]);

  const [isDateValid, setIsDateValid] = useState(true);

  const validator: IntlValidator<string, AnyObject> = (currentValue, values, meta) => {
    const result = validate(currentValue, values, meta);
    setValue(currentValue);
    onValidated(result);
    setIsDateValid(!result);
    return result;
  };

  const isDesktop = useInsuranceDesktopMediaQuery();
  return (
    <View paddingX={{ base: 'kitt.4', medium: 'kitt.6' }} paddingY="kitt.4" zIndex="10">
      {!inEdition ? (
        <HStack alignItems="center" justifyContent="space-between">
          <Typography.Paragraph>
            {label}{' '}
            <Typography.Paragraph base="body" variant="bold">
              {formatDateForUI(value)}
            </Typography.Paragraph>
          </Typography.Paragraph>
          <IconButton icon={<PencilLineRegularIcon />} color="primary" onPress={() => setInEdition(true)} />
        </HStack>
      ) : (
        <VStack>
          <Typography.Paragraph>{label}</Typography.Paragraph>
          <View marginTop="kitt.2" marginBottom="kitt.6" zIndex="5">
            {isDesktop ? (
              <Field
                name={fieldName}
                component={DatePicker}
                minDate={format(minDate, 'yyyy-MM-dd')}
                maxDate={format(maxDate, 'yyyy-MM-dd')}
                defaultValue={value}
                initialValue={value}
                validate={validator}
              />
            ) : (
              <DateField
                name={fieldName}
                validate={validator}
                defaultValue={value}
                initialValue={value}
                onBlur={handleBlur}
              />
            )}
          </View>
          <Button
            stretch={!isMedium}
            disabled={!isDateValid}
            type="primary"
            onPress={async () => {
              setIsDateValid(false);
              if (await onButtonPress()) {
                setInEdition(false);
              }
              setIsDateValid(true);
            }}
          >
            <FormattedMessage id="shoppingCart.apply" defaultMessage="Appliquer" />
          </Button>
        </VStack>
      )}
    </View>
  );
}
