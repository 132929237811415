import cx from 'classnames';
import type { ReactNode } from 'react';
import styles from './styles.module.css';

interface ConstrainedWidthProps {
  maxWidth?: string | number;
  padding?: number;
  className?: string;
  children: NonNullable<ReactNode>;
}

export function ConstrainedWidth({
  maxWidth = 576,
  padding = 16,
  className,
  children,
}: ConstrainedWidthProps): ReactNode {
  return (
    <div className={styles.OuterContainer}>
      <div className={cx(styles.InnerContainer, className)} style={{ maxWidth, padding: `0 ${padding}px` }}>
        {children}
      </div>
    </div>
  );
}
