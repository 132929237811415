import type { ReactNode } from 'react';
import { TitulaireCarteGriseType } from '../../../apis/types/LongQuoteAnswers';
import { InformationList } from '../../../components/InformationList/InformationList';
import { InformationEntry } from '../../../components/InformationList/components/InformationEntry';
import { RecapScreenTemplate } from '../../../components/ScreenTemplates/RecapScreenTemplate';
import {
  accompaniedDrivingOptionsDict,
  housingTypeOptionsDict,
  isPropertyOwnerOptionsDict,
  licenseTypeOptionsDict,
  ownerVehicleRegistrationOptionsDict,
  parkingLocationOptionsDict,
  vehicleUsageOptionsDict,
} from '../../../constants/mappers/driving';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { DefaultAnswersEnum, Event, State } from '../../../fsm/types';
import { formatDateForUI } from '../../../utils/date';
import MapIllustration from './assets/map.svg';

export function DrivingSummaryScreen(): ReactNode {
  const send = useSubscriptionFsmDispatch();

  const moveToScreen = (destination: State) => {
    send(Event.RETURN_TO_SCREEN, { destination });
  };

  const {
    context: { answers, defaultAnswers },
  } = useSubscriptionFsmState();

  const primaryDriverLicenseDate = formatDateForUI(answers.primaryDriver!.licenseDate!);
  const primaryDriverAccompaniedDriving = accompaniedDrivingOptionsDict[answers.primaryDriver!.accompaniedDriving!];
  const primaryDriverLicenseType = licenseTypeOptionsDict[answers.primaryDriver!.licenseType!];
  const vehicleUsage = vehicleUsageOptionsDict[answers.usage!];
  const kilometersPerYear = answers.kilometersPerYear!.toString();
  const parkingLocation = parkingLocationOptionsDict[answers.parkingLocation!];
  const parkingCity = `${answers.parkingCodePostal}, ${answers.parkingCommune}`;
  const housingType = housingTypeOptionsDict[answers.housingType!];
  const isPropertyOwner = isPropertyOwnerOptionsDict[answers.isPropertyOwner!];
  const ownerVehicleRegistration = ownerVehicleRegistrationOptionsDict[answers.titulaireCartegrise!];

  return (
    <RecapScreenTemplate
      title="Ma conduite"
      illustration={<img src={MapIllustration} alt="Illustration d'une carte" />}
      onConfirm={() => send(Event.CONTINUE)}
    >
      <InformationList>
        <InformationEntry
          label="Date d’obtention du permis"
          value={primaryDriverLicenseDate}
          isDefaultValue={defaultAnswers.includes(DefaultAnswersEnum.PRIMARY_DRIVER_LICENSE_DATE)}
          onPress={() => moveToScreen(State.DRIVING_PRIMARY_LICENSE_DATE)}
        />
        <InformationEntry
          label="Lieu d’obtention du permis"
          value={primaryDriverLicenseType}
          onPress={() => moveToScreen(State.DRIVING_PRIMARY_LICENSE_TYPE)}
        />
        <InformationEntry
          label="Conduite accompagnée"
          value={primaryDriverAccompaniedDriving}
          onPress={() => moveToScreen(State.DRIVING_PRIMARY_WAS_ACCOMPANIED)}
        />
        <InformationEntry
          label="Utilisation du véhicule"
          value={vehicleUsage}
          onPress={() => moveToScreen(State.DRIVING_VEHICULE_USAGE)}
        />
        <InformationEntry
          label="Kilomètres par an"
          value={kilometersPerYear}
          onPress={() => moveToScreen(State.DRIVING_KILOMETERS_PER_YEAR)}
        />
        <InformationEntry
          label="Lieu de stationnement"
          value={parkingLocation}
          onPress={() => moveToScreen(State.DRIVING_VEHICULE_PARKING)}
        />
        <InformationEntry
          label="Ville de stationnement"
          value={parkingCity}
          onPress={() => moveToScreen(State.DRIVING_CITY)}
        />
        <InformationEntry
          label="Type de logement"
          value={housingType}
          onPress={() => moveToScreen(State.DRIVING_HOUSING_TYPE)}
        />
        <InformationEntry
          label="Propriétaire du logement"
          value={isPropertyOwner}
          onPress={() => moveToScreen(State.DRIVING_IS_PROPERTY_OWNER)}
        />
        <InformationEntry
          label="Titulaire du certificat d'immatriculation"
          value={ownerVehicleRegistration}
          onPress={() =>
            moveToScreen(
              answers.titulaireCartegrise === TitulaireCarteGriseType.Souscripteur
                ? State.DRIVING_REGISTRATION_DOCUMENT_OWNER_IS_SUBSCRIBER
                : State.DRIVING_REGISTRATION_DOCUMENT_OWNER,
            )
          }
        />
      </InformationList>
    </RecapScreenTemplate>
  );
}
