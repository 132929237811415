import { InputText } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { Field } from '../../../../components/Field';
import { useIbanValidator } from '../../../../forms/validation/sections/insurance/iban';

export interface IbanFieldProps {
  name: string;
  onChange?: () => void;
}

export function IbanField({ name, onChange }: IbanFieldProps): ReactNode {
  const ibanValidator = useIbanValidator();

  const formatIban = (value: string): string =>
    value
      ?.replace(/[^0-9a-z]/gi, '')
      .toUpperCase()
      .slice(0, 34) || '';

  return (
    <Field
      component={InputText}
      name={name}
      label="IBAN"
      placeholder="FR0000000000000000"
      format={formatIban}
      parse={formatIban}
      validate={ibanValidator}
      onChange={onChange}
    />
  );
}
