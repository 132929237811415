import type { ReactNode } from 'react';
import { Civility } from '../../../apis/types/LongQuoteAnswers';
import { DateFromYearsAgo } from '../../../utils/date';
import { getPronoun } from './utils';

export function HighlightContent({
  isPrimaryDriver,
  civility,
}: {
  isPrimaryDriver: boolean;
  civility?: Civility;
}): ReactNode {
  const dateFrom = DateFromYearsAgo(3);
  const isWoman = civility === Civility.Femme;

  const pronoun = getPronoun(civility, true);

  return isPrimaryDriver ? (
    <div>
      Répondez non si :<br />
      <ul>
        <li>
          Vous n’avez <b>pas été inscrit sur l’assurance auto de quelqu’un de votre entourage</b> depuis le {dateFrom}.
        </li>
        <li>
          Vous n’avez <b>pas d’assurance auto</b> depuis le {dateFrom} en tant que conducteur principal ou secondaire.
        </li>
      </ul>
      <br />
      Répondez oui si :<br />
      <ul>
        <li>
          Vous êtes <b>déjà assuré</b> ou <b>avez été assuré</b> depuis le {dateFrom}, en temps que conducteur principal
          ou secondaire.
        </li>
        <li>
          Vous êtes ou avez <b>été inscrit sur l&apos;assurance auto de quelqu’un de votre entourage</b> depuis le{' '}
          {dateFrom}.
        </li>
      </ul>
    </div>
  ) : (
    <>
      Répondez non si :<br />
      <ul>
        <li>
          {pronoun} n’a <b>pas été inscrit{isWoman ? 'e' : ''} sur l’assurance auto de quelqu’un de votre entourage</b>{' '}
          depuis le {dateFrom}.
        </li>
        <li>
          {pronoun} n’a <b>pas d’assurance auto</b> depuis le {dateFrom} en tant que conducteur principal ou secondaire.
        </li>
      </ul>
      <br />
      Répondez oui si :<br />
      <ul>
        <li>
          {pronoun} est <b>déjà assuré{isWoman ? 'e' : ''}</b> ou <b>a été assuré</b> depuis le {dateFrom}, en temps que
          conducteur principal ou secondaire.
        </li>
        <li>
          {pronoun} est ou a{' '}
          <b>été inscrit{isWoman ? 'e' : ''} sur l&apos;assurance auto de quelqu’un de votre entourage</b> depuis le{' '}
          {dateFrom}.
        </li>
      </ul>
    </>
  );
}
