import { useCallback, useEffect, useState } from 'react';

const DEFAULT_TIMER_DELAY = 300;

export const useDelayedFunction = (callback: () => void, delay: number = DEFAULT_TIMER_DELAY): (() => void) => {
  const [timeout, timeoutSetter] = useState<NodeJS.Timeout>();

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, [timeout]);

  const triggerExecution = useCallback(() => {
    timeoutSetter(
      setTimeout(() => {
        callback();
      }, delay),
    );
  }, [callback, delay]);

  return triggerExecution;
};
